import React, { FC, useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useApolloClient } from 'react-apollo';
import { Button } from 'antd';
import Screen from './components/Screen';
import List from './components/List';
import MobileHeader from './components/MobileHeader';
import { LIST_COLLEGES, LIST_COURSES } from './query';
import {
  query,
  writeQuery_types,
  writeQuery_list,
  writeQuery_page
} from './cache';
import { removalCourse, handleSearch } from './utils';
import parseSearch from 'utils/search';
import './style.less';

const Courses: FC<RouteComponentProps> = ({ history }) => {
  const client = useApolloClient();

  // 读取全局搜索 value
  const searchValue: any = parseSearch(history.location.search);
  const { value } = searchValue;

  // 读取本地缓冲
  const { data: cache } = useQuery(query);
  const { page, list, collegeName, courseName, hasNextPage } = cache.courses;

  // 筛选分类列表
  const { data: fetchTypes } = useQuery(LIST_COLLEGES);

  // 筛选分类列表写入缓冲
  useEffect(() => {
    try {
      if (fetchTypes && Object.keys(fetchTypes).length !== 0) {
        const result = fetchTypes.courseCategories;
        const collegeTypes = result.filter((item: any) => item.parent === null);
        const filterCourseTypes = result.filter(
          (item: any) => item.parent !== null
        );

        client.writeQuery({
          query: writeQuery_types,
          data: {
            courses: {
              collegeTypes: collegeTypes,
              filterCourseTypes,
              courseTypes: removalCourse(filterCourseTypes),
              __typename: 'courses'
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [fetchTypes]);

  // 检索课程列表条件
  const [variables, setVariables] = useState(handleSearch({ page }));

  // 课程列表
  const { data: fetchList } = useQuery(LIST_COURSES, { variables });

  // 课程列表写入缓冲
  useEffect(() => {
    try {
      if (fetchList && Object.keys(fetchList).length !== 0) {
        const {
          count,
          edges,
          pageInfo: { hasNextPage }
        } = fetchList.courses;

        const list = edges.map((item: any) => ({
          title: item.node.title,
          cover: item.node.cover,
          subscribedCount: item.node.subscribedCount,
          shortUrl: item.node.shortUrl
        }));

        client.writeQuery({
          query: writeQuery_list,
          data: {
            courses: {
              count,
              list,
              hasNextPage,
              __typename: 'courses'
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [fetchList]);

  // 根据分类请求课程
  useEffect(() => {
    setVariables(
      handleSearch({
        page,
        title: value ? decodeURI(value) : undefined,
        collegiateCategory: collegeName,
        courseCategory: courseName
      })
    );
  }, [page, collegeName, courseName, value]);

  return (
    <div className='courses'>
      <Screen value={value} />
      <MobileHeader value={value} />
      <List list={list} />
      {hasNextPage && (
        <Button
          type='danger'
          size='large'
          style={{
            margin: '48px 0 81px 0',
            height: '50px',
            width: '160px',
            fontWeight: 500
          }}
          onClick={() => {
            client.writeQuery({
              query: writeQuery_page,
              data: {
                courses: {
                  page: page + 1,
                  __typename: 'courses'
                }
              }
            });
          }}
        >
          查看更多
        </Button>
      )}
    </div>
  );
};

export default withRouter(Courses);
