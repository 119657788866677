import React from 'react';
import { Input } from 'antd';
import Avatar from '../components/Avatar';
import { FormConfig } from 'components/Form';

const basic: FormConfig[] = [
  {
    label: '头像',
    value: 'avatar',
    component: ({ form, defaultProps: { avatar } }) => (
      <Avatar
        avatar={avatar}
        onChange={avatar => {
          form.setFieldsValue({ avatar });
        }}
      />
    )
  },
  {
    label: '手机',
    value: 'phone',
    component: props => <Input disabled />
  },
  {
    label: '昵称',
    value: 'nickname',
    rules: [{ required: true, message: '请输入昵称' }],
    component: props => <Input placeholder='请输入昵称' />
  }
];

export default basic;
