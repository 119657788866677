/**
 * @description 卡片单个轮播
 * @author ali
 */
import React, { Component } from 'react';
import CoachItem from './CoachItem';
import leftarrow from 'assets/icons/left.svg';
import rightarrow from 'assets/icons/right.svg';
import Slider from 'react-slick';
import { Coach } from 'global_variable/home';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/coach.less';

interface ICoach {
  coach: Coach[]
}

class CoachComponent extends Component<ICoach, {}> {
  constructor(props: ICoach) {
    super(props);
  }
  state = {
    isMobile: false
  };
  componentDidMount() {
    if (window.screen.width <= 500) {
      this.setState({
        isMobile: true
      });
    }

    window.addEventListener('resize', () => {
      //  监控屏幕分辨率的问题

      if (window.screen.width <= 500) {
        // alert(window.screen.width);
        this.setState({
          isMobile: true
        });
      } else {
        this.setState({
          isMobile: false
        });
      }
    });
  }

  render() {
    const settings = {
      className: 'center',
      // autoplay: true,
      adaptiveHeight: true,
      useCSS: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      centerPadding: '100px',
      slidesToShow: this.state.isMobile ? 1 : 3,
      slidesToScroll: 1,
      nextArrow: (
        <div>
          <div className='nextArrow'>
            <img src={rightarrow} alt='箭头' />
          </div>
        </div>
      ),
      prevArrow: (
        <div>
          <div className='prevArrow'>
            {' '}
            <img src={leftarrow} alt='箭头' />
          </div>
        </div>
      )
    };
    const { coach } = this.props;
    return (
      <div className='coach'>
        <div className='coach-container'>
          <h2
            style={{
              height: '62px',
              textAlign: 'center',
              marginBottom: '22px',
              transform: this.state.isMobile
                ? 'translate(-13px,0)'
                : 'translate(-26px,0)'
            }}
          >
            学员好评
          </h2>
          <Slider {...settings}>
            {coach.map((item, index) => (
              <div key={index.toString()}>
                <div
                  style={{
                    display: 'inline-block',
                    height: '600px',
                    paddingTop: '20px'
                  }}
                >
                  <CoachItem card={item} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default CoachComponent;
