import * as React from 'react';
import './styles.css';

//引入的库组件
import {
  Form,
  Button,
  Input,
  message,
  Layout,
  Dropdown,
  Menu,
  Upload
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { withRouter } from 'react-router';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import { GreenButton } from '../CommonComponents/CustomButton';
import IconButton from '../CommonComponents/IconButton';
import { QItem } from '../CommonComponents/CustomItem';
import {
  getDataFromTreeData,
  findPackageId,
  sordCardsInTreeData,
  getDataTypeFromTreeData
} from './utils';
import CopyModalCallbackFunc from '../CopyModal/CopyModalCallbackFunc';
import Modal3CallbackFunc from '../CommonComponents/Modals/Modal3CallbackFunc';
import ComponentFunction from './ComponentFunction';

//graphql查询
import {
  useGetSearchCardQuery,
  useSortCardsMutation,
  DiffType
} from '../../generated/graphql';

//引入的资源图片
import cross from '../../assets/icons/cross.png';
import down from '../../assets/icons/arrow_down_line.png';
import create from '../../assets/icons/create.png';
import upload from '../../assets/icons/upload_small.png';
import search from '../../assets/icons/search.png';
import fullscreen from '../../assets/icons/fullscreen.png';
import thumb1 from '../../assets/icons/thumb_1.png';
import thumb_1_hover from '../../assets/icons/thumb_1_hover.png';
import thumb2 from '../../assets/icons/thumb_2.png';
import thumb_2_hover from '../../assets/icons/thumb_2_hover.png';
import thumb3 from '../../assets/icons/thumb_3.png';
import thumb_3_hover from '../../assets/icons/thumb_3_hover.png';

const contentStyle: any = {
  width: '100%',
  border: '1px solid #D2D4D7',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F6F6F7',
  justifyContent: 'center'
};

const HoverItem = styled.div`
  background-color: #00000000;
  color: #383838;
  width: 80px;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    color: #fff;
  }
`;

interface Props extends FormComponentProps {
  props: any;
  pageState: any;
  pageFunction: any;
}

const GET_CREATE_PACKAGE_INFO = gql`
  {
    hanStatus @client {
      handing
      tip
    }
    screenful @client
    dragMode @client
    sort @client
    pMode @client
    isCheckPackage @client
    selectedFileId @client
    selectedFilePackageId @client
    errorCards @client
    selectCards @client
    viewMode @client
    treeData @client
    cooperationTreeData @client
    searchFiles @client
  }
`;

const EduManagerPackagePageToolbar = (props: any) => {
  const client = useApolloClient();
  const { data: packageInfoData } = useQuery(GET_CREATE_PACKAGE_INFO);
  const { refetch: searchCard } = useGetSearchCardQuery({ skip: true });
  const [sortCardsMutation] = useSortCardsMutation();

  const {
    hanStatus,
    screenful,
    dragMode,
    sort,
    pMode,
    isCheckPackage,
    selectedFileId,
    selectedFilePackageId,
    viewMode,
    selectCards,
    errorCards,
    treeData,
    cooperationTreeData
  } = packageInfoData;

  const {
    getShowFiles,
    getAllShowFiles,
    uploadCardFunc,
    uploadZipFunc,
    onCreate,
    deleteCards,
    diffChanges
  } = props.pageFunction;
  const {
    sortFilesState,
    collapsedState,
    searchContentState,
    pageState,
    isPackageState,
    modeState,
    refreshState
  } = props.pageState;

  const creates = (treeData: any, cooperationTreeData: any) => {
    const disabled = treeData.length === 0 && cooperationTreeData.length === 0;
    return (
      <Menu
        onClick={item => {
          onCreate(item.key);
        }}
      >
        <QItem key={'2'} disabled={disabled}>
          新建文本卡片
        </QItem>
        <QItem key={'3'} disabled={disabled}>
          新建视频卡片
        </QItem>
        <QItem key={'4'} disabled={disabled}>
          新建测试卡片
        </QItem>
        <QItem key={'5'} disabled={disabled}>
          新建音频卡片
        </QItem>
        <Menu.Divider />
        <QItem key={'1'} disabled={disabled}>
          新建文件夹
        </QItem>
        <QItem key={'0'}>新建卡包</QItem>
      </Menu>
    );
  };

  const inputs = () => (
    <Menu>
      <QItem key='file'>
        <Upload
          className='edu-PackageManager-12233'
          beforeUpload={uploadCardFunc}
          showUploadList={false}
        >
          <HoverItem>文件</HoverItem>
        </Upload>
      </QItem>
      <QItem key='folder'>
        <Upload
          className='edu-PackageManager-12233'
          beforeUpload={uploadZipFunc}
          showUploadList={false}
        >
          <HoverItem>压缩包</HoverItem>
        </Upload>
      </QItem>
    </Menu>
  );

  const more = (treeData: any, cooperationTreeData: any) => {
    // const { dispatch, selectCards, pMode, cooperationTreeData, selectedFileId, treeData } = this.props;
    return (
      <Menu
        onClick={item => {
          let packageData = getDataFromTreeData(cooperationTreeData, selectedFileId);
          switch (item.key) {
            case 'copy':
              client.writeData({
                data: {
                  selectCard: '',
                  handlerFileKeys: selectCards,
                  isShowCopyModal: true,
                  isCopy: true
                }
              });
              CopyModalCallbackFunc.callback = (res: any) => {
                if (pMode) {
                  getShowFiles(
                    typeof treeData === 'undefined' ? [] : treeData,
                    [res],
                    1
                  );
                } else {
                  getShowFiles(
                    typeof cooperationTreeData === 'undefined'
                      ? []
                      : cooperationTreeData,
                    [res],
                    1
                  );
                }
              };
              break;
            case 'move':
              client.writeData({
                data: {
                  selectCard: '',
                  handlerFileKeys: selectCards,
                  isShowCopyModal: true,
                  isCopy: false
                }
              });
              CopyModalCallbackFunc.callback = (res: any) => {
                if (pMode) {
                  getShowFiles(
                    typeof treeData === 'undefined' ? [] : treeData,
                    [res],
                    1
                  );
                } else {
                  getShowFiles(
                    typeof cooperationTreeData === 'undefined'
                      ? []
                      : cooperationTreeData,
                    [res],
                    1
                  );
                }
              };
              break;
            case 'edit':
              client.writeData({
                data: {
                  card_list: selectCards.filter((item: any) => {
                    return (
                      getDataTypeFromTreeData(
                        pMode ? treeData : cooperationTreeData,
                        item
                      ) !== 0
                    );
                  })
                }
              });
              client.writeData({
                data: {
                  selectCards: [],
                  selectedFilePackageId: findPackageId(
                    pMode ? treeData : cooperationTreeData,
                    selectedFileId
                  ),
                  checkAll: {
                    cancel: true,
                    all: false,
                    on: false,
                    __typename: 'CheckAll'
                  }
                }
              });
              props.history.push('edu/create/multiEditCard');
              break;
            case 'sort':
              collapsedState.setCollapsed(true);
              sortFilesState.setSortFiles([]);
              client.writeData({ data: { dragMode: true, rightClick: false } });

              if (pMode) {
                getAllShowFiles(treeData, selectedFileId);
              } else {
                getAllShowFiles(cooperationTreeData, selectedFileId);
              }

              break;
            case 'submit':
                diffChanges(selectedFileId, packageData.title, DiffType.BranchSubmitPr);
              // dispatch({ type: 'course/toSubmitDiff', payload: {} });
              break;
            case 'del':
              const fileLength = `确定要删除 ${selectCards.length} 个文件吗？`;
              Modal3CallbackFunc.callback = () => {
                client.writeData({
                  data: {
                    checkAll: {
                      cancel: false,
                      all: false,
                      on: false,
                      __typename: 'CheckAll'
                    }
                  }
                });
                deleteCards(
                  selectCards,
                  findPackageId(
                    pMode ? treeData : cooperationTreeData,
                    selectedFileId
                  )
                );
              };
              client.writeData({
                data: {
                  modal3Loading: false,
                  modal3: {
                    show: true,
                    title: '',
                    content: fileLength,
                    __typename: 'Modal3'
                  }
                }
              });
              break;
            case 'merge':
                diffChanges(selectedFileId, packageData.title, DiffType.BranchFetchMaster);
              // dispatch({ type: 'course/beforeMergeMaster' });
              break;
            default:
          }
        }}
      >
        <QItem key='copy' disabled={selectCards.length === 0 || !pMode}>
          复制
        </QItem>
        <QItem key='move' disabled={selectCards.length === 0 || !pMode}>
          移动
        </QItem>
        <Menu.Divider />
        <QItem key='edit' disabled={selectCards.length === 0}>
          批量修改
        </QItem>
        <QItem key='del' disabled={selectCards.length === 0 || !pMode}>
          批量删除
        </QItem>
        <Menu.Divider />
        <QItem key='sort' >编辑顺序</QItem>
        {pMode ? null : (
          <QItem
            disabled={
              cooperationTreeData
                .map((item: any) => {
                  return item.key;
                })
                .indexOf(selectedFileId) === -1
            }
            key='submit'
          >
            提交变更
          </QItem>
        )}
        {pMode ? null : (
          <QItem
            disabled={
              cooperationTreeData
                .map((item: any) => {
                  return item.key;
                })
                .indexOf(selectedFileId) === -1
            }
            key='merge'
          >
            合并
          </QItem>
        )}
      </Menu>
    );
  };

  return (
    <Layout.Content style={contentStyle}>
      <div className='edu-PackageManager-18918'>
        {dragMode ? (
          <div className='edu-PackageManager-19013'>
            <div className='edu-PackageManager-19099'>进入编辑顺序状态</div>
            <GreenButton
              width={'42px'}
              height={'30px'}
              className='edu-PackageManager-19241'
              onClick={() => {
                if (pMode) {
                  getShowFiles(treeData, [selectedFileId], pageState.page);
                } else {
                  getShowFiles(
                    cooperationTreeData,
                    [selectedFileId],
                    pageState.page
                  );
                }
                collapsedState.setCollapsed(false);
                client.writeData({
                  data: { dragMode: false, rightClick: true }
                });
              }}
            >
              取消
            </GreenButton>
            <div className='edu-PackageManager-19932'>
              <GreenButton
                width={'76px'}
                height={'30px'}
                loading={sort}
                onClick={() => {
                  if (sortFilesState.sortFiles.length === 0) {
                    if (pMode) {
                      getShowFiles(treeData, [selectedFileId], pageState.page);
                    } else {
                      getShowFiles(
                        cooperationTreeData,
                        [selectedFileId],
                        pageState.page
                      );
                    }
                    collapsedState.setCollapsed(!collapsedState.collapsed);
                    client.writeData({
                      data: { dragMode: false, rightClick: true }
                    });
                    return;
                  }
                  const keyList: any = sortFilesState.sortFiles.map(
                    (value: any, subIndex: any) => {
                      return value.title;
                    }
                  );
                  let packageId = findPackageId(
                    pMode ? treeData : cooperationTreeData,
                    selectedFileId
                  );
                  let folderId = selectedFileId;

                  let packageIdBase64 = Base64.decode(packageId).split(
                    'Package:'
                  )[1];
                  let folderIdBase64 = Base64.decode(folderId).split(
                    'Card:'
                  )[1];

                  let input: any = {};
                  if (packageId === selectedFileId) {
                    input = {
                      fileNames: keyList,
                      packageId: packageIdBase64
                    };
                  } else {
                    input = {
                      fileNames: keyList,
                      packageId: packageIdBase64,
                      folderId: folderIdBase64
                    };
                  }

                  client.writeData({
                    data: {
                      hanStatus: {
                        handing: true,
                        tip: '正在排序',
                        __typename: 'HanStatus'
                      }
                    }
                  });
                  sortCardsMutation({ variables: { input: input } }).then(
                    (res: any) => {
                      client.writeData({
                        data: {
                          hanStatus: {
                            handing: false,
                            tip: '',
                            __typename: 'HanStatus'
                          }
                        }
                      });
                      if (res.data.sortFolder.errors) {
                        message.error('排序出错!');
                      } else {

                        ComponentFunction.onSelectFile([selectedFileId], pMode);

                        // let cards = res.data.sortFolder.cards;
                        // sordCardsInTreeData(
                        //   pMode ? treeData : cooperationTreeData,
                        //   selectedFileId,
                        //   cards
                        // );
                        // client.writeQuery({
                        //   query: GET_CREATE_PACKAGE_INFO,
                        //   data: {
                        //     treeData: treeData,
                        //     cooperationTreeData: cooperationTreeData
                        //   }
                        // });
                        // if (pMode) {
                        //   getShowFiles(
                        //     treeData,
                        //     [selectedFileId],
                        //     pageState.page
                        //   );
                        // } else {
                        //   getShowFiles(
                        //     cooperationTreeData,
                        //     [selectedFileId],
                        //     pageState.page
                        //   );
                        // }
                        collapsedState.setCollapsed(!collapsedState.collapsed);
                        client.writeData({
                          data: { dragMode: false, rightClick: true }
                        });
                      }
                    }
                  );
                }}
              >
                保存顺序
              </GreenButton>
            </div>
          </div>
        ) : (
          <div className='edu-PackageManager-21536'>
            <Dropdown overlay={creates(treeData, cooperationTreeData)}>
              <Button className='edu-PackageManager-21734'>
                <img src={create} data-rjs='2' />
                <div className='edu-PackageManager-21900'>新建</div>
                <img
                  src={down}
                  className='edu-PackageManager-21983'
                  data-rjs='2'
                />
              </Button>
            </Dropdown>

            <Dropdown disabled={isCheckPackage} overlay={inputs()}>
              <Button className='edu-PackageManager-22234'>
                <img src={upload} data-rjs='2' />
                <div className='edu-PackageManager-22416'>上传</div>
                <img
                  src={down}
                  className='edu-PackageManager-22499'
                  data-rjs='2'
                />
              </Button>
            </Dropdown>

            <Dropdown
              disabled={isCheckPackage}
              overlay={more(treeData, cooperationTreeData)}
            >
              <Button className='edu-PackageManager-22748'>
                <div className='edu-PackageManager-22864'>更多</div>
                <img
                  src={down}
                  className='edu-PackageManager-22948'
                  data-rjs='2'
                />
              </Button>
            </Dropdown>
            <div
              style={{
                width: 220,
                height: 40,
                marginLeft: screenful ? 320 : 424
              }}
            >
              <Input
                className='edu-PackageManager-23244'
                suffix={
                  searchContentState.searchContent ? (
                    <a
                      onClick={() => {
                        searchContentState.setSearchContent('');
                      }}
                    >
                      <img src={cross} data-rjs='2' />
                    </a>
                  ) : null
                }
                value={searchContentState.searchContent}
                placeholder='搜索卡片'
                prefix={
                  <a>
                    <img src={search} data-rjs='2' />
                  </a>
                }
                onChange={value => {
                  searchContentState.setSearchContent(value.target.value);
                }}
                onPressEnter={(e: any) => {
                  pageState.setPage(1);
                  if (!isPackageState.isPackage && pMode) {
                    if (e.target.value === '') {
                      client.writeData({ data: { searchMode: false } });
                      getShowFiles(treeData, selectedFileId, 1);
                    } else {
                      let input: any = {};
                      let packageId = findPackageId(treeData, selectedFileId);
                      if (selectedFileId === packageId) {
                        input = {
                          packageId: packageId,
                          matching: e.target.value
                        };
                      } else {
                        input = {
                          packageId: packageId,
                          matching: e.target.value,
                          folderCardId: selectedFileId
                        };
                      }
                      client.writeData({ data: { searchMode: true } });
                      searchCard(input).then((res: any) => {
                        if (res.data.errors) {
                          message.error('查找错误!');
                          client.writeData({ data: { searchMode: false } });
                        } else {
                          let data = res.data.searchCardsInside;
                          let showDatas = data.map((item: any) => {
                            return {
                              collaborativeState: item.collaborativeState ? item.collaborativeState : "",
                              hasFolderChild: false,
                              pid: selectedFileId,
                              key: item.id,
                              is_assistant: pMode ? 0 : 1,
                              type: item.fileType === 'FOLDER' ? 0 : 1,
                              title: item.name,
                              children: [],
                              __typename: 'TreeData'
                            };
                          });
                          let treeDataTemp = [
                            {
                              collaborativeState: "",
                              hasFolderChild: true,
                              pid: '',
                              key: selectedFileId,
                              is_assistant: pMode ? 0 : 1,
                              type: 0,
                              title: 'temp',
                              children: showDatas,
                              __typename: 'TreeData'
                            }
                          ];
                          client.writeQuery({
                            query: GET_CREATE_PACKAGE_INFO,
                            data: { searchFiles: treeDataTemp }
                          });
                          getShowFiles(treeDataTemp, [selectedFileId], 1);
                        }
                      });
                      // dispatch({
                      //   type: 'course/searchTeacherCard',
                      //   payload: {
                      //     file_name: e.target.value,
                      //     page: 1,
                      //     count: 24,
                      //     key: selectedFileId
                      //   }
                      // });
                    }
                  } else if (!isPackageState.isPackage && !pMode) {
                    if ((e.target as any).value === '') {
                      // dispatch({
                      //   type: 'course/handlerSearchMode',
                      //   searchMode: false
                      // });
                      getShowFiles(cooperationTreeData, selectedFileId, 1);
                    } else {
                      // dispatch({
                      //   type: 'course/handlerSearchMode',
                      //   searchMode: true
                      // });
                      // dispatch({
                      //   type: 'course/searchTeacherCard',
                      //   payload: {
                      //     file_name: e.target.value,
                      //     page: 1,
                      //     count: 24,
                      //     key: selectedFileId
                      //   }
                      // });
                    }
                  } else {
                    modeState.setMode(false);
                    // dispatch({ type: 'course/searchTeacherCard', payload: { file_name: e.target.value, page: 1, count: 24, key: selectedFileId } });
                  }
                }}
              />
            </div>
            <div className='edu-PackageManager-26110' />
            <div className='edu-PackageManager-26224'>
              <IconButton
                click={() => {
                  client.writeData({ data: { viewMode: !viewMode } });
                  modeState.setMode(false);
                }}
                press={thumb_1_hover}
                unPress={thumb1}
                selected={!viewMode}
              />
              <IconButton
                click={() => {
                  client.writeData({ data: { viewMode: !viewMode } });
                  modeState.setMode(true);
                }}
                press={thumb_2_hover}
                unPress={thumb2}
                selected={viewMode}
              />
              <IconButton
                click={() => {

                  let filterCars = selectCards.filter((item: any, index: any) => {
                    return getDataTypeFromTreeData(pMode ? treeData: cooperationTreeData, item) !== 0;
                  });
                  if (filterCars.length === 0) {
                    message.warning('请选择卡片');
                  } else {
                    client.writeData({
                      data: {
                        card_list:filterCars,
                        showPreviewModal: true
                      }
                    });
                    // dispatch({
                    //   type: 'course/beforeCheckCard',
                    //   payload: { card_list: selectCards }
                    // });
                  }
                }}
                press={thumb_3_hover}
                unPress={thumb3}
              />
            </div>
            <div className='edu-PackageManager-27791' />
            {screenful ? (
              <Button
                className='edu-PackageManager-27985'
                onClick={() => {
                  client.writeData({
                    data: { screenful: false, isShowHeader: true }
                  });
                }}
              >
                退出全屏
              </Button>
            ) : (
              <img
                src={fullscreen}
                data-rjs='2'
                onClick={() => {
                  client.writeData({
                    data: { screenful: true, isShowHeader: false }
                  });
                }}
                className='edu-PackageManager-28574'
              />
            )}
          </div>
        )}
      </div>
    </Layout.Content>
  );
};

const EduManagerPackagePageToolbarWrapper = Form.create<Props>()(
  withRouter(EduManagerPackagePageToolbar)
);

export default EduManagerPackagePageToolbarWrapper;
