import React, { FC, useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { GET_COURSE_DIRECTORY, GET_SUB_DIRECTORY } from '../query';
import { query, writeQuery_directoryList } from '../cache';

const { SubMenu } = Menu;

const recursionDir = (data: any, node: any) => {
  // 目录层级结构
  return data.map((item: any) => {
    if (item.id === node.id) {
      item['children'] = node.children;
    }
    if (item.id !== node.id) {
      // 如果第一层不匹配，看看是否有children
      if (item.children) {
        recursionDir(item.children, node);
      }
    }
    // 如果item匹配，那很好直接插入

    return item;
  });
};

const Navigation: FC = () => {
  const client = useApolloClient();

  // 读取缓冲信息
  const { data: cache } = useQuery(query);
  const { directoryList, coursePackageId } = cache;

  const [count, setCount] = useState(0);

  // 读取目录
  const { data } = useQuery(GET_COURSE_DIRECTORY, {
    variables: {
      id: coursePackageId,
      coursePackageId: coursePackageId
    }
  });

  useEffect(() => {
    try {
      if (data && Object.keys(data).length !== 0) {
        const directoryList = data.node.contents.edges.map(
          (item: any) => item.node
        );
        client.writeQuery({
          query: writeQuery_directoryList,
          data: {
            directoryList
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  // 读取子目录
  const [loadSubMenu, { data: subMenu }] = useLazyQuery(GET_SUB_DIRECTORY);

  useEffect(() => {
    try {
      if (subMenu && Object.keys(subMenu).length !== 0) {
        setCount(count => count + 1);

        client.writeQuery({
          query: writeQuery_directoryList,
          data: {
            directoryList: recursionDir(directoryList, subMenu.node)
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [subMenu]);

  if (directoryList.length === 0) return null;

  const Recursive = (menu: any) =>
    menu.map((item: any) => {
      if (item.children && item.children.length !== 0) {
        return (
          <SubMenu
            key={item.id}
            title={<span>{item.name}</span>}
            onTitleClick={() => {
              loadSubMenu({
                variables: {
                  id: item.id,
                  coursePackageId: coursePackageId
                }
              });
            }}
          >
            {Recursive(item.children)}
          </SubMenu>
        );
      } else {
        return <Menu.Item key={item.id}>{item.name}</Menu.Item>;
      }
    });

  return (
    <Menu style={{ width: 256 }} mode='inline'>
      {console.log(count)}
      {directoryList.map((item: any) => (
        <SubMenu
          key={item.id}
          title={<span>{item.name}</span>}
          onTitleClick={() => {
            loadSubMenu({
              variables: {
                id: item.id,
                coursePackageId: coursePackageId
              }
            });
          }}
        >
          {item.children && Recursive(item.children)}
        </SubMenu>
      ))}
    </Menu>
  );
};

export default Navigation;
