import * as React from 'react';
import './styles.css';

//引入的库组件
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { useQuery } from "react-apollo-hooks";

//引入的其他页面组件
import EduManagerCoursePage from './EduManagerCoursePage';

//graphql查询
import { useGetEduManagerCoursesQuery } from '../../generated/graphql';

//引入的资源图片


const GET_COURSES_INFO = gql` 
  {
    createdCoursesAfter @client
    createdCoursesFirst @client
    createdCoursesSearchContent @client
  }
`;

const EduManagerCoursePageContainer = (props: any) => {
  const paramData = useQuery(GET_COURSES_INFO).data;
  const { data, error, loading, refetch } = useGetEduManagerCoursesQuery({
    variables: { after: paramData.createdCoursesAfter, first: paramData.createdCoursesFirst, filter: {title : paramData.createdCoursesSearchContent}  },
    fetchPolicy:"no-cache",
  });

  if (error || loading || !data) {
    return <div />;
  }

  return <EduManagerCoursePage courseData={data} props={props} refetch={refetch}  />;
};

export default withRouter(EduManagerCoursePageContainer);
