/**
 * @description 过滤作业组件
 * @author 阿礼
 * @since 2019-9-13
 */
import React, { useState } from 'react';
import './style.less';

// 第三方组件
import { Button } from 'antd';
import gql from 'graphql-tag';
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/react-hooks';

// Apollo 缓存查询
const GET_ASSIGNMENTSLIST = gql`
  {
    assignmentList @client
    allAssignmentList @client
    doneAssignmentList @client
    undoneAssignmentList @client
  }
`;

const FilterAssignments = props => {
  const [selectValue, setSelect] = useState('全部');
  const buttons = [{ name: '全部' }, { name: '未完成' }, { name: '完成' }];
  const client = useApolloClient();
  const {
    data: { allAssignmentList, doneAssignmentList, undoneAssignmentList },
    error,
    loading
  } = useQuery(GET_ASSIGNMENTSLIST);
  //如果是全部，那么就把全部的数据赋值给assignmentList
  //如果是未完成，那么就把全部的数据赋值给assignmentList
  //如果是完成，那么就把完成的数据赋值给assignmentList
  if (loading) {
    return null;
  }

  return (
    <div className='filter-buttons'>
      {buttons.map(item => (
        <Button
          className={selectValue === item.name ? 'selectedButton' : 'button'}
          key={item.name}
          onClick={() => {
            if (item.name === '全部') {
              client.writeQuery({
                query: GET_ASSIGNMENTSLIST,
                data: { assignmentList: allAssignmentList }
              });
            }
            if (item.name === '未完成') {
              client.writeQuery({
                query: GET_ASSIGNMENTSLIST,
                data: { assignmentList: undoneAssignmentList }
              });
            }
            if (item.name === '完成') {
              client.writeQuery({
                query: GET_ASSIGNMENTSLIST,
                data: { assignmentList: doneAssignmentList }
              });
            }
            setSelect(item.name);
          }}
        >
          {item.name}
          {item.name === '未完成' && undoneAssignmentList.length}
          {item.name === '完成' && doneAssignmentList.length}
        </Button>
      ))}
    </div>
  );
};

export default FilterAssignments;
