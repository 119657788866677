import React from 'react';
import finishAll from '../../../../assets/omcbg/studyall.png';

const FinishAllContent = () => {
  return (
    <>
      <img src={finishAll} alt={'congratulation~'} />
    </>
  );
};

export default FinishAllContent;
