import React, { FC, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { query, writeQuery_course_detail } from './cache';
import { College } from 'global_variable/home';
import { GET_COURSE_DETAIL_N, GET_COURSE_DETAIL_Y } from './query';
import Banner from './components/Banner';
import Content from './components/Content';
import './style.less';

const CourseDetail: FC<RouteComponentProps> = ({ history }) => {
  const client = useApolloClient();

  const { pathname } = history.location;
  const courseId = pathname.split('/')[3];
  // 课程 id
  const id = window.btoa(`Course:short_url:${courseId}`);

  // 读取缓冲信息
  const { data: cache } = useQuery(query);
  const { colleges } = cache.home;
  const { college } = cache.courseDetail;
  const { isLoggedIn } = cache;

  // 获取课程所属学院
  const { data, refetch } = useQuery(
    isLoggedIn ? GET_COURSE_DETAIL_Y : GET_COURSE_DETAIL_N,
    { variables: { id } }
  );

  // 课程详情
  useEffect(() => {
    try {
      if (data && Object.keys(data).length !== 0) {
        const course = data.node;

        // 所属学院
        const collegeTitle = course.category
          ? course.category.parent.name
          : 'OpenMind+';

        // 当前学院的配置信息
        const college = colleges.find(
          (item: College) => item.title === collegeTitle
        );

        client.writeQuery({
          query: writeQuery_course_detail,
          data: {
            courseDetail: {
              college,
              course,
              __typename: 'courseDetail'
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  return (
    <div className='course-detail'>
      <Banner college={college} />
      <Content refetch={refetch} course_Id={id} />
    </div>
  );
};

export default withRouter(CourseDetail);
