import React, { Component } from 'react';
import styled from 'styled-components';
import Avatar from 'antd/es/avatar/index';


const Comment = styled.div`
width: 1000px;
min-height: 127px;
display: flex;
padding-top: 20px;
`;

Comment.Avatar = styled(Avatar)`
  flex-shrink: 0;
`;

Comment.View = styled.div`
  width: 942px;
 margin-left: 10px;
`

Comment.InfoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
Comment.User = styled.div`
 height: 17px;
 font-size: 12px;
 color: #93979F;
`;

Comment.Delete = styled.div`
cursor: pointer;
 font-size: 14px;
 color: #93979F;
`;
Comment.Content = styled.div`
width: 942px;
min-height: 84px;
color:  #282A2D;
margin-top: 8px;
`;

export { Comment };
