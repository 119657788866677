import React, { FC } from 'react';
import { CollegesCourses } from 'global_variable/collegeDetail';
import CourseItem from './CourseItem';

interface ICourses {
  collegesCourses: CollegesCourses[];
  title: string;
}

const Courses: FC<ICourses> = ({ collegesCourses, title }) => {
  let courseMainOrders = [
    "英才四课",
    "AI四课",
    "三生四论",
    "写作三课",
    "通识千书",
    "专题课",
    "共读营",
    "公开课",
    "开智大会",
    "开智社群"
  ];
  let courseCategories = collegesCourses.sort((a: CollegesCourses, b: CollegesCourses) => {
    return courseMainOrders.indexOf(a.name) - courseMainOrders.indexOf(b.name);
  });
  return (
    <div className='college-detail-courses'>
      <div className='college-detail-courses-content'>
        {courseCategories.map(item => (
          <CourseItem
            key={item.name}
            collegiateCategory={title}
            courseCategory={item.name}
          />
        ))}
      </div>
    </div>
  );
};

export default Courses;
