import gql from 'graphql-tag';

export const query = gql`
  {
    directoryList @client
    coursePackageId @client
  }
`;

export const writeQuery_directoryList = gql`
  {
    directoryList @client
  }
`;
