import * as React from 'react';
import './style.less';

//引入的库组件
import { Button, message } from 'antd';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { useApolloClient } from '@apollo/react-hooks';
import { useCreateCardExamMutation } from '../../../../generated/graphql';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import { CustomP } from '../../../CommonComponents/common/customp';
import AnswerLayout from './AnswerLayout';

//graphql查询

//引入的资源图片

interface Props {
  content: any;
}

const GET_ANSWERLAYOUT_INFO = gql`
  {
    showResult @client
    examAnswerInfo @client
    showPreviewModal @client
  }
`;

const ExamCard: React.FC<Props> = ({ content }) => {
  const client = useApolloClient();
  const { data: answerLayoutInfo } = useQuery(GET_ANSWERLAYOUT_INFO);
  const { examAnswerInfo, showPreviewModal } = answerLayoutInfo;

  const [createCardExam] = useCreateCardExamMutation();

  React.useEffect(() => {
    client.writeData({ data: { showResult: false } });
    return () => {
      client.writeData({ data: { showResult: false } });
    };
  }, [content]);

  let checkIsAnswer = false;
  examAnswerInfo.info.map((item: any) => {
    if (item.answers && item.answers.length === 0) {
      checkIsAnswer = true;
    }
  });

  return (
    <div className='routes-course-CardModal-5879'>
      <div>
        <CustomP
          customStyle={{
            line: 2,
            fontSize: '26px',
            color: '#282A2D',
            lineHeight: '37px'
          }}
          style={{ width: 680, height: 'auto' }}
          className='routes-course-CardModal-6119'
        >
          {content.title}
        </CustomP>
        <div className='routes-course-CardModal-6237'>
          {content.front.exam.map((item: any) => {
            return <AnswerLayout key={examAnswerInfo.card} question={item} />;
          })}
          <Button
            className='routes-course-CardModal-6511'
            onClick={() => {
              if (checkIsAnswer) {
                message.error('请选择答案!');
              } else {
                client.writeData({ data: { showResult: true } });
                if (!showPreviewModal) {
                  createCardExam({
                    variables: {
                      input: {
                        cardId: Base64.decode(examAnswerInfo.card).split(
                          'Card:'
                        )[1],
                        info: examAnswerInfo.info
                      }
                    }
                  });
                }
              }
            }}
          >
            提交答案
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExamCard;
