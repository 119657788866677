/**
 * @description 学习中心的本地状态维护 文件夹都有物极必反
 * @since 2019
 */

let learningCenter = {
  // 全屏模式
  fullScreen: false,
  // 课程卡片是否全部刷完
  finishAllCards: false,
  //课程头部内容
  isLearnCardCount: 0,
  allCardCount: 0,

  courseTitle: "",
  cardTitle: '',
  // 搜索框备份目录数据
  copyDirectoryList: [],
  cardLearnPeople: 0,
  // 子目录
  courseId: '',
  coursePackageId: '',
  directoryList: [],
  content: null,
  currentCardId: '',
  currentParentId: '',
  currentParentIds: [],

  // 课程内容小章节是否完成
  chapterIsFinish: false,
  // 课程内容卡片收藏状态
  contentCardCollect: false,
  // 作业

  // 作业目录相关变量
  coursePackageAssignmentId: '',
  assignmentList: [],
  allAssignmentList: [],
  doneAssignmentList: [],
  undoneAssignmentList: [],
  doneCount: 0,
  undoneCount: 0,
  assignmentId: '',
  isIveDone: false,
  assignmentName: '',
  //作业内容相关
  assignmentQuestion: '',
  answerContent: '',
  // 作业评论数
  assignmentCommentsCount: 0,

  nickname: '',
  avatar: '',
  postTime: '',
  comments: [],
  // 回答作业的pid
  questionPid: '',
  // 查看其他同学的作业
  otherAnswers: [],
  // 文本id
  questionId: '',
  answerId: '',
  isCollected: false,

  showResult: false,
  examAnswerInfo: {
    __typename: 'examAnswerInfo'
  },

  //讨论相关
  discussCoursePackageId: '',

  // 收藏总数
  collectionTotal: 0,
  // 每一页的数最后一个endCursor
  endCursor: 0,
  // 每一页的数最前面一个startCursor
  startCursor: 0,
  //手机适配
  isShowMobileDirectory: false,

  //作业提交commits
  homeworkCommits: []
};

export default learningCenter;
