const local = {
  uri: '/',
  qiniuUri: '/',
  ws: 'wss://develop.openmindclub.com/socket'
};

const dev = {
  uri: 'https://develop.openmindclub.com/',
  qiniuUri: 'https://develop.openmindclub.com/',
  ws: 'wss://develop.openmindclub.com/socket'
};

const pro = {
  uri: '/',
  qiniuUri: '/',
  ws: 'wss://m.openmindclub.com/socket'
};

// 读取环境变量
const env = process.env.REACT_APP_ENV;

// 环境配置
const config = env === 'local' ? local : env === 'development' ? dev : pro;

export default config;
