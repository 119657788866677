/**
 * @description 查询迭代插入目录数据
 */
import { recursionDir } from './index.js';

// 同步执行，异步执行，必然把代码放在里面去。
const inserData = function(getNextData, id, coursePackageId, directoryList) {
  let child = null;
  let list = [];
  let parentIds = [];
  getNextData({ id, coursePackageId }).then(res => {
    const node = res.data.node;
    parentIds.push(res.data.node.id);

    if (node.children.length !== 0) {
      if (node.children[0].fileType === 'FILE') {
        list = recursionDir(directoryList, node);

        child = node.children[0];
      }
      if (node.children[0].fileType === 'FOLDER') {
        // const dirList = recursionDir(directoryList, node);
        inserData(
          getNextData,
          node.children[0].id,
          coursePackageId,
          directoryList
        );
      }
    }
  });
};
export default inserData;
