import React from 'react';

import CollectionDetailMain from './collectionDetailMain';
const CollectionMain = props => {
  return (
    <div>
      <CollectionDetailMain />
    </div>
  );
};

export default CollectionMain;
