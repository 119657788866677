import React from 'react';
import './App.css';

import IndexPage from './pages/IndexPage';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

const App: React.FC = () => {
  return (
    <div className='App'>
      <div className='App-background'>
        <ConfigProvider locale={zhCN}>
          <IndexPage />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default App;
