import React, { FC, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useApolloClient } from 'react-apollo';
import { Avatar, Button, message } from 'antd';
import { useQuery } from 'react-apollo-hooks';
import moment from 'moment';
import Card from 'components/Card';
import { SUBSCRIBED_COURSES } from './query';
import { query, query2, writeQuery_courses } from './cache';
import './style.less';
import empty1s from 'assets/omcbg/empty1s.png';
import learningCenter from '../../global_variable/learningCenter';
import discusspage from '../../global_variable/discusspage';
import timeLocalStorage from '../../utils/TimeLocalStorage';

const Stu: FC<RouteComponentProps> = ({ history }) => {
  const client = useApolloClient();

    // 获取课程列表
    const { data, refetch: getCourses } = useQuery(SUBSCRIBED_COURSES, {
      variables: { first: 1000 },
      fetchPolicy: "network-only",
      skip: true
    });

  // 读取缓冲
  const { data: cache } = useQuery(query);
  const { data: cache2 } = useQuery(query2);

  useEffect(() => {
    getCourses({ first: 1000 }).then((res : any) => {
      try {
        if (res && Object.keys(res).length !== 0) {
          const { edges } = res.data.subscribedCourses;
          const courses = edges
            .map((item: any) => item.node)
            .filter((item: any) => item.isOnline);
          const finished = courses.filter((item: any) => item.isFinished);
          const learning = courses.filter((item: any) => !item.isFinished);
  
          client.writeQuery({
            query: writeQuery_courses,
            data: {
              stu: {
                finished,
                learning,
                __typename: 'stu'
              }
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    });

  }, []);

  // const { head_img, username, userProfile } = cache2.userInfo;
  const userInfo = timeLocalStorage.get('USERINFO');
  const { head_img, username, userProfile } = userInfo as any;
  const { sayHi, finished, learning } = cache.stu;

  const content = [
    {
      title: '学习中',
      list: learning,
      noneMsg: '还没有加入任何课程'
    },
    {
      title: '已完成',
      list: finished,
      noneMsg: '还没有学过任何课程'
    }
  ];

  const date = moment(Date.now()).format('YYYY-MM-DD');

  return (
    <div className='stu'>
      <div className='stu-header'>
        <Avatar
          src={head_img}
          size={100}
          style={{
            border: '4px solid #fff'
          }}
        />
        <h2>{`${username}，${sayHi}`}</h2>
        <p>热情推动我们一直不断去创作，往前再走一步</p>
      </div>
      <div className='stu-main'>
        {content.map(i => (
          <div key={i.title}>
            <p className='stu-main-title'>{i.title}</p>
            <div className='stu-main-content'>
              {i.list.length === 0 ? (
                <div className='stu-main-none'>
                  <img src={empty1s} alt={i.noneMsg} />
                  <p>{i.noneMsg}</p>
                  {i.title === '学习中' && (
                    <span
                      className='stu-main-none-link'
                      onClick={() => history.push('/')}
                    >
                      发现课程 ›
                    </span>
                  )}
                </div>
              ) : (
                <>
                  {i.list.map((item: any) => (
                    <div
                      key={item.title}
                      className='stu-main-card'
                      onClick={() => {
                        if (item.isEnd) return;
                        if(item.needUserProfile) {
                          if (userProfile) {
                            history.push(`/stu/${item.shortUrl}/content`);
                          } else {
                            message.warning("上课前请先完善个人信息!");
                            history.push('/my');
                          }
                        }else {
                          client.writeData({
                            data: {
                              ...discusspage
                            }
                          });
                          history.push(`/stu/${item.shortUrl}/content`);
                        }
                        
                      }}
                    >
                      <Card cover={item.cover} title={item.title}>
                        <Button
                          style={{ marginLeft: '10px' }}
                          type='danger'
                          ghost
                          disabled={item.isEnd}
                        >
                          {item.isEnd ? '已结业' : '进入课程'}
                        </Button>
                      </Card>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(Stu);
