import timeLocalStorage from './TimeLocalStorage';

const info = () => {
  const userInfo = timeLocalStorage.get('USERINFO');
  if (userInfo === null || userInfo === '') {
    return {
      username: '未登录用户',
      userid: '未登录用户'
    };
  }
  return {
    username: userInfo.username,
    userid: userInfo.userid
  };
};

const gaEvents = {
  gaEventsUpload(event) {
    window.gtag('event', event.eventName, {
      event_category: info().username,
      event_label: info().userid
    });
  }
};

export default gaEvents;
