import * as React from 'react';
import './styles.css';

//引入的库组件
import styled from 'styled-components';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { Layout, Menu, Input, Button, List, Dropdown, message } from 'antd';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import EmptyLayout from '../CommonComponents/EmptyLayout';
import Course from '../CommonComponents/Course';
import Modal3CallbackFunc from '../CommonComponents/Modals/Modal3CallbackFunc';
import { history } from '../IndexPage';

//graphql查询
import {
  GetEduManagerCoursesQuery,
  useDeleteEduManagerCourseMutation
} from '../../generated/graphql';

//引入的资源图片
import search from '../../assets/icons/search.png';
import cross from '../../assets/icons/cross.png';
import empty1 from '../../assets/omcbg/empty1.png';
import user from '../../assets/icons/user.png';

const contentStyle = { width: 1000, alignSelf: 'center' };
const QItem = styled(Menu.Item)`
  background-color: #fff;
  color: #282a2d;
  font-size: 14px;
  &:hover {
    background-color: #1890ff;
    color: #fff;
  }
`;

interface Props {
  props: any;
  courseData: GetEduManagerCoursesQuery;
  refetch: any;
}

const SET_IS_EDIT_COURSE = gql`
  {
    isEditCourse @client
    editCourseId @client
    createdCoursesSearchContent @client
    createdCoursesFirst @client
    createdCoursesPageSize @client
  }
`;

const EduManagerCoursePage: React.FC<Props> = ({ courseData, props, refetch }) => {
  const [searchContent, setSearchContent] = React.useState('');
  const [page, setPage] = React.useState(1);

  const [deleteCourse] = useDeleteEduManagerCourseMutation();

  const client = useApolloClient();
  const { data } = useQuery(SET_IS_EDIT_COURSE); //为什么这里查询后，client.writeData的改变就变成实时的了

  let myCourseData = courseData.createdCourses
    ? courseData.createdCourses.edges
    : [];

  let start = (page - 1) * data.createdCoursesPageSize ;
  let end = page * data.createdCoursesPageSize;
  myCourseData = myCourseData!.slice(start, end < myCourseData!.length ? end : myCourseData!.length );

  const count = courseData.createdCourses!.count;
  const pageCount = Math.ceil(count! / data.createdCoursesPageSize)  ;


  React.useEffect(() => {
    setSearchContent(data.createdCoursesSearchContent);
  }, []);

  return (
    <Layout.Content style={contentStyle}>
      <div className='edu-manager-courses-1629'>
        <Input
          suffix={
            searchContent ? (
              <a
                onClick={() => {
                  setSearchContent('');
                  client.writeData({
                    data: {
                      createdCoursesAfter: null,
                      createdCoursesFirst: data.createdCoursesFirst,
                      createdCoursesSearchContent: ''
                    }
                  });
                }}
              >
                <img src={cross} data-rjs='2' />
              </a>
            ) : null
          }
          value={searchContent}
          placeholder='搜索课程'
          prefix={
            <a>
              <img src={search} data-rjs='2' />
            </a>
          }
          className='edu-manager-courses-2028'
          onChange={value => {
            setSearchContent(value.target.value);
          }}
          onPressEnter={e => {
            if ((e.target as any).value === '') {
              client.writeData({
                data: {
                  createdCoursesAfter: null,
                  createdCoursesFirst: data.createdCoursesFirst,
                  createdCoursesSearchContent: ''
                }
              });
            } else {
              client.writeData({
                data: {
                  createdCoursesAfter: null,
                  createdCoursesFirst: data.createdCoursesFirst,
                  createdCoursesSearchContent: (e.target as any).value
                }
              });
            }
          }}
        />

        <Button
          onClick={() => {
            client.writeData({
              data: {
                isEditCourse: false,
                editCourseId: '',
              }
            });
            props.history.push('/edu/course');
          }}
          className='edu-manager-courses-2784'
          icon='plus'
        >
          创建一门新课程
        </Button>
      </div>
      {myCourseData!.length === 0 ? (
        <EmptyLayout style={{ marginBottom: 400 }}>
          <EmptyLayout.Img src={empty1} />
          <EmptyLayout.Text>
            还没有课程，快去创建第一门课程吧～
          </EmptyLayout.Text>
        </EmptyLayout>
      ) : (
        <List
          className='edu-manager-courses-3216'
          style={{ minHeight: 500 }}
          split
          pagination={
            pageCount > 1
              ? {
                  pageSize: data.createdCoursesPageSize,
                  total: count,
                  current: page,
                  onChange: (e : number) => {
                    setPage(e);
                  }
                } as any
              : undefined
          }
          dataSource={myCourseData!}
          renderItem={courseItem => {
            let item = courseItem!.node;
            let category = item!.category ? item!.category!.name : '未分类';
            if (category !== '未分类') {
              category = item!.category!.parent
                ? item!.category!.parent!.name + '-' + category
                : category;
            }
            let type = category;
            return (
              <Course>
                <Course.CoverView>
                  <Course.Cover src={item!.cover} />
                </Course.CoverView>
                <Course.InfoView>
                  <Course.Title>{item!.title}</Course.Title>
                  <Course.Intro>{item!.subTitle}</Course.Intro>
                  <Course.Infos>
                    <Course.Info>{type}</Course.Info>
                    <Course.Info className='edu-manager-courses-4805'>
                      <img className='edu-manager-courses-4837' src={user} />{' '}
                      {item!.subscribedCount}
                    </Course.Info>
                  </Course.Infos>
                </Course.InfoView>
                <Course.Manager>
                  <Dropdown
                    overlay={
                      <Menu
                        onClick={subitem => {
                          switch (subitem.key) {
                            case 'edit':
                              client.writeData({
                                data: {
                                  isEditCourse: true,
                                  editCourseId: item!.id,
                                }
                              });
                              props.history.push('/edu/course');
                              break;
                            case 'delete':
                              Modal3CallbackFunc.callback = () => {
                                client.writeData({
                                  data: {
                                    modal3Loading: true
                                  }
                                });

                                deleteCourse({
                                  variables: { input: { id: Base64.decode(item!.id).split("Course:")[1] } }
                                }).then((res: any) => {
                                  const { errors } = res.data.deleteCourse;
                                  if (errors) {
                                    let error = errors[0];
                                    let errorMessage = '删除出错!';
                                    switch (error.message) {
                                      case 'Course not found':
                                        errorMessage = '课程不存在!';
                                        break;
                                    }
                                    message.error(errorMessage);
                                    client.writeData({
                                      data: {
                                        modal3Loading: false,
                                        modal3: {
                                          show: false,
                                          title: '',
                                          content: '',
                                          __typename: 'Modal3'
                                        }
                                      }
                                    });
                                  } else {
                                    message.success('删除课程成功!');
                                    client.writeData({
                                      data: {
                                        createdCoursesAfter: null,
                                        createdCoursesFirst: data.createdCoursesFirst,
                                        modal3Loading: false,
                                        modal3: {
                                          show: false,
                                          title: '',
                                          content: '',
                                          __typename: 'Modal3'
                                        }
                                      }
                                    });
                                    refetch();
                                    setPage(1);
                                  }
                                  Modal3CallbackFunc.callback = null;
                                });
                              };

                              client.writeData({
                                data: {
                                  modal3: {
                                    show: true,
                                    title: '',
                                    content: '确定要删除课程吗?',
                                    __typename: 'Modal3'
                                  }
                                }
                              });
                              break;
                            case 'pre':
                              history.push("mkt/course/" + item!.shortUrl);
                              // dispatch
                              //   routerRedux.push(`mkt/course/${item.short_url}`)
                              // );
                              break;
                            default:
                          }
                        }}
                      >
                        <QItem key='edit'>编辑课程</QItem>
                        {/* <QItem key='delete'>删除课程</QItem> */}
                        <QItem key='pre'>课程预览</QItem>
                      </Menu>
                    }
                  >
                    <Button>管理课程</Button>
                  </Dropdown>
                </Course.Manager>
              </Course>
            );
          }}
        />
      )}
    </Layout.Content>
  );
};

export default EduManagerCoursePage;
