/**
 * @description 卡包的内容、作业，讨论和收藏是可选
 * @author ali
 * @since 2019-9-2
 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.less';
import gaEvents from '../../../../utils/gaEvents.js';

const MenuLink = props => {
  const { list, shortUrl } = props;
  let newList = [];
  // 处理数据形成组件需要渲染的样式
  let handleList = list.filter(item => {
    if (item.packageTag === 'CONTENT' && item.status) {
      item.name = '课程';
      item.link = `/stu/${shortUrl}/content`;
      return true;
    }
    if (item.packageTag === 'ASSIGNMENT' && item.status) {
      item.name = '作业';
      item.link = `/stu/${shortUrl}/homework`;
      return true;
    }
    if (item.packageTag === 'DISCUSSION' && item.status) {
      item.name = '讨论';
      item.link = `/stu/${shortUrl}/discussion`;
      return true;
    }
    return false;
  });

  handleList.find(item => {
    if (item.name === '课程') {
      newList.push(item);
    }
  });
  handleList.find(item => {
    if (item.name === '作业') {
      newList.push(item);
    }
  });
  handleList.find(item => {
    if (item.name === '讨论') {
      newList.push(item);
    }
  });

  return (
    <div className='menu-link'>
      {newList.length > 0 &&
        newList.map(item => (
          <NavLink
            className='each-link'
            activeClassName='selected'
            activeStyle={{ color: 'red' }}
            to={item.link}
            key={item.id}
            onClick={() => {
              gaEvents.gaEventsUpload({
                eventName: `点击-${props.title}-${
                  item.name
                }`
              });
            }}
          >
            {item.name}
          </NavLink>
        ))}
      <NavLink
        className='each-link'
        activeClassName='selected'
        activeStyle={{ color: 'red' }}
        to={`/stu/${shortUrl}/collection`}
        onClick={() => {
          gaEvents.gaEventsUpload({
            eventName: `点击-${props.title}-收藏`
          });
        }}
      >
        收藏
      </NavLink>
    </div>
  );
};

export default MenuLink;
