import * as React from 'react';
import './styles.css';

//引入的库组件
import { Modal, Form, Tree, message, Layout } from 'antd';
import { withRouter } from 'react-router';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import CopyModalCallback from './CopyModalCallbackFunc';
import { CustomP } from '../CommonComponents/common/customp';
import { getSuffix } from '../../utils';
import { CustomTree } from '../CommonComponents/layout/customTree';
import {
  findPackageId,
  copyCards,
  moveCards,
  findDataIsInTreeData,
  addChildDataToPackage,
  addChildDataToCard,
  getDataFromTreeData
} from '../EduManagerPackagePage/utils';

//graphql查询
import {
  useCopyCardsMutation,
  useMoveCardsMutation,
  useGetEduCreatedPackageInfoQuery
} from '../,,/../../generated/graphql';

//引入的资源图片
import file_small_folder from '../../assets/icons/file_small_folder.png';
import root from '../../assets/icons/file_medium_root.png';
import folder from '../../assets/icons/file_medium_folder.png';
import root_while from '../../assets/icons/file_medium_root_white.png';
import folder_while from '../../assets/icons/file_medium_folder_white.png';

const GET_COPY_MODAL_INFO = gql`
  {
    isShowCopyModal @client
    isCopy @client
    selectedFileId @client
    pMode @client
    selectedFilePackageId @client(always: true)
    handlerFileKeys @client
    selectCard @client
    treeData @client
    cooperationTreeData @client
    selectCards @client
    expandedKeys @client
    checkAll @client {
      cancel
      all
      on
    }
  }
`;

const contentLayout: any = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 4,
  border: '1px solid #E4E5E7',
  width: 280,
  height: 220,
  marginTop: 11,
  overflow: 'scroll'
};

const CopyModal = (props: any) => {
  const { form } = props;
  const client = useApolloClient();
  const { data: copyModalInfo } = useQuery(GET_COPY_MODAL_INFO);

  const {
    isCopy,
    isShowCopyModal,
    selectedFileId,
    pMode,
    selectedFilePackageId,
    handlerFileKeys,
    selectCard,
    treeData,
    cooperationTreeData,
    expandedKeys
  } = copyModalInfo;

  const [expandedKeysInModal, setExpandedKeysInModal] = React.useState(
    [] as any
  );
  const [selectKey, setSelectKey] = React.useState([] as any);
  const [autoExpandParent, setAutoExpandParent] = React.useState(true);
  const [isReFresh, setIsRefresh] = React.useState(false);

  const [copyCardsMutation] = useCopyCardsMutation();
  const [moveCardsMutation] = useMoveCardsMutation();

  const { refetch: getPackageData } = useGetEduCreatedPackageInfoQuery({
    variables: { id: selectedFileId, isMaster: true },
    skip: true
  });

  const allData = pMode ? treeData : cooperationTreeData;

  const onCancel = () => {
    client.writeData({ data: { isShowCopyModal: false } });
  };

  const handleFile = () => {
    //对要移动、复制的目标文件夹查重
    let targetData = getDataFromTreeData(
      allData,
      selectKey[0]
    );
    for (let i = 0; i < handlerFileKeys.length; i++) {
      for(let j = 0 ; j < targetData.length ; j++) {
        if(targetData[j].key === handlerFileKeys[i]) {
          message.error('所选文件夹已存在所要操作的文件,无法操作!');
          return;
        }
      }
    }

    //对要移动的文件夹查询是否移动到自身子文件夹内
    for (let i = 0; i < handlerFileKeys.length; i++) {
      let data = getDataFromTreeData(
        allData,
        handlerFileKeys[i]
      );
      if (data && findDataIsInTreeData([data], selectKey[0])) {
        message.error('所选文件夹中包含目标路径,无法操作!');
        return;
      }
    }

    handlerFileKeys.map((item: any) => {});

    let packageId = findPackageId(allData, selectedFileId);

    let isToRoot = selectKey[0] === findPackageId(allData, selectKey[0]);
    let ids = handlerFileKeys.map((item: any) => {
      return Base64.decode(item).split('Card:')[1];
    });
    let toFolderId = Base64.decode(selectKey[0]).split(
      isToRoot ? 'Package:' : 'Card:'
    )[1];
    let toPackageId = Base64.decode(
      findPackageId(allData, selectKey[0])
    ).split('Package:')[1];
    let oldPackageId = Base64.decode(packageId).split('Package:')[1];
    let oldFolderId = Base64.decode(selectedFileId).split(
      selectedFileId === packageId ? 'Package:' : 'Card:'
    )[1];
    let input: any = {
      ids: ids,
      newFolderId: toFolderId === toPackageId ? 'null' : toFolderId,
      packageId: oldPackageId,
      newPackageId: toPackageId
    };
    if (oldPackageId !== oldFolderId) {
      input.oldFolderId = oldFolderId;
    }

    if (isCopy) {
      copyCardsMutation({ variables: { input: input, isMaster: pMode } })
        .then((res: any) => {
          if (res.data.copyCards.errors) {
            message.error(res.data.copyCards.errors[0].message);
          } else {
            let copyToId = '';
            if (res.data.copyCards.folder) {
              let newFolderData = res.data.copyCards.folder;
              copyToId = newFolderData.id;
              copyCards(
                pMode ? treeData : cooperationTreeData,
                newFolderData,
                false,
                pMode,
                'TreeData'
              );
            } else {
              let packageData = res.data.copyCards.package;
              copyToId = packageData.id;
              copyCards(
                pMode ? treeData : cooperationTreeData,
                packageData,
                true,
                pMode,
                'TreeData'
              );
            }

            client.writeQuery({
              query: GET_COPY_MODAL_INFO,
              data: {
                treeData: treeData,
                cooperationTreeData: cooperationTreeData,
                selectedFileId: copyToId,
                handlerFileKeys: [],
                selectCards: [],
                isShowCopyModal: false,
                checkAll: {
                  cancel: true,
                  all: false,
                  on: false,
                  __typename: 'CheckAll'
                }
              }
            });
            CopyModalCallback.callback(copyToId);
            CopyModalCallback.callback = null;
            message.success('复制成功!');
          }
        })
        .catch((res: any) => {
          message.error('复制失败!');
        });
    } else {
      moveCardsMutation({ variables: { input: input, isMaster: pMode } })
        .then((res: any) => {
          if (res.data.moveCards.errors) {
            message.error(res.data.moveCards.errors[0].message);
          } else {
            let moveToId = '';
            if (res.data.moveCards.folder) {
              let newFolderData = res.data.moveCards.folder;
              moveToId = newFolderData.id;
              moveCards(
                pMode ? treeData : cooperationTreeData,
                handlerFileKeys,
                newFolderData,
                false,
                pMode,
                'TreeData'
              );
            } else {
              let packageData = res.data.moveCards.package;
              moveToId = packageData.id;
              moveCards(
                pMode ? treeData : cooperationTreeData,
                handlerFileKeys,
                packageData,
                true,
                pMode,
                'TreeData'
              );
            }

            let newExpandedKeys = expandedKeys.filter((item: any) => {
              return findDataIsInTreeData(
                pMode ? treeData : cooperationTreeData,
                item
              );
            });

            client.writeQuery({
              query: GET_COPY_MODAL_INFO,
              data: {
                treeData: treeData,
                cooperationTreeData: cooperationTreeData,
                selectedFileId: moveToId,
                handlerFileKeys: [],
                selectCards: [],
                isShowCopyModal: false,
                expandedKeys: newExpandedKeys,
                checkAll: {
                  cancel: true,
                  all: false,
                  on: false,
                  __typename: 'CheckAll'
                }
              }
            });
            CopyModalCallback.callback(moveToId);
            CopyModalCallback.callback = null;
            message.success('移动成功!');
          }
        })
        .catch((res: any) => {
          message.error('移动失败!');
        });
    }

    // if (isCopy) {
    //   dispatch({
    //     type: 'course/copy',
    //     payload: {
    //       _type: '0',
    //       to_key: this.state.selectKey[0],
    //       key_list: handlerFileKeys,
    //       role: pMode ? '0' : '1'
    //     }
    //   });
    // } else {
    //   dispatch({
    //     type: 'course/copy',
    //     payload: {
    //       _type: '1',
    //       to_key: this.state.selectKey[0],
    //       key_list: handlerFileKeys,
    //       role: pMode ? '0' : '1'
    //     }
    //   });
    // }
  };

  const onSelect = (selectedKeys: any) => {
    if (selectedKeys.length === 0) {
      return;
    }
    setSelectKey(selectedKeys);
    setIsRefresh(!isReFresh);
  };

  const onExpand = (expandedKeysNew: any) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    let expandedKeysNewIn = expandedKeysNew.filter((item: any) => {
      return !(expandedKeysInModal.indexOf(item) !== -1);
    });
    if (expandedKeysNewIn.length > 0) {
      getPackageData({
        id: expandedKeysNew[expandedKeysNew.length - 1],
        isMaster: pMode
      }).then((res: any) => {
        let data: any = res.data.node;

        if (data.__typename === 'Package') {
          addChildDataToPackage(
            pMode ? treeData : cooperationTreeData,
            data,
            pMode,
            'TreeData'
          );
          client.writeQuery({
            query: GET_COPY_MODAL_INFO,
            data: {
              treeData: treeData,
              cooperationTreeData: cooperationTreeData
            }
          });
        } else if (data.__typename === 'Card') {
          addChildDataToCard(
            pMode ? treeData : cooperationTreeData,
            data,
            pMode,
            'TreeData'
          );
          client.writeQuery({
            query: GET_COPY_MODAL_INFO,
            data: {
              treeData: treeData,
              cooperationTreeData: cooperationTreeData
            }
          });
        } else {
          message.error('获取内容错误!');
          return;
        }
        setIsRefresh(!isReFresh);
      });
    }

    // if (expandedKeysNewIn.length > 0) {
    //   ComponentFunction.onExpendFile(expandedKeysNew, pMode, () => {
    //     setIsRefresh(!isReFresh);
    //   });
    // }
    setExpandedKeysInModal(expandedKeysNew);
    setAutoExpandParent(true);
  };

  const renderTreeNodes = (data: any) => {
    if (data.length === 0) {
      return;
    }
    return data.map((item: any) => {
      const selected = selectKey[0] === item.key;
      if (item.children && item.type === 0) {
        return (
          <Tree.TreeNode
            isLeaf={!item.hasFolderChild}
            title={
              <div>
                <img
                  src={
                    item.pid === 0
                      ? selected
                        ? root_while
                        : root
                      : selected
                      ? folder_while
                      : folder
                  }
                  className='routes-course-copyMadol-2751'
                />
                {item.title}
              </div>
            }
            key={item.key}
            dataRef={item}
          >
            {renderTreeNodes(item.children)}
          </Tree.TreeNode>
        );
      }
    });
  };

  let icon = '';
  if (selectCard !== '') {
    icon = getSuffix(
      selectCard.substring(selectCard.lastIndexOf('.') + 1, selectCard.length)
    );
  }

  const img = selectCard === '' ? file_small_folder : icon;
  const fileName =
    selectCard === '' ? `${handlerFileKeys.length} 个文件` : selectCard;

  return (
    <Modal
      maskClosable={false}
      title={isCopy ? '复制' : '移动'}
      visible={isShowCopyModal}
      onOk={handleFile}
      onCancel={onCancel}
      width={380}
      cancelText={'取消'}
      okText={'确认'}
      bodyStyle={{
        height: 340,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div className='edu-copyModal-3818'>
        <img src={img} />
        <CustomP
          customStyle={{ fontSize: 14, color: '#93979F', line: 1 }}
          className='edu-copyModal-3986'
        >
          {fileName}
        </CustomP>
      </div>
      <div className='edu-copyModal-4061'>
        {isCopy ? '选择复制到' : '选择移动到'}
      </div>
      <Layout.Content style={contentLayout}>
        <CustomTree
          onExpand={onExpand}
          expandedKeys={expandedKeysInModal}
          autoExpandParent={autoExpandParent}
          onSelect={onSelect}
          selectedKeys={selectKey}
        >
          {renderTreeNodes(allData)}
        </CustomTree>
      </Layout.Content>
    </Modal>
  );
};

const CopyModalWrapper = Form.create()(withRouter(CopyModal));

export default CopyModalWrapper;
