import React, { FC, useEffect, useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const CropperComponent = props => {
  return (
    <Cropper
      ref={props.cropperRef}
      src={props.src}
      style={props.style}
      aspectRatio={1 / 1}
      guides={false}
    />
  );
};

export default CropperComponent;
