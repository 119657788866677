import * as React from 'react';
import './styles.css';

//引入的库组件
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { List, Avatar, message } from 'antd';
import moment from 'moment';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import MyReactMarkdown from '../../../CommonComponents/MyReactMarkdown';
import Modal3CallbackFunc from '../../../CommonComponents/Modals/Modal3CallbackFunc';

//graphql查询
import {
  useRemoveCommentFromDiscussionMutation,
  useGetDiscussCommentQuery
} from '../../../../generated/graphql';

//引入的资源图片
import deleteIcon from '../../../../assets/v2.1/delete.png';

const GET_DISCUSS_DETAIL_LIST_INFO = gql`
  {
    discussCommentPage @client
    discussCountPerPage @client
    userInfo @client {
      userid
    }
    discussDetailId @client
    discussCoursePackageId @client
  }
`;

const DiscussCheckList = (props: any) => {
  const client = useApolloClient();
  const [removeCommentMutation] = useRemoveCommentFromDiscussionMutation();
  const { data: listInfo } = useQuery(GET_DISCUSS_DETAIL_LIST_INFO);
  const {
    discussCountPerPage,
    userInfo,
    discussDetailId,
    discussCoursePackageId,
    discussCommentPage
  } = listInfo;

  let datas = props.discussData.node;
  let commentList = datas.comments.edges.map((item: any) => {
    return item.node;
  });
  let discComment = {
    page: Math.ceil(datas.comments.count / discussCountPerPage),
    count: datas.comments.count
  };
  let pageInfo = datas.comments.pageInfo;

  //   const [discussData, setDiscussData] = React.useState(props.discussData);
  //   const [commentPage, setCommentPage] = React.useState(1);
  //   const [isGetNewData, setIsGetNewData] = React.useState(false);

  //   const [commentList, setCommentList] = React.useState(
  // datas.comments.edges.map((item: any) => {
  //   return item.node;
  // })
  //   );
  //   const [pageInfo, setPageInfo] = React.useState(datas.comments.pageInfo);
  //   const [discComment, setDiscComment] = React.useState({
  //   page: Math.ceil(datas.comments.count / discussCountPerPage),
  //   count: datas.comments.count
  //   });

  return commentList.length === 0 ? null : (
    <div className='new-routes-study-courseDiscussion-2509'>
      <List
        pagination={
          discComment.page < 2
            ? null
            : ({
                pageSize: 20,
                current: discussCommentPage,
                total: discComment.count,
                onChange: (e: number) => {
                  let pages = e - discussCommentPage;
                  let input: any = null;

                  if (pages > 0) {
                    let currentLastDataCursor = pageInfo.endCursor;
                    let cursorName = Base64.decode(currentLastDataCursor).split(
                      ':'
                    )[0];
                    let index =
                      parseInt(
                        Base64.decode(currentLastDataCursor).split(':')[1]
                      ) +
                      (pages - 1) * discussCountPerPage;

                    input = {
                      discussID: discussDetailId,
                      first: discussCountPerPage,
                      after: Base64.encode(cursorName + ':' + index),
                      before: undefined,
                      last: undefined
                    };
                  } else {
                    let currentLastDataCursor = pageInfo.startCursor;
                    let cursorName = Base64.decode(currentLastDataCursor).split(
                      ':'
                    )[0];
                    let index =
                      parseInt(
                        Base64.decode(currentLastDataCursor).split(':')[1]
                      ) +
                      (pages + 1) * discussCountPerPage;

                    input = {
                      discussID: discussDetailId,
                      last: discussCountPerPage,
                      before: Base64.encode(cursorName + ':' + index),
                      first: undefined,
                      after: undefined
                    };
                  }

                  props.getDiscussComment(input).then((res: any) => {
                    if (res.errors) {
                      message.error('获取评论列表失败!');
                    } else {
                      client.writeData({ data: { discussCommentPage: e } });
                    }
                  });
                }
              } as any)
        }
        itemLayout='horizontal'
        dataSource={commentList}
        renderItem={(item: any) => (
          <List.Item>
            <div className='new-routes-study-courseDiscussion-comment-wrapper new-routes-study-courseDiscussion-2611'>
              <div className='new-routes-study-courseDiscussion-2621'>
                <Avatar
                  className='new-routes-study-courseDiscussion-2630'
                  src={item.poster.avatar}
                  size={'default'}
                />
                <div className='new-routes-study-courseDiscussion-2644'>
                  <div className='new-routes-study-courseDiscussion-2657'>
                    <div className='new-routes-study-courseDiscussion-2661'>
                      <div className='new-routes-study-courseDiscussion-2679'>
                        {item.poster.nickname}
                      </div>
                      <div className='new-routes-study-courseDiscussion-2682'>
                        {moment(item.insertedAt).isSame(
                          moment().startOf('day'),
                          'd'
                        )
                          ? `今天 ${item.insertedAt.split(' ')[1]}`
                          : item.insertedAt}
                      </div>
                    </div>
                    {item.poster.id === userInfo.userid ? (
                      <img
                        src={deleteIcon}
                        className='new-routes-study-courseDiscussion-2694'
                        onClick={() => {
                          Modal3CallbackFunc.callback = () => {
                            let input: any = {
                              commentId: Base64.decode(item.id).split(
                                'CardComment:'
                              )[1],
                              coursePackageId: Base64.decode(
                                discussCoursePackageId
                              ).split('CoursePackage:')[1],
                              discussionId: Base64.decode(
                                discussDetailId
                              ).split('Discussion:')[1]
                            };
                            removeCommentMutation({
                              variables: { input: input }
                            }).then((res: any) => {
                              if (res.data.removeCommentFromDiscussion.errors) {
                                message.error(
                                  res.data.removeCommentFromDiscussion.errors[0]
                                    .message
                                );
                              } else {
                                message.success('删除成功!');
                                client.writeData({
                                  data: {
                                    modal3: {
                                      show: false,
                                      title: '',
                                      content: '',
                                      __typename: 'Modal3'
                                    }
                                  }
                                });
                                props
                                  .getDiscussComment({
                                    discussID: discussDetailId,
                                    first: discussCountPerPage,
                                    last: undefined,
                                    before: undefined,
                                    after: undefined
                                  })
                                  .then((res: any) => {
                                    if (res.errors) {
                                      message.error('获取评论列表失败!');
                                    } else {
                                      client.writeData({
                                        data: { discussCommentPage: 1 }
                                      });
                                    }
                                  });
                              }
                            });
                          };

                          client.writeData({
                            data: {
                              modal3: {
                                show: true,
                                title: '',
                                content: '确定要删除评论吗？',
                                __typename: 'Modal3'
                              }
                            }
                          });
                        }}
                      />
                    ) : null}
                  </div>
                  <MyReactMarkdown
                    className='new-routes-study-courseDiscussion-2788'
                    source={item.content}
                  />
                </div>
              </div>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default DiscussCheckList;
