import * as React from 'react';
import './styles.css';
import 'react-diff-view/style/index.css';

//引入的库组件
import { Layout, Breadcrumb } from 'antd';
import { parseDiff, Diff, Hunk } from 'react-diff-view';
import { diffLines, formatLines } from 'unidiff';

//引入的其他页面组件
import { createFilenameSelector } from './selector';
import ItemRender from '../ItemRender';
import { getGitDiffType } from './git';
import tokenize from './tokenize';

//graphql查询

//引入的资源图片

const EMPTY_HUNKS = [];

const DiffViewShowAll = props => {
  const { currentCommit, lastCommit, showAll, showType } = props;
  const computeFilename = createFilenameSelector();
  // const files = parseDiff(currentCommit.diff);
  // const titleHunks = currentCommit.assignment.location;

  const [{ type, hunks }, setDiff] = React.useState('');
  React.useEffect(() => {
    if (lastCommit !== null) {
      const diffText = formatLines(
        diffLines(lastCommit.content, currentCommit.content),
        { context: showAll ? 9999999 : 3 }
      );
      const [diff] = parseDiff(diffText, { nearbySequences: 'zip' });
      setDiff(diff);
    }
  }, [currentCommit, lastCommit, showAll]);
  const renderToken = (token, defaultRender, i) => {
    switch (token.type) {
      case 'space':
        console.log(token);
        return (
          <span key={i} className='space'>
            {token.children &&
              token.children.map((token, i) =>
                renderToken(token, defaultRender, i)
              )}
          </span>
        );
      default:
        return defaultRender(token, i);
    }
  };

  const tokens = React.useMemo(() => tokenize(hunks), [hunks]);

  if (!type || !hunks) {
    return <div />;
  }

  const titleType = getGitDiffType(hunks.type);

  return (
    <div className='routes-course-DiffView-953'>
      <div className='routes-course-DiffView-1534'>
        <div className='routes-course-DiffView-1734'>
          <div className='routes-course-DiffView-1840'>
            <div
              style={{
                backgroundColor: titleType.color,
                color: '#fff',
                marginLeft: 20,
                width: 30,
                height: 17,
                fontSize: 12,
                lineHeight: '17px',
                textAlign: 'center',
                borderRadius: 2
              }}
            >
              {titleType.title}
            </div>
            <div className='routes-course-DiffView-2206'>
              {/* {computeFilename(titleHunks)} */}
              {currentCommit.assignment.location}
            </div>
          </div>
          <Diff
            viewType={showType}
            diffType={type}
            hunks={hunks || EMPTY_HUNKS}
            tokens={tokens}
            renderToken={renderToken}
          >
            {hunks =>
              hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)
            }
          </Diff>
        </div>
      </div>
    </div>
  );
};

export default DiffViewShowAll;
