import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Spin, Breadcrumb, message } from 'antd';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { FormComponentProps } from 'antd/es/form';
import { withRouter } from 'react-router';

//引入的其他页面组件
import ItemRender from '../CommonComponents/ItemRender';
import DiffView from '../CommonComponents/DiffView';

//graphql查询

//引入的资源图片
import back from '../../assets/icons/back.png';
import root from '../../assets/icons/file_medium_root.png';

interface Props extends FormComponentProps {
  props: any;
}

const GET_DIFF_CHANGES_INFO = gql`
  {
    diffChangesStr @client
    isMerging @client
    packageTitle @client
    selectedFileId @client
  }
`;

const routes = [
  {
    path: '//edu',
    breadcrumbName: '全部文件'
  },
  {
    path: '',
    breadcrumbName: '我协作的'
  },
  {
    path: '',
    breadcrumbName: '查看变更内容'
  }
];

const EduManagerPackageDiffPage = (props: any) => {
  const { data: diffChangesInfo } = useQuery(GET_DIFF_CHANGES_INFO);
  const { diffChangesStr, isMerging, packageTitle } = diffChangesInfo;
  const { form } = props;

  return (
    <Spin spinning={isMerging} size={'large'}>
      <div className='routes-study-SubMerge-1015'>
        <div className='routes-study-SubMerge-1583'>
          <div className='routes-study-SubMerge-1606'>
            <div className='routes-study-SubMerge-1623'>
              <div
                onClick={() => {
                  props.history.push('/edu');
                }}
                className='routes-study-SubMerge-1802'
              >
                <img src={back} />
                <span className='routes-study-SubMerge-1869'>返回</span>
              </div>
              <div className='routes-study-SubMerge-1928' />
              <Breadcrumb
                separator={'›'}
                itemRender={ItemRender}
                routes={routes}
                className='routes-study-SubMerge-2088'
              />
            </div>

            {diffChangesStr === '' || packageTitle === '' ? null : (
              <div className='routes-study-SubMerge-2150'>
                <img src={root} />
                <div className='routes-study-SubMerge-2216'>{packageTitle}</div>
              </div>
            )}
          </div>

          {diffChangesStr === '' || packageTitle === '' ? null : (
            <div className='routes-study-SubMerge-4582'>
              <DiffView
                currentCommit={{
                  diff: diffChangesStr.replace(/medium\n/g, '')
                }}
                lastCommit={null}
                showAll={false}
                showType={'split'}
              />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

const EduManagerPackageDiffWrapper = Form.create<Props>()(
  withRouter(EduManagerPackageDiffPage)
);

export default EduManagerPackageDiffWrapper;
