/**
 * @description  查看其他同学的答案
 * @author 阿礼
 * @since 2019-9-13
 */
import React, { useEffect, useState } from 'react';
import './style.less';

import { useQuery, useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
// 自定义函数组件
import TopLinkAndTitle from './../../../../components/TopLinkAndTitle';
import Students from './Students';
import PostComment from './../PostComment';
import Comments from './../Comments';
import OtherSingleAnswer from './../OtherSingleAnswer';

import mobileMenuIcon from '../../../../assets/icons/mobile-menu.png';

import {
  useCheckOtherAnswersQuery,
  useCheckSingleAnswerQuery,
  useGetCourseIdQuery,
  useGetAnswerCommitsQuery,
  useGetMyAnswerIdQuery,
  AssignmentOrder
} from '../../../../generated/graphql.tsx';

const GET_LOGIN_INFO = gql`
  {
    isLoggedIn @client
  }
`;
const GET_STUDENTSANSWER = gql`
  {
    comments @client
    otherAnswers @client
    nickname @client
    avatar @client
    postTime @client
    answerContent @client

    answerId @client
    assignmentName @client
    isShowMobileDirectory @client
    courseId @client
  }
`;

const SET_COMMIT = gql`
  {
    homeworkCommits @client {
      commitHash
      commitedAt
    }
  }
`;
const WraperStudent = props => {
  const {
    data: { isLoggedIn }
  } = useQuery(GET_LOGIN_INFO);
  if (isLoggedIn) {
    return <StudentAnswers props={props} />;
  } else {
    return <div></div>;
  }
};
const StudentAnswers = props => {
  // 获取浏览上的地址
  const routerSpilt = window.location.href.split('/');
  const client = new useApolloClient();

  let id = window.location.pathname.split('/')[4];
  let selectAnswerKey = window.location.pathname.split('/')[5];
  id = window.btoa(`Assignment:${id}`);
  selectAnswerKey = window.btoa(`Assignment:${selectAnswerKey}`);
  const {
    data: { isLoggedIn }
  } = useQuery(GET_LOGIN_INFO);
  const {
    data: {
      comments,
      otherAnswers,
      answerContent,
      assignmentName,
      answerId,
      isShowMobileDirectory,
      courseId
    }
  } = useQuery(GET_STUDENTSANSWER);
  const { refetch: getSingleContent } = useCheckSingleAnswerQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });

  const { refetch: getCourseId } = useGetCourseIdQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });
  const { refetch: getCommits } = useGetAnswerCommitsQuery({
    skip: true,
    fetchPolicy: 'no-cache'
  });

  const { refetch: getOtherAnswers } = useCheckOtherAnswersQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });

  const [pageInfo, setPageInfo] = useState({
    startCursor: '',
    hasPreviousPage: false,
    hasNextPage: true,
    endCursor: '',
    count: 5000,
    countPerPage: 8,
    pageCount: 1,
    page: 1
  });

  const getOtherAnswersList = (variables, page) => {
    variables = {
      ...variables,
      id: id
    };
    getOtherAnswers(variables).then(res => {
      try {
        const data = res.data;
        const title = data.node.name;
        const otherAnswers = data.node.answers.edges.map(item => item.node);
        let count = data.node.answers.count;
        let pageInfoData = data.node.answers.pageInfo;
        let pageInfoTemp = {
          ...pageInfoData,
          count: count,
          countPerPage: pageInfo.countPerPage,
          pageCount: Math.ceil(count / pageInfo.countPerPage),
          page: page
        };
        setPageInfo(pageInfoTemp);

        getCourseId({
          id: window.btoa(`Course:short_url:${routerSpilt[4]}`)
        }).then(res => {
          let courseId = res.data && res.data.node.id;
          let coursePackageId = '';

          if (res.data) {
            res.data.node.coursesPackages.filter(item => {
              if (item.packageTag === 'ASSIGNMENT') {
                coursePackageId = item.id;
              }
            });
            client.writeData({
              data: { courseId, coursePackageAssignmentId: coursePackageId }
            });
          }

          // 初始化第一个数据、选中、内容、评论、评论数展示
          if (routerSpilt.length === 8) {
            const answerId = window.btoa(`Assignment:${routerSpilt[7]}`);
            getSingleContent({ id: answerId, courseId }).then(res => {
              if (res.data) {
                const data = res.data.node;

                const comments =
                  data.comments.edges.length > 0
                    ? data.comments.edges.map(item => item.node)
                    : [];
                const count = data.commentsCount;

                client.writeQuery({
                  query: GET_STUDENTSANSWER,
                  data: {
                    // 每个作业的状态
                    otherAnswers,
                    answerContent: data.content,
                    nickname: data.author.nickname,
                    avatar: data.author.avatar,
                    postTime: data.insertedAt,
                    comments: comments,
                    commentsCount: count,
                    answerId: data.id,
                    assignmentName: title
                  }
                });

                getCommits({ id: answerId }).then(res => {
                  if (res.data.node.commits) {
                    client.writeQuery({
                      query: SET_COMMIT,
                      data: { homeworkCommits: res.data.node.commits }
                    });
                  } else {
                    client.writeData({ data: { homeworkCommits: [] } });
                  }
                });
              }
            });
          } else {
            const firstAnswer = otherAnswers[0];
            getSingleContent({ id: firstAnswer.id, courseId }).then(res => {
              if (res.data) {
                const data = res.data.node;
                const comments =
                  data.comments.edges.length > 0
                    ? data.comments.edges.map(item => item.node)
                    : [];
                const count = data.commentsCount;

                client.writeQuery({
                  query: GET_STUDENTSANSWER,
                  data: {
                    // 每个作业的状态
                    otherAnswers,
                    answerContent: data.content,
                    nickname: data.author.nickname,
                    avatar: data.author.avatar,
                    postTime: data.insertedAt,
                    comments: comments,
                    commentsCount: count,
                    answerId: data.id,
                    assignmentName: title
                  }
                });

                getCommits({ id: firstAnswer.id }).then(res => {
                  if (res.data.node.commits) {
                    client.writeQuery({
                      query: SET_COMMIT,
                      data: { homeworkCommits: res.data.node.commits }
                    });
                  } else {
                    client.writeData({ data: { homeworkCommits: [] } });
                  }
                });
              }
            });
          }

          client.writeData({ data: { courseId } });
        });
      } catch (err) {}
    });
  };

  const { data, error, loading } = useGetMyAnswerIdQuery({
    fetchPolicy: 'network-only',
    variables: { id: id },
    onCompleted(data) {
      let answers = data.node.answers.edges.map(item => item.node);
      if (answers && answers.length > 0) {
        let myAnswer = answers[0];
        if (myAnswer.id === selectAnswerKey) {
          let pathname = window.location.pathname;
          // 判断是否是自己的链接，如果是就跳转
          if (pathname.split('/').length === 6) {
            const clipOne = pathname.replace(`/${pathname.split('/')[5]}`, '');
            props.props.history.push(clipOne);
            return;
          }
        }
      }
      getOtherAnswersList(
        {
          first: pageInfo.countPerPage,
          order: AssignmentOrder.DescInsertedAt
        },
        1
      );
    }
  });

  const [update, setUpdate] = useState(true);

  // 修改一个分享链接打开后，点击左侧列表为404的Bug
  const href = window.location.href.split('/')[4];
  window.sessionStorage.setItem('shortUrl', href);

  const [target, setTarget] = useState('');
  let toTarget;
  useEffect(() => {
    if (isLoggedIn) {
      client.writeData({ data: { isShowHeader: false, isShowFooter: false } });

      if (routerSpilt.length === 8) {
        toTarget = routerSpilt[6];
        setTarget(toTarget);
      }
    }
  }, []);

  if (!isLoggedIn) {
    return null;
  }
  if (loading) {
    return null;
  }

  // 获取课程代码

  return (
    <div className='check-other-answers'>
      {/* 标题 返回路由 */}
      <div className='top-link-title'>
        <img
          className='new-routes-study-courseHomework-otherHomework-12561'
          src={mobileMenuIcon}
          alt='mobileMenuIcon'
          onClick={() => {
            client.writeData({
              data: { isShowMobileDirectory: !isShowMobileDirectory }
            });
          }}
        />
        <TopLinkAndTitle
          to={`/stu/${
            window.location.pathname.split('/')[2]
          }/homework/${target}`}
          title={assignmentName}
        />
      </div>
      {/* 主内容 */}
      <div className='main-content'>
        {/* 其他学员的展示列表 */}
        {isShowMobileDirectory && (
          <div className='new-routes-study-courseHomework-otherHomework-15886' />
        )}
        <div
          className={
            isShowMobileDirectory ? 'left-directory-mobile' : 'left-directory'
          }
        >
          <Students
            otherAnswers={otherAnswers}
            setUpdate={setUpdate}
            getCommits={getCommits}
            pageInfo={pageInfo}
            getOtherAnswersList={getOtherAnswersList}
          />
        </div>
        {/* 一个同学的答案和其他同学的评论答案 */}
        <div className='new-routes-study-courseHomework-otherHomework-content-3038'>
          <div className='right-content'>
            <OtherSingleAnswer />
            {update && <PostComment />}
            {comments.length > 0 && <Comments />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(WraperStudent);
