import React, { FC, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  useSubscription,
  useQuery,
  useMutation,
  useApolloClient
} from '@apollo/react-hooks';
import { notification } from 'antd';
import { MESSAGE_SUBSCRIPTION, GET_MESSAGE_COUNT } from './query';
import { query } from './cache';
import { UPDATE_MESSAGE } from 'pages/Msg/query';
import { GET_PARENT_ID } from 'pages/Msg/query';

// 弹窗 message title
const title: any = {
  COMMENT: '评论',
  DIRECTIONAL: '通知',
  COLLABORATIVE: '申请'
};

// 跳转选项
const option: any = {
  discussion: 'discussion',
  assignment: 'homework'
};

const Subscription: FC<RouteComponentProps> = ({ history }) => {
  const client = useApolloClient();

  // 获取当前消息条数
  const { data: cache } = useQuery(query);
  const { all } = cache.badge;

  // 订阅消息
  const { data } = useSubscription(MESSAGE_SUBSCRIPTION);

  const [updateMessage] = useMutation(UPDATE_MESSAGE);

  const { refetch: refetchPid } = useQuery(GET_PARENT_ID, {
    skip: true
  });
  const { data: count } = useQuery(GET_MESSAGE_COUNT, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      try {
        // 获取消息内容
        const { category, content, id, info } = data.newMessage;
        const { card_id, course_short_url, tag } = info.comment;

        // 消息 + 1
        client.writeData({
          data: {
            badge: { all: all + 1, __typename: 'Badge' }
          }
        });

        // 弹窗提醒
        notification.open({
          message: `您有新的${title[category]}消息`,
          description: content,
          style: { cursor: 'pointer' },
          onClick: () => {
            if (category === 'COMMENT') {
              // 已读
              updateMessage({
                variables: {
                  input: { id: window.atob(id).split('Message:')[1] }
                }
              });
              // 消息 - 1
              client.writeData({
                data: {
                  badge: { all: all - 1 < 0 ? 0 : all - 1, __typename: 'Badge' }
                }
              });
              if (tag === 'assignment') {
                refetchPid({ id: window.btoa(`Assignment:${card_id}`) }).then(
                  res => {
                    const id = res.data.node.pid;
                    history.push(
                      `/stu/${course_short_url}/${option[tag]}/${id}`
                    );
                  }
                );
              } else {
                history.push(
                  `/stu/${course_short_url}/${option[tag]}/${card_id}`
                );
              }
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [data]);

  useEffect(() => {
    if (count && Object.keys(count).length !== 0) {
      try {
        client.writeData({
          data: {
            badge: { all: count.me.messages.unreadCount, __typename: 'Badge' }
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [count]);

  return null;
};

export default withRouter(Subscription);
