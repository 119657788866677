import * as React from 'react';
import './styles.css';

//引入的库组件
import { withRouter } from 'react-router';

//引入的其他页面组件
import CourseEditPage from './CourseEditPage';

//graphql查询
import { useGetCourseInfoInEditQuery, useGetCategoryInfoInEditQuery } from '../../generated/graphql';

//引入的资源图片


const ModifyCourseContainer = (props: any) => {
    const { data, error, loading } = useGetCourseInfoInEditQuery({variables: {id: props.editCourseId}, fetchPolicy: "no-cache"});
    const { data: categoryData, error: categoryError, loading: categoryLoading } = useGetCategoryInfoInEditQuery({fetchPolicy: "no-cache"});

    if(error || loading || !data || categoryError || categoryLoading || !categoryData) {
        return <div />;
    }

    return <CourseEditPage courseData={data} props={props} categoryData={categoryData} />;
}

export default withRouter(ModifyCourseContainer);
