import gql from 'graphql-tag';

export const GET_USER_ROLE = gql`
  {
    userInfo @client {
      role
    }
  }
`;

export const query = gql`
  {
    badge @client {
      all
    }
  }
`;
