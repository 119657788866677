import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Layout, Menu, message, Checkbox } from 'antd';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';

//引入的其他页面组件
import { File } from './File';

//graphql查询
import gql from 'graphql-tag';

//引入的资源图片
import warning from '../../../assets/icons/file_status_warning.png';
import newIcon from '../../../assets/icons/file_status_new.png';

const GET_FILE_CARD_INFO = gql`
  {
    checkAll @client {
      cancel
      all
      on
    }
  }
`;

const FileCard = (props: any) => {
  const client = useApolloClient();
  const { data: fileCardInfoData } = useQuery(GET_FILE_CARD_INFO);
  const { cover, title, cardKey, onSelect, disabled, collaborativeState, isOwner } = props;

                       

  const [hover, setHover] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  if(!disabled) {
    if(fileCardInfoData.checkAll.all) {
      !checked && setChecked(true);
    }else if(fileCardInfoData.checkAll.cancel) {
      checked && setChecked(false);
    }
  }

  let collaborativeSrc = "";
  if(!isOwner) {
    if(collaborativeState === "CREATED") {
      collaborativeSrc = newIcon;
    }
    if(collaborativeState === "MODIFIED") {
      collaborativeSrc = warning;
    }
  }

  return (
    <File
      style={{ border: checked || hover ? '1px solid #177E89' : 0 }}
      hoverBoder={hover || checked ? '1px solid #177E89' : 0}
      onMouseLeave={() => { if (!checked) { setHover(false); } }}
      onMouseEnter={() => { setHover(true) }}
    >
      {checked || hover ? (<File.Checkbox
        checked={checked}
        disabled={disabled}
        key={cardKey}
        value={cardKey}
        onChange={(e: any) => {
          if (e.target.checked) {
            client.writeData({data: {checkAll: { cancel: false, all: false, on: true, __typename: "CheckAll" }}});
            onSelect({ action: e.target.checked, cardKey });
            setHover(true);
            setChecked(true);
          } else {
            onSelect({ action: e.target.checked, cardKey });
            client.writeData({data: {checkAll: { cancel: false, all: false, on: true, __typename: "CheckAll" }}});
            setHover(false);
            setChecked(false);
          }
        }} className="components-course-FileCard-1879"
      />) : (null)}
      <div className="components-course-FileCard-1958">
        <File.Cover src={cover} data-rjs="2" />
        {isOwner || collaborativeSrc === "" ? (null) : (<File.Mark data-rjs="2" src={collaborativeSrc} />
        )}
      </div>
      <File.Title >{title}</File.Title>
    </File>
  );
}

export default FileCard;