const getGitDiffType = (type: string) => {
    switch (type) {
      case 'delete':
        return { title: '删除', color: '#F04134' };
      case 'new':
        return { title: '新增', color: '#00A854' };
      case 'add':
        return { title: '新增', color: '#00A854' };
      case 'modify':
        return { title: '修改', color: '#FFAA00' };
      default:
        return { title: '修改', color: '#FFAA00' };
    }
  }
  
  export { getGitDiffType };