/**
 * @description 学习中心标题和返回【我的学习】界面按钮
 * @author ali
 * @since 19/08/29
 */
import React, { FC } from 'react';
import './style.less';
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { Link, withRouter } from 'react-router-dom';
import gobackIcon from '../../../../assets/v2.1/back.png';
// 移动端的图标
// import gobackIcon from '../../../../assets/icons/mobile-menu.png';
import learningCenter from '../../../../global_variable/learningCenter';
import discusspage from '../../../../global_variable/discusspage';
const CLEARN_DATA = gql`
  {
    coursePackageId @client(always: true)
    coursePackageAssignmentId @client
    discussCoursePackageId @client
    isShowHeader @client
    isShowFooter @client
  }
`;
const Title = props => {
  const client = new useApolloClient();
  function goback() {
    // client.writeQuery({
    //   query: CLEARN_DATA,
    //   data: {
    //     isShowHeader: true,
    //     isShowFooter: true,
    //     coursePackageId: '',
    //     coursePackageAssignmentId: '',
    //     discussCoursePackageId: '',
    //     currentCardId: '',
    //     content: '',
    //     ...learningCenter
    //   }
    // });
    // 把测验卡的属性对象的属性更改为null，
    learningCenter.examAnswerInfo = null;

    client.writeData({
      data: {
        isShowHeader: true,
        isShowFooter: true,
        coursePackageId: '',
        coursePackageAssignmentId: '',
        discussCoursePackageId: '',
        currentCardId: '',
        content: '',

        ...learningCenter,
        ...discusspage
      }
    });
    props.history.push('/stu');
  }

  return (
    <div className='learningHeader-title'>
      <img
        className='icon'
        src={gobackIcon}
        alt='返回学习中心的图标'
        onClick={goback}
      />

      <span className='course-title'>{props.title}</span>
    </div>
  );
};

export default withRouter(Title);
