import gql from 'graphql-tag';

// 未登录
export const GET_COURSE_DETAIL_N = gql`
  query getCourseDetailN($id: ID!) {
    node(id: $id) {
      ... on Course {
        category {
          parent {
            name
          }
        }
        chargingLink
        cover
        description
        id
        instructors
        isEnd
        isFee
        isOnline
        isSelling
        needUserProfile
        price
        registrationBegin
        registrationEnd
        shortUrl
        subTitle
        subscribedCount
        title
      }
    }
  }
`;

// 已登录
export const GET_COURSE_DETAIL_Y = gql`
  query getCourseDetailY($id: ID!) {
    node(id: $id) {
      ... on Course {
        category {
          parent {
            name
          }
        }
        chargingLink
        cover
        description
        id
        instructors
        isEnd
        isFee
        isOnline
        isSelling
        isSubscribed
        needUserProfile
        price
        registrationBegin
        registrationEnd
        shortUrl
        subTitle
        subscribedCount
        title
      }
    }
  }
`;

export const GET_COURSE_SUBSCRIBED = gql`
  query getCourseSubscribed($id: ID!) {
    node(id: $id) {
      ... on Course {
        isSubscribed
      }
    }
  }
`;

export const CREATE_PURCHASE = gql`
  mutation createPurchase($input: CreatePurchaseInput!) {
    createPurchase(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
