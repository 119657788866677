import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button } from 'antd';
import { CollegeCourse } from 'global_variable/home';
import CollegeItem from './CollegeItem';

const Colleges: FC<
  RouteComponentProps & { collegeCourses: CollegeCourse[] }
> = ({ history, collegeCourses }) => (
  <div className='college-list'>
    <div className='college-list-content'>
      {collegeCourses.map(item => (
        <CollegeItem
          key={item.title}
          title={item.title}
          to={item.to}
          courses={item.courses}
        />
      ))}
    </div>
    <div className='college-list-button'>
      <Button
        type='danger'
        size='large'
        onClick={() => history.push('/mkt/courses')}
        style={{
          height: '50px',
          width: '160px',
          fontWeight: 500
        }}
      >
        查看全部课程
      </Button>
    </div>
  </div>
);

export default withRouter(Colleges);
