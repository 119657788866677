import * as React from 'react';
import './styles.css';
import 'react-diff-view/style/index.css';

//引入的库组件
import { Layout, Breadcrumb } from 'antd';
import { parseDiff, Diff, Hunk } from 'react-diff-view';
import { diffLines, formatLines } from 'unidiff';

//引入的其他页面组件
import { createFilenameSelector } from './selector';
import ItemRender from '../ItemRender';
import { getGitDiffType } from './git';

//graphql查询

//引入的资源图片


const DiffViewShowDiff = props => {
  const { currentCommit, lastCommit, showAll, showType } = props;
  const computeFilename = createFilenameSelector();
  const files = parseDiff(currentCommit.diff);

  return (
    <div className='routes-course-DiffView-953'>
      <div className='routes-course-DiffView-1534'>
        {files.map((hunks, i) => {
          const type = getGitDiffType(hunks.type);
          return (
            <div className='routes-course-DiffView-1734' key={'diff:' + i}>
              <div className='routes-course-DiffView-1840'>
                <div
                  style={{
                    backgroundColor: type.color,
                    color: '#fff',
                    marginLeft: 20,
                    width: 30,
                    height: 17,
                    fontSize: 12,
                    lineHeight: '17px',
                    textAlign: 'center',
                    borderRadius: 2
                  }}
                >
                  {type.title}
                </div>
                <div className='routes-course-DiffView-2206'>
                  {computeFilename(hunks)}
                </div>
              </div>
              <Diff
                key={hunks.oldRevision + '-' + hunks.newRevision}
                viewType={props.showType}
                diffType={hunks.type}
                hunks={hunks.hunks}
              >
                {hunks =>
                  hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)
                }
              </Diff>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DiffViewShowDiff;
