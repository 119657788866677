import * as React from 'react';
import './styles.css';

//引入的库组件
import { withRouter } from 'react-router';

//引入的其他页面组件
import VideoCardEditPage from './VideoCardEditPage';

//graphql查询

//引入的资源图片


const CreateVideoCardContainer = (props: any) => {

    return  <VideoCardEditPage props={props} cardInfo={null} />;

    return null;
}

export default withRouter(CreateVideoCardContainer);