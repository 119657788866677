import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Layout, Spin } from 'antd';
import { FormComponentProps } from 'antd/es/form';

//引入的其他页面组件
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import CreateAudioCard from './CreateAudioCard';
import ModifyAudioCard from './ModifyAudioCard';

//graphql查询

//引入的资源图片

const GET_CREATE_COURSE_INFO = gql`
  {
    isEditCard @client(always: true)
  }
`;

const EduManagerCreateAudioCardPageContainer = () => {
    const { data } = useQuery(GET_CREATE_COURSE_INFO);
    const { isEditCard } = data;
  
    if (isEditCard) {
      return <ModifyAudioCard  />;
    } else {
      return <CreateAudioCard  />;
    }
    return null;
  };
  
  export default EduManagerCreateAudioCardPageContainer;