import React, { FC } from 'react';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { query, writeQuery_collegeName, writeQuery_courseName } from '../cache';
import { Card } from 'antd';
import { removalCourse } from '../utils';
import RadioGroup from './RadioGroup';

const Screen: FC<{ value: string }> = ({ value }) => {
  const client = useApolloClient();
  const { data } = useQuery(query);
  const {
    count,
    collegeTypes,
    courseTypes,
    filterCourseTypes,
    collegeName,
    courseName
  } = data.courses;


  return (
    <Card
      className="courses-board"
      title={
        value ? (
          <p className='search-title'>
            共 <span>{count}</span> 个 “ <span>{decodeURI(value)}</span> “
            相关结果
          </p>
        ) : (
          <h3 style={{ color: '#f4404f' }}>全部课程</h3>
        )
      }
      extra={
        <p className='search-title'>
          共 <span>{count}</span> 个结果
        </p>
      }
    >
      <div className='courses-card'>
        <div className='courses-card-conent'>
          {/* <div className='college-types'>
            <div className='types-title'>课程学院</div>
            <RadioGroup
              data={collegeTypes}
              checked={collegeName}
              handleClick={(collegeName, collegeId) => {
                // 过滤课程分类列表
                const courses = filterCourseTypes.filter(
                  (item: any) => item.parent.id === collegeId
                );

                // 是否包含当前课程分类
                const hasCourseTypes = courses.find(
                  (item: any) => item.name === courseName
                );

                client.writeQuery({
                  query: writeQuery_collegeName,
                  data: {
                    courses: {
                      collegeName,
                      courseName:
                        hasCourseTypes || collegeId === '全部'
                          ? courseName
                          : '全部',
                      courseTypes:
                        collegeId === '全部'
                          ? removalCourse(filterCourseTypes)
                          : courses,
                      __typename: 'courses'
                    }
                  }
                });
              }}
            />
          </div> */}
          <div className='courses-types'>
            <div className='types-title'>课程类型</div>
            <RadioGroup
              data={courseTypes}
              checked={courseName}
              handleClick={courseName => {
                client.writeQuery({
                  query: writeQuery_courseName,
                  data: {
                    courses: {
                      courseName,
                      __typename: 'courses'
                    }
                  }
                });
              }}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Screen;
