import React, { FC, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Tabs } from 'antd';
import Message from './components/Message';
import { GET_USER_ROLE } from './cache';
import './style.less';

const { TabPane } = Tabs;

interface ITabs {
  tab: string;
  category: 'COLLABORATIVE' | 'DIRECTIONAL' | 'COMMENT';
}

const tabs: ITabs[] = [
  { tab: '申请', category: 'COLLABORATIVE' },
  { tab: '通知', category: 'DIRECTIONAL' },
  { tab: '评论', category: 'COMMENT' }
];

const Msg: FC = () => {
  // 读取用户权限
  const { data: cache } = useQuery(GET_USER_ROLE);
  const { role } = cache.userInfo;
  // 普通用户没有申请
  if (role === 'USER') delete tabs[0];

  return (
    <div className='msg'>
      <Tabs className='msg-tabs' defaultActiveKey='通知'>
        {tabs.map(item => (
          <TabPane tab={item.tab} key={item.tab}>
            <Message category={item.category} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Msg;
