import React, { Component } from 'react';
import './styles.css';

//引入的库组件
import ContextMenu from 'react-contextmenu/es6/ContextMenu';
import MenuItem from 'react-contextmenu/es6/MenuItem';
import styled from 'styled-components';
import { Base64 } from 'js-base64';
import { message } from 'antd';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { DiffType } from '../../generated/graphql';

//引入的其他页面组件
import ComponentFunction from './ComponentFunction';
import Modal2CallbackFunc from '../CommonComponents/Modals/Modal2CallbackFunc';
import Modal3CallbackFunc from '../CommonComponents/Modals/Modal3CallbackFunc';
import { deleteDataFromTreeData, getDataFromTreeData } from './utils';
import RenameModalCallback from '../RenameModal/RenameModalCallbackFunc';
import CopyModalCallbackFunc from '../CopyModal/CopyModalCallbackFunc';

//graphql查询

//引入的资源图片

const MenuItemDivier = styled.div`
   width: 100%;
   height: 2px;
   border: 1px solid rgba(72,72,72,0.25);
`;

const RIGHT_CLICK_CARD_INFO = gql`
  {
    pMode @client
    searchMode @client
    treeData @client
    cooperationTreeData @client
    selectedFilePackageId @client(always: true)
  }
`;

const RightClickCard = (props) => {
  const client = useApolloClient();
  const { data: RightClickCardInfo } = useQuery(RIGHT_CLICK_CARD_INFO);
  const { pMode, treeData, cooperationTreeData, selectedFilePackageId } = RightClickCardInfo;

  const { pageState, deleteCard, deleteCardFromAllBranches } = props;
  const { getShowFiles, renameCard, diffChanges } = props.pageFunction;
  const { isFileState, isCooState } = pageState;

  return (
      <ContextMenu id='card' className='edu-PackageManager-34465'>
        <MenuItem
          onClick={(e, data) => {
            if (data.type === 1) {
              client.writeData({data: {isCheckPackage: false, card_list: [data.key], role: pMode ? '0' : '1', isEditCard: true}});
              if(data.title.indexOf(".md") != -1) {
                props.props.history.push("/edu/create/textcard");
              }
              if(data.title.indexOf(".video") != -1) {
                props.props.history.push("/edu/create/videocard");
              }
              if(data.title.indexOf(".exam") != -1) {
                props.props.history.push("/edu/create/examcard");
              }
              if(data.title.indexOf(".audio") != -1) {
                props.props.history.push("/edu/create/audiocard");
              }
            } else {
              client.writeData({data: {selectedFileId: data.key, selectCards: [], isCheckPackage: false, checkAll: {cancel: true, all: false, on: false, __typename: 'CheckAll'}}});
              ComponentFunction.onSelectFile([data.key], pMode);
            }
          }}
        >
          打开
        </MenuItem>
        <MenuItemDivier />
        <MenuItem
          onClick={(e, data) => {
            RenameModalCallback.callback = (newName) => {
              renameCard(data.key, newName, selectedFilePackageId);
            }
            client.writeData({data: {isShowRenameModal: true, renameName: data.title}});
          }}
        >
          重命名
        </MenuItem>
        <MenuItem
          className='edu-PackageManager-36066'
          onClick={(e, data) => {
            if (data.pid === 0) {
            } else if (data.type) {
              Modal3CallbackFunc.callback = () => {
                let id = Base64.decode(data.key).split(
                  'Card:'
                )[1];
                let packageId = Base64.decode(selectedFilePackageId).split("Package:")[1];
                deleteCard({variables: { input: { ids: [id], packageId: packageId }}}).then((res) => {
                  client.writeData({ data: { modal3Loading: false } });
                  if (res.data.deleteCards.errors) {
                    if (
                      res.data.deleteCards.errors[0].message.indexOf(
                        'can not delete or move these cards:'
                      ) !== -1
                    ) {
                      message.error('不能删除其他分支共有文件!');
                      return;
                    }
                    message.error('删除失败!');
                    return;
                  }
                  message.success('删除成功!');
                  deleteDataFromTreeData(pMode ? treeData : cooperationTreeData, data.key);
                  client.writeQuery({query: RIGHT_CLICK_CARD_INFO, data: {searchMode: false, treeData: treeData, cooperationTreeData: cooperationTreeData}});
                  getShowFiles(pMode ? treeData : cooperationTreeData, [data.pid], pageState.pageState.page);
                });

                client.writeData({
                  data: {
                    modal3Loading: false,
                    modal3: {
                      show: false,
                      title: '',
                      content: '',
                      __typename: 'Modal3'
                    }
                  }
                });
              };
              client.writeData({
                data: {
                  modal3Loading: false,
                  modal3: {
                    show: true,
                    title: '',
                    content: '确定要删除文件吗?',
                    __typename: 'Modal3'
                  }
                }
              });
            } else {

              Modal2CallbackFunc.callback = () => {
                let id = Base64.decode(data.key).split(
                  'Card:'
                )[1];
                let packageId = Base64.decode(selectedFilePackageId).split("Package:")[1];
                deleteCard({variables: { input: { ids: [id], packageId: packageId }}}).then((res) => {
                  client.writeData({ data: { modal2Loading: false } });
                  if (res.data.deleteCards.errors) {
                    message.error('删除失败!');
                    return;
                  }
                  message.success('删除成功!');
                  deleteDataFromTreeData(pMode ? treeData : cooperationTreeData, data.key);
                  client.writeQuery({query: RIGHT_CLICK_CARD_INFO, data: {searchMode: false, treeData: treeData, cooperationTreeData: cooperationTreeData}});
                  getShowFiles(pMode ? treeData : cooperationTreeData, [data.pid], pageState.pageState.page);
                });

                client.writeData({
                  data: {
                    modal2Loading: false,
                    modal2: {
                      show: false,
                      title: '',
                      content: '',
                      __typename: 'Modal2'
                    }
                  }
                });
              };
              client.writeData({
                data: {
                  modal2Loading: false,
                  modal2: {
                    show: true,
                    title: '确定要删除文件夹吗?',
                    content: '删除文件夹后，文件夹内的所有卡片都会被同时删除。',
                    __typename: 'Modal2'
                  }
                }
              });
            }
          }}
        >
          删除
        </MenuItem>
        {isFileState.isFile && pMode ? (
          <MenuItem
            onClick={(e, data) => {
              Modal2CallbackFunc.callback = () => {
                let id = Base64.decode(data.key).split(
                  'Card:'
                )[1];
                deleteCardFromAllBranches({variables: { input: { id: id }}}).then((res) => {
                  client.writeData({ data: { modal2Loading: false } });
                  if (res.data.deleteCardFromAllBranches.errors) {
                    message.error('删除失败!');
                    return;
                  }
                  message.success('删除成功!');
                  deleteDataFromTreeData(treeData, data.key);
                  client.writeQuery({query: RIGHT_CLICK_CARD_INFO, data: {searchMode: false, treeData: treeData, cooperationTreeData: cooperationTreeData}});
                  getShowFiles(treeData, [data.pid], pageState.pageState.page);
                });

                client.writeData({
                  data: {
                    modal2Loading: false,
                    modal2: {
                      show: false,
                      title: '',
                      content: '',
                      __typename: 'Modal2'
                    }
                  }
                });
              };
              client.writeData({
                data: {
                  modal2Loading: false,
                  modal2: {
                    show: true,
                    title: '确定要从仓库所有分支删除吗？',
                    content: '删除文件后，所有分支的上的同一文件也会被删除。',
                    __typename: 'Modal2'
                  }
                }
              });
            }}
          >
            从分支删除
          </MenuItem>
        ) : null}
        {/* {isFileState.isFile || !pMode ? <MenuItemDivier /> : null} */}
        {isFileState.isFile && pMode ? (
          <div>
            <MenuItem
              onClick={(e, data) => {

                client.writeData({
                  data: {
                    searchMode: false,
                    selectCard: data.title,
                    handlerFileKeys: [data.key],
                    isShowCopyModal: true,
                    isCopy: true
                  }
                });
                CopyModalCallbackFunc.callback = (res) => {
                  if (pMode) {
                    getShowFiles(
                      typeof treeData === 'undefined' ? [] : treeData,
                      [res],
                      1
                    );
                  } else {
                    getShowFiles(
                      typeof cooperationTreeData === 'undefined'
                        ? []
                        : cooperationTreeData,
                      [res],
                      1
                    );
                  }
                }
              }}
            >
              复制到...
            </MenuItem>
            <MenuItem
              onClick={(e, data) => {
                client.writeData({
                  data: {
                    searchMode: false,
                    selectCard: data.title,
                    handlerFileKeys: [data.key],
                    isShowCopyModal: true,
                    isCopy: false
                  }
                });
                CopyModalCallbackFunc.callback = (res) => {
                  if (pMode) {
                    getShowFiles(
                      typeof treeData === 'undefined' ? [] : treeData,
                      [res],
                      1
                    );
                  } else {
                    getShowFiles(
                      typeof cooperationTreeData === 'undefined'
                        ? []
                        : cooperationTreeData,
                      [res],
                      1
                    );
                  }
                }
              }}
            >
              移动到...
            </MenuItem>
            <MenuItemDivier />
          </div>
        ) : null}
        {isFileState.isFile && pMode ? (
          <div>
            <MenuItem
              onClick={(e, data) => {
                client.writeData({data: {copyUrlModal: true, copyUrl: Base64.decode(data.key).split("Card:")[1] }});
              }}
            >
              获取卡片链接
            </MenuItem>
          </div>
        ) : null}
        {/* {isCooState.isCoo ? (
          <div>
            <MenuItem
              onClick={(e, data) => {
                let packageData = getDataFromTreeData(pMode ? treeData : cooperationTreeData, data.key);
                if(isFileState.isFile){
                  diffChanges(data.key, packageData.title, DiffType.BranchSubmitPr);
                }else {

                }
                // dispatch({
                //   type: 'course/toDiffFile',
                //   payload: { key: data.key, type: '0' }
                // });
              }}
            >
              {isFileState.isFile ? '对比文件' : '对比文件夹'}
            </MenuItem>
          </div>
        ) : null} */}
      </ContextMenu>
  );
}

export default RightClickCard;
