import gql from 'graphql-tag';

// 课程内容卡片，收藏时间分类
export const COLLECT_TIME_CONTENT = gql`
  query contentCollect(
    $courseId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $order: CollectionOrder!
    $filter: CollectionFilter
    $matching: String
  ) {
    collections(
      courseId: $courseId
      first: $first
      last: $last
      before: $before
      after: $after
      order: $order
      filter: $filter
      matching: $matching
    ) {
      count
      edges {
        cursor
        node {
          author {
            nickname
            avatar
          }
          commentsCount
          content
          id
          location
          name
          status
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

//作业内容 收藏分类
export const HOMEWORK_COLLECT = gql`
  query homeworkCollect(
    $courseId: ID!
    $first: Int
    $order: CollectionOrder!
    $filter: CollectionFilter
    $after: String
    $before: String
    $last: Int
    $matching: String
  ) {
    collections(
      courseId: $courseId
      first: $first
      order: $order
      filter: $filter
      after: $after
      before: $before
      last: $last
      matching: $matching
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      count
      edges {
        cursor
        node {
          author {
            nickname
            avatar
          }
          parent {
            name
            id
            insertedAt
            questions{
              content
            }
          }
          comments {
            insertedAt
            card {
              name
            }
          }
          commentsCount
          id
          name
        }
      }
    }
  }
`;

//获取讨论的labels
export const GET_DISCUSSION_LABELS = gql`
  query getLabelsInDiscussion {
    labels {
      id
      info
      name
      scenes
    }
  }
`;

// 讨论收藏
export const DISCUSS_COLLECTION = gql`
  query discussionCollection(
    $courseId: ID!
    $first: Int
    $filter: CollectionFilter
    $order: CollectionOrder!
    $after: String
    $before: String
    $last: Int
    $matching: String
  ) {
    collections(
      courseId: $courseId
      first: $first
      filter: $filter
      order: $order
      after: $after
      before: $before
      last: $last
      matching: $matching
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      count
      edges {
        cursor
        node {
          author {
            nickname
            avatar
          }
          insertedAt
          id
          name
          labels {
            info
            name
          }
          commentsCount
        }
      }
    }
  }
`;

//讨论查询接口
export const GET_SINGLE_DISCUSSION_DATA = gql`
  query getSingleDiscussionData($id: ID!, $courseId: ID!) {
    node(id: $id) {
      ... on Discussion {
        author {
          avatar
          nickname
        }
        comments(first: 1000) {
          edges {
            node {
              insertedAt
              id
              poster {
                nickname
                avatar
                id
              }
              content
            }
          }
        }
        commentsCount
        content
        name
        id
        insertedAt
        isCollected(courseId: $courseId)
        labels {
          id
          info
          name
          scenes
        }
      }
    }
  }
`;

// 添加讨论评论
export const POST_COMMENT_DISCUSSION = gql`
  mutation postCommentToDiscuss($input: AddCommentToDiscussionInput!) {
    addCommentToDiscussion(input: $input) {
      discussion {
        comments(first: 100) {
          edges {
            node {
              content
              id
              insertedAt
              poster {
                nickname
                avatar
                id
              }
            }
          }
        }
      }
    }
  }
`;

// 分页的卡片的下一页查询接口
