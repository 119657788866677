interface CollegeDetail {
  collegesCourses: CollegesCourses[];
  __typename: 'collegeDetail';
}

const collegeDetail: CollegeDetail = {
  collegesCourses: [],
  __typename: 'collegeDetail'
};

export default collegeDetail;

export interface CollegesCourses {
  name: string;
}
