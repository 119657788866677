import React, { Component } from 'react';
import { Button } from 'antd';
import ReactMarkdown from 'react-markdown';

class MyReactMarkdown extends Component {
  renderBlockquote(props) {
    return (
      <blockquote
        {...props}
        style={{
          borderLeft: '.25em solid #dfe2e5',
          padding: '0 1em',
          color: '#6a737d'
        }}
      />
    );
  }
  renderPraphgraph(props) {
    return (
      <pre>
        <p
          {...props}
          style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
        ></p>
      </pre>
    );
  }
  renderLink(props) {
    return <a {...props} target='_blank' alt='tu'></a>;
  }
  renderImage(props) {
    return <img {...props} style={{ maxWidth: '100%' }} />;
  }

  renderCode(props) {
    return (
      <pre style={{ whiteSpace: 'pre-wrap', background: '#f7f7f7' }}>
        <code>{props.value}</code>
      </pre>
    );
  }

  renderTable(props) {
    return (
      <table
        {...props}
        style={{ borderCollapse: 'collapse', borderSpacing: 1 }}
      />
    );
  }

  renderUl(props) {
    return (
      <ul
        {...props}
        style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
      />
    );
  }
  renderP(props) {
    return (
      <ul
        {...props}
        style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
      />
    );
  }

  render() {
    const { source, className } = this.props;

    return (
      <ReactMarkdown
        className={className}
        source={source}
        skipHtml={false}
        escapeHtml={false}
        renderers={{
          image: this.renderImage,
          code: this.renderCode,
          ul: this.renderUl,
          paragraph: this.renderPraphgraph,
          link: this.renderLink,
          blockquote: this.renderBlockquote
        }}
      />
    );
  }
}

export default MyReactMarkdown;
