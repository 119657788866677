/**
 * @description 学员评论内容展示
 * @author 阿礼
 * @since  2019-9-14
 */

import React, { useEffect } from 'react';
import './style.less';

import { List, Avatar, Icon, message } from 'antd';
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import {
  useDeleteCommentMutation,
  useAddCommentToAssignmentMutation,
  useRemoveCommentFromAssignmentMutation
} from './../../../../generated/graphql.tsx';

import Modal3CallbackFunc from '../../../CommonComponents/Modals/Modal3CallbackFunc';
import MyReactMarkdown from '../../../CommonComponents/MyReactMarkdown';
import deleteIcon from '../../../../assets/v2.1/delete.png';
const GET_COMMENTS = gql`
  {
    questionId @client
    coursePackageAssignmentId @client
    comments @client
    answerId @client
    otherAnswers @client
    userInfo @client {
      head_img
      username
      userid
    }
  }
`;
const Comments = props => {
  const client = new useApolloClient();
  const {
    data: {
      comments,
      questionId,
      coursePackageAssignmentId,
      answerId,
      userInfo,
      otherAnswers
    },
    loading,
    error
  } = useQuery(GET_COMMENTS);

  // const [deleteComment] = useDeleteCommentMutation({ skip: true });
  const [deleteComment] = useRemoveCommentFromAssignmentMutation({
    skip: true
  });

  if (loading) {
    return null;
  }

  return (
    <div className='assignment-comments'>
      {comments.length > 0 ? (
        <List
          itemLayout={'vertical'}
          dataSource={comments}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.poster.avatar} />}
                title={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span className='comment-nickname'>
                      {item.poster.nickname}
                    </span>
                    {/* 自己是可以删除自己的评论 */}
                    {userInfo.userid === item.poster.id && (
                      <img
                        className='comment-delete'
                        src={deleteIcon}
                        onClick={() => {
                          Modal3CallbackFunc.callback = () => {
                            const id = window
                              .atob(item.id)
                              .split('CardComment:')[1];
                            const coursePackageId = window
                              .atob(coursePackageAssignmentId)
                              .split('CoursePackage:')[1];
                            const myAnswerId = window
                              .atob(answerId)
                              .split('Assignment:')[1];

                            deleteComment({
                              variables: {
                                input: {
                                  assignmentId: myAnswerId,
                                  coursePackageId: Number(coursePackageId),
                                  commentId: Number(id)
                                }
                              }
                            }).then(res => {
                              const edges =
                                res.data.removeCommentFromAssignment.assignment
                                  .comments.edges;
                              const comments = edges.map(item => item.node);

                              otherAnswers.map(item => {
                                if (item.id === answerId) {
                                  item.commentsCount = comments.length;
                                }
                              });
                              client.writeQuery({
                                query: GET_COMMENTS,
                                data: { comments: comments, otherAnswers }
                              });

                              message.success('删除成功!');
                              client.writeData({
                                data: {
                                  modal3: {
                                    show: false,
                                    title: '',
                                    content: '',
                                    __typename: 'Modal3'
                                  }
                                }
                              });
                            });
                          };

                          client.writeData({
                            data: {
                              modal3: {
                                show: true,
                                title: '',
                                content: '确定要删除评论吗？',
                                __typename: 'Modal3'
                              }
                            }
                          });
                        }}
                      />
                    )}
                  </div>
                }
                description={
                  <span style={{ fontSize: '9px' }}>{item.insertedAt}</span>
                }
              />

              <MyReactMarkdown
                source={item.content}
                // className='new-routes-study-courseHomework-2709'
              />
            </List.Item>
          )}
        ></List>
      ) : null}
    </div>
  );
};

export default Comments;
