import React, { Component } from 'react';

import {
  Layout,
  Input,
  Button,
  List,
  Avatar,
  Form,
  Upload,
  message
} from 'antd';

import MDEditor from '../../CommonComponents/MDEditor/MDEditor';
import MDEditor_v2 from '../../CommonComponents/MDEditor/MDEditor_v2';
import homework from '../../../assets/icons/homework.png';
import { Comment } from '../../CommonComponents/list/comment';
import edit from '../../../assets/icons/edit.png';
import moment from 'moment/moment';
import uploadImg from '../../../assets/icons/import.png';
// import MarkdownView from '../../components/markdown/MarkdownView';
// import { checkMDContent } from '../../utils/checkForm';
import { checkMDContent } from '../../../utils/checkForm';
// import IconButton from '../../components/common/iconButton';
import {
  useGetSingleAssignmentQuery,
  useCheckSingleAnswerQuery,
  useAddCommentToSingleAssignmentMutation,
  useRemoveCommentFromAssignmentMutation
} from '../../../generated/graphql.tsx';
// import { baseURL } from '../../utils/url';
import copy from 'copy-to-clipboard/index';
// 图片
import share from '../../../assets/icons/share.png';
import share_hover from '../../../assets/icons/share_hover.png';
import * as Showdown from 'showdown';

import { useQuery, useApolloClient } from '@apollo/react-hooks';
// import MyReactMarkdown from '../../components/MyReactMarkdown.js';
import MyReactMarkdown from '../../CommonComponents/MyReactMarkdown';
// import { changeToMarkdownFormat } from '../../utils/index';
import { changeToMarkdownFormat } from '../../../utils/index.js';
import cross from '../../../assets/icons/cross.png';
import left from '../../../assets/icons/arrow_left_circle.png';
import right from '../../../assets/icons/arrow_right_circle.png';
import favorite from '../../../assets/icons/favorite.png';
import favorite_active from '../../../assets/icons/favorite_active.png';
import deleteIcon from '../../../assets/v2.1/delete.png';
import { handleCardContent } from './../../../utils/handleCardContent';
import gql from 'graphql-tag';
import { Base64 } from 'js-base64';
import Modal3CallbackFunc from '../../CommonComponents/Modals/Modal3CallbackFunc';
import timeLocalStorage from '../../../utils/TimeLocalStorage';

const formLayout = { wrapperCol: { span: 24 } };
const TextArea = Input.TextArea;

// import { handleCardContent } from './../../../utils/handleCardContent';

const GET_LIST = gql`
  {
    collectionShowData @client(always: true)
    collectionHomeworkDetailInfo @client
    commentList @client
    collectionDetailIndex @client
    coursePackageAssignmentId @client
    courseId @client
  }
`;

const SEND_HOMEWORK_DATA = gql`
  {
    commentList @client
    collectionHomeworkDetailInfo @client
    collectionDetailIndex @client
  }
`;

// 组件导出
const CollectionHomework = props => {
  // return Form.create()(CollectionDetailHomework);
  const client = useApolloClient();
  const {
    data: {
      collectionShowData,
      collectionHomeworkDetailInfo,
      commentList,
      collectionDetailIndex,
      coursePackageAssignmentId,
      courseId
    },
    loading
  } = useQuery(GET_LIST);
  const { refetch: getSingleHomeworkContent } = useGetSingleAssignmentQuery({
    skip: true
  });
  const { refetch: getHomeworkAnswerContent } = useCheckSingleAnswerQuery({
    skip: true
  });
  const [postComment] = useAddCommentToSingleAssignmentMutation({
    skip: true
  });
  const [deleteComment] = useRemoveCommentFromAssignmentMutation();

  if (loading) {
    return null;
  }
  const HomeWorkCollection = Form.create()(CollectionDetailHomework);
  return (
    <HomeWorkCollection
      collectionShowData={collectionShowData}
      commentList={commentList}
      collectionHomeworkDetailInfo={collectionHomeworkDetailInfo}
      collectionDetailIndex={collectionDetailIndex}
      getSingleHomeworkContent={getSingleHomeworkContent}
      getHomeworkAnswerContent={getHomeworkAnswerContent}
      client={client}
      postComment={postComment}
      coursePackageAssignmentId={coursePackageAssignmentId}
      courseId={courseId}
      deleteComment={deleteComment}
    />
  );
};

class CollectionDetailHomework extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeworkAnswer: '',
      homeworkComment: '',
      commentPage: 1,
      a: '1',
      collapse: true,
      importContent: '',
      comLoading: false,

      isUseMarkdownEditForDis: false,
      discussContent: ''
    };
  }

  onSubmitAnswer() {
    const {
      selectedHomeworkKey,
      selectedHomeworkPid,
      dispatch,
      form,
      isModifyAnswer,
      answer
    } = this.props;
    form.validateFieldsAndScroll(['homeworkAnswer'], (errors, values) => {
      if (errors) {
        return;
      }
      if (isModifyAnswer) {
        dispatch({
          type: 'course/modifyAnswer',
          payload: {
            key: answer.aw_key,
            content: values.homeworkAnswer.content.markdown,
            file_key: selectedHomeworkKey
          }
        });
      } else {
        dispatch({
          type: 'course/submitHomework',
          payload: {
            key: selectedHomeworkKey,
            pid: selectedHomeworkPid,
            content: values.homeworkAnswer.content.markdown,
            callback: () => {
              this.props.form.resetFields();
            }
          }
        });
      }
    });
  }

  onSubmitComment = answerId => {
    const {
      dispatch,
      userInfo,
      shortUrl,
      collectionHomeworkDetailInfo,
      HomeWorkCollection,
      coursePackageAssignmentId,
      postComment,
      client
    } = this.props;

    const { setFieldsValue, getFieldDecorator } = this.props.form;
    const { isUseMarkdownEditForDis, discussContent, comLoading } = this.state;
    let answer = collectionHomeworkDetailInfo.answerInfo;

    if (isUseMarkdownEditForDis) {
      this.props.form.validateFieldsAndScroll(['comment'], (errors, values) => {
        if (errors) {
          return;
        }
        const coursePackageId = window
          .atob(coursePackageAssignmentId)
          .split('CoursePackage:')[1];

        const myAnswerId = window.atob(answerId).split('Collection:')[1];

        postComment({
          variables: {
            input: {
              assignmentId: myAnswerId,
              coursePackageId: coursePackageId,
              comment: {
                content: values.comment.content.markdown,
                // cardId: myAnswerId,
                atUserIds: []
              }
            }
          }
        }).then(res => {
          const edges =
            res.data.addCommentToAssignment.assignment.comments.edges;
          const comments = edges.map(item => item.node);

          client.writeQuery({
            query: SEND_HOMEWORK_DATA,
            data: { commentList: comments }
          });
        });
        //增加评论的查询接口，接受到数据后，更新到commentList上

        // 注释以下代码
        // this.setState({ comLoading: true });
        // console.log(values);
        // this.props.form.resetFields();
        // this.resetCommentsList();
        // dispatch({
        //   type: 'course/commitComment',
        //   payload: {
        //     key: answer.id,
        //     content: values.comment.content.markdown,
        //     page: 1,
        //     count: 20,
        //     callback: () => {
        //       this.setState({ commentPage: 1, comLoading: false });
        //       this.props.form.resetFields();
        //       this.resetCommentsList();
        //     }
        //   }
        // });
      });
    } else {
      if (!comLoading && discussContent.trim() !== '') {
        const coursePackageId = window
          .atob(coursePackageAssignmentId)
          .split('CoursePackage:')[1];

        const myAnswerId = window.atob(answerId).split('Collection:')[1];

        postComment({
          variables: {
            input: {
              assignmentId: myAnswerId,
              coursePackageId: coursePackageId,
              comment: {
                content: discussContent,
                // cardId: myAnswerId,
                atUserIds: []
              }
            }
          }
        }).then(res => {
          const edges =
            res.data.addCommentToAssignment.assignment.comments.edges;
          const comments = edges.map(item => item.node);

          client.writeQuery({
            query: SEND_HOMEWORK_DATA,
            data: { commentList: comments }
          });
        });
        // 注释以下代码
        // this.setState({ comLoading: true });
        // this.props.form.resetFields();
        // console.log(discussContent, 'discussContent');
        // this.resetCommentsList();
        // dispatch({
        //   type: 'course/commitComment',
        //   payload: {
        //     key: answer.id,
        //     content: changeToMarkdownFormat(discussContent),
        //     page: 1,
        //     count: 20,
        //     callback: () => {
        //       this.setState({
        //         commentPage: 1,
        //         comLoading: false,
        //         discussContent: ''
        //       });
        //       this.props.form.resetFields();
        //       this.resetCommentsList();
        //     }
        //   }
        // });
      }
    }
  };

  resetCommentsList() {
    const {
      collectionHomeworkData,
      collectionHomeworkDetailInfo,
      commentList
    } = this.props;

    let data = collectionHomeworkData.find(item => {
      if (item.id === collectionHomeworkDetailInfo.answerInfo.id) {
        return item;
      }
    });

    data.comments_count = commentList.length;
    collectionHomeworkDetailInfo.comments = commentList;
    collectionHomeworkDetailInfo.comments_count = commentList.length;
  }

  importContent = importContent => {
    this.setState({ importContent });
    this.props.dispatch({
      type: 'course/handlerShowList',
      showHomeworkList: true
    });
    this.props.dispatch({
      type: 'course/handlerShowList',
      showHomeworkList: false
    });
  };

  beforeUpload(file) {
    const { dispatch } = this.props;
    const suffix = file.name.substring(
      file.name.lastIndexOf('.') + 1,
      file.name.length
    );
    const isMD = suffix === 'md';
    if (isMD) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function() {
        dispatch({
          type: 'course/handlerImportContent',
          importContent: reader.result
        });
        dispatch({ type: 'course/handlerShowList', showHomeworkList: true });
        dispatch({ type: 'course/handlerShowList', showHomeworkList: false });
        message.success('导入文件成功');
      };
    } else {
      message.error('仅支持导入markdown文件');
    }
    return false;
  }

  handleCollectHomework() {
    const { dispatch, courseId, answer } = this.props;
    dispatch({
      type: 'course/cardCol',
      payload: {
        key: answer.aw_key,
        course_id: courseId
      }
    });
  }

  showLeft() {
    const { collectionShowData, collectionDetailIndex } = this.props;
    let isShow = true;

    if (
      /*collectionShowData.current_page === 1 &&*/ collectionDetailIndex === 0
    ) {
      isShow = false;
    }

    return isShow;
  }

  goLeft() {
    const {
      dispatch,
      collectionShowData,
      collectionDetailIndex,
      getSingleHomeworkContent,
      getHomeworkAnswerContent,
      client,
      courseId
    } = this.props;
    const answerIds = collectionShowData[collectionDetailIndex - 1].parent.id;
    client.writeData({
      data: { collectionDetailIndex: collectionDetailIndex - 1 }
    });

    const item = collectionShowData[collectionDetailIndex - 1];

    // 获取作业的内容
    getHomeworkAnswerContent({
      courseId,
      id: Base64.encode(
        'Assignment:' + Base64.decode(item.id).split('Collection:')[1]
      )
    }).then(res => {
      const content = handleCardContent(
        item.name,
        item.parent.questions[0].content,
        item.id,
        client
      );

      const data = res.data.node;
      let commentList, answerContent;
      answerContent = data.content;

      if (data.comments.edges.length !== 0) {
        commentList = data.comments.edges.map(item => item.node);
      }

      const answerInfo = {
        head_img: data.author.avatar,
        user: data.author.nickname,
        content: answerContent,
        time: data.insertedAt,
        id: item.id
      };
      const homeworkInfo = {
        question: content,
        title: item.parent.name
      };

      // 最终组件要的数据源
      client.writeQuery({
        query: SEND_HOMEWORK_DATA,
        data: {
          commentList,
          collectionHomeworkDetailInfo: {
            answerInfo,
            homeworkInfo
          }
        }
      });
    });

    // getSingleHomeworkContent({ id: answerIds, courseId }).then(res => {
    //   const data = res.data.node;
    //   let commentList, answerContent;

    //   const content = handleCardContent(
    //     data.questions[0].name,
    //     res.data.node.questions[0].content
    //   );

    //   if (data.answers.edges.length !== 0) {
    //     commentList = data.answers.edges[0].node.comments.edges.map(
    //       item => item.node
    //     );
    //     answerContent = data.answers.edges[0].node.content;
    //   }

    //   const answerInfo = {
    //     head_img: res.data.node.author.avatar,
    //     user: res.data.node.author.nickname,
    //     content: answerContent,
    //     time: data.insertedAt
    //   };
    //   const homeworkInfo = {
    //     question: content,
    //     title: data.name
    //   };

    //   // 最终组件要的数据源
    //   client.writeQuery({
    //     query: SEND_HOMEWORK_DATA,
    //     data: {
    //       commentList,
    //       collectionHomeworkDetailInfo: { answerInfo, homeworkInfo },
    //       collectionDetailIndex: collectionDetailIndex - 1
    //     }
    //   });
    // });

    this.setState({ discussContent: '' });
    this.props.form.resetFields();
  }

  showRight() {
    const { collectionShowData, collectionDetailIndex } = this.props;
    let isShow = true;

    if (
      /*collectionShowData.current_page === collectionShowData.total_page &&*/
      collectionDetailIndex ===
      collectionShowData.length - 1
    ) {
      isShow = false;
    }

    return isShow;
  }

  goRight() {
    const {
      dispatch,
      collectionShowData,
      collectionDetailIndex,
      getSingleHomeworkContent,
      getHomeworkAnswerContent,
      client,
      courseId
    } = this.props;

    const answerIds = collectionShowData[collectionDetailIndex + 1].parent.id;

    // 再次发送请求

    client.writeData({
      data: { collectionDetailIndex: collectionDetailIndex + 1 }
    });

    const item = collectionShowData[collectionDetailIndex + 1];

    // 获取作业的内容
    getHomeworkAnswerContent({
      courseId,
      id: Base64.encode(
        'Assignment:' + Base64.decode(item.id).split('Collection:')[1]
      )
    }).then(res => {
      const content = handleCardContent(
        item.name,
        item.parent.questions[0].content,
        item.id,
        client
      );

      const data = res.data.node;
      let commentList, answerContent;
      answerContent = data.content;

      if (data.comments.edges.length !== 0) {
        commentList = data.comments.edges.map(item => item.node);
      }

      const answerInfo = {
        head_img: data.author.avatar,
        user: data.author.nickname,
        content: answerContent,
        time: data.insertedAt,
        id: item.id
      };
      const homeworkInfo = {
        question: content,
        title: item.parent.name
      };

      // 最终组件要的数据源
      client.writeQuery({
        query: SEND_HOMEWORK_DATA,
        data: {
          commentList,
          collectionHomeworkDetailInfo: {
            answerInfo,
            homeworkInfo
          }
        }
      });
    });

    // getSingleHomeworkContent({ id: answerIds, courseId }).then(res => {
    //   const data = res.data.node;
    //   let commentList, answerContent;

    //   const content = handleCardContent(
    //     data.questions[0].name,
    //     res.data.node.questions[0].content
    //   );

    //   if (data.answers.edges.length !== 0) {
    //     commentList = data.answers.edges[0].node.comments.edges.map(
    //       item => item.node
    //     );
    //     answerContent = data.answers.edges[0].node.content;
    //   }

    //   const answerInfo = {
    //     head_img: res.data.node.author.avatar,
    //     user: res.data.node.author.nickname,
    //     content: answerContent,
    //     time: data.insertedAt
    //   };
    //   const homeworkInfo = {
    //     question: content,
    //     title: data.name
    //   };

    //   // 最终组件要的数据源
    //   client.writeQuery({
    //     query: SEND_HOMEWORK_DATA,
    //     data: {
    //       commentList,
    //       collectionHomeworkDetailInfo: { answerInfo, homeworkInfo },
    //       collectionDetailIndex: collectionDetailIndex + 1
    //     }
    //   });
    // });
    // const homeworkIds = [
    //   collectionShowData[collectionDetailIndex + 1].homework_card_id
    // ];
    // const title = collectionShowData[
    //   collectionDetailIndex + 1
    // ].homework_file_name.split('.')[0];
    // const create_time =
    //   collectionShowData[collectionDetailIndex + 1].create_time;

    // dispatch({
    //   type: 'course/handlerSetCollectiondetailIndex',
    //   collectionDetailIndex: collectionDetailIndex + 1
    // });
    // dispatch({
    //   type: 'course/getAnswer',
    //   payload: {
    //     title: title,
    //     create_time: create_time,
    //     answerIds: answerIds,
    //     homeworkIds: homeworkIds
    //   }
    // });

    this.setState({ discussContent: '' });
    this.props.form.resetFields();
  }

  renderHomeworkContent() {
    const {
      dispatch,
      userInfo,
      shortUrl,
      commentList,
      collectionHomeworkDetailInfo,
      client,
      coursePackageAssignmentId,
      deleteComment
    } = this.props;

    // const { setFieldsValue, getFieldDecorator } = this.props.form;
    const { getFieldDecorator } = this.props.form;
    // console.log(getFieldDecorator, 'getFieldDecorator');
    const { isUseMarkdownEditForDis, discussContent, comLoading } = this.state;

    let answer = collectionHomeworkDetailInfo.answerInfo;

    let homework = collectionHomeworkDetailInfo.homeworkInfo;

    let title = '作业题目';
    let content = '';
    // try {
    //   title = answer.title;
    //   content = answer.content;
    // } catch (err) {
    //   false && console.log(err);
    // }

    let createTime = '';
    // try {
    //   createTime = `提交于${moment(answer.create_time).isSame(
    //     moment().startOf('day'),
    //     'd'
    //   )}`
    //     ? `今天 ${answer.create_time.split(' ')[1]}`
    //     : answer.create_time;
    // } catch (error) {}

    return (
      <Layout className='new-routes-collection-collectionDetail-homework-2050'>
        <Layout.Content
          className='new-routes-collection-collectionDetail-homework-2078'
          style={{ overflow: 'auto' }}
        >
          <div className='new-routes-collection-collectionDetail-homework-2087'>
            <div className='new-routes-collection-collectionDetail-homework-2096'>
              <div className='new-routes-collection-collectionDetail-homework-2105'>
                <div className='new-routes-collection-collectionDetail-homework-2114'>
                  <div className='new-routes-collection-collectionDetail-homework-2119'>
                    {homework.title}
                  </div>
                </div>
                <MyReactMarkdown
                  source={homework.question.front}
                  // source={homework.question.content}
                  className='new-routes-collection-collectionDetail-homework-2125'
                />
              </div>

              <div className='new-routes-collection-collectionDetail-homework-2600'>
                <div className='new-routes-collection-collectionDetail-homework-2607'>
                  <div className='new-routes-collection-collectionDetail-homework-2639'>
                    <div className='new-routes-collection-collectionDetail-homework-2658'>
                      <Avatar
                        src={answer.head_img}
                        size={'large'}
                        style={{ width: 36, height: 36 }}
                      />
                      <div className='new-routes-collection-collectionDetail-homework-2664'>
                        <div className='new-routes-collection-collectionDetail-homework-2672'>
                          {answer.user}
                        </div>
                        <div className='new-routes-collection-collectionDetail-homework-2688'>
                          {/* {moment(createTime).isSame(
                            moment().startOf('day'),
                            'd'
                          )
                            ? `今天 ${createTime.split(' ')[1]}`
                            : createTime} */}
                          {answer.time}
                        </div>
                      </div>
                    </div>
                    <div className='new-routes-collection-collectionDetail-homework-2694' />
                  </div>
                  <MyReactMarkdown
                    source={answer.content}
                    className='new-routes-collection-collectionDetail-homework-2709'
                  />
                </div>
              </div>

              <div className='new-routes-collection-collectionDetail-homework-3127'>
                <div className='new-routes-collection-collectionDetail-homework-3144'>
                  <div className='new-routes-collection-collectionDetail-homework-3159'>
                    <div className='new-routes-collection-collectionDetail-homework-3171'>
                      {/* {commentList.length}条评论 */}
                    </div>
                  </div>
                  {isUseMarkdownEditForDis ? (
                    <div className='new-routes-collection-collectionDetail-homework-3417'>
                      <Form.Item className='new-routes-collection-collectionDetail-homework-3440'>
                        {getFieldDecorator('comment', {
                          validateTrigger: ['onBlur'],
                          initialValue: { content: { markdown: '' } },
                          rules: [
                            {
                              validator: checkMDContent,
                              message: '请输入评论内容'
                            }
                          ]
                        })(
                          <MDEditor_v2
                            minHeight={'150px'}
                            isBottom
                            butTitle={'发布评论'}
                            loading={comLoading}
                            onSubmit={() => this.onSubmitComment(answer.id)}
                            width={920}
                            height={250}
                            pb={48}
                          />
                        )}
                      </Form.Item>
                      <div className='new-routes-collection-collectionDetail-homework-3442'>
                        <div className='new-routes-collection-collectionDetail-homework-3444'>
                          <div
                            className='new-routes-collection-collectionDetail-homework-3485'
                            onClick={() => {
                              this.setState({
                                isUseMarkdownEditForDis: false
                              });
                            }}
                          >
                            切换普通输入框
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='new-routes-collection-collectionDetail-homework-3454'>
                      {/* <Input
                        className="new-routes-collection-collectionDetail-homework-3466"
                        placeholder="说出你的评论"
                        prefix={null}
                        suffix={
                          discussContent ? (
                            <a
                              onClick={() => {
                                this.setState({ discussContent: "" });
                              }}
                            >
                              <img src={cross} data-rjs="2" />
                            </a>
                          ) : null
                        }
                        value={discussContent}
                        onChange={value => {
                          this.setState({ discussContent: value.target.value });
                        }}
                        onPressEnter={e => {
                          this.onSubmitComment();
                        }}
                        
                      /> */}

                      <TextArea
                        className='new-routes-collection-collectionDetail-homework-3466'
                        placeholder='说出你的评论'
                        value={discussContent}
                        autosize={{ minRows: 1, maxRows: 6 }}
                        onChange={value => {
                          this.setState({ discussContent: value.target.value });
                        }}
                      />

                      <div className='new-routes-collection-collectionDetail-homework-3471'>
                        <div className='new-routes-collection-collectionDetail-homework-3482'>
                          <div
                            className='new-routes-collection-collectionDetail-homework-3485'
                            onClick={() => {
                              this.setState({
                                isUseMarkdownEditForDis: true
                              });
                            }}
                          >
                            切换Markdown编辑器
                          </div>
                        </div>
                        <div
                          className='new-routes-collection-collectionDetail-homework-3492'
                          onClick={() => this.onSubmitComment(answer.id)}
                        >
                          <div className='new-routes-collection-collectionDetail-homework-3495'>
                            发布
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {commentList.length === 0 ? null : (
                  <div className='new-routes-collection-collectionDetail-homework-3508' />
                )}

                {commentList.length === 0 ? null : (
                  <div className='new-routes-collection-collectionDetail-homework-3509'>
                    <List
                      // pagination={
                      //   discComment.page < 2
                      //     ? null
                      //     : {
                      //         pageSize: 20,
                      //         current: this.state.commentPage,
                      //         total: discComment.count,
                      //         onChange: e => {
                      //           this.setState({ commentPage: e });
                      //           dispatch({
                      //             type: "course/uploadComment",
                      //             payload: {
                      //               key: discDetail.key,
                      //               page: e,
                      //               count: 20
                      //             }
                      //           });
                      //         }
                      //       }
                      // }
                      itemLayout='horizontal'
                      dataSource={commentList}
                      renderItem={item => {
                        let isMine = false;
                        const commentUserID = item.poster.id;

                        const myUserID = timeLocalStorage.get('USERINFO')
                          .userid;
                        if (commentUserID === myUserID) {
                          isMine = true;
                        }

                        return (
                          <List.Item>
                            <div className='new-routes-study-courseDiscussion-comment-wrapper new-routes-collection-collectionDetail-homework-3611'>
                              <div className='new-routes-collection-collectionDetail-homework-3621'>
                                <Avatar
                                  className='new-routes-collection-collectionDetail-homework-3630'
                                  src={item.poster.avatar}
                                  size={'normal'}
                                />
                                <div className='new-routes-collection-collectionDetail-homework-3644'>
                                  <div className='new-routes-collection-collectionDetail-homework-3657'>
                                    <div className='new-routes-collection-collectionDetail-homework-3661'>
                                      <div className='new-routes-collection-collectionDetail-homework-3679'>
                                        {item.poster.nickname}
                                      </div>
                                      <div className='new-routes-collection-collectionDetail-homework-3682'>
                                        {/* {moment(item.create_time).isSame(
                                        moment().startOf('day'),
                                        'd'
                                      )
                                        ? `今天 ${
                                            item.create_time.split(' ')[1]
                                          }`
                                        : item.create_time} */}
                                        {item.insertedAt}
                                      </div>
                                    </div>
                                    {isMine ? (
                                      <img
                                        src={deleteIcon}
                                        className='new-routes-collection-collectionDetail-homework-3694'
                                        onClick={() => {
                                          Modal3CallbackFunc.callback = () => {
                                            const id = window
                                              .atob(item.id)
                                              .split('CardComment:')[1];
                                            const coursePackageId = window
                                              .atob(coursePackageAssignmentId)
                                              .split('CoursePackage:')[1];
                                            const answerId = window
                                              .atob(answer.id)
                                              .split('Collection:')[1];

                                            deleteComment({
                                              variables: {
                                                input: {
                                                  assignmentId: answerId,
                                                  coursePackageId: Number(
                                                    coursePackageId
                                                  ),
                                                  commentId: Number(id)
                                                }
                                              }
                                            }).then(res => {
                                              const edges =
                                                res.data
                                                  .removeCommentFromAssignment
                                                  .assignment.comments.edges;
                                              const comments = edges.map(
                                                item => item.node
                                              );

                                              client.writeQuery({
                                                query: SEND_HOMEWORK_DATA,
                                                data: { commentList: comments }
                                              });

                                              message.success('删除成功!');
                                              client.writeData({
                                                data: {
                                                  modal3: {
                                                    show: false,
                                                    title: '',
                                                    content: '',
                                                    __typename: 'Modal3'
                                                  }
                                                }
                                              });
                                            });
                                          };

                                          client.writeData({
                                            data: {
                                              modal3: {
                                                show: true,
                                                title: '',
                                                content: '确定要删除评论吗？',
                                                __typename: 'Modal3'
                                              }
                                            }
                                          });
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                  <MyReactMarkdown
                                    className='new-routes-collection-collectionDetail-homework-3788'
                                    source={item.content}
                                  />
                                </div>
                              </div>
                            </div>
                          </List.Item>
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    );
  }

  render() {
    let showLeft = this.showLeft();
    let showRight = this.showRight();
    return (
      <div className='new-routes-collection-collectionDetail-homework-2000'>
        {this.renderHomeworkContent()}
        {showLeft ? (
          <div
            className='new-routes-collection-collectionDetail-homework-2000-left'
            style={{ cursor: 'pointer' }}
            onClick={this.goLeft.bind(this)}
          >
            <img src={left} data-rjs='2' />
          </div>
        ) : (
          <div className='new-routes-collection-collectionDetail-homework-2000-left' />
        )}
        {showRight ? (
          <div
            className='new-routes-collection-collectionDetail-homework-2000-right'
            style={{ cursor: 'pointer' }}
            onClick={this.goRight.bind(this)}
          >
            <img src={right} data-rjs='2' />
          </div>
        ) : (
          <div className='new-routes-collection-collectionDetail-homework-2000-right' />
        )}
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     homeworkInfo: state.course.homeworkInfo,
//     selectedHomeworkKey: state.course.selectedHomeworkKey,
//     isEditHomework: state.course.isEditHomework,
//     selectedHomeworkPid: state.course.selectedHomeworkPid,
//     answer: state.course.answer,
//     answers: state.course.answers,
//     discData: state.course.discData,
//     commentList: state.course.commentList,
//     isModifyAnswer: state.course.isModifyAnswer,
//     userInfo: state.login.userInfo,
//     discComment: state.course.discComment,
//     importContent: state.course.importContent,
//     answerCommentList: state.course.answerCommentList,
//     shortUrl: state.course.shortUrl,
//     homeworkKey: state.course.homeworkKey,
//     workUrl: state.course.workUrl,
//     courseId: state.course.courseId,

//     //收藏页面卡包详细页跳转用的数据
//     collectionHomeworkData: state.course.collectionHomeworkData,
//     collectionShowData: state.course.collectionShowData,
//     collectionDetailIndex: state.course.collectionDetailIndex,
//     collectionHomeworkDetailInfo: state.course.collectionHomeworkDetailInfo
//   };
// };
export default CollectionHomework;
