import React, { FC } from 'react';
import { Avatar, Icon } from 'antd';
import Markdown from 'react-markdown';

const renderImage = (props: any) => (
  <img {...props} style={{ maxWidth: '100%' }} />
);

interface IMessageItem {
  src: string;
  content: string;
  description: string;
  state: string;
  onClick?: () => void;
}

const MessageItem: FC<IMessageItem> = ({
  src,
  content,
  description,
  state,
  onClick
}) => {
  return (
    <div className='msg-item' onClick={onClick}>
      <Avatar icon='user' size={50} src={src} />
      <div className='msg-item-show'>
        <Icon type='caret-left' className='msg-item-show-icon' />
        <div className='msg-item-show-content'>
          <p className='msg-item-content'>{content}</p>
          <Markdown
            className='msg-item-description'
            source={description}
            renderers={{ image: renderImage }}
          />
        </div>
      </div>
      <span
        className='msg-item-state'
        style={{ color: state === 'VIEWED' ? '#93979F' : '#D4323C' }}
      >
        {state === 'VIEWED' ? '已读' : '未读'}
      </span>
    </div>
  );
};

export default MessageItem;
