import * as React from 'react';
import './styles.css';

//引入的库组件
import { message } from 'antd';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

//引入的其他页面组件
import SearchAndInitiate from './SearchAndInitiate';
import DiscussList from './DiscussList';
import DiscussToolbarTip from './DistussToolbarTip';
import DiscussToolbar from './DiscussToolbar';

//graphql查询
import { useGetDiscussListQuery, GetDiscussListQueryVariables } from '../../../../generated/graphql';

//引入的资源图片

const GET_DISCUSS_PAGE_INFO = gql`
  {
    disc @client {
      page
      count
    }
    discData @client
    discPage @client
    disscussPageInfo @client
    discussCountPerPage @client
  }
`;

const DiscussionListContainer = () => {
  const client = useApolloClient();
  const { data: discussPageInfo } = useQuery(GET_DISCUSS_PAGE_INFO);
  const { discussCountPerPage} = discussPageInfo;
  const { refetch: getDiscussList } = useGetDiscussListQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });

  const getListData = (input: any, page: number) => {
    let filter = input.filter;
    let matching = filter.name;
    delete filter.name;
    input = {
      ...input,
      filter: filter,
      matching: matching
    }
    client.writeData({
      data: {
        discLoading: {
          loading: true,
          tip: '正在获取讨论列表',
          __typename: 'DiscLoading'
        }
      }
    });
    getDiscussList(input).then((res: any) => {
      client.writeData({
        data: {
          discLoading: {
            loading: false,
            tip: '',
            __typename: 'DiscLoading'
          }
        }
      });
      
      if (res.errors) {
        message.error('获取讨论列表失败!');
      } else {
        let datas = res.data.node.discussions.edges;
        let count = res.data.node.discussions.count;
        let pageAll = Math.ceil( count / discussCountPerPage );
        client.writeQuery({
          query: GET_DISCUSS_PAGE_INFO,
          data: {
            disc: {page: pageAll, count: count,  __typename: "Disc"},
            discData: datas,
            discPage: page,
            disscussPageInfo: res.data.node.discussions.pageInfo
          }
        });
        let element = document.getElementById("main");
        if(element){
          element.scrollIntoView(true);
        }
      }
    }).catch((error) => {
      
    });
  }

  return (
    <div className="new-routes-study-courseDiscussion-1000" id="main">
        <SearchAndInitiate getListData={getListData}/>
        <DiscussToolbarTip getListData={getListData}/>
        <DiscussToolbar getListData={getListData}/>
        <DiscussList getListData={getListData}/>
    </div>
  );
};

export default DiscussionListContainer;