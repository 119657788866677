import * as React from 'react';
import './styles.css';

//引入的库组件
import { withRouter } from 'react-router';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

//引入的其他页面组件
import EduManagerPackagePage from './EduManagerPackagePage';
import EduManagerPreviewPage from './EduManagerPreviewPage';

//graphql查询
import { useGetEduCreatedPackagesQuery } from '../../generated/graphql';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_EDU_CREATED_PACKAGE_INFO } from './query';

//引入的资源图片

const GET_PACKAGE_MANAGER_INFO = gql`
  {
    treeData @client
    cooperationTreeData @client
    pMode @client
  }
`;

const EduManagerPackagePageContainer = (props: any) => {
  const {
    data: masterData,
    error: masterError,
    loading: masterLoading
  } = useGetEduCreatedPackagesQuery({
    variables: { first: 2000, filter: { isMaster: true } },
    fetchPolicy: 'network-only'
  });

  const {
    data: assistData,
    error: assistError,
    loading: assistLoading
  } = useGetEduCreatedPackagesQuery({
    variables: { first: 2000, filter: { isMaster: false } },
    fetchPolicy: 'network-only'
  });

  const [isInit, setIsInit] = React.useState(false);
  const client = useApolloClient();
  // const { data: packageManagerInfo } = useQuery(GET_PACKAGE_MANAGER_INFO);

  if (
    !masterData ||
    masterError ||
    masterLoading ||
    !assistData ||
    assistError ||
    assistLoading
  ) {
    return <div />;
  }

  let treeData: any = masterData!.packages!.edges!.map(
    (item: any, index: number) => {
      return {
        collaborativeState: item.collaborativeState ? item.collaborativeState : "",
        hasFolderChild: item.node.hasFolderCard,
        pid: 0,
        key: item.node.id,
        is_assistant: 0,
        type: 0,
        title: item.node.name,
        children: [],
        __typename: 'TreeData'
      };
    }
  );

  let cooperationTreeData: any = assistData!.packages!.edges!.map(
    (item: any) => {
      return {
        collaborativeState: item.collaborativeState ? item.collaborativeState : "",
        hasFolderChild: item.node.hasFolderCard,
        pid: 0,
        key: item.node.id,
        is_assistant: 1,
        type: 0,
        title: item.node.name,
        children: [],
        __typename: 'TreeData'
      };
    }
  );

  client.writeQuery({
    query: GET_PACKAGE_MANAGER_INFO,
    data: { treeData: treeData, cooperationTreeData: cooperationTreeData, pMode: treeData.length > 0 ? true : false }
  });

  return (
    <>
      <EduManagerPackagePage props={props} />
      <EduManagerPreviewPage />
    </>
  );
};

export default withRouter(EduManagerPackagePageContainer);
