import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useApolloClient } from 'react-apollo';
import { Spin, Breadcrumb, Collapse, message } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import decamelize from 'decamelize';
import {
  query,
  writeQuery_user,
  writeQuery_basic,
  writeQuery_detailed
} from './cache';
import {
  GET_USER_INFO,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PROFILE,
  CREATE_USER_PROFILE
} from './query';
import From from 'components/Form';
import basic from './models/basic';
import password from './models/password';
import detailed, { handleChange } from './models/detailed';
import timeLocalStorage from 'utils/TimeLocalStorage';
import './style.less';

const { Panel } = Collapse;

const My: FC = () => {
  const client = useApolloClient();

  // 写入缓冲
  const writeQuery = (query: any, data: any) => {
    client.writeQuery({
      query,
      data: {
        my: {
          ...data,
          __typename: 'my'
        }
      }
    });
  };

  // 读取缓冲
  const { data: cache } = useQuery(query);
  const { token } = cache.userInfo;
  const { user, basicProps, detailedProps } = cache.my;

  // 获取个人信息
  const { data, refetch } = useQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache'
  });

  // 修改信息
  const [updateUser] = useMutation(UPDATE_USER);
  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);
  const [createUserProfile] = useMutation(CREATE_USER_PROFILE);

  // 写入缓冲
  useEffect(() => {
    try {
      if (data && Object.keys(data).length !== 0) {
        const { me } = data;
        const basicProps = {
          avatar: me.avatar,
          phone: me.phone,
          nickname: me.nickname
        };

        let detailedProps = null;
        if (me.userProfile) {
          const { userProfile } = me;
          detailedProps = userProfile;
          detailedProps.birth = moment(userProfile.birth, 'YYYY-MM-DD');
          detailedProps.blog_url = userProfile.info.blog_url;
          detailedProps.douban_url = userProfile.info.douban_url;
          detailedProps.github_url = userProfile.info.github_url;
          handleChange(userProfile.state);
        }

        writeQuery(writeQuery_user, {
          user: me,
          basicProps,
          detailedProps,
          avatar: me.avatar
        });

        // 更新缓冲
        const userInfo = {
          head_img: me.avatar,
          phone: me.phone,
          role: me.role,
          token,
          userid: me.id,
          username: me.nickname,
          userProfile: me.userProfile,
          __typename: 'UserInfo'
        };

        window.localStorage.setItem('USERINFO', JSON.stringify(userInfo));
        client.writeData({ data: { userInfo, __typename: 'UserInfo' } });
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  if (!user) {
    return (
      <div id='loading'>
        <Spin size='large' />
      </div>
    );
  }

  // 处理接口数据返回
  const handleResult = (errors: any, tips: string) => {
    if (errors) {
      const { key, message: msg } = errors[0];
      if (msg === 'password incorrect') {
        message.error('原密码输入错误！');
      } else {
        const messages = [...basic, ...detailed].map((item: any) => ({
          label: item.label,
          value: item.value
        }));
        const data = messages.find(
          (item: any) => decamelize(item.value) === key
        );
        if (data && msg === 'has already been taken') {
          message.error(`${data.label}已被使用过！`);
        } else {
          message.error(`${key}-${msg}`);
        }
      }
    } else {
      message.success(tips);
      refetch();
    }
  };

  return (
    <div className='my'>
      <div className='my-content'>
        {/* 面包屑 */}
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to='/'>首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>个人设置</Breadcrumb.Item>
        </Breadcrumb>
        {/* 编辑区域 */}
        <Collapse
          bordered={false}
          defaultActiveKey={[1, 2, 3]}
          className='my-content-collapse'
        >
          <Panel className='my-content-Panel' header='基本资料' key={1}>
            <From
              config={basic}
              defaultProps={basicProps}
              submit={values => {
                writeQuery(writeQuery_basic, {
                  basicProps: values
                });
                const id = window.atob(user.id).split('User:')[1];
                updateUser({ variables: { input: { ...values, id } } }).then(
                  (res: any) => {
                    const { errors } = res.data.updateUser;
                    handleResult(errors, '信息修改成功');
                  }
                );
              }}
            />
          </Panel>
          <Panel className='my-content-Panel' header='修改密码' key={2}>
            <From
              config={password}
              submit={values => {
                const { newPassword, againNewPassword } = values;
                const { phone } = user;
                if (newPassword === againNewPassword) {
                  delete values.againNewPassword;
                  updateUserPassword({
                    variables: { input: { ...values, phone } }
                  }).then((res: any) => {
                    const { errors } = res.data.updateUserPassword;
                    handleResult(errors, '密码修改成功');
                  });
                } else {
                  message.error('两次密码输入不一致');
                }
              }}
            />
          </Panel>
          <Panel className='my-content-Panel' header='详细信息' key={3}>
            <From
              config={detailed}
              defaultProps={detailedProps}
              submit={values => {
                writeQuery(writeQuery_detailed, {
                  detailedProps: values
                });
                const birth = values.birth.format('YYYY-MM-DD');
                const info = {
                  blogUrl: values.blog_url || '',
                  doubanUrl: values.douban_url || '',
                  githubUrl: values.github_url || ''
                };
                if (values.state.includes('在读')) {
                  values.industry = '';
                  values.headline = '';
                  values.company = '';
                }
                if (values.works) {
                  values.works.forEach((item: any) => {
                    if (item.__typename) {
                      delete item.__typename;
                    }
                  });
                }
                const input = { ...values, birth, info };
                delete input.blog_url;
                delete input.douban_url;
                delete input.github_url;
                // 修改 userProfile
                if (user.userProfile) {
                  updateUserProfile({
                    variables: { input }
                  }).then((res: any) => {
                    const { errors } = res.data.updateUserProfile;
                    handleResult(errors, '信息修改成功');
                  });
                } else {
                  // 创建 userProfile
                  createUserProfile({
                    variables: { input }
                  }).then((res: any) => {
                    const { errors } = res.data.createUserProfile;
                    handleResult(errors, '信息修改成功');
                  });
                }
              }}
            />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default My;
