import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Icon, Button } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { validateUrl } from 'utils/regexp';

interface Works {
  name: string;
  url: string;
}

interface WorksProps extends FormComponentProps {
  defaultProps: any;
  onChange: (works: Works[]) => void;
}

const Works: FC<WorksProps> = ({ form, defaultProps, onChange }) => {
  const { getFieldDecorator } = form;
  const [data, setData] = useState<Works[]>([]);

  useEffect(() => {
    if (defaultProps) {
      setData(defaultProps.works);
    }
  }, [defaultProps]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const handleAddItem = () => {
    const item: Works = {
      name: '',
      url: ''
    };
    setData(data => {
      const newData = [...data];
      newData.push(item);
      return newData;
    });
  };

  const handleDeleteItem = (index: number) => {
    setData(data => {
      const newData = [...data];
      newData.splice(index, 1);
      return newData;
    });
  };

  return (
    <Form>
      {data.map((item, index) => (
        <Form.Item key={index}>
          {getFieldDecorator(`${index}`, {
            rules: [
              {
                validator: (rule, value, callback) => {
                  if (!validateUrl(item.url) && item.url !== '') {
                    callback(`请正确填写作品链接地址`);
                    return;
                  }
                  callback();
                }
              }
            ]
          })(
            <div key={index} className='my-works-item'>
              <Input
                style={{ marginRight: '10px', width: '150px' }}
                value={item.name}
                placeholder='请输入作品名称'
                onChange={e => {
                  const value = e.target.value;
                  const newData = [...data];
                  newData[index].name = value;
                  setData(newData);
                }}
              />
              <Input
                value={item.url}
                placeholder='请输入作品链接'
                onChange={e => {
                  const value = e.target.value;
                  const newData = [...data];
                  newData[index].url = value;
                  setData(newData);
                }}
              />
              <Icon
                className='my-works-item-icon'
                type='minus-circle'
                onClick={() => handleDeleteItem(index)}
              />
            </div>
          )}
        </Form.Item>
      ))}
      <Button
        type='primary'
        ghost
        onClick={handleAddItem}
        style={{
          border: '1px solid rgba(56, 56, 56, 0.50)',
          color: 'rgba(0, 0, 0, 0.65)',
          backgroundColor: '#fff'
        }}
      >
        添加输入项
      </Button>
    </Form>
  );
};

export default Form.create<WorksProps>({ name: 'works' })(Works);
