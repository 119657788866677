/**

 * @file: 开智js

 * @author: sky

 *          qiuweiming@quseit.com

 * @update: 2015-04-29 00:02:45

 *//**

 * @file: 开智js

 * @author: sky

 *          qiuweiming@quseit.com

 * @update: 2015-04-29 00:02:45

 */
import React, { Component } from 'react';
import Input from 'antd/es/input/Input';
import cross from '../../../assets/icons/cross.png';
import Checkbox from 'antd/es/checkbox/Checkbox';
import './styles.css';

class RadioLayout extends Component {
  constructor(props) {
    super(props);
    const value = props.value || {};
    this.state = {
      aw: value.aw || '',
      tf: value.tf || false,
    };
  }

  componentWillReceiveProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      const value = nextProps.value;
      this.setState(value);
    }
  }

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(Object.assign({}, this.state, changedValue));
    }
  }

  handleAWChange = (aw) => {
    if (!('value' in this.props)) {
      this.setState({ aw });
    }
    this.triggerChange({ aw });
  }

  handleTFChange = (tf) => {
    if (!('value' in this.props)) {
      this.setState({ tf });
    }
    this.triggerChange({ tf });
  }

  render() {
    const { aw, tf } = this.state;
    const { checkboxKey, onDelete } = this.props;
    return (
      <div className="components-layout-CheckboxLayout-1500">
        <Input
          className="components-layout-CheckboxLayout-1576"
          value={aw} onChange={(e) => {
            this.handleAWChange(e.target.value);
          }}
        />
        <Checkbox
          checked={tf}
          onChange={(e) => {
            this.handleTFChange(e.target.checked);
            false && console.log(`checkbox--->11111${JSON.stringify(e)}`);
          }} value={tf} className="components-layout-CheckboxLayout-1965"
        >正确选项</Checkbox>
        <img onClick={() => { onDelete(); }} src={cross} className="components-layout-CheckboxLayout-2074" />
      </div>

    );
  }
}

export default RadioLayout;
