import React, { FC, FormEvent } from 'react';
import { Form, Button, message } from 'antd';
import { FormComponentProps, ValidationRule } from 'antd/es/form';

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 15 }
};

export interface FormConfig {
  value: string;
  component: FC<any>;
  label?: string;
  rules?: ValidationRule[];
}

interface IBaseFromProps extends FormComponentProps {
  config: FormConfig[];
  defaultProps?: any;
  submit: (values: any) => void;
}

const BaseFrom: FC<IBaseFromProps> = ({
  form,
  config,
  submit,
  defaultProps
}) => {
  const { getFieldDecorator } = form;
  // 表单提交
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        message.error('请正确填写表单');
      } else {
        submit(values);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {config.map(item => {
        return (
          <Form.Item
            colon={false}
            key={item.value}
            label={item.label}
            {...formItemLayout}
          >
            {getFieldDecorator(item.value, {
              rules: item.rules
            })(
              item.component({
                config: item,
                form,
                defaultProps: defaultProps || null
              })
            )}
          </Form.Item>
        );
      })}
      {submit && (
        <Button
          type='danger'
          htmlType='submit'
          style={{
            width: '100px',
            height: '40px',
            backgroundColor: '#d4323c',
            borderColor: '#d4323c',
            marginLeft: '100px',
            fontWeight: 700
          }}
        >
          保存
        </Button>
      )}
    </Form>
  );
};

export default Form.create<IBaseFromProps>({
  mapPropsToFields({ defaultProps }) {
    if (defaultProps) {
      let data: any = {};
      Object.keys(defaultProps).forEach(item => {
        data[item] = Form.createFormField({
          value: defaultProps[item]
        });
      });
      return data;
    }
  }
})(BaseFrom);
