import React from 'react';
import './styles.css';
import notFound from '../../assets/omcbg/404.png';
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/react-hooks';

const GET_HEADER_FOOTER = gql`
  {
    isShowHeader @client
    isShowFooter @client
  }
`;

const Error = () => {
  const client = useApolloClient();
  const { data } = useQuery(GET_HEADER_FOOTER);
  client.writeData({ data: { isShowFooter: true, isShowHeader: true } });
  return (
    <div className='routes-error-index-108'>
      <div>
        <img src={notFound} data-rjs='2' />
      </div>
      <div className='routes-error-index-285'>找不到您访问的页面</div>
    </div>
  );
};

export default Error;
