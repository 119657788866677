import React, { Component } from 'react';
import Layout from 'antd/es/layout/layout';
import styled from 'styled-components';

const EmptyLayout = styled(Layout.Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2%;
  padding-bottom: ${props => props.bottom};
  margin-top: ${props => props.top};
`;

EmptyLayout.Img = styled.img``;
EmptyLayout.Text = styled.p`
  color: #93979f;
  font-size: 16px;
  margin-top: 30px;
  line-height: 22px;
`;

export default EmptyLayout;
