import * as React from 'react';
import './styles.css';

//引入的库组件
import { useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { Modal, Spin, Button, Input, message } from 'antd';

//引入的其他页面组件
import { Footer } from '../Footer';
import Modal3CallbackFunc from './Modal3CallbackFunc';

//graphql查询

//引入的资源图片
import warning from '../../../assets/icons/warning.png';

const GET_MODAL_INFO = gql`
  {
    modal3Loading @client
    modal3 @client {
      title
      show
      content
    }
  }
`;

const Modal3 = (props: any) => {
  const client = useApolloClient();
  const { data } = useQuery(GET_MODAL_INFO);
  const { modal3, modal3Loading } = data;


  const onCancel = () => {
    Modal3CallbackFunc.callback = null;
    client.writeData({
      data: { modal3: { show: false, title: '', content: '', __typename: "Modal3" } }
    });
  };

  return (
    <Modal
      onCancel={onCancel}
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 185,
        zIndex: 1000
      }}
      width={380}
      maskClosable={false}
      title={'操作提示'}
      visible={modal3.show}
      footer={null}
    >
      <Spin spinning={modal3Loading}>
        <div className='components-modal3-974'>
          <div className='components-modal3-1113'>
            <div>
              <img data-rjs='2' src={warning} />
            </div>
            <div className='components-modal3-1268'>{modal3.content}</div>
          </div>
          <Footer className='mobile-width components-modal-handlerModal3-footer'>
            <Footer.Cancel
              className='components-modal-handlerModal3-footer-cancel'
              onClick={onCancel}
            >
              取消
            </Footer.Cancel>
            <Footer.OK onClick={Modal3CallbackFunc.callback}>确认</Footer.OK>
          </Footer>
        </div>
      </Spin>
    </Modal>
  );

};

export default Modal3;
