import * as React from 'react';
import './styles.css';

//引入的库组件
import { Modal, Form, Button, Input, message, Icon } from 'antd';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import timeLocalStorage from '../../utils/TimeLocalStorage';

//引入的其他页面组件
import { CountDownText } from '../JSComponents/countDown';

//graphql查询
import { useGetAuthCodeMutation, useResetUserPasswordMutation } from '../../generated/graphql';

//引入的资源图片
import phone from '../../assets/icons/phone.png';
import pw from '../../assets/icons/password_grey.png';

const GET_INFOS_FOR_RESET = gql`
  {
    isShowResetModal @client
    isShowResetResult @client
    isShowResetButton @client
    resetLoading @client
  }
`;

const contentLayout: any = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
};

const ResetPassword = (props: any) => {
  const [showButton, setShowButton] = React.useState(true);
  const { form } = props;
  const { getFieldDecorator, validateFieldsAndScroll, getFieldValue } = form;

  const client = useApolloClient();
  const { data: resetInfo } = useQuery(GET_INFOS_FOR_RESET);
  const {
    isShowResetModal,
    isShowResetResult,
    isShowResetButton,
    resetLoading
  } = resetInfo;

  const [getAuthCodeMutation] = useGetAuthCodeMutation();
  const [resetPassword] = useResetUserPasswordMutation();

  const onCancel = () => {
    client.writeData({
      data: { isShowResetButton: true, isShowResetModal: false }
    });
  };

  const reSet = () => {
    form.validateFieldsAndScroll((errors: any, values: any) => {
      if (errors) {
        false && console.log('tag');
      } else {
        let input = {
            authCode: values.rCode,
            phone: values.rPhone,
            newPassword: values.rPassword
        };

        resetPassword({variables: {input: input}}).then((res: any) => {
            if(res.data.resetPassword.errors) {
                message.error("用户不存在或验证码错误");
            }else {
                message.success("重置密码成功");
                form.resetFields();
                client.writeData({data: {isShowResetResult: true}});
            }
        });
        // dispatch({ type: 'login/resetPassword', payload: { phone: values.rPhone, new_password: values.rPassword, auth_code: values.rCode } });
      }
    });
  };

  const checkPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== getFieldValue('rPassword')) {
      callback('两次密码输入不一致！');
    } else {
      callback();
    }
  };

  return (
    <Modal
      keyboard={false}
      maskClosable={false}
      title='重置密码'
      visible={isShowResetModal}
      onCancel={onCancel}
      width={380}
      footer={null}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <div style={contentLayout}>
        {isShowResetResult ? (
          <div className='login-ResetPassword-2284'>
            <Icon
              type={'check-circle'}
              className='login-ResetPassword-2428'
            />
            <p> 重置密码成功！</p>
          </div>
        ) : (
          <Form className='login-ResetPassword-2588'>
            <Form.Item hasFeedback>
              {getFieldDecorator('rPhone', {
                rules: [
                  { required: true, message: '请输入手机号' },
                  {
                    pattern: '^[1][3,4,5,7,8,6,9][0-9]{9}$',
                    message: '手机号码格式错误'
                  }
                ]
              })(
                <Input
                  size={'large'}
                  className='login-ResetPassword-2990'
                  placeholder='手机号'
                  prefix={<img src={phone} />}
                />
              )}
            </Form.Item>
            <div className='login-ResetPassword-3275'>
              <Form.Item hasFeedback>
                {getFieldDecorator('rCode', {
                  rules: [{ required: true, message: '验证码为空' }]
                })(
                  <Input
                    className='login-ResetPassword-3530'
                    maxLength={6}
                    placeholder='验证码'
                  />
                )}
              </Form.Item>

              <Form.Item>
                {isShowResetButton ? (
                  <Button
                    className='login-ResetPassword-3896'
                    onClick={() => {
                      validateFieldsAndScroll(
                        ['rPhone'],
                        (errors: any, values: any) => {
                          if (errors) {
                            false && console.log('tag');
                          } else {
                            getAuthCodeMutation({
                              variables: { input: { phone: values.rPhone } }
                            })
                              .then(res => {
                                message.success('验证码已发送');
                                client.writeData({
                                  data: { isShowResetButton: false }
                                });
                              })
                              .catch(res => {
                                message.error('请求发送失败');
                              });
                          }
                        }
                      );
                    }}
                  >
                    获取验证码
                  </Button>
                ) : (
                  <CountDownText
                    className='login-ResetPassword-4520'
                    countType={'seconds'}
                    auto
                    afterEnd={() => {
                      client.writeData({ data: { isShowResetButton: true } });
                    }}
                    timeLeft={60}
                    step={-1}
                    startText={'获取验证码'}
                    endText={'获取验证码'}
                    intervalText={(sec: any) => `${sec}秒重新获取`}
                  />
                )}
              </Form.Item>
            </div>
            <Form.Item hasFeedback className='login-ResetPassword-5390'>
              {getFieldDecorator('rPassword', {
                rules: [
                  { required: true, message: '请输入6-16位密码' },
                  { min: 6, message: '密码小于6位' }
                ]
              })(
                <Input
                  size={'large'}
                  className='login-ResetPassword-5716'
                  maxLength={16}
                  type='password'
                  placeholder='请输入6-16位新密码'
                  prefix={<img src={pw} />}
                />
              )}
            </Form.Item>
            <Form.Item hasFeedback className='login-ResetPassword-6127'>
              {getFieldDecorator('rPassword1', {
                rules: [
                  { required: true, message: '请再次输入新密码' },
                  { validator: checkPassword }
                ]
              })(
                <Input
                  size={'large'}
                  className='login-ResetPassword-6396'
                  type='password'
                  maxLength={16}
                  placeholder='请再次输入新密码'
                  prefix={<img src={pw} />}
                />
              )}
            </Form.Item>
          </Form>
        )}
        {isShowResetResult ? null : (
          <Button
            className='login-ResetPassword-6836'
            type="danger"
            onClick={reSet}
            loading={resetLoading}
          >
            重置密码
          </Button>
        )}
      </div>
    </Modal>
  );
};

const ResetPasswordWrapper = Form.create()(ResetPassword);

export default ResetPasswordWrapper;
