import * as React from 'react';
import './styles.css';

//引入的库组件
import styled from 'styled-components';
import { useApolloClient } from 'react-apollo';
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { Layout, Menu, message} from 'antd';
import { withRouter } from 'react-router';

//graphql查询

//引入的其他页面组件
import EduManagerCoursePage from '../EduManagerCoursePage';
import EduManagerPackagePage from '../EduManagerPackagePage';

//引入的资源图片
import bg from '../../assets/omcbg/bg_small.png';

const CustomMenu = styled(Menu)`
  .ant-menu-item {
  }

  .ant-menu-item-selected {
  }
`;

const GET_INFOS_FOR_MANAGER= gql`
{
  userInfo @client {
    role
  } 
  managerItem @client
}
`;
const renderContent = (managerItem: string) => {
  switch(managerItem){
    case 'course':
    return (
      <EduManagerCoursePage />
    );
    case 'package':
    return (
      <EduManagerPackagePage />
    );
    // case 'video':
    // return (
    //   <VideoManager />
    // );
  }
}

const EduManagerPage = (props: any) => {
  const client = useApolloClient();
  const { data } = useQuery(GET_INFOS_FOR_MANAGER);
  const { userInfo, managerItem } = data;


  if(userInfo.role === "USER") {
    props.history.push("/404");
    return (
      null
    );
  }

  if(userInfo.role === "COLLABORATOR" && managerItem === "course") {
    client.writeData({ data: { managerItem: 'package', pMode: false } });
  }

  return (
    <Layout className='edu-Manager-852'>
      <div
        style={{
          backgroundImage: `url(${bg})`,
          width: '100%',
          height: 120,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div className='edu-Manager-1063'>
          <div className='edu-Manager-1105'>教学管理中心</div>
          <CustomMenu
            mode='horizontal'
            className='edu-Manager-1356'
            selectedKeys={[`${managerItem}`]}

            onSelect={item => {
              switch (item.key) {
                case 'course':
                  if (userInfo.role === "COLLABORATOR") {
                    message.warning('无权限');
                    return;
                  }
                  client.writeData({ data: { managerItem: 'course' } });
                  break;
                case 'package':
                    client.writeData({ data: { managerItem: 'package' } });
                  break;
                case 'video':
                    client.writeData({ data: { managerItem: 'video' } });
                  break;
                default:
              }
            }}
          >
            <Menu.Item key='course' className='edu-Manager-2062'>
              课程
            </Menu.Item>
            <Menu.Item key='package' className='edu-Manager-2142'>
              卡包
            </Menu.Item>
            {/* <Menu.Item key="video" className="edu-Manager-2212">视频管理</Menu.Item> */}
          </CustomMenu>
        </div>
      </div>
      {
        renderContent(managerItem)
      }
    </Layout>
  );
};

export default withRouter(EduManagerPage);
