import React, { FC, useEffect } from 'react';
import { useApolloClient } from 'react-apollo';
import Upload from 'components/Upload';
import { useQuery } from 'react-apollo-hooks';
import { query, writeQuery_avatar } from '../cache';
import '../style.less';

interface IAvatarProps {
  avatar: string;
  onChange: (avarar: string) => void;
}



const Avatar: FC<IAvatarProps> = ({ avatar, onChange }) => {
  const client = useApolloClient();

  // 读取缓冲
  const { data: cache } = useQuery(query);
  const { avatar: url } = cache.my;

  const writeQuery = (avatar: string) => {
    client.writeQuery({
      query: writeQuery_avatar,
      data: {
        my: {
          avatar,
          __typename: 'my'
        }
      }
    });
  };

  useEffect(() => {
    if (avatar) {
      writeQuery(avatar);
    }
  }, [avatar]);

  return (
    <>
      <div className='my-avatar'>
        <img src={url} alt='avatar' className="avatar-normal" />
        <p className="my-self-my-avatar-p">头像</p>
        <img src={url} alt='avatar' className="avatar-mobile"/>
        <Upload
          btnValue='上传头像'
          describe='支持JPG、PNG图片，且文件不能超过5M'
          type={['image/jpeg', 'image/png']}
          size={5}
          onSuccess={avatar => {
            writeQuery(avatar);
            onChange(avatar);
          }}
        />
      </div>
      <div className='placeholder'></div>
    </>
  );
};

export default Avatar;
