/**
 * @description 收藏页面的状态
 */

const collectionPage = {
  collectionLoading: false,
  collectionShowData: [],
  collectionType: 'card',
  tagsInDiscuss: [],
  collectionShowDataTotalCount: 0,
  isShowCollectionList: true,
  collectionType: 'card', //course homework discuss

  isShowCollectionList: true,
  collectionCardData: [],
  collectionHomeworkData: [],
  collectionDiscussData: [],

  //收藏页面详细
  collectionDetailTitle: '',
  collectionDetailInfo: {},
  collectionDetailIndex: 0,

  collectionHomeworkDetailInfo: {},

  examAnswerInfo: {},
  cardData: '',
  shareCardKey: '',
  side: false,

  //  讨论详情
  discDetail: '',
  // 是否收藏
  isCol: false,
  // 讨论标签
  discDetailLabels: [],
  // 评论数
  commentList: [],
  // 索引
  collectionDetailIndex: 0,
  // 收藏的讨论ID
  discussionCollectionId: '',
  // 收藏
  isCollectedInDiscussion: false,
};

export default collectionPage;
