import React from 'react';
import './style.less';

// 第三方库
import gql from 'graphql-tag';
import copy from 'copy-to-clipboard';
import { message } from 'antd';
import { useQuery, useApolloClient } from '@apollo/react-hooks';

// 图片
import full_screen from '../../../../../assets/v2.1/ic_course_card_fullscreen.png';
import favorite from '../../../../../assets/icons/favorite.png';
import favorite_active from '../../../../../assets/icons/favorite_active.png';
import shareIcon from '../../../../../assets/v2.1/Shape.png';
// 收藏和未收藏的接口
import {
  useCollectCardMutation,
  useUnCollectMutation
} from '../../../../../generated/graphql.tsx';
import gaEvents from '../../../../../utils/gaEvents.js';

const CARD_LEARN_PEOPLE = gql`
  {
    cardLearnPeople @client
  }
`;

const CARD_COLLECT = gql`
  {
    contentCardCollect @client
    courseTitle @client
  }
`;

const GET_BASIC = gql`
  {
    courseId @client
    currentCardId @client
  }
`;

const FULL_SCREEN = gql`
  {
    fullScreen @client
  }
`;
const CardFrontFooter = props => {
  const client = new useApolloClient();
  const {
    data: { contentCardCollect, courseTitle }
  } = useQuery(CARD_COLLECT);
  const {
    data: { courseId, currentCardId }
  } = useQuery(GET_BASIC);
  const {
    data: { fullScreen }
  } = useQuery(FULL_SCREEN);
  // 收藏
  const [collect] = useCollectCardMutation({ skip: true });
  // 未收藏
  const [unCollect] = useUnCollectMutation({ skip: true });

  const {
    data: { cardLearnPeople },
    loading
  } = useQuery(CARD_LEARN_PEOPLE);
  if (loading) {
    return null;
  }

  return (
    <div
      className={
        props.isCollect
          ? 'card-content-footer-inCollect'
          : 'card-content-footer'
      }
    >
      <div className='new-routes-study-cardModal-1625'>
        {cardLearnPeople}学习过
      </div>
      {props.isCollect ? (
        <div className='new-routes-study-cardModal-1638'></div>
      ) : (
        <div
          className='new-routes-study-cardModal-1638'
          onClick={() => {
            client.writeData({ data: { fullScreen: !fullScreen } });
          }}
        >
          <img className='new-routes-study-cardModal-1669' src={full_screen} />
          <div className='new-routes-study-cardModal-1676'>
            {fullScreen ? '退出全屏模式' : '全屏模式'}
          </div>
        </div>
      )}

      <div
        className='card-content-share'
        onClick={() => {
          const cardId = window.atob(currentCardId).split('Card:')[1];
          const splitPathname = window.location.pathname.split('/');

          const sharePath =
            window.location.origin +
            '/' +
            splitPathname[1] +
            '/' +
            splitPathname[2] +
            '/content' +
            '/' +
            cardId;

          copy(sharePath);
          message.success('卡片链接已复制');

          gaEvents.gaEventsUpload({
            eventName: `点击-${props.isCollect ? "-收藏页-" : ""}卡片页-${courseTitle}-${
              props.content.title
            }-分享`
          });
        }}
      >
        <img src={shareIcon} style={{ marginRight: '6px' }} />
        <div className='cardModal-share-title'>分享</div>
      </div>
      <div
        className='card-content-collection'
        onClick={() => {
          // 首先需要一个缓冲在Apollo的状态去更改状态
          // 首先更改的是 最简单的就是假数据
          // 如果为真，说明要取消
          // 需要两种状态配合
          const cardId = window.atob(currentCardId).split('Card:')[1];
          const wholeCourseId = Number(
            window.atob(courseId).split('Course:')[1]
          );
          if (!contentCardCollect) {
            collect({
              variables: {
                input: { cardId, courseId: wholeCourseId }
              }
            }).then(res => {
              if (res.data.collectCard.errors === null) {
                // 更改isCollected的值
                client.writeQuery({
                  query: CARD_COLLECT,
                  data: {
                    contentCardCollect: true
                  }
                });
                gaEvents.gaEventsUpload({
                  eventName: `点击-${props.isCollect ? "-收藏页-" : ""}卡片页-${courseTitle}-${
                    props.content.title
                  }-收藏`
                });
              }
            });
          } else {
            unCollect({
              variables: {
                input: { cardId, courseId: wholeCourseId }
              }
            }).then(res => {
              client.writeQuery({
                query: CARD_COLLECT,
                data: {
                  contentCardCollect: false
                }
              });
              gaEvents.gaEventsUpload({
                eventName: `点击-${props.isCollect ? "-收藏页-" : ""}卡片页-${courseTitle}-${
                  props.content.title
                }-取消收藏`
              });
            });
          }
        }}
      >
        <img
          // 需要另一个字段，两种状态控制
          src={contentCardCollect ? favorite_active : favorite}
          className='new-routes-study-cardModal-1655'
        />
        <div className='cardModal-share-collection'>收藏</div>
      </div>
    </div>
  );
};

export default CardFrontFooter;
