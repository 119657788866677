import { IProps } from 'components/Card';

import cognitive from 'assets/college/cognitive.svg';
import growth from 'assets/college/growth.svg';
import hack from 'assets/college/hack.svg';
import wirte from 'assets/college/wirte.svg';
import openmind from 'assets/college/openmind.svg';
import cognitivebg from 'assets/college/mind@2x.png';
import hackbg from 'assets/college/hack@2x.png';
import openmindbg from 'assets/college/openmind@2x.png';
import growthbg from 'assets/college/growth@2x.png';
import writebg from 'assets/college/write@2x.png';

export interface Home {
  collegeCourses: CollegeCourse[];
  studentCourses: StudentCourse[];
  coachs: Coach[];
  colleges: College[];
  __typename: 'home';
}

const home: Home = {
  collegeCourses: [],
  studentCourses: [],
  coachs: [
    {
      avator: 'https://static.openmindclub.com/2022-01-14-coach-002kongfei.jpeg',
      userName: '空飞',
      workName: ['前日语翻译 现自由职业'],
      description:
        '极其幸运入坑神作行为分析课。学有小成，识人识己，成人成己；进一步精进，成为人生发展评估师，发展第二职业，名利双收。再往前走，站在高师的肩膀上，下一位大师就是你。',
      __typename: 'coach'
    },
    {
      avator: 'https://static.openmindclub.com/2022-01-14-coach-004junya.png',
      userName: '李俊雅',
      workName: ['教育行业创业者', '快写慢改写作课教练'],
      description:
        '写作，于你于我于他，不仅是必备的基本技能，更是别致的生活方式。从想出来到写出来，从写得慢到写得快，从写得好到写得美，参加“快写慢改”课程吧。在这里，让写作如同呼吸，变得容易，成为习惯，不可或缺。',
      __typename: 'coach'
    },
    {
      avator:
        'https://static.openmindclub.com/2022-01-14-coach-003-haochen.jpeg',
      userName: '鱼尾纹先生（郝晨）',
      workName: ['北京大学硕士'],
      description:
        '人与人为什么不同？为什么别人习以为常的观点，在你看来会匪夷所思？你是否也曾落入行动怪圈，年初计划总是半途而废？人人都是科学家，你需要解锁一套更有效的行为预判和行为改变框架。',
      __typename: 'coach'
    },
    {
      avator:
        'https://static.openmindclub.com/2022-01-14-381642134831_.pic.jpg',
      userName: '高耀辉',
      workName: ['互联网游戏从业者'],
      description:
        '我是谁，从哪里来，要到哪里去。也许你一直在思索但还没找到答案，从行为分析到行为预判，从了解自己到改变自己，让行为分析帮助你拂去覆盖在答案上的面纱，修已已敬，修己安人。',
      __typename: 'coach'
    },
    {
      avator: 'https://static.openmindclub.com/2022-01-14-coach-005xiaoyun.jpeg',
      userName: '晓云',
      workName: ['大学英语教师'],
      description:
        '秋月春花，夏风冬雪。凝眸蹙眉，大笑流泪。四季见美景，处处藏温情。你潺潺不断的文字，是溪流，是心流，通往你自己，通往他人，通往世界。让这写作的流，滋养你，滋养你的季节，滋养你的心花，滋养这个世界。',
      __typename: 'coach'
    },
    {
      avator: 'https://static.openmindclub.com/2022-01-14-coach-006waiwai.jpeg',
      userName: '歪歪',
      workName: ['自由职业者'],
      description:
        '有风自南，意象写作如夏日南风，吹走午后的烦闷，吹走写作的恐惧。遇上快写慢改课，从不会写到金点子蹦蹦哒哒跳出来，从此，这个世界鲜活起来。一个人写作有点孤单，你，愿意跟我一起观看世界那扇窗吗？',
      __typename: 'coach'
    },
    {
      avator:
        'https://static.openmindclub.com/2022-01-14-coach-007guyan.jpeg',
      userName: '古严',
      workName: ['管理咨询公司创业者'],
      description:
        '过往我用热情的心和死磕的行为却换得屈指的果，探究发现其实在被动吸收包装后的伪知识。在这里我用自己的蜕变告诉你：科学阅读，不仅用跨时代牛人的正统思想激活行动，还能享受到源源不断的愉悦多巴胺',
      __typename: 'coach'
    },
    {
      avator:
        'https://static.openmindclub.com/2022-01-14-coach-008weiming.png',
      userName: '微明',
      workName: ['外贸行业创业者'],
      description:
        '有一个魔法世界和真实世界同时存在。它包罗万象，精深幽微，藏有无数有趣魔法。这个世界仿佛触手可及，但多数人不得其门而入。开智是通往魔法世界的一扇门，阅读营是你探索魔法世界的练习基地。欢迎你，探险者。',
      __typename: 'coach'
    },
    {
      avator:
        'https://static.openmindclub.com/2022-01-14-coach-009wusansui.jpeg',
      userName: '吴三岁（吴文辉）',
      workName: ['大学教师', '阅读课教练'],
      description:
        '书品如人品。正是那些我们看过的书，写过的文，让我们成为自己理想的人。在这里，你会眺览学科教材，溯流而上；也会研读经典名著，心流涓涓；更会品读流行科普，纵情泛舟而出。读书如逆旅，你可愿结伴同游？',
      __typename: 'coach'
    },
    {
      avator:
        'https://static.openmindclub.com/2022-01-14-coach-010jessie.png',
      userName: 'Jessie',
      workName: ['快消品品牌营销负责人'],
      description:
        '阅读，是获取文化资本的必经之路，也是性价比最高的方式。好书能帮助我们更好的理解这个世界，更有体系的寻觅知识的源头；好的阅读方法能让我们更有效的吸纳知识，更迅速的与作者产生共鸣。阅读是一场壮丽的长途旅行，时间的复利最终会让我们看到：每一步，都算数。',
      __typename: 'coach'
    },
    {
      avator: 'https://static.openmindclub.com/2019-08-05-HeShenXian.jpeg',
      userName: '小何（何燊贤）',
      workName: ['浙江大学博士'],
      description:
        '打开信息分析宝盒，向上演绎，开上帝视角；向下分解，用变量思维。从原始数据出发，占据核心信息源。在这里，你我将取一瓢稀缺的信息不对称，在信息汪洋中泛舟悠扬。',
      __typename: 'coach'
    },
    {
      avator: 'https://static.openmindclub.com/2019-08-05-LiRenChong.jpg',
      userName: '李仁冲',
      workName: ['极燃动力创始人', '信息分析课程教练'],
      description:
        '商战，生死存亡，一线之间。有幸遇上信息分析，抢占信息高地，俯瞰全局，用信息不对称战胜未来不确定。手握信息分析之剑，运筹帷幄之中，决胜千里之外。你，一起前行吗？',
      __typename: 'coach'
    },
    {
      avator: 'https://static.openmindclub.com/2019-08-05-ZhongWei.jpeg',
      userName: '钟慰',
      workName: ['金融圈IT主管'],
      description:
        '结缘论证分析，我意识到理性可如肌肉一般锤炼。在课上，沿着大师们的脚印，跟随经典中的金带，用认知科学和逻辑学打造秘密武器。在信息大海中，破浪驰骋，武装你的大脑。',
      __typename: 'coach'
    },
    {
      avator:
        'https://static.openmindclub.com/2019-08-13-IMG_20190813_182049.jpg',
      userName: '周平',
      workName: ['外贸从业者'],
      description:
        '这是一个神奇的地方。从小白开始，一步步解锁技能，过程可追溯，结果可比较。还有优秀同侪，一路相互扶持，不断征服高峰，未来路上不再孤军奋战。想要多大收获，就看你有多大决心。Are you OK?',
      __typename: 'coach'
    },
  ],
  colleges: [
    {
      path: 'cognitive',
      icon: cognitive,
      title: '认知学院',
      introduce:
        '以认知科学知识为基础，结合领域最佳实践，快速提升核心认知能力，提升你的生产力',
      bgcolor: '#F24C5A',
      cover: cognitivebg,
      __typename: 'college'
    },
    {
      path: 'hacker',
      icon: hack,
      // title: '技术学院',
      title: '效率学院',
      introduce:
        '开智学堂与旗下品牌「活水智能」共建课程，用AI提升知识工作者的生产力。',
      cover: hackbg,
      bgcolor: '#124AA6',
      __typename: 'college'
    },
    {
      path: 'growth',
      icon: growth,
      title: '教练学院',
      introduce: 
        '开智学堂与旗下品牌「东木咨询」共建课程，用人生发展学促进知识工作者的人生发展。',
      cover: growthbg,
      bgcolor: '#5BD7A0',
      __typename: 'college'
    },
    {
      path: 'writer',
      icon: wirte,
      title: '写作学院',
      introduce:
        '开智学堂与旗下品牌「初火创作」共建课程，帮助知识工作者创作自己的作品，用作品说话。',
      cover: writebg,
      bgcolor: '#EF7D31',
      __typename: 'college'
    },
    // {
    //   path: 'reader',
    //   icon: wirte,
    //   title: '阅读学院',
    //   introduce:
    //     '探索文字之美，心智之奇，用审美与故事，深入人性，创造作品。提升你的生产力',
    //   cover: writebg,
    //   bgcolor: '#5BD7A0',
    //   __typename: 'college'
    // },
    {
      path: 'OpenMindPlus',
      icon: openmind,
      title: 'OpenMind+',
      introduce:
        '天南地北的伙伴们聚集于此，开放探讨，热情创作，推动改变。开智创造者学习社区欢迎你！',
      cover: openmindbg,
      bgcolor: '#F4404F',
      __typename: 'college'
    }
  ],
  __typename: 'home'
};

export default home;

// 课程
export interface Course extends IProps {
  shortUrl: string;
  college?: string;
}

// 学院
export interface College {
  path: string;
  icon: string;
  title: string;
  introduce: string;
  bgcolor: string;
  cover: string;
  __typename: 'college';
}

// 学院课程
export interface CollegeCourse {
  title: string;
  to: string;
  courses: Course[];
}

// 学员好评，原教练介绍
export interface Coach {
  avator: string;
  userName: string;
  workName: string[];
  description: string;
  __typename: 'coach';
}

// 学生作品
export interface StudentCourse extends IProps {
  shortUrl: string;
}
