import React, { FC, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useApolloClient } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import { query, writeQuery } from './cache';
import { College } from 'global_variable/home';
import { LIST_COLLEGES } from 'pages/Courses/query';
import Banner from './components/Banner';
import Course from './components/Course';
import './style.less';

const Colleges: FC<RouteComponentProps> = ({ history }) => {
  const client = useApolloClient();

  const { pathname } = history.location;
  const collegeType = pathname.split('/')[2];

  // 读取缓冲信息
  const { data: cache } = useQuery(query);
  const { colleges } = cache.home;
  const { collegesCourses } = cache.collegeDetail;

  // 当前学院的配置信息
  const college = colleges.find((item: College) => item.path === collegeType);

  // 获取学院列表
  const { data } = useQuery(LIST_COLLEGES);

  // 筛选当前学院的课程类型
  useEffect(() => {
    try {
      if (data && Object.keys(data).length !== 0) {
        const result = data.courseCategories;
        const colleges = result.filter((item: any) => item.parent === null);
        const courses = result.filter((item: any) => item.parent !== null);
        const collegeId = colleges.find(
          (item: any) => item.name === college.title
        );
        const collegesCourses = courses.filter(
          (item: any) => item.parent.id === collegeId.id
        );
        client.writeQuery({
          query: writeQuery,
          data: {
            collegeDetail: {
              collegesCourses,
              __typename: 'collegeDetail'
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  return (
    <div className='college-detail'>
      <Banner college={college} />
      <Course collegesCourses={collegesCourses} title={college.title} />
    </div>
  );
};
export default withRouter(Colleges);
