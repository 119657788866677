import * as React from 'react';
import './styles.css';

//引入的库组件
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

//引入的其他页面组件
import DiscussCheckHead from './DiscussCheckHead';
import DiscussCheckInput from './DiscussCheckInput';
import DiscussCheckList from './DiscussCheckList';

//graphql查询
import { useGetDiscussDetailQuery } from '../../../../generated/graphql';
//引入的资源图片

const GET_DISCUSS_DETAIL_INFO = gql`
  {
    discussDetailId @client(always: true)
    discussCountPerPage @client
    courseId @client(always: true)
  }
`;

const DiscussCheckPageContainer = () => {
  const { data: detailInfo } = useQuery(GET_DISCUSS_DETAIL_INFO);
  const { discussDetailId, discussCountPerPage, courseId } = detailInfo;
  const { data: discussData, loading, error, refetch: getDiscussComment } = useGetDiscussDetailQuery({
    variables: { discussID: discussDetailId, courseId: courseId, first: discussCountPerPage, last : undefined, before: undefined, after: undefined },
    fetchPolicy: 'network-only'
  });

  const [isRefresh, setIsRefresh] = React.useState(false);
  React.useEffect(() => {
    if(courseId !== "" && discussDetailId !== "") {
      getDiscussComment();
    }
  }, [courseId, discussDetailId]);

  if (!discussData || loading || error || courseId === "" || discussDetailId == "") {
    return <div />;
  }

  const refresh = () => {
    setIsRefresh(!isRefresh);
  }


  return (
    <div className='new-routes-study-courseDiscussion-1000'>
      <DiscussCheckHead discussData={discussData} getDiscussComment={getDiscussComment} refresh={refresh} />
      <div className='new-routes-study-courseDiscussion-2409'>
        { discussData!.node!.status ? <DiscussCheckInput discussData={discussData} getDiscussComment={getDiscussComment}/> : null}
        { discussData!.node!.status ? <div className="new-routes-study-courseDiscussion-2508" /> : null}
        <DiscussCheckList discussData={discussData}  getDiscussComment={getDiscussComment}/>
      </div>
    </div>
  );
};

export default DiscussCheckPageContainer;
