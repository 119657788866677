/**
 * @description 递归查找某一张卡片的属性的isLearned的状态
 * @param {*} data 目录层级数据
 * @param {*} currentCard 每一张卡片的对象
 */
export function updateIsLearnedState(data, currentCard) {
  return data.map(item => {
    if (item.children) {
      updateIsLearnedState(item.children, currentCard);
    } else {
      if (item.id === currentCard.id) {
        item.isLearned = true;
      }
    }
    return item;
  });
}
