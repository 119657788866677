import * as React from 'react';
import './styles.css';

//引入的库组件
import { withRouter } from 'react-router';

//引入的其他页面组件
import CourseEditPage from './CourseEditPage';

//graphql查询
import { useGetCategoryInfoInEditQuery } from '../../generated/graphql';

//引入的资源图片


const CreateCourseContainer = (props: any) => {
    const { data, error, loading } = useGetCategoryInfoInEditQuery();
    if(error || loading || !data ) {
        return <div />;
    }

    return  <CourseEditPage courseData={null} props={props} categoryData={data} />;;
}

export default withRouter(CreateCourseContainer);
