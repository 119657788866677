import * as React from 'react';
import './styles.css';

//引入的库组件
import {
  Form,
  message,
  Layout,
  Radio,
  Breadcrumb,
  Select,
  DatePicker,
  Checkbox,
  Upload,
  Cascader,
  Input
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import styled from 'styled-components';
import moment from 'moment';
import { EditorState } from 'draft-js';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import itemRender from '../CommonComponents/ItemRender';
import { WhiteButton, RedButton } from '../CommonComponents/CustomButton';
import { checkMDContent } from '../../utils/checkForm';
import MDEditor from '../CommonComponents/MDEditor/MDEditor';
import Modal2CallbackFunc from '../CommonComponents/Modals/Modal2CallbackFunc';
import Modal3CallbackFunc from '../CommonComponents/Modals/Modal3CallbackFunc';
import config from '../../config';
import headers from '../../global_variable/headers';

//graphql查询
import {
  GetCourseInfoInEditQuery,
  GetCategoryInfoInEditQuery,
  useCreateCourseMutation,
  useUpdateCourseMutation,
  useOpenCloseDiscussionMutation,
  useReplacePackageForCourseMutation,
  ReplacePackageForCourseInput,
  useDeleteCoursePackageMutation,
} from '../../generated/graphql';

//引入的资源图片
import uploadImg from '../../assets/icons/upload.png';

interface Props extends FormComponentProps {
  courseData: GetCourseInfoInEditQuery | null;
  categoryData: GetCategoryInfoInEditQuery | null;
  props: any;
}

const routes = [
  {
    path: '//edu',
    breadcrumbName: '教研中心'
  },
  {
    path: '/',
    breadcrumbName: '课程'
  },
  {
    path: '',
    breadcrumbName: '创建一门新课程'
  }
];

const CustomSelect: any = styled(Select)`
  font-size: 14px !important;
`;

CustomSelect.Option = styled(Select.Option)`
  font-size: 14px;
  color: #282a2d;
  background-color: #ff4141;
  border: 1px solid #93979f;
  &:hover {
    color: #fff !important;
  }
`;

const GET_EDIT_COURSE_INFO = gql`
  {
    qiniuToken @client
  }
`;

const contentStyle = { backgroundColor: '#fff', width: 1000 };
const formLayout = { wrapperCol: { span: 20 }, labelCol: { span: 4 } };

const initCategoryInfo = (categoryData: any) => {
  let categoriesArray = categoryData.courseCategories;
  const mappings: { [key: string]: string } = {
    效率学院: 'AI课堂',
    认知学院: '认知课堂',
    写作学院: '创作课堂',
    教练学院: '人生课堂',
    阅读学院: '阅读课堂'
  }
  let collegesArray = categoriesArray.filter((item: any) => {
    return item.parent === null;
  });
  let coursesArray = categoriesArray.filter((item: any) => {
    return item.parent !== null;
  });

  let colleges: any[] = [];
  collegesArray.map((item: any, index: any) => {
    let children: any[] = [];
    coursesArray.map((courseItem: any) => {
      if (courseItem.parent.id === item.id) {
        children.push({
          value: JSON.stringify({
            id: courseItem.id,
            name: courseItem.name,
            parent: courseItem.parent
          }),
          label: courseItem.name
        });
      }
    });
    colleges.push({
      value: JSON.stringify({
        id: item.id,
        name: item.name,
        parent: item.parent
      }),
      label: mappings[item.name] || item.name,
      children: children
    });
  });

  return colleges;
};

const initForms = (editCourseData: any, props: any, stateFunc: any) => {
  const { setFieldsValue } = props.form;

  let selectedCourseArray = editCourseData.coursesPackages.filter(
    (item: any) => {
      return item.packageTag === 'CONTENT';
    }
  );
  let selectedCourseKey =
    selectedCourseArray.length > 0 ? selectedCourseArray[0].id : '';
  let selectedCourseName =
    selectedCourseArray.length > 0 ? selectedCourseArray[0].package.name : '';
  let hasCourse = selectedCourseKey !== '' && selectedCourseName !== '';

  let selectedHomeworkArray = editCourseData.coursesPackages.filter(
    (item: any) => {
      return item.packageTag === 'ASSIGNMENT';
    }
  );
  let selectedHomeworkKey =
    selectedHomeworkArray.length > 0 ? selectedHomeworkArray[0].id : '';
  let selectedHomeworkName =
    selectedHomeworkArray.length > 0
      ? selectedHomeworkArray[0].package.name
      : '';
  let hasHomework =
    selectedHomeworkKey !== '' &&
    selectedHomeworkName !== '';

  let selectedDiscussArray = editCourseData.coursesPackages.filter(
    (item: any) => {
      return item.packageTag === 'DISCUSSION';
    }
  );
  let selectedOpenDiscuss = selectedDiscussArray.length > 0 ? selectedDiscussArray[0].status : false;

  let labels = '';
  if (editCourseData.labels) {
    editCourseData.labels.map((item: string, index: number) => {
      labels = labels + item;
      if (index < editCourseData.labels.length - 1) {
        labels = labels + ' ';
      }
    });
  }

  let courseType = [];

  if (editCourseData.category && editCourseData.category.parent) {
    let courseType_0 = JSON.stringify({
      id: editCourseData.category.parent.id,
      name: editCourseData.category.parent.name,
      parent: null
    });
    let courseType_1 = JSON.stringify({
      id: editCourseData.category.id,
      name: editCourseData.category.name,
      parent: editCourseData.category.parent
    });
    courseType.push(courseType_0, courseType_1);
  }

  try {
    stateFunc.setCover(editCourseData.cover);
    stateFunc.setTitle(editCourseData.title);
    stateFunc.setSubTitle(editCourseData.subTitle);
    stateFunc.setHost(editCourseData.instructors);
    stateFunc.setStart_time(moment(editCourseData.registrationBegin));
    stateFunc.setEnd_time(moment(editCourseData.registrationEnd));
    stateFunc.setIs_graduate(editCourseData.isEnd);
    stateFunc.setIs_online(editCourseData.isOnline);
    stateFunc.setDescription(editCourseData.description);
    stateFunc.setIs_selling(editCourseData.isSelling);
    stateFunc.setIs_free(!editCourseData.isFee);
    stateFunc.setPrice(`${editCourseData.price}`);
    stateFunc.setPrice_url(editCourseData.chargingLink);
    stateFunc.setIsDiscuss(selectedOpenDiscuss);
    stateFunc.setSelectedHomework(selectedHomeworkKey);
    stateFunc.setSelectedCourse(selectedCourseKey);
    stateFunc.setHandleFree(!editCourseData.isFee);
    stateFunc.setShortUrl(editCourseData.shortUrl);
    stateFunc.setIsNeedUserProfile(editCourseData.needUserProfile);
    stateFunc.setLabels(labels);
    stateFunc.setCourseType(courseType);
  } catch (error) {
    console.log(error);
    message.error('获取数据出错');
  }

  setFieldsValue({
    title: editCourseData.title,
    subtitle: editCourseData.subTitle,
    host: editCourseData.instructors,
    cover: editCourseData.cover,
    shortURL: editCourseData.shortUrl,
    // introduce: {content: { markdown: editCourseData.description }},
    discuss: selectedOpenDiscuss,
    isEnd: editCourseData.isEnd ? 'yes' : 'no',
    isOnline: editCourseData.isOnline ? 'yes' : 'no',
    isSelling: editCourseData.isSelling ? 'yes' : 'no', //后期直接对接成数据中获取
    courseType: courseType,
    labels: labels,
    isNeedUserProfile: editCourseData.needUserProfile ? 'yes' : 'no'
  });

  if (hasHomework) {
    setFieldsValue({
      homeworkPackage: { key: selectedHomeworkKey, label: selectedHomeworkName }
    });
  }
  if (hasCourse) {
    setFieldsValue({
      coursePackage: { key: selectedCourseKey, label: selectedCourseName }
    });
  }
};

const CourseEditPage = (props: any) => {
  const [createCourse] = useCreateCourseMutation();
  const [updateCourse] = useUpdateCourseMutation();
  const [openCloseDiscuss] = useOpenCloseDiscussionMutation();
  const [replacePackage] = useReplacePackageForCourseMutation();
  const [deletePackage] = useDeleteCoursePackageMutation();

  const checkCPackage = (rule: any, value: any, callback: any) => {
    const { getFieldValue } = form;
    if (
      value &&
      getFieldValue('homeworkPackage') &&
      value.key &&
      getFieldValue('homeworkPackage').key &&
      value.key === getFieldValue('homeworkPackage').key
    ) {
      callback('已选作业卡包不能作为课程卡包');
    } else {
      callback();
    }
  };

  const checkHPackage = (rule: any, value: any, callback: any) => {
    const { getFieldValue } = form;
    if (
      value &&
      getFieldValue('coursePackage') &&
      value.key &&
      getFieldValue('coursePackage').key &&
      value.key === getFieldValue('coursePackage').key
    ) {
      callback('已选课程卡包不能作为作业卡包');
    } else {
      callback();
    }
  };

  const client = useApolloClient();
  const { form, courseData } = props;
  const { getFieldDecorator, setFieldsValue } = form;

  const [cover, setCover] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [subTitle, setSubTitle] = React.useState('');
  const [host, setHost] = React.useState('');
  const [start_time, setStart_time] = React.useState(
    moment(moment().format('YYYY-MM-DD'))
  );
  const [end_time, setEnd_time] = React.useState(
    moment(moment().format('YYYY-MM-DD'))
  );
  const [is_graduate, setIs_graduate] = React.useState(false);
  const [is_online, setIs_online] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [is_selling, setIs_selling] = React.useState(false);
  const [is_free, setIs_free] = React.useState('yes');
  const [price, setPrice] = React.useState('');
  const [price_url, setPrice_url] = React.useState('');
  const [is_discuss, setIsDiscuss] = React.useState(false);
  const [selectedHomework, setSelectedHomework] = React.useState({});
  const [selectedCourse, setSelectedCourse] = React.useState({});
  const [handleFree, setHandleFree] = React.useState(true);
  const [short_url, setShortUrl] = React.useState(true);
  const [isNeedUserProfile, setIsNeedUserProfile] = React.useState(true);
  const [labels, setLabels] = React.useState('');
  const [courseType, setCourseType] = React.useState([]);
  const [courseCategoriesForEdu, setCourseCategoriesForEdu] = React.useState(
    initCategoryInfo(props.categoryData)
  );

  let treeData = props.props.packageData.packages.edges;

  const { data } = useQuery(GET_EDIT_COURSE_INFO);
  const { qiniuToken } = data;

  let desc = '';
  if (props.props.isEditCourse) {
    desc = courseData.node.description;
  }

  React.useEffect(() => {
    if (props.props.isEditCourse) {
      let stateFunc = {
        setCover: setCover,
        setTitle: setTitle,
        setSubTitle: setSubTitle,
        setHost: setHost,
        setStart_time: setStart_time,
        setEnd_time: setEnd_time,
        setIs_graduate: setIs_graduate,
        setIs_online: setIs_online,
        setDescription: setDescription,
        setIs_selling: setIs_selling,
        setIs_free: setIs_free,
        setPrice: setPrice,
        setPrice_url: setPrice_url,
        setIsDiscuss: setIsDiscuss,
        setSelectedHomework: setSelectedHomework,
        setSelectedCourse: setSelectedCourse,
        setHandleFree: setHandleFree,
        setShortUrl: setShortUrl,
        setIsNeedUserProfile: setIsNeedUserProfile,
        setLabels: setLabels,
        setCourseType: setCourseType,
        setCourseCategoriesForEdu: setCourseCategoriesForEdu
      };
      initForms(courseData.node, props, stateFunc);
    }
  }, []);

  const fetchApi = (url: any, options: any) => {
    const fetchAPI = new Promise((resolve, reject) => {
      fetch(url, options).then((res: any) => {
        res.text().then((text: any) => {
          client.writeData({ data: { qiniuToken: text } });
          resolve("");
        });
      });
    });
    return Promise.race([
      fetchAPI,
      new Promise((resolve, reject) => {
        setTimeout(() => {
          reject('time out');
        }, 100000);
      })
    ]);
  };

  async function getQiniuToken() {
    let url = config.qiniuUri + 't/api/qiniu_token';
    let options = { method: 'GET', headers: headers };
    await fetchApi(url, options);
  }

  const beforeUpload = (file: any) => {
    const isPNG = file.type === 'image/png';
    const isJPG = file.type === 'image/jpeg';
    if (!(isJPG || isPNG)) {
      message.error('仅支持JPG、PNG格式图片!');
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('上传照片小于5M!');
      return false;
    }

    return getQiniuToken();
  };

  const handleCreate = () => {
    const isEditCourse = props.props.isEditCourse;
    const form = props.form;

    form.validateFieldsAndScroll(
      { first: true, force: true },
      (errors: any, values: any) => {
        if (errors) {
          return;
        }
        let labels = values.labels ? values.labels.split(' ') : [];
        let category_id =
          !values.courseType || values.courseType.length === 0
            ? null
            : JSON.parse(values.courseType[1]).id;
        let contentPackageId = values.coursePackage.key;
        let assignmentPackageId = values.homeworkPackage && values.homeworkPackage.key
        ? values.homeworkPackage.key
        : '';
        let courseID = courseData ? courseData.node.id : '';

        
        category_id = category_id ? Base64.decode(category_id).split("CourseCategory:")[1]  : null;
        contentPackageId = contentPackageId !== '' ? Base64.decode(contentPackageId).split("Package:")[1]  : null;
        assignmentPackageId = assignmentPackageId !== '' ? Base64.decode(assignmentPackageId).split("Package:")[1]  : null;
        courseID = courseID !== '' ? Base64.decode(courseID).split("Course:")[1] : '';

        if (!isEditCourse) {
          let payloadForCreate : any = {
            // assignmentPackageId: assignmentPackageId,
            categoryId: category_id,
            chargingLink: values.payUrl,
            // contentPackageId: contentPackageId,
            cover: cover,
            description: values.introduce.content.markdown,
            instructors: values.host,
            isEnd: values.isEnd === 'yes',
            isFee: !(values.isFree === 'yes'),
            isOnline: values.isOnline === 'yes',
            isSelling: values.isSelling === 'yes',
            labels: labels,
            needUserProfile: values.isNeedUserProfile === 'yes',
            openDiscussion: values.discuss ? values.discuss : false,
            price: parseFloat(values.price),
            registrationBegin: moment(values.date[0]).format('YYYY-MM-DD'),
            registrationEnd: moment(values.date[1]).format('YYYY-MM-DD'),
            shortUrl: values.shortURL,
            subTitle: values.subtitle,
            title: values.title
          };

          if(assignmentPackageId) {
            payloadForCreate = {
              assignmentPackageId: assignmentPackageId,
              ...payloadForCreate,
            }
          }

          if(contentPackageId) {
            payloadForCreate = {
              contentPackageId: contentPackageId,
              ...payloadForCreate,
            }
          }


          Modal3CallbackFunc.callback = () => {
            createCourse({ variables: { input: payloadForCreate } }).then(
              (res: any) => {
                Modal3CallbackFunc.callback = null;
                const { errors } = res.data.createCourse;
                if (errors) {
                  message.error('课程创建失败!');
                } else {
                  message.success('课程创建成功!');
                  props.props.history.push('/edu');
                }

                client.writeData({
                  data: {
                    modal3Loading: false,
                    modal3: {
                      show: false,
                      title: '',
                      content: '',
                      __typename: 'Modal3'
                    }
                  }
                });
              }
            );
          };

          client.writeData({
            data: {
              modal3: {
                show: true,
                title: '',
                content: '确定要保存课程吗',
                __typename: 'Modal3'
              }
            }
          });
        } else {
          let editCourseData = courseData ? courseData.node : null;

          let selectedCourseArray = editCourseData.coursesPackages.filter(
            (item: any) => {
              return item.packageTag === 'CONTENT';
            }
          );
          let selectedCourseKey =
            selectedCourseArray.length > 0 ? selectedCourseArray[0].id : '';
         
          let selectedHomeworkArray = editCourseData.coursesPackages.filter(
            (item: any) => {
              return item.packageTag === 'ASSIGNMENT';
            }
          );
          let selectedHomeworkKey =
            selectedHomeworkArray.length > 0 ? selectedHomeworkArray[0].id : '';

                    
          selectedCourseKey = selectedCourseKey !== '' ? Base64.decode(selectedCourseKey).split("Package:")[1]  : null;
          selectedHomeworkKey = selectedHomeworkKey !== '' ? Base64.decode(selectedHomeworkKey).split("Package:")[1]  : null;


          let openDiscuss = values.discuss ? values.discuss : false;
          let selectedDiscussArray = editCourseData.coursesPackages.filter(
            (item: any) => {
              return item.packageTag === 'DISCUSSION';
            }
          );
          let selectedOpenDiscuss = selectedDiscussArray.length > 0 ? selectedDiscussArray[0].status : false;

          const replaceCoursePackage = selectedCourseKey !== contentPackageId;
          const replaceAssignmentPackage = selectedHomeworkKey !== assignmentPackageId;
          const replaceOpenDiscuss = selectedOpenDiscuss !== openDiscuss;
        
          let payloadForUpdate : any = {
            categoryId: category_id,
            chargingLink: values.payUrl,
            cover: cover,
            description: values.introduce.content.markdown,
            id: courseID,
            instructors: values.host,
            isEnd: values.isEnd === 'yes',
            isFee: !(values.isFree === 'yes'),
            isOnline: values.isOnline === 'yes',
            isSelling: values.isSelling === 'yes',
            labels: labels,
            needUserProfile: values.isNeedUserProfile === 'yes',
            price: parseFloat(values.price),
            registrationBegin: moment(values.date[0]).format('YYYY-MM-DD'),
            registrationEnd: moment(values.date[1]).format('YYYY-MM-DD'),
            shortUrl: values.shortURL,
            subTitle: values.subtitle,
            title: values.title
          };

          // if(category_id) {
          //   payloadForUpdate = {
          //     categoryId: category_id,
          //     ...payloadForUpdate,
          //   }
          // }

          Modal3CallbackFunc.callback = () => {
            if(replaceOpenDiscuss){
              openCloseDiscuss({ variables: { input: { courseId: courseID, status: openDiscuss } } });
            }

            if(replaceAssignmentPackage) {
              if(assignmentPackageId) {
                replacePackage({ variables: { input: { courseId: courseID, packageId: assignmentPackageId, packageTag: "ASSIGNMENT" } as ReplacePackageForCourseInput } });
              }else {
                deletePackage({ variables: { input: { id: selectedHomeworkKey } } });
              }
            }

            if(replaceCoursePackage && contentPackageId) {
              replacePackage({ variables: { input: { courseId: courseID, packageId: contentPackageId, packageTag: "CONTENT" } as ReplacePackageForCourseInput } });
            }

            updateCourse({ variables: { input: payloadForUpdate } }).then(
              (res: any) => {
                Modal3CallbackFunc.callback = null;
                const { errors } = res.data.updateCourse;
                if (errors) {
                  message.error('课程更新失败!');
                } else {
                  message.success('课程更新成功!');
                  props.props.history.push('/edu');
                }

                client.writeData({
                  data: {
                    modal3Loading: false,
                    modal3: {
                      show: false,
                      title: '',
                      content: '',
                      __typename: 'Modal3'
                    }
                  }
                });
              }
            );
          };

          client.writeData({
            data: {
              modal3: {
                show: true,
                title: '',
                content: '确定要保存课程吗',
                __typename: 'Modal3'
              }
            }
          });
        }
      }
    );
  };

  return (
    <Layout className='edu-manager-CourseEditPage-7846'>
      <Layout.Content style={contentStyle}>
        <Breadcrumb
          separator={'›'}
          itemRender={itemRender}
          routes={routes}
          className='edu-manager-CourseEditPage-7851'
        />
        <div className='edu-manager-CourseEditPage-7893'>基本信息</div>
        <div className='edu-manager-CourseEditPage-7999' />

        <Form className='edu-manager-CourseEditPage-8095'>
          <Layout.Content className='edu-manager-CourseEditPage-8167'>
            <Form.Item
              colon={false}
              {...formLayout}
              label='课程封面'
              hasFeedback
            >
              {getFieldDecorator('cover', {
                rules: [{ required: true, message: '请选择课程封面' }]
              })(
                <Upload
                  className='edu-manager-CourseEditPage-8418'
                  // data={{ token: "xi3SRrlQdTt4Pi2HLuLtGkRQVHkw8P8fxt1yLaqc:No3z0c_aiqyrQUiIT4vOL06NggU=:eyJzY29wZSI6Im9wZW5taW5kY2x1Yi1zdGF0aWMiLCJkZWFkbGluZSI6MTU3MDE4MTE1MH0=" }}
                  data={{ token: qiniuToken }}
                  name='file'
                  listType='picture-card'
                  showUploadList={false}
                  action='https://up-z1.qiniup.com'
                  beforeUpload={file => beforeUpload(file)}
                  onChange={info => {
                    if (info.file.status === 'done') {
                      setFieldsValue({
                        cover: `https://static.openmindclub.com/${info.file.response.key}?imageView2/2/w/345/h/190`
                      });
                      setCover(
                        `https://static.openmindclub.com/${info.file.response.key}`
                      );
                    }
                  }}
                >
                  {cover === '' ? (
                    <div className='edu-manager-CourseEditPage-9233'>
                      <img src={uploadImg} data-rjs='2' />
                      <span className='edu-manager-CourseEditPage-9476'>
                        点击将文件拖拽到这里上传
                      </span>
                      <span className='edu-manager-CourseEditPage-9582'>
                        仅支持JPG、PNG图片且文件大小不超过5M
                      </span>
                    </div>
                  ) : (
                    <img
                      src={cover}
                      className='edu-manager-CourseEditPage-9793'
                      alt='cover'
                    />
                  )}
                </Upload>
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='课程标题'
              className='edu-manager-CourseEditPage-10010'
              hasFeedback
            >
              {getFieldDecorator('title', {
                rules: [{ required: true, message: '请输入课程标题' }]
              })(
                <Input
                  className='edu-manager-CourseEditPage-10151'
                  maxLength={32}
                />
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='副标题'
              className='edu-manager-CourseEditPage-10302'
              hasFeedback
            >
              {getFieldDecorator('subtitle', {
                rules: [{ required: true, message: '请输入课程副标题' }]
              })(
                <Input
                  className='edu-manager-CourseEditPage-10447'
                  maxLength={32}
                />
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='课程代码'
              className='edu-manager-CourseEditPage-10599'
            >
              {getFieldDecorator('shortURL', {
                rules: [{ required: true, message: '请输入课程代码' }]
              })(
                <Input
                  className='edu-manager-CourseEditPage-10732'
                  maxLength={32}
                />
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='课程类型'
              className='edu-manager-CourseEditPage-10599'
            >
              {getFieldDecorator('courseType', {
                rules: []
              })(
                <Cascader
                  className='edu-manager-CourseEditPage-10796'
                  options={courseCategoriesForEdu}
                  size='large'
                  placeholder='请选择'
                />
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='主讲人'
              className='edu-manager-CourseEditPage-10883'
            >
              {getFieldDecorator('host', { rules: [{}] })(
                <Input
                  className='edu-manager-CourseEditPage-10977'
                  maxLength={32}
                />
              )}
            </Form.Item>

            <div className='edu-manager-CourseEditPage-11075' />

            <Form.Item
              colon={false}
              {...formLayout}
              label='课程标签'
              className='edu-manager-CourseEditPage-11214'
            >
              {getFieldDecorator('labels', {
                rules: []
              })(
                <Input
                  className='edu-manager-CourseEditPage-10977'
                  placeholder='标签之间空格分开'
                />
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='报名时间'
              className='edu-manager-CourseEditPage-11903'
            >
              {getFieldDecorator('date', {
                initialValue: [start_time, end_time],
                rules: [{ required: true, message: '请选择报名时间' }]
              })(
                <DatePicker.RangePicker
                  format='YYYY-MM-DD'
                  onChange={(_, dateString) => {
                    setFieldsValue({ date: dateString });
                  }}
                  placeholder={['开始时间', '结束时间']}
                />
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='是否结业'
              className='edu-manager-CourseEditPage-12428'
            >
              {getFieldDecorator('isEnd', {
                initialValue: is_graduate ? 'yes' : 'no',
                rules: [{ required: true, message: '请确认是否结业' }]
              })(
                <Radio.Group>
                  <Radio value='yes'>是</Radio>
                  <Radio value='no'>否</Radio>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='是否上线'
              className='edu-manager-CourseEditPage-12856'
            >
              {getFieldDecorator('isOnline', {
                initialValue: is_online ? 'yes' : 'no',
                rules: [{ required: true, message: '请确认课程是否上线' }]
              })(
                <Radio.Group>
                  <Radio value='yes'>是</Radio>
                  <Radio value='no'>否</Radio>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='是否在售'
              className='edu-manager-CourseEditPage-12878'
            >
              {getFieldDecorator('isSelling', {
                initialValue: is_selling ? 'yes' : 'no',
                rules: [{ required: true, message: '请确认课程是否在售' }]
              })(
                <Radio.Group>
                  <Radio value='yes'>是</Radio>
                  <Radio value='no'>否</Radio>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='是否需要用户信息'
              className='edu-manager-CourseEditPage-12905'
            >
              {getFieldDecorator('isNeedUserProfile', {
                initialValue: is_selling ? 'yes' : 'no',
                rules: [
                  { required: true, message: '请确认课程是否需要用户信息' }
                ]
              })(
                <Radio.Group>
                  <Radio value='yes'>是</Radio>
                  <Radio value='no'>否</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Layout.Content>

          <div className='edu-manager-CourseEditPage-13261'>课程介绍</div>
          <div className='edu-manager-CourseEditPage-13345' />

          <Form.Item className='edu-manager-CourseEditPage-13448'>
            {getFieldDecorator('introduce', {
              validateTrigger: ['onBlur'],
              initialValue: { content: { markdown: desc } },
              rules: [{ validator: checkMDContent, message: '请输入课程介绍' }]
            })(
              <MDEditor
                width={1000}
                height={450}
                isBottom={false}
                minHeight={'398px'}
              />
            )}
          </Form.Item>

          <div className='edu-manager-CourseEditPage-13817'>收费信息</div>
          <div className='edu-manager-CourseEditPage-13901' />

          <Layout.Content className='edu-manager-CourseEditPage-14008'>
            <Form.Item colon={false} {...formLayout} label='是否收费'>
              {getFieldDecorator('isFree', {
                initialValue: is_free ? 'yes' : 'no',
                rules: [{ required: true }]
              })(
                <Radio.Group
                  onChange={e => {
                    form.resetFields(["price", "payUrl"]);
                    setHandleFree(e.target.value === 'yes');
                  }}
                >
                  <Radio value='yes'>免费</Radio>
                  <Radio value='no'>收费</Radio>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='课程费用'
              className='edu-manager-CourseEditPage-14702'
            >
              {getFieldDecorator('price', {
                initialValue: price,
                rules: [!handleFree && { required: true, message: '请输入课程费用' }]
              })(
                <Input
                  disabled={handleFree}
                  type='number'
                  className='edu-manager-CourseEditPage-14874'
                />
              )}{' '}
              ¥
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='收费链接'
              hasFeedback
              className='edu-manager-CourseEditPage-15026'
            >
              {getFieldDecorator('payUrl', {
                initialValue: price_url,
                rules: [!handleFree && { required: true, message: '请输入收费链接' }]
              })(
                <Input
                  disabled={handleFree}
                  className='edu-manager-CourseEditPage-15189'
                />
              )}
            </Form.Item>
          </Layout.Content>

          <div className='edu-manager-CourseEditPage-15298'>课程内容</div>
          <div className='edu-manager-CourseEditPage-15383' />

          <Layout.Content className='edu-manager-CourseEditPage-15492'>
            <Form.Item
              colon={false}
              {...formLayout}
              label='课程卡包'
              hasFeedback
            >
              {getFieldDecorator('coursePackage', {
                rules: [
                  { required: true, message: '请选择课程卡包' },
                  { validator: checkCPackage }
                ]
              })(
                <CustomSelect
                  placeholder='请选择课程卡包'
                  size={'large'}
                  className='edu-manager-CourseEditPage-15854'
                  labelInValue
                >
                  {treeData.map((item: any) => {
                    return (
                      <CustomSelect.Option value={item.node.id}>
                        {item.node.name}
                      </CustomSelect.Option>
                    );
                  })}
                </CustomSelect>
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              {...formLayout}
              label='作业卡包'
              className='edu-manager-CourseEditPage-16215'
              hasFeedback
            >
              {getFieldDecorator('homeworkPackage', {
                rules: [{ validator: checkHPackage }]
              })(
                <CustomSelect
                  placeholder='请选择作业卡包'
                  allowClear
                  size={'large'}
                  className='edu-manager-CourseEditPage-16461'
                  labelInValue
                >
                  {treeData.map((item: any) => {
                    return (
                      <CustomSelect.Option value={item.node.id}>
                        {item.node.name}
                      </CustomSelect.Option>
                    );
                  })}
                </CustomSelect>
              )}
            </Form.Item>
            <Form.Item
              colon={false}
              {...formLayout}
              label='开启讨论'
              className='edu-manager-CourseEditPage-16820'
            >
              {getFieldDecorator('discuss', {
                valuePropName: 'checked',
                rules: []
              })(<Checkbox />)}
            </Form.Item>
          </Layout.Content>
        </Form>

        <div className='edu-manager-CourseEditPage-17094'>
          <div className='edu-manager-CourseEditPage-17330'>
            <WhiteButton
              onClick={() => {
                Modal2CallbackFunc.callback = () => {
                  form.resetFields();
                  Modal2CallbackFunc.callback = null;
                  client.writeData({
                    data: {
                      modal2Loading: false,
                      modal2: {
                        show: false,
                        title: '',
                        content: '',
                        __typename: 'Modal2'
                      }
                    }
                  });
                  props.props.history.push('/edu');
                };
                client.writeData({
                  data: {
                    modal2Loading: false,
                    modal2: {
                      show: true,
                      title: '确定要取消保存课程吗?',
                      content: '取消保存后，所有编辑信息将被清空。',
                      __typename: 'Modal2'
                    }
                  }
                });
              }}
              width={'100px'}
              height={'40px'}
              className='edu-manager-CourseEditPage-18091'
            >
              取消保存
            </WhiteButton>
            <RedButton
              width={'100px'}
              height={'40px'}
              className='edu-manager-CourseEditPage-18236'
              onClick={() => {
                handleCreate();
              }}
            >
              保存课程
            </RedButton>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};

const CourseEditPageWrapper = Form.create<Props>()(CourseEditPage);

export default CourseEditPageWrapper;
