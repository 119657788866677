const addPackageDataToTreeData = (
  treeData: any[],
  packageData: any,
  pMode: boolean,
  typename: string
) => {
  let data = {
    collaborativeState: "",
    hasFolderChild: packageData.hasFolderChild,
    pid: 0,
    key: packageData.id,
    is_assistant: pMode ? 0 : 1,
    type: 0,
    title: packageData.name,
    children: [],
    __typename: typename
  };
  treeData.unshift(data);
};

const addCardDataToPackage = (
  treeData: any[],
  cardData: any,
  pMode: boolean,
  typename: string
) => {
  let isFind = false;
  const findCard = (treeDataChildren: any, cardData: any) => {
    for (let i = 0; i < treeDataChildren.length; i++) {
      if (treeDataChildren[i].key === cardData.parent.id) {
        let data = {
          collaborativeState: treeDataChildren[i].collaborativeState ? treeDataChildren[i].collaborativeState : "",
          hasFolderChild: treeDataChildren[i].hasFolderChild,
          pid: treeDataChildren[i].key,
          key: cardData.id,
          is_assistant: pMode ? 0 : 1,
          type: cardData.fileType === 'FOLDER' ? 0 : 1,
          title: cardData.name,
          children: [],
          __typename: typename
        };
        if (treeDataChildren[i].children) {
          treeDataChildren[i].children.push(data);
        }
        if (cardData.fileType === 'FOLDER') {
          treeDataChildren[i].hasFolderChild = true;
        }
        return;
      } else if (treeDataChildren[i].children) {
        findCard(treeDataChildren[i].children, cardData);
      }
    }
  };
  if (!cardData.parent) {
    for (let i = 0; i < treeData.length; i++) {
      if (treeData[i].key === cardData.package.id) {
        let data = {
          collaborativeState: treeData[i].collaborativeState ? treeData[i].collaborativeState : "",
          hasFolderChild: treeData[i].hasFolderChild,
          pid: treeData[i].key,
          key: cardData.id,
          is_assistant: pMode ? 0 : 1,
          type: cardData.fileType === 'FOLDER' ? 0 : 1,
          title: cardData.name,
          children: [],
          __typename: typename
        };
        if (treeData[i].children) {
          treeData[i].children.push(data);
        }
        if (cardData.fileType === 'FOLDER') {
          treeData[i].hasFolderChild = true;
        }
      }
    }
  } else {
    for (let i = 0; i < treeData.length; i++) {
      if (treeData[i].children) {
        findCard(treeData[i].children, cardData);
      }
      if (isFind) {
        return;
      }
    }
  }
};

const addChildDataToPackage = (
  treeData: any[],
  childData: any,
  isMaster: boolean,
  typename: string
) => {
  treeData.map(item => {
    if (item.key === childData.id) {
      let childrenNode = childData.rootCards.edges;
      let chilren = childrenNode.map((childItem: any) => {
        let pid = childItem.node.parent
          ? childItem.node.parent.id
          : childItem.node.package.id;
        return {
          collaborativeState: childItem.node.collaborativeState ? childItem.node.collaborativeState : "",
          hasFolderChild: childItem.node.hasFolderChild,
          pid: pid,
          key: childItem.node.id,
          is_assistant: isMaster ? 0 : 1,
          type: childItem.node.fileType === 'FOLDER' ? 0 : 1,
          title: childItem.node.name,
          children: [],
          __typename: typename
        };
      });
      item.children = chilren;
      return;
    } else {
      if (item.children && item.children.length > 0) {
        addChildDataToPackage(item.children, childData, isMaster, typename);
      }
    }
  });
};

const addChildDataToCard = (
  treeData: any[],
  childData: any,
  isMaster: boolean,
  typename: string
) => {
  treeData.map(item => {
    if (item.key === childData.id) {
      let childrenNode = childData.children;
      let chilren = childrenNode.map((childItem: any) => {
        let pid = childItem.parent ? childItem.parent.id : childItem.package.id;
        return {
          collaborativeState: childItem.collaborativeState ? childItem.collaborativeState : "",
          hasFolderChild: childItem.hasFolderChild,
          pid: pid,
          key: childItem.id,
          is_assistant: isMaster ? 0 : 1,
          type: childItem.fileType === 'FOLDER' ? 0 : 1,
          title: childItem.name,
          children: [],
          __typename: typename
        };
      });
      item.children = chilren;
      return;
    } else {
      if (item.children && item.children.length > 0) {
        addChildDataToCard(item.children, childData, isMaster, typename);
      }
    }
  });
};

const findPackageId = (treeData: any[], selectedFileId: string) => {
  let packageId = treeData[0].key;

  const findId = (treeData: any[], selectedFileId: string): boolean => {
    for (let i = 0; i < treeData.length; i++) {
      if (treeData[i].key === selectedFileId) {
        return true;
      } else if (treeData[i].children) {
        if (findId(treeData[i].children, selectedFileId)) {
          return true;
        }
      }
    }
    return false;
  };

  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].key === selectedFileId) {
      packageId = treeData[i].key;
      break;
    } else if (treeData[i].children) {
      if (findId(treeData[i].children, selectedFileId)) {
        packageId = treeData[i].key;
        break;
      }
    }
  }

  return packageId;
};

// const findDataIsInTreeData = (treeData: any[], DataId: string) => {
//   let isFind = false;

//   const findId = (treeData: any[], DataId: string): boolean => {
//     for (let i = 0; i < treeData.length; i++) {
//       if (treeData[i].key === DataId) {
//         return true;
//       } else if (treeData[i].children) {
//         return findId(treeData[i].children, DataId);
//       }
//     }
//     return false;
//   };

//   for (let i = 0; i < treeData.length; i++) {
//     if (treeData[i].key === DataId) {
//       isFind = true;
//       break;
//     } else if (treeData[i].children) {
//       if (findId(treeData[i].children, DataId)) {
//         isFind = true;
//         break;
//       }
//     }
//   }
//   return isFind;
// };

const findDataIsInTreeData = (treeData: any[], DataId: string) => {
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].key === DataId) {
      return true;
    }else if(treeData[i].children) {
      let isFind = findDataIsInTreeData(treeData[i].children, DataId);
      if(isFind) {
        return true;
      }
    }
  }
  return false;
}

const sordCardsInTreeData = (
  treeData: any[],
  selectedFileId: string,
  cardsData: any[]
) => {
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].key === selectedFileId) {
      let treeDataChildren = treeData[i].children;
      let order = cardsData.map((item: any) => {
        return item.id;
      });
      treeDataChildren.sort((a: any, b: any) => {
        return order.indexOf(a.key) - order.indexOf(b.key);
      });
      return;
    }else if(treeData[i].children) {
      sordCardsInTreeData(treeData[i].children, selectedFileId, cardsData);
    }
  }
};

const deleteDataFromTreeData = (treeData: any[], dataKey: string) => {
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].key === dataKey) {
      treeData.splice(i, 1);
      return true;
    } else if (treeData[i].children && treeData[i].children.length > 0) {
      if (deleteDataFromTreeData(treeData[i].children, dataKey)) {
        treeData[i].hasFolderChild = false;
        if (treeData[i].children && treeData[i].children.length > 0) {
          for (let j = 0; j < treeData[j].children.length; j++) {
            if (treeData[i].children[j].type === 0) {
              treeData[i].hasFolderChild = true;
              return true;
            }
          }
        }
      }
    }
  }
  return false;
};

const moveCards = (
  treeData: any[],
  cardKeys: string[],
  newDatas: any,
  isPakcage: boolean,
  isMaster: boolean,
  typename: string
) => {
  cardKeys.map(item => {
    deleteDataFromTreeData(treeData, item);
  });

  if (!isPakcage) {
    addCardsToCardFromResponseCard(treeData, newDatas, isMaster, typename);
  } else {
    addCardsToPackageFromResponsePackage(
      treeData,
      newDatas,
      isMaster,
      typename
    );
  }
};

const copyCards = (
  treeData: any[],
  newDatas: any,
  isPakcage: boolean,
  isMaster: boolean,
  typename: string
) => {
  if (!isPakcage) {
    addCardsToCardFromResponseCard(treeData, newDatas, isMaster, typename);
  } else {
    addCardsToPackageFromResponsePackage(
      treeData,
      newDatas,
      isMaster,
      typename
    );
  }
};

const getCardData = (treeData: any[], cardKey: string): any => {
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].key === cardKey) {
      return treeData[i];
    } else if (treeData[i].children) {
      return getCardData(treeData[i].children, cardKey);
    }
  }
  return null;
};

const replaceDataFromTreeData = (
  treeData: any[],
  datas: any,
  isMaster: boolean,
  typename: string
) => {
  let children =
    datas.children.length > 0
      ? datas.children.map((item: any) => {
          let pid = item.parent ? item.parent.id : item.package.id;
          return {
            collaborativeState: item.collaborativeState ? item.collaborativeState : "",
            hasFolderChild: item.hasFolderChild,
            pid: pid,
            key: item.id,
            is_assistant: isMaster ? 0 : 1,
            type: item.fileType === 'FOLDER' ? 0 : 1,
            title: item.name,
            children: [],
            __typename: typename
          };
        })
      : [];
  datas.children = children;
  let data = {
    collaborativeState: datas.collaborativeState ? datas.collaborativeState : "",
    hasFolderChild: datas.hasFolderChild,
    pid: datas.parent ? datas.parent.id : datas.package.id,
    key: datas.id,
    is_assistant: isMaster ? 0 : 1,
    type: datas.fileType === 'FOLDER' ? 0 : 1,
    title: datas.name,
    children: datas.children,
    __typename: typename
  };

  const replaceData = (treeData: any[], data: any) => {
    for (let i = 0; i < treeData.length; i++) {
      if (treeData[i].key === data.key) {
        treeData[i] = data;
        return;
      } else {
        if (treeData[i].children) {
          replaceData(treeData[i].children, data);
        }
      }
    }
  };

  replaceData(treeData, data);
};

const addCardsToCardFromResponseCard = (
  treeData: any[],
  datas: any,
  isMaster: boolean,
  typename: string
) => {
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].key === datas.id) {
      treeData[i].hasFolderChild = datas.hasFolderChild;
      let newChildren = datas.children.filter((item: any) => {
        for (let j = 0; j < treeData[i].children.length; j++) {
          if (item.id === treeData[i].children[j].key) {
            return false;
          }
        }
        return true;
      });

      newChildren.map((item: any) => {
        let data = {
          collaborativeState: item.collaborativeState ? item.collaborativeState : "",
          hasFolderChild: item.hasFolderChild,
          pid: item.parent ? item.parent.id : item.package.id,
          key: item.id,
          is_assistant: isMaster ? 0 : 1,
          type: item.fileType === 'FOLDER' ? 0 : 1,
          title: item.name,
          children: [],
          __typename: typename
        };
        treeData[i].children.push(data);
      });

      let order = datas.children.map((item: any) => {
        return item.id;
      });

      treeData[i].children.sort((a: any, b: any) => {
        return order.indexOf(a.key) - order.indexOf(b.key);
      });

      return;
    } else if (treeData[i].children) {
      addCardsToCardFromResponseCard(
        treeData[i].children,
        datas,
        isMaster,
        typename
      );
    }
  }
};

const addCardsToPackageFromResponsePackage = (
  treeData: any[],
  datas: any,
  isMaster: boolean,
  typename: string
) => {
  let datasChildren = datas.rootCards.edges.map((item: any) => {
    return item.node;
  });
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].key === datas.id) {
      treeData[i].hasFolderChild = datas.hasFolderCard;
      let newChildren = datasChildren.filter((item: any) => {
        for (let j = 0; j < treeData[i].children.length; j++) {
          if (item.id === treeData[i].children[j].key) {
            return false;
          }
        }
        return true;
      });

      newChildren.map((item: any) => {
        let data = {
          collaborativeState: item.collaborativeState ? item.collaborativeState : "",
          hasFolderChild: item.hasFolderChild,
          pid: item.parent ? item.parent.id : item.package.id,
          key: item.id,
          is_assistant: isMaster ? 0 : 1,
          type: item.fileType === 'FOLDER' ? 0 : 1,
          title: item.name,
          children: [],
          __typename: typename
        };
        treeData[i].children.push(data);
      });

      let order = datasChildren.map((item: any) => {
        return item.id;
      });

      treeData[i].children.sort((a: any, b: any) => {
        return order.indexOf(a.key) - order.indexOf(b.key);
      });
      return;
    }
  }
};

const renameCardFromTreeData = (treeData: any[], id: string, newName: string) => {
  for(let i = 0 ; i < treeData.length ; i++) {
    if(treeData[i].key === id) {
      treeData[i].title = newName;
      return true;
    }else if(treeData[i].children) {
      if(renameCardFromTreeData(treeData[i].children, id, newName)) {
        return true;
      };
    }
  }
  return false;
}

const getDataTypeFromTreeData = (treeData: any, id: string) : number => {
  for(let i = 0 ; i < treeData.length ; i++) {
    if(treeData[i].key === id) {
      return treeData[i].type;
    }else if(treeData[i].children) {
      let type = getDataTypeFromTreeData(treeData[i].children, id);
      if(type !== -1) {
        return type;
      }
    }
  }
  return -1;
}

const getDataFromTreeData = (treeData: any, id: string) : any => {
  for(let i = 0 ; i < treeData.length ; i++) {
    if(treeData[i].key === id) {
      return treeData[i];
    }else if(treeData[i].children) {
      let data = getDataFromTreeData(treeData[i].children, id);
      if(data !== null) {
        return data;
      }
    }
  }
  return null;
}

const getDataFromTreeDataByFileTitle = (treeData: any, title: string, fileType: number): any => {
  for(let i = 0 ; i < treeData.length ; i++) {
    if(treeData[i].type === fileType && treeData[i].title.indexOf(title) !== -1 ) {
      return treeData[i];
    }else if(treeData[i].children) {
      let data = getDataFromTreeDataByFileTitle(treeData[i].children, title, fileType);
      if(data !== null) {
        return data;
      }
    }
  }
  return null;
}

export {
  moveCards,
  copyCards,
  addCardsToCardFromResponseCard,
  addCardsToPackageFromResponsePackage,
  replaceDataFromTreeData,
  deleteDataFromTreeData,
  addChildDataToPackage,
  addChildDataToCard,
  findPackageId,
  addPackageDataToTreeData,
  addCardDataToPackage,
  findDataIsInTreeData,
  sordCardsInTreeData,
  renameCardFromTreeData,
  getDataTypeFromTreeData,
  getDataFromTreeData,
  getDataFromTreeDataByFileTitle
};
