import * as React from "react";
import "./styles.css";

//引入的库组件
import { Menu, Icon } from "antd";
import { arrSortMinToMax } from "../../utils";

//graphql查询
import { useHomePageMenuQuery } from "../../generated/graphql";

//引入的其他页面组件
import CoursesMenuDetail from './CoursesMenuDetail'

//引入的资源图片

interface Props { }

const mappings: { [key: string]: string } = {
  效率学院: 'AI课堂',
  认知学院: '认知课堂',
  写作学院: '创作课堂',
  教练学院: '人生课堂',
  // 阅读学院: '阅读课堂',
  "OpenMind+": 'OpenMind+'
}

const courseName = [
  "认知学院",
  "效率学院",
  "教练学院",
  "写作学院",
  // "阅读学院"
  "OpenMind+"
];

const makeDatasForMenu = (menuData: any) => {
  let congnitionCollege = { name: "认知学院", datas: [] };
  let writingCollege = { name: "写作学院", datas: [] };
  let readingCollege = { name: "阅读学院", datas: [] };
  let hackerCollege = { name: "效率学院", datas: [] };
  let coachCollege = { name: "教练学院", datas: [] };
  let openmindPlusCollege = { name: "OpenMind+", datas: [] };

  let menus: any = {
    认知学院: congnitionCollege,
    写作学院: writingCollege,
    阅读学院: readingCollege,
    效率学院: hackerCollege,
    教练学院: coachCollege,
    "OpenMind+": openmindPlusCollege
  };
  let courseTypes: string[] = [];
  let courseMainOrders = [
    "英才四课",
    "AI四课",
    "三生四论",
    "写作三课",
    "通识千书",
    "专题课",
    "公开课",
    "开智大会",
    "开智社群"
  ];

  menuData.map((item: any, index: any) => {
    menus[item!.collegiateCategory!].datas.push(item);
    courseTypes.push(item!.courseCategory!);
  });

  courseTypes = [...Array.from(new Set(courseTypes))];
  courseTypes = courseTypes.filter((item, index) => {
    return courseMainOrders.indexOf(item) === -1;
  });

  courseTypes = arrSortMinToMax(courseTypes);
  courseMainOrders = courseMainOrders.concat(courseTypes);

  for (let college in menus) {
    menus[college].datas.map((item: { courseCategory: string; }) => {
      if (!menus[college]["course_names"]) {
        menus[college]["course_names"] = [];
      }
      menus[college]["course_names"].push(item.courseCategory);
    });
    menus[college]["course_names"] = [
      ...Array.from(new Set(menus[college]["course_names"]))
    ];
    menus[college]["course_names"].sort((a: string, b: string) => {
      return courseMainOrders.indexOf(a) - courseMainOrders.indexOf(b);
    });
  }

  menuData.map((item: any, index: any) => {
    if (!menus[item!.collegiateCategory!][item!.courseCategory!]) {
      menus[item!.collegiateCategory!][item!.courseCategory!] = [];
    }
    menus[item!.collegiateCategory!][item!.courseCategory!].push({ id: item!.id, short_url: item!.shortUrl, title: item!.title });
  });

  return menus;
}

//因为ts中对象可能为null的原因，在调用前用了很多为空的判定逻辑
const CoursesMenu: React.FC<Props> = ({ }) => {
  const [onSelectedCourseItem, setOnelectedCourseItem] = React.useState("认知课堂");
  const { data, error, loading } = useHomePageMenuQuery({ variables: { isOnline: true } });

  let menus = null;
  if (data && data.menu) {
    // console.log(data.menu);
    menus = makeDatasForMenu(data.menu);

    // menus = makeDatasForMenu(data.menu.filter(x => x && x.collegiateCategory !== 'OpenMind+'));
  }

  return (
    <div className="home-page-header-5048">
      <Menu className="home-page-header-5212" defaultSelectedKeys={["0"]}>
        {courseName.map((item, index) => {
          return (
            <Menu.Item
              key={index}
              className="home-page-header-5224"
              onClick={() => {
                setOnelectedCourseItem(item);
              }}
            >
              <div className="home-page-header-5235">
                <div
                  className="home-page-header-5240"
                  style={{
                    backgroundColor: onSelectedCourseItem === item ? "#F4404F" : "#EAEDF3"
                  }}
                />
                <div className="home-page-header-5248">{mappings[item]}</div>
              </div>
              {onSelectedCourseItem === item ? null : <Icon type="right" />}
            </Menu.Item>
          );
        })}
      </Menu>
      <CoursesMenuDetail menuData={menus !== null ? menus[onSelectedCourseItem] : null} />
      {/* <div className="home-page-header-5306" /> */}
    </div>
  );
};

export default CoursesMenu;
