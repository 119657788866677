import styled from 'styled-components';
import {Menu} from 'antd';

const QItem = styled(Menu.Item)`
  .ant-dropdown-menu-submenu-title{
    color: #383838;
  }
  background-color: ${props => props.disabled ? '#fff' : '#fff'};
  color: ${props => props.disabled ? 'rgba(56,56,56,0.50)' : '#383838'};
  font-size: 14px;
  &:hover{background-color: ${props => props.disabled ? '#fff' : '#1890ff'};
          color: ${props => props.disabled ? 'rgba(56,56,56,0.50)' : '#fff'} }
`;

export { QItem };
