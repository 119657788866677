import * as React from 'react';

//引入的库组件
import { Dropdown, Icon, Menu } from 'antd';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';

//引入的其他页面组件
import { query, writeQuery_collegeName, writeQuery_courseName } from '../cache';
import { removalCourse } from '../utils';

//graphql查询

//引入的资源图片

const MobileHeader: React.FC<{ value: string }> = ({ value }) => {
  const client = useApolloClient();
  const { data } = useQuery(query);
  const {
    count,
    collegeTypes,
    courseTypes,
    filterCourseTypes,
    collegeName,
    courseName
  } = data.courses;

  const setSelectCourse = (item: any) => {
    // dispatch({
    //   type: "course/handlerAllCourseCourseSelect",
    //   allCourseCourseSelect: item
    // });
    // dispatch({
    //   type: "course/getAllCourse_v2",
    //   payload: {
    //     page: "1",
    //     page_size: "9",
    //     collegiate_category: allCourseCollegeSelect,
    //     course_category: item,
    //     name: allCourseSearchContent,
    //     is_selling: true
    //   }
    // });
    // // 过滤课程分类列表
    // const courses = filterCourseTypes.filter(
    //   (item: any) => item.parent.id === collegeId
    // );
    // // 是否包含当前课程分类
    // const hasCourseTypes = courses.find(
    //   (item: any) => item.name === courseName
    // );
    // client.writeQuery({
    //   query: writeQuery_collegeName,
    //   data: {
    //     courses: {
    //       collegeName,
    //       courseName:
    //         hasCourseTypes || collegeId === '全部' ? courseName : '全部',
    //       courseTypes:
    //         collegeId === '全部' ? removalCourse(filterCourseTypes) : courses,
    //       __typename: 'courses'
    //     }
    //   }
    // });
  };

  const renderCollegeDropMobile = () => {
    return (
      <Menu style={{ marginTop: 15 }}>
        {collegeName === '全部' ? (
          <Menu.Item
            key={'全部学院'}
            className='new-routes-allcourse-3375-active'
          >
            {'全部'}
          </Menu.Item>
        ) : (
          <Menu.Item
            key={'全部学院'}
            className='new-routes-allcourse-3375'
            onClick={() => {
              client.writeQuery({
                query: writeQuery_collegeName,
                data: {
                  courses: {
                    collegeName: '全部',
                    courseName: '全部',
                    courseTypes: removalCourse(filterCourseTypes),
                    __typename: 'courses'
                  }
                }
              });
            }}
          >
            {'全部'}
          </Menu.Item>
        )}
        {collegeTypes.map((item: any, index: any) => {
          return item.name === collegeName ? (
            <Menu.Item
              key={item.name}
              className='new-routes-allcourse-3375-active'
            >
              {item.name}
            </Menu.Item>
          ) : (
            <Menu.Item
              key={item.name}
              className='new-routes-allcourse-3375'
              onClick={() => {
                const courses = filterCourseTypes.filter(
                  (coursesItem: any) => coursesItem.parent.id === item.id
                );

                // 是否包含当前课程分类
                const hasCourseTypes = courses.find(
                  (coursesItem: any) => coursesItem.name === courseName
                );

                client.writeQuery({
                  query: writeQuery_collegeName,
                  data: {
                    courses: {
                      collegeName: item.name,
                      courseName: hasCourseTypes ? courseName : '全部',
                      courseTypes: courses,
                      __typename: 'courses'
                    }
                  }
                });
              }}
            >
              {item.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const renderCourseDropMobile = () => {
    return (
      <Menu style={{ marginTop: 15 }}>
        {courseName === '全部' ? (
          <Menu.Item
            key={'全部课程'}
            className='new-routes-allcourse-3375-active'
          >
            {'全部'}
          </Menu.Item>
        ) : (
          <Menu.Item
            key={'全部课程'}
            className='new-routes-allcourse-3375'
            onClick={() => {
              client.writeQuery({
                query: writeQuery_courseName,
                data: {
                  courses: {
                    courseName: '全部',
                    __typename: 'courses'
                  }
                }
              });
            }}
          >
            {'全部'}
          </Menu.Item>
        )}
        {courseTypes.map((item: any, index: any) => {
          return item.name === courseName ? (
            <Menu.Item
              key={item.name}
              className='new-routes-allcourse-3475-active'
            >
              {item.name}
            </Menu.Item>
          ) : (
            <Menu.Item
              key={item.name}
              className='new-routes-allcourse-3475'
              onClick={() => {
                client.writeQuery({
                  query: writeQuery_courseName,
                  data: {
                    courses: {
                      courseName: item.name,
                      __typename: 'courses'
                    }
                  }
                });
              }}
            >
              {item.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <div className='new-routes-allcourse-2010-mobile'>
      <div className='new-routes-allcourse-2013-mobile'>
        <div className='new-routes-allcourse-2044-mobile'>
          <Dropdown
            trigger={['click']}
            overlay={renderCollegeDropMobile}
            placement={'bottomCenter'}
          >
            <div className='new-routes-allcourse-2049-mobile'>
              <div className='new-routes-allcourse-2058-mobile'>
                {collegeName === '全部' ? '课程学院' : collegeName}
              </div>
              <Icon type='down' style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        </div>
        <div className='new-routes-allcourse-2051-mobile' />
        <div className='new-routes-allcourse-2044-mobile'>
          <Dropdown
            trigger={['click']}
            overlay={renderCourseDropMobile}
            placement={'bottomCenter'}
          >
            <div className='new-routes-allcourse-2049-mobile'>
              <div className='new-routes-allcourse-2058-mobile'>
                {courseName === '全部' ? '课程类型' : courseName}
              </div>
              <Icon type='down' style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        </div>
      </div>
      {value ? (
        <p className='search-title'>
          共 <span>{count}</span> 个 “ <span>{decodeURI(value)}</span> “
          相关结果
        </p>
      ) : (
        <p className='search-title'>
          共 <span>{count}</span> 个结果
        </p>
      )}
    </div>
  );
};

export default MobileHeader;
