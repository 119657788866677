const checkMDContent = (rule: any, value: any, callback: any) => {
    if (value.content.markdown !== '') {
      callback();
      return;
    }
    callback('');
  };
  
  const checkAnswerItem = (rule: any, value: any, callback: any) => {
    if (value.aw !== '') {
      callback();
      return;
    }
    callback('');
  };
  
  export { checkMDContent, checkAnswerItem };