import gql from 'graphql-tag';

export const query = gql`
  {
    home @client {
      coachs {
        avator
        userName
        workName
        description
      }
      colleges {
        path
        icon
        title
        introduce
        bgcolor
        cover
      }
      collegeCourses
      studentCourses
    }
  }
`;

export const writeQuery = gql`
  {
    home @client {
      collegeCourses
      studentCourses
    }
  }
`;
