/* eslint-disable camelcase,max-len */
import React, { Component } from 'react';
import Layout from 'antd/es/layout/layout';
import Icon from 'antd/es/icon/index';
import Divider from 'antd/es/divider/index';
import CheckboxGroup from 'antd/es/checkbox/Group';
import RadioGroup from 'antd/es/radio/group';
import Radio from 'antd/es/radio/radio';

class AnswerLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResult: false,
      tORf: false,
      rightAnswer: [],
      myAnswer: []
    };

    this.setAnswer = this.setAnswer.bind(this);
  }

  setAnswer() {
    const { examAnswerInfo, question } = this.props;
    const { is_single, asw } = this.props.question;

    let title = is_single === 'true' ? question.sq : question.mq;
    examAnswerInfo.info.map((item, index) => {
      if (item.question.indexOf(title) > -1) {
        item.answers = this.state.myAnswer;
        item.is_correct = this.state.tORf;
      }
    });
  }

  render() {
    const { question, showResult, examAnswerInfo } = this.props;
    const { is_single, asw } = this.props.question;
    const answers = question.asw.map(item => {
      return { label: item, value: item };
    });

    return (
      <Layout.Content className='routes-course-cards-answerLayout-832'>
        <div className='routes-course-cards-answerLayout-909'>
          {is_single === 'true' ? question.sq : question.mq}
        </div>

        {is_single === 'true' ? (
          <RadioGroup
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: asw.length * 40
            }}
            onChange=/* eslint-disable eqeqeq */ {checkedValues => {
              this.setState(
                {
                  myAnswer: [checkedValues.target.value],
                  tORf: checkedValues.target.value == question.aswt
                },
                () => {
                  this.setAnswer();
                }
              );
            }}
          >
            {asw.map(item => {
              return <Radio value={item}>{item}</Radio>;
            })}
          </RadioGroup>
        ) : (
          <CheckboxGroup
            options={asw.map(item => {
              return { value: item, label: item };
            })}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: asw.length * 40
            }}
            onChange=/* eslint-disable eqeqeq */ {checkedValues => {
              this.setState(
                {
                  myAnswer: [checkedValues],
                  tORf:
                    JSON.stringify(question.aswt.sort()) ==
                    JSON.stringify(
                      checkedValues
                        .filter(item => {
                          return asw.indexOf(item) !== -1;
                        })
                        .sort()
                    )
                },
                () => {
                  this.setAnswer();
                }
              );
            }}
          />
        )}

        {showResult ? (
          <div>
            {this.state.tORf ? (
              <p className='routes-course-cards-answerLayout-2331'>
                <Icon
                  type='check-circle'
                  className='routes-course-cards-answerLayout-2401'
                />
                回答正确
              </p>
            ) : (
              <div>
                <p className='routes-course-cards-answerLayout-2521'>
                  {' '}
                  <Icon
                    type='close-circle'
                    className='routes-course-cards-answerLayout-2592'
                  />
                  回答错误
                </p>
                <div className='routes-course-cards-answerLayout-2660'>
                  <div className='routes-course-cards-answerLayout-2720'>
                    正确答案是：{question.aswt.join('、')}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div />
        )}
        <Divider />
      </Layout.Content>
    );
  }
}

const mapStateToProps = state => {
  return {
    showResult: state.course.showResult,
    examAnswerInfo: state.course.examAnswerInfo
  };
};

export default AnswerLayout;
