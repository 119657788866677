import * as React from 'react';
import './styles.css';

//引入的库组件
import { message } from 'antd';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

//引入的其他页面组件
import SearchAndInitiate from './SearchAndInitiate';

//graphql查询

//引入的资源图片

const GET_DISCUSS_TOOL_BAR_TIP_INFO = gql`
  {
    disc @client {
      page
      count
    }
    filterSelectedTags @client
    aboutMine @client
    discPage @client
    discussCoursePackageId @client
    discussPageContent @client
    discussStatus @client
    discussCountPerPage @client
    discussSort @client {
      type
      label
    }
  }
`;

const DiscussToolbarTip = (props: any) => {
  const client = useApolloClient();
  const { data: discussToolbarInfo } = useQuery(GET_DISCUSS_TOOL_BAR_TIP_INFO);
  const { disc, filterSelectedTags, aboutMine, discussCoursePackageId, discussPageContent, discussStatus, discussCountPerPage, discussSort } = discussToolbarInfo;

  let tagsInTip = '';
  if (filterSelectedTags.length > 0) {
    filterSelectedTags.map((item: any) => {
      tagsInTip = tagsInTip + '“' + item.name + '”';
    });
  }

  return (
    <div className='new-routes-study-courseDiscussion-1207'>
      <div className='new-routes-study-courseDiscussion-1212'>
        共{disc.count}条
      </div>
      {tagsInTip !== '' && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className='new-routes-study-courseDiscussion-1212'>标签为</div>
          <div
            className='new-routes-study-courseDiscussion-1212'
            style={{ color: '#000000' }}
          >
            {tagsInTip}
          </div>
          <div className='new-routes-study-courseDiscussion-1212'>的</div>
        </div>
      )}
      {aboutMine && (
        <div
          className='new-routes-study-courseDiscussion-1212'
          style={{ color: '#000000' }}
        >
          “我参与的”
        </div>
      )}
      <div className='new-routes-study-courseDiscussion-1212'>结果</div>
      {(tagsInTip !== '' || aboutMine) && (
        <div
          className='new-routes-study-courseDiscussion-1212'
          style={{
            marginLeft: 20,
            color: '#FF0000',
            cursor: 'pointer'
          }}
          onClick={() => {
            client.writeQuery({
              query: GET_DISCUSS_TOOL_BAR_TIP_INFO,
              data: { aboutMine: false, discPage: 1, filterSelectedTags: [] }
            });

            let input = {
              coursePackageId: discussCoursePackageId,
              filter: {
                name:
                  discussPageContent === ''
                    ? undefined
                    : discussPageContent,
                iParticipated: undefined,
                labels: undefined,
                status: discussStatus === '0'
              },
              last: undefined,
              before:undefined,
              first: discussCountPerPage,
              after: undefined,
              order: discussSort.type,
            };
            props.getListData(input, 1);
          }}
        >
          清除筛选
        </div>
      )}
    </div>
  );
};

export default DiscussToolbarTip;
