import * as React from 'react';
import './styles.css';

//引入的库组件
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

//引入的其他页面组件
import ModifyMultiCard from './ModifyMultiCard';

//graphql查询
import { useGetCardsForModifyQuery } from '../../generated/graphql';


//引入的资源图片

const GET_MODIFY_CARDS_INFO = gql`
  {
    card_list @client
    selectedFilePackageId @client
  }
`;


const EduManagerModifyMultiCardsPageContainer = (props: any) => {
    const { data: modifyCardsInfo } = useQuery(GET_MODIFY_CARDS_INFO);
    const { data, loading, error } = useGetCardsForModifyQuery({variables: {ids: modifyCardsInfo.card_list, packageId: modifyCardsInfo.selectedFilePackageId}, fetchPolicy: "network-only"});

    if(!data || loading || error) {
      return <div />
    }

    return <ModifyMultiCard props={props} cardsInfo={data} />
}

export default EduManagerModifyMultiCardsPageContainer;