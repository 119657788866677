import React, { useState, useEffect } from 'react';
import { Layout, Button, Progress } from 'antd';

import { withRouter } from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { findFlag, treeToArray } from '../../../utils/index.js';
import bg from '../../../assets/omcbg/detail_bg.png';

import { CustomP } from '../../CommonComponents/common/customp';
import backbutton from '../../../assets/v2.1/back.png';
import gql from 'graphql-tag';

import CollectionDetailCard from './collectionDetailCard';
import CollectionDetailDiscuss from './collectionDetailDiscuss';
import CollectionDetailHomework from './collectionDetailHomework';

const GET_DATA = gql`
  {
    collectionDetailTitle @client
  }
`;
const CollectionDetailMain = props => {
  const client = useApolloClient();
  const [bodyHeight, setBodyHeight] = useState(document.body.clientHeight);
  const {
    data: { collectionDetailTitle },
    error,
    loading
  } = useQuery(GET_DATA);

  function handleResize() {
    this.setState({
      bodyHeight: document.body.clientHeight
    });
  }

  useEffect(() => {
    client.writeData({ data: { isShowHeader: false, isShowFooter: false } });
  }, []);
  // componentWillMount() {
  //   const { dispatch } = this.props;
  //   dispatch({ type: 'handler/handlerFooter', footer: false });
  //   dispatch({ type: 'handler/handlerHeader', header: false });
  // }

  useEffect(() => {
    const { dispatch, shortUrl, collectionDetailTitle } = props;
    // if (collectionDetailTitle === '') {
    //   dispatch(routerRedux.push(`/stu/${shortUrl}`));
    // } else {
    //   window.addEventListener('resize', this.handleResize); //监听窗口大小改变
    // }
  }, []);
  // componentDidMount() {
  //   const { dispatch, shortUrl, collectionDetailTitle } = this.props;
  //   if (collectionDetailTitle === '') {
  //     dispatch(routerRedux.push(`/stu/${shortUrl}`));
  //   } else {
  //     window.addEventListener('resize', this.handleResize); //监听窗口大小改变
  //   }
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleResize); //监听窗口大小改变
  // }

  function getTitle(title) {
    switch (title) {
      case 'card':
        return '课程收藏';
      case 'homework':
        return '作业收藏';
      case 'discuss':
        return '讨论收藏';
    }
  }

  function goBack() {
    const flag = window.localStorage.getItem('CARDURL');
    const shortUrl = window.sessionStorage.getItem('shortUrl');
    props.history.push(`/stu/${shortUrl}/collection`);
    // this.props.dispatch({ type: 'course/handlerCardUrl', cardUrl: flag });
    // this.props.dispatch({ type: 'course/beforeStudy', payload: { key: flag } });
    // this.props.dispatch({
    //   type: 'course/handlerStudyActiveKey',
    //   studyActiveKey: '4'
    // });
    // this.props.dispatch({
    //   type: 'course/handlerSetCollectionDetailTitle',
    //   collectionDetailTitle: ''
    // });
    //this.props.dispatch(routerRedux.push(`/stu/${this.props.shortUrl}` ));
    //this.props.dispatch({ type: 'course/uploadCourse', payload: { key: this.props.shortUrl } });
    // this.props.history.goBack();
  }

  function renderDetail() {
    // const { collectionDetailTitle } = props;

    switch (collectionDetailTitle) {
      case 'card':
        return (
          <div className='new-routes-collection-collectionDetail-card-1000'>
            <CollectionDetailCard />
          </div>
        );
        break;
      case 'homework':
        return <CollectionDetailHomework />;
        break;
      case 'discuss':
        return <CollectionDetailDiscuss />;
    }
  }

  // const { dispatch, collectionDetailTitle, collectionShowData } = props;

  if (collectionDetailTitle.length === 0) {
    const index = window.location.href.lastIndexOf('/');
    const switchHref = window.location.href.slice(0, index);

    props.history.push(
      `/stu/${window.sessionStorage.getItem('shortUrl')}/collection`
    );
    return null;
  }

  return (
    <div
      className='new-routes-collection-collectionDetail-1000'
      style={{ height: bodyHeight }}
    >
      <div className='new-routes-collection-collectionDetail-1100'>
        <img
          src={backbutton}
          className='new-routes-collection-collectionDetail-1231'
          style={{ cursor: 'pointer' }}
          onClick={goBack}
        />
        <CustomP
          customStyle={{
            fontSize: '20px',
            color: '#4A4A4A',
            line: 1,
            lineHeight: '38px'
          }}
          className='new-routes-collection-collectionDetail-1236'
        >
          {getTitle(collectionDetailTitle)}
        </CustomP>
      </div>
      <div className='new-routes-collection-collectionDetail-2000'>
        {renderDetail()}
      </div>
    </div>
  );
};

export default withRouter(CollectionDetailMain);
