import styled, { keyframes } from 'styled-components';


const animal1 = keyframes`
  0%{
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
  }
  
  50%{
    transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  
  100%{
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
  }
`;

const animal2 = keyframes`
  0%{
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
  }
  
  49%{
    transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  
  100%{
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
  }
`;


const gradientAnimal = keyframes`
0% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    7% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    14% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    21% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    28% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    35% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    42% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    49% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    56% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    63% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 45%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    70% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    77% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 62.5%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    84% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    91% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 87.5%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
    100% {
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
        color: transparent;
        -webkit-background-clip: text;
    }
`;

const ScaleView = styled.div`
    align-self: center;
    animation:  0.5s ${props => props.on ? animal1 : animal2} linear ;
`;

const GradientView = styled.div`
    -webkit-animation: shinetext 2s 0s ease-in-out;
    -webkit-animation-fill-mode: forwards;
`;

export { ScaleView, GradientView };
