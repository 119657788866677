import * as React from 'react';
import './styles.css';

//引入的库组件
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { Icon, message, Avatar, Dropdown, Menu } from 'antd';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import styled from 'styled-components';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import MyReactMarkdown from '../../../CommonComponents/MyReactMarkdown';
import Modal2CallbackFunc from '../../../CommonComponents/Modals/Modal2CallbackFunc';

//graphql查询
import {
  useCloseDiscussMutation,
  useDeleteDiscussMutation,
  useCollectCardMutation,
  useUnCollectMutation,
} from '../../../../generated/graphql';

//引入的资源图片
import favorite from '../../../../assets/icons/favorite.png';
import favorite_active from '../../../../assets/icons/favorite_active.png';
import createIcon from '../../../../assets/v2.1/create.png';
import deleteIcon from '../../../../assets/v2.1/delete.png';
import shareIcon from '../../../../assets/v2.1/Shape.png';
import edit from '../../../../assets/icons/edit.png';
import more from '../../../../assets/icons/more_circle.png';

const GET_DISCUSS_DETAIL_HEAD_INFO = gql`
  {
    discussDetailId @client
    discussCountPerPage @client
    discussCoursePackageId @client
    isCheckDisc @client(always: true)
    userInfo @client {
      userid
    }
    courseId @client
  }
`;

const SET_DICSUSS_DATA = gql`
  {
    discussStatus @client
    discussSelectedTags @client
    discussMarkdownContent @client
    discussSelfDiscussTitle @client
    isModifyDisc @client
    isCheckDisc @client
    isShowDiscList @client
  }
`;

const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  width: '100%',
  alignItems: 'center'
};
const QItem = styled(Menu.Item)`
  background-color: #fff;
  color: #383838;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    background-color: #1890ff;
    color: #fff;
  }
`;

const DiscussCheckHead = (props: any) => {
  const client = useApolloClient();
  const { data: detailInfo } = useQuery(GET_DISCUSS_DETAIL_HEAD_INFO);
  const [closeDiscussMutation] = useCloseDiscussMutation();
  const [deleteDiscussMutation] = useDeleteDiscussMutation();
  const [collectDiscussMutation] = useCollectCardMutation();
  const [uncollectDiscussMutation] = useUnCollectMutation();

  const {
    discussDetailId,
    isCheckDisc,
    userInfo,
    discussCountPerPage,
    courseId,
    discussCoursePackageId
  } = detailInfo;

  // let isCol = false; //接口里面没有这个数据

  let datas = props.discussData.node;
  let discDetailLabels = datas.labels;
  let discussKey = datas.id;

  const [isCol, setIsCol] = React.useState(datas.isCollected);

  const handleCollectDis = () => {
    let input = {
      courseId: Base64.decode(courseId).split("Course:")[1],
      cardId: Base64.decode(discussDetailId).split("Discussion:")[1],
    }
    if(isCol) {
      uncollectDiscussMutation({variables: {input: input}}).then((res: any) => {
        if(res.data.uncollectCard.errors) {
          message.error(res.data.uncollectCard.errors[0].message);
        }else {
          message.success("取消收藏!");
          setIsCol(!isCol);
        }
      });
    }else {
      collectDiscussMutation({variables: {input: input}}).then((res: any) => {
        if(res.data.collectCard.errors) {
          message.error(res.data.collectCard.errors[0].message);
        }else {
          message.success("收藏成功!");
          setIsCol(!isCol);
        }
      });
    }
    // dispatch({
    //   type: isCol ? "course/canCardCol" : "course/cardCol",
    //   payload: {
    //     key: discDetail.key,
    //     course_id: courseId
    //   }
    // });
    
  };

  const menu = () => (
    <Menu
      onClick={(item: any) => {
        let input: any = {};
        switch (item.key) {
          case '0':
            const selectedTagsArray = discDetailLabels.map((item: any) => {
              return item.id;
            });
            input = {
              id: Base64.decode(discussKey).split('Discussion:')[1],
              status: !datas.status,
              labels: selectedTagsArray.map((item: any) => {
                return Base64.decode(item).split('Label:')[1];
              }),
              name: datas.name,
              content: datas.content,
              coursePackageId: Base64.decode(discussCoursePackageId).split('CoursePackage:')[1]
            };
            closeDiscussMutation({ variables: { input: input } }).then(
              (res: any) => {
                if (res.data.updateDiscussion.errors) {
                  message.error(res.data.updateDiscussion.errors[0].message);
                } else {
                  datas.status = res.data.updateDiscussion.discussion.status;
                  message.success(
                    res.data.updateDiscussion.discussion.status
                      ? '成功打开!'
                      : '成功关闭!'
                  );
                  props
                    .getDiscussComment({
                      discussID: discussDetailId,
                      first: discussCountPerPage,
                      last: undefined,
                      before: undefined,
                      after: undefined
                    })
                    .then((res: any) => {
                      if (res.errors) {
                        message.error('获取评论列表失败!');
                      } else {
                        props.refresh();
                        client.writeData({
                          data: { discussCommentPage: 1 }
                        });
                      }
                    });
                }
              }
            );
            break;
          case '1':
            Modal2CallbackFunc.callback = () => {
              input = {
                id: Base64.decode(discussKey).split('Discussion:')[1]
              };
              deleteDiscussMutation({ variables: { input: input } }).then(
                (res: any) => {
                  if (res.data.deleteDiscussion.errors) {
                    message.error(res.data.deleteDiscussion.errors[0].message);
                  } else {
                    message.success('成功删除!');
                    client.writeData({
                      data: {
                        isShowDiscList: true,
                        isModifyDisc: false,
                        isCheckDisc: false
                      }
                    });
                    client.writeData({
                      data: {
                        modal2Loading: false,
                        modal2: {
                          show: false,
                          title: '',
                          content: '',
                          __typename: 'Modal2'
                        }
                      }
                    });
                  }
                }
              );
            };

            client.writeData({
              data: {
                modal2Loading: false,
                modal2: {
                  show: true,
                  title: '确定要删除讨论吗？',
                  content: '删除讨论后，讨论及讨论内的所有评论都会被同时删除。',
                  __typename: 'Modal2'
                }
              }
            });
            break;
          default:
        }
      }}
    >
      <QItem key='0'>{datas.status ? '关闭讨论' : '重新开启'}</QItem>
      <QItem key='1'>删除讨论</QItem>
    </Menu>
  );

  return (
    <div
      className='new-routes-study-courseDiscussion-2087'
      style={isCheckDisc ? {} : { marginBottom: 290 }}
    >
      <div className='new-routes-study-courseDiscussion-2104'>
        <div
          className='new-routes-study-courseDiscussion-2113'
          onClick={() => {
            client.writeData({
              data: {
                isModifyDisc: false,
                isShowDiscList: true,
                isCheckDisc: false
              }
            });
          }}
        >
          ‹ 返回讨论列表
        </div>
        <div className='new-routes-study-courseDiscussion-img'>
          <Icon
            type='share-alt'
            style={{
              marginRight: '20px',
              color: '#93979f',
              fontSize: '19px'
            }}
            onClick={() => {
              let uri = window.location.href.split("discussion/")[0].indexOf("discussion") !== -1 ? window.location.href.split("discussion/")[0] : window.location.href.split("discussion/")[0] + "discussion";
              copy(uri + "/" + Base64.decode(discussDetailId).split(
                'Discussion:'
              )[1]);
              message.success('卡片链接已复制');
            }}
          />
          <img
            src={isCol ? favorite_active : favorite}
            className='new-routes-study-courseDiscussion-2119'
            onClick={handleCollectDis}
          />
        </div>
      </div>

      <div className='new-routes-study-courseDiscussion-2204'>
        <div className='new-routes-study-courseDiscussion-2216'>
          {discDetailLabels &&
            discDetailLabels.map((tagItem: any, index: any) => {
              return (
                <div
                  className='new-routes-study-courseDiscussion-2224'
                  style={{ backgroundColor: tagItem.info.color }}
                >
                  {tagItem.name}
                </div>
              );
            })}
          <div className='new-routes-study-courseDiscussion-2233'>
            {datas.name}
          </div>
        </div>
        <div className='new-routes-study-courseDiscussion-2307'>
          <div className='new-routes-study-courseDiscussion-2309'>
            <Avatar src={datas.author.avatar} size={'default'} />
            <div className='new-routes-study-courseDiscussion-2311'>
              {datas.author.nickname} ·{' '}
              {moment(datas.insertedAt).isSame(moment().startOf('day'), 'd')
                ? `今天 ${datas.insertedAt.split(' ')[1]}`
                : datas.insertedAt}
            </div>
          </div>
          {datas.author.id === userInfo.userid ? (
            <div className='new-routes-study-courseDiscussion-2330'>
              <div
                className='new-routes-study-courseDiscussion-2337'
                onClick={() => {
                  let labelsInfo = discDetailLabels.map((item: any) => {
                    return {
                      name: item.name,
                      id: item.id,
                      color: item.info.color
                    };
                  });
                  let content = datas.content;
                  let name = datas.name;
                  client.writeQuery({
                    query: SET_DICSUSS_DATA,
                    data: {
                      discussSelectedTags: labelsInfo.map((item: any) => {
                        return item;
                      }),
                      discussMarkdownContent: content,
                      discussSelfDiscussTitle: name,
                      isModifyDisc: true,
                      isCheckDisc: false,
                      isShowDiscList: false,
                      discussStatus: datas.status ? '0' : '1'
                    }
                  });
                  // client.writeData({
                  //   data: {
                  //     isModifyDisc: true,
                  //     isCheckDisc: false,
                  //     isShowDiscList: false
                  //   }
                  // });
                }}
              >
                <img src={edit} />
                <div className='new-routes-study-courseDiscussion-2342'>
                  编辑
                </div>
              </div>
              <Dropdown overlay={menu}>
                <img data-rjs='2' src={more} />
              </Dropdown>
            </div>
          ) : null}
        </div>
        <div className='new-routes-study-courseDiscussion-2352'>
          <MyReactMarkdown source={datas.content} />
        </div>
      </div>
    </div>
  );
};

export default DiscussCheckHead;
