import gql from 'graphql-tag';

export const query = gql`
  {
    courses @client {
      count
      collegeTypes
      courseTypes
      filterCourseTypes
      collegeName
      courseName
      page
      list
      hasNextPage
    }
  }
`;

export const writeQuery_types = gql`
  {
    courses @client {
      collegeTypes
      courseTypes
      filterCourseTypes
    }
  }
`;

export const writeQuery_list = gql`
  {
    courses @client {
      count
      list
      hasNextPage
    }
  }
`;

export const writeQuery_collegeName = gql`
  {
    courses @client {
      collegeName
      courseName
      courseTypes
    }
  }
`;

export const writeQuery_courseName = gql`
  {
    courses @client {
      courseName
    }
  }
`;

export const writeQuery_page = gql`
  {
    courses @client {
      page
    }
  }
`;
