// 上一页和下一页的过滤条件
export default function getPreviousAndNexPagination(
  page,
  endCursor,
  startCursor
) {
  if (page > 0) {
    const cursorConverIndex = window
      .atob(endCursor)
      .split('arrayconnection:')[1];
    const calculateIndex = Number(cursorConverIndex) + (page - 1) * 10;
    const converCursor = window.btoa('arrayconnection:' + calculateIndex);
    return {
      after: converCursor,
      first: 10,
      before: undefined,
      last: undefined
    };
  } else {
    const cursorConverIndex = window
      .atob(startCursor)
      .split('arrayconnection:')[1];
    const calculateIndex = Number(cursorConverIndex) + (page + 1) * 10;
    const converCursor = window.btoa('arrayconnection:' + calculateIndex);
    return {
      before: converCursor,
      last: 10,
      after: undefined,
      first: undefined
    };
  }
}
