import React, { Component, useState, useEffect, useCallback } from 'react';
import './style.less';

import { withRouter } from 'react-router-dom';

import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { LoadingView } from '../../CommonComponents/common/LoadingView';
import EmptyLayout from '../../CommonComponents/course/emptyLayout';

import { getSuffix } from '../../../utils/index';
import { Disc } from '../../CommonComponents/list/disc_v2.js';
import styled from 'styled-components';
import { Layout, Input, Menu, Dropdown, Icon, Spin, List, Avatar } from 'antd';
import { Base64 } from 'js-base64';

import moment from 'moment';
import search from '../../../assets/icons/search.png';
import cross from '../../../assets/icons/cross.png';
import loading from '../../../assets/icons/loading.png';
import empty1s from '../../../assets/omcbg/empty1s.png';
import discImg from '../../../assets/icons/discuss.png';
import chat from '../../../assets/v2.1/chat.png';
import gql from 'graphql-tag';
import md from '../../../assets/v2.1/md-normal.png';
import video from '../../../assets/v2.1/video-normal.png';
import audio from '../../../assets/v2.1/aw-normal.png';
import exam from '../../../assets/v2.1/aw-normal.png';
import { handleCardContent } from './../../../utils/handleCardContent';

import {
  useContentCollectQuery,
  useHomeworkCollectQuery,
  // useGetLabelInDiscussionsQuery,
  useGetLabelsInDiscussionQuery,
  useDiscussionCollectionQuery,
  useGetSingleAssignmentQuery,
  useGetDiscussDetailQuery,
  useCheckSingleAnswerQuery
} from '../../../generated/graphql.tsx';

import { GET_DISCUSSION_LABELS } from './query.ts';
import getPreviousAndNexPagination from '../../../utils/getPreviousAndNexPagination';

const QItem = styled(Menu.Item)`
  background-color: #fff;
  color: #383838;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    background-color: #1890ff;
    color: #fff;
  }
`;

const GET_DATA = gql`
  {
    collectionLoading @client
    collectionShowData @client
    collectionType @client
    tagsInDiscuss @client
    collectionShowDataTotalCount @client
    collectionTotal @client
    endCursor @client
    startCursor @client
  }
`;

const GET_COURSE_ID = gql`
  {
    courseId @client(always: true)
  }
`;

const PUSH_DATA = gql`
  {
    collectionShowData @client
    collectionTotal @client
    endCursor @client
    startCursor @client
  }
`;

const CARD_DATA = gql`
  {
    cardData @client
  }
`;

const SEND_HOMEWORK_DATA = gql`
  {
    commentList @client
    collectionHomeworkDetailInfo @client
  }
`;

const DISCUSSION_DATA = gql`
  {
    discDetailLabels @client
    commentList @client
    discDetail @client
    discussionCollectionId @client
    isCollectedInDiscussion @client
  }
`;
const CourseCollection = props => {
  const {
    data: {
      collectionLoading,
      collectionShowData,
      collectionType,
      tagsInDiscuss,
      collectionShowDataTotalCount,
      collectionTotal,
      endCursor,
      startCursor
    }
  } = useQuery(GET_DATA);

  const {
    data: { courseId }
  } = useQuery(GET_COURSE_ID);

  const { refetch: getSingleHomeworkContent } = useGetSingleAssignmentQuery({
    skip: true
  });

  const { refetch: getHomeworkAnswerContent } = useCheckSingleAnswerQuery({
    skip: true
  });

  // 收藏时间查询
  const { refetch: contentCollect } = useContentCollectQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });

  // 作业收藏查询
  const { refetch: homeWorkCollect } = useHomeworkCollectQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });
  const { refetch: discussionCollect } = useDiscussionCollectionQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });

  const { refetch: takeSingleDiscusion } = useGetDiscussDetailQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });
  const { data, loading } = useQuery(GET_DISCUSSION_LABELS);

  const [cardSort, setCardSort] = useState({
    type: 'CONTENT__DESC_COLLECTED',
    label: '按收藏时间排序'
  });

  const client = useApolloClient();
  const [cardFilterType, setCardFilterType] = useState('');
  const [searchContent, setSearchContent] = useState('');
  const [tags, setTags] = useState([]);
  const [cardTypes, setCardTypes] = useState(['', 'TXT', 'VIDEO', 'EXAM']);
  const [homeworkSort, setHomeWorkSort] = useState({
    type: 'ANSWER__DESC_INSERTED_AT',
    label: '按创建时间排序'
  });

  const [discussSort, setDiscussSort] = useState({
    type: 'DISCUSSION__DESC_INSERTED_AT',
    label: '按创建时间排序'
  });
  const [discussFilterType, setDiscussFilterType] = useState([]);
  const [showPage, setShowPage] = useState(1);
  const [filterSelectedTags, setFilterSelectedTag] = useState([]);
  const [selectTags, setSelectTags] = useState([]);
  const [tagDropdownVisible, setTagDropdownVisible] = useState(false);
  const [tagContent, setTagContent] = useState('');

  // const [startCursor, setStartCursor] = useState('');
  // const [endtCursor, setEndCursor] = useState('');

  useEffect(() => {
    setTags(data.labels);
  }, [data]);

  useEffect(() => {
    if (courseId === '') {
      return;
    }

    switch (collectionType) {
      case 'card':
        if (cardFilterType.length > 0) {
          handlerCardFilterCollection(cardFilterType);
        } else {
          handlerCardCollection(1);
        }
        break;
      case 'homework':
        handlerHomeworkCollection({});
        break;
      case 'discuss':
        handlerDiscussColleciion({});
        break;
    }
  }, [
    collectionType,
    courseId,
    cardSort,
    homeworkSort,
    discussSort,
    searchContent
  ]);

  // // 组件渲染完后执行此函数
  // useEffect(() => {
  //   console.log('cardSort');
  //   console.log(cardSort);
  //   if (cardFilterType.length > 0) {
  //     handlerCardFilterCollection(cardFilterType);
  //   } else {
  //     handlerCardCollection(1);
  //   }
  // }, [cardSort]);

  // // 作业收藏
  // useEffect(() => {
  //   console.log('homeworkSort');
  //   console.log(homeworkSort);
  //   handlerHomeworkCollection();
  // }, [homeworkSort]);

  // // // 讨论收藏
  // useEffect(() => {
  //   console.log('discussSort');
  //   console.log(discussSort);
  //   handlerDiscussColleciion();
  // }, [discussSort]);

  function getIcon(suffix) {
    switch (suffix) {
      case 'md':
        return md;
      case 'video':
        return video;
      case 'exam':
        return exam;
      case 'audio':
        return audio;
    }
  }
  function getType(type) {
    switch (type) {
      case 'md':
        return '文本卡片';
      case 'video':
        return '视频卡片';
      case 'exam':
        return '测验卡片';
      case 'audio':
        return '音频卡片';
    }
  }
  function getCourseType(type) {
    switch (type) {
      case -1:
      case '':
        return '全部分类';
      case 0:
      case 'TXT':
        return '文本卡片';
      case 1:
      case 'VIDEO':
        return '视频卡片';
      case 2:
      case 'EXAM':
        return '测验卡片';
      case 5:
      case 'audio':
        return '音频卡片';
      default:
        return '类型错误';
    }
  }

  const courseFilterMenu = courses => (
    <Menu>
      {courses.map((item, index) => {
        return (
          <Menu.Item
            key={`${index}`}
            onClick={() => {
              setCardFilterType(item);

              //接口查询的tag只要三种类型，所以要多一次全部卡片的判断
              if (item.length === 0) {
                handlerCardCollection();
              } else {
                handlerCardFilterCollection(item);
              }
            }}
          >
            {getCourseType(item)}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  function makeNewTags(fliter) {
    // const { tagsInDiscuss } = props;
    let newTags = [];
    // tags.map((item, index) => {
    //   if (fliter === '') {
    //     newTags[newTags.length] = item;
    //     newTags[newTags.length] = '';
    //   } else {
    //     if (item.name.indexOf(fliter) != -1) {
    //       newTags[newTags.length] = item;
    //       newTags[newTags.length] = '';
    //     }
    //   }
    // });
    // newTags = tags.map((item, index) => {
    //   return { name: item.name, color: item.info.color, id: item.id };
    // });
    newTags = tags;
    return newTags;
  }

  const discussFilterMenu = tags => (
    <Menu>
      <div
        className='new-routes-study-courseDiscussion-1403'
        onClick={() => {
          // this.setState({ tagDropdownVisible: true });
          setTagDropdownVisible(true);
        }}
      >
        <Input
          maxLength={36}
          className='new-routes-study-courseDiscussion-1416'
          placeholder='过滤标签'
          onChange={value => {
            // this.setState({ tagContent: value.target.value });
            setTagContent(value.target.value);
          }}
          onPressEnter={() => {
            if (tagContent.length == 0) {
              setTags(data.labels);
            } else {
              const newTags = tags.filter(item => {
                if (item.name.indexOf(tagContent) !== -1) {
                  return true;
                }
              });

              setTags(newTags);
            }
          }}
        />
      </div>

      {tags.length > 0 ? <Menu.Divider /> : null}
      {tags.map((item, index) => {
        if (item !== '') {
          let isInFilterSelectedTag = filterSelectedTags.indexOf(item) !== -1;

          return (
            <Menu.Item
              key={`${index}`}
              onClick={() => {
                //this.setState({ tagDropdownVisible: false });
                setTagDropdownVisible(false);
                let temp = filterSelectedTags;
                // 选中
                if (!isInFilterSelectedTag) {
                  temp[temp.length] = item;
                  setFilterSelectedTag(temp);

                  let selectedTags = temp.map(item => item.name);
                  handlerDiscussFilterColleciion(selectedTags);
                  setSelectTags(selectedTags);
                }
                // 不选中
                if (isInFilterSelectedTag) {
                  temp = temp.filter(tag => tag.name !== item.name);
                  setFilterSelectedTag(temp);

                  let selectedTags = temp.map(item => item.name);
                  handlerDiscussFilterColleciion(selectedTags);
                  setSelectTags(selectedTags);
                }
              }}
            >
              <div className='new-routes-study-courseDiscussion-1425'>
                <div className='new-routes-study-courseDiscussion-1430'>
                  <div
                    className='new-routes-study-courseDiscussion-1439'
                    style={{ backgroundColor: item.info.color }}
                  />
                  <div className='new-routes-study-courseDiscussion-1444'>
                    {item.name}
                  </div>
                </div>

                {isInFilterSelectedTag ? (
                  <Icon
                    type='check-circle'
                    theme='filled'
                    style={{ fontSize: '15px', color: '#f63448' }}
                  />
                ) : null}
              </div>
            </Menu.Item>
          );
        } else {
          if (index !== tags.length - 1) {
            return <Menu.Divider key={`divider_${index}`} />;
          }
          return null;
        }
      })}
    </Menu>
  );

  function handleTagVisibleChange(flag) {
    setTagDropdownVisible(flag);
    // this.setState({
    //   tagDropdownVisible: flag
    // });
  }

  function renderFilter() {
    // const { collectionType, tagsInDiscuss, dispatch } = props;
    // const
    //   cardTypes,
    //   tagDropdownVisible,
    //   tagContent,
    //   cardFilterType

    if (collectionType === 'homework') {
      return null;
    } else {
      let label = '';
      let overlay = null;
      if (collectionType === 'card') {
        label =
          cardFilterType === '' ? '全部卡片' : getCourseType(cardFilterType);
        overlay = courseFilterMenu(cardTypes);
      } else {
        label = '标签';
        overlay = discussFilterMenu(makeNewTags(tagContent));
      }

      return (
        <Dropdown
          onVisibleChange={handleTagVisibleChange}
          visible={tagDropdownVisible}
          trigger={['click']}
          overlay={overlay}
          className='new-routes-study-courseCollection-2241'
        >
          <div
            className='ant-dropdown-link'
            style={{ cursor: 'pointer', color: '#000000' }}
          >
            <Icon
              type='down'
              className='new-routes-study-courseCollection-2244'
            />
            <div className='new-routes-study-courseCollection-2249'>
              {label}
            </div>
          </div>
        </Dropdown>
      );
    }
  }

  const cardSortMenu = (
    <Menu
      onClick={item => {
        // const { dispatch } = this.props;
        switch (item.key) {
          case '0':
            // 同步的状态
            setCardSort(item => {
              return {
                type: 'CONTENT__DESC_COLLECTED',
                label: '按收藏时间排序'
              };
            });

            break;
          case '1':
            setCardSort(item => {
              return {
                type: 'CONTENT__ASC_INDEX',
                label: '按课程顺序排序'
              };
            });

            break;
          default:
        }
      }}
    >
      <QItem key={'0'}>按收藏时间排序</QItem>
      <QItem key={'1'}>按课程顺序排序</QItem>
    </Menu>
  );

  const homeworkSortMenu = (
    <Menu
      onClick={item => {
        // const { dispatch } = this.props;
        switch (item.key) {
          case '0':
            setHomeWorkSort({
              type: 'ANSWER__DESC_INSERTED_AT',
              label: '按创建时间排序'
            });

            break;
          case '1':
            setHomeWorkSort({
              type: 'ANSWER__DESC_COMMENTS_AT',
              label: '按最近回复排序'
            });

            break;
          default:
        }
      }}
    >
      <QItem key={'0'}>按创建时间排序</QItem>
      <QItem key={'1'}>按最近回复排序</QItem>
    </Menu>
  );

  const discussSortMenu = (
    <Menu
      onClick={item => {
        // const { dispatch } = this.props;
        let type = '';
        let label = '';
        switch (item.key) {
          case '0':
            type = 'DISCUSSION__DESC_INSERTED_AT';
            label = '按创建时间排序';
            break;
          case '1':
            type = 'DISCUSSION__DESC_COMMENTS_AT';
            label = '按最近回复排序';
            break;
          case '2':
            type = 'DISCUSSION__DESC_COMMENTS_COUNT';
            label = '按评论数排序';
            break;
          default:
        }
        setDiscussSort({ type, label });

        // handlerDiscussColleciion(1);
      }}
    >
      <QItem key={'0'}>按创建时间排序</QItem>
      <QItem key={'1'}>按最近回复排序</QItem>
      <QItem key={'2'}>按评论数排序</QItem>
    </Menu>
  );

  function handlerCardFilterCollection(item) {
    contentCollect({
      courseId: courseId,
      first: 10,
      order: cardSort.type,
      before: undefined,
      filter: { tag: item },
      matching: searchContent !== "" ? searchContent : undefined
    }).then(res => {
      // 收藏内容的数据处理和缓存
      try {
        if (res.data.collections.edges.length !== 0) {
          const cardList = res.data.collections.edges.map(item => item.node);

          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: cardList,
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
        } else {
          client.writeQuery({
            query: PUSH_DATA,
            data: { collectionShowData: [] }
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  // 分页卡片查询
  function handlerCardCollectionPagination(input) {
    // 查询接口请求，返回数据，推到缓存里面

    if (cardFilterType.length === 0) {
      contentCollect({
        ...input,
        courseId: courseId,
        order: cardSort.type,
        matching: searchContent !== "" ? searchContent : undefined
      }).then(res => {
        // 收藏内容的数据处理和缓存

        try {
          if (res.data.collections.edges.length !== 0) {
            const cardList = res.data.collections.edges.map(item => item.node);

            client.writeQuery({
              query: PUSH_DATA,
              data: {
                collectionShowData: cardList,
                collectionTotal: res.data.collections.count,
                endCursor: res.data.collections.pageInfo.endCursor,
                startCursor: res.data.collections.pageInfo.startCursor
              }
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      contentCollect({
        ...input,
        courseId: courseId,
        order: cardSort.type,
        filter: { tag: cardFilterType },
        matching: searchContent !== "" ? searchContent : undefined
      }).then(res => {
        // 收藏内容的数据处理和缓存

        try {
          if (res.data.collections.edges.length !== 0) {
            const cardList = res.data.collections.edges.map(item => item.node);

            client.writeQuery({
              query: PUSH_DATA,
              data: {
                collectionShowData: cardList,
                collectionTotal: res.data.collections.count,
                endCursor: res.data.collections.pageInfo.endCursor,
                startCursor: res.data.collections.pageInfo.startCursor
              }
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  }

  function handlerCardCollection(input) {
    setShowPage(1);
    // 查询接口请求，返回数据，推到缓存里面
    contentCollect({
      courseId: courseId,
      first: 10,
      order: cardSort.type,
      matching: searchContent !== "" ? searchContent : undefined,
      after: undefined,
      before: undefined,
      last: undefined
    }).then(res => {
      // 收藏内容的数据处理和缓存

      try {
        if (res.data.collections.edges.length !== 0) {
          const cardList = res.data.collections.edges.map(item => item.node);

          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: cardList,
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
          //  卡片收藏为空时
        } else {
          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: [],
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }
  // 作业收藏分页代码
  function handlerHomeworkCollectionPagination(input) {
    homeWorkCollect({
      ...input,
      courseId: courseId,
      order: homeworkSort.type,
      matching: searchContent !== "" ? searchContent : undefined
    }).then(res => {
      try {
        if (res.data.collections.edges.length !== 0) {
          const cardList = res.data.collections.edges.map(item => item.node);

          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: cardList,
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  //搜索定制作业查询
  function handleSearchHomeworkCollection(parentName) {
    setShowPage(1);
    homeWorkCollect({
      courseId: courseId,
      first: 10,
      order: homeworkSort.type,
      filter: { parentName },
      last: undefined,
      after: undefined,
      before: undefined
    }).then(res => {
      try {
        if (res.data.collections.edges.length !== 0) {
          const cardList = res.data.collections.edges.map(item => item.node);
          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: cardList,
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
        } else {
          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: [],
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }
  // 作业查询logic
  function handlerHomeworkCollection(page) {
    setShowPage(1);
    homeWorkCollect({
      courseId: courseId,
      first: 10,
      order: homeworkSort.type,
      matching: searchContent  !== "" ? searchContent : undefined,
      last: undefined,
      after: undefined,
      before: undefined
    }).then(res => {
      try {
        if (res.data.collections.edges.length !== 0) {
          const cardList = res.data.collections.edges.map(item => item.node);
          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: cardList,
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
        } else {
          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: [],
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  function handlerDiscussFilterColleciion(names) {
    setShowPage(1);
    discussionCollect({
      courseId: courseId,
      first: 10,
      filter: { labels: names },
      matching: searchContent !== "" ? searchContent : undefined,
      order: discussSort.type,
      after: undefined,
      last: undefined,
      before: undefined
    }).then(res => {
      try {
        if (res.data.collections.edges.length !== 0) {
          const cardList = res.data.collections.edges.map(item => item.node);

          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: cardList,
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
        } else {
          client.writeQuery({
            query: PUSH_DATA,
            data: { collectionShowData: [] }
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  // 分页讨论查询
  function handlerDiscussColleciionPagination(input) {
    discussionCollect({
      ...input,
      courseId: courseId,
      matching: searchContent !== "" ? searchContent : undefined,
      order: discussSort.type
    }).then(res => {
      try {
        if (res.data.collections.edges.length !== 0) {
          const cardList = res.data.collections.edges.map(item => item.node);

          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: cardList,
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
        } else {
          client.writeQuery({
            query: PUSH_DATA,
            data: { collectionShowData: [] }
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }
  function handlerDiscussColleciion(page) {
    // const { courseId, dispatch } = this.props;
    // const { discussSort, filterSelectedTags, pageContent } = this.state;
    setShowPage(1);
    discussionCollect({
      courseId: courseId,
      first: 10,
      filter: { labels: selectTags },
      matching: searchContent !== "" ? searchContent : undefined,
      order: discussSort.type,
      after: undefined,
      before: undefined,
      last: undefined
    }).then(res => {
      try {
        if (res.data.collections.edges.length !== 0) {
          const cardList = res.data.collections.edges.map(item => item.node);

          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: cardList,
              collectionTotal: res.data.collections.count,
              endCursor: res.data.collections.pageInfo.endCursor,
              startCursor: res.data.collections.pageInfo.startCursor
            }
          });
        } else {
          client.writeQuery({
            query: PUSH_DATA,
            data: {
              collectionShowData: [],
              collectionTotal: res.data.collections.count
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  function renderSort() {
    let overlay = null;
    let label = '';
    switch (collectionType) {
      case 'card':
        overlay = cardSortMenu;
        label = cardSort.label;

        break;
      case 'homework':
        // overlay = this.homeworkSortMenu;
        overlay = homeworkSortMenu;
        label = homeworkSort.label;
        break;
      case 'discuss':
        // overlay = this.discussSortMenu;
        overlay = discussSortMenu;
        label = discussSort.label;
        break;
    }

    return (
      <Dropdown
        trigger={['click']}
        overlay={overlay}
        className='new-routes-study-courseCollection-2242'
      >
        <div
          className='ant-dropdown-link'
          style={{ cursor: 'pointer', color: '#000000' }}
        >
          <Icon
            type='down'
            className='new-routes-study-courseCollection-2244'
          />
          <div className='new-routes-study-courseCollection-2249'>{label}</div>
        </div>
      </Dropdown>
    );
  }

  function renderCardListItem(item, index) {
    // const { shortUrl, dispatch } = this.props;

    // const title = item.file_name.split('.')[0];
    // const suffix = item.file_name.substring(
    //   item.file_name.lastIndexOf('.') + 1,
    //   item.file_name.length
    // );
    const title = item.name.split('.')[0];
    const suffix = item.name.substring(
      item.name.lastIndexOf('.') + 1,
      item.name.length
    );

    if (item.parent) {
      return <div></div>;
    } else {
      return (
        <div className='new-routes-study-courseCollection-3422'>
          <div className='new-routes-study-courseCollection-3431'>
            <div
              className='new-routes-study-courseCollection-3521'
              onClick={() => {
                let shortUrl = window.sessionStorage.getItem('shortUrl');
                client.writeData({
                  data: {
                    collectionDetailTitle: 'card',
                    collectionDetailIndex: index
                  }
                });
                // console.log(item, 'item in 690 ');
                // 请求内容数据，然后处理 ,发送过去
                const parseContent = handleCardContent(
                  item.name,
                  item.content,
                  item.id,
                  client
                );
                client.writeQuery({
                  query: CARD_DATA,
                  data: {
                    cardData: parseContent
                  }
                });
                props.history.push(`/stu/${shortUrl}/collection/cardDetail`);
              }}
            >
              <div className='new-routes-study-courseCollection-3547'>
                <div className='new-routes-study-courseCollection-3573 '>
                  {title}
                </div>
              </div>
              <div className='new-routes-study-courseCollection-3586 '>
                <img src={getIcon(suffix)} />
                <div className='new-routes-study-courseCollection-3595'>
                  {getType(suffix)}
                </div>
              </div>
            </div>
          </div>
          <div className='new-routes-study-courseCollection-3447' />
        </div>
      );
    }
  }

  function renderHomeworkListItem(item, index) {
    const homeworkIds = [item.homework_card_id];

    const title = item.parent && item.parent.name;

    if (!item.parent) {
      return <div></div>;
    } else {
      return (
        <div className='new-routes-study-courseCollection-3422'>
          <div className='new-routes-study-courseCollection-3431'>
            <div
              className='new-routes-study-courseCollection-3611'
              onClick={() => {
                client.writeData({
                  data: {
                    collectionDetailTitle: 'homework',
                    collectionDetailIndex: index
                  }
                });

                // 获取作业的内容
                getHomeworkAnswerContent({
                  courseId,
                  id: Base64.encode(
                    'Assignment:' +
                      Base64.decode(item.id).split('Collection:')[1]
                  )
                }).then(res => {
                  const content = handleCardContent(
                    item.name,
                    item.parent.questions[0].content,
                    item.id,
                    client
                  );

                  const data = res.data.node;
                  let commentList, answerContent;
                  answerContent = data.content;

                  if (data.comments.edges.length !== 0) {
                    commentList = data.comments.edges.map(item => item.node);
                  }

                  const answerInfo = {
                    head_img: data.author.avatar,
                    user: data.author.nickname,
                    content: answerContent,
                    time: data.insertedAt,
                    id: item.id
                  };
                  const homeworkInfo = {
                    question: content,
                    title: item.parent.name
                  };

                  // 最终组件要的数据源
                  client.writeQuery({
                    query: SEND_HOMEWORK_DATA,
                    data: {
                      commentList,
                      collectionHomeworkDetailInfo: {
                        answerInfo,
                        homeworkInfo
                      }
                    }
                  });

                  const shortUrl = window.sessionStorage.getItem('shortUrl');
                  props.history.push(`/stu/${shortUrl}/collection/cardDetail`);
                });
              }}
            >
              <div className='new-routes-study-courseCollection-3634'>
                <div className='new-routes-study-courseCollection-3644'>
                  {title}
                </div>
              </div>
              <div className='new-routes-study-courseCollection-3659'>
                <div className='new-routes-study-courseCollection-3662'>
                  <Avatar
                    // src={item.user_head_img}
                    src={item.author.avatar}
                    size={'default'}
                    className='new-routes-study-courseCollection-3661'
                  />
                  <div className='new-routes-study-courseCollection-3667'>
                    {item.author.nickname} · {item.parent.insertedAt}
                    {/* {moment(item.parent.insertedAt).isSame(
                      moment().startOf('day'),
                      'd'
                    )
                      ? `今天 ${item.parent.insertedAt.split(' ')[1]}`
                      : item.comments.insertedAt} */}
                  </div>
                </div>
                <div className='new-routes-study-courseCollection-3673'>
                  <img
                    src={chat}
                    className='new-routes-study-courseCollection-3678'
                  />
                  <div className='new-routes-study-courseCollection-3689'>
                    {/* {item.comments_count > 100 ? '100+' : item.comments_count} */}
                    {item.commentsCount > 100 ? '100+' : item.commentsCount}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='new-routes-study-courseCollection-3447' />
        </div>
      );
    }
  }

  function renderDiscussListItem(item, index) {
    if (!item.labels) {
      return <div></div>;
    } else {
      return (
        // <div className="new-routes-study-courseCollection-3422">
        //   <div className="new-routes-study-courseCollection-3431" />
        //   <div className="new-routes-study-courseCollection-3447" />
        // </div>
        <Disc className='new-routes-study-courseDiscussion-disc-wrapper'>
          <Disc.Container
            onClick={() => {
              const shortUrl = window.sessionStorage.getItem('shortUrl');
              client.writeData({
                data: {
                  collectionDetailTitle: 'discuss',
                  collectionDetailIndex: index
                }
              });
              const realyId = window.atob(item.id).split('Collection:')[1];
              const discussId = window.btoa(`Discussion:${realyId}`);

              takeSingleDiscusion({
                discussID: discussId,
                courseId: courseId,
                first: 1000
              }).then(res => {
                const data = res.data.node;
                let commentList = [];

                const discDetailLabels = data.labels.map(item => {
                  return { color: item.info.color, name: item.name };
                });

                if (data.comments.edges.length !== 0) {
                  commentList = data.comments.edges.map(item => item.node);
                }

                const discDetail = {
                  head_img: data.author.avatar,
                  content: data.content,
                  time: data.insertedAt,
                  title: data.name,
                  id: item.id
                };

                client.writeQuery({
                  query: DISCUSSION_DATA,
                  data: {
                    discDetailLabels,
                    commentList,
                    discDetail,
                    discussionCollectionId: item.id,
                    isCollectedInDiscussion: data.isCollected
                  }
                });
              });
              props.history.push(`/stu/${shortUrl}/collection/cardDetail`);
              // 获取讨论查询接口

              // dispatch({
              //   type: 'course/handlerSetCollectionDetailTitle',
              //   collectionDetailTitle: 'discuss'
              // });
              // dispatch({
              //   type: 'course/handlerSetCollectiondetailIndex',
              //   collectionDetailIndex: index
              // });
              // dispatch({
              //   type: 'course/toDiscDetail',
              //   payload: { key: item.id, page: 1, count: 20, labels: item.labels }
              // });
              // dispatch(
              //   routerRedux.push(`/stu/${shortUrl}/collection/discussDetail`)
              // );
            }}
          >
            <Disc.BlankContainer />
            <Disc.TitleContainer>
              {item.labels &&
                item.labels.length > 0 &&
                item.labels.map((tagItem, index) => {
                  return (
                    <Disc.TitleTag
                      key={index.toString()}
                      style={{ backgroundColor: tagItem.info.color }}
                    >
                      {tagItem.name}
                    </Disc.TitleTag>
                  );
                })}

              <Disc.Title>{item.name}</Disc.Title>
            </Disc.TitleContainer>
            <Disc.InfoContainer>
              <Disc.InfoUser>
                <Disc.Avatar
                  className='routes-study-courseDiscussion-disc-avatar'
                  size={'small'}
                  src={item.author.avatar}
                />
                <Disc.User className='routes-study-courseDiscussion-disc-user'>
                  {item.author.nickname} · {item.insertedAt}
                </Disc.User>
              </Disc.InfoUser>
              <Disc.ReplyCount>
                <Disc.Comment data-rjs='2' src={chat} />
                <Disc.ComCount>{item.commentsCount}</Disc.ComCount>
              </Disc.ReplyCount>
            </Disc.InfoContainer>
            <Disc.BlankContainer />
          </Disc.Container>
        </Disc>
      );
    }
  }

  // 如果total_page如果大于2自然就大于2
  const total_page = Math.ceil(collectionTotal / 10);

  return (
    <Layout.Content
      className='new-routes-study-courseCollection-1000'
      style={{ overflow: 'auto' }}
    >
      <div className='new-routes-study-courseCollection-1145'>
        <Input
          maxLength={36}
          className='new-routes-study-courseCollection-1159'
          suffix={
            searchContent ? (
              <a
                onClick={() => {
                  // this.setState({ searchContent: '', pageContent: '' }, () => {
                  setSearchContent('');
                  console.log(collectionType);
                  if (collectionType === 'card') {
                    handlerCardCollection();
                  }
                  if (collectionType === 'homework') {
                    handlerHomeworkCollection();
                  }
                  if (collectionType === 'discuss') {
                    handlerDiscussColleciion();
                  }
                }}
              >
                <img src={cross} data-rjs='2' />
              </a>
            ) : null
          }
          value={searchContent}
          placeholder='搜索收藏'
          prefix={
            <a>
              <img style={{ marginLeft: '24px' }} src={search} data-rjs='2' />
            </a>
          }
          onChange={value => {
            // this.setState({ searchContent: value.target.value });
            setSearchContent(value.target.value);
          }}
          onPressEnter={e => {
            if (collectionType === 'card') {
              handlerCardCollection(1);
            }
            if (collectionType === 'homework') {
              handlerHomeworkCollection(1);
            }
            if (collectionType === 'discuss') {
              handlerDiscussColleciion(1);
            }
            // this.setState(
            //   { pageContent: e.target.value, searchContent: e.target.value },
            //   () => {
            //     if (collectionType === 'card') {
            //       handlerCardCollection(1);
            //     }
            //     if (collectionType === 'homework') {
            //       handlerHomeworkCollection(1);
            //     }
            //     if (collectionType === 'discuss') {
            //       handlerDiscussColleciion(1);
            //     }
            //   }
            // );
          }}
        />
      </div>
      <div className='new-routes-study-courseCollection-2000'>
        <div className='new-routes-study-courseCollection-2123'>
          <div
            className='new-routes-study-courseCollection-2139'
            style={{
              color: collectionType === 'card' ? '#F4404F' : '#3E3F42'
            }}
            onClick={() => {
              if (collectionType !== 'card') {
                handlerCardCollection(1);
                client.writeQuery({
                  query: GET_DATA,
                  data: { collectionType: 'card' }
                });
              }
            }}
          >
            课程
          </div>
          <div
            className='new-routes-study-courseCollection-2139'
            style={{
              color: collectionType === 'homework' ? '#F4404F' : '#3E3F42'
            }}
            onClick={() => {
              if (collectionType !== 'homework') {
                handlerHomeworkCollection(1);
                client.writeQuery({
                  query: GET_DATA,
                  data: {
                    collectionType: 'homework'
                  }
                });
                client.writeData({ data: { collectionType: 'homework' } });
                // dispatch({
                //   type: 'course/handlerSetCollectionType',
                //   collectionType: 'homework'
                // });
              }
            }}
          >
            作业
          </div>
          <div
            className='new-routes-study-courseCollection-2139'
            style={{
              color: collectionType === 'discuss' ? '#F4404F' : '#3E3F42'
            }}
            onClick={() => {
              if (collectionType !== 'discuss') {
                handlerDiscussColleciion(1);
                client.writeQuery({
                  query: GET_DATA,
                  data: {
                    collectionType: 'discuss'
                  }
                });

                // dispatch({
                //   type: "course/getCollectionDiscussData",
                //   payload: {
                //     course_id: courseId,
                //     page: 1,
                //     per_page: 12
                //   }
                // });
                // dispatch({
                //   type: 'course/handlerSetCollectionType',
                //   collectionType: 'discuss'
                // });
              }
            }}
          >
            讨论
          </div>
        </div>

        <div className='new-routes-study-courseCollection-2234'>
          {renderFilter()}
          {renderSort()}
        </div>
      </div>

      <div className='new-routes-study-courseCollection-3108'>
        <Spin
          // spinning={collectionLoading.loading}
          // tip={collectionLoading.tip}
          // spinning={collectionLoading.loading}
          spinning={false}
          tip={''}
          // tip={collectionLoading.tip}
          // indicator={<LoadingView src={loading} />}
          size={'large'}
        >
          <Layout.Content className='new-routes-study-courseCollection-3214'>
            {collectionShowData.length === 0 ? (
              <EmptyLayout top={'120px'} bottom={'0px'}>
                <EmptyLayout.Img data-rjs='2' src={empty1s} />
                <EmptyLayout.Text>没有找到收藏的内容</EmptyLayout.Text>
              </EmptyLayout>
            ) : (
              <List
                pagination={
                  total_page < 2
                    ? null
                    : {
                        pageSize: 10,
                        // current: this.state.showPage,
                        current: showPage,
                        // total: collectionShowDataTotalCount,
                        // total: 100,
                        total: collectionTotal,
                        onChange: e => {
                          // 跨域页面计算
                          let page = e - showPage;
                          // 计算得到上或下页的游标
                          let input = getPreviousAndNexPagination(
                            page,
                            endCursor,
                            startCursor
                          );

                          // 页码选中样式设置
                          setShowPage(e);
                          if (collectionType === 'card') {
                            handlerCardCollectionPagination(input);
                          }
                          if (collectionType === 'homework') {
                            handlerHomeworkCollectionPagination(input);
                          }
                          if (collectionType === 'discuss') {
                            handlerDiscussColleciionPagination(input);
                          }
                        }
                      }
                }
                split
                dataSource={collectionShowData}
                renderItem={(item, index) => {
                  switch (collectionType) {
                    case 'card':
                      return renderCardListItem(item, index);
                      break;
                    case 'homework':
                      return renderHomeworkListItem(item, index);
                      break;
                    case 'discuss':
                      return renderDiscussListItem(item, index);
                      break;
                  }
                }}
              />
            )}
          </Layout.Content>
        </Spin>
      </div>
    </Layout.Content>
  );
};

export default withRouter(CourseCollection);
