import * as React from 'react';
import './styles.css';
import parseSearch from 'utils/search';

//引入的库组件
import { Redirect, withRouter } from 'react-router-dom';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { Layout, Icon, Input, Dropdown, Avatar, Badge } from 'antd';
import { createBrowserHistory } from 'history';

//引入的其他页面组件
import CoursesMenu from './CoursesMenu';
import UserMenu from './UserMenu';

import Login from '../LoginAndRegister/Login';
import Register from '../LoginAndRegister/Register';
import ResetPasswod from '../LoginAndRegister/ResetPassword';
import CreateModal from '../CreateModal/CreateModal';
import CopyModal from '../CopyModal/CopyModal';
import RenameModal from '../RenameModal/RenameModal';
import RejectModal from '../RejectModal/RejectModal';
import UrlModal from '../UrlModal/UrlModal';
import headers from '../../global_variable/headers';
import manager from '../../global_variable/manager';
import learningCenter from '../../global_variable/learningCenter';
import collectionPage from '../../global_variable/collectionPage';
import home from '../../global_variable/home';
import courses from '../../global_variable/courses';
import collegeDetail from '../../global_variable/collegeDetail';
import courseDetail from '../../global_variable/courseDetail';
import my from '../../global_variable/my';
import stu from '../../global_variable/stu';
import common from '../../global_variable/common';
import discusspage from '../../global_variable/discusspage';
import { RedButton, WhiteButton } from '../CommonComponents/CustomButton';
import Subscription from 'components/Subscription';

//引入的资源图片
import HEADER_LOGO from '../../assets/omcbg/red_logo.png';
import search from '../../assets/icons/search.png';
import cross from '../../assets/icons/cross.png';
import arrow_down_solid from '../../assets/icons/arrow_down_solid.png';
import mobileMenuIcon from '../../assets/icons/mobile-menu.png';

const GET_INFOS_FOR_USER = gql`
  {
    isLoggedIn @client
    userInfo @client {
      head_img
      username
      userid
      phone
      role
    }
    badge @client {
      all
    }
    allCourseSearchContent @client
    screenful @client
  }
`;

const HomePageHeaderContainer = (props: any) => {
  const client = useApolloClient();
  const { data } = useQuery(GET_INFOS_FOR_USER);
  const {
    isLoggedIn,
    userInfo,
    badge,
    allCourseSearchContent,
    screenful
  } = data;
  const { userid } = userInfo;
  const { history } = props;

  const [dropdownVisible, setDropdownVisible] = React.useState(false);
  const [dropType, setDropType] = React.useState(null);

  // 全局搜索
  const searchValue: any = parseSearch(history.location.search);
  const { value } = searchValue;

  React.useEffect(() => {
    if (value) {
      client.writeData({
        data: { allCourseSearchContent: decodeURI(value) }
      });
    } else {
      client.writeData({
        data: { allCourseSearchContent: '' }
      });
    }
  }, [value]);

  const resetSearchContent = () => {
    client.writeData({
      data: { allCourseSearchContent: '' }
    });
  };

  const jumpToMain = () => {
    resetSearchContent();
    setDropType(null);
    history.push('/');
  };

  const searchCourse = () => {
    setDropType(null);
    if (allCourseSearchContent === '') {
      history.push(`/mkt/courses`);
    } else {
      history.push(`/mkt/courses?value=${allCourseSearchContent}`);
    }
  };

  const toggleDropPanel = (type: any) => {
    // this.setState({ dropType: this.state.dropType === type ? null : type });
    setDropType(dropType === type ? null : type);
  };

  const userMenuInMobile = () => {
    let userInfOb: any = {};
    try {
      userInfOb = userInfo;
    } catch (error) {
      false && console.log(error);
    }
    return (
      <div
        className={`home-page-header-4134 home-page-header-4134-${dropType}`}
      >
        {isLoggedIn && <Subscription />}
        {screenful ? (
          <div />
        ) : (
          <Input
            value={allCourseSearchContent}
            onChange={e => {
              client.writeData({
                data: { allCourseSearchContent: e.target.value }
              });
            }}
            suffix={
              allCourseSearchContent ? (
                <a
                  onClick={() => {
                    const pathname = history.location.pathname;
                    if (pathname === '/mkt/courses') {
                      history.push(`/mkt/courses`);
                    }
                    setDropType(null);
                    client.writeData({
                      data: { allCourseSearchContent: '' }
                    });
                  }}
                >
                  <img
                    src={cross}
                    data-rjs='2'
                    style={{ width: 13, height: 13 }}
                  />
                </a>
              ) : null
            }
            className='home-page-header-4653 home-page-header-search'
            placeholder='搜索课程'
            prefix={
              <a>
                <img src={search} data-rjs='2' />
              </a>
            }
            onPressEnter={searchCourse}
          />
        )}
        {isLoggedIn && userInfOb !== null ? (
          <div
            className='home-page-header-4655 home-page-header-menu'
            style={{ marginLeft: userInfOb.role !== 'user' ? 78 : 164 }}
          >
            <div className='no-mobile-none home-page-header-drop'>
              <div className='home-page-header-item-head'>
                欢迎您，{userInfOb.username}
              </div>
              <span
                className='home-page-header-7183 home-page-header-item'
                onClick={jumpToMain}
              >
                首页
              </span>
              <div className='home-page-header-hr' />
              <div
                className='home-page-header-item'
                onClick={() => {
                  resetSearchContent();
                  setDropType(null);
                  history.push('/stu');
                }}
              >
                我的学习
              </div>
              {userInfOb.role !== 'user' ? (
                <div
                  className='home-page-header-item'
                  onClick={() => {
                    resetSearchContent();
                    history.push('/edu');
                    setDropType(null);
                    client.writeData({
                      data: { isShowFooter: false }
                    });
                  }}
                >
                  教研中心
                </div>
              ) : null}
              <div
                className='home-page-header-item'
                onClick={() => {
                  resetSearchContent();
                  setDropType(null);
                  client.writeData({
                    data: {
                      allCourseSearchContent: '',
                      isShowFooter: true,
                      badge: { all: 0, __typename: 'Badge' }
                    }
                  });
                  history.push(`/msg`);
                }}
              >
                消息
                <Badge offset={[0, -22]} count={badge.all} />
              </div>
              <div className='home-page-header-hr' />
              <div
                className='home-page-header-item'
                onClick={() => {
                  setDropType(null);
                  resetSearchContent();
                  history.push('/my');
                }}
              >
                个人设置
              </div>
              <div
                className='home-page-header-item'
                onClick={() => {
                  setDropType(null);
                  resetSearchContent();
                  headers.Authorization = '';
                  client.writeData({
                    data: {
                      //主页头 主页脚
                      isShowHeader: true,
                      isShowFooter: true,

                      //一些弹出菜单用的全局变量
                      isShowLoginModal: false,
                      isShowResetResult: false,
                      isShowResetModal: false,
                      resetLoading: false,
                      isShowRegisterModal: false,
                      isShowResetButton: true,
                      buttonLoading: false,
                      showButton: true,

                      isLoggedIn: false,

                      userInfo: {
                        head_img: '',
                        phone: '',
                        role: '',
                        token: '',
                        userid: '',
                        username: '',
                        userProfile: null,
                        __typename: 'UserInfo'
                      },
                      badge: {
                        all: 0,
                        apply: 0,
                        com: 0,
                        sys: 0,
                        __typename: 'Badge'
                      },

                      allCourseSearchContent: '',
                      allCourseCollegeSelect: '全部',
                      allCourseCourseSelect: '全部',

                      ...common,
                      ...discusspage,

                      ...manager,
                      home,
                      courses,
                      collegeDetail,
                      courseDetail,
                      my,
                      stu
                    }
                  });
                  window.localStorage.setItem('LOGINSTATUS', '1');
                  window.localStorage.setItem('USERINFO', '');
                  window.localStorage.setItem('TOKENINFO', '');
                  props.history.push('/');
                }}
              >
                退出登录
              </div>
            </div>
          </div>
        ) : (
          <div className='home-page-header-7182 home-page-header-menu'>
            <RedButton
              className='mobile-none'
              width={'60px'}
              height={'40px'}
              onClick={() => {
                client.writeData({ data: { isShowLoginModal: true } });
              }}
            >
              登录
            </RedButton>
            <WhiteButton
              className='home-page-header-7547 mobile-none'
              width={'60px'}
              height={'40px'}
              onClick={() => {
                client.writeData({ data: { isShowRegisterModal: true } });
              }}
            >
              注册
            </WhiteButton>
            <div className='no-mobile-none home-page-header-drop'>
              <div className='home-page-header-item'>
                <span
                  onClick={() => {
                    setDropType(null);
                    client.writeData({ data: { isShowLoginModal: true } });
                  }}
                >
                  登录
                </span>
                ·
                <span
                  onClick={() => {
                    setDropType(null);
                    client.writeData({ data: { isShowRegisterModal: true } });
                  }}
                >
                  注册
                </span>
              </div>
              <div className='home-page-header-hr' />
              <span
                className='home-page-header-7183 home-page-header-item'
                onClick={jumpToMain}
              >
                首页
              </span>
              <span className='home-page-header-7183 home-page-header-item'>
                <a href='https://blog.openmindclub.com'>博客</a>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout className='home-page-header-1040' id='main_layout'>
      <Layout className='home-page-header-1048'>
        <img
          src={mobileMenuIcon}
          className='home-page-header-3789'
          alt='mobileMenuIcon'
          onClick={() => {
            toggleDropPanel('menu');
          }}
        />
        <Layout.Content className='home-page-header-1075'>
          <div className='home-page-header-1124'>
          <a href="https://www.openmindclub.com" target="_blank">
            <img
              data-rjs='2'
              onClick={() => {
                client.writeData({ data: { isShowFooter: true } });
                props.props.history.push('/mkt');
              }}
              alt='omc'
              src={HEADER_LOGO}
              className='home-page-header-1137'
            />
          </a>

            <div className='home-page-header-1216'>
              <div
                className='home-page-header-1224'
                onClick={() => {
                  client.writeData({
                    data: { allCourseSearchContent: '', isShowFooter: true }
                  });
                  props.props.history.push('/mkt');
                }}
              >
                首页
              </div>
              <div className='ant-dropdown-link'>
                <Dropdown
                  getPopupContainer={() =>
                    document.getElementById('main_layout')!
                  }
                  trigger={['hover']}
                  visible={dropdownVisible}
                  onVisibleChange={e => {
                    setDropdownVisible(!dropdownVisible);
                  }}
                  overlay={<CoursesMenu />}
                  placement={'bottomLeft'}
                  overlayClassName='home-page-header-5015'
                  transitionName=''
                >
                  <div className='home-page-header-1220'>
                    <Icon type='down' />
                    <div
                      className='home-page-header-1224'
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        history.push(`/mkt/courses`);
                        if (
                          window.location.pathname.indexOf('/mkt/courses') ===
                          -1
                        ) {
                          setDropdownVisible(false);
                          client.writeData({
                            data: {
                              allCourseSearchContent: '',
                              allCourseCollegeSelect: '全部',
                              allCourseCourseSelect: '全部',
                              isShowFooter: true
                            }
                          });
                        }
                      }}
                    >
                      全部课程
                    </div>
                  </div>
                </Dropdown>
              </div>
              <div className='home-page-header-1224'>
                {/* <a href='https://blog.openmindclub.com' target='_blank'>
                  博客
                </a> */}
              </div>
            </div>
          </div>

          <div className='home-page-header-1639'>
            <Input
              maxLength={20}
              className='home-page-header-1654'
              value={allCourseSearchContent}
              placeholder='搜索课程'
              prefix={
                <a>
                  <img
                    style={{ marginLeft: '10%' }}
                    src={search}
                    data-rjs='2'
                  />
                </a>
              }
              suffix={
                allCourseSearchContent ? (
                  <a
                    onClick={() => {
                      const pathname = history.location.pathname;
                      if (pathname === '/mkt/courses') {
                        history.push(`/mkt/courses`);
                      }
                      client.writeData({
                        data: { allCourseSearchContent: '' }
                      });
                    }}
                  >
                    <img
                      src={cross}
                      data-rjs='2'
                      style={{ width: 13, height: 13 }}
                    />
                  </a>
                ) : null
              }
              onChange={value => {
                client.writeData({
                  data: { allCourseSearchContent: value.target.value }
                });
              }}
              onPressEnter={searchCourse}
            />

            {isLoggedIn && userInfo !== null ? (
              <div className='home-page-4655 home-page-menu'>
                <Badge count={badge.all}>
                  <div
                    onClick={() => {
                      client.writeData({
                        data: {
                          allCourseSearchContent: '',
                          isShowFooter: true
                        }
                      });
                      history.push(`/msg`);

                      // return <Redirect to='/msg' />;
                    }}
                    className='home-page-5593 mobile-none'
                  >
                    消息
                  </div>
                </Badge>
                <div
                  className='home-page-6364 mobile-none'
                  onClick={() => {
                    client.writeData({
                      data: { allCourseSearchContent: '', isShowFooter: true }
                    });
                    history.push(`/stu`);
                    // return <Redirect to='/stu' />;
                  }}
                >
                  <div className='home-page-6388'>我的学习</div>
                </div>
                <div className='home-page-6715 mobile-none'>
                  <Dropdown
                    overlay={
                      <UserMenu userInfo={userInfo} props={props.props} />
                    }
                  >
                    <div className='home-page-6841'>
                      <Avatar size={48} src={userInfo.head_img} />
                      <img src={arrow_down_solid} className='home-page-7015' />
                    </div>
                  </Dropdown>
                </div>
              </div>
            ) : (
              <div className='home-page-header-1714'>
                <div
                  className='home-page-header-1677'
                  onClick={() => {
                    client.writeData({ data: { isShowLoginModal: true } });
                  }}
                >
                  <div className='home-page-header-1685'>登录</div>
                </div>
                <div
                  className='home-page-header-1690'
                  onClick={() => {
                    client.writeData({ data: { isShowRegisterModal: true } });
                  }}
                >
                  <div className='home-page-header-1695'>注册</div>
                </div>
              </div>
            )}
          </div>
        </Layout.Content>

        {
          //手机适配时的搜索按钮
          <img
            className='home-page-header-4133'
            src={search}
            onClick={() => {
              toggleDropPanel('search');
            }}
            data-rjs='2'
          />
        }

        <Login />
        <Register />
        <ResetPasswod />
        <CreateModal />
        <CopyModal />
        <RenameModal />
        <UrlModal />
        <RejectModal />
      </Layout>
      {userMenuInMobile()}
    </Layout>
  );
};

export default withRouter(HomePageHeaderContainer);
