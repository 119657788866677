import React from 'react';
import './QRCode.css';
import kzqr from '../../assets/omcbg/kzqr.png';
function QRCode(props) {
  return (
    <div className='qrcode-container'>
      <img src={kzqr} className='qrcode-image' />
      <p className='qrcode-care'>关注我们</p>
    </div>
  );
}

export default QRCode;
