import gql from 'graphql-tag';

export const SUBSCRIBED_COURSES = gql`
  query subscribedCourses($first: Int) {
    subscribedCourses(first: $first) {
      count
      edges {
        node {
          title
          cover
          shortUrl
          isFinished
          isOnline
          isEnd
          needUserProfile
        }
      }
    }
  }
`;
