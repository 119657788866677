import * as React from 'react';
import './styles.css';

//引入的库组件
import { Base64 } from 'js-base64';
import { withRouter } from 'react-router';
import { Radio, Checkbox } from 'antd';
import { useApolloClient } from 'react-apollo';

//引入的其他页面组件
import DiffView from '../../../CommonComponents/DiffView';

//graphql查询
import {
  useGetCommitDetailQuery,
  useGetAnswerCommitsQuery,
  useGetCommitDetailContentQuery,
  useGetCommitDetailDiffQuery
} from '../../../../generated/graphql';

//引入的资源图片
import backImg from '../../../../assets/icons/back.png';

const DiffHomeworkContainer = (props: any) => {
  const client = useApolloClient();
  const id = Base64.encode(
    'Commit:' + window.location.pathname.split('diffhomework/')[1]
  );

  const answerId = window.location.pathname
    .split('diffhomework/')[1]
    .split(':')[0];

  const commitHash = window.location.pathname
    .split('diffhomework/')[1]
    .split(':')[1];

  const { refetch: getCommitList } = useGetAnswerCommitsQuery({
    variables: {
      id: Base64.encode('Assignment:' + answerId)
    },
    fetchPolicy: 'no-cache',
    skip: true
  });

  const { refetch: getCommit } = useGetCommitDetailQuery({
    variables: { id: id },
    fetchPolicy: 'no-cache',
    skip: true
  });

  const { refetch: getCommitContent } = useGetCommitDetailContentQuery({
    variables: { id: id },
    fetchPolicy: 'no-cache',
    skip: true
  });

  const { refetch: getCommitDiff } = useGetCommitDetailDiffQuery({
    variables: { id: id },
    fetchPolicy: 'no-cache',
    skip: true
  });

  const [showType, setShowType] = React.useState('unified');
  const [showAll, setShowAll] = React.useState(false);
  const [commitList, setCommitList] = React.useState([]);
  const [currentCommit, setCurrentCommit] = React.useState(null);
  const [lastCommit, setLastCommit] = React.useState(null);
  const [homeworId, setHomeworkId] = React.useState('');

  React.useEffect(() => {
    getCommitList().then((res: any) => {
      if (res.data.node.commits) {
        let commits = res.data.node.commits;
        setCommitList(commits);
        client.writeData({ data: { homeworkCommits: res.data.node.commits } });

        let isFirstCommit = true;
        let lastCommitHash = '';

        for (let i = 0; i < commits.length; i++) {
          if (commits[i].commitHash === commitHash && i < commits.length - 1) {
            isFirstCommit = false;
            lastCommitHash = Base64.encode(
              'Commit:' + answerId + ':' + commits[i + 1].commitHash
            );
            break;
          }
        }

        if (isFirstCommit) {
          getCommitDiff({ id: id }).then((res: any) => {
            if (res.data.node) {
              setCurrentCommit(res.data.node);
              setHomeworkId(res.data.node.assignment.pid);
            } else {
              setCurrentCommit(null);
            }
          });
        } else {
          getCommitContent({ id: id }).then((res: any) => {
            if (res.data.node) {
              setCurrentCommit(res.data.node);
              setHomeworkId(res.data.node.assignment.pid);
              getCommitContent({ id: lastCommitHash }).then((res: any) => {
                if (res.data.node) {
                  setLastCommit(res.data.node);
                } else {
                  setLastCommit(null);
                }
              });
            } else {
              setCurrentCommit(null);
            }
          });
        }

        // getCommit({ id: id }).then((res: any) => {
        //   if (res.data.node) {
        //     setCurrentCommit(res.data.node);
        //     setHomeworkId(res.data.node.assignment.pid);

        //     if (!isFirstCommit) {
        //       getCommit({ id: lastCommitHash }).then((res: any) => {
        //         if (res.data.node) {
        //           setLastCommit(res.data.node);
        //         } else {
        //           setLastCommit(null);
        //         }
        //       });
        //     }
        //   } else {
        //     setCurrentCommit(null);
        //   }
        // });
      } else {
        setCommitList([]);
        client.writeData({ data: { homeworkCommits: [] } });
      }
    });
  }, []);

  if (!((currentCommit && (currentCommit as any).diff) || ((currentCommit && (currentCommit as any).content) && (lastCommit && (lastCommit as any).content)))) {
    return <div />;
  }

  return (
    <div className='diffhomework-1000'>
      <div className='diffhomework-1158 '>
        <div className='diffhomework-1376'>
          <div
            className='diffhomework-1429'
            onClick={() => {
              const shortUrl = window.sessionStorage.getItem('shortUrl');
              props.history.goBack();
              // props.history.push(`/stu/${shortUrl}/homework/${homeworId}`);
            }}
          >
            <img src={backImg} />
            <span className='diffhomework-1731'>返回</span>
          </div>

          <div>
            {lastCommit !== null && (
              <Checkbox
                defaultChecked={showAll}
                onChange={e => {
                  setShowAll(e.target.checked);
                }}
                style={{ marginRight: 10 }}
              >
                查看全文
              </Checkbox>
            )}

            <Radio.Group
              onChange={e => {
                setShowType(e.target.value);
              }}
              defaultValue={showType}
            >
              <Radio.Button value='unified'>单栏对比</Radio.Button>
              <Radio.Button value='split'>双栏对比</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div className='diffhomework-1894'>
          <DiffView
            currentCommit={currentCommit}
            lastCommit={lastCommit}
            showAll={showAll}
            showType={showType}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(DiffHomeworkContainer);
