import React, { FC } from 'react';
import { CollegesCoursesTypes } from 'global_variable/courses';

interface IRadioProps {
  data: CollegesCoursesTypes[];
  checked: string;
  handleClick: (name: string, id: string) => void;
}

const Radio: FC<IRadioProps> = ({ data, checked, handleClick }) => {
  return (
    <>
      <div
        className={
          checked === '全部' ? 'courses-radio-checked' : 'courses-radio'
        }
        onClick={() => handleClick('全部', '全部')}
      >
        全部
      </div>
      {data.map(item => (
        <div
          key={item.id}
          className={
            item.name === checked ? 'courses-radio-checked' : 'courses-radio'
          }
          onClick={() => handleClick(item.name, item.id)}
        >
          {item.name === '效率学院' ? '技术学院' : item.name}
        </div>
      ))}
    </>
  );
};

export default Radio;
