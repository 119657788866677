import * as React from 'react';
import './styles.css';

//引入的库组件
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

//引入的其他页面组件
import DiscussEditPage from './DiscussEditPage';

//graphql查询

//引入的资源图片

const GET_DISCUSS_EDIT_INFO = gql`
  {
    isModifyDisc @client(always: true)
    discussSelectedTags @client (always: true)
    discussMarkdownContent @client (always: true)
    discussSelfDiscussTitle @client (always: true)
  }
`;

const DiscussEditPageContainer = () => {
    const { data } = useQuery(GET_DISCUSS_EDIT_INFO);

    return <DiscussEditPage /> ;
}

export default DiscussEditPageContainer;