import * as React from 'react';
import './styles.css';

//引入的库组件
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { useApolloClient } from 'react-apollo';

//引入的其他页面组件
import CreateCourse from './CreateCourseContainer';
import ModifyCourse from './ModifyCourseContainer';

//graphql查询
import { useGetEduCreatedCoursePackagesQuery } from '../../generated/graphql';

//引入的资源图片

const GET_CREATE_COURSE_INFO = gql`
  {
    isEditCourse @client(always: true)
    editCourseId @client(always: true)
  }
`;

const EduManagerCreateCoursePageContainer = () => {
  const { data: queryData, error: queryError, loading: queryLoading } = useGetEduCreatedCoursePackagesQuery({variables: {first: 1000, filter: {isMaster: true}}, fetchPolicy:"network-only"});

  const client = useApolloClient();
  const { data } = useQuery(GET_CREATE_COURSE_INFO);
  const { isEditCourse, editCourseId } = data;

  if(!queryData || queryError || queryLoading) {
    return <div />;
  }

  if (isEditCourse) {
    return <ModifyCourse editCourseId={editCourseId} isEditCourse={isEditCourse} packageData={queryData} />;
  } else {
    return <CreateCourse isEditCourse={isEditCourse} packageData={queryData} />;
  }
};

export default EduManagerCreateCoursePageContainer;
