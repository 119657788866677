import * as React from 'react';
import './styles.css';

//引入的库组件
import { withRouter } from 'react-router';

//引入的其他页面组件
import TextCardEditPage from './TextCardEditPage';

//graphql查询

//引入的资源图片


const CreateTextCardContainer = (props: any) => {

    return  <TextCardEditPage props={props} cardInfo={null} />;
}

export default withRouter(CreateTextCardContainer);