import styled from 'styled-components';
import { Layout } from 'antd';

interface EmptyLayoutProps {
    bottom: string;
    top: string;
}

const EmptyLayout : any = styled(Layout.Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2%;
  padding-bottom: ${(props: EmptyLayoutProps) => props.bottom};
  margin-top: ${(props: EmptyLayoutProps) => props.top};
`;
EmptyLayout.Img = styled.img`
`;
EmptyLayout.Text = styled.p`
   color: #93979F;
   font-size: 16px;
   margin-top: 30px;
   line-height: 22px;
`;

export default EmptyLayout;