import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-apollo-hooks';
import { writeQuery } from './cache';
import { useApolloClient } from 'react-apollo';
import { College } from 'global_variable/home';
import './styles.css';

//引入的库组件

//graphql查询

//引入的其他页面组件

//引入的资源图片

interface Props {
  menuData: any;
}

const CoursesMenuDetail: React.FC<Props & RouteComponentProps> = ({
  menuData,
  history
}) => {
  const client = useApolloClient();

  if (!menuData) {
    return null;
  }

  const selectCourse = menuData;
  let componentIndex = [0, 1, 2];

  return (
    <div className='home-page-header-5306'>
      {componentIndex.map((itemIndex, curIndex) => {
        return selectCourse.course_names[itemIndex] ? (
          <div key={curIndex + ''} className='home-page-header-5413'>
            <div className='home-page-header-5521'>
              {selectCourse.course_names[itemIndex]}
            </div>
            <div className='home-page-header-5544'>
              {selectCourse[selectCourse.course_names[itemIndex]].length < 1 ? (
                <div className='home-page-header-5579'>没有相关课程</div>
              ) : (
                selectCourse[selectCourse.course_names[itemIndex]].map(
                  (item: any, index: any) => {
                    if (index > 1) {
                      return null;
                    }
                    return (
                      <div
                        key={index}
                        className='home-page-header-5568'
                        onClick={() => {
                          history.push(`/mkt/course/${item.short_url}`);
                        }}
                      >
                        {item.title}
                      </div>
                    );
                  }
                )
              )}
              {selectCourse[selectCourse.course_names[itemIndex]].length > 2 ? (
                <div
                  className='home-page-header-5569'
                  onClick={() => {
                    history.push('/mkt/courses');
                    client.writeQuery({
                      query: writeQuery,
                      data: {
                        courses: {
                          collegeName: selectCourse.name,
                          courseName: selectCourse.course_names[itemIndex],
                          __typename: 'courses'
                        }
                      }
                    });
                  }}
                >
                  更多
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div key={curIndex + ''} className='home-page-header-5413' />
        );
      })}
    </div>
  );
};

export default withRouter(CoursesMenuDetail);
