import videojs from 'video.js';
import './VideoMarquee.css';
import { of } from 'zen-observable';

let videoMarquee = null;

const getVideoMarquee = () => {
  if(videoMarquee){
    return videoMarquee;
  }else {
    videoMarquee = new VideoMarquee();
    return videoMarquee;
  }
}

class VideoMarquee {
  constructor() {
    this.marqueeStr = '';
    this.videojsName = '';
    this.timeid = null;
    this.checkMarqueeTimeId = null;
    this.isFirstCheck = true;
    this.isShowMarquee = false;
    this.marquee = null;
    this.checkMarqueeid = null;
  }

  initMarqueeComponent(playerName, marqueeInfo) {
    this.videojsName = playerName;
    this.marqueeStr = marqueeInfo;
    this.deleteExistedMarquee();
    this.createMarqueeComponent();
    this.addMarquee();
    if(!this.checkMarqueeid) {
      this.checkMarqueeid = setTimeout(() => {
        this.checkMarquee();
      }, Math.random() * 10000);
    }
  }

  deleteExistedMarquee() {
    this.stopAnimation();
    let marquee = document.getElementById('marquee');
    if (marquee) {
      marquee.parentNode.removeChild(marquee);
    }
  }

  checkMarquee() {
    this.checkMarqueeTimeId = setInterval(() => {
      let marquee = document.getElementById('marquee');
      try {
        this.player = videojs(this.videojsName);
        if (this.player.hasStarted_) {
          if(!this.isShowMarquee) {
            this.startAnimation();
            this.isShowMarquee = true;
          }
        } else {
          if(this.isShowMarquee) {
            this.isShowMarquee = false;
            this.stopAnimation();
            this.deleteExistedMarquee();
          }
        }
      } catch (e) {}
      if (!this.player && this.isFirstCheck) {
        clearInterval(this.checkMarqueeTimeId);
        this.checkMarqueeTimeId = null;
        this.stopAnimation();
        this.isFirstCheck = true;
        this.isShowMarquee = false;
      } else {
        this.isFirstCheck = true;
        if (this.player && !marquee) {
          this.createMarqueeComponent();
          this.addMarquee();
          this.stopAnimation();
          setTimeout(() => {
            if (this.isShowMarquee) {
              this.startAnimation();
            }
          }, Math.random() * 10000);
        }
      }
    }, 1000);
  }

  addMarquee() {
    try {
      let player = videojs(this.videojsName);
      let marquee = document.getElementById('marquee');
      if (this.player && !marquee) {
        player.addChild('Marquee', { text: this.marqueeStr });
      }
    } catch (e) {}
  }

  createMarqueeComponent() {
    if(this.marquee){
      return;
    }
    this.isFirstCheck = false;
    let videoComponent = videojs.getComponent('Component');
    this.marquee = videojs.extend(videoComponent, {
      constructor: function(player, options) {
        videoComponent.apply(this, arguments);
        if (options.text) {
          this.marqueeStr = options.text;
          this.updateTextContent(options.text);
        }
      },
      createEl: function() {
        return videojs.dom.createEl('div', {
          className: 'vjs-marquee',
          id: 'marquee'
        });
      },
      updateTextContent: function(text) {
        if (typeof text !== 'string') {
          text = 'Text Unkonwn';
        }

        videojs.dom.emptyEl(this.el());
        videojs.dom.appendContent(this.el(), text);
      }
    });

    videojs.registerComponent('Marquee', this.marquee);
  }

  startAnimation() {
    this.stopAnimation();
    try {
      if (this.timeid) {
        return;
      }
      let marquee = document.getElementById('marquee');
      let marqueeStyle = document.getElementById('marquee').style;

      let video = document.getElementById(this.videojsName);

      let width = video.offsetWidth;
      let height = video.offsetHeight;

      if (height > width) {
        width = width + height;
        height = width - height;
        width = width - height;
      }

      let startPositionX = Math.random() * width;
      let startPositionY = Math.random() * height;

      this.marquee.textContent = this.marqueeStr;
      marqueeStyle.display = 'block';

      marqueeStyle.left = startPositionX + 'px';
      marqueeStyle.top = startPositionY + 'px';
      marqueeStyle.color = "#ffffff";
      marqueeStyle.opacity = 0.5;
      marqueeStyle.fontSize = "16px";
      marqueeStyle.position = "absolute";
      marqueeStyle.minWidth = "80px";
      marqueeStyle.minHeight= "40px";
      marqueeStyle.lineHeight = "40px";
      marqueeStyle.whiteSpace = "nowrap";
      
      let current = 0;

      this.timeid = setInterval(() => {
        try {
          current += 1;
          video = document.getElementById(this.videojsName);
          width = startPositionX;
          height = startPositionY;

          this.marquee.textContent = this.marqueeStr;
          marqueeStyle.display = 'block';
          marqueeStyle.left = width - current + 'px';
          if (!document.getElementById('marquee')) {
            this.stopAnimation();
            return;
          }

          if (width - current < -200) {
            marqueeStyle.left = '999999px';
            clearInterval(this.timeid);
            this.timeid = null;
            setTimeout(this.startAnimation.bind(this), Math.random() * 1000);
          }
        } catch (e) {
          this.stopAnimation();
        }
      }, 10);
    } catch (e) {
      this.stopAnimation();
    }
  }

  stopAnimation() {
    let marquee = document.getElementById('marquee');
    if(marquee) {
      let marqueeStyle = document.getElementById('marquee').style;
      marqueeStyle.left = '999999px';
    }
    if(this.timeid) {
      clearInterval(this.timeid);
      this.timeid = null;
    }
    if (this.timeid) {
      clearInterval(this.timeid);
      this.timeid = null;
    }
  }
}

export default getVideoMarquee();
