import styled from 'styled-components';
import { colors } from '../../../assets/colors';
import Checkbox from 'antd/es/checkbox/Checkbox';

const File: any = styled.div`
    height: 120px;
    width: 120px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

File.Cover = styled.img`
  align-self: center;
  margin-top: 10px;
   cursor: pointer;
`;

File.Checkbox = styled(Checkbox)`

`;

File.Title = styled.div`
  word-break: break-all;
  align-self: center;
  font-size: 14px;
  color: #282A2D;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;
  line-height: 20px;
  width: 100px;
  height: 40px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
   cursor: pointer;
`;

File.Mark = styled.img`
margin-left: -10px;
margin-top: 50px;
`

export { File };
