const parseSearch = search => {
  if (!search) return {};
  try {
    const result = {};
    const target = search.slice(1);
    target.split('&').forEach(item => {
      const kv = item.split('=');
      result[kv[0]] = kv[1];
    });
    return result;
  } catch (err) {
    return {};
  }
};

export default parseSearch;
