import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Layout, Spin, Breadcrumb, Modal, Input, message } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Base64 } from 'js-base64';
import { useApolloClient } from 'react-apollo';

//引入的其他页面组件
import ItemRender from '../CommonComponents/ItemRender';
import MDEditor_v2 from '../CommonComponents/MDEditor/MDEditor_v2';
import { RedButton } from '../CommonComponents/CustomButton';
import { checkMDContent } from '../../utils/checkForm';
import { getTextCardContent } from '../../utils';

//graphql查询
import {
  useCreateCardMutation,
  useModifyCardMutation,
  FileType,
  GetCardInfoQuery,
  useDeleteCardMutation
} from '../../generated/graphql';

//引入的资源图片
import backImg from '../../assets/icons/back.png';
import del from '../../assets/icons/delete.png';

interface Props extends FormComponentProps {
  props: any;
  cardInfo: GetCardInfoQuery | null;
}

const CustomForm = styled(Form)`
  .ant-form-explain {
    margin-left: 10px;
  }
`;

const GET_CREATE_CARD_INFO = gql`
  {
    pMode @client
    isEditCard @client(always: true)
    selectedFileId @client
    selectedFilePackageId @client
  }
`;

const CreateTextCard = (props: any) => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = React.useState(false);
  const { data: createCardInfoData } = useQuery(GET_CREATE_CARD_INFO);
  const {
    pMode,
    isEditCard,
    selectedFileId,
    selectedFilePackageId
  } = createCardInfoData;
  const { form } = props;

  const { getFieldDecorator, setFieldsValue } = form;

  const [createTextCard] = useCreateCardMutation();
  const [modifyTextCard] = useModifyCardMutation();
  const [deleteTextCard] = useDeleteCardMutation();

  const routes = () => {
    return [
      {
        path: '//edu',
        breadcrumbName: '全部文件'
      },
      {
        path: '',
        breadcrumbName: pMode ? '我创建的' : '我协作的'
      },
      {
        path: '',
        breadcrumbName: isEditCard ? '编辑文本卡片' : '新建文本卡片'
      }
    ];
  };

  const formLayout = {
    wrapperCol: { span: 21, offset: 0 },
    labelCol: { span: 2 }
  };

  const handleSave = () => {
    // const { dispatch, form, selectedFileId, editKey, pMode } = this.props;
    form.validateFieldsAndScroll((errors: any, values: any) => {
      if (errors) {
        return;
      }

      const mdContent = `---\ntitle:${values.courseTitle}\nsign:default\n---\n# front\n${values.frontContent.content.markdown}\n# back\n${values.backContent.content.markdown}`;
      if (isEditCard) {
        if (props.cardInfo) {
          let id = Base64.decode(props.cardInfo.node.id).split('Card:')[1];
          modifyTextCard({
            variables: { input: { content: mdContent, id: id } }
          }).then((res: any) => {
            if (res.data.updateCardContent.errors) {
              message.error('修改失败!');
              return;
            } else {
              message.success('修改成功!');
              props.props.history.goBack();
            }
          });
        } else {
          message.error('修改失败!');
        }
      } else {
        if (selectedFilePackageId === '' || selectedFileId === '') {
          message.error('请选择要创建卡片的文件夹!');
          return;
        }
        let packageId = selectedFilePackageId;
        packageId = Base64.decode(packageId).split('Package:')[1];
        let pid =
          selectedFileId === selectedFilePackageId
            ? null
            : Base64.decode(selectedFileId).split('Card:')[1];
        let input = pid
          ? {
              content: mdContent,
              fileType: FileType.File,
              name: values.courseTitle.trim() + '.md',
              pid: pid,
              packageId: packageId
            }
          : {
              content: mdContent,
              fileType: FileType.File,
              name: values.courseTitle.trim() + '.md',
              packageId: packageId
            };

        createTextCard({ variables: { input: input } }).then((res: any) => {
          if (res.data.createCard.errors) {
            message.error('创建失败!');
            return;
          }
          message.success('创建成功!');
          props.props.history.goBack();
        });
      }
    });
  };

  let title: string = '';
  let front: string = '';
  let back: string = '';
  if (props.cardInfo && isEditCard) {
    let cardInfo = props.cardInfo;
    let content = getTextCardContent(cardInfo!.node!.sourceContent);
    title = content.title;
    front = content.front;
    back = content.back;
  } else {
    title = '';
    front = '';
    back = '';
  }

  return (
    <Layout className='edu-CreateCard-TextCard-3375'>
      <div className='edu-CreateCard-TextCard-3389'>
        <div className='edu-CreateCard-TextCard-3461'>
          <Spin size='large' spinning={isLoading}>
            <div className='edu-CreateCard-TextCard-3567'>
              <div className='edu-CreateCard-TextCard-3670'>
                <div className='edu-CreateCard-TextCard-3720'>
                  <div
                    onClick={() => {
                      props.props.history.goBack();
                    }}
                    className='edu-CreateCard-TextCard-3928'
                  >
                    <img src={backImg} />
                    <span className='edu-CreateCard-TextCard-4043'>返回</span>
                  </div>
                  <div className='edu-CreateCard-TextCard-4126' />
                </div>
                <Breadcrumb
                  separator={'›'}
                  itemRender={ItemRender}
                  routes={routes()}
                  className='edu-CreateCard-TextCard-4320'
                />
              </div>
              <div className='edu-CreateCard-TextCard-4391'>
                {isEditCard ? (
                  <img
                    src={del}
                    className='edu-CreateCard-TextCard-4527'
                    onClick={() => {
                      Modal.confirm({
                        title: '操作提醒',
                        content: '确定要删除文件吗?',
                        okText: '确定',
                        cancelText: '取消',
                        onOk: () => {
                          let id = Base64.decode(props.cardInfo.node.id).split(
                            'Card:'
                          )[1];
                          let packageId = Base64.decode(
                            selectedFilePackageId
                          ).split('Package:')[1];
                          client.writeData({ data: { modal3Loading: true } });
                          deleteTextCard({
                            variables: {
                              input: { ids: [id], packageId: packageId }
                            }
                          }).then((res: any) => {
                            client.writeData({
                              data: { modal3Loading: false }
                            });
                            if (res.data.deleteCards.errors) {
                              if (
                                res.data.deleteCards.errors[0].message.indexOf(
                                  'can not delete or move these cards:'
                                ) !== -1
                              ) {
                                message.error('不能删除其他分支共有文件!');
                                return;
                              }
                              message.error('删除失败!');
                              return;
                            }
                            message.success('删除成功!');
                            props.props.history.goBack();
                          });
                        }
                      });
                    }}
                  />
                ) : null}
                <RedButton width={'100px'} height={'40px'} onClick={handleSave}>
                  保存卡片
                </RedButton>
              </div>
            </div>
            <div className='edu-CreateCard-TextCard-5468'>卡片内容</div>

            <div className='edu-CreateCard-TextCard-5558' />
            <Layout.Content className='edu-CreateCard-TextCard-5654'>
              <CustomForm>
                <Form.Item
                  colon={false}
                  {...formLayout}
                  label='标题'
                  hasFeedback
                >
                  {getFieldDecorator('courseTitle', {
                    initialValue: title,
                    rules: [{ required: true, message: '请输入卡片名称' }]
                  })(
                    <Input
                      className='edu-CreateCard-TextCard-5941'
                      maxLength={36}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  required
                  colon={false}
                  {...formLayout}
                  label='正面'
                  className='edu-CreateCard-TextCard-6118'
                >
                  {getFieldDecorator('frontContent', {
                    validateTrigger: ['onBlur'],
                    initialValue: {
                      content: {
                        markdown: front
                      }
                    },
                    rules: [
                      {
                        validator: checkMDContent,
                        message: '请输入卡片正面内容'
                      }
                    ]
                  })(
                    <MDEditor_v2
                      isBottom={false}
                      butTitle={''}
                      loading={false}
                      onSubmit={() => {}}
                      height={300}
                      marLeft={10}
                      width={850}
                      minHeight={'248px'}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  colon={false}
                  {...formLayout}
                  label='背面'
                  className='edu-CreateCard-TextCard-6652'
                >
                  {getFieldDecorator('backContent', {
                    validateTrigger: ['onBlur'],
                    initialValue: { content: { markdown: back } },
                    rules: []
                  })(
                    <MDEditor_v2
                      isBottom={false}
                      butTitle={''}
                      loading={false}
                      onSubmit={() => {}}
                      height={300}
                      marLeft={10}
                      width={850}
                      minHeight={'248px'}
                    />
                  )}
                </Form.Item>
              </CustomForm>
            </Layout.Content>
          </Spin>
        </div>
      </div>
    </Layout>
  );
};

const CourseEditPageWrapper = Form.create<Props>()(CreateTextCard);

export default CourseEditPageWrapper;
