/**
 * @description 课程内容
 * @author ali
 * @since 2019
 */

import React, { useEffect, useState } from 'react';
import './style.less';

// 第三方组件
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Spin, Modal, message } from 'antd';
import {
  useGetCourseDirectoryQuery,
  // useGetCourseDirectoryRecordCardQuery
  // useGetCourseDirectoryRecordCardQuery
  useGetNextFolderDataQuery,
  useGetSingleCardContentQuery,
  useGetWholeDirectoryQuery,
  useGetUnlockSingleCardMutation
} from '../../../generated/graphql';

// 自定义组件
import Directory from './Directory';
import CardDisplay from './CardDisplay';
import Navigation from './Navigation';

// 迭代查询目录数据
// import inserData from '../../../../utils/recursionSearchDir';
import { updateIsLearnedState } from '../../../utils/updatCardState';
import inserData from '../../../utils/recursionSearchDir';
import { handleCardContent } from '../../../utils/handleCardContent';
import menu1 from '../../../assets/icons/menu.png';
import cross from '../../../assets/icons/cross.png';
// 取缓存目录、记录id的数据
const GET_LEARING_CENTER = gql`
  {
    directoryList @client
    currentCardId @client
    currentParentId @client
    courseId @client
    coursePackageId @client(always: true)
    isLearnCardCount @client
    allCardCount @client
    copyDirectoryList @client
    cardTitle @client
    currentParentIds @client
  }
`;

const COURSEPACKAGE_ID = gql`
  {
    coursePackageId @client(always: true)
  }
`;
const GET_CONTENT = gql`
  {
    content @client
    chapterIsFinish @client
    cardTitle @client
    cardLearnPeople @client
  }
`;
const FULL_SCREEN = gql`
  {
    fullScreen @client
  }
`;

const GET_INFO = gql`
  {
    directoryList @client(always: true)
    copyDirectoryList @client
  }
`;
const CourseContent = props => {
  // 取数据 => 整理数据 => 输出数据

  const [update, setUpdate] = useState(0);
  const [seen, setSeen] = useState(false);
  // const [coursePackageId, setCoursePackageId] = useState('');
  const client = useApolloClient();
  const { data: dir } = useQuery(GET_LEARING_CENTER);
  const {
    data: { fullScreen }
  } = useQuery(FULL_SCREEN);
  const {
    data: { coursePackageId }
  } = useQuery(COURSEPACKAGE_ID);
  const {
    directoryList,
    currentCardId,
    currentParentId,
    currentParentIds,
    courseId,
    cardTitle
  } = dir;

  const { refetch: getNextFolderData } = useGetNextFolderDataQuery({
    skip: true
  });
  const { refetch: getCardContent } = useGetSingleCardContentQuery({
    skip: true
  });
  const { refetch: updateContent } = useGetSingleCardContentQuery({
    skip: true
  });
  const [unlockCard] = useGetUnlockSingleCardMutation({
    skip: true
  });

  useEffect(() => {
    let courseElement = document.getElementById('content');

    if (courseElement) {
      courseElement.oncontextmenu = new Function('event.returnValue=false');
    }
    if (courseElement) {
      courseElement.onselectstart = new Function('event.returnValue=false');
    }
  }, [coursePackageId]);
  if (coursePackageId.length <= 0) {
    return null;
  }

  return (
    <>
      {/* 是否是全屏模式 */}
      {fullScreen ? (
        <div className='course-content-modal'>
          <div style={{ backgroundColor: 'rgba(64, 64, 64)' }}>
            {seen && (
              <Directory setSeen={setSeen} coursePackageId={coursePackageId} />
            )}

            {fullScreen && (
              <div className='routes-course-CardModal-14474'>
                <div>
                  <img
                    src={menu1}
                    alt='列表图标'
                    onClick={() => {
                      setSeen(!seen);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                  <span className='routes-course-CardModal-14766'>
                    {cardTitle.substring(0, cardTitle.lastIndexOf('.'))}
                  </span>
                </div>
                <div
                  onClick={() => {
                    client.writeData({ data: { fullScreen: false } });
                  }}
                  className='exit-fullscreen'
                >
                  <img src={cross} alt='取消按钮' />
                </div>
              </div>
            )}
            {/* 卡片展示  */}
            <div className='card-modal'>
              <CardDisplay />
            </div>
          </div>
        </div>
      ) : (
        <div className='course-content' id='content'>
          <Directory coursePackageId={coursePackageId} />

          {/* 卡片展示  */}
          <div className='card-modal'>
            <CardDisplay />
          </div>
        </div>
      )}
    </>
  );
};

export default CourseContent;
