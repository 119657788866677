import styled from 'styled-components';

const Course: any = styled.div`
  width: 1000px;
  height: 230px;
  display: flex;
  align-items: center;
`;

Course.CoverView = styled.div`
  width: 310px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 4px;
`;

Course.Cover = styled.img`
height: 190px;

`;

Course.InfoView = styled.div`
width: 496px;
height: 190px;
display: flex;
flex-direction: column;
margin-left: 20px;
`;
Course.Title = styled.div`
  font-size: 18px;
  height: 28px;
  width: 496px;
  color: #282A2D;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

Course.Intro = styled.div`
width: 496px;
height: 80px;
margin-top: 25px;
font-size: 14px;
color: #93979F;
line-height: 20px;
`;
Course.Infos = styled.div`
  margin-top: 40px;
  display: flex;
`;
Course.Info = styled.div`
  min-width: 80px;
  height: 20px;
  color: #93979F;
  font-size: 14px;
  line-height: 20px;
`;
Course.Manager = styled.div`
align-self: flex-start;
  margin-left: 61px;
  margin-top: 76px;
`;

export default Course;