import * as React from 'react';
import './styles.css';

//引入的库组件
import { Modal, Form, Row, message, Col, Button, Input } from 'antd';
import { withRouter } from 'react-router';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import copy from 'copy-to-clipboard';

//引入的其他页面组件

//graphql查询

//引入的资源图片

const GET_URL_MODAL_INFO = gql`
  {
    copyUrlModal @client
    copyUrl @client
  }
`;

const UrlModal = (props: any) => {
  const { form } = props;
  const { getFieldDecorator } = form;
  const client = useApolloClient();
  const { data: copyUrlModalInfo } = useQuery(GET_URL_MODAL_INFO);

  const { copyUrlModal, copyUrl } = copyUrlModalInfo;

  const onCancel = () => {
    client.writeData({data: {copyUrlModal: false}});
  };

  return (
    <Modal
      maskClosable={false}
      title='复制链接地址'
      visible={copyUrlModal}
      onCancel={onCancel}
      width={380}
      footer={null}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 210
      }}
    >
      <Input className='edu-urlModal-1227' value={copyUrl} />
      <Button
        onClick={() => {
          copy(copyUrl);
          message.success('复制成功，如果失败，请在输入框内手动复制.');
        }}
        className='edu-urlModal-1443'
      >
        复制链接地址
      </Button>
    </Modal>
  );
};

const UrlModalWrapper = Form.create()(withRouter(UrlModal));

export default UrlModalWrapper;
