import React, { Component } from 'react';
import {Button} from 'antd';
import ReactMde from './react-mde/lib/js';
import * as Showdown from 'showdown';
import './react-mde/lib/styles/css/react-mde-all.css';
import './react-mde/lib/styles/css/react-mde-editor.css';
import './react-mde/lib/styles/css/react-mde.css';
import styled from 'styled-components';
import './styles.css';


const CustomReactMde = styled(ReactMde)`
    .public-DraftEditor-content{
        min-height: ${props => props.minHeight}!important;
        outline: #177E89!important;
        margin: 0;
    }

    div.notranslate.public-DraftEditor-content{
      padding-left: 10px;
      padding-top: 5px;
    }


    span{
       line-height: 20px;
    }

    div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr{
      margin: 0;
    }

    div.DraftEditor-editorContainer {
      line-height: 20px;
    }

     li>button{
    height: 40px!important;
  }

  .react-mde {
    border-radius: 0px !important;
  }

`;


class MDEditor extends Component {

  constructor(props) {
    super(props);
    const value = props.value || {};
    this.state = ({
      content: value.content || { content: { markdown: '' } },
    });
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
      simpleLineBreaks: true,
    });
  }

  componentWillReceiveProps(nextProps) {

    // Should be a controlled component.
    if ('value' in nextProps) {
      const value = nextProps.value;
      this.setState(value);
    }
  }

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(Object.assign({}, this.state, changedValue));
    }
  }

  handleContentChange = (content) => {
    if (!('value' in this.props)) {
      this.setState({ content });
    }
    this.triggerChange({ content });
  }


  render() {
    const { width, butTitle, onSubmit, isBottom, height, marLeft, pb, minHeight } = this.props;
    const { content } = this.state;
    return (
      <div className="components-MDEditor" style={{ height, width, position: 'relative', borderTop: '0', marginLeft: marLeft, paddingBottom: pb }}>
        <CustomReactMde
          minHeight={minHeight}
          generateMarkdownPreview={markdown => Promise.resolve(this.converter.makeHtml(markdown))}
          editorState={content}
          onChange={(mdeState) => {
            this.handleContentChange(mdeState);
          }}
          layout={'tabbed'}
        />
        {isBottom ? (
          <div className="components-MDEditor-2603">
            <Button
              onClick={onSubmit}
              className="components-MDEditor-2938"
            >{butTitle}</Button>
          </div>
          ) : (null)}

      </div>
    );
  }
}

export default MDEditor;
