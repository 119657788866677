// import React from 'react';
// import './style.less';
// import { Input } from 'antd';
// import SearchAndStart from './SearchAndStart';
// import ResultFilterDisplay from './ResultFilterDisplay';
// import ThemesDisplay from './ThemesDisplay';
// import PostOneDiscussion from './PostOneDiscussion';
// const CourseDiscussion = () => {
//   return (
//     <div className='discussion'>
//       <SearchAndStart />
//       <ResultFilterDisplay result={70} />
//       <ThemesDisplay />
//       {/* <PostOneDiscussion /> */}
//     </div>
//   );
// };

// export default CourseDiscussion;

import * as React from 'react';
import './styles.css';

//引入的库组件
import { Layout } from 'antd';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import DiscussListPage from './DiscussListPage';
import DiscussEditPage from './DiscussEditPage';
import DiscussCheckPage from './DiscussCheckPage';

//graphql查询

//引入的资源图片

const GET_DISCUSS_PAGE_INFO = gql`
  {
    isShowDiscList @client
    isCheckDisc @client
    discussCoursePackageId @client
    discussDetailId @client
  }
`;

const DiscussionContainer = () => {
  const client = useApolloClient();
  const { data: discussPageInfo } = useQuery(GET_DISCUSS_PAGE_INFO);
  const {
    isShowDiscList,
    isCheckDisc,
    discussCoursePackageId,
    discussDetailId
  } = discussPageInfo;

  React.useEffect(() => {
    let path = window.location.pathname;
    let idStr =
      window.location.pathname.indexOf('discussion/') !== -1
        ? window.location.pathname.split('discussion/')[1]
        : '';
    let id = idStr !== '' ? Base64.encode('Discussion:' + idStr) : '';
    if (id !== '') {
      client.writeData({
        data: { discussDetailId: id, isShowDiscList: false, isCheckDisc: true }
      });
    }
  }, []);

  if (isShowDiscList) {
    if (discussDetailId !== '') {
      setTimeout(() => {
        let element = document.getElementById(discussDetailId);
        if (element) {
          element.scrollIntoView();
        }
      }, 100);
      client.writeData({
        data: { discussDetailId: '' }
      });
    }
  }

  return (
    <Layout.Content style={{ overflow: 'auto' }}>
      <div
        className={
          isShowDiscList ? 'discussion-list-show' : 'discussion-list-hide'
        }
      >
        <DiscussListPage />
      </div>

      {!isShowDiscList && isCheckDisc ? (
        <DiscussCheckPage />
      ) : (
        <DiscussEditPage />
      )}
    </Layout.Content>
  );
};

export default DiscussionContainer;
