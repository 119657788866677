import React, { Component } from 'react';
import './styles.css';

//引入的库组件
import { Modal } from 'antd';
import ContextMenu from 'react-contextmenu/es6/ContextMenu';
import MenuItem from 'react-contextmenu/es6/MenuItem';
import styled from 'styled-components';

//引入的其他页面组件
import RenameModalCallback from '../RenameModal/RenameModalCallbackFunc';

//graphql查询

//引入的资源图片

const MenuItemDivier = styled.div`
  width: 100%;
  height: 2px;
  border: 1px solid rgba(72, 72, 72, 0.25);
`;

class RightClickPackage extends Component {
  render() {
    const { deletePackage, renamePackage } = this.props.pageFunction;
    const client = this.props.client;

    return (
      <ContextMenu id='package' className='edu-PackageManager-42150'>
        <MenuItem
          onClick={(e, data) => {
            RenameModalCallback.callback = newName => {
              renamePackage(data.key, newName);
            };
            client.writeData({
              data: { isShowRenameModal: true, renameName: data.title }
            });
            // dispatch({
            //   type: 'course/handlerRenameModal',
            //   isShowRenameModal: true
            // });
            // dispatch({
            //   type: 'course/handlerRenameInfo',
            //   renameKey: data.key,
            //   renameName: data.title,
            //   renameType: -1
            // });
          }}
        >
          重命名
        </MenuItem>
        <MenuItem
          onClick={(e, data) => {
            Modal.confirm({
              title: '操作提醒',
              content: '确定要删除卡包吗?',
              okText: '确定',
              cancelText: '取消',
              onOk: () => {
                deletePackage(data.key);
              }
            });
          }}
        >
          删除
        </MenuItem>
      </ContextMenu>
    );
  }
}

export default RightClickPackage;
