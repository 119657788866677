import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Layout, Spin, Breadcrumb, Modal, Input, message } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Base64 } from 'js-base64';
import { useApolloClient } from 'react-apollo';
import json2md from 'json2md';

//引入的其他页面组件
import ItemRender from '../CommonComponents/ItemRender';
import MDEditor_v2 from '../CommonComponents/MDEditor/MDEditor_v2';
import { RedButton } from '../CommonComponents/CustomButton';
import { checkMDContent } from '../../utils/checkForm';
import { getVideoCardContent } from '../../utils';

//graphql查询
import {
  useCreateCardMutation,
  useModifyCardMutation,
  FileType,
  GetCardInfoQuery,
  useDeleteCardMutation
} from '../../generated/graphql';

//引入的资源图片
import backImg from '../../assets/icons/back.png';
import del from '../../assets/icons/delete.png';

interface Props extends FormComponentProps {
  props: any;
  cardInfo: GetCardInfoQuery | null;
}

const CustomForm = styled(Form)`
  .ant-form-explain {
    margin-left: 10px;
  }
`;

const GET_CREATE_CARD_INFO = gql`
  {
    pMode @client
    isEditCard @client(always: true)
    selectedFileId @client
    selectedFilePackageId @client
  }
`;

const CreateAudioCard = (props: any) => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = React.useState(false);
  const { data: createCardInfoData } = useQuery(GET_CREATE_CARD_INFO);
  const {
    pMode,
    isEditCard,
    selectedFileId,
    selectedFilePackageId
  } = createCardInfoData;
  const { form } = props;

  const { getFieldDecorator, setFieldsValue } = form;

  const [createAudioCard] = useCreateCardMutation();
  const [modifyAudioCard] = useModifyCardMutation();
  const [deleteAudioCard] = useDeleteCardMutation();

  const routes = () => {
    return [
      {
        path: '//edu',
        breadcrumbName: '全部文件'
      },
      {
        path: '',
        breadcrumbName: pMode ? '我创建的' : '我协作的'
      },
      {
        path: '',
        breadcrumbName: isEditCard ? '编辑音频卡片' : '新建音频卡片'
      }
    ];
  };

  const formLayout = {
    wrapperCol: { span: 21, offset: 0 },
    labelCol: { span: 2 }
  };

  const handleSave = () => {
    form.validateFieldsAndScroll((errors: any, values: any) => {
      const mdContent = `---\r\ntitle:${
        values.title
      }\nsign:default\n---\n${json2md([{ h1: 'front' }])}\`\`\`audio\nname:${
        values.title
      }\nurl:${values.url}\n\`\`\`\n# back\n${
        values.backContent.content.markdown
      }`;
      if (errors) {
        return;
      }
      if (isEditCard) {
        if (props.cardInfo) {
          let id = Base64.decode(props.cardInfo.node.id).split('Card:')[1];
          modifyAudioCard({
            variables: { input: { content: mdContent, id: id } }
          }).then((res: any) => {
            if (res.data.updateCardContent.errors) {
              message.error('修改失败!');
              return;
            } else {
              message.success('修改成功!');
              props.props.history.goBack();
            }
          });
        } else {
          message.error('修改失败!');
        }
      } else {
        if (selectedFilePackageId === '' || selectedFileId === '') {
          message.error('请选择要创建卡片的文件夹!');
          return;
        }
        let packageId = selectedFilePackageId;
        packageId = Base64.decode(packageId).split('Package:')[1];
        let pid =
          selectedFileId === selectedFilePackageId
            ? null
            : Base64.decode(selectedFileId).split('Card:')[1];
        let input = pid
          ? {
              content: mdContent,
              fileType: FileType.File,
              name: values.title.trim() + '.audio',
              pid: pid,
              packageId: packageId
            }
          : {
              content: mdContent,
              fileType: FileType.File,
              name: values.title.trim() + '.audio',
              packageId: packageId
            };

        createAudioCard({ variables: { input: input } }).then((res: any) => {
          if (res.data.createCard.errors) {
            message.error('创建失败!');
            return;
          }
          message.success('创建成功!');
          props.props.history.goBack();
        });
      }
    });
  };

  let title: string = '';
  let url: string = '';
  let back: string = '';
  let encryptedUrl: string = ''; //加密音频链接待实现
  if (props.cardInfo && isEditCard) {
    let cardInfo = props.cardInfo;
    let content = getVideoCardContent(cardInfo!.node!.sourceContent); //目前结构一样
    title = content.title;
    url = content.front;
    back = content.back;
  } else {
    title = '';
    url = '';
    back = '';
  }

  if (encryptedUrl !== '') {
    let cardUrlArray = url.split('://');
    let encryptedUrlArray = encryptedUrl.split('://');
    if (url !== '' && encryptedUrl !== '') {
      if (
        cardUrlArray[cardUrlArray.length > 0 ? cardUrlArray.length - 1 : 0] ===
        encryptedUrlArray[
          encryptedUrlArray.length > 0 ? encryptedUrlArray.length - 1 : 0
        ]
      ) {
        encryptedUrl = '';
      }
    }
  }

  return (
    <Layout className='edu-CreateCard-AudioCard-3432'>
      <Spin size='large' spinning={isLoading}>
        <div className='edu-CreateCard-AudioCard-3520'>
          <div className='edu-CreateCard-AudioCard-3594'>
            <div className='edu-CreateCard-AudioCard-3695'>
              <div
                onClick={() => {
                  props.props.history.goBack();
                }}
                className='edu-CreateCard-AudioCard-3845'
              >
                <img src={backImg} />
                <span className='edu-CreateCard-AudioCard-3956'>返回</span>
                <div className='edu-CreateCard-AudioCard-4031' />
              </div>
              <Breadcrumb
                separator={'›'}
                itemRender={ItemRender}
                routes={routes()}
                className='edu-CreateCard-AudioCard-4221'
              />
            </div>
            <div className='edu-CreateCard-AudioCard-4287'>
              {isEditCard ? (
                <img
                  src={del}
                  className='edu-CreateCard-AudioCard-4417'
                  onClick={() => {
                    Modal.confirm({
                      title: '操作提醒',
                      content: '确定要删除文件吗?',
                      okText: '确定',
                      cancelText: '取消',
                      onOk: () => {
                        let id = Base64.decode(props.cardInfo.node.id).split(
                          'Card:'
                        )[1];
                        let packageId = Base64.decode(
                          selectedFilePackageId
                        ).split('Package:')[1];
                        client.writeData({ data: { modal3Loading: true } });
                        deleteAudioCard({
                          variables: {
                            input: { ids: [id], packageId: packageId }
                          }
                        }).then((res: any) => {
                          client.writeData({ data: { modal3Loading: false } });
                          if (res.data.deleteCards.errors) {
                            if (
                              res.data.deleteCards.errors[0].message.indexOf(
                                'can not delete or move these cards:'
                              ) !== -1
                            ) {
                              message.error('不能删除其他分支共有文件!');
                              return;
                            }
                            message.error('删除失败!');
                            return;
                          }
                          message.success('删除成功!');
                          props.props.history.goBack();
                        });
                      }
                    });
                  }}
                />
              ) : null}
              <RedButton width={'100px'} height={'40px'} onClick={handleSave}>
                保存卡片
              </RedButton>
            </div>
          </div>
          <div className='edu-CreateCard-AudioCard-5320'>卡片内容</div>
          <div className='edu-CreateCard-AudioCard-5408' />
          <Layout.Content className='edu-CreateCard-AudioCard-5502'>
            <CustomForm>
              <Form.Item colon={false} {...formLayout} label='标题' hasFeedback>
                {getFieldDecorator('title', {
                  initialValue: title,
                  rules: [{ required: true, message: '请输入卡片名称' }]
                })(
                  <Input
                    maxLength={36}
                    className='edu-CreateCard-AudioCard-5752'
                  />
                )}
              </Form.Item>
              <Form.Item
                colon={false}
                {...formLayout}
                label='音频链接'
                hasFeedback
                className='edu-CreateCard-AudioCard-5915'
              >
                {getFieldDecorator('url', {
                  initialValue: url,
                  rules: [{ required: true, message: '请输入音频链接' }]
                })(<Input className='edu-CreateCard-AudioCard-6044' />)}
              </Form.Item>
              {//encryptedUrl !== ''  ? <div style={{marginTop:20}}>加密视频链接:{encryptedUrl}</div> : null
              encryptedUrl !== '' ? (
                <Form.Item
                  colon={false}
                  {...formLayout}
                  label='加密链接'
                  className='edu-CreateCard-AudioCard-5915'
                >
                  {getFieldDecorator('encryptedUrl', {
                    initialValue: encryptedUrl,
                    rules: [{ required: false }]
                  })(
                    <Input disabled className='edu-CreateCard-AudioCard-6044' />
                  )}
                </Form.Item>
              ) : null}
              <Form.Item
                colon={false}
                {...formLayout}
                label='背面'
                className='edu-CreateCard-AudioCard-6193'
              >
                {getFieldDecorator('backContent', {
                  initialValue: { content: { markdown: back } },
                  rules: []
                })(
                  <MDEditor_v2
                    isBottom={false}
                    butTitle={''}
                    loading={false}
                    onSubmit={() => {}}
                    height={300}
                    marLeft={10}
                    width={850}
                  />
                )}
              </Form.Item>
            </CustomForm>
          </Layout.Content>
        </div>
      </Spin>
    </Layout>
  );
};

const CourseEditPageWrapper = Form.create<Props>()(CreateAudioCard);

export default CourseEditPageWrapper;
