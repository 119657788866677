import * as React from 'react';
import './styles.css';

//引入的库组件
import { Checkbox, Dropdown, Icon, Menu, Input } from 'antd';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

//引入的其他页面组件
import SearchAndInitiate from './SearchAndInitiate';

//graphql查询
import { useGetDiscussLabelsQuery, DiscussionOrder } from '../../../../generated/graphql';

//引入的资源图片

const GET_DISCUSS_TOOL_BAR_INFO = gql`
  {
    aboutMine @client
    discussStatus @client
    discussTag @client {
      tag
      label
    }
    discussSort @client {
      type
      label
    }
    tagsInDiscuss @client
    filterSelectedTags @client(always: true)
    discKey @client
    discussCoursePackageId @client
    discussPageContent @client
    discussCountPerPage @client
  }
`;

const DiscussToolbar = (props: any) => {
  const client = useApolloClient();
  const { data: discussToobarInfo } = useQuery(GET_DISCUSS_TOOL_BAR_INFO);
  const {
    discussStatus,
    aboutMine,
    discussTag,
    tagsInDiscuss,
    filterSelectedTags,
    discussSort,
    discKey,
    discussCoursePackageId,
    discussPageContent,
    discussCountPerPage
  } = discussToobarInfo;

  const { refetch: getLabels } = useGetDiscussLabelsQuery({ skip: true });

  const [tagDropdownVisible, setTagDropdownVisible] = React.useState(false);
  const [tagContent, setTagContent] = React.useState('');

  const fliterPress = (filter: any) => {
    if (discussStatus === filter) {
      return;
    }
    client.writeData({ data: { discussStatus: filter, discPage: 1 } });

    let filterNew = filter === "2" ? "0" : filter;
    let input = {
      coursePackageId: discussCoursePackageId,
      filter: {
        name: discussPageContent === '' ? undefined : discussPageContent,
        iParticipated: aboutMine ? aboutMine : undefined,
        labels:
          filterSelectedTags.length > 0
            ? filterSelectedTags.map((item: any) => {
                return item.name;
              })
            : undefined,
        status: filterNew === '0'
      },
      last: undefined,
      before: undefined,
      first: discussCountPerPage,
      after: undefined,
      order: discussSort.type
    };

    props.getListData(input, 1);
  };

  const handleTagVisibleChange = (flag: boolean) => {
    setTagDropdownVisible(flag);
  };

  const makeNewTags = (fliter: any) => {
    let newTags: any = [];
    tagsInDiscuss.map((item: any, index: any) => {
      if (fliter === '') {
        newTags[newTags.length] = item;
        newTags[newTags.length] = '';
      } else {
        if (item.name.indexOf(fliter) != -1) {
          newTags[newTags.length] = item;
          newTags[newTags.length] = '';
        }
      }
    });
    return newTags;
  };

  const tagMenu_new = (tags: any) => (
    <Menu>
      <div
        className='new-routes-study-courseDiscussion-1403'
        onClick={() => {
          setTagDropdownVisible(true);
        }}
      >
        <Input
          maxLength={36}
          className='new-routes-study-courseDiscussion-1416'
          placeholder='过滤标签'
          onChange={value => {
            setTagContent(value.target.value);
          }}
        />
      </div>

      {tags.length > 0 ? <Menu.Divider /> : null}
      {tags.map((item: any, index: any) => {
        if (item !== '') {
          let isInFilterSelectedTag = filterSelectedTags.indexOf(item) !== -1;
          return (
            <Menu.Item
              key={`${index}`}
              onClick={() => {
                if (!isInFilterSelectedTag) {
                  filterSelectedTags.push(item);
                  client.writeQuery({
                    query: GET_DISCUSS_TOOL_BAR_INFO,
                    data: {
                      filterSelectedTags: filterSelectedTags.map(
                        (item: any) => {
                          return item;
                        }
                      )
                    }
                  });
                } else {
                  let index = filterSelectedTags.indexOf(item);
                  if (index > -1) {
                    filterSelectedTags.splice(index, 1);
                  }
                  client.writeQuery({
                    query: GET_DISCUSS_TOOL_BAR_INFO,
                    data: {
                      filterSelectedTags: filterSelectedTags.map((item: any) => {
                        return item;
                      })
                    }
                  });

                }

                let input = {
                  coursePackageId: discussCoursePackageId,
                  filter: {
                    name: discussPageContent === '' ? undefined : discussPageContent,
                    iParticipated: aboutMine ? aboutMine : undefined,
                    labels:
                      filterSelectedTags.length > 0
                        ? filterSelectedTags.map((item: any) => {
                            return item.name;
                          })
                        : undefined,
                    status: discussStatus === '0'
                  },
                  last: undefined,
                  before: undefined,
                  first: discussCountPerPage,
                  after: undefined,
                  order: discussSort.type
                };
            
                props.getListData(input, 1);
              }}
            >
              <div className='new-routes-study-courseDiscussion-1425'>
                <div className='new-routes-study-courseDiscussion-1430'>
                  <div
                    className='new-routes-study-courseDiscussion-1439'
                    style={{ backgroundColor: item.color }}
                  />
                  <div className='new-routes-study-courseDiscussion-1444'>
                    {item.name}
                  </div>
                </div>

                {isInFilterSelectedTag ? (
                  <Icon
                    type='check-circle'
                    theme='filled'
                    style={{ fontSize: '15px', color: '#f63448' }}
                  />
                ) : null}
              </div>
            </Menu.Item>
          );
        } else {
          if (index !== tags.length - 1) {
            return <Menu.Divider key={index + '-divider'} />;
          }
          return null;
        }
      })}
    </Menu>
  );

  const sortMenu_new = (
    <Menu
      onClick={item => {
        let type : DiscussionOrder = DiscussionOrder.DescInsertedAt ;
        let label = '按创建时间排序';
        switch (item.key) {
          case '0':
            type = DiscussionOrder.DescInsertedAt;
            label = '按创建时间排序';
            break;
          case '1':
              type = DiscussionOrder.DescCommentsAt;
              label = '按最近回复排序';
            break;
          case '2':
              type = DiscussionOrder.DescCommentsCount;
              label = '按评论数量排序';
            break;
          default:
        }

        client.writeData({
          data: {
            discussSort: {
              type: type,
              label: label,
              __typename: 'DiscussSort'
            },
            discPage: 1
          }
        });

        let input = {
          coursePackageId: discussCoursePackageId,
          filter: {
            name: discussPageContent === '' ? undefined : discussPageContent,
            iParticipated: aboutMine ? aboutMine : undefined,
            labels:
              filterSelectedTags.length > 0
                ? filterSelectedTags.map((item: any) => {
                    return item.name;
                  })
                : undefined,
            status: discussStatus === '0'
          },
          last: undefined,
          before: undefined,
          first: discussCountPerPage,
          after: undefined,
          order: type
        };
    
        props.getListData(input, 1);

      }}
    >
      <Menu.Item key='0'>按创建时间排序</Menu.Item>
      <Menu.Divider />
      <Menu.Item key='1'>按最近回复排序</Menu.Item>
      <Menu.Divider />
      <Menu.Item key='2'>按评论数量排序</Menu.Item>
    </Menu>
  );

  const renderFliters = () => {
    switch (discussStatus) {
      case '0':
        return (
          <div className='new-routes-study-courseDiscussion-1324'>
            <div
              className='new-routes-study-courseDiscussion-1330-activate'
              onClick={() => {
                fliterPress('0');
              }}
            >
              <div className='new-routes-study-courseDiscussion-1335-activate'>
                讨论中
              </div>
            </div>
            <div
              className='new-routes-study-courseDiscussion-1330'
              onClick={() => {
                fliterPress('1');
              }}
            >
              <div className='new-routes-study-courseDiscussion-1335'>
                已关闭
              </div>
            </div>
          </div>
        );
      case '1':
        return (
          <div className='new-routes-study-courseDiscussion-1324'>
            <div
              className='new-routes-study-courseDiscussion-1330'
              onClick={() => {
                fliterPress('0');
              }}
            >
              <div className='new-routes-study-courseDiscussion-1335'>
                讨论中
              </div>
            </div>
            <div
              className='new-routes-study-courseDiscussion-1330-activate'
              onClick={() => {
                fliterPress('1');
              }}
            >
              <div className='new-routes-study-courseDiscussion-1335-activate'>
                已关闭
              </div>
            </div>
          </div>
        );
      case '2':
        return (
          <div className='new-routes-study-courseDiscussion-1324'>
            <div
              className='new-routes-study-courseDiscussion-1330'
              onClick={() => {
                fliterPress('0');
              }}
            >
              <div className='new-routes-study-courseDiscussion-1335'>
                讨论中
              </div>
            </div>
            <div
              className='new-routes-study-courseDiscussion-1330'
              onClick={() => {
                fliterPress('1');
              }}
            >
              <div className='new-routes-study-courseDiscussion-1335'>
                已关闭
              </div>
            </div>
          </div>
        );
    }
  };

  React.useEffect(() => {
    getLabels().then((res: any) => {
      let datas = res.data.labels;
      let labels = datas.map((item: any) => {
        return { id: item.id, name: item.name, color: item.info.color };
      });
      client.writeQuery({
        query: GET_DISCUSS_TOOL_BAR_INFO,
        data: { tagsInDiscuss: labels }
      });
    });
  }, []);

  return (
    <div className='new-routes-study-courseDiscussion-1300'>
      {renderFliters()}

      <div className='new-routes-study-courseDiscussion-1350'>
        <Checkbox
          className='new-routes-study-courseDiscussion-1371'
          checked={aboutMine}
          onChange={e => {
            client.writeData({
              data: { aboutMine: e.target.checked, discPage: 1 }
            });

            let input = {
              coursePackageId: discussCoursePackageId,
              filter: {
                name: discussPageContent === '' ? undefined : discussPageContent,
                iParticipated: e.target.checked ? e.target.checked : undefined,
                labels:
                  filterSelectedTags.length > 0
                    ? filterSelectedTags.map((item: any) => {
                        return item.name;
                      })
                    : undefined,
                status: discussStatus === '0'
              },
              last: undefined,
              before: undefined,
              first: discussCountPerPage,
              after: undefined,
              order: discussSort.type
            };
        
            props.getListData(input, 1);
          }}
        >
          我参与的
        </Checkbox>

        <Dropdown
          onVisibleChange={handleTagVisibleChange}
          visible={tagDropdownVisible}
          trigger={['click']}
          overlay={tagMenu_new(makeNewTags(tagContent))}
          className='new-routes-study-courseDiscussion-1389'
        >
          <div
            className='ant-dropdown-link'
            style={{ cursor: 'pointer', color: '#000000' }}
          >
            <Icon
              type='down'
              className='new-routes-study-courseDiscussion-1382'
            />
            {discussTag.label}
          </div>
        </Dropdown>

        <Dropdown
          trigger={['click']}
          overlay={sortMenu_new}
          className='new-routes-study-courseDiscussion-1389  new-routes-study-courseDiscussion-1389-mobile'
        >
          <div
            className='ant-dropdown-link'
            style={{ cursor: 'pointer', color: '#000000' }}
          >
            <Icon
              type='down'
              className='new-routes-study-courseDiscussion-1382'
            />
            {discussSort.label}
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default DiscussToolbar;
