// 正则表达式

// 密码 6 - 15 位
export const validatePassword = (str: string) =>
  /^[a-zA-Z0-9]{6,15}$/.test(str);

// url
export const validateUrl = (str: string) =>
  /(http[s]?|ftp):\/\/[^\/\.]+?\..+\w$/.test(str);

// 邮箱
export const validateEmail = (str: string) =>
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(str);
