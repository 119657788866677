import * as React from 'react';
import './styles.css';

//引入的库组件
import { Layout, Spin, List, message } from 'antd';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import LoadingView from '../../../CommonComponents/LoadingView';
import EmptyLayout from '../../../CommonComponents/EmptyLayout';
import Disc from './Disc';
import gaEvents from '../../../../utils/gaEvents.js';

//graphql查询


//引入的资源图片
import loading from '../../../../assets/icons/loading.png';
import empty1s from '../../../../assets/omcbg/empty1s.png';
import chat from '../../../../assets/v2.1/chat.png';

const GET_DISCUSS_LIST_INFO = gql`
  {
    discLoading @client {
      loading
      tip
    }
    discData @client
    disc @client {
      page
      count
    }
    discPage @client
    discussStatus @client
    aboutMine @client
    discussCoursePackageId @client
    discussCountPerPage @client
    discussPageContent @client
    filterSelectedTags @client
    aboutMine @client
    discussSort @client{
      type
      label
    }
    disscussPageInfo @client
    courseTitle @client
  }
`;

const DiscussList = (props: any) => {
  const client = useApolloClient();
  const { data: discussListInfo } = useQuery(GET_DISCUSS_LIST_INFO);
  const {
    discLoading,
    discData,
    disc,
    discPage,
    discussStatus,
    discussCoursePackageId,
    discussCountPerPage,
    discussPageContent,
    filterSelectedTags,
    aboutMine,
    discussSort,
    disscussPageInfo,
    courseTitle
  } = discussListInfo;

  React.useEffect(() => {
    if(discussCoursePackageId !== "") {
      let input = {
        coursePackageId: discussCoursePackageId,
        filter: {
          name:
            discussPageContent === ''
              ? undefined
              : discussPageContent,
          iParticipated: aboutMine ? aboutMine : undefined,
          labels:
            filterSelectedTags.length > 0
              ? filterSelectedTags.map((item: any) => {
                  return item.name;
                })
              : undefined,
          status: discussStatus === '0'
        },
        last: undefined,
        before:undefined,
        first: discussCountPerPage,
        after: undefined,
        order: discussSort.type,
      };
      props.getListData(input, 1);
    }
  }, [discussCoursePackageId]);

  return (
    <div className='new-routes-study-courseDiscussion-1600'>
      <Spin
        spinning={discLoading.loading}
        tip={discLoading.tip}
        indicator={<LoadingView src={loading} />}
        size={'large'}
      >
        <Layout.Content className='new-routes-study-courseDiscussion-1610'>
          {discData.length === 0 ? (
            <EmptyLayout top={'120px'} bottom={'114px'}>
              <EmptyLayout.Img data-rjs='2' src={empty1s} />
              <EmptyLayout.Text>没有找到讨论</EmptyLayout.Text>
            </EmptyLayout>
          ) : (
            <List
              pagination={
                disc.page < 2
                  ? null
                  : ({
                      pageSize: discussCountPerPage,
                      current: discPage,
                      total: disc.count,
                      onChange: (e: any) => {
                        let pages = e - discPage;
                        let input: any = null;
                        if (pages > 0) {
                          let currentLastDataCursor = disscussPageInfo.endCursor;
                          let cursorName = Base64.decode(
                            currentLastDataCursor
                          ).split(':')[0];
                          let index =
                            parseInt(
                              Base64.decode(currentLastDataCursor).split(':')[1]
                            ) +
                            (pages - 1) * discussCountPerPage;
                          input = {
                            coursePackageId: discussCoursePackageId,
                            filter: {
                              name:
                                discussPageContent === ''
                                  ? undefined
                                  : discussPageContent,
                              iParticipated: aboutMine ? aboutMine : undefined,
                              labels:
                                filterSelectedTags.length > 0
                                  ? filterSelectedTags.map((item: any) => {
                                      return item.id;
                                    })
                                  : undefined,
                              status: discussStatus === '0'
                            },
                            first: discussCountPerPage,
                            after: Base64.encode(cursorName + ':' + index),
                            before: undefined,
                            last: undefined,
                            order: discussSort.type,
                          };
                        } else {
                          let currentLastDataCursor = disscussPageInfo.startCursor;
                          let cursorName = Base64.decode(
                            currentLastDataCursor
                          ).split(':')[0];
                          let index =
                            parseInt(
                              Base64.decode(currentLastDataCursor).split(':')[1]
                            ) +
                            (pages + 1) * discussCountPerPage;

                          input = {
                            coursePackageId: discussCoursePackageId,
                            filter: {
                              name:
                                discussPageContent === ''
                                  ? undefined
                                  : discussPageContent,
                              iParticipated: aboutMine ? aboutMine : undefined,
                              labels:
                                filterSelectedTags.length > 0
                                  ? filterSelectedTags.map((item: any) => {
                                      return item.name;
                                    })
                                  : undefined,
                              status: discussStatus === '0',
                            },
                            last: discussCountPerPage,
                            before: Base64.encode(cursorName + ':' + index),
                            first: undefined,
                            after: undefined,
                            order: discussSort.type,
                          };
                        }
                        props.getListData(input, e);
                      }
                    } as any)
              }
              split
              dataSource={discData}
              renderItem={(item: any) => {
                const tagInfo = item.node.labels
                  ? item.node.labels.map((labelItem: any, index: any) => {
                      return {
                        id: labelItem.id,
                        name: labelItem.name,
                        color: labelItem.info.color
                      };
                    })
                  : [];
                return (
                  <Disc className='new-routes-study-courseDiscussion-disc-wrapper' id={item.node.id}>
                    <Disc.Divider className='new-routes-study-courseDiscussion-divider' />
                    <Disc.Container
                      onClick={() => {
                        gaEvents.gaEventsUpload({
                          eventName : `点击-讨论-${courseTitle}-${item.node.name}`,
                        });
                        client.writeData({data: { discussCommentPage: 1, discussSearchContent: '', discussPageContent: '', isShowDiscList: false, isCheckDisc: true, discussDetailId: item.node.id}});
                      }}
                    >
                      <Disc.BlankContainer />
                      <Disc.TitleContainer>
                        {tagInfo.map((tagItem: any, index: any) => {
                          return (
                            <Disc.TitleTag
                              key={tagItem.id}
                              style={{ backgroundColor: tagItem.color }}
                            >
                              {tagItem.name}
                            </Disc.TitleTag>
                          );
                        })}
                        <Disc.Title>{item.node.name}</Disc.Title>
                      </Disc.TitleContainer>
                      <Disc.InfoContainer>
                        <Disc.InfoUser>
                          <Disc.Avatar
                            className='routes-study-courseDiscussion-disc-avatar'
                            size={'small'}
                            src={item.node.author.avatar}
                          />
                          <Disc.User className='routes-study-courseDiscussion-disc-user'>
                            {item.node.author.nickname} ·{' '}
                            {moment(item.node.insertedAt).isSame(
                              moment().startOf('day'),
                              'd'
                            )
                              ? `今天 ${item.node.insertedAt.split(' ')[1]}`
                              : item.node.insertedAt}
                          </Disc.User>
                        </Disc.InfoUser>
                        <Disc.ReplyCount>
                          <Disc.Comment data-rjs='2' src={chat} />
                          <Disc.ComCount>
                            {item.node.commentsCount}
                          </Disc.ComCount>
                        </Disc.ReplyCount>
                      </Disc.InfoContainer>
                      <Disc.BlankContainer />
                    </Disc.Container>
                  </Disc>
                );
              }}
            />
          )}
        </Layout.Content>
      </Spin>
    </div>
  );
};

export default withRouter(DiscussList);
