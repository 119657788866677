import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';

import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
// import { createUploadLink } from 'apollo-upload-client'
import { createLink } from 'apollo-absinthe-upload-link';
import { IntrospectionFragmentMatcher } from 'apollo-boost';
import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { hasSubscription } from '@jumpn/utils-graphql';
import { split, ApolloLink, concat } from 'apollo-link';
import { Socket as PhoenixSocket } from 'phoenix';
import { onError } from 'apollo-link-error';
import { message } from 'antd';
// import ApolloClient, {
//   InMemoryCache,
//   ApolloLink,
//   HttpLink,
//   IntrospectionFragmentMatcher
// } from 'apollo-boost';

import { ApolloProvider } from 'react-apollo';
import {
  ApolloProvider as ApolloHooksProvider,
  useApolloClient
} from 'react-apollo-hooks';
import gql from 'graphql-tag';

import introspectionQueryResultData from './fragmentTypes.json';

import timeLocalStorage from './utils/TimeLocalStorage';

import manager from './global_variable/manager';
import headers from './global_variable/headers';
import learningCenter from './global_variable/learningCenter';
import collectionPage from './global_variable/collectionPage';
import home from './global_variable/home';
import courses from './global_variable/courses';
import collegeDetail from './global_variable/collegeDetail';
import courseDetail from './global_variable/courseDetail';
import my from './global_variable/my';
import stu from './global_variable/stu';
import common from './global_variable/common';
import discusspage from './global_variable/discusspage';

import { history } from './pages/IndexPage/index';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const checkUserInfo = () => {
  let isTokenInfo = timeLocalStorage.get('TOKENINFO') ? true : false;
  let isUserInfo = timeLocalStorage.get('USERINFO') ? true : false;

  if (isTokenInfo && isUserInfo) {
    return timeLocalStorage.get('USERINFO');
  }

  window.localStorage.setItem('LOGINSTATUS', '1');
  window.localStorage.setItem('USERINFO', '');

  return {
    head_img: '',
    phone: '',
    role: '',
    token: '',
    userid: '',
    username: '',
    userProfile: null,
    __typename: 'UserInfo'
  };
};

//全局变量，使用了Graphql的缓存方案
const cache = new InMemoryCache({ fragmentMatcher });
cache.writeData({
  data: {
    refreshToken: false,
    //主页头 主页脚
    isShowHeader: true,
    isShowFooter: true,

    //一些弹出菜单用的全局变量
    isShowLoginModal: false,
    isShowResetResult: false,
    isShowResetModal: false,
    resetLoading: false,
    isShowRegisterModal: false,
    isShowResetButton: true,
    buttonLoading: false,
    showButton: true,
    userInfo: checkUserInfo(),
    isLoggedIn: window.localStorage.getItem('LOGINSTATUS') === '0',

    badge: { all: 0, apply: 0, com: 0, sys: 0, __typename: 'Badge' },

    allCourseSearchContent: '',
    allCourseCollegeSelect: '全部',
    allCourseCourseSelect: '全部',
    routeFrom: null,

    ...manager,
    ...common,
    ...discusspage,

    // 学习中心的本地状态
    ...learningCenter,
    ...collectionPage,
    home,
    courses,
    collegeDetail,
    courseDetail,
    my,
    stu
  }
});

const typeDefs = gql`
  extend type Query {
    treeData: [TreeData]!
    cooperationTreeData: [TreeData]!
  }

  extend type TreeData {
    title: Int!
    key: Int!
    children: [TreeData]!
    type: Int!
    pid: String!
    is_assistant: Int!
    hasFolderChild: Boolean!
    collaborativeState: String!
  }
`;

const socket = new PhoenixSocket(config.ws, {
  params: () => {
    return {
      token: timeLocalStorage.get('TOKENINFO')
        ? timeLocalStorage.get('TOKENINFO')
        : ''
    };
  },
  heartbeatIntervalMs: 5000
});

const absintheSocketLink = createAbsintheSocketLink(
  AbsintheSocket.create(socket)
);

const middlewareLink = new ApolloLink((operation, forward) => {
  if (
    operation.operationName !== 'RefreshToken' &&
    operation.operationName !== 'getMessageCount'
  ) {
    cache.writeData({
      data: {
        refreshToken: true
      }
    });
  }

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    let isToErrorPage = false;
    graphQLErrors.map(({ message, locations, path }) => {
      if (
        message.indexOf('invalid role') !== -1 ||
        message.indexOf('unauthorized') !== -1 ||
        message.indexOf('Exception calling application') !== -1
      ) {
        isToErrorPage = true;
        // history.push("/404");
        // window.location.href = config.uri + "404";
      }
      // console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // );
    });

    if (isToErrorPage) {
      history.push('/404');
    }
  }
  if (networkError) {
    if (networkError.statusCode === 500) {
      // message.error('请刷新页面');
    }
    if (networkError.statusCode === 404) {
      history.push('/404');
    }
    // window.location.href = config.uri + "404";
    // console.log(networkError);
    // console.log(`[Network error]: ${networkError}`);
  }
});

const link = split(
  operation => hasSubscription(operation.query),
  absintheSocketLink,
  ApolloLink.from([
    errorLink,
    middlewareLink,
    createLink({ uri: config.uri + 'api', headers })
  ])
);

const client = new ApolloClient({
  // uri: '/graphiql',
  // headers,
  link,
  cache: cache,
  typeDefs
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <App />
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById('root')
  // function() {
  //   let script = document.createElement('script');
  //   script.src = 'https://use.fontawesome.com/releases/v5.0.6/js/all.js';
  //   document.body.appendChild(script);
  // }
);
