import React, { Component } from 'react';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Menu, Form, Layout, Input, Avatar, List, message } from 'antd';
// import { connect } from 'dva';
// import Layout from 'antd/es/layout/layout';
// import Row from 'antd/es/grid/row';
// import Col from 'antd/es/grid/col';
// import Input from 'antd/es/input/Input';
// import TextArea from 'antd/es/input/TextArea';
// import Button from 'antd/es/button/button';
// import List from 'antd/es/list/index';
// import Checkbox from 'antd/es/checkbox/Checkbox';
// import Avatar from 'antd/es/avatar/index';
// import Form from 'antd/es/form/Form';
// import RadioButton from 'antd/es/radio/radioButton';
// import RadioGroup from 'antd/es/radio/group';
// import MDEditor from '../../components/MDEditor';
// import MDEditor_v2 from '../../components/MDEditor_v2';
import MDEditor_v2 from '../../CommonComponents/MDEditor/MDEditor_v2';
// import EmptyLayout from '../../components/course/emptyLayout';
// import empty1s from '../../assets/omcbg/empty1s.png';
// import more from '../../assets/icons/more_circle.png';
// import Menu from 'antd/es/menu/index';
// import Dropdown from 'antd/es/dropdown/dropdown';
// import { Icon } from 'antd';
// import discImg from '../../assets/icons/discuss.png';
// import down from '../../assets/icons/arrow_down_line.png';
// import { Disc } from '../../components/list/disc_v2';
// import { Comment } from '../../components/list/comment';
import styled from 'styled-components';
import moment from 'moment';
// import search from '../../assets/icons/search.png';
// import cross from '../../assets/icons/cross.png';
// import edit from '../../assets/icons/edit.png';
// import Modal from 'antd/es/modal/Modal';
// import MarkdownView from '../../components/markdown/MarkdownView';
import { checkMDContent } from '../../../utils/checkForm';
// import Spin from 'antd/es/spin/index';
// import { LoadingView } from '../../components/common/LoadingView';
// import loading from '../../assets/icons/loading.png';
// import message from 'antd/es/message/index';
// import { routerRedux } from 'dva/router';
// import gaEvents from '../../utils/gaEvents';

// import DiscussTagInput from '../../components/discuss/DiscussTagInput';

import MyReactMarkdown from '../../CommonComponents/MyReactMarkdown';
// import { changeToMarkdownFormat } from '../../utils/index';
// import Upload from 'antd/es/upload/Upload';
// import uploadImg from '../../assets/icons/import.png';

import left from '../../../assets/icons/arrow_left_circle.png';
import right from '../../../assets/icons/arrow_right_circle.png';

// import favorite from '../../../assets/icons/favorite.png';
import favorite from '../../../assets/icons/favorite.png';
import favorite_active from '../../../assets/icons/favorite_active.png';

import deleteIcon from '../../../assets/v2.1/delete.png';
import {
  useGetDiscussDetailQuery,
  usePostCommentToDiscussMutation,
  useCollectCardMutation,
  useUnCollectMutation,
  useRemoveCommentFromDiscussionMutation
} from '../../../generated/graphql.tsx';

import { Base64 } from 'js-base64';
import Modal3CallbackFunc from '../../CommonComponents/Modals/Modal3CallbackFunc';

import timeLocalStorage from '../../../utils/TimeLocalStorage';

const TextArea = Input.TextArea;
const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  width: '100%',
  alignItems: 'center'
};
const QItem = styled(Menu.Item)`
  background-color: #fff;
  color: #383838;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    background-color: #1890ff;
    color: #fff;
  }
`;

const GET_BASEIC_DATA = gql`
  {
    collectionShowData @client
    discDetailLabels @client
    isCol @client
    discDetail @client
    commentList @client
    courseId @client
    collectionDetailIndex @client
    discussCoursePackageId @client
    discussionCollectionId @client
    isCollectedInDiscussion @client
  }
`;
const CHANGE_STATE = gql`
  {
    isCollectedInDiscussion @client
  }
`;
const DISCUSSION_DATA = gql`
  {
    discDetailLabels @client
    commentList @client
    discDetail @client
    collectionDetailIndex @client
    isCollectedInDiscussion @client
  }
`;
const DiscussionCollection = props => {
  const client = useApolloClient();
  const {
    data: {
      collectionShowData,
      discDetailLabels,
      isCol,
      discDetail,
      commentList,
      courseId,
      collectionDetailIndex,
      discussCoursePackageId,
      discussionCollectionId,
      isCollectedInDiscussion
    },
    error,
    loading
  } = useQuery(GET_BASEIC_DATA);

  const { refetch: takeSingleDiscusion } = useGetDiscussDetailQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });
  const [postCommentToDiscuss] = usePostCommentToDiscussMutation({
    skip: true
  });
  const [removeCommentMutation] = useRemoveCommentFromDiscussionMutation();

  const [collect] = useCollectCardMutation({ skip: true });
  // 未收藏
  const [unCollect] = useUnCollectMutation({ skip: true });

  if (loading) {
    return null;
  }
  const Wrapper = Form.create()(CollectionDetailDiscuss);

  return (
    <Wrapper
      collectionShowData={collectionShowData}
      discDetail={discDetail}
      discDetailLabels={discDetailLabels}
      commentList={commentList}
      takeSingleDiscusion={takeSingleDiscusion}
      isCol={isCol}
      client={client}
      courseId={courseId}
      collectionDetailIndex={collectionDetailIndex}
      discussCoursePackageId={discussCoursePackageId}
      postCommentToDiscuss={postCommentToDiscuss}
      discussionCollectionId={discussionCollectionId}
      isCollectedInDiscussion={isCollectedInDiscussion}
      collect={collect}
      unCollect={unCollect}
      removeCommentMutation={removeCommentMutation}
    />
  );
};

export default DiscussionCollection;

class CollectionDetailDiscuss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discussContent: '',
      isUseMarkdownEditForDis: false
    };
  }

  showLeft() {
    const { collectionShowData, collectionDetailIndex } = this.props;
    let isShow = true;

    if (
      /*collectionShowData.current_page === 1 &&*/ collectionDetailIndex === 0
    ) {
      isShow = false;
    }

    return isShow;
  }

  showRight() {
    const { collectionShowData, collectionDetailIndex } = this.props;
    let isShow = true;

    if (
      /*collectionShowData.current_page === collectionShowData.total_page &&*/
      collectionDetailIndex ===
      collectionShowData.length - 1
    ) {
      isShow = false;
    }

    return isShow;
  }

  goLeft() {
    const {
      dispatch,
      collectionShowData,
      collectionDetailIndex,
      takeSingleDiscusion,
      form,
      client,
      courseId
    } = this.props;
    const key = collectionShowData[collectionDetailIndex - 1].id;
    const labels = collectionShowData[collectionDetailIndex - 1].labels;

    const realyId = window.atob(key).split('Collection:')[1];

    const discussId = window.btoa(`Discussion:${realyId}`);

    takeSingleDiscusion({ discussID: discussId, courseId: courseId, first: 1000 }).then(res => {
      const data = res.data.node;
      let commentList = [];

      const discDetailLabels = data.labels.map(item => {
        return { color: item.info.color, name: item.name };
      });

      if (data.comments.edges.length !== 0) {
        commentList = data.comments.edges.map(item => item.node);
      }

      const discDetail = {
        head_img: data.author.avatar,
        content: data.content,
        time: data.insertedAt,
        title: data.name
      };

      console.log('data.isCollected');
      console.log(data.isCollected);
      client.writeQuery({
        query: DISCUSSION_DATA,
        data: {
          discDetailLabels,
          commentList,
          discDetail,
          collectionDetailIndex: collectionDetailIndex - 1,
          isCollectedInDiscussion: data.isCollected
        }
      });
    });

    this.setState({ discussContent: '' });
    form.resetFields();
  }
  goRight() {
    const {
      dispatch,
      collectionShowData,
      collectionDetailIndex,
      takeSingleDiscusion,
      form,
      client,
      courseId
    } = this.props;

    const key = collectionShowData[collectionDetailIndex + 1].id;
    const labels = collectionShowData[collectionDetailIndex + 1].labels;

    const realyId = window.atob(key).split('Collection:')[1];

    const discussId = window.btoa(`Discussion:${realyId}`);

    takeSingleDiscusion({ discussID: discussId, courseId: courseId, first: 1000  }).then(res => {
      const data = res.data.node;
      let commentList = [];

      const discDetailLabels = data.labels.map(item => {
        return { color: item.info.color, name: item.name };
      });

      if (data.comments.edges.length !== 0) {
        commentList = data.comments.edges.map(item => item.node);
      }

      const discDetail = {
        head_img: data.author.avatar,
        content: data.content,
        time: data.insertedAt,
        title: data.name
      };

      client.writeQuery({
        query: DISCUSSION_DATA,
        data: {
          discDetailLabels,
          commentList,
          discDetail,
          collectionDetailIndex: collectionDetailIndex + 1,
          isCollectedInDiscussion: data.isCollected
        }
      });
    });
    // dispatch({
    //   type: 'course/handlerSetCollectiondetailIndex',
    //   collectionDetailIndex: collectionDetailIndex + 1
    // });
    // dispatch({
    //   type: 'course/toDiscDetail',
    //   payload: { key: key, page: 1, count: 20, labels: labels }
    // });

    this.setState({ discussContent: '' });
    form.resetFields();
  }

  handleCommitDisc = discussId => {
    const {
      dispatch,
      form,
      commentKey,
      discussCoursePackageId,
      postCommentToDiscuss,
      discussionCollectionId,
      client
    } = this.props;
    const { discussContent, comLoading, isUseMarkdownEditForDis } = this.state;
    if (isUseMarkdownEditForDis) {
      form.validateFieldsAndScroll(['comment'], (errors, values) => {
        if (errors) {
          return;
        }

        const discussId = window
          .atob(discussionCollectionId)
          .split('Collection:')[1];
        const coursePackageId = window
          .atob(discussCoursePackageId)
          .split('CoursePackage:')[1];

        postCommentToDiscuss({
          variables: {
            input: {
              comment: { content: values.comment.content.markdown },
              coursePackageId,
              discussionId: discussId
            }
          }
        }).then(res => {
          const commentList = res.data.addCommentToDiscussion.discussion.comments.edges.map(
            item => item.node
          );

          // const commentList =
          client.writeQuery({ query: DISCUSSION_DATA, data: { commentList } });
        });
        // this.setState({ comLoading: true });
        // dispatch({
        //   type: 'course/commitComment',
        //   payload: {
        //     key: commentKey,
        //     content: values.comment.content.markdown,
        //     page: 1,
        //     count: 20,
        //     callback: () => {
        //       this.setState({ comLoading: false, discussContent: '' });
        //       form.resetFields();
        //       this.resetCommentsList();
        //     }
        //   }
        // });
      });
    } else {
      if (!comLoading && discussContent.trim() !== '') {
        // console.log(discussionCollectionId);
        const discussId = window
          .atob(discussionCollectionId)
          .split('Collection:')[1];
        const coursePackageId = window
          .atob(discussCoursePackageId)
          .split('CoursePackage:')[1];

        postCommentToDiscuss({
          variables: {
            input: {
              comment: { content: discussContent },
              coursePackageId,
              discussionId: discussId
            }
          }
        }).then(res => {
          const commentList = res.data.addCommentToDiscussion.discussion.comments.edges.map(
            item => item.node
          );

          client.writeQuery({ query: DISCUSSION_DATA, data: { commentList } });
        });
        // this.setState({ comLoading: true });
        // dispatch({
        //   type: 'course/commitComment',
        //   payload: {
        //     key: commentKey,
        //     content: changeToMarkdownFormat(discussContent),
        //     page: 1,
        //     count: 20,
        //     callback: () => {
        //       this.setState({ comLoading: false, discussContent: '' });
        //       form.resetFields();
        //       this.resetCommentsList();
        //     }
        //   }
        // });
      }
    }
  };

  handleCollectDis() {
    const {
      dispatch,
      discDetail,
      discKey,
      courseId,
      isCol,
      discussionCollectionId,
      collect,
      unCollect,
      client,
      isCollectedInDiscussion
    } = this.props;
    // 两种状态去控制收藏
    const cardId = window.atob(discussionCollectionId).split('Collection:')[1];
    const wholeCourseId = window.atob(courseId).split('Course:')[1];
    if (!isCollectedInDiscussion) {
      collect({
        variables: {
          input: { cardId, courseId: wholeCourseId }
        }
      }).then(res => {
        if (res.data.collectCard.errors === null) {
          client.writeQuery({
            query: CHANGE_STATE,
            data: {
              isCollectedInDiscussion: true
            }
          });
        }
      });
    } else {
      unCollect({
        variables: {
          input: { cardId, courseId: wholeCourseId }
        }
      }).then(res => {
        client.writeQuery({
          query: CHANGE_STATE,
          data: {
            isCollectedInDiscussion: false
          }
        });
      });
    }
    // 收藏

    // 未收藏
    // dispatch({
    //   type: isCol ? 'course/canCardCol' : 'course/cardCol',
    //   payload: {
    //     key: discDetail.key,
    //     course_id: courseId
    //   }
    // });
  }

  renderDiscussContent() {
    const {
      dispatch,
      discData,
      discComment,
      discDetail,
      commentList,
      courseInfo,
      disc,
      discKey,
      shortUrl,
      isCol,
      discDetailLabels,
      isCollectedInDiscussion,
      discussCoursePackageId,
      discussionCollectionId,
      client,
      removeCommentMutation,
      collectionShowData,
      collectionDetailIndex,
      courseId,
      takeSingleDiscusion
    } = this.props;

    const {
      searchContent,
      pageContent,
      comLoading,
      aboutMine,
      sort,
      tag,
      status,
      tagDropdownVisible,
      tagContent,

      selfDiscussTitle,
      discussContent,
      isUseMarkdownEditForDis,
      selectedTags
    } = this.state;

    const { getFieldDecorator } = this.props.form;

    // if (discDetail === '') {
    //   return null;
    // }
    // console.log(isCollectedInDiscussion);
    return (
      <Layout className='new-routes-collection-collectionDetail-discuss-3100'>
        <Layout.Content
          className='new-routes-collection-collectionDetail-discuss-3103'
          style={{ overflow: 'auto' }}
        >
          <div className='new-routes-collection-collectionDetail-discuss-3214'>
            <div className='new-routes-collection-collectionDetail-discuss-3231'>
              <div className='new-routes-collection-collectionDetail-discuss-3308'>
                <div className='new-routes-collection-collectionDetail-discuss-3316'>
                  <div className='new-routes-collection-collectionDetail-discuss-3334'>
                    <div className='new-routes-collection-collectionDetail-discuss-3340'>
                      {discDetailLabels.map((tagItem, index) => {
                        return (
                          <div
                            className='new-routes-collection-collectionDetail-discuss-3343'
                            style={{ backgroundColor: tagItem.color }}
                            key={index}
                          >
                            {tagItem.name}
                          </div>
                        );
                      })}
                      <div className='new-routes-collection-collectionDetail-discuss-3345'>
                        {discDetail.title}
                      </div>
                    </div>
                    <img
                      src={isCollectedInDiscussion ? favorite_active : favorite}
                      className='new-routes-collection-collectionDetail-discuss-3357'
                      onClick={this.handleCollectDis.bind(this)}
                    />
                    {/* <div
                      className="new-routes-collection-collectionDetail-discuss-3357"
                      onClick={this.handleCollectDis.bind(this)}
                    /> */}
                  </div>

                  <div className='new-routes-collection-collectionDetail-discuss-3425'>
                    <div className='new-routes-collection-collectionDetail-discuss-3431'>
                      <Avatar src={discDetail.head_img} size={'default'} />
                      <div className='new-routes-collection-collectionDetail-discuss-3446'>
                        {/* {discDetail.user} ·{' '}
                        {moment(discDetail.create_time).isSame(
                          moment().startOf('day'),
                          'd'
                        )
                          ? `今天 ${discDetail.create_time.split(' ')[1]}`
                          : discDetail.create_time} */}
                      </div>
                    </div>
                  </div>

                  <div
                    className='new-routes-collection-collectionDetail-discuss-3559'
                    style={{ display: 'block' }}
                  >
                    <MyReactMarkdown className='' source={discDetail.content} />
                  </div>
                </div>
              </div>

              {
                //讨论模块的下半部分
              }
              <div className='new-routes-collection-collectionDetail-discuss-3708'>
                <div className='new-routes-collection-collectionDetail-discuss-3717'>
                  <div className='new-routes-collection-collectionDetail-discuss-3729'>
                    <div className='new-routes-collection-collectionDetail-discuss-3738'>
                      {commentList.length}条评论
                    </div>
                  </div>
                  {isUseMarkdownEditForDis ? (
                    <div className='new-routes-collection-collectionDetail-discuss-4417'>
                      <Form.Item className='new-routes-collection-collectionDetail-discuss-4440'>
                        {getFieldDecorator('comment', {
                          validateTrigger: ['onBlur'],
                          initialValue: { content: { markdown: '' } },
                          rules: [
                            {
                              validator: checkMDContent,
                              message: '请输入评论内容'
                            }
                          ]
                        })(
                          <MDEditor_v2
                            style={{ display: 'flex' }}
                            minHeight={'150px'}
                            isBottom
                            butTitle={'发布评论'}
                            loading={comLoading}
                            onSubmit={() =>
                              this.handleCommitDisc(discDetail.id)
                            }
                            width={920}
                            height={250}
                            pb={48}
                          />
                        )}
                      </Form.Item>
                      <div className='new-routes-collection-collectionDetail-discuss-4442'>
                        <div className='new-routes-collection-collectionDetail-discuss-4444'>
                          <div
                            className='new-routes-collection-collectionDetail-discuss-4485'
                            onClick={() => {
                              this.setState({
                                isUseMarkdownEditForDis: false
                              });
                            }}
                          >
                            切换普通输入框
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='new-routes-collection-collectionDetail-discuss-4454'>
                      <TextArea
                        className='new-routes-collection-collectionDetail-discuss-4466'
                        placeholder='说出你的评论'
                        autosize={{ minRows: 1, maxRows: 6 }}
                        value={discussContent}
                        onChange={value => {
                          this.setState({ discussContent: value.target.value });
                        }}
                      />

                      <div className='new-routes-collection-collectionDetail-discuss-4471'>
                        <div className='new-routes-collection-collectionDetail-discuss-4482'>
                          <div
                            className='new-routes-collection-collectionDetail-discuss-4485'
                            onClick={() => {
                              this.setState({
                                isUseMarkdownEditForDis: true
                              });
                            }}
                          >
                            切换Markdown编辑器
                          </div>
                        </div>
                        <div
                          className='new-routes-collection-collectionDetail-discuss-4492'
                          onClick={() => this.handleCommitDisc(discDetail.id)}
                        >
                          <div className='new-routes-collection-collectionDetail-discuss-4495'>
                            发布
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='new-routes-collection-collectionDetail-discuss-4508' />
                {commentList.length === 0 ? null : (
                  <div className='new-routes-collection-collectionDetail-discuss-4509'>
                    <List
                      // pagination={
                      //   discComment.page < 2
                      //     ? null
                      //     : {
                      //         pageSize: 20,
                      //         current: this.state.commentPage,
                      //         total: discComment.count,
                      //         onChange: e => {
                      //           this.setState({ commentPage: e });
                      //           // dispatch({
                      //           //   type: 'course/uploadComment',
                      //           //   payload: {
                      //           //     key: discDetail.key,
                      //           //     page: e,
                      //           //     count: 20
                      //           //   }
                      //           // });
                      //         }
                      //       }
                      // }
                      itemLayout='horizontal'
                      dataSource={commentList}
                      renderItem={item => {
                        let isMine = false;
                        const commentUserID = item.poster.id;
                        const myUserID = timeLocalStorage.get('USERINFO').userid;
                        if (commentUserID === myUserID) {
                          isMine = true;
                        }
                        return (
                          <List.Item>
                            <div className='new-routes-study-courseDiscussion-comment-wrapper new-routes-collection-collectionDetail-discuss-4611'>
                              <div className='new-routes-collection-collectionDetail-discuss-4621'>
                                <Avatar
                                  className='new-routes-collection-collectionDetail-discuss-4630'
                                  src={item.poster.avatar}
                                  size={'normal'}
                                />
                                <div className='new-routes-collection-collectionDetail-discuss-4644'>
                                  <div className='new-routes-collection-collectionDetail-discuss-4657'>
                                    <div className='new-routes-collection-collectionDetail-discuss-4661'>
                                      <div className='new-routes-collection-collectionDetail-discuss-4679'>
                                        {item.poster.nickname}
                                      </div>
                                      <div className='new-routes-collection-collectionDetail-discuss-4682'>
                                        {/* {moment(item.create_time).isSame(
                                        moment().startOf('day'),
                                        'd'
                                      )
                                        ? `今天 ${
                                            item.create_time.split(' ')[1]
                                          }`
                                        : item.create_time} */}
                                        {item.insertedAt}
                                      </div>
                                    </div>
                                    {isMine ? (
                                      <img
                                        src={deleteIcon}
                                        className='new-routes-collection-collectionDetail-discuss-4694'
                                        onClick={() => {
                                          Modal3CallbackFunc.callback = () => {
                                            let input= {
                                              commentId: Base64.decode(
                                                item.id
                                              ).split('CardComment:')[1],
                                              coursePackageId: Base64.decode(
                                                discussCoursePackageId
                                              ).split('CoursePackage:')[1],
                                              discussionId: Base64.decode(
                                                discussionCollectionId
                                              ).split('Collection:')[1]
                                            };
                                            removeCommentMutation({
                                              variables: { input: input }
                                            }).then((res) => {
                                              if (
                                                res.data
                                                  .removeCommentFromDiscussion
                                                  .errors
                                              ) {
                                                message.error(
                                                  res.data
                                                    .removeCommentFromDiscussion
                                                    .errors[0].message
                                                );
                                              } else {
                                                message.success('删除成功!');
                                                client.writeData({
                                                  data: {
                                                    modal3: {
                                                      show: false,
                                                      title: '',
                                                      content: '',
                                                      __typename: 'Modal3'
                                                    }
                                                  }
                                                });
                                                const key =
                                                  collectionShowData[
                                                    collectionDetailIndex
                                                  ].id;
                                                const labels =
                                                  collectionShowData[
                                                    collectionDetailIndex
                                                  ].labels;

                                                const realyId = window
                                                  .atob(key)
                                                  .split('Collection:')[1];

                                                const discussId = window.btoa(
                                                  `Discussion:${realyId}`
                                                );

                                                takeSingleDiscusion({
                                                  discussID: discussId,
                                                  courseId: courseId,
                                                  first: 1000 
                                                }).then(res => {
                                                  const data = res.data.node;
                                                  let commentList = [];

                                                  const discDetailLabels = data.labels.map(
                                                    item => {
                                                      return {
                                                        color: item.info.color,
                                                        name: item.name
                                                      };
                                                    }
                                                  );

                                                  if (
                                                    data.comments.edges
                                                      .length !== 0
                                                  ) {
                                                    commentList = data.comments.edges.map(
                                                      item => item.node
                                                    );
                                                  }

                                                  const discDetail = {
                                                    head_img:
                                                      data.author.avatar,
                                                    content: data.content,
                                                    time: data.insertedAt,
                                                    title: data.name
                                                  };

                                                  client.writeQuery({
                                                    query: DISCUSSION_DATA,
                                                    data: {
                                                      commentList
                                                    }
                                                  });
                                                });
                                              }
                                            });
                                          };

                                          client.writeData({
                                            data: {
                                              modal3: {
                                                show: true,
                                                title: '',
                                                content: '确定要删除评论吗？',
                                                __typename: 'Modal3'
                                              }
                                            }
                                          });
                                        }}

                                        // onClick={() => {
                                        //   dispatch({
                                        //     type: 'handler/handModal3',
                                        //     modal3: {
                                        //       show: true,
                                        //       title: '',
                                        //       content: '确定要删除评论吗？',
                                        //       event: () => {
                                        //         dispatch({
                                        //           type: 'course/deleteComment',
                                        //           payload: {
                                        //             key: discDetail.key,
                                        //             id: item.id,
                                        //             page: 1,
                                        //             count: 20,
                                        //             callback: () => {
                                        //               this.resetCommentsList();
                                        //             }
                                        //           }
                                        //         });
                                        //       }
                                        //     }
                                        //   });
                                        // }}
                                      />
                                    ) : null}
                                  </div>
                                  <MyReactMarkdown
                                    className='new-routes-collection-collectionDetail-discuss-4788'
                                    source={item.content}
                                  />
                                </div>
                              </div>
                            </div>
                          </List.Item>
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    );
  }

  render() {
    const { collectionShowData } = this.props;

    let showLeft = this.showLeft();
    let showRight = this.showRight();
    return (
      <div className='new-routes-collection-collectionDetail-discuss-3000'>
        {this.renderDiscussContent()}
        {showLeft ? (
          <div
            className='new-routes-collection-collectionDetail-discuss-3000-left'
            style={{ cursor: 'pointer' }}
            onClick={this.goLeft.bind(this)}
          >
            <img src={left} data-rjs='2' />
          </div>
        ) : (
          <div className='new-routes-collection-collectionDetail-discuss-3000-left' />
        )}
        {showRight ? (
          <div
            className='new-routes-collection-collectionDetail-discuss-3000-right'
            style={{ cursor: 'pointer' }}
            onClick={this.goRight.bind(this)}
          >
            <img src={right} data-rjs='2' />
          </div>
        ) : (
          <div className='new-routes-collection-collectionDetail-discuss-3000-right' />
        )}
      </div>
    );
  }
}

// export default Form.create()(CollectionDetailDiscuss);
