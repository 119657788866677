import { Course, CollegeCourse } from 'global_variable/home';
import { College } from 'global_variable/home';

// 根据学院筛选课程
const filterCourses = (courses: Course[], college: string): Course[] =>
  courses.filter((item: Course) => {
    if (item) {
      return item.college === college;
    }
  });

// 列表渲染需要的数据结构
export const mapCollegeCourses = (
  colleges: College[],
  courses: Course[]
): CollegeCourse[] =>
  colleges.map(item => ({
    title: item.title,
    to: `/college/${item.path}`,
    courses: filterCourses(courses, item.title).slice(0, 3)
  }));

// 学院课程查询条件
export const variables = {
  filter: {
    is_selling: true,
    is_online: true
  },
  first: 100
};
