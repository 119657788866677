import React, { Component } from "react";

import Input from "antd/es/input/Input";
import Select from "antd/es/select";
import Dropdown from "antd/es/dropdown/dropdown";
import Menu from "antd/es/menu/index";
import './styles.css';

class DiscussTagInput extends Component {
  constructor(props) {
    super(props);
    const tagsArray = props.tags.map(item => {
      return item["name"];
    });
    const tagsColor = props.tags.map(item => {
      return item["color"];
    });
    this.state = {
      tags: props.tags,
      tagsColor: tagsColor,
      tagsArray: tagsArray,
      inputContent: "",

      inputOnFocus: false
    };
  }

  renderSelectedItem() {
    return <div style={{ width: 10, height: 10, backgroundColor: "red" }} />;
  }

  addSelectedTag(tag) {
    let selectedTags = this.props.selectedTags;
    selectedTags[selectedTags.length] = tag;

    this.props.selectedTagsCallback(selectedTags);
  }

  deleteSelectedTag(tag) {
    let selectedTags = this.props.selectedTags;
    let index = selectedTags.indexOf(tag);
    if (index > -1) {
      selectedTags.splice(index, 1);
    }
    this.props.selectedTagsCallback(selectedTags);
  }

  makeNewTags(tags, fliter) {
    let newTags = [];
    tags.map((item, index) => {
      if (fliter === "") {
        newTags[newTags.length] = item;
        newTags[newTags.length] = "";
      } else {
        if (item.name.indexOf(fliter) != -1) {
          newTags[newTags.length] = item;
          newTags[newTags.length] = "";
        }
      }
    });
    return newTags;
  }

  tagMenu = tags => (
    <Menu className="new-component-discuss-discussInput-2408">
      {tags.length === 0 ? (
        <div className="new-component-discuss-discussInput-2478">
          没有可选用的标签
        </div>
      ) : (
        tags.map((item, index) => {
          if (item !== "") {
            return (
              <Menu.Item
                key={`${index}`}
                onClick={() => {
                  this.addSelectedTag(item);
                  this.setState({ inputContent: "" });
                }}
              >
                <div className="new-component-discuss-discussInput-2412">
                  <div
                    className="new-component-discuss-discussInput-2423"
                    style={{ backgroundColor: item.color }}
                  />
                  <div className="new-component-discuss-discussInput-2434">
                    {item.name}
                  </div>
                </div>
              </Menu.Item>
            );
          } else {
            if (index !== tags.length - 1) {
              return <Menu.Divider />;
            }
            return null;
          }
        })
      )}
    </Menu>
  );

  render() {
    const {
      inputContent,
      tags,
      tagsArray,
      tagsColor,
      inputOnFocus
    } = this.state;
    const { selectedTags } = this.props;

    // const filteredOptions = tags.filter(o => !selectedTags.includes(o));

    const filteredOptions = tags.filter(o => {
      let isHave = false;
      selectedTags.map((item) => {
        if(!isHave){
          isHave = item.id === o.id && item.name === o.name && item.color === o.color;
        }
      });
      return !isHave;
    });

    const showTags = this.makeNewTags(filteredOptions, inputContent);
    const visible = showTags.length > 0;

    return (
      <div className="new-component-discuss-discussInput-1010">
        <div className="new-component-discuss-discussInput-1127">
          {selectedTags.map((item, index) => {
            return (
              <div
                className="new-component-discuss-discussInput-1133"
                style={{ backgroundColor: item.color }}
                onClick={() => this.deleteSelectedTag(item)}
              >
                <div className="new-component-discuss-discussInput-1159 ">
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>

        {selectedTags.length > 0 ? (
          <div className="new-component-discuss-discussInput-1235" />
        ) : null}
        <div className="new-component-discuss-discussInput-1314">
          <Dropdown
            placement="bottomLeft"
            trigger={["click"]}
            overlay={this.tagMenu(showTags)}
            overlayClassName="new-component-discuss-discussInput-2466"
          >
            <Input
              maxLength={36}
              placeholder="请输入标签"
              onChange={value => {
                this.setState({ inputContent: value.target.value });
              }}
              value={inputContent}
            />
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default DiscussTagInput;
