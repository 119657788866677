import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Layout, Menu, message, Checkbox } from 'antd';

import { FormComponentProps } from 'antd/es/form';
import { withRouter } from 'react-router';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { ContextMenuTrigger } from 'react-contextmenu';
import { arrayMove } from 'react-sortable-hoc';

//引入的其他页面组件
import FileTree from '../CommonComponents/layout/FileTree';
import SortableList from '../CommonComponents/sort/sortableComponent';
import { getRouterSrc } from '../../utils';
import EmptyLayout from '../CommonComponents/EmptyLayout';
import {
  addChildDataToPackage,
  addChildDataToCard,
  findPackageId
} from './utils';
import ComponentFunction from './ComponentFunction';

//graphql查询
import {
  useGetEduCreatedPackageInfoQuery,
  useGetDataWithParentDataQuery
} from '../../generated/graphql';

//引入的资源图片
import cross from '../../assets/icons/cross.png';
import backImg from '../../assets/icons/back.png';
import empty1 from '../../assets/omcbg/empty1.png';
import down from '../../assets/icons/arrow_down_line.png';
import create from '../../assets/icons/create.png';
import upload from '../../assets/icons/upload_small.png';
import search from '../../assets/icons/search.png';
import fullscreen from '../../assets/icons/fullscreen.png';
import thumb1 from '../../assets/icons/thumb_1.png';
import thumb_1_hover from '../../assets/icons/thumb_1_hover.png';
import thumb2 from '../../assets/icons/thumb_2.png';
import thumb_2_hover from '../../assets/icons/thumb_2_hover.png';
import thumb3 from '../../assets/icons/thumb_3.png';
import thumb_3_hover from '../../assets/icons/thumb_3_hover.png';
import loading from '../../assets/icons/loading.png';
import { FetchType } from 'apollo-boost';

interface Props extends FormComponentProps {
  props: any;
  pageState: any;
  pageFunction: any;
}

const GET_CREATE_PACKAGE_INFO = gql`
  {
    hanStatus @client {
      handing
      tip
    }
    screenful @client
    dragMode @client
    sort @client
    pMode @client
    isCheckPackage @client
    selectedFileId @client
    errorCards @client
    selectCards @client
    viewMode @client
    searchMode @client
    expandedKeys @client
    cardPaging @client {
      page
      count
    }
    checkAll @client {
      cancel
      all
      on
    }
  }
`;

const GET_CREATE_PACKAGE_DATA = gql`
  {
    showFiles @client
    searchMode @client
    treeData @client(always: true)
    cooperationTreeData @client(always: true)
    selectedFilePackageId @client
  }
`;

const EduManagerPackagePageContent = (props: any) => {
  const client = useApolloClient();
  const { data: packageInfoData } = useQuery(GET_CREATE_PACKAGE_INFO);
  const { data: packageData, errors: packageDataError } = useQuery(
    GET_CREATE_PACKAGE_DATA
  );
  const [isInit, setIsInit] = React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(true);

  // const { treeData, cooperationTreeData } = props;
  const { showFiles, treeData, cooperationTreeData } = packageData;

  const {
    hanStatus,
    screenful,
    dragMode,
    sort,
    pMode,
    isCheckPackage,
    selectedFileId,
    viewMode,
    selectCards,
    errorCards,
    checkAll,
    cardPaging,
    expandedKeys
  } = packageInfoData;

  const { getShowFiles } = props.pageFunction;

  const {
    sortFilesState,
    collapsedState,
    searchContentState,
    pageState,
    isPackageState,
    modeState,
    isFileState,
    isCooState
  } = props.pageState;

  const { refetch: getPackageDataWithParent } = useGetDataWithParentDataQuery({
    variables: { id: selectedFileId, isMaster: true },
    skip: true
  });

  const { refetch: getPackageData } = useGetEduCreatedPackageInfoQuery({
    variables: { id: selectedFileId, isMaster: true },
    skip: true
  });

  React.useEffect(() => {
    if (treeData.length > 0 || cooperationTreeData.length > 0) {
      let id = selectedFileId === '' ? (pMode ? treeData[0].key : cooperationTreeData[0].key) : selectedFileId;
      initSelectFile([id], pMode);
      client.writeData({ data: { selectedFileId: id, selectedFilePackageId: findPackageId(pMode ? treeData : cooperationTreeData, id) } });
    } else {
      setIsInit(true);
    }
    setIsRefresh(!isRefresh);
    ComponentFunction.onSelectFile = onSelectFile;
    ComponentFunction.onExpendFile = onExpendFile;
  }, []);

  const onExpendFile = (expendKeys: any, handlerMode: any) => {
    getPackageData({
      id: expendKeys[expendKeys.length - 1],
      isMaster: handlerMode
    }).then(res => {
      let data: any = res.data.node;

      if (data.__typename === 'Package') {
        addChildDataToPackage(
          handlerMode ? treeData : cooperationTreeData,
          data,
          handlerMode,
          'TreeData'
        );
        client.writeQuery({
          query: GET_CREATE_PACKAGE_DATA,
          data: { treeData: treeData, cooperationTreeData: cooperationTreeData }
        });
      } else if (data.__typename === 'Card') {
        addChildDataToCard(
          handlerMode ? treeData : cooperationTreeData,
          data,
          handlerMode,
          'TreeData'
        );
        client.writeQuery({
          query: GET_CREATE_PACKAGE_DATA,
          data: { treeData: treeData, cooperationTreeData: cooperationTreeData }
        });
      } else {
        message.error('获取内容错误!');
        return;
      }
      setIsRefresh(!isRefresh);
    });
  };

  const initCheckParentData = (
    treeData: any[],
    data: any,
    selectKeys: any,
    handlerMode: any
  ) => {
    if (data.parent) {
      let ancestors = data.ancestors.sort((a: any, b: any) => {
        return b.depth - a.depth;
      });

      getPackageDataWithParent({
        id: data.package.id,
        isMaster: handlerMode
      }).then(res => {
        let packageData: any = res.data.node;
        addChildDataToPackage(treeData, packageData, handlerMode, 'TreeData');
        client.writeQuery({
          query: GET_CREATE_PACKAGE_DATA,
          data: handlerMode
            ? { treeData: treeData }
            : { cooperationTreeData: treeData }
        });
        ancestors.map((item: any) => {
          addChildDataToCard(treeData, item, handlerMode, 'TreeData');
        });
        addChildDataToCard(treeData, data, handlerMode, 'TreeData');
        client.writeQuery({
          query: GET_CREATE_PACKAGE_DATA,
          data: handlerMode
            ? {
                searchMode: false,
                treeData: treeData
              }
            : {
                searchMode: false,
                cooperationTreeData: treeData
              }
        });
        if (handlerMode) {
          getShowFiles(
            typeof treeData === 'undefined' ? [] : treeData,
            selectKeys,
            1
          );
        } else {
          getShowFiles(
            typeof cooperationTreeData === 'undefined'
              ? []
              : treeData,
            selectKeys,
            1
          );
        }
        setIsRefresh(!isRefresh);
        setIsInit(true);
      });
    } else {
      getPackageDataWithParent({
        id: data.package.id,
        isMaster: handlerMode
      }).then(res => {
        let parentData: any = res.data.node;
        addChildDataToPackage(treeData, parentData, handlerMode, 'TreeData');
        addChildDataToCard(treeData, data, handlerMode, 'TreeData');
        client.writeQuery({
          query: GET_CREATE_PACKAGE_DATA,
          data: handlerMode ? { treeData: treeData, searchMode: false } : { cooperationTreeData: treeData, searchMode: false }
        });
        if (handlerMode) {
          getShowFiles(
            typeof treeData === 'undefined' ? [] : treeData,
            selectKeys,
            1
          );
        } else {
          getShowFiles(
            typeof cooperationTreeData === 'undefined'
              ? []
              : treeData,
            selectKeys,
            1
          );
        }
        setIsRefresh(!isRefresh);
        setIsInit(true);
      });
    }
  };

  const initSelectFile = (selectKeys: any, handlerMode: any) => {
    getPackageDataWithParent({ id: selectKeys[0], isMaster: handlerMode }).then(
      res => {
        let data: any = res.data.node;
        if (data.__typename === 'Package') {
          addChildDataToPackage(
            handlerMode ? treeData : cooperationTreeData,
            data,
            handlerMode,
            'TreeData'
          );
          client.writeQuery({
            query: GET_CREATE_PACKAGE_DATA,
            data: {
              treeData: treeData,
              cooperationTreeData: cooperationTreeData,
              searchMode: false
            }
          });
          if (handlerMode) {
            getShowFiles(
              typeof treeData === 'undefined' ? [] : treeData,
              selectKeys,
              1
            );
          } else {
            getShowFiles(
              typeof cooperationTreeData === 'undefined'
                ? []
                : cooperationTreeData,
              selectKeys,
              1
            );
          }
          setIsRefresh(!isRefresh);
          setIsInit(true);
        } else if (data.__typename === 'Card') {
          initCheckParentData(
            handlerMode ? treeData : cooperationTreeData,
            data,
            selectKeys,
            handlerMode
          );
        } else {
          message.error('获取内容错误!');
          return;
        }
      }
    );
  };

  const onSelectFile = (selectKeys: any, handlerMode: any) => {
    pageState.setPage(1);
    getPackageData({ id: selectKeys[0], isMaster: handlerMode })
      .then(res => {
        let data: any = res.data.node;

        if (data.__typename === 'Package') {
          addChildDataToPackage(
            handlerMode ? treeData : cooperationTreeData,
            data,
            handlerMode,
            'TreeData'
          );
          client.writeQuery({
            query: GET_CREATE_PACKAGE_DATA,
            data: {
              searchMode: false,
              treeData: treeData,
              cooperationTreeData: cooperationTreeData,
              selectedFileId: selectKeys[0],
              selectedFilePackageId: findPackageId(
                pMode ? treeData : cooperationTreeData,
                selectKeys[0]
              )
            }
          });
        } else if (data.__typename === 'Card') {
          addChildDataToCard(
            handlerMode ? treeData : cooperationTreeData,
            data,
            handlerMode,
            'TreeData'
          );
          client.writeQuery({
            query: GET_CREATE_PACKAGE_DATA,
            data: {
              searchMode: false,
              treeData: treeData,
              cooperationTreeData: cooperationTreeData,
              selectedFileId: selectKeys[0],
              selectedFilePackageId: findPackageId(
                pMode ? treeData : cooperationTreeData,
                selectKeys[0]
              )
            }
          });
        } else {
          message.error('获取内容错误!');
          return;
        }
        if (handlerMode) {
          getShowFiles(
            typeof treeData === 'undefined' ? [] : treeData,
            selectKeys,
            1
          );
        } else {
          getShowFiles(
            typeof cooperationTreeData === 'undefined'
              ? []
              : cooperationTreeData,
            selectKeys,
            1
          );
        }
      })
      .catch((res: any) => {});
  };

  const goUp = () => {
    // const { treeData, dispatch, selectedFileId, pMode, cooperationTreeData } = this.props;
    if (selectedFileId === '') {
      message.error('卡包不存在!');
      return;
    }
    const routes = getRouterSrc(
      pMode ? treeData : cooperationTreeData,
      selectedFileId
    ).reverse();
    if (routes.length === 1) {
      message.warning('已经是文件夹顶级');
      return;
    }
    const key = routes[routes.length - 2].key;
    client.writeData({
      data: {
        checkAll: {
          cancel: true,
          all: false,
          on: false,
          __typename: 'CheckAll'
        },
        selectCards: [],
        selectedFileId: key
      }
    });
    onSelectFile([key], pMode);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      //   const { showFiles, dispatch, dragMode } = this.props;
      const sortData = arrayMove(showFiles, oldIndex, newIndex);
      if (dragMode) {
        sortFilesState.setSortFiles(sortData);
        client.writeQuery({
          query: GET_CREATE_PACKAGE_DATA,
          data: { showFiles: sortData }
        });
      }
    }
  };

  const renderCardRoutes = () => {
    // const { treeData, dispatch, selectedFileId, pMode, cooperationTreeData } = this.props;
    const routes = getRouterSrc(
      pMode ? treeData : cooperationTreeData,
      selectedFileId
    ).reverse();
    return routes.map((value, index) => {
      return (
        <div className='edu-PackageManager-17046' key={value + index}>
          {index === routes.length - 1 ? (
            <div className='edu-PackageManager-17135'>
              {value.title}
            </div>
          ) : (
            <div
              onClick={() => {
                client.writeData({
                  data: { selectedFileId: value.key, searchMode: false }
                });
                //   dispatch({ type: 'course/handleSelectFileId', selectedFileId: value.key });
                // onSelectFile(value.key, pMode);
                if (pMode) {
                  getShowFiles(
                    typeof treeData === 'undefined' ? [] : treeData,
                    [value.key],
                    1
                  );
                } else {
                  getShowFiles(
                    typeof cooperationTreeData === 'undefined'
                      ? []
                      : cooperationTreeData,
                    [value.key],
                    1
                  );
                }
              }}
              className='edu-PackageManager-17485'
            >
              {value.title} ›
            </div>
          )}
        </div>
      );
    });
  };

  if (!isInit) {
    return null;
  }

  return (
    <Layout className='edu-PackageManager-28813'>
      <Layout.Sider
        collapsed={collapsedState.collapsed}
        collapsible
        trigger={null}
        collapsedWidth={0}
        className='edu-PackageManager-28946'
      >
        <div
          style={{
            backgroundColor: '#fff',
            zIndex: 1000,
            transform: collapsedState.collapsed
              ? 'rotate(-180deg)'
              : 'rotate(0deg)',
            border: '1px solid #D2D4D7',
            borderRadius: '12px 0 0 12px',
            width: 21,
            height: 40,
            cursor: 'pointer',
            position: 'absolute',
            right: collapsedState.collapsed ? -22 : 0,
            top: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => {
            if (!dragMode) {
              collapsedState.setCollapsed(!collapsedState.collapsed);
            }
          }}
        >
          <img src={down} className='edu-PackageManager-29618' />
        </div>
        <div className='edu-PackageManager-29700'>我创建的</div>
        <div className='edu-PackageManager-29805'>
          <div className='edu-PackageManager-29883'>
            <Menu className='edu-PackageManager-29964'>
              <FileTree
                selectedFileId={selectedFileId}
                expandedKeys={expandedKeys}
                handlerMode={true}
                onExpendFile={onExpendFile}
                onSelectFile={onSelectFile}
                treeData={treeData}
              />
            </Menu>
          </div>
        </div>
        <div className='edu-PackageManager-30285'>我协作的</div>
        <div
          className='edu-PackageManager-30390'
          ref={node => {
            let container = node;
          }}
        >
          <Menu className='edu-PackageManager-30529'>
            <FileTree
              selectedFileId={selectedFileId}
              expandedKeys={expandedKeys}
              handlerMode={false}
              onExpendFile={onExpendFile}
              onSelectFile={onSelectFile}
              treeData={cooperationTreeData}
            />
          </Menu>
        </div>
      </Layout.Sider>

      <Layout className='edu-PackageManager-30856'>
        <div className='edu-PackageManager-30929'>
          <div onClick={goUp} className='edu-PackageManager-31070'>
            <img src={backImg} />
            <span className='edu-PackageManager-31200'>返回</span>
            <div className='edu-PackageManager-31277' />
          </div>
          {renderCardRoutes()}
        </div>
        <div className='edu-PackageManager-31462'>
          <Checkbox
            checked={checkAll.all}
            onChange={e => {
              if (e.target.checked) {
                let cards = showFiles
                  .filter((item: any) => {
                    return true || item.type === 1;
                  })
                  .map((item: any) => {
                    return item.key;
                  });
                client.writeData({
                  data: {
                    selectCards: cards,
                    checkAll: {
                      cancel: false,
                      all: true,
                      on: false,
                      __typename: 'CheckAll'
                    }
                  }
                });
              } else {
                client.writeData({
                  data: {
                    selectCards: [],
                    checkAll: {
                      cancel: true,
                      all: false,
                      on: false,
                      __typename: 'CheckAll'
                    }
                  }
                });
              }
            }}
          >
            {!modeState.mode ? '全选' : '文件名'}
          </Checkbox>
          {!modeState.mode ? (
            <div className='edu-PackageManager-32353'>
              选中{selectCards.length}个对象
            </div>
          ) : null}
          {!modeState.mode ? null : (
            <div className='edu-PackageManager-32465'>创建时间</div>
          )}
          {!modeState.mode ? null : (
            <div className='edu-PackageManager-32564'>最后修改时间</div>
          )}
        </div>
        <ContextMenuTrigger id='screen' key='screen' collect={() => {}}>
          {showFiles.length === 0 ? (
            <EmptyLayout className='edu-PackageManager-32849'>
              <EmptyLayout.Img src={empty1} />
              <EmptyLayout.Text>还没有任何文件，快去上传吧～</EmptyLayout.Text>
            </EmptyLayout>
          ) : (
            <Layout.Content
              style={{
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                width: 1000,
                paddingLeft: modeState.mode ? 0 : 26,
                paddingTop: 20
              }}
            >
              <SortableList
                handleRigth={(item: any) => {
                  isFileState.setIsFile(item.type === 1);
                  isCooState.setIsCoo(item.is_assistant === 1);
                }}
                handlePage={(page: any) => {
                  pageState.setPage(page);
                }}
                page={pageState.page}
                mode={modeState.mode}
                onCollect={(value: any) => {
                  isFileState.setIsFile(value.type === 1);
                  isCooState.setIsCoo(value.is_assistant === 1);
                  return {
                    key: value.key,
                    type: value.type,
                    title: value.title,
                    pid: value.pid
                  };
                }}
                ref={instance => {
                  let SortableList = instance;
                }}
                axis='xy'
                {...props}
                items={showFiles}
                onSortEnd={onSortEnd}
                packageInfoData={packageInfoData}
                getShowFiles={getShowFiles}
                onSelectFile={onSelectFile}
              />
            </Layout.Content>
          )}
        </ContextMenuTrigger>
      </Layout>
    </Layout>
  );
};

const EduManagerPackagePageContentWrapper = Form.create<Props>()(
  withRouter(EduManagerPackagePageContent)
);

export default EduManagerPackagePageContentWrapper;
