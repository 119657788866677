/**

 * @file: 开智js

 * @author: sky

 *          qiuweiming@quseit.com

 * @update: 2015-04-29 00:02:45

 */
import styled from 'styled-components';

interface CustomPProps {
  customStyle?: any;
  cs?: any;
}

const CustomP = styled.div`
font-family: "PingFang SC","Hiragino Sans GB","Microsoft Yahei",arial,\\5b8b\\4f53,"Helvetica Neue",Helvetica,STHeiTi,sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: ${(props: CustomPProps) => props.customStyle.line};
  -webkit-box-orient: vertical;
  font-size: ${(props: CustomPProps) => props.customStyle.fontSize};
  color: ${(props: CustomPProps) => props.customStyle.color};
  font-weight: 400;
  line-height: ${(props: CustomPProps) => props.customStyle.lineHeight};
`;

const HoverP = styled.div`
color: ${(props: CustomPProps) => props.cs.color};
margin-left: 12px;
line-height: 22px;
&:hover{
    color: ${(props: CustomPProps) => props.cs.hColor};
  }
`;

const HoverP_V2 = styled.div`
color: ${(props: CustomPProps) => props.cs.color};
margin-left: 12px;

word-break: break-all;
word-wrap: break-word;
white-space:normal;

&:hover{
    color: ${(props: CustomPProps) => props.cs.hColor};
  }
`;

export { CustomP, HoverP, HoverP_V2 };
