import * as React from 'react';
import './styles.css';

//引入的库组件
import { useQuery } from 'react-apollo-hooks';
import { useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { Input, Form, Upload, message } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import { checkMDContent } from '../../../../utils/checkForm';
import MDEditor_v2 from '../../../CommonComponents/MDEditor/MDEditor_v2';
import DiscussTagInput from './DiscussTagInput';

//graphql查询
import {
  useCreateDiscussMutation,
  useUpdateDiscussMutation
} from '../../../../generated/graphql';

//引入的资源图片
import uploadImg from '../../../../assets/icons/import.png';

interface Props extends FormComponentProps {}

const GET_CREATE_DISCUSS_INFO = gql`
  {
    isModifyDisc @client
    isCheckDisc @client
    isShowDiscList @client
    discussDetailId @client
    tagsInDiscuss @client
    discussStatus @client
    discussCoursePackageId @client
    discussSelectedTags @client(always: true)
    discussMarkdownContent @client(always: true)
    discussSelfDiscussTitle @client(always: true)
  }
`;

const DiscussEditPage = (props: any) => {
  const client = useApolloClient();
  const { data: discussInfo } = useQuery(GET_CREATE_DISCUSS_INFO);
  const {
    tagsInDiscuss,
    discussCoursePackageId,
    isModifyDisc,
    isCheckDisc,
    isShowDiscList,
    discussDetailId,
    discussStatus
  } = discussInfo;

  const { data: discussData } = useQuery(GET_CREATE_DISCUSS_INFO);
  const {
    discussSelectedTags,
    discussMarkdownContent,
    discussSelfDiscussTitle
  } = discussData;

  const { form } = props;
  const { getFieldDecorator } = form;

  const [createDiscuss] = useCreateDiscussMutation();
  const [updateDiscuss] = useUpdateDiscussMutation();
  const [comLoading, setComLoading] = React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(false);

  React.useEffect(() => {}, []);

  let markdownContent = discussMarkdownContent;

  const handleSelectedTags = (tags: any) => {
    client.writeQuery({
      query: GET_CREATE_DISCUSS_INFO,
      data: {
        discussSelectedTags: tags.map((item: any) => {
          return item;
        })
      }
    });
  };

  const handleSave = () => {
    const selectedTagsArray = discussSelectedTags.map((item: any) => {
      return item['id'];
    });

    form.validateFieldsAndScroll(
      ['title', 'selfDiscussContent'],
      (errors: any, values: any) => {
        if (errors) {
          return;
        }

        if (isModifyDisc) {
          let input = {
            content: values.selfDiscussContent.content.markdown,
            id: Base64.decode(discussDetailId).split('Discussion:')[1],
            labels: selectedTagsArray.map((item: any) => {
              return Base64.decode(item).split('Label:')[1];
            }),
            name: discussSelfDiscussTitle,
            coursePackageId: Base64.decode(discussCoursePackageId).split('CoursePackage:')[1],
            status: discussStatus === '0'
          };
          updateDiscuss({ variables: { input: input } }).then((res: any) => {
            if (res.data.updateDiscussion.errors) {
              message.error(res.data.updateDiscussion.errors[0].message);
            } else {
              message.success('讨论更新成功!');
              client.writeData({
                data: {
                  isModifyDisc: false,
                  isCheckDisc: true,
                  isShowDiscList: false
                }
              });
            }
          });
        } else {
          let input = {
            content: values.selfDiscussContent.content.markdown,
            coursePackageId: Base64.decode(discussCoursePackageId).split(
              'CoursePackage:'
            )[1],
            labels: selectedTagsArray.map((item: any) => {
              return Base64.decode(item).split('Label:')[1];
            }),
            name: discussSelfDiscussTitle
          };
          createDiscuss({ variables: { input: input } }).then((res: any) => {
            if (res.data.createDiscussion.errors) {
              let errorMsg = res.data.createDiscussion.errors[0].message;
              if (errorMsg.lastIndexOf('已存在')) {
                errorMsg = '讨论已存在，请更换讨论标题';
              }
              message.error(errorMsg);
            } else {
              message.success('讨论发布成功!');
              client.writeData({
                data: {
                  isModifyDisc: false,
                  isCheckDisc: true,
                  isShowDiscList: false,
                  discussDetailId: res.data.createDiscussion.discussion.id
                }
              });
            }
          });
        }
      }
    );
  };

  if (isShowDiscList || isCheckDisc) {
    return null;
  }

  return (
    <div className='new-routes-study-courseDiscussion-1000'>
      <div
        className='new-routes-study-courseDiscussion-2087'
        style={{ marginBottom: 290 }}
      >
        <div className='new-routes-study-courseDiscussion-2104'>
          <div
            className='new-routes-study-courseDiscussion-2113'
            onClick={() => {
              client.writeData({
                data: {
                  isModifyDisc: false,
                  isShowDiscList: true,
                  isCheckDisc: false
                }
              });
            }}
          >
            ‹ 返回讨论列表
          </div>
        </div>

        <div className='new-routes-study-courseDiscussion-3142'>
          <Input
            className='new-routes-study-courseDiscussion-3155'
            suffix={null}
            value={discussSelfDiscussTitle}
            placeholder='标题'
            prefix={null}
            onChange={value => {
              client.writeData({
                data: { discussSelfDiscussTitle: value.target.value }
              });
            }}
          />
          <div className='new-routes-study-courseDiscussion-3168'>
            <Form>
              <Form.Item>
                {getFieldDecorator('selfDiscussContent', {
                  validateTrigger: ['onBlur'],
                  initialValue: {
                    content: {
                      markdown: markdownContent
                    }
                  },
                  rules: [
                    {
                      validator: checkMDContent,
                      message: '请输入评论内容'
                    }
                  ]
                })(
                  <MDEditor_v2
                    extraButton={() => {
                      return isModifyDisc ? (
                        <div
                          onClick={() => {
                            form.resetFields();
                            client.writeData({
                              data: {
                                isModifyDisc: false,
                                isCheckDisc: true,
                                isShowDiscList: false
                              }
                            });
                            // dispatch({
                            //   type: 'course/handlerIsModifyDisc',
                            //   isModifyDisc: false
                            // });
                            // dispatch({
                            //   type: 'course/handlerIsCheckDisc',
                            //   isCheckDisc: true
                            // });
                          }}
                        >
                          取消编辑
                        </div>
                      ) : null;
                    }}
                    isBottom={false}
                    minHeight={'150px'}
                    butTitle={'发布评论'}
                    loading={comLoading}
                    onSubmit={() => {}}
                    width={920}
                    height={250}
                    pb={10}
                  />
                )}
              </Form.Item>

              <div className='new-routes-study-courseDiscussion-3228'>
                <Upload
                  action='temp/'
                  showUploadList={false}
                  beforeUpload={file => {
                    const suffix = file.name.substring(
                      file.name.lastIndexOf('.') + 1,
                      file.name.length
                    );
                    const isMD = suffix === 'md';
                    if (isMD) {
                      const reader = new FileReader();
                      reader.readAsText(file);
                      reader.onload = function() {
                        client.writeData({
                          data: { discussMarkdownContent: reader.result }
                        });
                        client.writeData({ data: { isShowDiscList: true, discussDetailId: discussDetailId } });
                        client.writeData({ data: { isShowDiscList: false, discussDetailId: discussDetailId } });
                        message.success('导入文件成功');
                      };
                    } else {
                      message.error('仅支持导入markdown文件');
                    }
                    return false;
                  }}
                >
                  <div className='new-routes-study-courseDiscussion-3242'>
                    <img
                      className='new-routes-study-courseDiscussion-3257'
                      src={uploadImg}
                    />
                    <div className='new-routes-study-courseDiscussion-3266'>
                      导入Markdown文件
                    </div>
                  </div>
                </Upload>
              </div>
            </Form>
          </div>

          <div className='new-routes-study-courseDiscussion-3401'>
            <DiscussTagInput
              tags={tagsInDiscuss}
              selectedTags={discussSelectedTags}
              selectedTagsCallback={handleSelectedTags}
            />
          </div>
          <div className='new-component-discuss-discussInput-2617'>
            <div
              className='new-component-discuss-discussInput-2626'
              onClick={handleSave}
            >
              <div className='new-component-discuss-discussInput-2631'>
                发布
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DiscussEditPageWrapper = Form.create<Props>()(DiscussEditPage);

export default DiscussEditPageWrapper;
