import gql from 'graphql-tag';

// 获取具体课程的第一层目录内容
export const GET_WHOLE_DIRECTORY = gql`
  query getWholeDirectory($id: ID!, $coursePackageId: ID!) {
    node(id: $id) {
      ... on CoursePackage {
        contents(first: 100) {
          edges {
            node {
              id
              name
              fileType
              parent {
                id
              }
              isLearned(coursePackageId: $coursePackageId)
              children(ignoreAns: true, coursePackageId: $coursePackageId) {
                id
                name
                fileType
                parent {
                  id
                }
                isLearned(coursePackageId: $coursePackageId)
                children(ignoreAns: true, coursePackageId: $coursePackageId) {
                  id
                  name
                  fileType
                  isLearned(coursePackageId: $coursePackageId)
                  parent {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// 获取具体课程的第一层目录和学习记录的卡片目录
export const GET_COURSE_DIRECTORY = gql`
  query getCourseDirectory($id: ID!) {
    node(id: $id) {
      ... on CoursePackage {
        id
        contents(first: 100) {
          edges {
            node {
              id
              name
              fileType
              isLearned(coursePackageId: $id)
              parent {
                id
              }
            }
          }
        }
        fileCardsCount
        learnedCount
        firstUnlearnedCard {
          ancestors(isMaster: true) {
            depth
            id
            name
            children(ignoreAns: true, coursePackageId: $id) {
              id
              name
              isLearned(coursePackageId: $id)
              fileType
              parent {
                id
              }
            }
          }
          id
          name
          isLearned(coursePackageId: $id)
          fileType
          parent {
            id
          }
        }
      }
    }
  }
`;

// 获取子目录
export const GET_SUB_DIRECTORY = gql`
  query getSubDirectory($id: ID!, $coursePackageId: ID!) {
    node(id: $id) {
      ... on Card {
        id
        name
        parent {
          id
        }
        fileType
        children(ignoreAns: true,coursePackageId:$coursePackageId) {
          id
          name
          fileType
          parent {
            id
          }
          isLearned(coursePackageId: $coursePackageId)
        }
      }
    }
  }
`;

// 获取下一层文件夹数据
export const GET_NEXT_FODLER_DATA = gql`
  query getNextFolderData($id: ID!, $coursePackageId: ID!) {
    node(id: $id) {
      ... on Card {
        id
        name
        parent {
          id
        }
        children(ignoreAns: true, coursePackageId: $coursePackageId) {
          id
          name
          fileType
          parent {
            id
          }
          isLearned(coursePackageId: $coursePackageId)
        }
      }
    }
  }
`;

// 查询单个卡片的内容
export const GET_SINGLE_CARD_CONTENT = gql`
  query getSingleCardContent($id: ID!, $coursePackageId: ID!, $courseId: ID!) {
    node(id: $id) {
      ... on Card {
        content
        name
        id
        isLearned(coursePackageId: $coursePackageId)
        isCollected(courseId: $courseId)
        learnedPeopleCount(coursePackageId: $coursePackageId)
        parent {
          id
        }
      }
    }
  }
`;

// 记录卡片的位置
// export const GET_RECORED_CARD_LOCATION = gql`
//   query getRecordCardLocation($coursePackageId: ID!) {
//     me {
//       lastCardLearningRecord(coursePackageId: $coursePackageId) {
//         cardId
//       }
//     }
//   }
// `;

// 获取记录卡片位置的祖先ID
export const GET_CARD_ANCESTOR = gql`
  query getCardAncestor($id: ID!) {
    node(id: $id) {
      ... on Card {
        id
      }
    }
  }
`;

// 获取顶层，单个章节的所有层级目录
export const GET_SINGLE_CHAPTER_Alll_DATA = gql`
  query getSingleChapterAllData($id: ID!) {
    node(id: $id) {
      ... on Card {
        id
        name
        children(ignoreAns: true) {
          id
          name
          children(ignoreAns: true) {
            id
            name
          }
        }
      }
    }
  }
`;

//解锁单张卡片
const UNLOCAK_SINGEL_CARD = gql`
  mutation getUnlockSingleCard($input: ViewCardInput!, $coursePackageId: ID!) {
    viewCard(input: $input) {
      learningRecord {
        card {
          id
          isLearned(coursePackageId: $coursePackageId)
        }
      }
    }
  }
`;

export const CREATE_CARD_EXAM = gql`
  mutation CreateCardExam($input: CreateCardExamInput!) {
    createCardExam(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
