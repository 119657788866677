/**

 * @file: 开智js

 * @author: sky

 *          qiuweiming@quseit.com

 * @update: 2015-04-29 00:02:45

 */
import React, { Component } from 'react';
import Input from 'antd/es/input/Input';
import Radio from 'antd/es/radio/radio';
import cross from '../../../assets/icons/cross.png';
import './styles.css';

class RadioLayout extends Component {
  constructor(props) {
    super(props);
    const value = props.value || {};
    this.state = {
      aw: value.aw || '',
      tf: value.tf || 'yes',
    };
  }

  componentWillReceiveProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      const value = nextProps.value;
      this.setState(value);
    }
  }

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(Object.assign({}, this.state, changedValue));
    }
  }

  handleAWChange = (aw) => {
    if (!('value' in this.props)) {
      this.setState({ aw });
    }
    this.triggerChange({ aw });
  }

  render() {
    const { aw } = this.state;
    const { radioKey, onDelete } = this.props;
    false && console.log(`key--->${radioKey}`);
    return (
      <div className="components-layout-RadioLayout-1194">
        <Input
          className="components-layout-RadioLayout-1270"
          value={aw} onChange={(e) => {
            this.handleAWChange(e.target.value);
          }}
        />
        <Radio value={radioKey} className="components-layout-RadioLayout-1480">正确选项</Radio>
        <img
          onClick={() => {
            onDelete();
          }} src={cross} className="components-layout-RadioLayout-1609"
        />
      </div>

    );
  }
}

export default RadioLayout;
