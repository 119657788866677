import * as React from 'react';
import './styles.css';

//引入的库组件
import { useApolloClient } from 'react-apollo';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

//graphql查询
import { useRefreshTokenMutation } from '../../generated/graphql';

//引入的其他页面组件
import headers from '../../global_variable/headers';
import HomePageHeader from '../HomePageHeader';
import Modal2 from '../CommonComponents/Modals/Modal2';
import Modal3 from '../CommonComponents/Modals/Modal3';
import Footer from '../Footer';
import timeLocalStorage from '../../utils/TimeLocalStorage';

//引入的资源图片

const GET_SHOW_HEADER = gql`
  {
    isShowHeader @client
    isShowFooter @client
    isLoggedIn @client
    refreshToken @client
  }
`;

const IndexPage = (props: any) => {
  const client = useApolloClient();
  const { data } = useQuery(GET_SHOW_HEADER);
  const { history } = props;

  const { refreshToken, isLoggedIn } = data;
  const [refreshTokenMutation] = useRefreshTokenMutation();

  React.useEffect(() => {
    if (refreshToken) {
      if (isLoggedIn) {
        if (timeLocalStorage.get('TOKENINFO')) {
          let value = JSON.parse(
            window.localStorage.getItem('TOKENINFO') as any
          );
          if (value && value.timeStorageTime) {
            let time = +new Date();
            let runtime = (value.timeStorageTime - time) / 1000;
            let day = Math.floor(runtime / 86400);
            if (day <= 5) {
              refreshTokenMutation({
                variables: { token: headers.Authorization.split('Bearer ')[1] }
              }).then((res: any) => {
                if (!res.data.refreshToken.errors) {
                  let session = res.data.refreshToken.session;
                  let token = session.token;
                  let userInfo = timeLocalStorage.get('USERINFO');
                  userInfo.token = token;
                  headers.Authorization = 'Bearer ' + token;
                  window.localStorage.setItem(
                    'USERINFO',
                    JSON.stringify(userInfo)
                  );
                  timeLocalStorage.set('TOKENINFO', token, 60 * 60 * 24 * 15);
                } else {
                  window.localStorage.setItem('LOGINSTATUS', '1');
                  window.localStorage.setItem('USERINFO', '');
                  client.writeData({ data: { isLoggedIn: false } });
                  history.push('/');
                }
              });
            }
          }
        } else {
          window.localStorage.setItem('LOGINSTATUS', '1');
          window.localStorage.setItem('USERINFO', '');
          client.writeData({ data: { isLoggedIn: false } });
          history.push('/');
        }
      }
      client.writeData({ data: { refreshToken: false } });
    }
  }, [refreshToken]);

  if (
    !data.isLoggedIn &&
    (window.location.pathname.match('/edu') ||
      window.location.pathname.match('/stu'))
  ) {
    const { pathname } = history.location;
    client.writeData({
      data: {
        isShowLoginModal: true,
        routeFrom: pathname
      }
    });
    history.push('/');
  }
  return (
    <div style={{ height: '100%' }}>
      {data.isShowHeader ? <HomePageHeader props={props} /> : null}
      <Modal2 />
      <Modal3 />
      {props.children}
      {data.isShowFooter && !window.location.pathname.match('/edu') ? (
        <Footer />
      ) : null}
    </div>
  );
};

export default withRouter(IndexPage);
