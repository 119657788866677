/**
 * @description 发布评论
 * @author 阿礼
 * @since 2019-9-14
 */

import React, { useState, useEffect } from 'react';
import './style.less';

// 第三方库
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import { Input, Button } from 'antd';
import gql from 'graphql-tag';
import MDEditor_v3 from '../../../CommonComponents/MDEditor/MDEditor_v3';

// addCommentToSingleAssingment
import { useAddCommentToSingleAssignmentMutation } from '../../../../generated/graphql';
import { ADD_COMMENT_TO_ASSIGNMENT } from './../query.ts';

const GET_COMMENT_COUNT = gql`
  {
    assignmentCommentsCount @client
    assignmentId @client
    coursePackageAssignmentId @client
    questionId @client
    comments @client
    otherAnswers @client
    answerId @client(always: true)
  }
`;

const { TextArea } = Input;

const PostComment = props => {
  const { setUpdate, setinitialValue, update } = props;
  const client = new useApolloClient();
  const {
    data: {
      assignmentCommentsCount,
      assignmentId,
      coursePackageAssignmentId,
      questionId,
      answerId,
      comments,
      otherAnswers
    },
    error,
    loading
  } = useQuery(GET_COMMENT_COUNT);

  // const [postComment] = usePostAssignmentCommentMutation({
  //   skip: true
  // });
  const [postComment] = useAddCommentToSingleAssignmentMutation({ skip: true });

  const [isSwitch, setSwitch] = useState(true);

  const [value, setValue] = useState({ content: { markdown: '' } });
  useEffect(() => {
    setSwitch(true);
  }, [assignmentId]);

  if (loading) {
    return null;
  }

  return (
    <div className='post-comment'>
      <div className='comment-counts'>
        {comments.length > 0 ? comments.length : 0}条评论
      </div>
      {isSwitch ? (
        <TextArea
          row={1}
          // defaultValue='说出你的评论'
          placeholder='说出你的评论'
          value={value.content.markdown}
          onChange={e => {
            const markdown = { content: { markdown: e.target.value } };
            setValue(markdown);
          }}
        />
      ) : (
        <div className='new-routes-study-courseHomework-3440'>
          {
            <MDEditor_v3
              // extraButton={() => {
              //   return isEditor ? <div>取消编辑</div> : null;
              // }}

              contentValue={value}
              isBottom
              width='100%'
              height={340}
              onSubmit={() => {
                // const cardId = window.atob(questionId).split('Assignment:')[1];

                const coursePackageId = window
                  .atob(coursePackageAssignmentId)
                  .split('CoursePackage:')[1];

                const myAnswerId = window
                  .atob(answerId)
                  .split('Assignment:')[1];
                // setinitialValue({ content: { markdown: '' } });
                // setUpdate(false);
                postComment({
                  variables: {
                    input: {
                      assignmentId: myAnswerId,
                      coursePackageId: Number(coursePackageId),
                      comment: {
                        content: value.content.markdown,
                        // cardId: myAnswerId,
                        atUserIds: []
                      }
                    }
                  }
                })
                  .then(res => {
                    const edges =
                      res.data.addCommentToAssignment.assignment.comments.edges;
                    const comments = edges.map(item => item.node);
                    // 同步更新对应目录的评论数
                    otherAnswers.map(item => {
                      if (item.id === answerId) {
                        item.commentsCount = comments.length;
                      }
                    });
                    client.writeQuery({
                      query: GET_COMMENT_COUNT,
                      data: { comments: comments, otherAnswers }
                    });
                    // setValue({ content: { markdown: '' } });

                    // setUpdate(true);
                  })
                  .catch(res => {
                    // setValue({ content: { markdown: '' } });
                    // setUpdate(true);
                  });
                setValue({ content: { markdown: '' } });
                setSwitch(true);
              }}
              onChange={content => {
                setValue(content);
              }}
              butTitle={'发布评论'}
              minHeight={'240px'}
              pb={48}
            />
          }
        </div>
      )}

      <div className='switch-and-post'>
        <Button
          onClick={() => {
            setSwitch(!isSwitch);
            // setValue({ content: { markdown: answerContent } });
          }}
        >
          {isSwitch ? '切换markdown编辑器' : '切换普通输入框'}
        </Button>
        {isSwitch && (
          <span
            className='post'
            onClick={() => {
              // const cardId = window.atob(questionId).split('Assignment:')[1];
              const coursePackageId = window
                .atob(coursePackageAssignmentId)
                .split('CoursePackage:')[1];

              const myAnswerId = window.atob(answerId).split('Assignment:')[1];

              postComment({
                variables: {
                  input: {
                    assignmentId: myAnswerId,
                    coursePackageId: Number(coursePackageId),
                    comment: {
                      content: value.content.markdown,
                      // cardId: myAnswerId,
                      atUserIds: []
                    }
                  }
                }
              }).then(res => {
                const edges =
                  res.data.addCommentToAssignment.assignment.comments.edges;
                const comments = edges.map(item => item.node);

                // 同步更新对应目录的评论数
                otherAnswers.map(item => {
                  if (item.id === answerId) {
                    item.commentsCount = comments.length;
                  }
                });
                client.writeQuery({
                  query: GET_COMMENT_COUNT,
                  data: {
                    comments: comments,
                    assignmentCommentsCount: comments.length,
                    otherAnswers
                  }
                });
              });
              setValue({ content: { markdown: '' } });
            }}
          >
            发布
          </span>
        )}
      </div>
    </div>
  );
};

export default PostComment;
