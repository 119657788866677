import * as React from 'react';
import './styles.css';

//引入的库组件
import { FormComponentProps } from 'antd/es/form';
import { Form, Layout, Button, Breadcrumb, List, Input, message } from 'antd';
import json2md from 'json2md';
import RadioGroup from 'antd/es/radio/group';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import itemRender from '../CommonComponents/ItemRender';
import MDEditor_v2 from '../CommonComponents/MDEditor/MDEditor_v2';
import CheckboxLayout from '../CommonComponents/layout/CheckboxLayout';
import RadioLayout from '../CommonComponents/layout/RadioLayout';
import { checkAnswerItem } from '../../utils/checkForm';
import { getTextCardContent, getVideoCardContent, getExamCardContent } from '../../utils';
import config from '../../config';
import headers from '../../global_variable/headers';

//graphql查询
import { GetCardsForModifyQuery, useModifyCardsMutation } from '../../generated/graphql';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

//引入的资源图片
import backImg from '../../assets/icons/back.png';

interface Props extends FormComponentProps {
  props: any;
  cardsInfo: GetCardsForModifyQuery | null;
}

const GET_MODIFY_CARDS_INFO = gql`
  {
    pMode @client
    isEditCard @client(always: true)
    selectedFileId @client
    selectedFilePackageId @client
  }
`;

const titleLayout = {
  wrapperCol: { span: 20, offset: 0 },
  labelCol: { span: 2, offset: 1 }
};
const answerLayout = {
  wrapperCol: { span: 20, offset: 3 },
  labelCol: { span: 2, offset: 1 }
};
const mdLayout = { wrapperCol: { span: 22, offset: 1 }, labelCol: { span: 1 } };
const topTitleLayout = {
  wrapperCol: { span: 22, offset: 1 },
  labelCol: { span: 1 }
};

const ModifyMultiCard = (props: any) => {
  const { data: modifyCardsInfo } = useQuery(GET_MODIFY_CARDS_INFO);
  const { pMode, selectedFilePackageId } = modifyCardsInfo;
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [modifyCardsMutation] = useModifyCardsMutation();

  const { form, cardsInfo: cardsInfoData } = props;

  const cardsInfo = cardsInfoData.cards ? cardsInfoData.cards : [];

  const routes = () => {
    return [
      {
        path: '//edu',
        breadcrumbName: '全部文件'
      },
      {
        path: '',
        breadcrumbName: pMode ? '我创建的' : '我协作的'
      },
      {
        path: '',
        breadcrumbName: '批量修改卡片'
      }
    ];
  };

  const [encryptedUrl, setEncryptedUrl] = React.useState({} as any);

  const handleEdit = () => {
    // const { form, dispatch, multiEditCardData, pMode } = this.props;
    form.validateFieldsAndScroll((errors: any, values: any) => {
      if (errors) {
        return;
      }
      const editContent = cardsInfo
        .filter((value: any, index: any) => {
          return typeof values[`title${[index]}`] !== 'undefined';
        })
        .map((value: any, index: any) => {
          let type = 0;
          if(value.name.indexOf(".md") !== -1) {
            type = 0 ;

          }else if(value.name.indexOf(".video") !== -1) {
            type = 1 ;
          }else if(value.name.indexOf(".exam") !== -1) {
            type = 2 ;
          }else if(value.name.indexOf(".audio") !== -1) {
            type = 5 ;
          }

          switch (type) {
            case 0:
              if (typeof values[`title${[index]}`] === 'undefined') {
                return;
              }
              const textMdContent = `---\ntitle:${
                values[`title${index}`]
              }\nsign:default\n---\n# front\n${
                values[`front${index}`].content.markdown
              }\n# back\n${values[`back${index}`].content.markdown}`;
              return { key: value.id, content: textMdContent };
            case 1:
              if (typeof values[`title${[index]}`] === 'undefined') {
                return;
              }
              const videoMdContent = `---\r\ntitle:${values[`title${[index]}`] +
                index}\nsign:default\n---\n${json2md([
                { h1: 'front' }
              ])}video\`\`\`url:${values[`url${index}`]}\n\`\`\`\n# back\n${
                values[`back${index}`].content.markdown
              }`;
              return { key: value.id, content: videoMdContent };
            case 2:
              if (typeof values[`title${[index]}`] === 'undefined') {
                return;
              }
              const mdContent = mapChoice2Json(getExamCardContent(value.sourceContent), index, values);
              const examMdContent = `---\r\ntitle:${
                values[`title${index}`]
              }\r\nsign:default\r\n---\r\n${json2md(
                [{ h1: 'front' }].concat(mdContent)
              )}# back\n${values[`back${index}`].content.markdown}`;
              return { key: value.id, content: examMdContent };
            case 5:
              if (typeof values[`title${[index]}`] === 'undefined') {
                return;
              }
              const audioMdContent = `---\r\ntitle:${values[`title${[index]}`] +
                index}\nsign:default\n---\n${json2md([
                { h1: 'front' }
              ])}audio\`\`\`url:${values[`url${index}`]}\n\`\`\`\n# back\n${
                values[`back${index}`].content.markdown
              }`;
              return { key: value.id, content: audioMdContent };
            default:
              break;
          }
        });
      setLoading(true);

      let input : any = {
        contents: editContent.map((item: any) => {return item.content}),
        ids: editContent.map((item: any) => {return Base64.decode(item.key).split("Card:")[1]}),
        packageId: Base64.decode(selectedFilePackageId).split("Package:")[1]
      };

      modifyCardsMutation({variables: {input : input}}).then((res: any) => {
        if (res.data.modifyCards.errors) {
          message.error(res.data.modifyCards.errors[0].message);
          return;
        } else {
          message.success('修改成功!');
          props.props.history.goBack();
        }
      });
    });
  };

  const mapChoice2Json = (value: any, index: any, values: any) => {
    return value.front.exam.map((value1: any) => {
      return [
        { p: value1.is_single === 'true' ? '```SQ\r\n' : '```MQ\r\n' },
        {
          p: `Q: ${
            values[value1.is_single === 'true' ? value1.sq[0] : value1.mq[0]]
          }\r\n`
        },
        {
          p: value1.asw.map((value2: any) => {
            if (value1.is_single === 'true') {
              if (values[`r${value1.sq[0]}`] === values[value2].aw) {
                return `ASWT:${values[value2].aw}\r\n`;
              } else {
                return `ASWF:${values[value2].aw}\r\n`;
              }
            } else if (values[value2].tf) {
              return `ASWT:${values[value2].aw}\r\n`;
            } else {
              return `ASWF:${values[value2].aw}\r\n`;
            }
          })
        },
        { p: '```' }
      ];
    });
  };

  const fetchApi = (url: any, options: any, key: string) => {
    const fetchAPI = new Promise((resolve, reject) => {
      fetch(url, options).then((res: any) => {
        res.text().then((text: any) => {
          setEncryptedUrl(
            {
              [key]: text ? text : '',
              ...encryptedUrl,
            }
          );
          resolve(text);
        });
      });
    });
    return Promise.race([
      fetchAPI,
      new Promise((resolve, reject) => {
        setTimeout(() => {
          reject('time out');
        }, 100000);
      })
    ]);
  };

  async function getEncryptedUrl(url: any) {
    let fetchUrl = config.qiniuUri + 't/api/encrypted_url?default_url=' + url;
    let options = { method: 'GET', headers: headers };
    await fetchApi(fetchUrl, options, url);
  }

  const { getFieldDecorator, setFieldsValue } = form;

  return (
    <Layout className='edu-EditMultiCard-4786'>
      <div className='edu-EditMultiCard-4795'>
        <div className='edu-EditMultiCard-5187'>
          <div className='edu-EditMultiCard-5242'>
            <div className='edu-EditMultiCard-5356'>
              <div
                onClick={() => {
                  props.props.history.goBack();
                }}
                className='edu-EditMultiCard-5498'
              >
                <img src={backImg} />
                <span className='edu-EditMultiCard-5607'>返回</span>
                <div className='edu-EditMultiCard-5680' />
              </div>
              <Breadcrumb
                separator={'›'}
                itemRender={itemRender}
                routes={routes()}
                className='edu-EditMultiCard-5866'
              />
            </div>
            <div className='edu-EditMultiCard-5928'>
              <Button
                loading={loading}
                type="danger"
                className='edu-EditMultiCard-6017'
                onClick={handleEdit}
              >
                批量修改
              </Button>
            </div>
          </div>
          <Form>
            {cardsInfo.slice(0, 5 * page).map((value: any, index: any) => {
              // const { back, type, title } = value;

              let title: string = "";
              let front: any = "";
              let back: string = "";
              let content: any = {};
              let type = -1;
              if(value.name.indexOf(".md") !== -1) {
                type = 0 ;
                content = getTextCardContent(value.sourceContent);

              }else if(value.name.indexOf(".video") !== -1) {
                type = 1 ;
                content = getVideoCardContent(value.sourceContent);
              }else if(value.name.indexOf(".exam") !== -1) {
                type = 2 ;
                content = getExamCardContent(value.sourceContent);
              }else if(value.name.indexOf(".audio") !== -1) {
                type = 5 ;
                content = getVideoCardContent(value.sourceContent);
              }

              title = content.title;
              front = content.front;
              back = content.back;

              // const editBack = back.substr(1);
              const editBack = back;
              switch (type) {
                case 0:
                  // const front = value.front.content.substr(1);
                  // const editFront = front.substring(0, front.length - 1);
                  const editFront = front;
                  return (
                    <div className='edu-EditMultiCard-6591'>
                      <div className='edu-EditMultiCard-6643'>
                        卡片{index + 1}
                      </div>
                      <div className='edu-EditMultiCard-6750' />
                      <Form.Item
                        {...topTitleLayout}
                        label='标题'
                        hasFeedback
                        className='edu-EditMultiCard-6892'
                      >
                        {getFieldDecorator(`title${index}`, {
                          initialValue: title,
                          rules: [{ required: true, message: '请输入卡片标题' }]
                        })(<Input className='edu-EditMultiCard-7081' />)}
                      </Form.Item>
                      <Form.Item
                        {...mdLayout}
                        label='正面'
                        className='edu-EditMultiCard-7198'
                      >
                        {getFieldDecorator(`front${index}`, {
                          initialValue: { content: { markdown: editFront } },
                          rules: [{ required: true }]
                        })(
                          <MDEditor_v2
                            isBottom={false}
                            butTitle={''}
                            loading={false}
                            onSubmit={() => {}}
                            height={240}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        {...mdLayout}
                        label='背面'
                        className='edu-EditMultiCard-7663'
                      >
                        {getFieldDecorator(`back${index}`, {
                          initialValue: { content: { markdown: editBack } },
                          rules: []
                        })(
                          <MDEditor_v2
                            isBottom={false}
                            butTitle={''}
                            loading={false}
                            onSubmit={() => {}}
                            height={240}
                          />
                        )}
                      </Form.Item>
                    </div>
                  );

                case 1: {
                  let url = front;
                  let encryptedUrlTemp = encryptedUrl[url];
                  if(typeof encryptedUrlTemp === 'undefined' ) {
                    encryptedUrlTemp = '';
                    getEncryptedUrl(url);
                  }
                    
                  return (
                    <div className='edu-EditMultiCard-8353'>
                      <div className='edu-EditMultiCard-8405'>
                        卡片{index + 1}
                      </div>
                      <div className='edu-EditMultiCard-8512' />
                      <Form.Item
                        {...topTitleLayout}
                        label='标题'
                        hasFeedback
                        className='edu-EditMultiCard-8654'
                      >
                        {getFieldDecorator(`title${index}`, {
                          initialValue: title,
                          rules: [{ required: true, message: '请输入卡片标题' }]
                        })(<Input className='edu-EditMultiCard-8849' />)}
                      </Form.Item>
                      <Form.Item
                        {...mdLayout}
                        label='视频链接'
                        hasFeedback
                        className='edu-EditMultiCard-8980'
                      >
                        {getFieldDecorator(`url${index}`, {
                          initialValue: url,
                          rules: []
                        })(<Input className='edu-EditMultiCard-9156' />)}
                      </Form.Item>
                      {// encryptedUrl !== ''  ? <div style={{marginTop:20}}>加密视频链接: {encryptedUrl}</div> : null
                      encryptedUrlTemp   ? (
                        <Form.Item
                          colon={false}
                          {...mdLayout}
                          label='加密链接'
                          className='edu-cardPackage-VideoCard-5915'
                        >
                          {getFieldDecorator('encryptedUrl', {
                            initialValue: encryptedUrlTemp,
                            rules: [{ required: false }]
                          })(
                            <Input
                              disabled
                              className='edu-cardPackage-VideoCard-6044'
                            />
                          )}
                        </Form.Item>
                      ) : null}
                      <Form.Item
                        {...mdLayout}
                        label='背面'
                        className='edu-EditMultiCard-9336'
                      >
                        {getFieldDecorator(`back${index}`, {
                          initialValue: { content: { markdown: editBack } },
                          rules: []
                        })(
                          <MDEditor_v2
                            isBottom={false}
                            butTitle={''}
                            loading={false}
                            onSubmit={() => {}}
                            height={240}
                          />
                        )}
                      </Form.Item>
                    </div>
                  );
                }

                // eslint-disable-next-line no-case-declarations
                case 2:
                  let exam = [];
                  try {
                    // exam = value.front.exam;
                    exam = front.exam;
                    // eslint-disable-next-line no-empty
                  } catch (error) {}
                  return (
                    <div className='edu-EditMultiCard-10095'>
                      <div className='edu-EditMultiCard-10147'>
                        卡片{index + 1}
                      </div>
                      <div className='edu-EditMultiCard-10254' />
                      <Form.Item
                        {...topTitleLayout}
                        label='标题'
                        hasFeedback
                        className='edu-EditMultiCard-10396'
                      >
                        {getFieldDecorator(`title${index}`, {
                          initialValue: title,
                          // initialValue: value.title,
                          rules: [{ required: true, message: '请输入卡片标题' }]
                        })(<Input className='edu-EditMultiCard-10591' />)}
                      </Form.Item>
                      <div className='edu-EditMultiCard-10677'>
                        {exam.map((value1: any, index1: any) => {
                          return (
                            <div>
                              <Form.Item
                                {...titleLayout}
                                label='题目'
                                hasFeedback
                                className='edu-EditMultiCard-10977'
                              >
                                {getFieldDecorator(
                                  value1.is_single === 'true'
                                    ? value1.sq[0]
                                    : value1.mq[0],
                                  {
                                    initialValue:
                                      value1.is_single === 'true'
                                        ? value1.sq[0]
                                        : value1.mq[0],
                                    rules: [
                                      { required: true, message: '请输入题目' }
                                    ]
                                  }
                                )(
                                  <Input className='edu-EditMultiCard-11306' />
                                )}
                              </Form.Item>
                              {value1.is_single === 'true' ? (
                                <Form.Item>
                                  {getFieldDecorator(
                                    `r${
                                      value1.is_single === 'true'
                                        ? value1.sq[0]
                                        : value1.mq[0]
                                    }`,
                                    { initialValue: value1.aswt[0] }
                                  )(
                                    <RadioGroup
                                      className='edu-EditMultiCard-11739'
                                      onChange={e => {}}
                                    >
                                      <List
                                        className='edu-EditMultiCard-11976'
                                        dataSource={value1.asw}
                                        renderItem={item1 => (
                                          <Form.Item
                                            {...answerLayout}
                                            className='edu-EditMultiCard-12209'
                                          >
                                            {getFieldDecorator(item1, {
                                              initialValue: {
                                                aw: item1,
                                                tf: true
                                              },
                                              rules: [
                                                {
                                                  validator: checkAnswerItem,
                                                  message: '请输入答案'
                                                }
                                              ]
                                            })(
                                              <RadioLayout
                                                onDelete={() => {}}
                                                radioKey={item1}
                                              />
                                            )}
                                          </Form.Item>
                                        )}
                                      />
                                    </RadioGroup>
                                  )}
                                </Form.Item>
                              ) : (
                                <List
                                  className='edu-EditMultiCard-13037'
                                  dataSource={value1.asw}
                                  renderItem={item1 => (
                                    <Form.Item
                                      {...answerLayout}
                                      className='edu-EditMultiCard-13258'
                                    >
                                      {getFieldDecorator(item1, {
                                        initialValue: {
                                          aw: item1,
                                          tf: value1.aswt.indexOf(item1) !== -1
                                        },
                                        rules: []
                                      })(
                                        <CheckboxLayout checkboxKey={item1} />
                                      )}
                                    </Form.Item>
                                  )}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <Form.Item
                        {...mdLayout}
                        label='背面'
                        className='edu-EditMultiCard-14071'
                      >
                        {getFieldDecorator(`back${index}`, {
                          initialValue: { content: { markdown: back } },
                          rules: []
                        })(
                          <MDEditor_v2
                            isBottom={false}
                            butTitle={''}
                            loading={false}
                            onSubmit={() => {}}
                            height={240}
                          />
                        )}
                      </Form.Item>
                    </div>
                  );
                case 5: {
                  let url = front;
                  let encryptedUrl = '';
                  return (
                    <div className='edu-EditMultiCard-8353'>
                      <div className='edu-EditMultiCard-8405'>
                        卡片{index + 1}
                      </div>
                      <div className='edu-EditMultiCard-8512' />
                      <Form.Item
                        {...topTitleLayout}
                        label='标题'
                        hasFeedback
                        className='edu-EditMultiCard-8654'
                      >
                        {getFieldDecorator(`title${index}`, {
                          initialValue: title,
                          rules: [{ required: true, message: '请输入卡片标题' }]
                        })(<Input className='edu-EditMultiCard-8849' />)}
                      </Form.Item>
                      <Form.Item
                        {...mdLayout}
                        label='音频链接'
                        hasFeedback
                        className='edu-EditMultiCard-8980'
                      >
                        {getFieldDecorator(`url${index}`, {
                          initialValue: url,
                          rules: []
                        })(<Input className='edu-EditMultiCard-9156' />)}
                      </Form.Item>
                      {// encryptedUrl !== ''  ? <div style={{marginTop:20}}>加密视频链接: {encryptedUrl}</div> : null
                      encryptedUrl !== '' ? (
                        <Form.Item
                          colon={false}
                          {...mdLayout}
                          label='加密链接'
                          className='edu-cardPackage-AudioCard-5915'
                        >
                          {getFieldDecorator('encryptedUrl', {
                            initialValue: encryptedUrl,
                            rules: [{ required: false }]
                          })(
                            <Input
                              disabled
                              className='edu-cardPackage-AudioCard-6044'
                            />
                          )}
                        </Form.Item>
                      ) : null}
                      <Form.Item
                        {...mdLayout}
                        label='背面'
                        className='edu-EditMultiCard-9336'
                      >
                        {getFieldDecorator(`back${index}`, {
                          initialValue: { content: { markdown: editBack } },
                          rules: []
                        })(
                          <MDEditor_v2
                            isBottom={false}
                            butTitle={''}
                            loading={false}
                            onSubmit={() => {}}
                            height={240}
                          />
                        )}
                      </Form.Item>
                    </div>
                  );
                }

                default:
                  break;
              }
            })}
          </Form>
          {Math.ceil(cardsInfo.length / 5) > 1 &&
          Math.ceil(cardsInfo.length / 5) !== page ? (
            <div className='edu-EditMultiCard-14695'>
              <Button
                onClick={() => {
                  setPage(page + 1);
                }}
                className='edu-EditMultiCard-14929'
              >
                查看更多
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

const CourseEditPageWrapper = Form.create<Props>()(ModifyMultiCard);

export default CourseEditPageWrapper;
