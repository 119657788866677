import * as React from 'react';
import './styles.css';

//引入的库组件
import { Spin, Form, Layout, Collapse, message } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { withRouter } from 'react-router';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import LoadingView from '../CommonComponents/LoadingView';
import EduManagerPackagePageToolbar from './EduManagerPackagePageToolbar';
import EduManagerPackagePageContent from './EduManagerPackagePageContent';
import EduManagerPackagePageRightClickCard from './EduManagerPackagePageRightClickCard';
import EduManagerPackagePageRightClickScreen from './EduManagerPackagePageRightClickScreen';
import EduManagerPackagePageRightClickPackage from './EduManagerPackagePageRightClickPackage';
import {
  findPackageId,
  addCardDataToPackage,
  addPackageDataToTreeData,
  addCardsToPackageFromResponsePackage,
  addCardsToCardFromResponseCard,
  deleteDataFromTreeData,
  renameCardFromTreeData
} from './utils';
import CreateModalCallback from '../CreateModal/CreateModalCallbackFunc';

//graphql查询
import {
  useDeleteCardMutation,
  useDeleteCardFromAllBranchesMutation,
  useUploadCardMutation,
  useUploadZipMutation,
  useUploadZipToPackageRootMutation,
  useDeletePackageMutation,
  useRenameCardMutation,
  useRenamePackageMutation,
  useDeleteCardsMutation,
  useSubmitPullRequestMutation,
  useDiffChangesQuery,
  DiffType
} from '../../generated/graphql';

//引入的资源图片
import loading from '../../assets/icons/loading.png';
import negative from '../../assets/icons/negative.png';

interface Props extends FormComponentProps {
  props: any;
}

const GET_CREATE_PACKAGE_INFO = gql`
  {
    hanStatus @client {
      handing
      tip
    }
    screenful @client
    rightClick @client
    pMode @client
    errorCards @client
    selectedFileId @client
    showFiles @client
    cardPaging @client {
      page
      count
    }
    searchMode @client
    treeData @client
    cooperationTreeData @client
  }
`;

const GET_FAIL_DATAS = gql`
  {
    errorCards @client
  }
`;

const EduManagerPackagePage = (props: any) => {
  const client = useApolloClient();
  const { data: packageInfoData } = useQuery(GET_CREATE_PACKAGE_INFO);
  const [deleteCard] = useDeleteCardMutation();
  const [deleteCardFromAllBranches] = useDeleteCardFromAllBranchesMutation();
  const [uploadCard] = useUploadCardMutation();
  const [uploadZip] = useUploadZipMutation();
  const [uploadZipToPackage] = useUploadZipToPackageRootMutation();
  const [deletePackageMutation] = useDeletePackageMutation();
  const [renameCardMutation] = useRenameCardMutation();
  const [renamePackageMutation] = useRenamePackageMutation();
  const [deleteCardsMutation] = useDeleteCardsMutation();
  const [submitPullRequestMutation] = useSubmitPullRequestMutation();
  const { refetch: diffQuery } = useDiffChangesQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });
  const {
    hanStatus,
    screenful,
    rightClick,
    pMode,
    errorCards,
    selectedFileId,
    treeData,
    cooperationTreeData
  } = packageInfoData;

  const [mode, setMode] = React.useState(false);
  const [searchContent, setSearchContent] = React.useState('');
  const [isPackage, setIsPackage] = React.useState(false);
  const [isFile, setIsFile] = React.useState(true);
  const [isCoo, setIsCoo] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [sortFiles, setSortFiles] = React.useState([]);
  const [isRefresh, setIsRefresh] = React.useState(false);

  const uploadZipFunc = (file: any, ile_list: any[]) => {
    if (selectedFileId === '') {
      message.warning('请先选择卡包');
      return false;
    }

    const suffix = file.name.substring(
      file.name.lastIndexOf('.') + 1,
      file.name.length
    );
    const isZip = suffix === 'zip';
    if (!isZip) {
      message.error('仅支持上传zip格式文件!');
      return false;
    }

    let packageId = findPackageId(
      pMode ? treeData : cooperationTreeData,
      selectedFileId
    );
    let selectedFileIdOri =
      packageId === selectedFileId
        ? null
        : Base64.decode(selectedFileId).split('Card:')[1];
    let packageIdOri = Base64.decode(packageId).split('Package:')[1];
    let input: any = {};
    if (selectedFileIdOri) {
      input = {
        folderId: selectedFileIdOri,
        packageId: packageIdOri
      };
    } else {
      input = {
        packageId: packageIdOri
      };
    }

    client.writeData({
      data: {
        hanStatus: { handing: true, tip: '正在上传', __typename: 'HanStatus' }
      }
    });

    if (selectedFileIdOri) {
      uploadZip({
        variables: { input: input, metadata: file, isMaster: pMode }
      })
        .then((res: any) => {
          client.writeData({
            data: {
              hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
            }
          });

          if (res.data.uploadZip.errors) {
            message.error(res.data.uploadZip.errors[0].message);
          } else {
            let failedFiles = res.data.uploadZip.failedFiles;
            // client.writeData({data: { errorCards: failedFiles }});
            client.writeQuery({
              query: GET_FAIL_DATAS,
              data: {
                errorCards: failedFiles
              }
            });

            let folder = res.data.uploadZip.folder;

            // replaceDataFromTreeData(
            //   pMode ? treeData : cooperationTreeData,
            //   folder,
            //   pMode,
            //   'TreeData'
            // );
            addCardsToCardFromResponseCard(
              pMode ? treeData : cooperationTreeData,
              folder,
              pMode,
              'TreeData'
            );
            client.writeQuery({
              query: GET_CREATE_PACKAGE_INFO,
              data: {
                searchMode: false,
                treeData: treeData,
                cooperationData: cooperationTreeData
              }
            });
            if (pMode) {
              getShowFiles(
                typeof treeData === 'undefined' ? [] : treeData,
                [selectedFileId],
                1
              );
            } else {
              getShowFiles(
                typeof cooperationTreeData === 'undefined'
                  ? []
                  : cooperationTreeData,
                [selectedFileId],
                1
              );
            }
            message.success('文件上传完毕!');
          }
        })
        .catch((res: any) => {
          client.writeData({
            data: {
              hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
            }
          });
          message.error('文件上传失败!');
        });
    } else {
      uploadZipToPackage({
        variables: { input: input, metadata: file, isMaster: pMode }
      }).then((res: any) => {
        client.writeData({
          data: {
            hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
          }
        });
        if (res.data.uploadZip.errors) {
          message.error(res.data.uploadZip.errors[0].message);
        } else {
          let failedFiles = res.data.uploadZip.failedFiles;
          // client.writeData({data: { errorCards: failedFiles }});
          client.writeQuery({
            query: GET_FAIL_DATAS,
            data: {
              errorCards: failedFiles
            }
          });

          let packageData = res.data.uploadZip.package;

          addCardsToPackageFromResponsePackage(
            pMode ? treeData : cooperationTreeData,
            packageData,
            pMode,
            'TreeData'
          );

          client.writeQuery({
            query: GET_CREATE_PACKAGE_INFO,
            data: {
              searchMode: false,
              treeData: treeData,
              cooperationData: cooperationTreeData
            }
          });

          if (pMode) {
            getShowFiles(
              typeof treeData === 'undefined' ? [] : treeData,
              [selectedFileId],
              1
            );
          } else {
            getShowFiles(
              typeof cooperationTreeData === 'undefined'
                ? []
                : cooperationTreeData,
              [selectedFileId],
              1
            );
          }
          message.success('文件上传完毕!');
        }
      });
    }

    return false;
  };

  const uploadCardFunc = (file: any, file_list: any[]) => {
    if (selectedFileId === '') {
      message.warning('请先选择卡包');
      return false;
    }
    const suffix = file.name.substring(
      file.name.lastIndexOf('.') + 1,
      file.name.length
    );
    const isMD = suffix === 'md';
    const isVideo = suffix === 'video';
    const isExam = suffix === 'exam';
    const isAudio = suffix === 'audio';
    if (!(isMD || isVideo || isExam || isAudio)) {
      message.error('仅支持上传md、video、exam、audio格式文件!');
      return false;
    }

    let packageId = findPackageId(
      pMode ? treeData : cooperationTreeData,
      selectedFileId
    );
    let selectedFileIdOri =
      packageId === selectedFileId
        ? null
        : Base64.decode(selectedFileId).split('Card:')[1];
    let packageIdOri = Base64.decode(packageId).split('Package:')[1];
    let input: any = {};
    if (selectedFileIdOri) {
      input = {
        folderId: selectedFileIdOri,
        packageId: packageIdOri
      };
    } else {
      input = {
        packageId: packageIdOri
      };
    }

    client.writeData({
      data: {
        hanStatus: { handing: true, tip: '正在上传', __typename: 'HanStatus' }
      }
    });

    uploadCard({ variables: { input: input, metadata: file, isMaster: pMode } })
      .then((res: any) => {
        client.writeData({
          data: {
            hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
          }
        });
        if (res.data.uploadCard.errors) {
          message.error(res.data.uploadCard.errors.message);
        } else {
          let cardData = res.data.uploadCard.card;
          addCardDataToPackage(
            pMode ? treeData : cooperationTreeData,
            cardData,
            pMode,
            'TreeData'
          );
          client.writeQuery({
            query: GET_CREATE_PACKAGE_INFO,
            data: {
              searchMode: false,
              treeData: treeData,
              cooperationData: cooperationTreeData
            }
          });
          if (pMode) {
            getShowFiles(
              typeof treeData === 'undefined' ? [] : treeData,
              [selectedFileId],
              1
            );
          } else {
            getShowFiles(
              typeof cooperationTreeData === 'undefined'
                ? []
                : cooperationTreeData,
              [selectedFileId],
              1
            );
          }
          message.success('文件上传成功!');
        }
      })
      .catch((res: any) => {
        client.writeData({
          data: {
            hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
          }
        });
        message.error('文件上传失败!');
      });
    return false;
  };

  const getShowFiles = (treeData: any, selectKeys: string[], page: number) => {
    return treeData.map((item: any) => {
      if (item.children) {
        if (item.key === selectKeys[0]) {
          while ((page - 1) * 24 >= item.children.length) {
            page = page - 1;
          }
          let showFilesTemp = item.children.slice((page - 1) * 24, page * 24);
          client.writeQuery({
            query: GET_CREATE_PACKAGE_INFO,
            data: {
              showFiles: showFilesTemp,
              cardPaging: {
                page: Math.ceil(item.children.length / 24),
                count: item.children.length,
                __typename: 'CardPaging'
              }
            }
          });
          setPage(page);
          return item.children;
        }
        getShowFiles(item.children, selectKeys, page);
      } else {
        false && console.log('omc');
      }
      return null;
    });
  };

  const getAllShowFiles = (treeData: any, selectedFileId: string) => {
    return treeData.forEach((item: any) => {
      if (item.children) {
        if (item.key == selectedFileId) {
          client.writeQuery({
            query: GET_CREATE_PACKAGE_INFO,
            data: {
              showFiles: item.children,
              cardPaging: {
                page: 1,
                count: item.children.length,
                __typename: 'CardPaging'
              }
            }
          });
        }
        getAllShowFiles(item.children, selectedFileId);
      } else {
        false && console.log('omc');
      }
    });
  };

  const onCreate = (type: string) => {
    switch (type) {
      case '0':
        CreateModalCallback.callback = (res: any) => {
          addPackageDataToTreeData(
            pMode ? treeData : cooperationTreeData,
            res,
            pMode,
            'TreeData'
          );
          if (pMode) {
            getShowFiles(
              typeof treeData === 'undefined' ? [] : treeData,
              [res.id],
              1
            );
          } else {
            getShowFiles(
              typeof cooperationTreeData === 'undefined'
                ? []
                : cooperationTreeData,
              [res.id],
              1
            );
          }
          client.writeQuery({
            query: GET_CREATE_PACKAGE_INFO,
            data: {
              searchMode: false,
              treeData: treeData,
              cooperationTreeData: cooperationTreeData,
              selectedFileId: res.id
            }
          });
        };
        client.writeData({
          data: { isShowCreateModal: true, isCreateFolder: false }
        });
        break;
      case '1':
        CreateModalCallback.callback = (res: any) => {
          addCardDataToPackage(
            pMode ? treeData : cooperationTreeData,
            res,
            pMode,
            'TreeData'
          );
          if (pMode) {
            getShowFiles(
              typeof treeData === 'undefined' ? [] : treeData,
              [selectedFileId],
              1
            );
          } else {
            getShowFiles(
              typeof cooperationTreeData === 'undefined'
                ? []
                : cooperationTreeData,
              [selectedFileId],
              1
            );
          }
          client.writeQuery({
            query: GET_CREATE_PACKAGE_INFO,
            data: {
              searchMode: false,
              treeData: treeData,
              cooperationTreeData: cooperationTreeData
            }
          });
        };
        client.writeData({
          data: {
            isShowCreateModal: true,
            isCreateFolder: true,
            selectedFilePackageId: findPackageId(
              pMode ? treeData : cooperationTreeData,
              selectedFileId
            )
          }
        });

        break;
      case '2':
        client.writeData({
          data: {
            isEditCard: false,
            selectedFilePackageId: findPackageId(
              pMode ? treeData : cooperationTreeData,
              selectedFileId
            )
          }
        });
        props.history.push('edu/create/textcard');
        break;
      case '3':
        client.writeData({
          data: {
            isEditCard: false,
            selectedFilePackageId: findPackageId(
              pMode ? treeData : cooperationTreeData,
              selectedFileId
            )
          }
        });
        props.history.push('edu/create/videocard');
        break;
      case '4':
        client.writeData({
          data: {
            isEditCard: false,
            selectedFilePackageId: findPackageId(
              pMode ? treeData : cooperationTreeData,
              selectedFileId
            )
          }
        });
        props.history.push('edu/create/examcard');
        break;
      case '5':
        client.writeData({
          data: {
            isEditCard: false,
            selectedFilePackageId: findPackageId(
              pMode ? treeData : cooperationTreeData,
              selectedFileId
            )
          }
        });
        props.history.push('edu/create/audiocard');
        break;
      default:
    }
  };

  const deletePackage = (packageIdOri: string) => {
    let packageId = Base64.decode(packageIdOri).split('Package:')[1];
    client.writeData({
      data: {
        hanStatus: {
          handing: true,
          tip: '正在删除卡包',
          __typename: 'HanStatus'
        }
      }
    });
    deletePackageMutation({ variables: { input: { id: packageId } } }).then(
      (res: any) => {
        client.writeData({
          data: {
            hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
          }
        });
        if (res.data.deletePackage.errors) {
          message.error(res.data.deletePackage.errors[0].message);
        } else {
          deleteDataFromTreeData(
            pMode ? treeData : cooperationTreeData,
            packageIdOri
          );
          let selectPackageId = '';
          if (pMode) {
            if (treeData.length > 0) {
              selectPackageId =
                selectedFileId === packageIdOri
                  ? treeData[0].key
                  : selectedFileId;
            } else if (cooperationTreeData.length > 0) {
              selectPackageId =
                selectedFileId === packageIdOri
                  ? cooperationTreeData[0].key
                  : selectedFileId;
            } else {
              selectPackageId = '';
            }
          } else {
            if (cooperationTreeData.length > 0) {
              selectPackageId =
                selectedFileId === packageIdOri
                  ? cooperationTreeData[0].key
                  : selectedFileId;
            } else if (treeData.length > 0) {
              selectPackageId =
                selectedFileId === packageIdOri
                  ? treeData[0].key
                  : selectedFileId;
            } else {
              selectPackageId = '';
            }
          }
          // let selectPackageId =
          //   selectedFileId === packageIdOri
          //     ? pMode
          //       ? treeData[0].key
          //       : cooperationTreeData[0].key
          //     : selectedFileId;

          if (pMode) {
            getShowFiles(
              typeof treeData === 'undefined' ? [] : treeData,
              [selectPackageId],
              1
            );
          } else {
            getShowFiles(
              typeof cooperationTreeData === 'undefined'
                ? []
                : cooperationTreeData,
              [selectPackageId],
              1
            );
          }
          client.writeQuery({
            query: GET_CREATE_PACKAGE_INFO,
            data: {
              searchMode: false,
              treeData: treeData,
              cooperationTreeData: cooperationTreeData,
              selectedFileId: selectPackageId
            }
          });
        }
      }
    );
  };

  const renameCard = (idOri: string, newName: string, packageIdOri: string) => {
    let id = Base64.decode(idOri).split('Card:')[1];
    let packageId = Base64.decode(packageIdOri).split("Package:")[1];
    client.writeData({
      data: {
        hanStatus: {
          handing: true,
          tip: '正在重命名卡片',
          __typename: 'HanStatus'
        }
      }
    });
    renameCardMutation({
      variables: { input: { id: id, newName: newName, packageId: packageId } }
    }).then((res: any) => {
      client.writeData({
        data: {
          hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
        }
      });
      if (res.data.renameCard.errors) {
        if(res.data.renameCard.errors[0].message.indexOf('can not rename or delete card that have branches') !== -1) {
          message.error('不能重命名其他分支共有文件!');
        }else {
          message.error('无法重命名!');
        }
        
      } else {
        renameCardFromTreeData(
          pMode ? treeData : cooperationTreeData,
          res.data.renameCard.card.id,
          res.data.renameCard.card.name
        );
        if (pMode) {
          getShowFiles(
            typeof treeData === 'undefined' ? [] : treeData,
            [selectedFileId],
            page
          );
        } else {
          getShowFiles(
            typeof cooperationTreeData === 'undefined'
              ? []
              : cooperationTreeData,
            [selectedFileId],
            page
          );
        }
        client.writeQuery({
          query: GET_CREATE_PACKAGE_INFO,
          data: {
            searchMode: false,
            treeData: treeData,
            cooperationTreeData: cooperationTreeData
          }
        });
      }
    });
  };

  const renamePackage = (idOri: string, newNMame: string) => {
    let id = Base64.decode(idOri).split('Package:')[1];
    client.writeData({
      data: {
        hanStatus: {
          handing: true,
          tip: '正在重命名卡包',
          __typename: 'HanStatus'
        }
      }
    });
    renamePackageMutation({
      variables: { input: { id: id, name: newNMame } }
    }).then((res: any) => {
      client.writeData({
        data: {
          hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
        }
      });
      if (res.data.updatePackage.errors) {
        message.error(res.data.updatePackage.errors[0].message);
      } else {
        renameCardFromTreeData(
          pMode ? treeData : cooperationTreeData,
          res.data.updatePackage.package.id,
          res.data.updatePackage.package.name
        );
        if (pMode) {
          getShowFiles(
            typeof treeData === 'undefined' ? [] : treeData,
            [selectedFileId],
            page
          );
        } else {
          getShowFiles(
            typeof cooperationTreeData === 'undefined'
              ? []
              : cooperationTreeData,
            [selectedFileId],
            page
          );
        }
        client.writeQuery({
          query: GET_CREATE_PACKAGE_INFO,
          data: {
            searchMode: false,
            treeData: treeData,
            cooperationTreeData: cooperationTreeData
          }
        });
      }
    });
  };

  const deleteCards = (ids: string[], packageId: string) => {
    let input = {
      ids: ids.map((item: any) => {
        return Base64.decode(item).split('Card:')[1];
      }),
      packageId: Base64.decode(packageId).split('Package:')[1]
    };
    client.writeData({
      data: {
        hanStatus: {
          handing: true,
          tip: '正在批量删除卡片',
          __typename: 'HanStatus'
        }
      }
    });
    deleteCardsMutation({ variables: { input: input } })
      .then((res: any) => {
        client.writeData({
          data: {
            hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
          }
        });
        if (res.data.deleteCards.errors) {
          message.error(res.data.deleteCards.errors[0].message);
        } else {
          ids.map((item: string) => {
            deleteDataFromTreeData(
              pMode ? treeData : cooperationTreeData,
              item
            );
          });
          if (pMode) {
            getShowFiles(
              typeof treeData === 'undefined' ? [] : treeData,
              [selectedFileId],
              page
            );
          } else {
            getShowFiles(
              typeof cooperationTreeData === 'undefined'
                ? []
                : cooperationTreeData,
              [selectedFileId],
              page
            );
          }
          client.writeQuery({
            query: GET_CREATE_PACKAGE_INFO,
            data: {
              searchMode: false,
              treeData: treeData,
              cooperationTreeData: cooperationTreeData
            }
          });
          client.writeData({
            data: {
              modal3Loading: false,
              modal3: {
                show: false,
                title: '',
                content: '',
                __typename: 'Modal3'
              }
            }
          });
          message.success('删除成功!');
        }
      })
      .catch((res: any) => {
        client.writeData({
          data: {
            hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
          }
        });
        message.error('删除失败!');
      });
  };

  const diffChanges = (
    packageIdOri: string,
    packageTitle: string,
    diffType: DiffType,
    isCheck: boolean = false
  ) => {
    client.writeData({
      data: {
        hanStatus: {
          handing: true,
          tip: '正在对比变更内容',
          __typename: 'HanStatus'
        }
      }
    });
    diffQuery({ currentPackageId: packageIdOri, diffType: diffType })
      .then((res: any) => {
        let changes = res.data.diff.changes;
        if (changes && changes !== '') {
          client.writeData({
            data: {
              packageTitle: packageTitle,
              diffChangesStr: changes
            }
          });
          if(isCheck) {
            props.history.push('edu/diff/check');
          }else {
            switch (diffType) {
              case DiffType.BranchSubmitPr:
                props.history.push('edu/diff/submerge');
                break;
              case DiffType.BranchFetchMaster:
                props.history.push('edu/diff/mergefrommaster');
                break;
            }
          }
        } else {
          message.warning('无变更内容!');
        }

        client.writeData({
          data: {
            hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
          }
        });
      })
      .catch((error: any) => {
        client.writeData({
          data: {
            hanStatus: { handing: false, tip: '', __typename: 'HanStatus' }
          }
        });
        message.error('对比失败!');
      });
  };

  const pageState = {
    modeState: {
      mode: mode,
      setMode: setMode
    },
    searchContentState: {
      searchContent: searchContent,
      setSearchContent: setSearchContent
    },
    isPackageState: {
      isPackage: isPackage,
      setIsPackage: setIsPackage
    },
    isFileState: {
      isFile: isFile,
      setIsFile: setIsFile
    },
    isCooState: {
      isCoo: isCoo,
      setIsCoo: setIsCoo
    },
    collapsedState: {
      collapsed: collapsed,
      setCollapsed: setCollapsed
    },
    pageState: {
      page: page,
      setPage: setPage
    },
    sortFilesState: {
      sortFiles: sortFiles,
      setSortFiles: setSortFiles
    },
    refreshState: {
      isRefresh: isRefresh,
      setIsRefresh: setIsRefresh
    }
  };

  const pageFunction = {
    getShowFiles: getShowFiles,
    getAllShowFiles: getAllShowFiles,
    uploadCardFunc: uploadCardFunc,
    uploadZipFunc: uploadZipFunc,
    onCreate: onCreate,
    deletePackage: deletePackage,
    renameCard: renameCard,
    renamePackage: renamePackage,
    deleteCards: deleteCards,
    diffChanges: diffChanges
  };

  return (
    <Spin
      spinning={hanStatus.handing}
      tip={hanStatus.tip}
      indicator={<LoadingView src={loading} />}
      size={'large'}
      style={{ position: screenful ? 'absolute' : 'relative' }}
    >
      <Layout
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'relative',
          alignSelf: 'center',
          marginTop: screenful ? -188 : 0,
          backgroundColor: '#FFFFFF'
        }}
      >
        <EduManagerPackagePageToolbar
          props={props}
          pageFunction={pageFunction}
          pageState={pageState}
        />
        <EduManagerPackagePageContent
          props={props}
          pageFunction={pageFunction}
          pageState={pageState}
        />
        {rightClick ? (
          <EduManagerPackagePageRightClickCard
            pageState={pageState}
            props={props}
            pageFunction={pageFunction}
            deleteCard={deleteCard}
            deleteCardFromAllBranches={deleteCardFromAllBranches}
          />
        ) : null}
        <EduManagerPackagePageRightClickScreen
          pageState={pageState}
          pageFunction={pageFunction}
          pMode={pMode}
        />
        <EduManagerPackagePageRightClickPackage
          pageFunction={pageFunction}
          client={client}
        />
        <div className='edu-PackageManager-43003'>
          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel
              header='上传失败文件'
              key='1'
              className='edu-PackageManager-43248'
            >
              {errorCards.map((item: any) => {
                return (
                  <div className='edu-PackageManager-43372'>
                    {item.location}
                    <img src={negative} className='edu-PackageManager-43492' />
                    失败, {item.message}
                  </div>
                );
              })}
            </Collapse.Panel>
          </Collapse>
        </div>
      </Layout>
    </Spin>
  );
};

const EduManagerPackagePageWrapper = Form.create<Props>()(
  withRouter(EduManagerPackagePage)
);

export default EduManagerPackagePageWrapper;
