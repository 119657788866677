/**
 * @description 单张卡片内容展示 三个组件章节学习、最后完成、那么那么
 * @author 阿礼
 * @since 2019
 */

import React, { useState, useEffect } from 'react';
import ReactCardFlip from 'react-card-flip';
import './style.less';

// 第三方库
import gql from 'graphql-tag';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import ReactMarkdown from 'react-markdown';
import MyReactMarkdown from '../../../CommonComponents/MyReactMarkdown';
import { message } from 'antd';
import { Base64 } from 'js-base64';
// 自定义
import TextCard from './TextCard';
import VideoCard from './VideoCard';
import ExamCard from './ExamCard';
import AudioCard from './AudioCard';
import CardFrontFooter from './CardFrontFooter';
import rotateImg from '../../../../assets/v2.1/ic_course_card_turnover.png';
import left from '../../../../assets/icons/arrow_left_circle.png';
import right from '../../../../assets/icons/arrow_right_circle.png';
import { handleCardContent } from '../../../../utils/handleCardContent';
import FinishOneChapterTip from './FinishOneChapterTip';
import makeExamCardInfo from './../../../../utils/makeExamCardInfo';
import gaEvents from '../../../../utils/gaEvents.js';
// 判断卡片切换的逻辑
import { findNextCard } from '../../../../utils/getNextAndPrev';
import { getTextCardContent, recursionDir } from '../../../../utils/index.js';
import { updateIsLearnedState } from '../../../../utils/updatCardState.js';
import { GET_SINGLE_CARD_CONTENT } from './../query.ts';

// 查询接口
import {
  useGetSingleCardContentQuery,
  useGetUnlockSingleCardMutation,
  useGetNextFolderDataQuery,
  useGetNextCardQuery,
  useGetPrevCardQuery
} from '../../../../generated/graphql';

const GET_INFO = gql`
  {
    courseTitle @client
    directoryList @client
    currentCardId @client
    coursePackageId @client
    content @client
    courseId @client
    currentParentId @client
    userInfo @client {
      username
      phone
    }
  }
`;

const GET_DIR = gql`
  {
    directoryList @client
  }
`;
const GET_CONTENT = gql`
  {
    content @client
    chapterIsFinish @client
    finishAllCards @client
    currentCardId @client
    directoryList @client
    currentParentId @client(always: true)
    isLearnCardCount @client
    allCardCount @client
    cardLearnPeople @client
  }
`;

const GET_CONTENT_COLLECT = gql`
  {
    contentCardCollect @client
  }
`;

const FINISH_CHAPTER = gql`
  {
    chapterIsFinish @client
  }
`;
const CardDisplay = props => {
  const client = useApolloClient();
  const [side, setSide] = useState(false);
  const [flipSpeeed, setFlipSpeeed] = useState(0.5);
  const [parentNode, setParentNode] = useState({});
  const {
    data: { chapterIsFinish }
  } = useQuery(FINISH_CHAPTER);
  const {
    data: {
      courseTitle,
      directoryList,
      currentCardId,
      coursePackageId,
      userInfo,
      courseId,
      currentParentId
    }
  } = useQuery(GET_INFO);
  const {
    data: { content, isLearnCardCount },
    loading
  } = useQuery(GET_CONTENT);

  const { refetch: updateContent } = useGetSingleCardContentQuery({
    skip: true
  });
  const [unlockCard] = useGetUnlockSingleCardMutation({
    skip: true
  });
  const { refetch: getNextCard } = useGetNextCardQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });
  const { refetch: getPrevCard } = useGetPrevCardQuery({ skip: true });
  useEffect(() => {
    setSide(false);
    setFlipSpeeed(0);
  }, [currentCardId]);
  // 获取下一层的数据
  const { refetch: getNextData } = useGetNextFolderDataQuery({ skip: true });

  const renderContent = content => {
    switch (content.type) {
      case 'md':
        return <TextCard content={content} />;
      case 'video':
        return <VideoCard content={content} userInfo={userInfo} />;
      case 'exam':
        return <ExamCard content={content} />;
      case 'audio':
        return <AudioCard content={content} />;
      default:
        break;
    }
  };
  useEffect(() => {
    document.onkeydown = function(e) {
      if (e.keyCode === 32 && content.back.length > 2) {
        e.preventDefault();
        setSide(!side);
        setFlipSpeeed(0.6);
      }
      //键盘左键

      if (e.keyCode === 37) {
        e.preventDefault();
        getPrevCard({
          courseId,
          coursePackgeId: coursePackageId,
          currentCardId
        })
          .then(res => {
            try {
              const nextCard = res.data.node.previousCard;
              // 展示内容、收藏、学习人数、学习进度 放到缓存里面
              // 判断是否继续学习
              // 内容处理

              const newContent = handleCardContent(
                nextCard.name,
                nextCard.content,
                nextCard.id,
                client
              );

              //设置卡片问题和回答的目录结构，方便后续上传答案

              client.writeQuery({
                query: GET_CONTENT,
                data: {
                  content: newContent,
                  chapterIsFinish: false,
                  currentCardId: nextCard.id,
                  currentParentId: nextCard.parent ? nextCard.parent.id : null,
                  // isLearnCardCount: nextCard.fileCardCount,
                  // allCardCount: nextCard.learnedCount,
                  cardLearnPeople: nextCard.learnedPeopleCount
                  // 学习人数 进度
                }
              });
              client.writeQuery({
                query: GET_CONTENT_COLLECT,
                data: {
                  contentCardCollect: nextCard.isCollected,
                }
              });

              gaEvents.gaEventsUpload({
                eventName: `点击键盘左键-卡片页-上一页-到-${courseTitle}-${
                  nextCard.name
                }-卡`
              });

              // 并且判断isLearned是否需要解锁

              // 判断是否继续学习
            } catch (err) {
              console.log(err);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
      if (!chapterIsFinish) {
        // 键盘右键
        if (e.keyCode === 39) {
          e.preventDefault();
          getNextCard({
            courseId,
            coursePackgeId: coursePackageId,
            currentCardId
          }).then(res => {
            try {
              const nextCard = res.data.node.nextCard;
              if (nextCard === null) {
                client.writeData({
                  data: { finishAllCards: true, chapterIsFinish: true }
                });
                return;
                return;
              }
              // 展示内容、收藏、学习人数、学习进度 放到缓存里面
              // 判断是否继续学习
              // 内容处理

              if (
                nextCard.parent !== null &&
                nextCard.parent.id !== currentParentId
              ) {
                client.writeData({
                  data: { chapterIsFinish: true, finishAllCards: false }
                });
                // client.writeQuery({
                //   query: GET_CONTENT,
                //   data: {
                //     currentCardId: nextCard.id,
                //     currentParentId: nextCard.parent.id
                //   }
                // });
                return;
              }

              // 处理文本内容
              const newContent = handleCardContent(
                nextCard.name,
                nextCard.content,
                nextCard.id,
                client
              );

              // 小锁标志解开
              const newDir = updateIsLearnedState(directoryList, nextCard);
              client.writeQuery({
                query: GET_CONTENT,
                data: {
                  directoryList: newDir,
                  content: newContent,
                  chapterIsFinish: false,
                  finishAllCards: false,
                  currentCardId: nextCard.id,
                  currentParentId: nextCard.parent
                    ? nextCard.parent.id
                    : nextCard.parent,
                  // isLearnCardCount: nextCard.fileCardCount,
                  // allCardCount: nextCard.learnedCount,
                  cardLearnPeople: nextCard.learnedPeopleCount
                  // 学习人数 进度
                }
              });
              client.writeQuery({
                query: GET_CONTENT_COLLECT,
                data: {
                  contentCardCollect: nextCard.isCollected,
                }
              });
              // 并且判断isLearned是否需要解锁
              if (!nextCard.isLearned) {
                unlockCard({
                  variables: {
                    input: {
                      cardId: Base64.decode(nextCard.id).split('Card:')[1],
                      coursePackageId: Base64.decode(coursePackageId).split(
                        'CoursePackage:'
                      )[1],
                      visitDuration: 20.1
                    },
                    coursePackageId: coursePackageId
                  }
                });
                // 判断卡片进度是否增加
                client.writeData({
                  data: { isLearnCardCount: isLearnCardCount + 1 }
                });
              }
              gaEvents.gaEventsUpload({
                eventName: `点击键盘右键-卡片页-下一页-到-${courseTitle}-${
                  nextCard.name
                }-卡`
              });
              // 判断是否继续学习c
            } catch (err) {
              console.log(err);
            }
          });
        }
      }
    };

    return () => {
      document.onkeydown = function(e) {
        e.stopPropagation();
      };
    };
  });

  if (loading) {
    return null;
  }

  return (
    <div className='card-content-display'>
      {/* 左图片 */}
      <img
        className='switch-prev'
        src={left}
        alt='左图片'
        onClick={() => {
          getPrevCard({
            courseId,
            coursePackgeId: coursePackageId,
            currentCardId
          }).then(res => {
            try {
              const nextCard = res.data.node.previousCard;
              // 展示内容、收藏、学习人数、学习进度 放到缓存里面
              // 判断是否继续学习
              // 内容处理

              const newContent = handleCardContent(
                nextCard.name,
                nextCard.content,
                nextCard.id,
                client
              );

              // 小锁标志解开

              client.writeQuery({
                query: GET_CONTENT,
                data: {
                  content: newContent,
                  chapterIsFinish: false,
                  currentCardId: nextCard.id,
                  currentParentId: nextCard.parent ? nextCard.parent.id : null,
                  // isLearnCardCount: nextCard.fileCardCount,
                  // allCardCount: nextCard.learnedCount,
                  cardLearnPeople: nextCard.learnedPeopleCount
                  // 学习人数 进度
                }
              });
              client.writeQuery({
                query: GET_CONTENT_COLLECT,
                data: {
                  contentCardCollect: nextCard.isCollected,
                }
              });
              gaEvents.gaEventsUpload({
                eventName: `点击-卡片页-上一页-到-${courseTitle}-${
                  nextCard.name
                }-卡`
              });
            } catch (err) {
              console.log(err);
            }
          });
        }}
      />

      {/* 卡片正反两面 */}
      <div className={!side ? 'single-card' : 'single-card-back'}>
        {chapterIsFinish ? (
          <FinishOneChapterTip parentNode={parentNode} />
        ) : (
          <ReactCardFlip
            className='new-routes-study-cardModal-1189'
            isFlipped={side}
            infinite={false}
            // 用变量控制它
            flipSpeedBackToFront={flipSpeeed}
            flipSpeedFrontToBack={flipSpeeed}
          >
            {/* 正面组件 */}
            <div key='front' className='new-routes-study-cardModal-1580'>
              {/* 翻转标签 */}

              {content && content.back.length > 2 && (
                <div
                  className='new-routes-study-cardModal-1342'
                  onClick={() => {
                    setSide(!side);
                    setFlipSpeeed(0.6);
                    gaEvents.gaEventsUpload({
                      eventName: `点击-卡片页-翻转到背面-${courseTitle}-${
                        content.name
                      }-卡`
                    });
                  }}
                >
                  <div className='new-routes-study-cardModal-1411'></div>

                  <img
                    data-rjs='2'
                    src={rotateImg}
                    className='new-routes-study-cardModal-1425'
                  />
                </div>
              )}

              {content && renderContent(content)}

              <CardFrontFooter  content={content} />
            </div>

            {/* 背面组件 */}
            <div key='back' className='new-routes-study-cardModal-1580'>
              {content && content.back.length > 2 && (
                <div
                  className='new-routes-study-cardModal-1342'
                  onClick={() => {
                    gaEvents.gaEventsUpload({
                      eventName: `点击-卡片页-翻转到正面-${courseTitle}-${
                        content.name
                      }-卡`
                    });
                    setSide(!side);
                  }
                  }
                >
                  <div className='new-routes-study-cardModal-1411'></div>

                  <img
                    data-rjs='2'
                    src={rotateImg}
                    className='new-routes-study-cardModal-1425'
                  />
                </div>
              )}
              <div className='routes-course-CardModal-4122'>
                <div className='routes-course-CardModal-4451' />
                {/* <ReactMarkdown
                  skipHtml={false}
                  escapeHtml={false}
                  source={content && content.back}
                /> */}
                <div>
                  <MyReactMarkdown source={content && content.back} />
                </div>
              </div>
            </div>
          </ReactCardFlip>
        )}
      </div>

      <img
        className='switch-next'
        style={
          chapterIsFinish
            ? { pointerEvents: 'none' }
            : { pointerEvents: 'visible' }
        }
        src={right}
        alt='右图片'
        onClick={() => {
          // 调用下一张的查询接口，获取下一张卡片的Id

          getNextCard({
            courseId,
            coursePackgeId: coursePackageId,
            currentCardId
          }).then(res => {
            try {
              const nextCard = res.data.node.nextCard;
              // 展示内容、收藏、学习人数、学习进度 放到缓存里面
              // 判断是否继续学习
              // 内容处理
              if (nextCard === null) {
                // 如果我起了一个状态，作为因变量，那谁会受影响？
                // 点击、上下键盘、左右按钮
                client.writeData({
                  data: { finishAllCards: true, chapterIsFinish: true }
                });
                return;
              }

              // parent 为null是目录层级是第一层的考虑
              if (
                nextCard.parent !== null &&
                nextCard.parent.id !== currentParentId
              ) {
                client.writeData({
                  data: { chapterIsFinish: true, finishAllCards: false }
                });
                return;
              }

              const newContent = handleCardContent(
                nextCard.name,
                nextCard.content,
                nextCard.id,
                client
              );

              // 小锁标志解开
              const newDir = updateIsLearnedState(directoryList, nextCard);
              client.writeQuery({
                query: GET_CONTENT,
                data: {
                  directoryList: newDir,
                  content: newContent,
                  chapterIsFinish: false,
                  finishAllCards: false,
                  currentCardId: nextCard.id,
                  currentParentId: nextCard.parent
                    ? nextCard.parent.id
                    : nextCard.parent,
                  // isLearnCardCount: nextCard.fileCardCount,
                  // allCardCount: nextCard.learnedCount,
                  cardLearnPeople: nextCard.learnedPeopleCount
                  // 学习人数 进度
                }
              });
              client.writeQuery({
                query: GET_CONTENT_COLLECT,
                data: {
                  contentCardCollect: nextCard.isCollected,
                }
              });
              // 并且判断isLearned是否需要解锁
              if (!nextCard.isLearned) {
                unlockCard({
                  variables: {
                    input: {
                      cardId: Base64.decode(nextCard.id).split('Card:')[1],
                      coursePackageId: Base64.decode(coursePackageId).split(
                        'CoursePackage:'
                      )[1],
                      visitDuration: 20.1
                    },
                    coursePackageId: coursePackageId
                  }
                });
                // 判断卡片进度是否增加
                client.writeData({
                  data: { isLearnCardCount: isLearnCardCount + 1 }
                });
              }
              gaEvents.gaEventsUpload({
                eventName: `点击-卡片页-下一页-到-${courseTitle}-${
                  nextCard.name
                }-卡`
              });
              // 判断是否继续学习
            } catch (err) {
              console.log(err);
            }
          });
        }}
      />
    </div>
  );
};

export default CardDisplay;
