import React, { Component } from 'react';
import styled from 'styled-components';
import Avatar from 'antd/es/avatar/index';

const Disc = styled.div`
  width: 1000px;
  height: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #FFFFFF; 
  cursor: pointer;
`;

Disc.Divider = styled.div`
  width: 1000px; 
  height: 1px; 
  background-color: #EAEDF3;
`;

Disc.Container = styled.div`
  width: 93%; 
  height: 100px; 
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

Disc.BlankContainer = styled.div`
  width: 100%; 
  height: 10px;
  display: flex;
  align-items: center;
`;

Disc.TitleContainer = styled.div`
  width: 100%; 
  height: 40px;
  display: flex;
  align-items: center;
`;

Disc.TitleTag = styled.div`
	height: 24px;
	border-radius: 4px;
  margin-right: 10px;
  
  text-align:center;
  padding-left:10px;
  padding-right:10px;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 24px;
  white-space:nowrap;
`;

Disc.Title = styled.div`
	color: #3E3F42;
	font-size: 18px;
  line-height: 32px;
  
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
`;


Disc.InfoContainer = styled.div`
  width: 100%; 
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

Disc.InfoUser = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  align-items: center;
  flex-direction: row;
`;

Disc.Avatar = styled(Avatar)`
  
`;

Disc.User = styled.div`
  color: #9EA0A5; 
  font-size: 14px;
  line-height: 22px;
  margin-left: 16px;
`;

Disc.ReplyCount = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  align-items: center;
  flex-direction: row;
`;

Disc.Comment = styled.img`
  width: 18px;
  height: 18px;
`

Disc.ComCount = styled.div`
 font-size: 14px;
 color: #9EA0A5;
 margin-left: 6px;
 line-height: 22px;
`

export { Disc };
