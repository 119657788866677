import video from '../assets/v2.1/video-normal.png';
import video_selected from '../assets/v2.1/video-selected.png';
import exam from '../assets/v2.1/aw-normal.png';
import exam_selected from '../assets/v2.1/aw-selected.png';
import md from '../assets/v2.1/md-normal.png';
import md_selected from '../assets/v2.1/md-selected.png';
import folder from '../assets/icons/file_small_folder.png';
export const getSuffix = suffix => {
  switch (suffix) {
    case 'md':
      return md;
    case 'exam':
      return exam;
    case 'video':
    case 'audio':
      return video;
    default:
      return folder;
  }
};

export const getSelectedSuffix = suffix => {
  switch (suffix) {
    case 'md':
      return md_selected;
    case 'exam':
      return exam_selected;
    case 'video':
    case 'audio':
      return video_selected;
    default:
      return md_selected;
  }
};
