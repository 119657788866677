/**
 * @description 查看其他人的答案组件
 * @author 阿礼
 * @since 2019-9--14
 */

import React, { useState, useEffect } from 'react';
import './style.less';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useGetSingleAnswerIdQuery } from '../../../../generated/graphql.tsx';

// 第三方组件

const CheckOtherAnswer = props => {
  let ak_key = '';
  const [skip, setSkip] = useState(true);
  const { data, error, loading } = useGetSingleAnswerIdQuery({
    fetchPolicy: "network-only",
    variables: { id: props.assignmentId },
    skip
  });

  //如果有值，就请求
  useEffect(() => {
    if (props.assignmentId) {
      setSkip(false);
    }
  }, [props.assignmentId]);
  if (loading) {
    return null;
  }
  if (data) {
    if (data.node.answers.edges.length > 0) {
      ak_key = data.node.answers.edges[0].node.id;
      ak_key = window.atob(ak_key).split('Assignment:')[1];
      props.setShowCheckOtherAnswers(true);
    } else {
      props.setShowCheckOtherAnswers(false);
      ak_key = 0;
    }
  }

  return (
    <div className='check-other-answer'>
      <span className='word-description'>查看其他同学</span>
      <Button className='check'>
        <Link
          to={`/stu/${window.sessionStorage.getItem('shortUrl')}/homework/${
            window.atob(props.assignmentId).split('Assignment:')[1]
          }/${ak_key}`}
        >
          查看
        </Link>
      </Button>
    </div>
  );
};

export default CheckOtherAnswer;
