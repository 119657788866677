import gql from 'graphql-tag';

export const query = gql`
  {
    home @client {
      colleges {
        path
        icon
        title
        introduce
        bgcolor
        cover
      }
    }
    collegeDetail @client {
      collegesCourses
    }
  }
`;

export const writeQuery = gql`
  {
    collegeDetail @client {
      collegesCourses
    }
  }
`;
