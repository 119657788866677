import React from 'react';
import { Input, Select, DatePicker } from 'antd';
import Works from '../components/Works';
import { FormConfig } from 'components/Form';
import { validateUrl, validateEmail } from 'utils/regexp';

const { Option } = Select;

const state = [
  '本科在读',
  '硕士在读',
  '博士在读',
  '工作1-3年',
  '工作4-7年',
  '工作8年以上',
  '其他'
];

const omcRegion = [
  '北京大区（含京津冀地区）',
  '长三角大区',
  '珠三角大区',
  '其他'
];

export const handleChange = (value: any) => {
  const isShow = detailed.find(item => item.label === '所在行业');
  if (value.includes('在读')) {
    if (isShow) {
      detailed.splice(7, 3);
    }
  } else {
    if (!isShow) {
      const list: FormConfig[] = [
        {
          label: '所在行业',
          value: 'industry',
          rules: [{ required: true, message: '请输入所在行业' }],
          component: props => <Input placeholder='请输入所在行业' />
        },
        {
          label: '目前职业',
          value: 'headline',
          rules: [{ required: true, message: '请输入目前职业' }],
          component: props => <Input placeholder='请输入目前职业' />
        },
        {
          label: '公司名称',
          value: 'company',
          rules: [{ required: true, message: '请输入公司名称' }],
          component: props => <Input placeholder='请输入公司名称' />
        }
      ];
      detailed.splice(7, 0, ...list);
    }
  }
};

const detailed: FormConfig[] = [
  {
    label: '微信昵称',
    value: 'wechatNickname',
    rules: [{ required: true, message: '请输入微信昵称' }],
    component: props => <Input placeholder='请输入微信昵称' />
  },
  {
    label: '微信号',
    value: 'wechatUsername',
    rules: [{ required: true, message: '请输入微信号' }],
    component: props => <Input placeholder='请输入微信号' />
  },
  {
    label: '真实姓名',
    value: 'name',
    rules: [{ required: true, message: '请输入真实姓名' }],
    component: props => <Input placeholder='请输入真实姓名' />
  },
  {
    label: '邮箱',
    value: 'email',
    rules: [
      { required: true, message: '请输入邮箱' },
      {
        validator: (rule, value, callback) => {
          if (!validateEmail(value) && value !== '') {
            callback('请输入正确邮箱');
          } else {
            callback();
          }
        }
      }
    ],
    component: props => <Input placeholder='请输入邮箱' />
  },
  {
    label: '性别',
    value: 'gender',
    rules: [{ required: true, message: '请选择性别' }],
    component: props => (
      <Select>
        <Option value='male'>男</Option>
        <Option value='female'>女</Option>
      </Select>
    )
  },
  {
    label: '生日',
    value: 'birth',
    rules: [{ required: true, message: '请选择生日' }],
    component: props => (
      <DatePicker
        format='YYYY-MM-DD'
        style={{ width: '100%' }}
        placeholder='请选择生日'
      />
    )
  },
  {
    label: '目前个人状态',
    value: 'state',
    rules: [{ required: true, message: '请选择目前个人状态' }],
    component: props => (
      <Select onChange={value => handleChange(value)}>
        {state.map(item => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </Select>
    )
  },
  {
    label: '所在大区',
    value: 'omcRegion',
    rules: [{ required: true, message: '请选择所在大区' }],
    component: props => (
      <Select>
        {omcRegion.map(item => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </Select>
    )
  },
  {
    label: '联系地址',
    value: 'address',
    rules: [{ required: true, message: '请输入联系地址' }],
    component: props => <Input placeholder='请输入联系地址' />
  },
  {
    label: '就读院校',
    value: 'school',
    component: props => <Input placeholder='请输入就读院校' />
  },
  {
    label: '就读专业',
    value: 'fieldOfStudy',
    component: props => <Input placeholder='请输入就读专业' />
  },
  {
    label: '兴趣爱好',
    value: 'hobbies',
    component: props => <Input placeholder='请输入兴趣爱好' />
  },
  {
    label: 'GitHub个人主页地址',
    value: 'github_url',
    rules: [
      {
        validator: (rule, value, callback) => {
          if (!value || value === '') {
            callback();
          } else if (!validateUrl(value) || !value.includes('github.com')) {
            callback('请输入正确的GitHub地址');
          } else {
            callback();
          }
        }
      }
    ],
    component: props => <Input placeholder='请输入GitHub个人主页地址' />
  },
  {
    label: '豆瓣个人主页地址',
    value: 'douban_url',
    rules: [
      {
        validator: (rule, value, callback) => {
          if (!value || value === '') {
            callback();
          } else if (!validateUrl(value) || !value.includes('douban.com')) {
            callback('请输入正确的豆瓣地址');
          } else {
            callback();
          }
        }
      }
    ],
    component: props => <Input placeholder='请输入豆瓣个人主页地址' />
  },
  {
    label: '个人网站或博客',
    value: 'blog_url',
    rules: [
      {
        validator: (rule, value, callback) => {
          if (!value || value === '') {
            callback();
          } else if (!validateUrl(value)) {
            callback('请输入正确的博客地址');
          } else {
            callback();
          }
        }
      }
    ],
    component: props => <Input placeholder='请输入个人网站或博客' />
  },
  {
    label: '个人作品链接',
    value: 'works',
    component: ({ form, defaultProps }) => (
      <Works
        defaultProps={defaultProps}
        onChange={works => {
          form.setFieldsValue({ works });
        }}
      />
    )
  }
];

export default detailed;
