import * as React from 'react';
import './styles.css';

//引入的库组件
import styled from 'styled-components';
import { Menu } from 'antd';
import { useApolloClient } from 'react-apollo';
import { setContext } from 'apollo-link-context';
import gql from 'graphql-tag';

//引入的其他页面组件
import manager from '../../global_variable/manager';
import headers from '../../global_variable/headers';
import learningCenter from '../../global_variable/learningCenter';
import collectionPage from '../../global_variable/collectionPage';
import home from '../../global_variable/home';
import courses from '../../global_variable/courses';
import collegeDetail from '../../global_variable/collegeDetail';
import courseDetail from '../../global_variable/courseDetail';
import my from '../../global_variable/my';
import stu from '../../global_variable/stu';
import common from '../../global_variable/common';
import discusspage from '../../global_variable/discusspage';

import timeLocalStorage from '../../utils/TimeLocalStorage';

//引入的资源图片

const QItem = styled(Menu.Item)`
  background-color: #fff;
  color: #282a2d;
  font-size: 14px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  &:hover {
    background-color: #1890ff !important;
    color: #fff;
  }
`;

interface Props {
  userInfo: any;
  props: any;
}


const UserMenu: React.FC<Props> = ({ userInfo, props }) => {
  const client = useApolloClient();
  return (
    <Menu
      selectable={false}
      onClick={e => {
        client.writeData({
          data: { allCourseSearchContent: '' }
        });
        switch (e.key) {
          case '0':
            break;
          case '1':
            props.history.push('/my');
            break;
          case '2':
            headers.Authorization = '';
            client.writeData({
              data: {
                //主页头 主页脚
                isShowHeader: true,
                isShowFooter: true,

                //一些弹出菜单用的全局变量
                isShowLoginModal: false,
                isShowResetResult: false,
                isShowResetModal: false,
                resetLoading: false,
                isShowRegisterModal: false,
                isShowResetButton: true,
                buttonLoading: false,
                showButton: true,

                isLoggedIn: false,

                userInfo: {
                  head_img: '',
                  phone: '',
                  role: '',
                  token: '',
                  userid: '',
                  username: '',
                  userProfile: null,
                  __typename: 'UserInfo'
                },
                badge: {
                  all: 0,
                  apply: 0,
                  com: 0,
                  sys: 0,
                  __typename: 'Badge'
                },

                allCourseSearchContent: '',
                allCourseCollegeSelect: '全部',
                allCourseCourseSelect: '全部',

                ...common,
                ...discusspage,

                ...manager,
                home,
                courses,
                collegeDetail,
                courseDetail,
                my,
                stu
              }
            });
            // client.writeQuery({
            //   query: SET_DATA,
            //   data: {
            //     userInfo: {
            //       head_img: '',
            //       phone: '',
            //       role: '',
            //       token: '',
            //       userid: '',
            //       username: '',
            //       userProfile: null,
            //       __typename: 'UserInfo'
            //     }
            //   }
            // });
            // client.writeData({
            //   data: {
            //     isLoggedIn: false,
            //   }
            // });
            window.localStorage.setItem('LOGINSTATUS', '1');
            window.localStorage.setItem('USERINFO', '');
            window.localStorage.setItem('TOKENINFO', '');
            props.history.push('/');
            break;
          case '3':
            window.open('https://support.qq.com/product/37428');
            break;
          case '4':
            props.history.push('/edu');
            break;
          default:
        }
      }}
    >
      <QItem key={0}>{userInfo.username}</QItem>
      {userInfo.role !== 'USER' ? <QItem key={4}>教研中心</QItem> : null}
      <QItem key={1}>个人设置</QItem>
      <QItem key={3}>问题反馈</QItem>
      <QItem key={2}>退出登录</QItem>
    </Menu>
  );
};

export default UserMenu;
