import gql from 'graphql-tag';

export const query = gql`
  {
    stu @client {
      sayHi
      finished
      learning
    }
  }
`;

export const query2 = gql`
  {
    userInfo @client(always: true) {
      head_img
      username
      userProfile{
        id
      }
    }
  }
`;

export const writeQuery_courses = gql`
  {
    stu @client {
      finished
      learning
    }
  }
`;
