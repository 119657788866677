// 课程去重
export const removalCourse = (courses: any) => {
  const collegesCourses: any = [];
  courses.forEach((coursesItem: any) => {
    let flag = true;
    collegesCourses.forEach((item: any) => {
      if (coursesItem.name == item.name) {
        flag = false;
      }
    });
    if (flag) {
      collegesCourses.push(coursesItem);
    }
  });
  return collegesCourses;
};

interface Search {
  page: number;
  collegiateCategory?: string;
  courseCategory?: string;
  title?: string;
}

// 处理筛选条件
export const handleSearch = ({
  page,
  collegiateCategory,
  courseCategory,
  title
}: Search) => {
  const option = {
    filter: {
      isSelling: true,
      isOnline: true,
      collegiateCategory,
      courseCategory,
      title
    },
    first: 9 * page
  };
  if (collegiateCategory === '全部') delete option.filter.collegiateCategory;
  if (courseCategory === '全部') delete option.filter.courseCategory;
  return option;
};
