/**
 * @description 课程内容目录展示
 * @author ali
 * @since 2019
 */

import React, { useEffect, useState } from 'react';
import './style.less';

// 引入第三方组件
import { Menu, Tree, Icon, message } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { GET_SUB_DIRECTORY, GET_SINGLE_CARD_CONTENT } from '../query';

// 自定义
import {
  useGetCourseDirectoryQuery,
  useGetSubDirectoryQuery,
  useGetSingleCardContentQuery,
  useGetWholeDirectoryQuery,
  useGetUnlockSingleCardMutation,
  useShareCardLinkQuery
} from '../../../../generated/graphql';
import { updateIsLearnedState } from '../../../../utils/updatCardState';
import { handleCardContent } from '../../../../utils/handleCardContent';
import lock from '../../../../assets/icons/password_light.png';
import {
  getSuffix,
  getSelectedSuffix
} from '../../../../utils/judgeCardSuffix';

import cross from '../../../../assets/icons/cross.png';
import FilterCard from './FilterCard';
import inserData from './../../../../utils/recursionSearchDir';
import {
  getTextCardContent,
  getVideoCardContent,
  getExamCardContent
} from '../../../../utils/index.js';

import makeExamCardInfo from './../../../../utils/makeExamCardInfo';
import gaEvents from '../../../../utils/gaEvents.js';
import { history } from '../../../IndexPage';

const GET_SUBDIR = gql`
  {
    content @client
    currentParentId @client
    coursePackageId @client
    currentCardId @client
    cardLearnPeople @client
    courseId @client
    cardTitle @client
    fullScreen @client
    courseTitle @client
  }
`;
const GET_SUBDIR_COLLECT = gql`
  {
    contentCardCollect @client
  }
`;
const GET_WHOLE_DIRECTORY = gql`
  {
    directoryList @client
  }
`;

const SET_EXAM_ANSWER_INFO = gql`
  {
    examAnswerInfo @client
  }
`;
const GET_LEARING_CENTER = gql`
  {
    directoryList @client
    currentCardId @client
    currentParentId @client
    courseId @client
    coursePackageId @client
    currentParentIds @client
    isLearnCardCount @client
    allCardCount @client
    copyDirectoryList @client
    cardTitle @client
    content @client
  }
`;
const GET_CONTENT = gql`
  {
    content @client
    chapterIsFinish @client
    cardTitle @client
    cardLearnPeople @client
    currentCardId @client
    directoryList @client
    copyDirectoryList @client
    currentParentIds @client
  }
`;

const GET_INFO = gql`
  {
    currentCardId @client
    currentParentIds @client
    directoryList @client
    copyDirectoryList @client
    allCardCount @client
    isLearnCardCount @client
    currentParentId @client
    currentParentIds @client
  }
`;
const PUSH_PARENTID = gql`
  {
    currentParentIds @client
  }
`;
const GET_SHOW_DIRECTORY_INFO = gql`
  {
    isShowMobileDirectory @client
  }
`;

const DIRECTORY_LIST = gql`
  {
    directoryList @client
  }
`;
function recursionDir(data, node) {
  // 目录层级结构
  return data.map(item => {
    if (item.id === node.id) {
      item['children'] = node.children;
    }
    if (item.id !== node.id) {
      // 如果第一层不匹配，看看是否有children
      if (item.children) {
        recursionDir(item.children, node);
      }
    }
    // 如果item匹配，那很好直接插入

    return item;
  });
}

// 渲染子目录
const renderSubDir = (
  data,
  loadSubMenu,
  coursePackageId,
  currentCardId,
  client,
  currentParentIds
) => {
  return data.map((subDir, index) => {
    if (subDir.children) {
      return (
        <SubMenu
          key={subDir.id}
          title={subDir.name}
          onTitleClick={item => {
            let openKeys = [];

            if (currentParentIds.indexOf(item.key) === -1) {
              openKeys = currentParentIds.concat(item.key);
            } else {
              openKeys = currentParentIds.filter(key => {
                return key !== item.key;
              });
            }
            client.writeQuery({
              query: PUSH_PARENTID,
              data: { currentParentIds: openKeys }
            });

            loadSubMenu({
              variables: {
                id: item.key,
                coursePackageId: coursePackageId
              }
            });
          }}
        >
          {/* 递归渲染 判断条件是否有children */}
          {renderSubDir(
            subDir.children,
            loadSubMenu,
            coursePackageId,
            currentCardId,
            client,
            currentParentIds
          )}
        </SubMenu>
      );
    }

    // 拆分模块
    if (subDir.fileType === 'FOLDER') {
      return (
        <SubMenu
          key={subDir.id}
          title={subDir.name}
          onTitleClick={item => {
            let openKeys = [];

            if (currentParentIds.indexOf(item.key) === -1) {
              openKeys = currentParentIds.concat(item.key);
            } else {
              openKeys = currentParentIds.filter(item => {
                return item !== item.key;
              });
            }
            client.writeQuery({
              query: PUSH_PARENTID,
              data: { currentParentIds: openKeys }
            });

            loadSubMenu({
              variables: {
                id: item.key,
                coursePackageId: coursePackageId
              }
            });
          }}
        ></SubMenu>
      );
    }
    // 过滤文件名，获取后缀名
    const suffix = subDir.name.slice(subDir.name.lastIndexOf('.') + 1);
    // 后缀名匹配对应的图标 且如果当前的卡片id被选中，则选中不同的图标
    const icon =
      subDir.id === currentCardId
        ? getSelectedSuffix(suffix)
        : getSuffix(suffix);

    return (
      <Menu.Item key={subDir.id} style={{ height: '100%' }} node={subDir}>
        {subDir.fileType === 'FILE' && (
          <>
            {' '}
            {/* 每张卡片是否已经学习的两种状态 */}
            {subDir.isLearned ? (
              <div className='card-isLearned'>
                <img src={icon} alt='图标' />
                {/* 去除文件后缀名 */}
                {/* <div className='card-title-isLearned'>
                  {subDir.name.substring(0, subDir.name.lastIndexOf('.'))}
                </div> */}
                <div className='card-title'>
                  {subDir.name.substring(0, subDir.name.lastIndexOf('.'))}
                </div>
              </div>
            ) : (
              <div
                className='new-routes-study-courseDirectory-1220'
                onClick={event => {
                  // 阻止父标签的点击事件
                  event.stopPropagation();
                  message.warning('请先学完前面的卡片');
                }}
              >
                <img src={icon} alt='图标' />
                {/* 去除文件后缀名 */}
                <div className='card-title-unlock'>
                  {' '}
                  {subDir.name.substring(0, subDir.name.lastIndexOf('.'))}
                </div>
                <img src={lock} style={{ marginLeft: '16px' }} />
              </div>
            )}
          </>
        )}
      </Menu.Item>
    );
  });
};

const Directory = props => {
  const { coursePackageId } = props;

  const client = useApolloClient();
  let shareCardId = '';
  if (window.location.href.split('/').length === 7) {
    shareCardId = window.location.href.split('/')[6];
  }

  const { data: dir } = useQuery(GET_LEARING_CENTER);
  const {
    directoryList,
    currentCardId,
    currentParentId,
    courseId,
    cardTitle,
    currentParentIds,
    isLearnCardCount,
  } = dir;
  const { refetch: updateContent } = useGetSingleCardContentQuery({
    skip: true
  });
  const [unlockCard] = useGetUnlockSingleCardMutation({
    skip: true
  });

  const { refetch: shareCardLink } = useShareCardLinkQuery({ skip: true });

  const { refetch: getNextDirectory } = useGetSubDirectoryQuery({ skip: true });

  const { data, error, loading } = useGetCourseDirectoryQuery({
    variables: {
      id: props.coursePackageId
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      // 回调数据处理
      let topDir = data.node.contents.edges;
      let menu = topDir.map(item => item.node);
      if (menu.length === 0) {
        message.error('该课程无内容!');
        const shortUrl = window.sessionStorage.getItem('shortUrl');
        history.push('/mkt/course/' + shortUrl);
        return;
      }
      if (shareCardId.length > 30) {
        shareCardId = window.btoa('Card:' + shareCardId);

        // 先假设ID是对的
        shareCardLink({
          courseId,
          coursePackgeId: coursePackageId,
          currentCardId: shareCardId
        }).then(res => {
          // 判断是否有祖先数组
          // 如果有，就组合，如果没有，直接请求

          const ancestors = res.data.node.ancestors;
          if (ancestors.length > 0) {
            let takeMyData = null;
            let parentIds = [];
            for (let i = ancestors.length - 1; i > 0; i--) {
              ancestors[i].children.map(item => {
                if (item.id === ancestors[i - 1].id) {
                  item.children = ancestors[i - 1].children;
                }
                return item;
              });
            }
            ancestors.map(item => {
              parentIds.push(item.id);
            });
            takeMyData = ancestors[ancestors.length - 1];
            menu = menu.map(item => {
              if (item.id === takeMyData.id) {
                item.children = takeMyData.children;
              }
              return item;
            });
            let newContent = handleCardContent(
              res.data.node.name,
              res.data.node.content,
              res.data.node.id,
              client
            );

            client.writeQuery({
              query: GET_LEARING_CENTER,
              data: {
                currentCardId: res.data.node.id,
                currentParentId: res.data.node.parent.id,
                currentParentIds: parentIds,
                // isLearnCardCount,
                // allCardCount,
                directoryList: menu,
                content: newContent
              }
            });
          } else {
            // 如果没有祖先
            let newContent = handleCardContent(
              res.data.node.name,
              res.data.node.content,
              res.data.node.id,
              client
            );
            client.writeQuery({
              query: GET_LEARING_CENTER,
              data: {
                currentCardId: res.data.node.id,
                currentParentId: null,
                currentParentIds: [],
                content: newContent,
                directoryList: menu
              }
            });
          }
        });
      } else {
        try {
          const isLearnCardCount = data.node.learnedCount;
          const allCardCount = data.node.fileCardsCount;
          let firstUnlearnedCard = data.node.firstUnlearnedCard;
          if (firstUnlearnedCard !== null) {
            const cardId = data.node.firstUnlearnedCard.id;
            let ancestors = data.node.firstUnlearnedCard.ancestors;
            let parentIds = [];
            if (ancestors.length > 0) {
              //第二层目录的数据
              let takeMyData = null;

              // 如果有祖先Id
              for (let i = ancestors.length - 1; i > 0; i--) {
                ancestors[i].children.map(item => {
                  if (item.id === ancestors[i - 1].id) {
                    item.children = ancestors[i - 1].children;
                  }
                  return item;
                });
                // 父ID
                // parentIds.push(ancestors[i].id);
              }
              ancestors.map(item => {
                parentIds.push(item.id);
              });

              takeMyData = ancestors[ancestors.length - 1];

              // 找到第二层数据之后，再插入对应的第一层目
              menu = menu.map(item => {
                if (item.id === takeMyData.id) {
                  item.children = takeMyData.children;
                }
                return item;
              });
              // 防止，未学习完的卡片报500后，可以显示目录
              client.writeQuery({
                query: DIRECTORY_LIST,
                data: {
                  directoryList: menu
                }
              });
            }

            try {
              //初始化获卡片内容

              updateContent({
                id: cardId,
                coursePackageId: props.coursePackageId,
                courseId: courseId
              }).then(res => {
                try {
                  if (res.data && res.data.node) {
                    let newContent = handleCardContent(
                      res.data.node.name,
                      res.data.node.content,
                      res.data.node.id,
                      client
                    );

                    const newDir = updateIsLearnedState(menu, res.data.node);

                    client.writeQuery({
                      query: GET_CONTENT,
                      data: {
                        copyDirectoryList: newDir,
                        directoryList: newDir,
                        content: newContent,
                        cardTitle: res.data.node.name,
                        cardLearnPeople: res.data.node.learnedPeopleCount
                      }
                    });
                    // 这个缓存放到这里，为了让目录和解锁先在卡片定位和张开目录之前
                    client.writeQuery({
                      query: GET_LEARING_CENTER,
                      data: {
                        currentCardId: cardId,
                        currentParentId: firstUnlearnedCard.parent.id,
                        currentParentIds: parentIds,
                        // 解锁加1
                        isLearnCardCount: isLearnCardCount + 1,
                        allCardCount
                      }
                    });
                  }
                } catch (err) {
                  console.log(err);
                }
              });
              // console.log(isLearnCardCount);
              // client.writeData({
              //   data: { isLearnCardCount: isLearnCardCount + 1 }
              // });
              // 自动解锁第一张
              unlockCard({
                variables: {
                  input: {
                    cardId: window.atob(cardId).split('Card:')[1],
                    coursePackageId: window
                      .atob(coursePackageId)
                      .split('CoursePackage:')[1],
                    visitDuration: 20.1
                  },
                  coursePackageId: coursePackageId
                }
              });
              // 判断卡片进度是否增加
            } catch (err) {}
          }
          if (firstUnlearnedCard === null) {
            let initialId = menu[0];
            let parentIds = [];
            const inserData = function(
              getNextData,
              id,
              coursePackageId,
              directoryList
            ) {
              let child = null;
              let list = [];

              // 拿到第一张卡，遍历里面的层数
              getNextData({ id, coursePackageId }).then(res => {
                try {
                  const node = res.data.node;

                  if (node.children.length !== 0) {
                    parentIds.push(node.id);

                    if (node.children[0].fileType === 'FILE') {
                      list = recursionDir(directoryList, node);

                      child = node.children[0];

                      client.writeQuery({
                        query: GET_INFO,
                        data: {
                          directoryList: list,
                          copyDirectoryList: list,
                          currentCardId: child.id,
                          currentParentIds: parentIds,
                          currentParentId: child.parent.id,
                          isLearnCardCount,
                          allCardCount
                        }
                      });

                      // 获取单张卡片内容
                      updateContent({
                        id: child.id,
                        coursePackageId: coursePackageId,
                        courseId: courseId
                      }).then(res => {
                        try {
                          if (res.data && res.data.node) {
                            let newContent = handleCardContent(
                              res.data.node.name,
                              res.data.node.content,
                              res.data.node.id,
                              client
                            );

                            client.writeQuery({
                              query: GET_CONTENT,
                              data: {
                                content: newContent,
                                cardTitle: res.data.node.name,
                                cardLearnPeople:
                                  res.data.node.learnedPeopleCount,
                                currentParentIds: parentIds
                              }
                            });
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      });
                    }
                    // 判断children是否是文件夹
                    if (node.children[0].fileType === 'FOLDER') {
                      list = recursionDir(directoryList, node);
                      inserData(
                        getNextData,
                        node.children[0].id,
                        coursePackageId,
                        list,
                        courseId
                      );
                    }
                  } else {
                    client.writeQuery({
                      query: GET_INFO,
                      data: {
                        directoryList: directoryList,
                        copyDirectoryList: directoryList,
                        currentCardId: node.id,
                        currentParentIds: [],
                        currentParentId: null,
                        isLearnCardCount,
                        allCardCount
                      }
                    });

                    // 获取单张卡片内容
                    updateContent({
                      id: node.id,
                      coursePackageId: coursePackageId,
                      courseId: courseId
                    }).then(res => {
                      try {
                        if (res.data && res.data.node) {
                          let newContent = handleCardContent(
                            res.data.node.name,
                            res.data.node.content,
                            res.data.node.id,
                            client
                          );

                          client.writeQuery({
                            query: GET_CONTENT,
                            data: {
                              content: newContent,
                              cardTitle: res.data.node.name,
                              cardLearnPeople: res.data.node.learnedPeopleCount
                            }
                          });
                        }
                      } catch (err) {
                        console.log(err);
                      }
                    });
                  }
                } catch (err) {
                  console.log(err);
                }
              });
            };

            // 迭代目录数据，为了初始化第一张卡片内容
            inserData(
              getNextDirectory,
              initialId.id,
              props.coursePackageId,
              menu
            );

            // 恭喜弹窗
            // message.success(
            //   '恭喜你刷完了所有卡片，快去讨论区和同学分享你的学习心得！'
            // );
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  });

  return (
    <Catelogue
      coursePackageId={coursePackageId}
      setSeen={props.setSeen}
      currentParentIds={currentParentIds}
    />
  );
};
const Catelogue = props => {
  const { coursePackageId, currentParentIds } = props;

  // console.log(coursePackageId);
  const client = useApolloClient();
  const [count, setUpdate] = useState(0);

  const {
    data: {
      content,
      currentCardId,
      currentParentId,
      courseId,
      fullScreen,
      cardTitle,
      courseTitle
    }
  } = useQuery(GET_SUBDIR);

  const { data: showMobileDirectoryInfo } = useQuery(GET_SHOW_DIRECTORY_INFO);
  const { isShowMobileDirectory } = showMobileDirectoryInfo;

  const {
    data: { directoryList }
  } = useQuery(GET_WHOLE_DIRECTORY);

  const [getContent, { data, error }] = useLazyQuery(GET_SINGLE_CARD_CONTENT, {
    fetchPolicy: 'network-only'
  });

  const [loadSubMenu, { data: subMenu }] = useLazyQuery(GET_SUB_DIRECTORY, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    try {
      if (subMenu && Object.keys(subMenu).length !== 0) {
        client.writeQuery({
          query: GET_WHOLE_DIRECTORY,
          data: {
            directoryList: recursionDir(directoryList, subMenu.node)
          }
        });
      }

      setUpdate(count => count + 1);
    } catch (error) {
      console.log(error);
    }
  }, [subMenu]);

  //设置卡片问题和回答的目录结构，方便后续上传答案
  const makeExamCardInfo = (cardContent, key) => {
    let position = [];
    let pos = cardContent.indexOf('```');
    while (pos > -1) {
      position.push(pos);
      pos = cardContent.indexOf('```', pos + 1);
    }

    let info = [];
    for (let index = 0; index < position.length; index += 2) {
      let question = cardContent.substring(
        position[index],
        position[index + 1] + 3
      );
      let obj = {
        question: question,
        answers: [],
        isCorrect: false
      };
      info.push(obj);
    }

    let examInfo = { card: key, info: info };
    client.writeQuery({
      query: SET_EXAM_ANSWER_INFO,
      data: { examAnswerInfo: examInfo }
    });
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      let type = data.node.name.substring(
        data.node.name.lastIndexOf('.') + 1,
        data.node.name.length
      );
      let content = {
        type: type,
        title: '',
        front: '',
        back: ''
      };
      let contentData = {};
      switch (type) {
        case 'md':
          contentData = getTextCardContent(data.node.content);
          break;
        case 'video':
          contentData = getVideoCardContent(data.node.content);
          break;
        case 'exam':
          makeExamCardInfo(data.node.content, data.node.id);
          contentData = getExamCardContent(data.node.content);
          break;
        case 'audio':
          contentData = getVideoCardContent(data.node.content);
          break;
        default:
          contentData = getTextCardContent(data.node.content);
          break;
      }

      content.title = contentData.title;
      content.front = contentData.front;
      content.back = contentData.back;

      // console.log(getExamCardContent(data.node.content));

      client.writeQuery({
        query: GET_SUBDIR,
        data: {
          content,
          cardLearnPeople: data.node.learnedPeopleCount,
          currentParentId: data.node.parent ? data.node.parent.id : null,
          currentCardId: data.node.id,
          cardTitle: data.node.name,
        }
      });
      client.writeQuery({
        query: GET_SUBDIR_COLLECT,
        data: {
          contentCardCollect: data.node.isCollected
        }
      });
    }
  }, [data]);

  return (
    <div className='new-routes-study-courseDirectory-container'>
      {isShowMobileDirectory && (
        <div className='new-routes-study-courseDirectory-14283' />
      )}
      <div
        className={isShowMobileDirectory ? 'catalogue-mobile' : 'catalogue'}
        style={
          fullScreen
            ? { width: '460px', position: 'absolute', zIndex: '100' }
            : { width: '300px' }
        }
      >
        {fullScreen ? (
          <div className='routes-course-CardModal-13269'>
            <div className='routes-course-CardModal-13389'>
              {cardTitle.substring(0, cardTitle.lastIndexOf('.'))}
            </div>
            <div className='routes-course-CardModal-13507'>
              <img
                className='routes-course-CardModal-13561'
                onClick={() => {
                  props.setSeen(false);
                }}
                src={cross}
              />
            </div>
          </div>
        ) : (
          <FilterCard />
        )}
        <div className='directoryList-menu'>
          <Menu
            mode={'inline'}
            defaultSelectedKeys={[currentCardId]}
            defaultOpenKeys={currentParentIds}
            selectedKeys={[currentCardId]}
            openKeys={currentParentIds}
            onClick={item => {
              // 获取父ID
              try {
                const parentId =
                  item &&
                  item.item &&
                  item.item.props &&
                  item.item.props.node.parent &&
                  item.item.props.node.parent.id;
                client.writeData({
                  data: {
                    currentCardId: item.key,
                    chapterIsFinish: false,
                    currentParentId: parentId
                  }
                });
                // 获取当前卡片的内容且发送一个学习记录的变更

                getContent({
                  variables: {
                    id: item.key,
                    coursePackageId: coursePackageId,
                    courseId
                  }
                });
                gaEvents.gaEventsUpload({
                  eventName: `点击-课程目录-${courseTitle}-${
                    item.item.props.node.name
                  }`
                });
              } catch (err) {
                console.log(err);
              }
            }}
            overflowedIndicator={<Icon type='caret-down' theme='filled' />}
          >
            {renderSubDir(
              directoryList,
              loadSubMenu,
              coursePackageId,
              currentCardId,
              client,
              currentParentIds
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Directory;
