/**
 * @description 学习中心的头部内容
 * @author ali
 */

import React, { useEffect } from 'react';
import './style.less';

import Title from './Title';
import MenuLink from './MenuLink';
import MobileMenuLink from './MobileMenuLink';
import ProgressBar from './ProgressBar';
import ContinueLearn from './ContinueLearn';
// 第三方组件库
import gql from 'graphql-tag';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import {
  useGetCourseInfoQuery,
  useGetCardProgressQuery
} from '../../../generated/graphql.tsx';
import { history } from '../../IndexPage'
// const GET_COUSE_INFO = gql``;

const GET_COURSE_IFNO = gql`
  {
    courseId @client(always: true)
    coursePackageId @client
    coursePackageAssignmentId @client
    discussCoursePackageId @client
    courseTitle @client
  }
`;

const GET_GROGRESS = gql`
  {
    isLearnCardCount @client
    allCardCount @client
  }
`;

const GET_COURSE_PACKAGE_ID = gql`
  {
    coursePackageId @client
  }
`;
const LearningHeader = props => {
  const client = useApolloClient();
  const href = window.location.href.split('/')[4];
  const shortUrl = window.btoa(`Course:short_url:${href}`);
  window.sessionStorage.setItem('shortUrl', href);
  const { data, error, loading } = useGetCourseInfoQuery({
    variables: { id: shortUrl },
    fetchPolicy: 'network-only'
  });
  const {
    data: { allCardCount, isLearnCardCount }
  } = useQuery(GET_GROGRESS);
  const {
    data: { coursePackageId }
  } = useQuery(GET_COURSE_PACKAGE_ID);
  const { refetch: getCardGrogress } = useGetCardProgressQuery({ skip: true });
  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      let coursePackageAssignmentId, coursePackageId, discussCoursePackageId;
      if(!data.node.isOnline) {
        history.push('/404');
        return;
      }
      data.node.coursesPackages.map(item => {
        if (item.packageTag === 'CONTENT') {
          coursePackageId = item.id;
        }
        if (item.packageTag === 'ASSIGNMENT') {
          coursePackageAssignmentId = item.id;
        }
        if (item.packageTag === 'DISCUSSION') {
          discussCoursePackageId = item.id;
        }
      });

      client.writeQuery({
        query: GET_COURSE_IFNO,
        data: {
          courseTitle: data.node.title,
          courseId: data.node.id,
          coursePackageId,
          coursePackageAssignmentId,
          discussCoursePackageId
        }
      });
    }
  }, [data]);
  // 如果报错则是null

  useEffect(() => {
    if (coursePackageId.length > 0) {
      getCardGrogress({ id: coursePackageId })
        .then(res => {
          client.writeQuery({
            query: GET_GROGRESS,
            data: {
              isLearnCardCount: res.data.node.learnedCount,
              allCardCount: res.data.node.fileCardsCount
            }
          });
        })
        .catch(err => {
          // console.log(err);
        });
    }
  }, [coursePackageId, isLearnCardCount, allCardCount]);
  if (loading || !data) {
    return null;
  }

  return (
    <div className='learning-header-for-mobile'>
      <div className='learning-header'>
        {/* 标题 */}
        <Title title={data.node.title} />

        {/* 课程菜单栏 */}
        <div className='menu'>
          <MenuLink list={data.node.coursesPackages} shortUrl={href} title={data.node.title} />
        </div>

        {/* 学习进度条 */}
        <ProgressBar />

        {/* 继续学习按钮 */}
        <div className='continue-learn'>
          <ContinueLearn continue={props.continue} />
        </div>
      </div>
      <MobileMenuLink list={data.node.coursesPackages} shortUrl={href} title={data.node.title}  />
    </div>
  );
};

export default LearningHeader;
