import styled from 'styled-components';

const Footer : any = styled.div`
  background-color: #F6F6F7;
  width: 380px;
  height: 65px;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

Footer.Cancel = styled.button`
cursor: pointer;
border-radius: 4px;
  background-color: #FFFFFF;
  border: 1px solid #93979F;
  margin-left: 185px;
  width: 80px;
  height: 40px;
  outline: none;
`;

Footer.OK = styled.button`
border-radius: 4px;
cursor: pointer;
  background-color: #1890ff;
  color: #fff;
  margin-left: 15px;
  width: 80px;
  height: 40px;
  outline: none;
`;

export { Footer };