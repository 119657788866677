import * as React from 'react';
import './styles.css';

//引入的库组件
import { Modal } from 'antd';
import { useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import ReactCardFlip from 'react-card-flip';

//引入的其他页面组件
import MyReactMarkdown from '../CommonComponents/MyReactMarkdown';

import TextCard from '../LearningCenter/CourseContent/CardDisplay/TextCard';
import VideoCard from '../LearningCenter/CourseContent/CardDisplay/VideoCard';
import ExamCard from '../LearningCenter/CourseContent/CardDisplay/ExamCard';
import AudioCard from '../LearningCenter/CourseContent/CardDisplay/AudioCard';
import { getTextCardContent, getVideoCardContent, getExamCardContent } from '../../utils';
import makeExamCardInfo from '../../utils/makeExamCardInfo';

//graphql查询
import { useGetCardInfoContentQuery } from '../../generated/graphql';

//引入的资源图片
import left from '../../assets/icons/arrow_left_circle.png';
import right from '../../assets/icons/arrow_right_circle.png';
import rotateImg from '../../assets/v2.1/ic_course_card_turnover.png';
import cross from "../../assets/v2.1/close.png";

const GET_PREVIEW_INFO = gql`
  {
    showPreviewModal @client
    card_list @client
    userInfo @client {
      username
      phone
    }
  }
`;

const EduManagerPreviewPageContainer = () => {
  const client = useApolloClient();

  const { refetch: getCardInfoQuery } = useGetCardInfoContentQuery({
    fetchPolicy: 'network-only',
    skip: true
  });

  const { data: previewInfo } = useQuery(GET_PREVIEW_INFO);
  const { showPreviewModal, card_list, userInfo } = previewInfo;

  const [showLeft, setShowLeft] = React.useState(false);
  const [showRight, setShowRight] = React.useState(false);
  const [cardIndex, setCardIndex] = React.useState(0);
  const [side, setSide] = React.useState(false);
  const [flipSpeeed, setFlipSpeeed] = React.useState(0.5);
  const [content, setContent] = React.useState(null);

  React.useEffect(() => {
    if (showPreviewModal) {
      getCardInfoQuery({ id: card_list[cardIndex] })
        .then(res => {
          if(res.data.node){
            let data = res.data.node;
            let oriContent = data.content;
            let name = data.name;


            let title = "";
            let front = "";
            let back = "";
            let content = {};
            let type = -1;
            if(name.indexOf(".md") !== -1) {
              type = "md" ;
              content = getTextCardContent(oriContent);
            }else if(name.indexOf(".video") !== -1) {
              type = "video" ;
              content = getVideoCardContent(oriContent);
            }else if(name.indexOf(".exam") !== -1) {
              type = "exam" ;
              makeExamCardInfo(oriContent, card_list[cardIndex], client);
              content = getExamCardContent(oriContent);
            }else if(name.indexOf(".audio") !== -1) {
              type = "audio" ;
              content = getVideoCardContent(oriContent);
            }

            content = {
              type: type,
              ...content
            }
            setContent(content);
            setFlipSpeeed(0.5);
          }
        })
        .catch(error => {});

      setSide(false);
      setFlipSpeeed(0);
      if (cardIndex < card_list.length - 1) {
        setShowRight(true);
      } else {
        setShowRight(false);
      }
      if (cardIndex > 0) {
        setShowLeft(true);
      } else {
        setShowLeft(false);
      }
    }
  }, [cardIndex, card_list, showPreviewModal]);

  const onCancel = () => {
    setShowLeft(false);
    setShowRight(false);
    setCardIndex(0);
    setSide(false);
    client.writeData({ data: { showPreviewModal: false, card_list: [] } });
  };

  const renderContent = (content) => {
    switch (content.type) {
      case 'md':
        return <TextCard content={content} />;
      case 'video':
        return <VideoCard content={content} userInfo={userInfo} />;
      case 'exam':
        return <ExamCard content={content} />;
      case 'audio':
        return <AudioCard content={content} />;
      default:
        break;
    }
  };

  return (
    <Modal
      keyboard={false}
      maskClosable={false}
      closable={false}
      maskStyle={{ backgroundColor: '#404040' }}
      visible={showPreviewModal}
      footer={null}
      className='routes-course-CardModal-12137'
      bodyStyle={{
        flexDirection: "column",
        display: 'flex',
        backgroundColor: '#ffffff00',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'fixed',
        left: 0,
        overflow: 'auto',
        height: '100%'
      }}
    >
      <img
        data-rjs='2'
        src={cross}
        className='routes-course-CardModal-14966'
        onClick={onCancel}
      />
      <div className='card-content-display'>
        {showLeft ? (
          <img
            className='preview-prev'
            src={left}
            alt='左图片'
            onClick={() => {
              setCardIndex(cardIndex - 1);
            }}
          />
        ) : (
          <div className='preview-prev-none' />
        )}

        <div className={!side ? 'single-card' : 'single-card-back'}>
          <ReactCardFlip
            className='new-routes-study-cardModal-1189'
            isFlipped={side}
            infinite={false}
            // 用变量控制它
            flipSpeedBackToFront={flipSpeeed}
            flipSpeedFrontToBack={flipSpeeed}
          >
            {/* 正面组件 */}
            <div key='front' className='new-routes-study-cardModal-1580'>
              {/* 翻转标签 */}

              {content && content.back.length > 2 && (
                <div
                  className='new-routes-study-cardModal-1342'
                  onClick={() => {
                    setSide(!side);
                    setFlipSpeeed(0.6);
                  }}
                >
                  <div className='new-routes-study-cardModal-1411'></div>

                  <img
                    data-rjs='2'
                    src={rotateImg}
                    className='new-routes-study-cardModal-1425'
                  />
                </div>
              )}
              {content && renderContent(content)}
            </div>

            {/* 背面组件 */}
            <div key='back' className='new-routes-study-cardModal-1580'>
              {content && content.back.length > 2 && (
                <div
                  className='new-routes-study-cardModal-1342'
                  onClick={() => setSide(!side)}
                >
                  <div className='new-routes-study-cardModal-1411'></div>

                  <img
                    data-rjs='2'
                    src={rotateImg}
                    className='new-routes-study-cardModal-1425'
                  />
                </div>
              )}
              <div className='routes-course-CardModal-4122'>
                <div className='routes-course-CardModal-4451' />
                {/* <ReactMarkdown
                  skipHtml={false}
                  escapeHtml={false}
                  source={content && content.back}
                /> */}
                <MyReactMarkdown source={content && content.back} />
              </div>
            </div>
          </ReactCardFlip>
        </div>
        {showRight ? (
          <img
            className='preview-next'
            src={right}
            alt='右图片'
            onClick={() => {
              setCardIndex(cardIndex + 1);
            }}
          />
        ) : (
          <div className='preview-next-none' />
        )}
      </div>
    </Modal>
  );
};

export default EduManagerPreviewPageContainer;
