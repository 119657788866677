const video = '../assets/v2.1/video-normal.png';
const video_selected = '../assets/v2.1/video-selected.png';
const exam = '../assets/v2.1/aw-normal.png';
const exam_selected = '../assets/v2.1/aw-selected.png';
// const md = '../assets/v2.1/md-normal.png';
const md = './../assets/v2.1/md-normal.png';
const md_selected = '../assets/v2.1/md-selected.png';

const folder = '../assets/icons/file_small_folder.png';
const colorVideo = '../assets/icons/file_label_video.png';
const colorAudio = '../assets/icons/file_label_audio.png';
const colorExam = '../assets/icons/file_label_quiz.png';
const colorMd = '../assets/icons/file_label_markdown.png';

function arrMinNum(arr) {
  var minNum = Infinity,
    index = -1,
    minVul = '';
  for (var i = 0; i < arr.length; i++) {
    if (typeof arr[i] == 'string') {
      if (arr[i].charCodeAt() < minNum) {
        minNum = arr[i].charCodeAt();
        minVul = arr[i];
        index = i;
      }
    } else {
      if (arr[i] < minNum) {
        minNum = arr[i];
        minVul = arr[i];
        index = i;
      }
    }
  }
  return { minNum: minVul, index: index };
}

function arrSortMinToMax(arr) {
  var arrNew = [];
  var arrOld = arr.concat();
  for (var i = 0; i < arr.length; i++) {
    arrNew.push(arrMinNum(arrOld).minNum);
    arrOld.splice(arrMinNum(arrOld).index, 1);
  }
  return arrNew;
}

function arrMaxNum(arr) {
  var maxNum = -Infinity,
    index = -1,
    maxVul = '';
  for (var i = 0; i < arr.length; i++) {
    if (typeof arr[i] == 'string') {
      if (arr[i].charCodeAt() > maxNum) {
        maxNum = arr[i].charCodeAt();
        maxVul = arr[i];
        index = i;
      }
    } else {
      if (arr[i] > maxNum) {
        maxNum = arr[i];
        maxVul = arr[i];
        index = i;
      }
    }
  }
  return { maxNum: maxVul, index: index };
}

function arrSortMaxToMin(arr) {
  var arrNew = [];
  var arrOld = arr.slice(0);
  for (var i = 0; i < arr.length; i++) {
    arrNew.push(arrMaxNum(arrOld).maxNum);
    arrOld.splice(arrMaxNum(arrOld).index, 1);
  }
  return arrNew;
}

function deepCopy(obj) {
  var newobj = obj instanceof Array ? [] : {};
  for (let arr in obj) {
    if (typeof obj[arr] === 'object' && obj[arr] !== null) {
      newobj[arr] = deepCopy(obj[arr]);
    } else {
      newobj[arr] = obj[arr];
    }
  }
  return newobj;
}

function mySerialize(obj, name) {
  var result = '';
  function serializeInternal(o, path) {
    for (let p in o) {
      var value = o[p];
      if (typeof value != 'object') {
        if (typeof value == 'string') {
          result +=
            '\n' +
            path +
            '[' +
            (isNaN(p) ? '"' + p + '"' : p) +
            '] = ' +
            '"' +
            value.replace(/\"/g, '\\"') +
            '"' +
            ';';
        } else {
          result +=
            '\n' +
            path +
            '[' +
            (isNaN(p) ? '"' + p + '"' : p) +
            '] = ' +
            value +
            ';';
        }
      } else {
        if (value instanceof Array) {
          result +=
            '\n' +
            path +
            '[' +
            (isNaN(p) ? '"' + p + '"' : p) +
            ']' +
            '=' +
            'new Array();';
          serializeInternal(
            value,
            path + '[' + (isNaN(p) ? '"' + p + '"' : p) + ']'
          );
        } else {
          result +=
            '\n' +
            path +
            '[' +
            (isNaN(p) ? '"' + p + '"' : p) +
            ']' +
            '=' +
            'new Object();';
          serializeInternal(
            value,
            path + '[' + (isNaN(p) ? '"' + p + '"' : p) + ']'
          );
        }
      }
    }
  }
  serializeInternal(obj, name);
  return result;
}

function getRouterSrc(treeData, key) {
  const routers = [];
  let curKey = key;
  function find(subData) {
    subData.forEach(item => {
      find(item.children);
      if (item.key === curKey) {
        curKey = item.pid;
        routers.push({ title: item.title, key: item.key });
      }
    });
  }
  find(treeData);
  return routers;
}

function getChildren(tree, key) {
  let children = [];

  function find(subTree) {
    for (let i = 0; i < subTree.length; i++) {
      if (subTree[i].key === key) {
        children = subTree[i].children;
        break;
      }
      find(subTree[i].children);
    }
    find(tree);
    return children;
  }
}

function getTextCardContent(content) {
  let getContent = {
    title: '',
    front: '',
    back: ''
  };

  let mainContent = content.replace(/\n/g, '<br/>');
  mainContent = mainContent.split('<br/>');

  let isFront = false;
  let isBack = false;

  mainContent.map((item, index) => {
    if (item.indexOf('title:') != -1) {
      getContent.title = item.split('title:')[1].trim();
    }
    if (isFront) {
      if (item.indexOf('# back') != -1 || item.indexOf('#back') != -1) {
        isFront = false;
      } else {
        if (
          mainContent[index + 1].indexOf('# back') != -1 ||
          mainContent[index + 1].indexOf('#back') != -1
        ) {
          getContent.front = getContent.front + item;
        } else {
          getContent.front = getContent.front + item + '\n';
        }
      }
    }
    if (item.indexOf('front') != -1) {
      isFront = true;
    }
    if (isBack) {
      if (index + 1 >= mainContent.length) {
        getContent.back = getContent.back + item;
      } else {
        getContent.back = getContent.back + item + '\n';
      }
    }
    if (item.indexOf('# back') != -1 || item.indexOf('#back') != -1) {
      isBack = true;
    }
  });

  return getContent;
}

function getVideoCardContent(content) {
  let getContent = {
    title: '',
    front: '',
    back: ''
  };

  let mainContent = content.replace(/\n/g, '<br/>');
  mainContent = mainContent.split('<br/>');

  let isBack = false;

  mainContent.map((item, index) => {
    if (item.indexOf('title:') != -1) {
      getContent.title = item.split('title:')[1].trim();
    }
    if (item.indexOf('url:') != -1) {
      getContent.front = item
        .split('url:')[1]
        .split('```')[0]
        .trim();
    }
    if (isBack) {
      if (index + 1 >= mainContent.length) {
        getContent.back = getContent.back + item;
      } else {
        getContent.back = getContent.back + item + '\n';
      }
    }
    if (item.indexOf('back') != -1) {
      isBack = true;
    }
  });

  return getContent;
}

function getExamCardContent(content) {
  let getContent = {
    title: '',
    front: {
      exam: []
    },
    back: ''
  };

  let mainContent = content.replace(/\n/g, '<br/>');
  mainContent = mainContent.split('<br/>');

  let isBack = false;

  mainContent.map((item, index) => {
    if (item.indexOf('title:') != -1) {
      getContent.title = item.split('title:')[1].trim();
    }
    if (isBack) {
      if (index + 1 >= mainContent.length) {
        getContent.back = getContent.back + item;
      } else {
        getContent.back = getContent.back + item + '\n';
      }
    }
    if (item.indexOf('back') != -1) {
      isBack = true;
    }
  });

  let position = [];
  let pos = content.indexOf('```');
  while (pos > -1) {
    position.push(pos);
    pos = content.indexOf('```', pos + 1);
  }

  for (let index = 0; index < position.length; index += 2) {
    let question = content.substring(position[index], position[index + 1] + 3);

    let isSingle = question.indexOf('SQ') != -1;

    let questionObj = isSingle
      ? { is_single: 'true', sq: [], aswt: [], asw: [] }
      : { is_single: 'false', mq: [], aswt: [], asw: [] };

    question = question.replace(/\n/g, '<br/>');
    question = question.split('<br/>');

    question.map(item => {
      if (item.indexOf('Q:') != -1) {
        isSingle
          ? questionObj.sq.push(item.split('Q:')[1].trim())
          : questionObj.mq.push(item.split('Q:')[1].trim());
      }
      if (item.indexOf('ASWT') != -1) {
        questionObj.aswt.push(item.split('ASWT:')[1].trim());
        questionObj.asw.push(item.split('ASWT:')[1].trim());
      }
      if (item.indexOf('ASWF') != -1) {
        questionObj.asw.push(item.split('ASWF:')[1].trim());
      }
    });
    getContent.front.exam.push(questionObj);
  }
  return getContent;
}

function getSuffix(suffix) {
  switch (suffix) {
    case 'md':
      return md;
    case 'exam':
      return exam;
    case 'video':
    case 'audio':
      return video;
    default:
      return folder;
  }
}

function findFlag(data) {
  let sign = '';
  try {
    sign = data[0].sign;
  } catch (eror) {
    sign = '';
  }

  let flag = '';
  let isFindSign = false;
  function find(subData) {
    for (let i = 0; i < subData.length; i++) {
      if (flag !== '' || isFindSign) {
        break;
      }
      if (subData[i].key === sign) {
        isFindSign = true;
        break;
      }
      if (subData[i].is_study === 0 && subData[i].type === 1) {
        flag = subData[i].key;
        break;
      }
      find(subData[i].children);
    }
  }
  if (!isFindSign) {
    find(data);
  }

  return flag;
}

function getPid(data, key) {
  let result = '';
  let index = 0;
  function find(subData, subKey) {
    for (let i = 0; i < subData.length; i++) {
      if (subData[i].key === subKey && subData[i].pid !== data[0].key) {
        find(data, subData[i].pid);
        break;
      } else if (subData[i].key === subKey && subData[i].pid === data[0].key) {
        result = subData[i].key;
        index = i;
      } else {
        find(subData[i].children, subKey);
      }
    }
  }
  find(data, key);
  return { key: result, index };
}

function getEndKeys(treeData) {
  const keys = [];
  function find(subData) {
    const keyLength = subData.filter(item => {
      return item.key === 1;
    }).length;
    subData.forEach((value, index) => {
      if (index === keyLength) {
        keys.push(value.key);
      }
    });
    subData.forEach(item => {
      find(item.children);
    });
  }
  find(treeData);
  return keys;
}
function changeToMarkdownFormat(text) {
  return text.replace(/\n/g, '  \n');
}

// 目录插入递归函数
function recursionDir(data, node) {
  // 目录层级结构

  return data.map(item => {
    if (item.id === node.id) {
      item['children'] = node.children;
    }
    if (item.id !== node.id) {
      // 如果第一层不匹配，看看是否有children
      if (item.children) {
        recursionDir(item.children, node);
      }
    }
    // 如果item匹配，那很好直接插入

    return item;
  });
}
module.exports = {
  arrSortMaxToMin,
  arrSortMinToMax,
  deepCopy,
  mySerialize,
  getRouterSrc,
  getChildren,
  getTextCardContent,
  getVideoCardContent,
  getExamCardContent,
  getSuffix,
  findFlag,
  getPid,
  getEndKeys,
  changeToMarkdownFormat,
  recursionDir
};
