import { DiscussionOrder } from '../generated/graphql';

const discusspage = {
    discussSearchContent: "",
    discussPageContent: "",
    discussSelfDiscussTitle: "",
    discussSelectedTags: [],
    disc: {page: 0, count: 0, __typename: "Disc"},
    filterSelectedTags: [],
    aboutMine: false,
    discPage: 1,
    discussStatus : "0",
    discussTag: { tag: "0", label: "标签", __typename: "DiscussTag" },
    discussSort: { type: DiscussionOrder.DescInsertedAt, label: "按创建时间排序", __typename: "DiscussSort" },
    tagsInDiscuss: [],
    discKey: "",
    discLoading: { loading: false, tip: '', __typename: "DiscLoading" },
    discData: [],
    disscussPageInfo: null,

    isShowDiscList: true,
    isModifyDisc: false,
    isCheckDisc: false,
    discussMarkdownContent: "",

    discussDetailId: "",
    discussCommentPage: 1,

    //某些可能的默认值?
    discussCountPerPage: 20,
};

export default discusspage;