import * as React from 'react';
import './style.less';

//引入的库组件

//引入的其他页面组件
import MyReactMarkdown from '../../../CommonComponents/MyReactMarkdown';
import { CustomP } from '../../../CommonComponents/common/customp';

//graphql查询

//引入的资源图片

interface Props {
  content: any;
}

const TextCard: React.FC<Props> = ({ content }) => {
  return (
    <div className='routes-course-CardModal-4122'>
      <div>
        <CustomP
          customStyle={{
            line: 2,
            fontSize: '26px',
            color: '#282A2D',
            lineHeight: '37px'
          }}
          style={{ width: 680, height: 'auto' }}
          className='routes-course-CardModal-4351'
        >
          {content.title}
        </CustomP>
        <div className='routes-course-CardModal-4451' />
        <MyReactMarkdown source={content.front} />
      </div>
    </div>
  );
};

export default TextCard;
