import * as React from 'react';
import './style.less';

//引入的库组件
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import ContextMenuTrigger from 'react-contextmenu/es6/ContextMenuTrigger';

//引入的其他页面组件
import { CustomP } from '../../../CommonComponents/common/customp';
import VideoMarquee from '../../../CommonComponents/VideoMarquee/VideoMarquee';
import headers from '../../../../global_variable/headers';

//graphql查询

//引入的资源图片

class VideoCard extends React.Component {
  constructor(props) {
    super(props);

    this.playerInit = null;
  }
  initPlayer(url, $player) {
    const { userInfo } = this.props;
    const $video = $player;

    if ($video) {
      if (!this.playerInit) {
        this.playerInit = videojs(
          'my_video',
          {
            autoplay: false,
            controls: true,
            playbackRates:[0.5, 1, 1.25, 1.5, 2]
          },
          function onPlayerReady() {
            let playerInit = this;
            playerInit.src(url);
          }
        );
      } else {
        this.playerInit.src(url);
        this.playerInit.load(url);
      }
      videojs.Hls.xhr.beforeRequest = function(options) {
        if (options.uri.indexOf('myqcloud.com') !== -1) {
          options.headers = {};
        } else {
          options.headers = {
            Authorization: headers.Authorization
          };
        }

        return options;
      };

      let marqueeStr = userInfo.username + ':' + userInfo.phone;
      VideoMarquee.initMarqueeComponent('my_video', marqueeStr);
    }

    // if ($video) {
    //   if (!$video.id) {
    //     this.disposePlayers();
    //   }

    //   this.playerInit = videojs($video, {
    //     id: 'my_video',
    //     autoplay: false,
    //     controls: true
    //   });

    //   if ($video.id) {
    //     this.playerInit.src(url);
    //     this.playerInit.load(url);
    //   }

    //   videojs.Hls.xhr.beforeRequest = function(options) {
    //     if (options.uri.indexOf('myqcloud.com') !== -1) {
    //       options.headers = {};
    //     } else {
    //       options.headers = {
    //         Authorization: headers.Authorization
    //       };
    //     }

    //     return options;
    //   };

    //   let marqueeStr = userInfo.username + ':' + userInfo.phone;
    //   VideoMarquee.initMarqueeComponent('my_video', marqueeStr);
    // }
  }

  disposePlayers() {
    Object.keys(videojs.players).forEach(player => {
      if (videojs.players[player]) {
        videojs.players[player].dispose();
      }
    });
  }

  componentWillUnmount() {
    this.disposePlayers();
    VideoMarquee.deleteExistedMarquee();
  }

  render() {
    const { content } = this.props;
    let url = content.front;
    url = url.replace('http:', 'https:');

    return (
      <div className='routes-course-CardModal-4822'>
        <div>
          <CustomP
            customStyle={{
              line: 2,
              fontSize: '26px',
              color: '#282A2D',
              lineHeight: '37px'
            }}
            style={{ width: 680, height: 'auto' }}
            className='routes-course-CardModal-5220'
          >
            {content.title}
          </CustomP>
          {url.trim() !== '' ? (
            <div className='routes-course-CardModal-5310'>
              <ContextMenuTrigger id='player'>
                <video
                  id='my_video'
                  ref={this.initPlayer.bind(this, url.trim())}
                  width={680}
                  height={384}
                  className='vjs-matrix vjs-big-play-centered video-js routes-course-CardModal-5310-mobile'
                >
                  <source src={url.trim()} />
                </video>
              </ContextMenuTrigger>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default VideoCard;
