import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { withRouter } from 'react-router';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import RejectModalCallback from './RejectModalCallbackFunc';

//graphql查询

//引入的资源图片

const GET_REJECT_MODAL_INFO = gql`
  {
    isShowRejectModal @client
  }
`;

const RejectModal = (props: any) => {
  const client = useApolloClient();
  const { data: rejectModalInfo } = useQuery(GET_REJECT_MODAL_INFO);
  const { form } = props;

  const onCancel = () => {
    RejectModalCallback.callback = null;
    client.writeData({ data: { isShowRejectModal: false } });
  };

  const onOk = () => {
    form.validateFieldsAndScroll((errors: any, values: any) => {
      if (errors) {
        return;
      }
      client.writeData({ data: { isShowRejectModal: false } });
      if (RejectModalCallback.callback) {
        RejectModalCallback.callback(values.message);
        RejectModalCallback.callback = null;
      }
    });

    // this.props.form.validateFieldsAndScroll((errors, values) => {
    //   if (errors) {
    //     return;
    //   }
    //   this.props.dispatch({ type: 'course/refuseMerge', payload: { messageid: this.props.selectMsgID, key: this.props.ass.key, id: this.props.ass.id, message: values.message } });
    // });
  };

  return (
    <Modal
      afterClose={() => {
        form.resetFields();
      }}
      maskClosable={false}
      title={'拒绝变更申请'}
      visible={rejectModalInfo.isShowRejectModal}
      onOk={onOk}
      onCancel={onCancel}
      width={380}
      okText="确定"
      cancelText="取消"
      bodyStyle={{
        width: 380,
        height: 200,
        paddingLeft: 48,
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Form>
        <Form.Item hasFeedback>
          {form.getFieldDecorator('message', {
            rules: [{ required: true, message: '请输入变更说明' }]
          })(
            <TextArea
              placeholder={'请输入拒绝原因，不超过200字'}
              className='routes-study-rejectModal-1562'
              maxLength={200}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const RejectModalWrapper = Form.create()(withRouter(RejectModal));

export default RejectModalWrapper;
