import React, { FC, useEffect } from 'react';
import { useApolloClient } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import { Spin } from 'antd';
import HomePageBanner from '../HomePageBanner';
import Colleges from './components/Colleges';
import Coach from './components/Coach';
import Student from './components/Student';
import { LIST_COLLEGE_COURSES } from './query';
import { query, writeQuery } from './cache';
import { mapCollegeCourses, variables } from './utils';
import './styles/index.less';

const HomePageContainer: FC = () => {
  const client = useApolloClient();

  // 读取缓冲
  const { data: cache } = useQuery(query);
  const { colleges, coachs, collegeCourses, studentCourses } = cache.home;

  // 获取课程数据
  const { data, loading } = useQuery(LIST_COLLEGE_COURSES, { variables });

  // 课程数据写入缓冲
  useEffect(() => {
    try {
      if (data && Object.keys(data).length !== 0) {
        const { edges } = data.courses;
        // 学院课程
        const collegeCourses = edges.map((item: any) => {
          if (item.node.category) {
            return {
              title: item.node.title,
              cover: item.node.cover,
              subscribedCount: item.node.subscribedCount,
              shortUrl: item.node.shortUrl,
              college: item.node.category.parent.name
            };
          }
        });

        // 学生作品
        const studentCourses = edges
          .map((item: any) => {
            if (item.node.labels.length !== 0) {
              if (item.node.labels.includes('信息分析结业BP')) {
                return {
                  title: item.node.title,
                  cover: item.node.cover,
                  shortUrl: item.node.shortUrl
                };
              }
            }
          })
          .filter((item: any) => item !== undefined)
          .slice(0, 4);

        // 写入本地缓冲
        client.writeQuery({
          query: writeQuery,
          data: {
            home: {
              collegeCourses: mapCollegeCourses(colleges, collegeCourses),
              studentCourses,
              __typename: 'home'
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  if ((studentCourses.length === 0 && collegeCourses.length === 0) || loading) {
    return (
      <div id='loading'>
        <Spin size='large' />
      </div>
    );
  }

  return (
    <div className='home-page-container'>
      <HomePageBanner />
      <Colleges collegeCourses={collegeCourses} />
      <Coach coach={coachs} />
      <div className='line'></div>
      {/* <Student studentCourses={studentCourses} /> */}
    </div>
  );//student 为原学员作品显示
};

export default HomePageContainer;
