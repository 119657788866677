import * as React from 'react';
import './styles.css';

//引入的库组件

//引入的其他页面组件
import DiffView from './DiffView';

//graphql查询

//引入的资源图片

const DiffViewContainer = (props: any) => {
  return <DiffView {...props} />;
};

export default DiffViewContainer;
