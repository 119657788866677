import React, { FC, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, message, Spin } from 'antd';
import { useQuery, useMutation, useApolloClient } from 'react-apollo-hooks';
import { GET_USER_MESSAGE, UPDATE_MESSAGE, GET_PARENT_ID } from '../query';
import { query } from '../cache';
import MessageItem from './MessageItem';
import empty1s from 'assets/omcbg/empty1s.png';

import {
  useDiffChangesInMessageQuery,
  DiffType
} from '../../../generated/graphql';
import { Base64 } from 'js-base64';

interface IMessageProps extends RouteComponentProps {
  category: 'COLLABORATIVE' | 'DIRECTIONAL' | 'COMMENT';
}

const Message: FC<IMessageProps> = ({ category, history }) => {
  const client = useApolloClient();
  // 获取当前消息条数
  const { data: cache } = useQuery(query);
  const { all } = cache.badge;

  const [page, setPage] = useState(1);
  const { data, refetch } = useQuery(GET_USER_MESSAGE, {
    variables: {
      filter: { category },
      first: 10 * page
    },
    fetchPolicy: 'network-only'
  });
  const { refetch: refetchPid } = useQuery(GET_PARENT_ID, {
    skip: true
  });
  const [updateMessage] = useMutation(UPDATE_MESSAGE);
  const [messages, setMessages] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [spinTip, setSpinTip] = useState('');

  const { refetch: diffChangesInMessageQuery } = useDiffChangesInMessageQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    try {
      if (data && Object.keys(data).length !== 0) {
        const { edges, pageInfo } = data.me.messages;
        const messages = edges.map((item: any) => item.node);
        const { hasNextPage } = pageInfo;
        setMessages(messages);
        setHasNextPage(hasNextPage);
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  const handleMessageClick = (item: any) => {
    // 已读
    if (item.state !== 'VIEWED') {
      const id = window.atob(item.id).split('Message:')[1];
      updateMessage({
        variables: { input: { id } }
      }).then(res => {
        const { errors } = res.data.updateMessage;
        if (errors) {
          message.error(`${errors[0].message}`);
        } else {
          refetch();
        }
      });
      // 消息 - 1
      client.writeData({
        data: {
          badge: { all: all - 1 < 0 ? 0 : all - 1, __typename: 'Badge' }
        }
      });
    }

    // 评论链接跳转对应作业或者讨论  消息点击已读。申请点击跳转合并流程

    // COLLABORATIVE - 申请
    // DIRECTIONAL - 通知
    // COMMENT - 评论
    switch (category) {
      case 'COMMENT':
        const { card_id, course_short_url, tag } = item.info.comment;
        const option: any = {
          discussion: 'discussion',
          assignment: 'homework'
        };
        if (tag === 'assignment') {
          refetchPid({ id: window.btoa(`Assignment:${card_id}`) }).then(res => {
            const id = res.data.node.pid;
            history.push(`/stu/${course_short_url}/${option[tag]}/${id}`);
          });
        } else {
          history.push(`/stu/${course_short_url}/${option[tag]}/${card_id}`);
        }

        break;
      case 'COLLABORATIVE':
        const {
          user_id,
          state,
          mastered_package_id,
          branched_package_id
        } = item.info.collaborative;
        const { avatar, nickname } = item.poster;
        const content = item.content;
        const description = item.description;
        const msgId = item.id;
        if (state === 'submitted') {
          setIsLoading(true);
          setSpinTip('正在对比修改');
          diffChangesInMessageQuery({
            currentPackageId: Base64.encode('Package:' + mastered_package_id),
            targetPackageId: Base64.encode('Package:' + branched_package_id),
            diffType: DiffType.MasterMergeBranch
          })
            .then((res: any) => {
              if (res.data.diff.changes) {
                const mergeInfo = {
                  __typename: 'MergeInfo',
                  currentPackageId: mastered_package_id,
                  targetPackageId: branched_package_id,
                  msgId: msgId,
                  diffChangesStr: res.data.diff.changes,
                  head_img: avatar,
                  user: nickname,
                  content: content,
                  description: description,
                  status: state
                };
                client.writeData({ data: { mergeInfo: mergeInfo } });
                history.push('/edu/diff/mergetomaster');
              }
              setIsLoading(false);
              setSpinTip('');
            })
            .catch((error: any) => {
              setIsLoading(false);
              setSpinTip('');
            });
        }else {
          if(state === 'rejected'){
            message.warning("该请求已拒绝!");
          }else {
            message.warning("该请求已合并!");
          }
        }
        break;
      default:
        break;
    }
  };

  return (
    <Spin size='large' spinning={isLoading} tip={spinTip}>
      <div className='msg-tabs-item'>
        {messages.length === 0 ? (
          <div className='msg-tabs-item-none'>
            <img src={empty1s} alt='还没有消息' />
            <p>还没有消息～</p>
          </div>
        ) : (
          <>
            {messages.map((item: any, index: number) => (
              <MessageItem
                key={index}
                src={item.poster.avatar}
                content={item.content}
                description={item.description}
                state={item.state}
                onClick={() => handleMessageClick(item)}
              />
            ))}
            {hasNextPage && (
              <Button
                type='dashed'
                style={{ margin: '100px 0' }}
                onClick={() => setPage(page => page + 1)}
              >
                查看更多
              </Button>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default withRouter(Message);
