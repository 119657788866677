import gql from 'graphql-tag';

export const query = gql`
  {
    my @client {
      user
      basicProps
      detailedProps
      avatar
    }
    userInfo @client {
      token
    }
  }
`;

export const writeQuery_user = gql`
  {
    my @client {
      user
      basicProps
      detailedProps
      avatar
    }
  }
`;

export const writeQuery_basic = gql`
  {
    my @client {
      basicProps
    }
  }
`;

export const writeQuery_detailed = gql`
  {
    my @client {
      detailedProps
    }
  }
`;

export const writeQuery_avatar = gql`
  {
    my @client {
      avatar
    }
  }
`;
