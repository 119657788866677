import * as React from 'react';
import './styles.css';
import 'react-diff-view/style/index.css';

//引入的库组件
import { withRouter } from 'react-router';
import { Layout, Breadcrumb } from 'antd';
import { parseDiff, Diff, Hunk } from 'react-diff-view';
import { diffLines, formatLines } from 'unidiff';

//引入的其他页面组件
import { createFilenameSelector } from './selector';
import ItemRender from '../ItemRender';
import { getGitDiffType } from './git';
import DiffViewShowDiff from './DiffViewShowDiff';
import DiffViewShowAll from './DiffViewShowAll';

//graphql查询

//引入的资源图片
import backImg from '../../../assets/icons/back.png';

const DiffView = props => {
  const { currentCommit, lastCommit, showAll, showType } = props;

  if (lastCommit !== null) {
    return <DiffViewShowAll {...props} />;
  } else {
    return <DiffViewShowDiff {...props} />;
  }

  // return <DiffViewShowAll {...props} />;
};

export default withRouter(DiffView);
