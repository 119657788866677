import * as React from 'react';
import './styles.css';

//引入的库组件
import { Modal, Form, Row, message, Col, Button, Input } from 'antd';
import { withRouter } from 'react-router';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import RenameModalCallback from './RenameModalCallbackFunc';
import { CustomP } from '../CommonComponents/common/customp';
import { getSuffix } from '../../utils';
import { CustomTree } from '../CommonComponents/layout/customTree';
import ComponentFunction from '../EduManagerPackagePage/ComponentFunction';

//graphql查询
import { useGetEduManagerCoursesQuery } from '../../generated/graphql';

//引入的资源图片
import file_small_folder from '../../assets/icons/file_small_folder.png';
import root from '../../assets/icons/file_medium_root.png';
import folder from '../../assets/icons/file_medium_folder.png';
import root_while from '../../assets/icons/file_medium_root_white.png';
import folder_while from '../../assets/icons/file_medium_folder_white.png';

const contentLayout: any = { justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' };

const GET_RENAME_MODAL_INFO = gql`
  {
    isShowRenameModal @client
    renameName @client
  }
`;

const GET_RENAME_MODAL_DATA = gql`
  {
      treeData @client
      cooperationTreeData @client
  }
`

const RenameModal = (props: any) => {
  const { form } = props;
  const { getFieldDecorator } = form;
  const client = useApolloClient();
  const { data: renameModalInfo } = useQuery(GET_RENAME_MODAL_INFO);
  const {
    isShowRenameModal,
    renameName,
  } = renameModalInfo;

  const onCancel = () => {
    RenameModalCallback.callback = null;
    client.writeData({ data: { isShowRenameModal: false } });
  };

  const rename = () => {
    form.validateFieldsAndScroll((errors: any, values: any) => {
      if (errors) {
        false && console.log('tag');
      } else {
        client.writeData({ data: { isShowRenameModal: false } });
        let fileType = renameName.lastIndexOf('.') === -1 ? 0 : 1
        let fileName = values.fileName + (fileType === 0 ? "" : ("." + renameName.substring(renameName.lastIndexOf('.') + 1, renameName.length)));
        if(RenameModalCallback.callback) {
          RenameModalCallback.callback(fileName);
          RenameModalCallback.callback = null;
        }
      }
    });
  };

  const icon = getSuffix(renameName.substring(renameName.lastIndexOf('.') + 1, renameName.length));

  return (
    <Modal
    maskClosable={false}
    title="重命名"
    visible={isShowRenameModal}
    onCancel={onCancel}
    width={380}
    okText="确定"
    cancelText="取消"
    bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    footer={
      <Row>
        <Col span={6} offset={12}><Button onClick={onCancel}>取消</Button></Col>
        <Col span={6}><Button
          onClick={rename}
          className="edu-renameModal-2401"
        >确定</Button></Col>
      </Row>
    }
  >
    <Form style={contentLayout}>
      {/* <Row>
        <Col span={2}>
          <Icon type="folder" />
        </Col>
        <Col span={12}>{this.props.renameName}</Col>
      </Row>*/}
      <div className="edu-renameModal-2757">
        <img src={icon} />
        <CustomP customStyle={{ fontSize: 14, color: '#93979F', line: 1 }} className="edu-renameModal-2930">{renameName}</CustomP>
      </div>
      <Form.Item hasFeedback className="edu-renameModal-3029">
        {getFieldDecorator('fileName', { rules: [{ required: true, message: '请输入名称' }] })(
          <Input
            className="edu-renameModal-3207"
            placeholder="请输入新名称"
          />,
                        )}
      </Form.Item>
    </Form>
  </Modal>
  );
};
const RenameModalWrapper = Form.create()(withRouter(RenameModal));

export default RenameModalWrapper;
