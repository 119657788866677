import React, { FC, useEffect, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Markdown from 'react-markdown';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { useApolloClient } from 'react-apollo';
import { CREATE_PURCHASE, GET_COURSE_SUBSCRIBED } from '../query';
import {
  query,
  writeQuery_payVisible,
  writeQuery_popVisible,
  writeQuery_btn
} from '../cache';
import { Button, message, Modal, Popconfirm } from 'antd';
import moment from 'moment';
import QRCode from 'qrcode.react';
import learningCenter from '../../../global_variable/learningCenter';
import discusspage from '../../../global_variable/discusspage';
import { useGetCourseSubscribedQuery } from '../../../generated/graphql';

const renderImage = (props: any) => (
  <img {...props} style={{ maxWidth: '100%' }} />
);

const mappings: { [key: string]: string } = {
  效率学院: 'AI课堂',
  认知学院: '认知课堂',
  写作学院: '创作课堂',
  教练学院: '人生课堂',
  阅读学院: '阅读课堂',
  "OpenMind+": 'OpenMind+'
}

const Content: FC<RouteComponentProps & { refetch: any; course_Id: any }> = ({
  history,
  refetch,
  course_Id
}) => {
  const client = useApolloClient();

  // 读取缓冲信息
  const { data: cache } = useQuery(query);
  const {
    isLoggedIn,
    courseDetail: { course, payVisible, popVisible, disabled, btnText },
    userInfo: { userid, userProfile }
  } = cache;

  // 写入缓冲
  const writeQuery = (query: any, data: any) => {
    client.writeQuery({
      query,
      data: {
        courseDetail: {
          ...data,
          __typename: 'courseDetail'
        }
      }
    });
  };

  // 报名
  const [createPurchase] = useMutation(CREATE_PURCHASE);

  const { refetch: getSubscribed } = useGetCourseSubscribedQuery({
    skip: true,
    variables: { id: course_Id },
    fetchPolicy: 'no-cache'
  });

  // if (isPower && payVisible) {
  //   if (isPower!.node!.isSubscribed) {
  //     stopPolling();
  //     writeQuery(writeQuery_payVisible, {
  //       payVisible: false
  //     });
  //     message.success('购买成功');
  //     refetch();
  //   }
  // }
  // useEffect(() => {
  //   console.log("isPower=============");
  //   console.log(isPower);
  //   console.log("isPower=============");
  //   if (isPower && payVisible) {
  //     if (isPower!.node!.isSubscribed) {
  //       stopPolling();
  //       writeQuery(writeQuery_payVisible, {
  //         payVisible: false
  //       });
  //       message.success('购买成功');
  //       refetch();
  //     }
  //   }
  // }, [isPower ? isPower!.node!.isSubscribed : isPower, payVisible]);

  // useEffect(() => {
  //   if (payVisible) {
  //     isPowerRefetch({ id: course_Id });
  //   }
  // }, [payVisible]);

  // 课程按钮逻辑再次说明：
  // 当前用户有课程权限 —— 进入课程，点击进入课程内容
  // 当前用户有课程权限&课程已结业 —— 已结业，无法点击
  // 当前用户没有课程权限，在报名时间内 —— 我要报名，付费课程点击弹出付费二维码，免费课程点击后提示报名成功
  // 当前用户没有课程权限，在报名时间之前 —— 敬请期待，无法点击
  // 当前用户没有课程权限，在报名时间之后 —— 已开课，无法点击

  useEffect(() => {
    if (course) {
      const date = moment(Date.now()).format('YYYY-MM-DD');
      const {
        isSubscribed,
        registrationEnd,
        registrationBegin,
        isEnd
      } = course;

      let disabled = false;
      let btnText = '';

      if (!isLoggedIn || !isSubscribed) {
        if (moment(date).isBefore(registrationBegin)) {
          disabled = true;
          btnText = '敬请期待';
        } else if (moment(date).isAfter(registrationEnd)) {
          disabled = true;
          btnText = '已开课';
        } else {
          disabled = false;
          btnText = '我要报名';
        }
      } else {
        if (isEnd) {
          disabled = true;
          btnText = '已结业';
        } else {
          disabled = false;
          btnText = '进入课程';
        }
      }

      writeQuery(writeQuery_btn, {
        disabled,
        btnText
      });
    }
  }, [course]);

  const pay = () => {
    getSubscribed().then((res: any) => {
      if (res.data.node.isSubscribed) {
        writeQuery(writeQuery_payVisible, {
          payVisible: false
        });
        message.success('购买成功');
        refetch();
      }
    });
  }

  const intervalRef = useRef();

  useEffect(() => {
    if(payVisible) {
      const time: any = setInterval(() => {
        pay();
      }, 1000);
      intervalRef.current = time;
    }else {
      if(intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [payVisible]);

  if (!course) return null;

  // 课程详情数据
  const {
    title,
    cover,
    subTitle,
    category,
    isFee,
    id,
    price,
    shortUrl,
    chargingLink,
    needUserProfile,
    description
  } = course;

  const handleClick = () => {
    if (isLoggedIn) {
      if (btnText === '进入课程') {
        if (needUserProfile) {
          if (userProfile) {
            history.push(`/stu/${shortUrl}/content`);
          } else {
            // writeQuery(writeQuery_popVisible, {
            //   popVisible: true
            // });
            message.warning('上课前请先完善个人信息!');
            history.push('/my');
          }
        } else {
          client.writeData({
            data: {
              ...discusspage
            }
          });
          history.push(`/stu/${shortUrl}/content`);
        }
      } else {
        if (isFee) {
          // startPolling(1000);
          writeQuery(writeQuery_payVisible, {
            payVisible: true
          });
        } else {
          const courseId = window.atob(id).split('Course:')[1];
          const userId = window.atob(userid).split('User:')[1];
          createPurchase({
            variables: {
              input: {
                courseId,
                from: 'WEB',
                userId
              }
            }
          }).then(res => {
            const { errors } = res.data.createPurchase;
            if (errors) {
              message.error(`${errors[0].message}`);
            } else {
              message.success('报名成功');
              refetch();
            }
          });
        }
      }
    } else {
      client.writeData({ data: { isShowLoginModal: true } });
    }
  };

  return (
    <div className='course-detail-content'>
      <div className='course-detail-content-show'>
        <div className='course-detail-content-show-header'>
          <img src={cover} alt='cover' />
          <div className='course-detail-info'>
            <div className='course-detail-info-title'>
              <h2>{title}</h2>
              {category && (
                <div className='course-detail-info-title-category'>
                  {mappings[category.parent.name]}
                </div>
              )}
            </div>
            <p className='course-detail-info-subTitle'>{subTitle}</p>
            <div className='course-detail-info-button'>
              <Popconfirm
                placement='right'
                title='进入该课程需要完善您的个人信息'
                visible={popVisible}
                onConfirm={() => {
                  writeQuery(writeQuery_popVisible, {
                    popVisible: false
                  });
                  history.push('/my');
                }}
                onCancel={() => {
                  writeQuery(writeQuery_popVisible, {
                    popVisible: false
                  });
                }}
                okText='前去完善'
                cancelText='取消'
              >
                <Button
                  type='danger'
                  size='large'
                  disabled={disabled}
                  onClick={handleClick}
                  style={{
                    width: '236px',
                    height: '50px',
                    fontSize: '18px',
                    fontWeight: 700,
                    color: '#fff',
                    backgroundColor: disabled
                      ? 'rgb(210, 212, 215)'
                      : 'rgb(244, 64, 79)'
                  }}
                >
                  {btnText}
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
        <Markdown
          className='course-detail-content-show-markdown'
          source={description}
          renderers={{ image: renderImage }}
        />
        <Modal
          maskClosable={false}
          title={'微信支付'}
          visible={payVisible}
          onCancel={() => {
            writeQuery(writeQuery_payVisible, {
              payVisible: false
            });
          }}
          width={380}
          footer={null}
          bodyStyle={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center'
          }}
        >
          <p>您需要支付 ¥{price}</p>
          <QRCode size={240} value={chargingLink} />
          <p style={{ marginTop: '1em' }}>微信扫一扫，立即支付</p>
        </Modal>
      </div>
    </div>
  );
};

export default withRouter(Content);
