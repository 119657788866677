//此方案为临时添加，因为赶时间没办法特别处理。。日后有时间再说吧;
interface ComponentFunction {
    onSelectFile: any;
    onExpendFile: any;
}

const event : ComponentFunction = {
    onSelectFile : null,
    onExpendFile: null,
};

export default event;