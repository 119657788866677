/**
 * @description 顶部的路由返回和标题展示
 * @author 阿礼
 * @since 2019-9-15
 */

import React from 'react';
import './style.less';

import { Link } from 'react-router-dom';
import gobackIcon from '../../assets/v2.1/back.png';

const TopLinkAndTitle = props => {
  return (
    <div className='top-link-and-title'>
      <Link to={props.to}>
        <img className='icon' src={gobackIcon} alt='返回学习中心的图标' />
      </Link>
      <span className='top-title'>{props.title}</span>
    </div>
  );
};

export default TopLinkAndTitle;
