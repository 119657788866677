/**
 * @description 学习完一小节的提示
 */
import React from 'react';
import './style.less';
import gql from 'graphql-tag';

//第三方库
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import ReactMarkdown from 'react-markdown';
import { Button } from 'antd';
import { Base64 } from 'js-base64';

import FinishAllContent from '../FinishAllContent';
// 图片引用
import finish from '../../../../../assets/omcbg/finish.png';
import finishAll from '../../../../../assets/omcbg/studyall.png';

//函数引用
import { handleCardContent } from '../../../../../utils/handleCardContent';
import { updateIsLearnedState } from '../../../../../utils/updatCardState.js';
import {
  useGetSingleCardContentQuery,
  useGetUnlockSingleCardMutation,
  useGoLearningNextCardQuery
} from '../../../../../generated/graphql';
import { findNextCard } from '../../../../../utils/getNextAndPrev';

const GET_INFO = gql`
  {
    directoryList @client
    currentCardId @client
    coursePackageId @client
    content @client
    courseId @client
    isLearnCardCount @client
    currentParentId @client
    fullScreen @client
    userInfo @client {
      username
      phone
    }
  }
`;

const GET_DIR = gql`
  {
    directoryList @client
  }
`;
const GET_CONTENT = gql`
  {
    content @client
    chapterIsFinish @client
    currentCardId @client
    directoryList @client
    currentParentId @client(always: true)
    currentParentIds @client
    isLearnCardCount @client
    allCardCount @client
    cardLearnPeople @client
  }
`;

const GET_CONTENT_COLLECT = gql`
  {
    contentCardCollect @client
  }
`;

const FINISH_CHAPTER = gql`
  {
    chapterIsFinish @client
    finishAllCards @client
  }
`;

const FinishOneChapterTip = props => {
  const client = useApolloClient();
  const { refetch: updateContent } = useGetSingleCardContentQuery({
    skip: true
  });
  const [unlockCard] = useGetUnlockSingleCardMutation({
    skip: true
  });
  const {
    data: { chapterIsFinish, finishAllCards }
  } = useQuery(FINISH_CHAPTER);
  const {
    data: {
      directoryList,
      currentCardId,
      coursePackageId,
      userInfo,
      courseId,
      currentParentId,
      fullScreen,
      isLearnCardCount,
      allCardCount
    }
  } = useQuery(GET_INFO);

  // const { refetch: getNextCard } = useGetNextCardQuery({ skip: true });
  const { refetch: goLearnNextCard } = useGoLearningNextCardQuery({
    skip: true
  });
  return (
    <div
      className='new-routes-study-cardModal-1236'
      style={
        fullScreen
          ? { boxShadow: 'none' }
          : { boxShadow: '0 22px 49px 0 #e6ebf4' }
      }
    >
      {/* 完成全部 */}

      {finishAllCards ? (
        <FinishAllContent />
      ) : (
        <div className='new-routes-study-cardModal-1249'>
          <img
            className='new-routes-study-cardModal-1255'
            src={finish}
            alt={'congratulation~'}
          />
          <p className='new-routes-study-cardModal-1279'>
            真棒！已经完成这个章节的学习
          </p>
          <Button
            className='new-routes-study-cardModal-1301'
            onClick={() => {
              goLearnNextCard({
                courseId,
                coursePackgeId: coursePackageId,
                currentCardId
              }).then(res => {
                try {
                  const nextCard = res.data.node.nextCard;
                  // 展示内容、收藏、学习人数、学习进度 放到缓存里面

                  let newDirectoryList = null,
                    takeMyData;
                  let parentIds = [];
                  if (nextCard.ancestors.length !== 0) {
                    const ancestors = nextCard.ancestors;
                    for (let i = ancestors.length - 1; i > 0; i--) {
                      ancestors[i].children.map(item => {
                        if (item.id === ancestors[i - 1].id) {
                          item.children = ancestors[i - 1].children;
                        }
                        return item;
                      });
                    }
                    nextCard.ancestors.map(item => {
                      parentIds.push(item.id);
                    });
                    takeMyData = ancestors[ancestors.length - 1];

                    // 找到第二层数据之后，再插入对应的第一层目
                    newDirectoryList = directoryList.map(item => {
                      if (item.id === takeMyData.id) {
                        item.children = takeMyData.children;
                      }
                      return item;
                    });
                  }
                  newDirectoryList = updateIsLearnedState(
                    directoryList,
                    nextCard
                  );

                  // 内容处理
                  const newContent = handleCardContent(
                    nextCard.name,
                    nextCard.content
                  );

                  client.writeQuery({
                    query: GET_CONTENT,
                    data: {
                      directoryList: newDirectoryList,
                      content: newContent,
                      chapterIsFinish: false,
                      currentCardId: nextCard.id,
                      currentParentId: nextCard.parent.id,
                      currentParentIds: parentIds,
                      // isLearnCardCount: nextCard.fileCardCount,
                      // allCardCount: nextCard.learnedCount,
                      cardLearnPeople: nextCard.learnedPeopleCount
                    }
                  });
                  client.writeQuery({
                    query: GET_CONTENT_COLLECT,
                    data: {
                      contentCardCollect: nextCard.isCollected,
                    }
                  });
                  // 并且判断isLearned是否需要解锁
                  if (!nextCard.isLearned) {
                    unlockCard({
                      variables: {
                        input: {
                          cardId: Base64.decode(nextCard.id).split('Card:')[1],
                          coursePackageId: Base64.decode(coursePackageId).split(
                            'CoursePackage:'
                          )[1],
                          visitDuration: 20.1
                        },
                        coursePackageId: coursePackageId
                      }
                    });
                  }
                  // 卡片的学习进度

                  if (isLearnCardCount < allCardCount) {
                    client.writeData({
                      data: { isLearnCardCount: isLearnCardCount + 1 }
                    });
                  }
                } catch (err) {
                  console.log(err);
                }
              });
            }}
          >
            继续学习
          </Button>
        </div>
      )}
    </div>
  );
};

export default FinishOneChapterTip;
