import React, { Component } from 'react';
import FileCard from '../course/FileCard';
import FileCardLine from '../course/FileCardLine';
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc';
import ContextMenuTrigger from 'react-contextmenu/es6/ContextMenuTrigger';
import List from 'antd/es/list';
import video from '../../../assets/icons/file_large_video.png';
import exam from '../../../assets/icons/file_large_quiz.png';
import md from '../../../assets/icons/file_large_markdown.png';
import folder from '../../../assets/icons/file_large_folder.png';
import audio from '../../../assets/icons/file_large_audio.png';

import './styles.css';

import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';

const VIRTUAL_LIST_INFO = gql`
  {
    card_list @client
    treeData @client
    cooperationTreeData @client
    searchFiles @client
  }
`;

const SortableItem = SortableElement(
  ({ value, mode, selectCards, client, pMode }) => {
    const getSuffix = suffix => {
      switch (suffix) {
        case 'md':
          return md;
        case 'exam':
          return exam;
        case 'video':
          return video;
        case 'audio':
          return audio;
        default:
          return folder;
      }
    };
    let suffix = '';
    try {
      suffix = value.title.substring(
        value.title.lastIndexOf('.') + 1,
        value.title.length
      );
    } catch (error) {}
    return (
      <div>
        {mode ? (
          <FileCardLine
            onSelect={e => {
              if (e.action) {
                client.writeData({
                  data: { selectCards: selectCards.concat(e.cardKey) }
                });
              } else {
                client.writeData({
                  data: {
                    selectCards: selectCards.filter(key => {
                      return key !== e.cardKey;
                    })
                  }
                });
              }
            }}
            uploadTime={value.updatae_time}
            createTime={value.create_time}
            disabled={false && value.type === 0}
            title={value.title}
            cover={getSuffix(suffix)}
            cardKey={value.key}
          />
        ) : (
          <FileCard
            onSelect={e => {
              if (e.action) {
                client.writeData({
                  data: { selectCards: selectCards.concat(e.cardKey) }
                });
              } else {
                client.writeData({
                  data: {
                    selectCards: selectCards.filter(key => {
                      return key !== e.cardKey;
                    })
                  }
                });
              }
            }}
            disabled={false && value.type === 0}
            title={value.title}
            cover={getSuffix(suffix)}
            cardKey={value.key}
            collaborativeState={value.collaborativeState}
            isOwner={pMode}
          />
        )}
      </div>
    );
  }
);

const VirtualList = props => {
  const client = useApolloClient();
  const { data: VirtualListInfo } = useQuery(VIRTUAL_LIST_INFO);
  const { treeData, cooperationTreeData, searchFiles, card_list } = VirtualListInfo;

  const {
    packageInfoData,
    items,
    onCollect,
    mode,
    page,
    handlePage,
    handleRigth,
    onSelectFile,
    pageState,
    getShowFiles,
  } = props;
  const {
    pMode,
    cardPaging,
    searchMode,
    selectedFileId,
    selectCards
  } = packageInfoData;

  return (
    <List
      pagination={
        cardPaging.page < 2
          ? null
          : {
              pageSize: 24,
              current: page,
              total: cardPaging.count,
              onChange: e => {
                handlePage(e);
                client.writeData({
                  data: {
                    selectCards: [],
                    checkAll: {
                      cancel: true,
                      all: false,
                      on: false,
                      __typename: 'CheckAll'
                    }
                  }
                });
                if (searchMode) {
                  getShowFiles(searchFiles, [selectedFileId], e);
                } else if (pMode) {
                  getShowFiles(treeData, [selectedFileId], e);
                } else {
                  getShowFiles(cooperationTreeData, [selectedFileId], e);
                }
              }
            }
      }
      split={false}
      grid={mode ? { column: 1 } : { column: 6 }}
      dataSource={items}
      renderItem={(item, index) => {
        return (
          <List.Item
            className='components-sort-sortableComponent-4144'
            onDoubleClick={() => {
              client.writeData({ data: { isCheckPackage: false } });
              if (item.type === 1) {
                client.writeData({
                  data: {
                    card_list: [item.key],
                    // role: pMode ? '0' : '1',
                    isEditCard: true
                  }
                });


                //全部的卡片双击跳转
                if (item.title.indexOf('.md') != -1) {
                  props.props.history.push('/edu/create/textcard');
                }else if (item.title.indexOf('.video') != -1) {
                  props.props.history.push('/edu/create/videocard');
                }else if (item.title.indexOf('.exam') != -1) {
                  props.props.history.push('/edu/create/examcard');
                }else if (item.title.indexOf('.audio') != -1) {
                  props.props.history.push('/edu/create/audiocard');
                }
              } else {
                client.writeData({
                  data: {
                    checkAll: {
                      cancel: true,
                      all: false,
                      on: false,
                      __typename: 'CheckAll'
                    },
                    selectCards: [],
                    selectedFileId: item.key
                  }
                });
                onSelectFile([item.key], pMode);
              }
            }}
          >
            <ContextMenuTrigger
              id='card'
              key='hello'
              collect={() => {
                handleRigth(item);
                return {
                  key: item.key,
                  type: item.type,
                  title: item.title,
                  pid: item.pid
                };
              }}
            >
              <SortableItem
                selectCards={selectCards}
                mode={mode}
                onCollect={onCollect}
                index={index}
                value={item}
                pMode={pMode}
                client={client}
                props={props}
              />
            </ContextMenuTrigger>
          </List.Item>
        );
      }}
    />
  );
};

const SortableList = SortableContainer(VirtualList);

export default SortableList;
