import React, { FC } from 'react';
import { Progress } from 'antd';
import './style.less';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_PGROGRESS = gql`
  {
    isLearnCardCount @client
    allCardCount @client
  }
`;
const ProgressBar = () => {
  const { data, error, loading } = useQuery(GET_PGROGRESS);
  if (loading) {
    return null;
  }
  return (
    <div className='progress-bar'>
      <Progress
        percent={Math.floor((data.isLearnCardCount / data.allCardCount) * 100)}
        size='small'
        strokeColor='#F4404F'
        strokeWidth={19}
      />
      <div className='proportion'>
        {data.isLearnCardCount}/{data.allCardCount}
      </div>
    </div>
  );
};

export default ProgressBar;
