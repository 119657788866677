import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Card from 'components/Card';
import { Course } from 'global_variable/courses';
import empty1s from 'assets/omcbg/empty1s.png';

const List: FC<RouteComponentProps & { list: Course[] }> = ({
  history,
  list
}) => {
  return (
    <div className='courses-list'>
      {list.length === 0 ? (
        <div className='courses-none'>
          <img src={empty1s} alt='没有找到课程' />
          <p>没有找到课程</p>
        </div>
      ) : (
        <div className='courses-list-content'>
          {list.map((item: Course) => (
            <div key={item.shortUrl} className='courses-list-content-card'>
              <Card
                title={item.title}
                cover={item.cover}
                subscribedCount={item.subscribedCount}
                onClick={() => history.push(`/mkt/course/${item.shortUrl}`)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default withRouter(List);
