/**

 * @file: 开智js

 * @author: sky

 *          qiuweiming@quseit.com

 * @update: 2015-04-29 00:02:45

 */
import styled, { keyframes } from 'styled-components';

const animal = keyframes`
0%{
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100%{
    transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
`;

const LoadingView = styled.img`
    animation:  1s ${animal} linear infinite;
`;

export { LoadingView };
