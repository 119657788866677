/**
 * @description 课程布置的作业
 * @author 阿礼
 * @since 2019-9-14
 */

import React, { useEffect, useState } from 'react';
import './style.less';
// 第三方组件
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { Menu, Icon, Spin, Layout } from 'antd';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
// 自定义组件
import FilterAssignments from './FilterAssignments';
import { getTextCardContent } from '../../../../utils/index';
import gaEvents from '../../../../utils/gaEvents.js';
import {
  useGetAllAssignmentsQuery,
  useGetSingleAssignmentQuery,
  useGetAnswerCommitsQuery
} from './../../../../generated/graphql.tsx';

import { history } from '../../../IndexPage';

const GET_ASSIGNMENTSLIST = gql`
  {
    coursePackageAssignmentId @client
    allAssignmentList @client
    doneAssignmentList @client
    undoneAssignmentList @client
    assignmentList @client
    doneCount @client
    undoneCount @client
    assignmentId @client
    assignmentQuestion @client
    assignmentCommentsCount @client
    answerContent @client
    nickname @client
    avatar @client
    postTime @client
    isIveDone @client
    comments @client
    questionPid @client
    questionId @client
    answerId @client
    assignmentName @client
    isShowMobileDirectory @client
    courseId @client
    isCollected @client
    courseTitle @client
  }
`;
const Assignments = props => {
  const { coursePackageAssignmentId, setUpdate, setinitialValue } = props;
  const client = new useApolloClient();
  const {
    data: {
      assignmentList,
      allAssignmentList,
      doneAssignmentList,
      undoneAssignmentList,
      doneCount,
      undoneCount,
      assignmentId,
      isShowMobileDirectory,
      courseId,
      courseTitle
    }
  } = useQuery(GET_ASSIGNMENTSLIST);
  // 得到单个作业任务的内容查询
  const { refetch: getContent } = useGetSingleAssignmentQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });
  const { refetch: getCommits } = useGetAnswerCommitsQuery({ skip: true });

  const [commits, setCommits] = useState([]);

  // 作业内容数据初始化
  const { data, error, loading } = useGetAllAssignmentsQuery({
    variables: { id: coursePackageAssignmentId },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      const allAssignmentList = data.node.assignments;

      // 完成的作业
      const doneAssignmentList = allAssignmentList.filter(
        item => item.isIveDone === true
      );
      // 未完成的作业
      const undoneAssignmentList = allAssignmentList.filter(
        item => item.isIveDone === false
      );
      // 初始化目录数据
      client.writeQuery({
        query: GET_ASSIGNMENTSLIST,
        data: {
          assignmentList: allAssignmentList,
          allAssignmentList,
          doneAssignmentList,
          undoneAssignmentList,
          doneCount: doneAssignmentList.length,
          undoneCount: undoneAssignmentList.length
        }
      });
      // 判断此链接是否有效
      let hk = window.location.pathname.split('/')[4];
      hk = window.btoa('Assignment:' + hk);
      if (allAssignmentList.filter(item => item.id === hk).length > 0) {
        // 浏览器链接处理 长度为7和为6的两种情况
        if (window.location.href.split('/').length === 7) {
          let selectId = window.location.href.split('/')[6];

          selectId = window.btoa(`Assignment:${selectId}`);

          getContent({ id: selectId, courseId }).then(res => {
            if (res.data) {
              const data = res.data.node;

              const comments =
                data.answers.edges.length > 0 &&
                data.answers.edges[0].node.comments.edges.map(
                  item => item.node
                );
              const answers =
                data.answers.edges.length > 0
                  ? data.answers.edges[0].node
                  : null;

              let questionContent = data.questions[0].content;

              questionContent = getTextCardContent(questionContent);

              client.writeQuery({
                query: GET_ASSIGNMENTSLIST,
                data: {
                  assignmentQuestion: questionContent,
                  assignmentId: selectId,
                  assignmentCommentsCount: data.commentsCount,
                  answerContent: data.answers.edges.length
                    ? data.answers.edges[0].node.content
                    : '',
                  nickname: answers ? answers.author.nickname : '',
                  avatar: answers ? answers.author.avatar : '',
                  postTime: answers ? answers.insertedAt : '',
                  isIveDone: data.isIveDone,
                  questionPid: data.questions[0].pid,
                  comments: comments,
                  isCollected: answers ? answers.isCollected : null,
                  answerId:
                    data.answers.edges.length > 0 &&
                    data.answers.edges[0].node.id,
                  assignmentName: data.name
                }
              });

              if (data.isIveDone) {
                getCommits({ id: data.answers.edges[0].node.id }).then(res => {
                  if (res.data.node.commits) {
                    client.writeData({
                      data: { homeworkCommits: res.data.node.commits }
                    });
                  } else {
                    client.writeData({ data: { homeworkCommits: [] } });
                  }
                });
              } else {
                client.writeData({ data: { homeworkCommits: [] } });
              }
            }
          });
        } else {
          if (allAssignmentList.length !== 0) {
            getContent({ id: allAssignmentList[0].id, courseId }).then(res => {
              if (res.data) {
                const data = res.data.node;

                const comments =
                  data.answers.edges.length > 0 &&
                  data.answers.edges[0].node.comments.edges.map(
                    item => item.node
                  );
                const answers =
                  data.answers.edges.length > 0
                    ? data.answers.edges[0].node
                    : null;
                let questionContent = data.questions[0].content;

                questionContent = getTextCardContent(questionContent);
                client.writeQuery({
                  query: GET_ASSIGNMENTSLIST,
                  data: {
                    assignmentQuestion: questionContent,
                    assignmentId: allAssignmentList[0].id,
                    assignmentCommentsCount: data.commentsCount,
                    answerContent: data.answers.edges.length
                      ? data.answers.edges[0].node.content
                      : '',
                    nickname: answers ? answers.author.nickname : '',
                    avatar: answers ? answers.author.avatar : '',
                    postTime: answers ? answers.insertedAt : '',
                    questionPid: data.questions[0].pid,
                    isIveDone: data.isIveDone,
                    comments: comments,
                    isCollected: answers ? answers.isCollected : null,
                    answerId:
                      data.answers.edges.length > 0 &&
                      data.answers.edges[0].node.id,
                    assignmentName: data.name
                  }
                });
                if (data.isIveDone) {
                  getCommits({ id: data.answers.edges[0].node.id }).then(
                    res => {
                      if (res.data.node.commits) {
                        client.writeData({
                          data: { homeworkCommits: res.data.node.commits }
                        });
                      } else {
                        client.writeData({ data: { homeworkCommits: [] } });
                      }
                    }
                  );
                } else {
                  client.writeData({ data: { homeworkCommits: [] } });
                }
              }
            });
          }
        }
      } else {
        // 如果链接无效跳转404
        if (window.location.href.split('/').length === 6) {
          if (allAssignmentList.length !== 0) {
            const unDoneAssignments = allAssignmentList.filter(
              item => item.isIveDone === false
            );

            let defaultAssignment = null;
            if (unDoneAssignments.length !== 0) {
              defaultAssignment = unDoneAssignments[0];
            } else {
              defaultAssignment = allAssignmentList[0];
            }

            getContent({ id: defaultAssignment.id, courseId }).then(res => {
              if (res.data) {
                const data = res.data.node;

                const comments =
                  data.answers.edges.length > 0 &&
                  data.answers.edges[0].node.comments.edges.map(
                    item => item.node
                  );
                const answers =
                  data.answers.edges.length > 0
                    ? data.answers.edges[0].node
                    : null;
                let questionContent = data.questions[0].content;

                questionContent = getTextCardContent(questionContent);
                client.writeQuery({
                  query: GET_ASSIGNMENTSLIST,
                  data: {
                    assignmentQuestion: questionContent,
                    assignmentId: defaultAssignment.id,
                    assignmentCommentsCount: data.commentsCount,
                    answerContent: data.answers.edges.length
                      ? data.answers.edges[0].node.content
                      : '',
                    nickname: answers ? answers.author.nickname : '',
                    avatar: answers ? answers.author.avatar : '',
                    postTime: answers ? answers.insertedAt : '',
                    questionPid: data.questions[0].pid,

                    isIveDone: data.isIveDone,
                    comments: comments,
                    isCollected: answers > 0 ? answers.isCollected : null,
                    answerId:
                      data.answers.edges.length > 0 &&
                      data.answers.edges[0].node.id,
                    assignmentName: data.name
                  }
                });
                if (data.isIveDone) {
                  getCommits({ id: data.answers.edges[0].node.id }).then(
                    res => {
                      if (res.data.node.commits) {
                        client.writeData({
                          data: { homeworkCommits: res.data.node.commits }
                        });
                      } else {
                        client.writeData({ data: { homeworkCommits: [] } });
                      }
                    }
                  );
                } else {
                  client.writeData({ data: { homeworkCommits: [] } });
                }
              }
            });
          }
        }
      }
    }
  });

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin></Spin>
      </div>
    );
  }

  const decodeAssignmentId = window.atob(assignmentId).split('Assignment:')[1];

  return (
    <div className='new-routes-study-courseHomework-container'>
      <div
        className={isShowMobileDirectory ? 'assignments-mobile' : 'assignments'}
      >
        <FilterAssignments />
        <div className='assignment-menu-wrap'>
          <Menu
            className='assignment-menu'
            defaultSelectedKeys={[assignmentId]}
            selectedKeys={[assignmentId]}
            onClick={menu => {
              // 请求数据 问题描述 答案 comments
              setUpdate(false);
              setinitialValue({ content: { markdown: '' } });

              getContent({ id: menu.key, courseId })
                .then(res => {
                  const data = res.data.node;

                  const comments =
                    data.answers.edges.length &&
                    data.answers.edges[0].node.comments.edges.length
                      ? data.answers.edges[0].node.comments.edges.map(
                          item => item.node
                        )
                      : [];
                  const answers =
                    data.answers.edges.length > 0
                      ? data.answers.edges[0].node
                      : null;

                  let questionContent = data.questions[0].content;

                  questionContent = getTextCardContent(questionContent);

                  client.writeQuery({
                    query: GET_ASSIGNMENTSLIST,
                    data: {
                      assignmentQuestion: questionContent,
                      // 评论数
                      assignmentCommentsCount: data.commentsCount,
                      // 每个作业的状态
                      isIveDone: menu.item.props.item.isIveDone,
                      comments: comments,
                      assignmentId: menu.key,
                      // nickname: data.author.nickname,
                      nickname: answers ? answers.author.nickname : '',
                      avatar: answers ? answers.author.avatar : '',
                      // avatar: data.author.avatar,
                      postTime: answers ? answers.insertedAt : '',
                      questionPid: data.questions[0].pid,
                      answerContent: data.answers.edges.length
                        ? data.answers.edges[0].node.content
                        : '',
                      isCollected: answers ? answers.isCollected : null,
                      questionId: data.questions[0].id,
                      answerId: data.answers.edges.length
                        ? data.answers.edges[0].node.id
                        : ''
                    }
                  });

                  if (menu.item.props.item.isIveDone) {
                    getCommits({ id: data.answers.edges[0].node.id }).then(
                      res => {
                        if (res.data.node.commits) {
                          client.writeData({
                            data: { homeworkCommits: res.data.node.commits }
                          });
                        } else {
                          client.writeData({ data: { homeworkCommits: [] } });
                        }
                      }
                    );
                  } else {
                    client.writeData({ data: { homeworkCommits: [] } });
                  }
                  setUpdate(true);
                })
                .catch(error => {
                  setUpdate(true);
                });
            }}
          >
            {assignmentList.map(item => (
              <Menu.Item key={item.id} item={item}>
                {/* 作业是否完成的logo判断 */}
                {item.isIveDone ? (
                  <Icon
                    type='check-circle'
                    theme='filled'
                    style={{ fontSize: '18px', color: '#4EA2FE' }}
                  />
                ) : (
                  <span className='small-circle'></span>
                )}

                {/* <Link
                to={
                  `/stu/${window.localStorage.getItem('shortUrl')}/homework/` +
                  decodeAssignmentId
                }
              >
                {item.name}
              </Link> */}
                <div
                  onClick={() => {
                    gaEvents.gaEventsUpload({
                      eventName: `点击-${courseTitle}-作业列表-${item.name}`
                    });
                    history.push(
                      `/stu/${window.sessionStorage.getItem(
                        'shortUrl'
                      )}/homework/` + decodeAssignmentId
                    );
                  }}
                  style={{ cursor: 'pointer', display: 'inline' }}
                >
                  {item.name}
                </div>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Assignments;
