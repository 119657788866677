import styled from 'styled-components';
import Checkbox from 'antd/es/checkbox/Checkbox';

const FileLine: any = styled.div`
    height: 40px;
    width: 960px;
    display: flex;
    cursor: pointer;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 #E4E5E7;
    margin-top: -20px;
    padding-left: 30px;
`;

FileLine.Cover = styled.img`
   cursor: pointer;
   width: 24px;
   height: 24px;
   margin-left: 15px;
`;

FileLine.Checkbox = styled(Checkbox)`
`;

FileLine.Title = styled.div`
width: 371px;
margin-left: 5px;
  align-self: center;
  font-size: 14px;
  color: #282A2D;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
   cursor: pointer;
`;

FileLine.Time = styled.div`
  width: 200px;
  height: 20px;
  font-size: 14px;
  color: #282a2d;
  margin-left: 10px;
`

export { FileLine };
