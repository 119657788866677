import styled from 'styled-components';
import Tree from 'antd/es/tree';

const CustomTree = styled(Tree)`
  .ant-tree-node-selected{
      background-color: #177E89!important;
      width: 100%;
      height: 40px!important;
      vertical-align: middle!important;
      color: #fff!important;
      display: inline-flex!important;
      align-items: center!important;
   }
   
  
   
   span.ant-tree-node-selected:hover{
      background-color: #177E89!important;
      width: 100%;
      height: 40px!important;
      vertical-align: middle!important;
      color: #fff!important;
      display: inline-flex!important;
      align-items: center!important;
   }
   
   .ant-tree-node-content-wrapper-normal{
      height: 40px!important;
      width: 100%!important;
   }
   
   .ant-tree-node-content-wrapper-normal:hover{
      height: 40px!important;
      width: 100%!important;
      background-color: #F6F6F7!important;
   }
   
   
   
   .ant-tree-node-content-wrapper-close{
       width: 100%;
      height: 40px!important;
      display: inline-flex!important;
      align-items: center!important;
   }
   
   .react-contextmenu-wrapper{
     width: 100%;
      height: 40px!important;
      display: inline-flex!important;
      align-items: center!important;
      background-color: #ffffff00;
   }
   
   /*.react-contextmenu-wrapper:hover{
     width: 100%!important;
      height: 40px!important;
      display: inline-flex!important;
      align-items: center!important;
      background-color: #F6F6F7!important;
   }*/
   
   .ant-tree-node-content-wrapper-open{
   width: 100%!important;
      height: 40px!important;
      display: inline-flex!important;
      align-items: center!important;
   }
   
   .ant-tree-node-content-wrapper-open:hover{
   width: 100%!important;
      height: 40px!important;
      display: inline-flex!important;
      align-items: center!important;
      background-color: #F6F6F7!important;
   }
   
   
    .ant-tree-node-content-wrapper-close:hover{
       width: 100%;
      height: 40px!important;
       background-color: #F6F6F7!important;
      /* display: inline-flex!important;
      align-items: center!important;*/
   }
   
   
   .react-contextmenu-wrapper >div:hover{
      height: 100%;
      display: flex!important;
      align-items: center!important;
   }
   
   .ant-tree-node-selected >span.ant-tree-title{
      color: #fff!important;
   }
   
   .ant-tree-switcher_open{
      height: 40px!important;
      display: inline-flex!important;
      align-items: center!important;
   }
   
   .ant-tree-switcher_close{
      height: 40px!important;
      display: inline-flex!important;
      align-items: center!important;
   }
  
`;


export { CustomTree };
