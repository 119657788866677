import React, { Component } from 'react';
import './style.less';
import { Modal, Layout, Spin, message, Sider, Menu, SubMenu } from 'antd';
import Button from 'antd/es/button/button';
import videojs from 'video.js';
// import "video.js/dist/video-js.css";
// import TextCard from '../../CommonComponents/cards/textCard';
import { checkFolder, getAllPid, getSuffix } from '../../../utils/index';

import AnswerLayout from '../../CommonComponents/cards/answerLayout';
import ReactCardFlip from 'react-card-flip';
import { findFlag, getPid, getEndKeys } from '../../../utils';
import lock from '../../../assets/icons/password_grey.png';
import { HoverP } from '../../CommonComponents/common/customp.tsx';
import { CustomP } from '../../CommonComponents/common/customp';

import { Player, BigPlayButton } from 'video-react';
// import '../../../node_modules/video-react/dist/video-react.css';
import left from '../../../assets/icons/arrow_left_circle.png';
import right from '../../../assets/icons/arrow_right_circle.png';

// import rotateImg from '../../assets/icons/rotate.png';
// import rotateImg from '../../assets/v2.1/ic_course_card_turnover.png';
import rotateImg from '../../../assets/v2.1/ic_course_card_turnover.png';

import menu1 from '../../../assets/icons/menu.png';

import cross from '../../../assets/icons/cross.png';
import finish from '../../../assets/omcbg/finish.png';
import finishAll from '../../../assets/omcbg/studyall.png';
import favorite from '../../../assets/icons/favorite.png';
import favorite_active from '../../../assets/icons/favorite_active.png';
import full_screen from '../../../assets/v2.1/ic_course_card_fullscreen.png';
import share_hover from '../../../assets/icons/share_hover.png';
import share from '../../../assets/icons/share.png';
import shareIcon from '../../../assets/v2.1/Shape.png';

// import MarkdownView from '../../CommonComponents/markdown/MarkdownView';

import MarkdownView from '../../CommonComponents/MyReactMarkdown';

import { ScaleView } from '../../CommonComponents/animation';
import IconButton from '../../CommonComponents/common/iconButton';

import copy from 'copy-to-clipboard';
// import { baseURL } from '../../utils/url';
import ContextMenuTrigger from 'react-contextmenu/es6/ContextMenuTrigger';

import gaEvents from '../../../utils/gaEvents';

// import MyAudioPlayer from '../../components/AudioPlayer';
import MyAudioPlayer from '../../CommonComponents/AudioPlayer';
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { useGetSingleCardContentQuery } from '../../../generated/graphql';

//新加入代码
import CardFrontFooter from '../../LearningCenter/CourseContent/CardDisplay/CardFrontFooter';
import TextCard from '../../LearningCenter/CourseContent/CardDisplay/TextCard';
import VideoCard from '../../LearningCenter/CourseContent/CardDisplay/VideoCard';
import ExamCard from '../../LearningCenter/CourseContent/CardDisplay/ExamCard';
import MyReactMarkdown from '../../CommonComponents/MyReactMarkdown';
import { handleCardContent } from '../../../utils/handleCardContent';
import { Base64 } from 'js-base64';

const isSafari =
  window.navigator.userAgent.indexOf('Safari') !== -1 &&
  window.navigator.userAgent.indexOf('Version') !== -1;

const GET_LIST = gql`
  {
    collectionShowData @client(always: true)
    cardData @client
    side @client
    collectionDetailIndex @client
    userInfo @client {
      username
      phone
    }
    courseId @client
    coursePackageId @client
  }
`;

const CARD_DATA = gql`
  {
    cardData @client
    collectionDetailIndex @client
  }
`;

// 包裹一层组件
const CollectionDetailCardWrap = props => {
  const client = useApolloClient();
  const {
    data: {
      collectionShowData,
      cardData,
      side,
      collectionDetailIndex,
      userInfo,
      courseId,
      coursePackageId
    },
    loading
  } = useQuery(GET_LIST);

  const { refetch: getCardDetailQuery } = useGetSingleCardContentQuery({
    fetchPolicy: 'network-only',
    skip: true
  });

  const getCardLearnedPeopleCount = cardData => {
    let id = Base64.decode(cardData.id).split('Collection:')[1];
    id = Base64.encode('Card:' + id);
    getCardDetailQuery({
      courseId: courseId,
      coursePackageId: coursePackageId,
      id: id
    }).then(res => {
      if (res.data && res.data.node) {
        client.writeData({
          data: {
            cardLearnPeople: res.data.node.learnedPeopleCount,
            contentCardCollect: res.data.node.isCollected,
            currentCardId: id
          }
        });
      }
    });
  };

  React.useEffect(() => {
    getCardLearnedPeopleCount(collectionShowData[collectionDetailIndex]);
  }, [collectionDetailIndex]);

  if (loading) {
    return null;
  }
  return (
    <CollectionDetailCard
      client={client}
      collectionShowData={collectionShowData}
      cardData={cardData}
      side={side}
      collectionDetailIndex={collectionDetailIndex}
      userInfo={userInfo}
      getCardLearnedPeopleCount={getCardLearnedPeopleCount}
    />
  );
};

class CollectionDetailCard extends Component {
  curNum = 0;

  constructor(props) {
    super(props);

    this.state = {
      flipSpeeed: 0.5,
      side: false
    };
  }

  componentWillMount() {
    //阻止按键默认事件
    document.addEventListener('keydown', this.forbidSpaceButton);

    //为了在打开卡片时屏蔽掉复制粘贴等功能加的代码
    if (!document.oncontextmenu) {
      document.oncontextmenu = new Function('event.returnValue=false');
    }
    if (!document.onselect) {
      document.onselectstart = new Function('event.returnValue=false');
    }
  }

  forbidSpaceButton(e) {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  }

  componentDidMount() {
    const { isStudy } = this.props;
    const that = this;

    document.onkeydown = e => {
      if (e.keyCode === 37) {
        if (this.props.collectionDetailIndex !== 0) {
          this.goLeft();
        }
      }
      if (e.keyCode === 39) {
        if (
          this.props.collectionShowData.length - 1 !==
          this.props.collectionDetailIndex
        ) {
          this.goRight();
        }
      }
      if (e.keyCode === 32) {
        this.setState({ side: !this.state.side });
      }
    };

    if (isStudy) {
      // 学习模式
    }
  }

  componentWillUnmount() {
    document.oncontextmenu = '';
    document.onselectstart = true;
    document.removeEventListener('keydown', this.forbidSpaceButton);
  }

  showLeft() {
    const { collectionShowData, collectionDetailIndex } = this.props;
    let isShow = true;

    if (
      /*collectionShowData.current_page === 1 &&*/ collectionDetailIndex === 0
    ) {
      isShow = false;
    }

    return isShow;
  }

  goLeft() {
    const { collectionShowData, collectionDetailIndex, client } = this.props;

    const { side } = this.state;
    side && this.setState({ side: false });

    const data = collectionShowData[collectionDetailIndex - 1];
    const parseContent = handleCardContent(
      data.name,
      data.content,
      data.id,
      client
    );
    client.writeQuery({
      query: CARD_DATA,
      data: {
        cardData: parseContent,
        collectionDetailIndex: collectionDetailIndex - 1
      }
    });
  }

  showRight() {
    const { collectionShowData, collectionDetailIndex } = this.props;
    let isShow = true;

    if (collectionDetailIndex === collectionShowData.length - 1) {
      isShow = false;
    }

    return isShow;
  }

  goRight() {
    const { collectionShowData, collectionDetailIndex, client } = this.props;

    const { side } = this.state;
    side && this.setState({ side: false });

    const data = collectionShowData[collectionDetailIndex + 1];
    const parseContent = handleCardContent(
      data.name,
      data.content,
      data.id,
      client
    );
    client.writeQuery({
      query: CARD_DATA,
      data: {
        cardData: parseContent,
        collectionDetailIndex: collectionDetailIndex + 1
      }
    });
  }

  renderContent(content) {
    const { userInfo } = this.props;
    switch (content.type) {
      case 'md':
        return <TextCard content={content} />;
      case 'video':
        return <VideoCard content={content} userInfo={userInfo} />;
      case 'exam':
        return <ExamCard content={content} />;
      case 'audio':
        break;
      default:
        break;
    }
  }

  render() {
    const { side, flipSpeeed } = this.state;
    const { userInfo, cardData: content } = this.props;

    let showLeft = this.showLeft();
    let showRight = this.showRight();
    return (
      <div className='new-routes-collection-collectionDetail-card-2000'>
        {showLeft ? (
          <img
            className='new-routes-collection-collectionDetail-card-2000-left'
            style={{ cursor: 'pointer' }}
            onClick={this.goLeft.bind(this)}
            src={left}
            data-rjs='2'
          />
        ) : (
          <div className='new-routes-collection-collectionDetail-card-2000-left' />
        )}

        <div className={!side ? 'single-card' : 'single-card-back'}>
          <ReactCardFlip
            className='new-routes-study-cardModal-1189'
            isFlipped={side}
            infinite={false}
            // 用变量控制它
            flipSpeedBackToFront={flipSpeeed}
            flipSpeedFrontToBack={flipSpeeed}
          >
            {/* 正面组件 */}
            <div key='front' className='new-routes-study-cardModal-1580'>
              {/* 翻转标签 */}

              {content && content.back.length > 2 && (
                <div
                  className='new-routes-study-cardModal-1342'
                  onClick={() => {
                    this.setState({
                      side: !side,
                      flipSpeeed: 0.6
                    });
                  }}
                >
                  <div className='new-routes-study-cardModal-1411'></div>

                  <img
                    data-rjs='2'
                    src={rotateImg}
                    className='new-routes-study-cardModal-1425'
                  />
                </div>
              )}

              {content && this.renderContent(content)}

              <CardFrontFooter isCollect={true} content={content} />
            </div>

            {/* 背面组件 */}
            <div key='back' className='new-routes-study-cardModal-1580'>
              {content && content.back.length > 2 && (
                <div
                  className='new-routes-study-cardModal-1342'
                  onClick={() => this.setState({ side: !side })}
                >
                  <div className='new-routes-study-cardModal-1411'></div>

                  <img
                    data-rjs='2'
                    src={rotateImg}
                    className='new-routes-study-cardModal-1425'
                  />
                </div>
              )}
              <div className='routes-course-CardModal-4122'>
                <div className='routes-course-CardModal-4451' />
                <MyReactMarkdown source={content && content.back} />
              </div>
            </div>
          </ReactCardFlip>
        </div>

        {showRight ? (
          <img
            className='new-routes-collection-collectionDetail-card-2000-right'
            style={{ cursor: 'pointer' }}
            onClick={this.goRight.bind(this)}
            data-rjs='2'
            src={right}
          />
        ) : (
          <div className='new-routes-collection-collectionDetail-card-2000-right' />
        )}
      </div>
    );
  }
}

export default CollectionDetailCardWrap;
