import gql from 'graphql-tag';

export const LIST_COLLEGE_COURSES = gql`
  query listCollegeCourses($filter: CourseFilter, $first: Int) {
    courses(filter: $filter, first: $first) {
      edges {
        node {
          title
          cover
          subscribedCount
          labels
          shortUrl
          category {
            name
            parent {
              name
            }
          }
        }
      }
    }
  }
`;
