import React, { Component } from 'react';
import './styles.css';

//引入的库组件
import ContextMenu from 'react-contextmenu/es6/ContextMenu';
import MenuItem from 'react-contextmenu/es6/MenuItem';
import SubMenu from 'react-contextmenu/es6/SubMenu';
import styled from 'styled-components';
import { Upload } from 'antd';

//引入的其他页面组件

//graphql查询

//引入的资源图片

const MenuItemDivier = styled.div`
  width: 100%;
  height: 2px;
  border: 1px solid rgba(72, 72, 72, 0.25);
`;

const UploadItem = styled.div`
  color: #282a2d;
  width: 114px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-left: 30px;
  &:hover {
    color: #fff;
    background-color: #177e89;
  }
`;

class RightClickScreen extends Component {

  render() {
    const { pageState, pMode, pageFunction } = this.props;
    const { isFileState, isCooState } = pageState;

    return (
      <ContextMenu id='screen' className='edu-PackageManager-41190'>
        <MenuItem>刷新</MenuItem>
        <SubMenu title={'新建'} hoverDelay={100}>
          <MenuItem
            onClick={() => {
              pageFunction.onCreate('2');
            }}
          >
            新建文本卡片
          </MenuItem>
          <MenuItem
            onClick={() => {
              pageFunction.onCreate('3');
            }}
          >
            新建视频卡片
          </MenuItem>
          <MenuItem
            onClick={() => {
              pageFunction.onCreate('4');
            }}
          >
            新建测验卡片
          </MenuItem>
          <MenuItem
            onClick={() => {
              pageFunction.onCreate('5');
            }}
          >
            新建音频卡片
          </MenuItem>
          <MenuItemDivier />
          <MenuItem
            onClick={() => {
              pageFunction.onCreate('1');
            }}
          >
            新建文件夹
          </MenuItem>
          <MenuItem
            onClick={() => {
              pageFunction.onCreate('0');
            }}
          >
            新建卡包
          </MenuItem>
        </SubMenu>
        <SubMenu
          title={'上传'}
          hoverDelay={100}
          className='edu-PackageManager-41195'
        >
          <Upload beforeUpload={pageFunction.uploadCardFunc} showUploadList={false}>
            <UploadItem>文件</UploadItem>
          </Upload>
          <Upload beforeUpload={pageFunction.uploadZipFunc} showUploadList={false}>
            <UploadItem>压缩包</UploadItem>
          </Upload>
        </SubMenu>
      </ContextMenu>
    );
  }
}

export default RightClickScreen;
