import * as React from 'react';
import './style.less';

//引入的库组件
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { useApolloClient } from '@apollo/react-hooks';
import { Layout, Radio, Icon, Divider } from 'antd';
import RadioGroup from 'antd/es/radio/group';
import CheckboxGroup from 'antd/es/checkbox/Group';

//引入的其他页面组件

//graphql查询

//引入的资源图片

const GET_ANSWERLAYOUT_INFO = gql`
  {
    showResult @client
    examAnswerInfo @client
  }
`;

const AnswerLayout = (props: any) => {
const client = useApolloClient();
  const { data: answerLayoutInfo } = useQuery(GET_ANSWERLAYOUT_INFO);
  const { showResult, examAnswerInfo } = answerLayoutInfo;

  const [tORf, setTORf] = React.useState(false);
  const [myAnswer, setMyAnswer] = React.useState([] as any);

  const { question } = props;
  const { is_single, asw } = question;

  const setAnswer = () => {
    let title = is_single === 'true' ? question.sq : question.mq;
    examAnswerInfo.info.map((item: any, index: any) => {
      if (item.question.indexOf(title) > -1) {
        item.answers = myAnswer;
        item.isCorrect = tORf;
      }
    });
    client.writeQuery({query: GET_ANSWERLAYOUT_INFO, data: {examAnswerInfo: examAnswerInfo}});
  };

  React.useEffect(() => {
    setAnswer();
  }, [myAnswer, tORf]);

  return (
    <Layout.Content className='routes-course-cards-answerLayout-832'>
      <div className='routes-course-cards-answerLayout-909'>
        {is_single === 'true' ? question.sq : question.mq}
      </div>

      {is_single === 'true' ? (
        <RadioGroup
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: asw.length * 40
          }}
          onChange=/* eslint-disable eqeqeq */ {checkedValues => {
            setMyAnswer(checkedValues.target.value);
            setTORf(checkedValues.target.value == question.aswt);
          }}
        >
          {asw.map((item: any) => {
            return <Radio value={item}>{item}</Radio>;
          })}
        </RadioGroup>
      ) : (
        <CheckboxGroup
          options={asw.map((item: any) => {
            return { value: item, label: item };
          })}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: asw.length * 40
          }}
          onChange=/* eslint-disable eqeqeq */ {checkedValues => {
            setMyAnswer(checkedValues);
            setTORf(
              JSON.stringify(question.aswt.sort()) ==
                JSON.stringify(
                  checkedValues
                    .filter(item => {
                      return asw.indexOf(item) !== -1;
                    })
                    .sort()
                )
            );
          }}
        />
      )}

      {showResult ? (
        <div>
          {tORf ? (
            <p className='routes-course-cards-answerLayout-2331'>
              <Icon
                type='check-circle'
                className='routes-course-cards-answerLayout-2401'
              />
              回答正确
            </p>
          ) : (
            <div>
              <p className='routes-course-cards-answerLayout-2521'>
                {' '}
                <Icon
                  type='close-circle'
                  className='routes-course-cards-answerLayout-2592'
                />
                回答错误
              </p>
              <div className='routes-course-cards-answerLayout-2660'>
                <div className='routes-course-cards-answerLayout-2720'>
                  正确答案是：{question.aswt.join('、')}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
      <Divider />
    </Layout.Content>
  );
};

export default AnswerLayout;
