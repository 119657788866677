import * as React from 'react';

//引入的库组件
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { Tree } from 'antd';
import { ContextMenuTrigger } from 'react-contextmenu';

//引入的其他页面组件
import { getChildren } from '../../../utils';
import { CustomTree } from './customTree';

//graphql查询
import gql from 'graphql-tag';

//引入的资源图片
import root from '../../../assets/icons/file_medium_root.png';
import folder from '../../../assets/icons/file_medium_folder.png';
import root_while from '../../../assets/icons/file_medium_root_white.png';
import folder_while from '../../../assets/icons/file_medium_folder_white.png';

const GET_FILE_TREE_INFO = gql`
  {
    expandedKeys @client
  }
`;

const FileTree = (props: any) => {
  const client = useApolloClient();
  const { data: fileTreeInfoData } = useQuery(GET_FILE_TREE_INFO);
  const [checkedKeys, setCheckedKeys] = React.useState([]);
  const [autoExpandParent, setAutoExpandParent] = React.useState(true);
  const { treeData, handlerMode, onSelectFile, onExpendFile } = props;
  const { expandedKeys } = fileTreeInfoData;

  const onExpand = (expandedKeysNew: any) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.

    let expandedKeysNewIn = expandedKeysNew.filter((item: any) => {
      return !(expandedKeys.indexOf(item) !== -1);
    });
    client.writeData({ data: { expandedKeys: expandedKeysNew } });
    setAutoExpandParent(false);

    if(expandedKeysNewIn.length === 0) {
      return ;
    }
    onExpendFile(expandedKeysNewIn, handlerMode);
  };

  const onSelect = (selectedKeys: any) => {
    client.writeData({
      data: { searchMode: false, pMode: handlerMode, isCheckPackage: false }
    });
    if (selectedKeys.length === 0) {
      return;
    }
    client.writeData({
      data: {
        checkAll: {
          cancel: true,
          all: false,
          on: false,
          __typename: 'CheckAll'
        },
        selectCards: [],
        selectedFileId: selectedKeys[0]
      }
    });
    onSelectFile(selectedKeys, handlerMode);
  };

  const renderTreeNodes = (data: any) => {
    if (data.length === 0) {
      return;
    }
    return data.map((item: any, index: number) => {
      const selected = props.selectedFileId === item.key;
      const isPackage = treeData.indexOf(item) !== -1;
      if (item.children && item.type === 0) {
        return (
          <Tree.TreeNode
            isLeaf={!item.hasFolderChild}
            title={
              <ContextMenuTrigger
                id={handlerMode && isPackage ? 'package' : ''}
                key='screen'
                collect={() => {
                  return {
                    key: item.key,
                    type: item.type,
                    title: item.title,
                    pid: item.pid,
                    mode: item.is_assistant === 0
                  };
                }}
              >
                <div>
                  <img
                    src={
                      item.pid === 0
                        ? selected
                          ? root_while
                          : root
                        : selected
                        ? folder_while
                        : folder
                    }
                    className='components-layout-FileTree-3026'
                  />
                  {item.title}
                </div>
              </ContextMenuTrigger>
            }
            key={item.key}
            dataRef={item}
          >
            {renderTreeNodes(item.children)}
          </Tree.TreeNode>
        );
      }
    });
  };

  let defaultKey: any = '';
  try {
    defaultKey = [treeData[0].key];
  } catch (error) {}
  return (
    <CustomTree
      onRightClick={e => {
        false && console.log(e.event.pageX);
      }}
      onExpand={onExpand}
      expandedKeys={props.expandedKeys}
      autoExpandParent={autoExpandParent}
      checkedKeys={checkedKeys}
      // selectedKeys={this.state.checkedKeys}
      onSelect={onSelect}
      selectedKeys={props.selectedFileId === '' ? defaultKey : [props.selectedFileId]}
    >
      {renderTreeNodes(treeData)}
    </CustomTree>
  );
};

export default FileTree;
