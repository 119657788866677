import * as React from 'react';
import './styles.css';

//引入的库组件
import { Modal, Form, Button, Input, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import timeLocalStorage from '../../utils/TimeLocalStorage';

//graphql查询
import { useUserLoginMutation } from '../../generated/graphql';
import headers from '../../global_variable/headers';

//引入的其他页面组件

//引入的资源图片
import phone from '../../assets/icons/phone.png';
import pw from '../../assets/icons/password_grey.png';

const GET_INFOS_FOR_USER = gql`
  {
    userInfo @client {
      head_img
    }
    isShowLoginModal @client
    buttonLoading @client
    routeFrom @client
  }
`;

const WRITE_INFOS_FOR_USER = gql`
  {
    userInfo @client {
      head_img
      phone
      role
      token
      userid
      username
      userProfile
    }
    isShowLoginModal @client
    buttonLoading @client
    isLoggedIn @client
  }
`;

const contentLayout: React.CSSProperties = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
};

const Login = (props: any) => {
  const client = useApolloClient();
  const { data } = useQuery(GET_INFOS_FOR_USER);
  const { isShowLoginModal, buttonLoading, routeFrom } = data;

  const [loginMutation] = useUserLoginMutation();
  const { getFieldDecorator } = props.form;

  const login = () => {
    client.writeData({
      data: { buttonLoading: true }
    });
    let phone = '';
    let password = '';
    props.form.validateFieldsAndScroll((errors: any, values: any) => {
      if (errors) {
        false && console.log('tag');
      } else {
        phone = values.phone;
        password = values.password;
      }
    });

    loginMutation({ variables: { phone: phone, password: password } }).then(
      (res: any) => {
        client.writeData({
          data: { buttonLoading: false }
        });
        if (res.data.login.errors) {
          message.error('用户不存在或密码错误!');
        } else {
          const { user, token } = res.data.login.session;
          if (user && token) {
            let userInfo = {
              head_img: user.avatar,
              phone: user.phone,
              role: user.role,
              token: token,
              userid: user.id,
              username: user.nickname,
              userProfile: user.userProfile,
              __typename: 'UserInfo'
            };
            message.success('登录成功!');
            window.localStorage.setItem('LOGINSTATUS', '0');
            timeLocalStorage.set('TOKENINFO', token, 60 * 60 * 24 * 15);
            window.localStorage.setItem('USERINFO', JSON.stringify(userInfo));
            
            headers.Authorization = 'Bearer ' + userInfo.token;
            client.writeData({
              data: {
                buttonLoading: false,
                userInfo: userInfo,
                isLoggedIn: true,
                isShowLoginModal: false
              }
            });
            if(user.isDef) {
              message.error('您当前的密码是初始化密码，请修改!');
              const { history } = props;
              history.push("/my");
            }
            if (routeFrom) {
              const { history } = props;
              history.push(routeFrom);
              client.writeData({
                data: {
                  routeFrom: null
                }
              });
            }
            // client.writeQuery({
            //   query: WRITE_INFOS_FOR_USER,
            //   data: { buttonLoading: false, userInfo: userInfo, isLoggedIn: true, isShowLoginModal: false }
            // });
          } else {
            message.error('用户不存在或密码错误!');
          }
        }
      }
    );
  };

  const onCancel = () => {
    props.form.resetFields();
    client.writeData({ data: { isShowLoginModal: false } });
  };

  return (
    <Modal
      keyboard={false}
      maskClosable={false}
      title='账号登录'
      visible={isShowLoginModal}
      onOk={login}
      onCancel={onCancel}
      width={380}
      footer={null}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <div style={contentLayout}>
        <div className='login-register-login-1643'>
          未来的创造者，从这里起步
        </div>
        <Form className='login-register-login-1744'>
          <Form.Item hasFeedback>
            {getFieldDecorator('phone', {
              rules: [
                {
                  pattern: '^[1][3,4,5,7,8,6,9][0-9]{9}$',
                  message: '手机号码格式错误'
                }
              ],
              validateTrigger: 'onBlur'
            })(
              <Input
                size={'large'}
                className='login-register-login-2084'
                placeholder='手机号'
                prefix={<img src={phone} />}
              />
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: '请输入密码' }]
            })(
              <Input
                size={'large'}
                className='login-register-login-2495'
                maxLength={16}
                type='password'
                placeholder='密码'
                prefix={<img src={pw} />}
              />
            )}
          </Form.Item>
        </Form>
        <div className='login-register-login-2808'>
          <a
            className='login-register-login-2876'
            onClick={() => {
              client.writeData({
                data: {
                  isShowLoginModal: false,
                  isShowResetResult: false,
                  isShowResetModal: true
                }
              });
            }}
          >
            忘记密码？
          </a>
          <a
            className='login-register-login-3321'
            onClick={() => {
              client.writeData({
                data: { isShowLoginModal: false, isShowRegisterModal: true }
              });
            }}
          >
            注册账号
          </a>
        </div>
        <Button
          className='login-register-login-3729'
          type='danger'
          onClick={login}
          loading={buttonLoading}
        >
          登录
        </Button>
      </div>
    </Modal>
  );
};

const LoginWrapper = Form.create()(withRouter(Login));

export default LoginWrapper;
