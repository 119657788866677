/**

 * @file: 开智js

 * @author: sky

 *          qiuweiming@quseit.com

 * @update: 2015-04-29 00:02:45

 */
import React, { Component } from 'react';

class IconButton extends Component {
  constructor(props) {
    super(props);
    this.state = ({ isPress: false });
  }

  render() {
    const { press, unPress, click, selected } = this.props;
    const { isPress } = this.state;
    return (
      <img
        data-rjs="2"
        className="components-common-iconButton-437"
        onMouseOver={() => {
          this.setState({ isPress: true });
        }}
        onMouseOut={() => {
          this.setState({ isPress: false });
        }}
        onClick={click}
        src={isPress || selected ? press : unPress}
      />
    );
  }
}

export default IconButton;
