/**
 * @description 联系我们
 */
import React from 'react';
import { withRouter } from 'react-router-dom';

import './Concat.css';
const fontColor = { color: '#BFC4CE' };

let enNames = [
  { name: '认知学院', en: 'cognitive' },
  { name: '效率学院', en: 'hacker' },
  { name: '教练学院', en: 'growth' },
  { name: '写作学院', en: 'writer' },
  { name: '阅读学院', en: 'reader' },
  { name: 'OpenMind+', en: 'OpenMindPlus' }
];

function Concat(props) {
  return (
    <div>
      <h3 className='concat-title'>{props.title}</h3>
      <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
        {props.list.map((item, index) => (
          <li
            key={index.toString()}
            style={fontColor}
            className={item.cursor ? 'concat-hasCursor' : 'concat-noneCursor'}
            onClick={() => {
              if (enNames.find(i => i.name === item.name)) {
                let realName = enNames.filter(k => k.name === item.name).pop();
                props.history.push(`/college/${realName.en}`);
              }
            }}
          >
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default withRouter(Concat);
