import React, { FC, useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button } from 'antd';
import { useQuery } from 'react-apollo-hooks';
import { LIST_COURSES } from '../../Courses/query';
import Card from 'components/Card';

interface ICourseItem {
  collegiateCategory: string;
  courseCategory: string;
}

const CourseItem: FC<ICourseItem & RouteComponentProps> = ({
  collegiateCategory,
  courseCategory,
  history
}) => {
  // 筛选条件
  const search = (page: number) => ({
    filter: {
      isSelling: true,
      isOnline: true,
      collegiateCategory,
      courseCategory
    },
    first: 6 * page
  });

  // 检索课程条件
  const [page, setPage] = useState(1);
  const [variables, setVariables] = useState(search(page));
  const { data } = useQuery(LIST_COURSES, { variables }); // 筛选分类列表
  const [courses, setCourses] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false); // 是否有下一页

  // 处理课程数据
  useEffect(() => {
    try {
      if (data && Object.keys(data).length !== 0) {
        const { edges, pageInfo } = data.courses;
        setHasNextPage(pageInfo.hasNextPage);
        const courses = edges.map((item: any) => ({
          title: item.node.title,
          cover: item.node.cover,
          subscribedCount: item.node.subscribedCount,
          shortUrl: item.node.shortUrl
        }));
        setCourses(courses);
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  // 展开更多
  useEffect(() => {
    if (page !== 1) {
      setVariables(search(page));
    }
  }, [page]);

  if (courses.length === 0) return null;

  return (
    <div className='college-detail-courses-content-list'>
      <h2>{courseCategory}</h2>
      <div className='college-detail-courses-content-item'>
        {courses.map((item: any) => (
          <div
            key={item.shortUrl}
            className='college-detail-courses-content-item-card'
          >
            <Card
              title={item.title}
              cover={item.cover}
              subscribedCount={item.subscribedCount}
              onClick={() => history.push(`/mkt/course/${item.shortUrl}`)}
            />
          </div>
        ))}
        {courses.length > 0 && courses.length < 3 && (
          <div className='college-detail-courses-content-item-await'>
            敬请期待
          </div>
        )}
        {hasNextPage && (
          <div className='college-detail-courses-content-more'>
            <Button
              type='danger'
              size='large'
              ghost
              onClick={() => setPage(page => page + 1)}
              className='college-detail-courses-content-more-btn'
            >
              展开全部课程
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(CourseItem);
