/**
 *
 * @param {*} name 文件类型名
 * @param {*} content  markdown的内容
 */

import {
  getTextCardContent,
  getVideoCardContent,
  getExamCardContent
} from './index.js';
import makeExamCardInfo from './../utils/makeExamCardInfo';

export const handleCardContent = function(name, content, id, client) {
  try {
    let type = name.substring(name.lastIndexOf('.') + 1);
    let newContent = { type, title: '', front: '', back: '' };
    let contentData;
    switch (type) {
      case 'md':
        contentData = getTextCardContent(content);
        break;
      case 'video':
        contentData = getVideoCardContent(content);
        break;
      case 'exam':
        if (id && client) {
          makeExamCardInfo(content, id, client);
        }
        contentData = getExamCardContent(content);
        break;
      case 'audio':
        contentData = getVideoCardContent(content);
        break;
      default:
        contentData = getTextCardContent(content);
        break;
    }
    newContent.title = contentData.title;
    newContent.front = contentData.front;
    newContent.back = contentData.back.replace(/↵/g, '');

    return newContent;
  } catch (err) {
    console.log(err);
  }
};
