import React, { FC, useState } from 'react';
import { Upload, Button, message } from 'antd';
import CropperModal from './CropperModal';
import './style.less';

interface IUpload {
  btnValue: string;
  onSuccess: (url: string) => void;
  describe?: string;
  type?: string[];
  size?: number;
}

const UploadAvatar: FC<IUpload> = ({
  btnValue,
  onSuccess,
  describe,
  type,
  size
}) => {
  const [file, setFile] = useState(null);

  const beforeUpload = (file: any) => {
    let isType = true;
    let isSize = true;
    if (type) {
      isType = type.includes(file.type);
    }
    if (size) {
      isSize = file.size / 1024 / 1024 < size;
    }

    if (!isType) {
      message.error(`不支持 ${file.type} 文件类型`);
    }
    if (!isSize) {
      message.error(`文件限制 ${size} M`);
    }

    if (isType && isSize) {
      setFile(file);
    }
    return false;
  };

  return (
    <>
      <div className='component-upload'>
        <Upload
          name='file'
          listType='text'
          showUploadList={false}
          beforeUpload={beforeUpload}
        >
          <Button
            style={{
              width: '100px',
              height: '40px',
              color: '#282A2D'
            }}
          >
            {btnValue}
          </Button>
        </Upload>
        {describe && <p className='upload-describe'>{describe}</p>}
      </div>
      <CropperModal
        file={file}
        onSuccess={src => {
          onSuccess(src);
        }}
      />
    </>
  );
};

export default UploadAvatar;
