import React, { FC } from 'react';
import { College } from 'global_variable/home';

const mappings: { [key: string]: string } = {
  效率学院: 'AI课堂',
  认知学院: '认知课堂',
  写作学院: '创作课堂',
  教练学院: '人生课堂',
  阅读学院: '阅读课堂'
}

const Banner: FC<{ college: College }> = ({
  college: { cover, bgcolor, title, introduce }
}) => (
  <div
    className='college-detail-banner'
    style={{
      backgroundImage: `url(${cover})`,
      backgroundColor: `${bgcolor}`,
      backgroundRepeat: 'repeat',
      backgroundSize: 'contain'
    }}
  >
    <div className='college-detail-banner-title'>
      <p className='college-detail-banner-name'>{mappings[title] || title}</p>
    </div>
    <div className='college-detail-banner-introduce'>
      <p>{introduce}</p>
    </div>
  </div>
);

export default Banner;
