/**
 * @description 一门课程的具体学习中心
 * @author ali
 * @since 2019/09/02
 */
import React, { FC, useEffect } from 'react';
import './style.less';
import LearningHeader from './LearningHeader';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import CourseContent from './CourseContent';
import CourseDiscussion from './CourseDiscussion';
import CourseHomework from './CourseHomework';
import StudentAnswers from './CourseHomework/StudentAnswers';
import CourseCollection from './CourseCollection/index';
import DiffHomework from './CourseHomework/DiffHomework';
// import { useApolloClient } from 'react-apollo';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import CollectionDetailMain from './CourseCollection/CollectionMain';
import gql from 'graphql-tag';
import { Modal } from 'antd';
import Error from '../404';

interface ILearning {
  continue: any;
}

const GET_SHOW_HEADER = gql`
  {
    isShowHeader @client
    isShowFooter @client
    isLoggedIn @client
  }
`;
const LearningCenter: FC<ILearning> = props => {
  const client = useApolloClient();
  const { data } = useQuery(GET_SHOW_HEADER);

  client.writeData({ data: { isShowFooter: false, isShowHeader: false } });
  useEffect(() => {
    client.writeData({ data: { isShowFooter: false, isShowHeader: false } });
    return () => {
      // 退出显示 header footer
      client.writeData({ data: { isShowFooter: true, isShowHeader: true } });
    };
  }, []);
  // 解析出shortUrl来，取到title packageTagid course_id 卡片进度
  if (!data.isLoggedIn) {
    return null;
  }
  return (
    <div className='learning-center'>
      <LearningHeader continue={props.continue} />
      <Switch>
        <Route
          exact
          path='/stu/:code/discussion'
          component={CourseDiscussion}
        />
        <Route
          exact
          path='/stu/:code/discussion/:dk'
          component={CourseDiscussion}
        />
        <Route path='/stu/:code/diffhomework/:dk' component={DiffHomework} />
        <Route exact path='/stu/:code/homework' component={CourseHomework} />
        <Route
          exact
          path='/stu/:code/homework/:hk'
          component={CourseHomework}
        />
        <Route
          exact
          path='/stu/:code/collection'
          component={CourseCollection}
        />
        <Route path='/stu/:code/content' component={CourseContent} />
        <Redirect from='/stu/:code/' to='/stu/:code/content' />
        <Redirect from='/stu/:code' to='/stu/:code/content' />
        <Route component={Error} />
      </Switch>
    </div>
  );
};

export default LearningCenter;
