import React, { FC } from 'react';

import './style.less';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import {
  useGetSingleCardContentQuery,
  useContinueLearningQuery,
  useGetUnlockSingleCardMutation
} from '../../../../generated/graphql';
import { message } from 'antd';
import gql from 'graphql-tag';
import { updateIsLearnedState } from '../../../../utils/updatCardState';
import inserData from '../../../../utils/recursionSearchDir';
import { handleCardContent } from '../../../../utils/handleCardContent';

const GET_LEARING_CENTER = gql`
  {
    directoryList @client
    currentCardId @client
    currentParentIds @client
    currentParentId @client
    coursePackageId @client
    isLearnCardCount @client
    allCardCount @client
    copyDirectoryList @client
    cardTitle @client
    courseId @client
  }
`;
const GET_COURSE_ID = gql`
  {
    courseId @client
  }
`;
const GET_DATA = gql`
  {
    coursePackageId @client
  }
`;

const GET_CONTENT = gql`
  {
    directoryList @client
    content @client
    chapterIsFinish @client
    cardTitle @client
    cardLearnPeople @client
  }
`;

const GET_INFO = gql`
  {
    directoryList @client
  }
`;
const ContinueLearn = props => {
  const client = useApolloClient();
  // 如果使用了always:true,则使用以下注释代码
  // const {
  //   data: { courseId }
  // } = useQuery(GET_COURSE_ID);
  const {
    data: { directoryList, courseId, isLearnCardCount }
  } = useQuery(GET_LEARING_CENTER);
  const { refetch: updateContent } = useGetSingleCardContentQuery({
    skip: true
  });
  const {
    data: { coursePackageId }
  } = useQuery(GET_DATA);
  const [unlockCard] = useGetUnlockSingleCardMutation({
    skip: true
  });
  const { refetch: goningLearn } = useContinueLearningQuery({
    skip: true
  });

  return (
    <>
      {/* 描述现象：属性continue并不存在类型'{ children?: ReactNode; } */}
      {/* 消除不确定性，需要新的信息，ts的语法，jsx */}
      <span
        className='continute'
        onClick={() => {
          goningLearn({
            id: coursePackageId
          }).then(res => {
            // let topDir = data.node.contents.edges;

            try {
              let menu = [];
              const firstUnlearnedCard = res.data.node.firstUnlearnedCard;
              if (firstUnlearnedCard !== null) {
                let ancestors = res.data.node.firstUnlearnedCard.ancestors;
                const cardId = res.data.node.firstUnlearnedCard.id;
                // let isLearnCardCount =
                //     res.data.node.firstUnlearnedCard.learnedCount,
                //   allCardCount = res.data.node.firstUnlearnedCard.fileCardCount;
                //第二层目录的数据
                let takeMyData = null;
                let parentIds = [];
                if (ancestors.length > 0) {
                  for (let i = ancestors.length - 1; i > 0; i--) {
                    ancestors[i].children.map(item => {
                      if (item.id === ancestors[i - 1].id) {
                        item.children = ancestors[i - 1].children;
                      }
                      return item;
                    });
                  }
                  takeMyData = ancestors[ancestors.length - 1];
                  ancestors.map(item => {
                    parentIds.push(item.id);
                  });
                }
                // 找到第二层数据之后，再插入对应的第一层目
                menu = directoryList.map(item => {
                  if (item.id === takeMyData.id) {
                    item.children = takeMyData.children;
                  }
                  return item;
                });

                try {
                  //初始化获卡片内容
                  updateContent({
                    id: cardId,
                    coursePackageId: coursePackageId,
                    courseId: courseId
                  }).then(res => {
                    try {
                      if (res.data && res.data.node) {
                        let newContent = handleCardContent(
                          res.data.node.name,
                          res.data.node.content
                        );

                        const newDir = updateIsLearnedState(
                          menu,
                          res.data.node
                        );
                        client.writeQuery({
                          query: GET_CONTENT,
                          data: {
                            copyDirectoryList: newDir,

                            directoryList: newDir,
                            content: newContent,
                            cardTitle: res.data.node.name,
                            cardLearnPeople: res.data.node.learnedPeopleCount
                          }
                        });
                        client.writeQuery({
                          query: GET_LEARING_CENTER,
                          data: {
                            currentCardId: cardId,
                            currentParentId: firstUnlearnedCard.parent.id,
                            currentParentIds: parentIds
                            // isLearnCardCount,
                            // allCardCount
                          }
                        });
                        client.writeData({
                          data: { isLearnCardCount: isLearnCardCount + 1 }
                        });
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  });
                  // 自动解锁第一张
                  unlockCard({
                    variables: {
                      input: {
                        cardId: window.atob(cardId).split('Card:')[1],
                        coursePackageId: window
                          .atob(coursePackageId)
                          .split('CoursePackage:')[1],
                        visitDuration: 20.1
                      },
                      coursePackageId: coursePackageId
                    }
                  });
                } catch (err) {
                  console.log(err);
                }
              }
              if (firstUnlearnedCard === null) {
                message.success(
                  '恭喜你刷完了所有卡片，快去讨论区和同学分享你的学习心得！'
                );
              }
            } catch (err) {
              console.log(err);
            }
          });
        }}
      >
        继续学习
      </span>
    </>
  );
};

export default ContinueLearn;
