import gql from 'graphql-tag';
import { Base64 } from 'js-base64';

const SET_EXAM_ANSWER_INFO = gql`
  {
    examAnswerInfo @client
  }
`;
//设置卡片问题和回答的目录结构，方便后续上传答案
const makeExamCardInfo = (cardContent, key, client) => {
  let position = [];
  let pos = cardContent.indexOf('```');
  while (pos > -1) {
    position.push(pos);
    pos = cardContent.indexOf('```', pos + 1);
  }

  let info = [];
  for (let index = 0; index < position.length; index += 2) {
    let question = cardContent.substring(
      position[index],
      position[index + 1] + 3
    );
    let obj = {
      question: question,
      answers: [],
      isCorrect: false
    };
    info.push(obj);
  }

  let examInfo = { card: Base64.decode(key).split("Card:")[1] , info: info };
  client.writeQuery({
    query: SET_EXAM_ANSWER_INFO,
    data: { examAnswerInfo: examInfo }
  });
};

export default makeExamCardInfo;
