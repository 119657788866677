import gql from 'graphql-tag';

export const LIST_COLLEGES = gql`
  query listColleges {
    courseCategories {
      id
      name
      parent {
        id
      }
    }
  }
`;

export const LIST_COURSES = gql`
  query listCourses($filter: CourseFilter, $first: Int) {
    courses(filter: $filter, first: $first) {
      count
       edges {
        cursor
        node {
          title
          cover
          subscribedCount
          shortUrl
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
