import * as React from 'react';
import './styles.css';

//引入的库组件
import { Input } from 'antd';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

//引入的其他页面组件

//graphql查询

//引入的资源图片
import search from '../../../../assets/icons/search.png';
import cross from '../../../../assets/icons/cross.png';
import createIcon from '../../../../assets/v2.1/create.png';

const GET_DISCUSS_SEARCH_AND_INITIATE_INFO = gql`
  {
    discussMarkdownContent @client
    discussSearchContent @client
    discussPageContent @client
    discussSelfDiscussTitle @client
    discussSelectedTags @client
    discussCoursePackageId @client
    aboutMine @client
    filterSelectedTags @client
    discussStatus @client
    discussCountPerPage @client
    discussSort @client {
      type
      label
    }
    isModifyDisc @client
    isShowDiscList @client
    isCheckDisc @client
  }
`;

const SearchAndInitiate = (props: any) => {
  const client = useApolloClient();
  const { data: pageInfo } = useQuery(GET_DISCUSS_SEARCH_AND_INITIATE_INFO);
  const {
    discussSearchContent,
    discussCoursePackageId,
    aboutMine,
    filterSelectedTags,
    discussStatus,
    discussCountPerPage,
    discussSort
  } = pageInfo;

  return (
    <div className='new-routes-study-courseDiscussion-1045'>
      <Input
        maxLength={36}
        className='new-routes-study-courseDiscussion-1059'
        suffix={
          discussSearchContent ? (
            <a
              onClick={() => {
                client.writeData({
                  data: { discussSearchContent: '', discussPageContent: '' }
                });

                let input = {
                  coursePackageId: discussCoursePackageId,
                  filter: {
                    name: undefined,
                    iParticipated: aboutMine ? aboutMine : undefined,
                    labels:
                      filterSelectedTags.length > 0
                        ? filterSelectedTags.map((item: any) => {
                            return item.name;
                          })
                        : undefined,
                    status: discussStatus === '0'
                  },
                  last: undefined,
                  before: undefined,
                  first: discussCountPerPage,
                  after: undefined,
                  order: discussSort.type
                };
                props.getListData(input, 1);
              }}
            >
              <img src={cross} data-rjs='2' />
            </a>
          ) : null
        }
        value={discussSearchContent}
        placeholder='搜索讨论'
        prefix={
          <a>
            <img src={search} data-rjs='2' />
          </a>
        }
        onChange={value => {
          client.writeData({
            data: { discussSearchContent: value.target.value }
          });
        }}
        onPressEnter={e => {
          client.writeData({
            data: { discussPageContent: (e.target as any).value }
          });

          let input = {
            coursePackageId: discussCoursePackageId,
            filter: {
              name:
                (e.target as any).value === ''
                  ? undefined
                  : (e.target as any).value,
              iParticipated: aboutMine ? aboutMine : undefined,
              labels:
                filterSelectedTags.length > 0
                  ? filterSelectedTags.map((item: any) => {
                      return item.name;
                    })
                  : undefined,
              status: discussStatus === '0'
            },
            last: undefined,
            before: undefined,
            first: discussCountPerPage,
            after: undefined,
            order: discussSort.type
          };
          props.getListData(input, 1);
        }}
      />

      <div
        className='new-routes-study-courseDiscussion-1071'
        onClick={() => {
          client.writeQuery({
            query: GET_DISCUSS_SEARCH_AND_INITIATE_INFO,
            data: {
              discussPageContent: '',
              discussSearchContent: '',
              discussSelfDiscussTitle: '',
              discussMarkdownContent: '',
              discussSelectedTags: [],
              isModifyDisc: false,
              isShowDiscList: false,
              isCheckDisc: false
            }
          });
          // client.writeData({
          //   data: {
          //     isModifyDisc: false,
          //     isShowDiscList: false,
          //     isCheckDisc: false
          //   }
          // });
        }}
      >
        <img
          src={createIcon}
          className='new-routes-study-courseDiscussion-1082'
        />
        <div className='new-routes-study-courseDiscussion-1097'>发起讨论</div>
      </div>
    </div>
  );
};

export default SearchAndInitiate;
