import React, { FC } from 'react';
import { College } from 'global_variable/home';

const Banner: FC<{ college: College | null }> = ({ college }) => {
  if (!college) return null;

  const { cover, bgcolor } = college;

  return (
    <div
      className='course-detail-banner'
      style={{
        backgroundImage: `url(${cover})`,
        backgroundColor: `${bgcolor}`,
        backgroundRepeat: 'repeat',
        backgroundSize: '200px 200px'
      }}
    ></div>
  );
};

export default Banner;
