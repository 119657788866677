import * as React from 'react';
import './style.less';

//引入的库组件
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import { NavLink } from 'react-router-dom';

//引入的其他页面组件

//graphql查询

//引入的资源图片
import mobileMenuIcon from '../../../../assets/icons/mobile-menu.png';

const MOBILE_MENU_INFO = gql`
  {
    isShowMobileDirectory @client
  }
`;

const MobileMenuLink = (props: any) => {
  const client = useApolloClient();
  const { data: menuInfo } = useQuery(MOBILE_MENU_INFO);
  const { isShowMobileDirectory } = menuInfo;

  const { list, shortUrl } = props;
  let newList: any = [];

  let handleList = list.map((item: any) => {
    if (item.packageTag === 'CONTENT') {
      item.name = '课程';
      item.link = `/stu/${shortUrl}/content`;
    }
    if (item.packageTag === 'ASSIGNMENT') {
      item.name = '作业';
      item.link = `/stu/${shortUrl}/homework`;
    }
    if (item.packageTag === 'DISCUSSION') {
      item.name = '讨论';
      item.link = `/stu/${shortUrl}/discussion`;
    }
    return item;
  });
  handleList.find((item: any) => {
    if (item.name === '课程') {
      newList.push(item);
    }
  });
  handleList.find((item: any) => {
    if (item.name === '作业') {
      newList.push(item);
    }
  });
  handleList.find((item: any) => {
    if (item.name === '讨论') {
      newList.push(item);
    }
  });

  return (
    <div className='new-routes-study-MyStudyCourse-12345'>
      <div style={{ width: '100%', height: 2, backgroundColor: '#f3f5f8' }} />
      <div className="new-routes-study-MyStudyCourse-13225">
        <img
          src={mobileMenuIcon}
          className='new-routes-study-MyStudyCourse-13701'
          alt='mobileMenuIcon'
          onClick={() => {
            client.writeData({
              data: { isShowMobileDirectory: !isShowMobileDirectory }
            });
          }}
        />
        <div className='menu-link-mobile'>
        {newList.length > 0 &&
          newList.map((item: any) => (
            <NavLink
              className='each-link'
              activeClassName='selected'
              activeStyle={{ color: 'red' }}
              to={item.link}
              key={item.id}
            >
              {item.name}
            </NavLink>
          ))}
        <NavLink
          className='each-link'
          activeClassName='selected'
          activeStyle={{ color: 'red' }}
          to={`/stu/${shortUrl}/collection`}
        >
          收藏
        </NavLink>
      </div>
      </div>
    </div>
  );
};

export default MobileMenuLink;
