import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Spin, Breadcrumb, message, Layout } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { FormComponentProps } from 'antd/es/form';
import { withRouter } from 'react-router';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import ItemRender from '../CommonComponents/ItemRender';
import DiffView from '../CommonComponents/DiffView';
import { RedButton } from '../CommonComponents/CustomButton';
import Modal2CallbackFunc from '../CommonComponents/Modals/Modal2CallbackFunc';

//graphql查询
import { useMergeMutation } from '../../generated/graphql';

//引入的资源图片
import back from '../../assets/icons/back.png';
import root from '../../assets/icons/file_medium_root.png';

interface Props extends FormComponentProps {
  props: any;
}

const GET_DIFF_CHANGES_INFO = gql`
  {
    diffChangesStr @client
    isMerging @client
    packageTitle @client
    selectedFileId @client
  }
`;

const EduManagerPackageMergeFromMasterPage = (props: any) => {
  const client = useApolloClient();
  const { data: diffChangesInfo } = useQuery(GET_DIFF_CHANGES_INFO);
  const {
    diffChangesStr,
    isMerging,
    packageTitle,
    selectedFileId
  } = diffChangesInfo;
  const { form } = props;

  const [mergeMutation] = useMergeMutation();

  const routes = [
    {
      path: '//edu',
      breadcrumbName: '全部文件'
    },
    {
      path: '',
      breadcrumbName: '我协作的'
    },
    {
      path: '',
      breadcrumbName: packageTitle
    },
    {
      path: '',
      breadcrumbName: '合并主干卡包'
    }
  ];

  return (
    <Layout className='routes-course-MergeMaster-1325'>
      <div className='routes-course-MergeMaster-1388'>
        <div className='routes-course-MergeMaster-1450'>
          <div
            onClick={() => {
              props.history.push('/edu');
            }}
            className='routes-course-MergeMaster-1616'
          >
            <img src={back} />
            <span className='routes-course-MergeMaster-1732'>返回</span>
            <div className='routes-course-MergeMaster-1801' />
          </div>
          <Breadcrumb
            separator={'›'}
            itemRender={ItemRender}
            routes={routes}
            className='routes-course-MergeMaster-1977'
          />
        </div>

        <span className='routes-course-MergeMaster-2032'>
          共有{diffChangesStr.split('--git').length - 1}个文件变更
        </span>

        {diffChangesStr === '' || packageTitle === '' ? null : (
          <>
            <div className='routes-study-SubMerge-4582'>
              <DiffView
                currentCommit={{
                  diff: diffChangesStr.replace(/medium\n/g, '')
                }}
                lastCommit={null}
                showAll={false}
                showType={'split'}
              />
            </div>

            <div className='routes-study-SubMerge-4133'>
              <RedButton
                width={'100px'}
                height={'40px'}
                onClick={() => {
                  form.validateFieldsAndScroll(
                    { first: true, force: true },
                    (errors: any, values: any) => {
                      if (errors) {
                        return;
                      }

                      Modal2CallbackFunc.callback = () => {
                        let input = {
                          currentPackageId: Base64.decode(selectedFileId).split(
                            'Package:'
                          )[1],
                        };
                        mergeMutation({
                          variables: { input: input }
                        })
                          .then((res: any) => {
                            if (res.data.merge.errors) {
                              message.error('合并失败!');
                            } else {
                              let merger = res.data.merge.merger;
                              if(merger.conflicts && merger.conflicts.length > 0) {
                                message.error('合并发生冲突!');
                                client.writeData({data: {conflictData: merger.conflicts, isMasterMerging: false}});
                                props.history.push('/edu/diff/resolveconflict');
                              }else {
                                message.success('合并成功!');
                                props.history.push('/edu');
                              }
                            }

                            client.writeData({
                              data: {
                                modal2Loading: false,
                                modal2: {
                                  show: false,
                                  title: '',
                                  content: '',
                                  __typename: 'Modal2'
                                }
                              }
                            });
                          })
                          .catch((error: any) => {
                            client.writeData({
                              data: {
                                modal2Loading: false,
                                modal2: {
                                  show: false,
                                  title: '',
                                  content: '',
                                  __typename: 'Modal2'
                                }
                              }
                            });
                            message.error('合并失败!');
                          });
                      };

                      client.writeData({
                        data: {
                          modal2Loading: false,
                          modal2: {
                            show: true,
                            title: '确定要合并变更吗？',
                            content: '合并变更后，卡包内容将被覆盖。',
                            __typename: 'Modal2'
                          }
                        }
                      });

                      // dispatch({
                      //   type: 'handler/handModal2',
                      //   modal2: {
                      //     show: true,
                      //     title: '确定要提交变更吗？',
                      //     content: '提交变更后，卡包拥有者将收到消息。',
                      //     event: () => {
                      //       dispatch({
                      //         type: 'course/submitMerge',
                      //         payload: {
                      //           message: values.message,
                      //           key: selectedFileId
                      //         }
                      //       });
                      //     }
                      //   }
                      // });
                    }
                  );
                }}
              >
                确认合并
              </RedButton>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

const EduManagerPackageMergeFromMasterPageWrapper = Form.create<Props>()(
  withRouter(EduManagerPackageMergeFromMasterPage)
);

export default EduManagerPackageMergeFromMasterPageWrapper;
