import * as React from 'react';
import './styles.css';

//引入的库组件
import { Modal, Form, Input, message } from 'antd';
import { withRouter } from 'react-router';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import CreateModalCallback from './CreateModalCallbackFunc';

//graphql查询
import { useEduCreatePackageMutation, useEduCreateCardMutation, FileType } from '../../generated/graphql';

//引入的资源图片

const GET_CREATE_MODAL_INFO = gql`
  {
    isShowCreateModal @client
    isCreateFolder @client
    selectedFileId @client
    pMode @client
    selectedFilePackageId @client(always: true)
  }
`;

const CreateModal = (props: any) => {
  const { form } = props;


  const client = useApolloClient();
  const { data: createModalInfo } = useQuery(GET_CREATE_MODAL_INFO);
  const { isCreateFolder, isShowCreateModal, selectedFileId, pMode, selectedFilePackageId } = createModalInfo;

  const [createPackage] = useEduCreatePackageMutation();
  const [createCard] = useEduCreateCardMutation();

  const handleCancel = () => {
    client.writeData({ data: { isShowCreateModal: false } });
  };

  const handleCreate = () => {
    form.validateFieldsAndScroll((errors: any, values: any) => {
      if (errors) {
        return;
      }

      if(isCreateFolder) {
        let packageId = selectedFilePackageId;
        packageId = Base64.decode(packageId).split("Package:")[1];
        let pid = selectedFileId === selectedFilePackageId ? null : Base64.decode(selectedFileId).split("Card:")[1];
        
        let input = pid ? 
        {content: "", fileType: FileType.Folder, name: values.fileName.trim(), pid: pid, packageId: packageId} : {content: "", fileType: FileType.Folder, name: values.fileName.trim(), packageId: packageId} ;

        createCard({variables: {input: input}}).then((res: any) => {
          if(res.data.createCard.errors) {
            message.error("创建失败!");
          }else {
            message.success("创建成功!");
            form.resetFields();
            client.writeData({ data: { isShowCreateModal: false } });
            if(CreateModalCallback.callback){
              CreateModalCallback.callback(res.data.createCard.card);
              CreateModalCallback.callback = null;
            }
          }
        }).catch(res => {
          message.error("创建失败!");
        });
      }else {
        createPackage({variables: {input: {name: values.fileName.trim()}}}).then((res: any) => {
          if(res.data.createPackage.errors) {
            message.error("创建失败!");
          }else {
            message.success("创建成功!");
            form.resetFields();
            client.writeData({ data: { isShowCreateModal: false } });
            if(CreateModalCallback.callback){
              CreateModalCallback.callback(res.data.createPackage.package);
              CreateModalCallback.callback = null;
            }
          }
        }).catch(res => {
          message.error("创建失败!");
        });
      }
    });
  };

  return (
    <Modal
      afterClose={() => {
        form.resetFields();
      }}
      width={380}
      cancelText={'取消'}
      okText={'保存'}
      className='edu-createModal-1546'
      title={isCreateFolder ? '新建文件夹' : '新建卡包'}
      visible={isShowCreateModal}
      onOk={handleCreate}
      onCancel={handleCancel}
    >
      <Form>
        <Form.Item hasFeedback>
          {form.getFieldDecorator('fileName', {
            rules: [
              {
                required: true,
                message: isCreateFolder ? '请输入文件夹名称' : '请输入卡包名'
              }
            ]
          })(
            <Input
              maxLength={36}
              className='edu-createModal-2036'
              size={'large'}
              onPressEnter={handleCreate}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const CreateModalWrapper = Form.create()(withRouter(CreateModal));

export default CreateModalWrapper;
