import React, { FC, useEffect, useState, useRef } from 'react';
import { Modal, message } from 'antd';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import config from '../../config';
import headers from '../../global_variable/headers';
import CropperComponent from './CropperComponent';

const TOKENURL = config.qiniuUri + 't/api/qiniu_token';
const UPLOADURL = 'https://up-z1.qiniup.com';

interface ICropperModal {
  file: any;
  onSuccess: (src: string) => void;
}

const CropperModal: FC<ICropperModal> = ({ file, onSuccess }) => {
  const cropperRef: any = useRef();
  const [src, setSrc] = useState('');
  const [visible, setVisible] = useState(false);

  // 获取上传文件
  useEffect(() => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e: any) => {
        const dataURL = e.target.result;
        setSrc(dataURL);
        setVisible(true);
      };
    }
  }, [file]);

  // 提交设置头像
  const handleSubmit = () => {
    cropperRef.current.getCroppedCanvas().toBlob(async (blob: any) => {
      try {
        const requestToken = await fetch(TOKENURL, {
          method: 'GET',
          headers: headers
        });
        const resultToken = await requestToken.text();
        const token = resultToken;
        const formData = new FormData();
        formData.append('token', token);
        formData.append('file', blob);
        const upload = await fetch(UPLOADURL, {
          method: 'POST',
          body: formData
        });
        const resultUpload = await upload.json();
        const src = `https://static.openmindclub.com/${resultUpload.key}`;
        onSuccess(src);
        setVisible(false);
      } catch (error) {
        message.error('上传失败，请检查网络设置');
      }
    });
  };

  return (
    <Modal
      title='裁剪头像'
      okText='设置头像'
      cancelText='取消'
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => setVisible(false)}
    >
      {/* <Cropper
        ref={cropperRef}
        src={src}
        style={{ height: 400, width: '100%' }}
      /> */}
      <CropperComponent
        cropperRef={cropperRef}
        src={src}
        style={{ height: 400, width: '100%' }}
      />
    </Modal>
  );
};

export default CropperModal;
