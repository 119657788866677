import gql from 'graphql-tag';

export const query = gql`
  {
    home @client {
      colleges {
        path
        icon
        title
        introduce
        bgcolor
        cover
      }
    }
    courseDetail @client {
      college
      course
      payVisible
      popVisible
      disabled
      btnText
    }
    isLoggedIn @client
    userInfo @client {
      userid
      userProfile {
        id
      }
    }
  }
`;

export const writeQuery_course_detail = gql`
  {
    courseDetail @client {
      college
      course
    }
  }
`;

export const writeQuery_payVisible = gql`
  {
    courseDetail @client {
      payVisible
    }
  }
`;

export const writeQuery_popVisible = gql`
  {
    courseDetail @client {
      popVisible
    }
  }
`;

export const writeQuery_btn = gql`
  {
    courseDetail @client {
      disabled
      btnText
    }
  }
`;
