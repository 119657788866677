/**
 * @description 我的回答
 * @author 阿礼
 * @since 2019-9-14
 */
import React, { useState, useEffect } from 'react';
import './style.less';

// 第三方库
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { Avatar, Icon, Form, message, Dropdown, Menu } from 'antd';
import copy from 'copy-to-clipboard';
import moment from 'moment/moment';
import { checkMDContent } from '../../../../utils/checkForm';
import MyReactMarkdown from '../../../CommonComponents/MyReactMarkdown';
import {
  useCollectCardMutation,
  useUnCollectMutation
} from '../../../../generated/graphql.tsx';
import { Base64 } from 'js-base64';

import { history } from '../../../IndexPage';

// 图片资源
import editIcon from '../../../../assets/v2.1/edit.png';
import favorite from '../../../../assets/icons/favorite.png';
import favorite_active from '../../../../assets/icons/favorite_active.png';

const GET_ANSWER = gql`
  {
    answerContent @client
    nickname @client
    avatar @client
    postTime @client
    questionPid @client
    coursePackageAssignmentId @client
    assignmentList @client
    allAssignmentList @client
    isIveDone @client
    answerId @client
    assignmentId @client
    courseId @client
    isCollected @client
    homeworkCommits @client {
      commitHash
      commitedAt
    }
  }
`;
const MyAnswer = props => {
  const client = new useApolloClient();
  const {
    data: {
      answerContent,
      nickname,
      avatar,
      postTime,
      questionPid,
      coursePackageAssignmentId,
      isIveDone,
      assignmentId,
      answerId,
      courseId,
      isCollected,
      homeworkCommits
    },
    loading
  } = useQuery(GET_ANSWER);
  // 收藏
  const [collect] = useCollectCardMutation({ skip: true });
  // 未收藏
  const [unCollect] = useUnCollectMutation({ skip: true });
  if (loading) {
    return null;
  }

  const renderCommitsList = () => {
    let temp = [];
    homeworkCommits.map((item, index) => {
      temp.push(item);
      if (index !== homeworkCommits.length - 1) {
        temp.push({ commitedAt: 'divider' });
      }
    });
    return (
      <Menu className='commit-list-main'>
        {temp.map((item, index) => {
          if (item.commitedAt === 'divider') {
            return (
              <Menu.Divider
                key={'divider:' + index}
                style={{ width: '100%' }}
              />
            );
          } else {
            return (
              <Menu.Item
                key={'commit:' + index}
                onClick={() => {
                  const shortUrl = window.sessionStorage.getItem('shortUrl');
                  history.push(
                    `/stu/${shortUrl}/diffhomework/${
                      Base64.decode(answerId).split('Assignment:')[1]
                    }:${item.commitHash}`
                  );
                }}
              >
                <div>提交时间:{item.commitedAt}</div>
                <div>Commit:{item.commitHash}</div>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );
  };

  // 如果作业做完，展示内容，如果没做，markdown展示

  return (
    <div className='assignment-other-answer'>
      <>
        <div className='assignment-answer-meta'>
          <div className='avatar-time'>
            <Avatar src={avatar} />
            <div className='nickname-time'>
              <span className='time'> {nickname}</span>
              <span className='time'>{postTime}</span>
            </div>
          </div>
          <div className='function-icon'>
            <Dropdown
              trigger={['hover']}
              placement={'bottomCenter'}
              overlay={renderCommitsList()}
            >
              <div className='commit-other'>
                <Icon type='down' style={{ marginRight: 5 }} />
                <div>查看历史提交</div>
              </div>
            </Dropdown>
            <Icon
              type='share-alt'
              style={{
                marginLeft: '10px',
                color: 'rgb(173, 173, 173)',
                fontSize: '19px'
              }}
              onClick={() => {
                // 这里的链接是，作业查看里面的链接
                const ans_key = window.atob(answerId).split('Assignment:')[1];
                const homework_key = window
                  .atob(assignmentId)
                  .split('Assignment:')[1];
                // let href = window.location.href.split('/');
                let pathname = window.location.href;
                pathname = pathname.replace(
                  `${pathname.split('/')[7]}`,
                  ans_key
                );

                copy(pathname);
                message.success('作业链接已复制');
              }}
            />
            <img
              src={isCollected ? favorite_active : favorite}
              className='collect'
              onClick={() => {
                // 查询接口 传入参数

                const cardId = window.atob(answerId).split('Assignment:')[1];
                const coursePackageId = window
                  .atob(coursePackageAssignmentId)
                  .split('CoursePackage:')[1];

                const wholeCourseId = Number(
                  window.atob(courseId).split('Course:')[1]
                );
                if (!isCollected) {
                  collect({
                    variables: {
                      // courseId Q291cnNlOjMw
                      input: { cardId, courseId: wholeCourseId }
                    }
                  }).then(res => {
                    if (res.data.collectCard.errors === null) {
                      // console.log('收藏成功');
                      // 更改isCollected的值
                      client.writeQuery({
                        query: GET_ANSWER,
                        data: {
                          isCollected: true
                        }
                      });
                    }
                  });
                } else {
                  unCollect({
                    variables: {
                      input: { cardId, courseId: wholeCourseId }
                    }
                  }).then(res => {
                    // console.log('取消收藏');
                    client.writeQuery({
                      query: GET_ANSWER,
                      data: {
                        isCollected: false
                      }
                    });
                  });
                }
              }}
            />
          </div>
        </div>
        <MyReactMarkdown
          source={answerContent}
          className='new-routes-study-courseHomework-2709'
        />
      </>
    </div>
  );
};

export default MyAnswer;
