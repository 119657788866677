/**
 * @description 单张作业的问题描述展示
 * @author 阿礼
 * @since 2019-9-14
 */

import React from 'react';
import './style.less';

//第三方组件
import ReactMarkdown from 'react-markdown';
import MyReactMarkdown from '../../../CommonComponents/MyReactMarkdown';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_CONTENT = gql`
  {
    assignmentQuestion @client
  }
`;

const QuestionDisplay = props => {
  const {
    data: { assignmentQuestion },
    error,
    loading
  } = useQuery(GET_CONTENT);
  if (loading) {
    return <div>...</div>;
  }
  const title = 'Ch1基础作业';
  const content =
    '\n每章基础作业为一份试卷，由选择题构成。基础作业为每周必须完成的作业，请于 2wd1 08:42 前完成并提交。完成了知识产权承诺书，并达到满分 100 分才能解锁基础作业哦\n\n1. [请点击此链接](http://e.openmindclub.com/jq/40129936.aspx)，领取试卷，账号为你的手机号，密码为 openmind，提交后会获得一个得分。\n2. 根据得分和反馈，自检本章重要知识点学习情况，并将自评提交在下面的文本框中，字数约 200 字。自评可参考如下模板\n \n```\n## 错题复盘\n\n- 错题 1 \n  - 错误原因：理解？粗心？反映了自己哪些认知偏差？反应了自己哪些错误的思维习惯？课程哪些知识点没有理解好。\n  - 正确做法、正确理解\n- 错题 2\n  - 错误原因\n  - 正确做法\n- 其他题：对自己不确定的题目的复盘\n\n## 学习复盘\n\n- 学习反思：对本次学习的自己哪些题目的解题思路不够好\n- 知识点清单：对哪些知识点有了新的认识\n- 好习惯清单：有哪些好的工具、网站、求解习惯可以列入自己的清单，或者别的同学的好的复盘习惯\n- 改进点\n\n## 疑问和讨论\n\n看了正确答案和同学复盘以后仍然存在疑问的地方\n\n```\n\n注意：基础作业第 14 道题提及的图片，请点击[此链接](http://cardstatic.openmindclub.com/InfoAnalysis/ia003textpic.jpg)获取  \n';

  return (
    <div>
      <h2>{assignmentQuestion.title}</h2>
      {/* <ReactMarkdown source={assignmentQuestion.front} /> */}
      <MyReactMarkdown source={assignmentQuestion.front} />
    </div>
  );
};

export default QuestionDisplay;
