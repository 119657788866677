import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form , message} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { useApolloClient } from 'react-apollo';
import TextArea from 'antd/es/input/TextArea';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import { checkMDContent } from '../../../../utils/checkForm';
import MDEditor_v2 from '../../../CommonComponents/MDEditor/MDEditor_v2';
import { changeToMarkdownFormat } from '../../../../utils';

//graphql查询
import { useCreateCommentToDiscussionMutation } from '../../../../generated/graphql';

//引入的资源图片

interface Props extends FormComponentProps {
  discussData: any;
  getDiscussComment: any;
}

const GET_DISCUSS_INPUT_INFO = gql`
  {
    discussDetailId @client
    discussCoursePackageId @client
    discussCountPerPage @client
  }
`;

const DiscussCheckInput = (props: any) => {
  const client = useApolloClient();
  const [createCommentMutation] =  useCreateCommentToDiscussionMutation();
  const { data: inputData } = useQuery(GET_DISCUSS_INPUT_INFO);
  const { discussDetailId, discussCoursePackageId, discussCountPerPage } = inputData;


  const { form } = props;
  const { getFieldDecorator } = form;
  const [isUseMarkdownEditForDis, setIsUseMarkdownEditForDis] = React.useState(
    false
  );
  const [discussContent, setDiscussContent] = React.useState('');

  const [comLoading, setComLoading] = React.useState(false);

  let datas = props.discussData.node;
  let commentList = datas.comments.edges;

  const handleCommitDisc = () => {
    let input : any = {};
    if (isUseMarkdownEditForDis) {
      form.validateFieldsAndScroll(['comment'], (errors: any, values: any) => {
        if (errors) {
          return;
        }
        setComLoading(true);
        input = {
          coursePackageId: Base64.decode(discussCoursePackageId).split(
            'CoursePackage:'
          )[1],
          discussionId: Base64.decode(discussDetailId).split(
            'Discussion:'
          )[1], 
          comment: {
            content: values.comment.content.markdown
          }
        }
      });
    } else {
      if (!comLoading && discussContent.trim() !== '') {
        setComLoading(true);
        let content = changeToMarkdownFormat(discussContent);
        input = {
          coursePackageId: Base64.decode(discussCoursePackageId).split(
            'CoursePackage:'
          )[1],
          discussionId: Base64.decode(discussDetailId).split(
            'Discussion:'
          )[1], 
          comment: {
            content: content
          }
        }
      }
    }

    createCommentMutation({variables: {input : input}}).then((res: any) => {
      setComLoading(false);
      if(res.data.addCommentToDiscussion.errors) {
        message.error(res.data.addCommentToDiscussion.errors[0].message); 
      }else {
        form.resetFields();
        setDiscussContent("");
        message.success("发表成功!");
        props.getDiscussComment({discussID: discussDetailId, first: discussCountPerPage, last : undefined, before: undefined, after: undefined}).then((res: any) => {
          if(res.errors) {
            message.error("获取评论列表失败!");
          }else {
            client.writeData({data: {discussCommentPage : 1}});
          }
        });
      }
    });
  };

  return (
    <div className='new-routes-study-courseDiscussion-2414'>
      <div className='new-routes-study-courseDiscussion-2426'>
        <div className='new-routes-study-courseDiscussion-2430'>
          {datas.comments.count}条评论
        </div>
      </div>
      {isUseMarkdownEditForDis ? (
        <div className='new-routes-study-courseDiscussion-2417'>
          <Form.Item className='new-routes-study-courseDiscussion-2440'>
            {getFieldDecorator('comment', {
              validateTrigger: ['onBlur'],
              initialValue: { content: { markdown: '' } },
              rules: [
                {
                  validator: checkMDContent,
                  message: '请输入评论内容'
                }
              ]
            })(
              <MDEditor_v2
                minHeight={'150px'}
                isBottom
                butTitle={'发布评论'}
                loading={comLoading}
                onSubmit={handleCommitDisc}
                width={920}
                height={250}
                pb={48}
              />
            )}
          </Form.Item>
          <div className='new-routes-study-courseDiscussion-2442'>
            <div className='new-routes-study-courseDiscussion-2444'>
              <div
                className='new-routes-study-courseDiscussion-2485'
                onClick={() => {
                  setIsUseMarkdownEditForDis(false);
                }}
              >
                切换普通输入框
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='new-routes-study-courseDiscussion-2454'>
          <TextArea
            className='new-routes-study-courseDiscussion-2466'
            placeholder='说出你的评论'
            autosize={{ minRows: 1, maxRows: 6 }}
            value={discussContent}
            onChange={value => {
              setDiscussContent(value.target.value);
            }}
          />

          <div className='new-routes-study-courseDiscussion-2471'>
            <div className='new-routes-study-courseDiscussion-2482'>
              <div
                className='new-routes-study-courseDiscussion-2485'
                onClick={() => {
                  setIsUseMarkdownEditForDis(true);
                }}
              >
                切换Markdown编辑器
              </div>
            </div>
            <div className='new-routes-study-courseDiscussion-2492' onClick={handleCommitDisc}>
              <div
                className='new-routes-study-courseDiscussion-2495'
              >
                发布
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const DiscussCheckInputWrapper = Form.create<Props>()(DiscussCheckInput);

export default DiscussCheckInputWrapper;
