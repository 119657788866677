import * as React from 'react';
import './style.less';

//引入的库组件

//引入的其他页面组件
import { CustomP } from '../../../CommonComponents/common/customp';
import MyAudioPlayer from '../../../CommonComponents/AudioPlayer';

//graphql查询

//引入的资源图片

interface Props {
  content: any;
}

const AudioCard: React.FC<Props> = ({ content }) => {
  let audioUrl = content.front;
  let list = [
    {
      name: content.title,
      src: audioUrl,
      img: '',
      comments: []
    }
  ];

  return (
    <div className='routes-course-CardModal-5924'>
      {audioUrl !== '' ? (
        <div>
          <CustomP
            customStyle={{
              line: 2,
              fontSize: '26px',
              color: '#282A2D',
              lineHeight: '37px'
            }}
            style={{ width: 680, height: 'auto' }}
            className='routes-course-CardModal-5220'
          >
            {content.title}
          </CustomP>
          {
            <div className='routes-course-CardModal-5936'>
              {
                <MyAudioPlayer
                  key={content.title}
                  width={600}
                  height={60}
                  autoPlay={false}
                  playlist={list}
                />
              }
            </div>
          }
        </div>
      ) : null}
    </div>
  );
};

export default AudioCard;
