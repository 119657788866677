/**
 * @description 课程作业卡包
 * @author 阿礼
 * @since
 */

import React, { useEffect, useState } from 'react';
import './style.less';

import Assignments from './Assignments';
import QuestionDisplay from './QuestionDisplay';
import CheckOtherAnswer from './CheckOtherAnswer';
import MyAnswer from './MyAnswer';
import PostComment from './PostComment';
import Comments from './Comments';
import gaEvents from '../../../utils/gaEvents.js';

// 第三方库
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/react-hooks';

const IS_HAVE_DONE = gql`
  {
    isIveDone @client
    comments @client
    isShowFooter @client
    isShowHeader @client
    assignmentId @client
    coursePackageAssignmentId @client(always: true)
    isShowMobileDirectory @client
    courseTitle @client
  }
`;

const CourseHomeWork = () => {
  const client = new useApolloClient();
  const [update, setUpdate] = useState(true);
  const [isUpload, setIsUpload] = useState(false);
  const [initialValue, setinitialValue] = useState({
    content: { markdown: ''}
  });
  const [showCheckOtherAnswers, setShowCheckOtherAnswers] = useState(true);
  const {
    data: {
      isIveDone,
      comments,
      assignmentId,
      coursePackageAssignmentId,
      isShowMobileDirectory,
      courseTitle
    }
  } = useQuery(IS_HAVE_DONE);
  useEffect(() => {
    client.writeData({ data: { isShowHeader: false, isShowFooter: false } });
  }, []);

  if (coursePackageAssignmentId.length <= 0) {
    return null;
  }

  return (
    <div className='course-homework'>
      {/* 左边目录 */}
      {isShowMobileDirectory && (
        <div className='new-routes-study-courseHomework-14759' />
      )}
      <div
        className={
          isShowMobileDirectory ? 'course-directory-mobile' : 'course-directory'
        }
      >
        <Assignments coursePackageAssignmentId={coursePackageAssignmentId} setUpdate={setUpdate} setinitialValue={setinitialValue} />
      </div>
      {/* 右边内容 */}
      <div className='course-main-content'>
        <div className='course-qustion'>
          <QuestionDisplay />
        </div>
        <div className='course-my-answer'>
          {update && (
            <MyAnswer
              setUpdate={setUpdate}
              setinitialValue={setinitialValue}
              initialValue={initialValue}
              isUpload={isUpload}
              setIsUpload={setIsUpload}
            />
          )}

          {isIveDone && <PostComment update={update} setUpdate={setUpdate} setinitialValue={setinitialValue} />}
          {comments.length > 0 && isIveDone && <Comments />}
        </div>
        <div className={showCheckOtherAnswers ? 'course-other-answer' : 'course-other-answer-none'}>
          <CheckOtherAnswer assignmentId={assignmentId} setShowCheckOtherAnswers={setShowCheckOtherAnswers} />
        </div>
      </div>
    </div>
  );
};

export default CourseHomeWork;
