/**
 * @description 学员们的头像、评论数展示
 * @author 阿礼
 * @since 2019-9-15
 */

import React, { useState } from 'react';
import './style.less';
import { List, Icon, Avatar, Menu, Input, Dropdown, Pagination } from 'antd';
import { withRouter } from 'react-router-dom';
import comment from './../../../../../assets/v2.1/chat.png';

import gql from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { NavLink } from 'react-router-dom';
import { useCheckSingleAnswerQuery } from '../../../../../generated/graphql.tsx';

import { Base64 } from 'js-base64';

// 引入图片
import start from '../../../../../assets/icons/start.svg';
import filterIcon from '../../../../../assets/v2.1/filter.png';
import orderIcon from '../../../../../assets/v2.1/order.png';
import cross from '../../../../../assets/icons/cross.png';

const GET_CONTENT = gql`
  {
    answerContent @client
    answerId @client
    comments @client
    assignmentCommentsCount @client
    nickname @client
    postTime @client
    avatar @client
    courseId @client
    isCollected @client
  }
`;

const SET_COMMIT = gql`
  {
    homeworkCommits @client {
      commitHash
      commitedAt
    }
  }
`;

const GET_USER_INFO = gql`
  {
    userInfo @client {
      username
    }
  }
`;

const Students = props => {
  const client = new useApolloClient();
  const { otherAnswers, setUpdate, pageInfo, getOtherAnswersList } = props;
  const {
    data: { answerId, courseId },
    loading
  } = useQuery(GET_CONTENT);
  const {
    data: { userInfo }
  } = useQuery(GET_USER_INFO);
  const { refetch: getSingleContent } = useCheckSingleAnswerQuery({
    skip: true,
    fetchPolicy: 'network-only'
  });

  const [searchInput, setSearchInput] = useState('');
  const [order, setOrder] = useState('DESC_INSERTED_AT');
  const [filter, setFilter] = useState('name');

  if (answerId.length <= 0) {
    return null;
  }

  let aId = '',
    kId = '';

  aId = window.location.href.split('/')[6];
  kId = window.location.href.split('/')[7];

  const orderMenu = (
    <Menu
      selectedKeys={[order]}
      onClick={item => {
        let key = item.key;
        if (key !== order) {
          setOrder(key);
          let isAuthorName = filter === 'name';
          let inputContent =
            searchInput && searchInput.trim() !== ''
              ? searchInput.trim()
              : undefined;
          let variables = {
            filter: isAuthorName ?  {authorName : inputContent}  : undefined,
            first: pageInfo.countPerPage,
            after: undefined,
            before: undefined,
            last: undefined,
            matching: isAuthorName ? undefined : inputContent,
            order: key
          };
          getOtherAnswersList(variables, 1);
        }
      }}
    >
      <Menu.Item key='DESC_INSERTED_AT'>最新提交排序</Menu.Item>
      <Menu.Item key='ASC_INSERTED_AT'>最早提交排序</Menu.Item>
      <Menu.Item key='DESC_COMMENTS_COUNT'>讨论数多排序</Menu.Item>
      <Menu.Item key='ASC_COMMENTS_COUNT'>讨论数少排序</Menu.Item>
    </Menu>
  );

  const filterMenu = (
    <Menu
      selectedKeys={[filter]}
      onClick={item => {
        let key = item.key;
        if (key !== filter) {
          setFilter(key);
        }
      }}
    >
      <Menu.Item key='name'>作者姓名搜索</Menu.Item>
      <Menu.Item key='content'>作业内容搜索</Menu.Item>
    </Menu>
  );

  let listStyle = {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  };

  if(otherAnswers.length === pageInfo.countPerPage) {
    listStyle = {
      ...listStyle,
      height: "90%",
      paddingBottom: '70px',
    }
  }else {
    let rate = otherAnswers.length / pageInfo.countPerPage;
    listStyle = {
      ...listStyle,
      minHeight: rate * 90 + "%",
      paddingBottom: rate * 70 + 'px',
    }
  }

  return (
    <div className='single-assignment-students'>
      <div className='student-catalogue-filter-search'>
        <Dropdown overlay={orderMenu}>
          <a className='ant-dropdown-link' href='javascript:void(0)'>
            <span className='filter'>
              <img src={orderIcon} alt='排序标签' />
            </span>
          </a>
        </Dropdown>
        <Dropdown overlay={filterMenu}>
          <a className='ant-dropdown-link' href='javascript:void(0)'>
            <span className='filter'>
              <img src={filterIcon} alt='过滤标签' />
            </span>
          </a>
        </Dropdown>
        <Input
          className='search'
          placeholder={'搜索作业'}
          prefix={<Icon type='search' style={{ fontSize: '18px' }} />}
          suffix={
            searchInput && (
              <img
                src={cross}
                onClick={() => {
                  setSearchInput('');
                  let variables = {
                    filter: undefined,
                    first: pageInfo.countPerPage,
                    after: undefined,
                    before: undefined,
                    last: undefined,
                    matching: undefined,
                    order: order
                  };
                  getOtherAnswersList(variables, 1);
                }}
              />
            )
          }
          style={{
            width: '249px',
            height: '50px'
          }}
          value={searchInput}
          onChange={e => {
            setSearchInput(e.target.value);
          }}
          onKeyDown={e => {
            if (e.keyCode === 13 && searchInput.length === 0) {
              let variables = {
                filter: undefined,
                first: pageInfo.countPerPage,
                after: undefined,
                before: undefined,
                last: undefined,
                matching: undefined,
                order: order
              };
              getOtherAnswersList(variables, 1);
            } else if (
              e &&
              e.keyCode == 13 &&
              searchInput.trim().length !== 0
            ) {
              let isAuthorName = filter === 'name';
              let inputContent =
                searchInput && searchInput.trim() !== ''
                  ? searchInput.trim()
                  : undefined;
              let variables = {
                filter: isAuthorName ? {authorName : inputContent}  : undefined,
                first: pageInfo.countPerPage,
                after: undefined,
                before: undefined,
                last: undefined,
                matching: isAuthorName ? undefined : inputContent,
                order: order
              };
              getOtherAnswersList(variables, 1);
            }
          }}
        />
      </div>
      <Menu
        style={listStyle}
        defaultSelectedKeys={[answerId]}
        selectedKeys={[answerId]}
        onClick={item => {
          setUpdate(false);
          getSingleContent({ id: item.key, courseId })
            .then(res => {
              const data = res.data.node;
              const comments =
                data.comments.edges.length > 0
                  ? data.comments.edges.map(item => item.node)
                  : [];
              const count = data.commentsCount;

              client.writeQuery({
                query: GET_CONTENT,
                data: {
                  // 每个作业的状态
                  answerContent: data.content,
                  nickname: data.author.nickname,
                  avatar: data.author.avatar,
                  postTime: data.insertedAt,
                  comments: comments,
                  assignmentCommentsCount: count,
                  answerId: data.id,
                  isCollected: data.isCollected
                }
              });
              setUpdate(true);
              //路由跳转
              props.history.push(
                `/stu/${window.sessionStorage.getItem(
                  'shortUrl'
                )}/homework/${aId}/${
                  window.atob(data.id).split('Assignment:')[1]
                }`
              );
              props.getCommits({ id: item.key }).then(res => {
                if (res.data.node.commits) {
                  client.writeQuery({
                    query: SET_COMMIT,
                    data: { homeworkCommits: res.data.node.commits }
                  });
                } else {
                  client.writeData({ data: { homeworkCommits: [] } });
                }
              });
            })
            .catch(() => {
              setUpdate(true);
            });
        }}
      >
        {otherAnswers.map(item => (
          <Menu.Item key={item.id}>
            <div className='single-student'>
              <Avatar
                src={item.author.avatar}
                style={{ width: '32px', height: '32px' }}
              />
              <div className='nickname-time'>
                <div className='author-nickname'>
                  <span>{item.author.nickname}</span>
                  {userInfo.username === item.author.nickname && ( // 判断是否是自己的作业，如果是增加一个标记
                    <img src={start} alt='星星' className='myself' />
                  )}
                </div>
                <div className='time'>{item.insertedAt}</div>
              </div>
              <div className='comments-count'>
                <img src={comment} style={{ width: '20px', height: '20px' }} />
                <span>{item.commentsCount}</span>
              </div>
            </div>
          </Menu.Item>
        ))}
      </Menu>
      <div className='pagination-container'>
        <Pagination
          // itemRender={(current, type, originalElement) => {
          //   return originalElement;
          // }}
          showQuickJumper
          showLessItems
          hideOnSinglePage
          pageSize={pageInfo.countPerPage}
          current={pageInfo.page}
          total={pageInfo.count}
          onChange={e => {
            {
              let pages = e - pageInfo.page;
              let variables = {};
              if (pages > 0) {
                let currentLastDataCursor = pageInfo.endCursor;
                let cursorName = Base64.decode(currentLastDataCursor).split(
                  ':'
                )[0];
                let index =
                  parseInt(Base64.decode(currentLastDataCursor).split(':')[1]) +
                  (pages - 1) * pageInfo.countPerPage;
                let isAuthorName = filter === 'name';
                variables = {
                  filter: isAuthorName
                    ? { authorName: searchInput.trim() }
                    : undefined,
                  first: pageInfo.countPerPage,
                  after: Base64.encode(cursorName + ':' + index),
                  before: undefined,
                  last: undefined,
                  matching:
                    searchInput && searchInput.trim() !== ''
                      ? searchInput.trim()
                      : undefined,
                  order: order
                };
              } else {
                let currentLastDataCursor = pageInfo.startCursor;
                let cursorName = Base64.decode(currentLastDataCursor).split(
                  ':'
                )[0];
                let index =
                  parseInt(Base64.decode(currentLastDataCursor).split(':')[1]) +
                  (pages + 1) * pageInfo.countPerPage;
                let isAuthorName = filter === 'name';
                let inputContent =
                  searchInput && searchInput.trim() !== ''
                    ? searchInput.trim()
                    : undefined;
                variables = {
                  filter: isAuthorName ? {authorName : inputContent}  : undefined,
                  last: pageInfo.countPerPage,
                  before: Base64.encode(cursorName + ':' + index),
                  first: undefined,
                  after: undefined,
                  matching: isAuthorName ? undefined : inputContent,
                  order: order
                };
              }
              getOtherAnswersList(variables, e);
            }
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(Students);
