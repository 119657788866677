import * as React from 'react';
import './styles.css';

//引入的库组件
import {
  Form,
  Layout,
  Dropdown,
  Breadcrumb,
  Modal,
  Input,
  message,
  Menu,
  List,
  Button,
  Radio,
  Checkbox
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Base64 } from 'js-base64';
import { useApolloClient } from 'react-apollo';
import json2md from 'json2md';
import RadioGroup from 'antd/es/radio/group';

//引入的其他页面组件
import ItemRender from '../CommonComponents/ItemRender';
import MDEditor_v2 from '../CommonComponents/MDEditor/MDEditor_v2';
import { RedButton, WhiteButton } from '../CommonComponents/CustomButton';
import { checkMDContent, checkAnswerItem } from '../../utils/checkForm';
import { getExamCardContent } from '../../utils';
import { QItem } from '../CommonComponents/CustomItem';
import RadioLayout from '../CommonComponents/layout/RadioLayout';
import CheckboxLayout from '../CommonComponents/layout/CheckboxLayout';

//graphql查询
import {
  useCreateCardMutation,
  useModifyCardMutation,
  FileType,
  GetCardInfoQuery,
  useDeleteCardMutation
} from '../../generated/graphql';

//引入的资源图片
import backImg from '../../assets/icons/back.png';
import del from '../../assets/icons/delete.png';
import deleteImg from '../../assets/icons/delete.png';
import create from '../../assets/icons/create.png';

interface Props extends FormComponentProps {
  props: any;
  cardInfo: GetCardInfoQuery | null;
}

const CustomForm = styled(Form)`
  .ant-form-explain {
    margin-left: 10px;
  }
`;

const GET_CREATE_CARD_INFO = gql`
  {
    pMode @client
    isEditCard @client(always: true)
    selectedFileId @client
    selectedFilePackageId @client
  }
`;

const CreateExamCard = (props: any) => {
  const [choice, setChoice] = React.useState([] as any);
  const [choices, setChoices] = React.useState([] as any);
  const [srAnswer, setSrAnswer] = React.useState([]);
  const [pre, setPre] = React.useState(false);
  const [preData, setPreData] = React.useState({} as any);
  const [backContent, setBackContent] = React.useState('');
  const [isInit, setIsInit] = React.useState(false);

  const client = useApolloClient();
  const { data: createCardInfoData } = useQuery(GET_CREATE_CARD_INFO);
  const {
    pMode,
    isEditCard,
    selectedFileId,
    selectedFilePackageId
  } = createCardInfoData;
  const { form } = props;

  const { getFieldDecorator, setFieldsValue } = form;

  const [createTextCard] = useCreateCardMutation();
  const [modifyTextCard] = useModifyCardMutation();
  const [deleteTextCard] = useDeleteCardMutation();

  const routes = () => {
    return [
      {
        path: '//edu',
        breadcrumbName: '全部文件'
      },
      {
        path: '',
        breadcrumbName: pMode ? '我创建的' : '我协作的'
      },
      {
        path: '',
        breadcrumbName: isEditCard ? '编辑测验卡片' : '新建测验卡片'
      }
    ];
  };

  const mapChoice2Json = (values: any, choice: any) => {
    return choice.map((item: any) => {
      return [
        { p: item.choice.indexOf('Single') !== -1 ? '```SQ' : '```MQ' },
        { p: `Q: ${values[item.choice]}` },
        {
          p: item.answer.map((item1: any) => {
            if (item1.answer === values[item.rightAnswer]) {
              return `ASWT:${values[item1.answer].aw}`;
            } else {
              return `ASWF:${values[item1.answer].aw}`;
            }
          })
        },
        { p: '```' }
      ];
    });
  };

  const mapChoices2Json = (values: any, choice: any) => {
    return choice.map((item: any) => {
      return [
        { p: item.choice.indexOf('Single') !== -1 ? '```SQ' : '```MQ' },
        { p: `Q: ${values[item.choice]}` },
        {
          p: item.answer.map((item1: any) => {
            if (values[item1.answer].tf) {
              return `ASWT:${values[item1.answer].aw}`;
            } else {
              return `ASWF:${values[item1.answer].aw}`;
            }
          })
        },
        { p: '```' }
      ];
    });
  };

  const handleSave = () => {
    form.validateFieldsAndScroll((errors: any, values: any) => {
      if (errors) {
      } else {
        const singleContent = mapChoice2Json(
          values,
          choice.filter((item: any) => {
            return item !== null;
          })
        );
        const multipleContent = mapChoices2Json(
          values,
          choices.filter((item: any) => {
            return item !== null;
          })
        );
        let content = [{ h1: 'front' }]
          .concat(singleContent)
          .concat(multipleContent);

        if (isEditCard) {
          if (props.cardInfo) {
            let id = Base64.decode(props.cardInfo.node.id).split('Card:')[1];
            modifyTextCard({
              variables: {
                input: {
                  content: `---\r\ntitle:${
                    values.title
                  }\r\nsign:default\r\n---\r\n${json2md(content)}# back\n${
                    values.backContent.content.markdown
                  }`,
                  id: id
                }
              }
            }).then((res: any) => {
              if (res.data.updateCardContent.errors) {
                message.error('修改失败!');
                return;
              } else {
                message.success('修改成功!');
                props.props.history.goBack();
              }
            });
          } else {
            message.error('修改失败!');
          }
        } else {
          if (selectedFilePackageId === '' || selectedFileId === '') {
            message.error('请选择要创建卡片的文件夹!');
            return;
          }
          let packageId = selectedFilePackageId;
          packageId = Base64.decode(packageId).split('Package:')[1];
          let pid =
            selectedFileId === selectedFilePackageId
              ? null
              : Base64.decode(selectedFileId).split('Card:')[1];
          let input = pid
            ? {
                content: `---\r\ntitle:${
                  values.title
                }\r\nsign:default\r\n---\r\n${json2md(content)}# back\n${
                  values.backContent.content.markdown
                }`,
                fileType: FileType.File,
                name: values.title.trim() + '.exam',
                pid: pid,
                packageId: packageId
              }
            : {
                content: `---\r\ntitle:${
                  values.title
                }\r\nsign:default\r\n---\r\n${json2md(content)}# back\n${
                  values.backContent.content.markdown
                }`,
                fileType: FileType.File,
                name: values.title.trim() + '.exam',
                packageId: packageId
              };

          createTextCard({ variables: { input: input } }).then((res: any) => {
            if (res.data.createCard.errors) {
              message.error('创建失败!');
              return;
            }
            message.success('创建成功!');
            props.props.history.goBack();
          });
        }
      }
    });
  };

  const onPre = () => {
    form.validateFieldsAndScroll((errors: any, values: any) => {
      if (errors) {
      } else {
        setPre(true);
        setPreData(values);
      }
    });
  };

  const topic = (
    <Menu
      onClick={item => {
        switch (item.key) {
          case 'sq':
            setChoice(
              choice.concat({
                choice: `SingleQuestion${choice.length}${1}`,
                defaultQ: '',
                rightAnswer: `sr${choice.length}${1}`,
                answer: [
                  {
                    answer: `SingleAnswer${choice.length + 1}1`,
                    tf: `stf${choice.length}${1}1`,
                    defaultA: ''
                  },
                  {
                    answer: `SingleAnswer${choice.length + 1}2`,
                    tf: `stf${choice.length}${1}2`,
                    defaultA: ''
                  },
                  {
                    answer: `SingleAnswer${choice.length + 1}3`,
                    tf: `stf${choice.length}${1}3`,
                    defaultA: ''
                  }
                ]
              })
            );

            break;
          case 'mq':
            setChoices(
              choices.concat({
                choice: `MultipleQuestion${choices.length}${1}`,
                defaultQ: '',
                rightAnswer: `mr${choices.length}`,
                defaultRA: [],
                answer: [
                  {
                    answer: `MultipleAnswer${choices.length + 1}1`,
                    tf: `mtf${choices.length}${1}1`,
                    defaultA: ''
                  },
                  {
                    answer: `MultipleAnswer${choices.length + 1}2`,
                    tf: `stf${choices.length}${1}2`,
                    defaultA: ''
                  },
                  {
                    answer: `MultipleAnswer${choices.length + 1}3`,
                    tf: `stf${choices.length}${1}3`,
                    defaultA: ''
                  }
                ]
              })
            );
            break;
          default:
        }
      }}
    >
      <QItem key={'sq'}>单选题</QItem>
      <QItem key={'mq'}>多选题</QItem>
    </Menu>
  );

  const addQuestion = (key: any, choice: any, flag: any) => {
    switch (flag) {
      case 'SingleAnswer':
        setChoice(
          choice.map((value: any, index: any) => {
            if (key === value.choice) {
              return {
                choice: value.choice,
                defaultQ: '',
                defaultRA: '',
                rightAnswer: value.rightAnswer,
                answer: value.answer.concat({
                  answer: `${flag}${index}${value.answer.length + 1}`,
                  tf: `stf${index}${value.answer.length}${1}`,
                  defaultA: ''
                })
              };
            } else {
              return value;
            }
          })
        );
        break;
      case 'MultipleAnswer':
        setChoices(
          choices.map((value: any, index: any) => {
            if (key === value.choice) {
              return {
                choice: value.choice,
                defaultQ: '',
                defaultRA: [],
                rightAnswer: value.rightAnswer,
                answer: value.answer.concat({
                  answer: `${flag}${index}${value.answer.length + 1}`,
                  tf: `mtf${index}${value.answer.length}${1}`,
                  defaultA: []
                })
              };
            } else {
              return value;
            }
          })
        );
        break;
      default:
    }
  };

  const preViewChoice = () => {
    if (!pre) {
      return null;
    }
    return (
      <div style={{ display: pre ? 'block' : 'none' }}>
        {choice.map((value: any, index: any) => {
          if (value === null) {
            return null;
          }
          return (
            <div
              key={'preViewChoice+choice:' + index}
              className='edu-CreateCard-TestCard-9200'
            >
              <div>{preData[value.choice]}</div>
              <RadioGroup
                value={preData}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  height: value.answer.length * 40
                }}
              >
                {value.answer.map((subValue: any, subIndex: any) => {
                  let aw = '';
                  try {
                    aw = preData[value.answer[subIndex].answer].aw;
                  } catch (error) {
                    aw = '';
                  }
                  return (
                    <Radio
                      key={'preViewChoice+choice+subIndex:' + subIndex}
                      value={aw}
                    >
                      {aw}
                    </Radio>
                  );
                })}
              </RadioGroup>
              <div className='edu-CreateCard-TestCard-9850' />
            </div>
          );
        })}
        {choices.map((value: any, index: any) => {
          if (value === null) {
            return null;
          }
          return (
            <div className='edu-CreateCard-TestCard-10106'>
              <div>{preData[value.choice]}</div>
              <Checkbox.Group
                options={value.answer.map((subValue: any, subIndex: any) => {
                  let aw = preData[value.answer[subIndex].answer].aw;
                  return { value: aw, label: aw };
                })}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  height: value.answer.length * 40
                }}
              />
              <div className='edu-CreateCard-TestCard-10701' />
            </div>
          );
        })}
      </div>
    );
  };

  const renderChoice = (choice: any, flag: any) => {
    const choiceForm = {
      wrapperCol: { span: 22, offset: 0 },
      labelCol: { span: 2 }
    };
    const questionForm = {
      wrapperCol: { span: 22, offset: 2 },
      labelCol: { span: 2 }
    };
    return (
      <div>
        {flag === 'SingleAnswer'
          ? choice.map((item: any, index: any) => {
              if (item === null) {
                return null;
              }
              let defaultIndex = 0;
              try {
                item.answer.forEach((rValue: any, rIndex: any) => {
                  if (rValue.defaultA === item.defaultRA) {
                    defaultIndex = rIndex;
                  }
                });
              } catch (error) {
                defaultIndex = 0;
              }

              return (
                <div
                  key={'choice:' + index}
                  style={{
                    width: '100%',
                    marginTop: 40,
                    display: pre ? 'none' : 'block'
                  }}
                >
                  <div className='edu-CreateCard-TestCard-11817'>
                    <div className='edu-CreateCard-TestCard-11904'>单选题</div>
                    <img
                      src={deleteImg}
                      data-rjs='2'
                      className='edu-CreateCard-TestCard-12053'
                      onClick={() => {
                        let choiceTemp = choice.map((item: any) => {
                          return item;
                        });
                        choiceTemp.splice(index, 1);
                        setChoice(
                          choiceTemp
                        );
                      }}
                    />
                  </div>
                  <div className='edu-CreateCard-TestCard-12355' />
                  <Form.Item
                    {...choiceForm}
                    label='题目'
                    hasFeedback
                    colon={false}
                    className='edu-CreateCard-TestCard-12538'
                  >
                    {getFieldDecorator(item.choice, {
                      initialValue: item.defaultQ,
                      rules: [{ required: true, message: '请输入题目' }]
                    })(<Input className='edu-CreateCard-TestCard-12727' />)}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator(item.rightAnswer, {
                      initialValue: item.answer[defaultIndex].answer
                    })(
                      <RadioGroup
                        className='edu-CreateCard-TestCard-13012'
                        onChange={e => {
                          setSrAnswer(srAnswer.concat(e.target.value));
                        }}
                      >
                        <List
                          className='edu-CreateCard-TestCard-13276'
                          dataSource={item.answer}
                          renderItem={(item1: any) => (
                            <Form.Item
                              {...questionForm}
                              className='edu-CreateCard-TestCard-13474'
                            >
                              {getFieldDecorator(item1.answer, {
                                initialValue: { aw: item1.defaultA, tf: true },
                                rules: [
                                  {
                                    validator: checkAnswerItem,
                                    message: '请输入答案'
                                  }
                                ]
                              })(
                                <RadioLayout
                                  onDelete={() => {
                                    if (item.answer.length === 1) {
                                      return;
                                    }
                                    setChoice(
                                      choice.map((item: any) => {
                                        const copyItem = item;
                                        copyItem.answer = item.answer.filter(
                                          (subItem: any) => {
                                            return (
                                              subItem.answer !== item1.answer
                                            );
                                          }
                                        );
                                        return item;
                                      })
                                    );
                                  }}
                                  radioKey={item1.answer}
                                />
                              )}
                            </Form.Item>
                          )}
                        />
                      </RadioGroup>
                    )}
                  </Form.Item>
                  <Form.Item
                    className='edu-CreateCard-TestCard-14939'
                    {...{
                      wrapperCol: { span: 22, offset: 2 },
                      labelCol: { span: 0 }
                    }}
                    label=''
                    hasFeedback
                  >
                    {getFieldDecorator('new')(
                      <WhiteButton
                        className='edu-CreateCard-TestCard-15133'
                        type='button'
                        onClick={() => {
                          addQuestion(item.choice, choice, flag);
                        }}
                      >
                        <img
                          src={create}
                          data-rjs='2'
                          className='edu-CreateCard-TestCard-15513'
                        />
                        新选项
                      </WhiteButton>
                    )}
                  </Form.Item>
                </div>
              );
            })
          : choices.map((item: any, index: any) => {
              if (item === null) {
                return null;
              }
              return (
                <div
                  key={'choices:' + index}
                  style={{
                    width: '100%',
                    marginTop: 40,
                    display: pre ? 'none' : 'block'
                  }}
                >
                  <div className='edu-CreateCard-TestCard-15920'>
                    <div className='edu-CreateCard-TestCard-16007'>多选题</div>
                    <img
                      src={deleteImg}
                      data-rjs='2'
                      className='edu-CreateCard-TestCard-16156'
                      onClick={() => {
                        let choicesTemp = choices.map((item: any) => {
                          return item;
                        });
                        choicesTemp.splice(index, 1);
                        setChoices(
                          choicesTemp
                        );
                      }}
                    />
                  </div>
                  <div className='edu-CreateCard-TestCard-16462' />
                  <Form.Item
                    {...choiceForm}
                    label='题目'
                    hasFeedback
                    colon={false}
                  >
                    {getFieldDecorator(item.choice, {
                      initialValue: item.defaultQ,
                      rules: [{ required: true, message: '请输入题目' }]
                    })(<Input className='edu-CreateCard-TestCard-16811' />)}
                  </Form.Item>
                  <List
                    className='edu-CreateCard-TestCard-16942'
                    dataSource={item.answer}
                    renderItem={(item1: any) => (
                      <Form.Item {...questionForm}>
                        {getFieldDecorator(item1.answer, {
                          initialValue: {
                            aw: item1.defaultA,
                            tf: item.defaultRA.indexOf(item1.defaultA) !== -1
                          },
                          rules: []
                        })(
                          <CheckboxLayout
                            onDelete={() => {
                              if (item.answer.length === 1) {
                                return;
                              }
                              setChoices(
                                choices.map((item: any) => {
                                  const copyItem = item;
                                  copyItem.answer = item.answer.filter(
                                    (subItem: any) => {
                                      return subItem.answer !== item1.answer;
                                    }
                                  );
                                  return item;
                                })
                              );
                            }}
                            checkboxKey={item1.answer}
                          />
                        )}
                      </Form.Item>
                    )}
                  />
                  <Form.Item
                    {...{
                      wrapperCol: { span: 22, offset: 2 },
                      labelCol: { span: 0 }
                    }}
                    label=''
                    hasFeedback
                  >
                    {getFieldDecorator('new')(
                      <WhiteButton
                        className='edu-CreateCard-TestCard-18518'
                        type='button'
                        onClick={() => {
                          addQuestion(item.choice, choice, flag);
                        }}
                      >
                        <img
                          src={create}
                          data-rjs='2'
                          className='edu-CreateCard-TestCard-18898'
                        />
                        新选项
                      </WhiteButton>
                    )}
                  </Form.Item>
                </div>
              );
            })}
      </div>
    );
  };

  let back = '';
  let title = '';
  let sq: any = [];
  let mq: any = [];

  if (props.cardInfo) {
    let cardInfo = props.cardInfo;
    let editCardData = getExamCardContent(cardInfo.node.sourceContent);

    title = editCardData.title;
    back = editCardData.back;

    try {
      sq = editCardData.front.exam
        .filter((item: any) => {
          return item.is_single === 'true';
        })
        .map((value: any, index: any) => {
          return {
            choice: `SingleQuestion${index}`,
            defaultQ: value.sq[0],
            rightAnswer: `sr${index}`,
            defaultRA: value.aswt[0],
            answer: value.asw.map((subitem: any, subindex: any) => {
              return {
                answer: `SingleAnswer${index}${subindex}`,
                defaultA: subitem,
                tf: `stf${index}${subindex}`
              };
            })
          };
        });

      mq = editCardData.front.exam
        .filter((item: any) => {
          return item.is_single === 'false';
        })
        .map((value: any, index: any) => {
          return {
            choice: `MultipleQuestion${index}`,
            defaultQ: value.mq[0],
            rightAnswer: `mr${index}`,
            defaultRA: value.aswt,
            answer: value.asw.map((subitem: any, subindex: any) => {
              return {
                answer: `MultipleAnswer${index}${subindex}`,
                defaultA: subitem,
                tf: `stf${index}${subindex}`
              };
            })
          };
        });
    } catch (error) {
      false && console.log('omc');
    }
  }

  React.useEffect(() => {
    setBackContent(back);
    setChoice(sq);
    setChoices(mq);
    setIsInit(true);
  }, []);

  return (
    <Layout className='edu-CreateCard-TestCard-19652'>
      <div className='edu-CreateCard-TestCard-19737'>
        <div className='edu-CreateCard-TestCard-19848'>
          <div className='edu-CreateCard-TestCard-19947'>
            <div
              onClick={() => {
                props.props.history.goBack();
              }}
              className='edu-CreateCard-TestCard-20089'
            >
              <img src={backImg} data-rjs='2' />
              <span className='edu-CreateCard-TestCard-20211'>返回</span>
              <div className='edu-CreateCard-TestCard-20284' />
            </div>
            <Breadcrumb
              separator={'›'}
              itemRender={ItemRender}
              routes={routes()}
              className='edu-CreateCard-TestCard-20470'
            />
          </div>
          <div className='edu-CreateCard-TestCard-20532'>
            {isEditCard ? (
              <img
                data-rjs='2'
                src={del}
                className='edu-CreateCard-TestCard-20685'
                onClick={() => {
                  Modal.confirm({
                    title: '操作提醒',
                    content: '确定要删除文件吗?',
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () => {
                      let id = Base64.decode(props.cardInfo.node.id).split(
                        'Card:'
                      )[1];
                      let packageId = Base64.decode(
                        selectedFilePackageId
                      ).split('Package:')[1];
                      client.writeData({ data: { modal3Loading: true } });
                      deleteTextCard({
                        variables: {
                          input: { ids: [id], packageId: packageId }
                        }
                      }).then((res: any) => {
                        client.writeData({ data: { modal3Loading: false } });
                        if (res.data.deleteCards.errors) {
                          if (
                            res.data.deleteCards.errors[0].message.indexOf(
                              'can not delete or move these cards:'
                            ) !== -1
                          ) {
                            message.error('不能删除其他分支共有文件!');
                            return;
                          }
                          message.error('删除失败!');
                          return;
                        }
                        message.success('删除成功!');
                        props.props.history.goBack();
                      });
                    }
                  });
                }}
              />
            ) : null}
            <RedButton width={'100px'} height={'40px'} onClick={handleSave}>
              保存卡片
            </RedButton>
          </div>
        </div>
        <div className='edu-CreateCard-TestCard-21542'>卡片内容</div>
        <div className='edu-CreateCard-TestCard-21628' />

        <Layout.Content className='edu-CreateCard-TestCard-21735'>
          <Form>
            <Form.Item
              {...{
                wrapperCol: { span: 21, offset: 0 },
                labelCol: { span: 2 }
              }}
              label='标题'
              hasFeedback
            >
              {getFieldDecorator('title', {
                initialValue: title,
                rules: [{ required: true, message: '请输入卡片名称' }]
              })(
                <Input
                  maxLength={36}
                  className='edu-CreateCard-TestCard-22011'
                />
              )}
            </Form.Item>
            <Form.Item
              className='edu-CreateCard-TestCard-22099'
              {...{
                wrapperCol: { span: 21, offset: 0 },
                labelCol: { span: 2 }
              }}
              label='正面'
              hasFeedback
            >
              <div className='edu-CreateCard-TestCard-22236'>
                <div className='edu-CreateCard-TestCard-22359'>
                  <div className='edu-CreateCard-TestCard-22499'>
                    <div
                      onClick={() => {
                        setPre(false);
                      }}
                      style={{
                        borderBottom: pre ? 0 : '2px solid #177E89',
                        cursor: 'pointer'
                      }}
                    >
                      编辑
                    </div>
                    <div
                      onClick={onPre}
                      style={{
                        marginLeft: 20,
                        borderBottom: pre ? '2px solid #177E89' : 0,
                        cursor: 'pointer'
                      }}
                    >
                      预览
                    </div>
                  </div>
                  {pre ? null : (
                    <div className='edu-CreateCard-TestCard-23079'>
                      <Dropdown overlay={topic}>
                        <Button className='edu-CreateCard-TestCard-23215'>
                          <img src={create} data-rjs='2' />
                          <div className='edu-CreateCard-TestCard-23357'>
                            题目
                          </div>
                        </Button>
                      </Dropdown>
                    </div>
                  )}
                </div>
                <Layout.Content className='edu-CreateCard-TestCard-23563'>
                  {renderChoice(choice, 'SingleAnswer')}
                  {renderChoice(choices, 'MultipleAnswer')}
                  <div className='edu-CreateCard-TestCard-23817'>
                    {preViewChoice()}
                  </div>
                </Layout.Content>
              </div>
            </Form.Item>

            <Form.Item
              className='edu-CreateCard-TestCard-24062'
              {...{
                wrapperCol: { span: 21, offset: 0 },
                labelCol: { span: 2 }
              }}
              label='背面'
            >
              {getFieldDecorator('backContent', {
                initialValue: { content: { markdown: back } },
                rules: []
              })(
                <MDEditor_v2
                  isBottom={false}
                  butTitle={''}
                  loading={false}
                  onSubmit={() => {}}
                  height={300}
                  width={891}
                />
              )}
            </Form.Item>
          </Form>
        </Layout.Content>
      </div>
    </Layout>
  );
};

const CourseEditPageWrapper = Form.create<Props>()(CreateExamCard);

export default CourseEditPageWrapper;
