import * as React from 'react';

//引入的库组件
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';

//引入的其他页面组件
import { FileLine } from './FileLine';

//graphql查询
import gql from 'graphql-tag';

//引入的资源图片

const GET_FILE_CARD_LINE_INFO = gql`
  {
    checkAll @client {
      cancel
      all
      on
    }
  }
`;

const FileCardLine = (props: any) => {
  const client = useApolloClient();
  const { data: fileCardLineInfoData } = useQuery(GET_FILE_CARD_LINE_INFO);
  const { cover, title, cardKey, onSelect, disabled, createTime, uploadTime } = props;

  const [hover, setHover] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  if(!disabled) {
    if(fileCardLineInfoData.checkAll.all) {
      !checked && setChecked(true);
    }else if(fileCardLineInfoData.checkAll.cancel) {
      checked && setChecked(false);
    }
  }

  return (
    <FileLine
      onMouseLeave={() => {
        if (!checked) {
          setHover(false);
        }
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      style={{ backgroundColor: hover ? 'rgba(56,56,56,0.05)' : '' }}
    >
      <FileLine.Checkbox
        checked={checked}
        disabled={disabled}
        key={cardKey}
        value={cardKey}
        onChange={(e: any) => {
          if (e.target.checked) {
            client.writeData({data: {checkAll: { cancel: false, all: false, on: true, __typename: "CheckAll" }}});
            onSelect({ action: e.target.checked, cardKey });
            setHover(true);
            setChecked(true);
          } else {
            onSelect({ action: e.target.checked, cardKey });
            client.writeData({data: {checkAll: { cancel: false, all: false, on: true, __typename: "CheckAll" }}});
            setHover(false);
            setChecked(false);
          }
        }}
      />
      <FileLine.Cover src={cover} data-rjs='2' />
      <FileLine.Title>{title}</FileLine.Title>
      <FileLine.Time>{createTime}</FileLine.Time>
      <FileLine.Time>{uploadTime}</FileLine.Time>
    </FileLine>
  );
};

export default FileCardLine;
