import gql from 'graphql-tag';

export const writeQuery = gql`
  {
    courses @client {
      collegeName
      courseName
    }
  }
`;
