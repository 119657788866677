/**
 * @description 我的回答
 * @author 阿礼
 * @since 2019-9-14
 */
import React, { useState, useEffect, useRef } from 'react';
import './style.less';
import { Base64 } from 'js-base64';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import {
  Avatar,
  Icon,
  Form,
  message,
  Upload,
  Button,
  Dropdown,
  Menu
} from 'antd';

import copy from 'copy-to-clipboard';

import { checkMDContent } from '../../../../utils/checkForm';
import MDEditor_v3 from '../../../CommonComponents/MDEditor/MDEditor_v3';
import MyReactMarkdown from '../../../CommonComponents/MyReactMarkdown';
import {
  usePostAssignmentMutation,
  useUpdateAssignmentMutation,
  useCollectCardMutation,
  useUnCollectMutation,
  useGetAnswerCommitsQuery
} from '../../../../generated/graphql.tsx';

import editIcon from '../../../../assets/v2.1/edit.png';
import favorite from '../../../../assets/icons/favorite.png';
import favorite_active from '../../../../assets/icons/favorite_active.png';
import uploadImg from '../../../../assets/icons/import.png';

const GET_ANSWER = gql`
  {
    nickname @client
    avatar @client
    postTime @client
    questionPid @client
    coursePackageAssignmentId @client
    assignmentList @client
    allAssignmentList @client
    isIveDone @client
    assignmentId @client
    undoneAssignmentList @client
    doneAssignmentList @client
    answerId @client(always: true)
    isCollected @client
    answerContent @client
    courseId @client
    homeworkCommits @client {
      commitHash
      commitedAt
    }
  }
`;

const MyAnswer = props => {
  const client = new useApolloClient();
  const {
    data: {
      nickname,
      avatar,
      postTime,
      questionPid,
      coursePackageAssignmentId,
      isIveDone,
      assignmentId,
      assignmentList,
      allAssignmentList,
      undoneAssignmentList,
      doneAssignmentList,
      answerId,
      isCollected,
      answerContent,
      courseId,
      homeworkCommits
    },
    loading
  } = useQuery(GET_ANSWER);

  const [isEditor, setEditor] = useState(props.isUpload);

  const [value, setValue] = useState(props.initialValue);
  const [postAssignment] = usePostAssignmentMutation({ skip: true });
  const [updateComment] = useUpdateAssignmentMutation({
    skip: true
  });
  const { refetch: getCommits } = useGetAnswerCommitsQuery({ skip: true });
  // 收藏
  const [collect] = useCollectCardMutation({ skip: true });
  // 未收藏
  const [unCollect] = useUnCollectMutation({ skip: true });
  useEffect(() => {
    setEditor(false);
    setValue({ content: { markdown: '' } });
  }, [assignmentId]);

  useEffect(() => {
    if (props.isUpload) {
      props.setIsUpload(false);
      setEditor(true);
    }
  }, []);

  if (loading) {
    return null;
  }

  // 如果作业做完，展示内容，如果没做，markdown展示
  function beforeUpload(file) {
    // const { dispatch } = this.props;
    const suffix = file.name.substring(
      file.name.lastIndexOf('.') + 1,
      file.name.length
    );

    const isMD = suffix === 'md';
    if (isMD) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function() {
        props.setinitialValue({ content: { markdown: reader.result } });
        props.setIsUpload(true);
        props.setUpdate(false);
        props.setUpdate(true);
        message.success('导入文件成功');
      };
    } else {
      message.error('仅支持导入markdown文件');
    }
    return false;
  }

  const renderCommitsList = () => {
    let temp = [];
    homeworkCommits.map((item, index) => {
      temp.push(item);
      if (index !== homeworkCommits.length - 1) {
        temp.push({ commitedAt: 'divider' });
      }
    });
    return (
      <Menu className='commit-list-main'>
        {temp.map((item, index) => {
          if (item.commitedAt === 'divider') {
            return (
              <Menu.Divider
                key={'divider:' + index}
                style={{ width: '100%' }}
              />
            );
          } else {
            return (
              <Menu.Item
                key={'commit:' + index}
                onClick={() => {
                  const shortUrl = window.sessionStorage.getItem('shortUrl');
                  props.history.push(
                    `/stu/${shortUrl}/diffhomework/${
                      Base64.decode(answerId).split('Assignment:')[1]
                    }:${item.commitHash}`
                  );
                }}
              >
                <div>提交时间:{item.commitedAt}</div>
                <div>Commit:{item.commitHash}</div>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );
  };

  return (
    <div className='assignment-my-answer'>
      <div className='assignment-my-answer-header'>
        <div className='assignment-my-answer-tip'>我的回答</div>
        {isIveDone ? (
          isEditor && (
            <div className='new-routes-study-courseHomework-2228'>
              <Upload
                action='temp/'
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                <div className='new-routes-study-courseHomework-2242'>
                  <img
                    className='new-routes-study-courseHomework-2257'
                    src={uploadImg}
                  />
                  <div className='new-routes-study-courseHomework-2266'>
                    导入Markdown文件
                  </div>
                </div>
              </Upload>
            </div>
          )
        ) : (
          <div className='new-routes-study-courseHomework-2228'>
            <Upload
              action='temp/'
              showUploadList={false}
              beforeUpload={beforeUpload}
            >
              <div className='new-routes-study-courseHomework-2242'>
                <img
                  className='new-routes-study-courseHomework-2257'
                  src={uploadImg}
                />
                <div className='new-routes-study-courseHomework-2266'>
                  导入Markdown文件
                </div>
              </div>
            </Upload>
          </div>
        )}
      </div>
      {isEditor && <div className='new-routes-study-courseHomework-2331'></div>}
      {isIveDone ? (
        isEditor ? (
          <>
            <MDEditor_v3
              extraButton={() => {
                return isEditor ? (
                  <div
                    onClick={() => {
                      setEditor(!isEditor);
                    }}
                  >
                    取消编辑
                  </div>
                ) : null;
              }}
              contentValue={value}
              isBottom
              width='100%'
              height={340}
              onSubmit={() => {
                //拿值发送请求
                try {
                  if (value.content.markdown) {
                    const idAnswer = window
                      .atob(answerId)
                      .split('Assignment:')[1];
                    const coursePackageId = window
                      .atob(coursePackageAssignmentId)
                      .split('CoursePackage:')[1];
                    updateComment({
                      variables: {
                        assignmentInput: {
                          content: value.content.markdown,
                          id: idAnswer,
                          coursePackageId: coursePackageId
                        }
                      }
                    }).then(res => {
                      const data = res.data.updateAssignment.assignment;

                      client.writeQuery({
                        query: GET_ANSWER,
                        data: {
                          answerContent: data.content
                        }
                      });
                      getCommits({ id: answerId }).then(res => {
                        if (res.data.node.commits) {
                          client.writeData({
                            data: { homeworkCommits: res.data.node.commits }
                          });
                        } else {
                          client.writeData({ data: { homeworkCommits: [] } });
                        }
                      });
                      setEditor(!isEditor);
                    });
                  }
                } catch (err) {
                  console.log(err);
                }
              }}
              onChange={content => {
                setValue(content);
              }}
              butTitle={'提交作业'}
              minHeight={'240px'}
              pb={48}
            />
          </>
        ) : (
          <div className='assignment-answer-container '>
            <div className='assignment-answer-meta'>
              <div className='avatar-time'>
                <Avatar src={avatar} />
                <div className='nickname-posttime'>
                  <span className='time'> {nickname}</span>
                  <span className='time'>{postTime}</span>
                </div>
              </div>
              <div className='function-icon'>
                <Dropdown
                  trigger={['hover']}
                  placement={'bottomCenter'}
                  overlay={renderCommitsList()}
                >
                  <div className='commit'>
                    <Icon type='down' style={{ marginRight: 5 }} />
                    <div>查看历史提交</div>
                  </div>
                </Dropdown>
                <img
                  src={editIcon}
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                  onClick={() => {
                    setEditor(true);
                    setValue({ content: { markdown: answerContent } });
                  }}
                />
                <Icon
                  type='share-alt'
                  style={{
                    marginLeft: '10px',
                    color: 'rgb(173, 173, 173)',
                    fontSize: '19px'
                  }}
                  onClick={() => {
                    // 分享链接

                    const ans_key = window
                      .atob(answerId)
                      .split('Assignment:')[1];
                    const homework_key = window
                      .atob(assignmentId)
                      .split('Assignment:')[1];

                    const href = window.location.href.split('/');

                    copy(
                      `${href[0]}/${href[1]}/${href[2]}/${href[3]}/${
                        href[4]
                      }/homework/${homework_key}/${ans_key}`
                    );
                    message.success('作业链接已复制');
                  }}
                />
                <img
                  src={isCollected ? favorite_active : favorite}
                  className='collect'
                  onClick={() => {
                    // 查询接口 传入参数

                    const cardId = window
                      .atob(answerId)
                      .split('Assignment:')[1];
                    const coursePackageId = window
                      .atob(coursePackageAssignmentId)
                      .split('CoursePackage:')[1];

                    const wholeCourseId = Number(
                      window.atob(courseId).split('Course:')[1]
                    );
                    if (!isCollected) {
                      collect({
                        variables: {
                          // courseId Q291cnNlOjMw
                          input: { cardId, courseId: wholeCourseId }
                        }
                      }).then(res => {
                        if (res.data.collectCard.errors === null) {
                          // console.log('收藏成功');
                          // 更改isCollected的值
                          client.writeQuery({
                            query: GET_ANSWER,
                            data: {
                              isCollected: true
                            }
                          });
                        }
                      });
                    } else {
                      unCollect({
                        variables: {
                          input: { cardId, courseId: wholeCourseId }
                        }
                      }).then(res => {
                        // console.log('取消收藏');
                        client.writeQuery({
                          query: GET_ANSWER,
                          data: {
                            isCollected: false
                          }
                        });
                      });
                    }
                  }}
                />
              </div>
            </div>{' '}
            <MyReactMarkdown
              source={answerContent}
              className='new-routes-study-courseHomework-2709'
            />
          </div>
        )
      ) : (
        <>
          <div className='new-routes-study-courseHomework-2331'></div>
          <MDEditor_v3
            contentValue={value}
            isBottom
            width='100%'
            height={340}
            onSubmit={() => {
              //拿值发送请求
              if (value.content.markdown) {
                // 这里变量都从状态管理获取

                const coursePackageId = window
                  .atob(coursePackageAssignmentId)
                  .split('CoursePackage:')[1];

                postAssignment({
                  variables: {
                    assignmentInput: {
                      coursePackageId: coursePackageId,
                      pid: questionPid,
                      content: value.content.markdown
                    }
                  }
                }).then(res => {
                  // 错误机制

                  if (res.data.createAssignment.errors) {
                    // message.info('');
                    return;
                  }
                  // 如果有数据
                  if (res.data) {
                    const data = res.data.createAssignment.assignment;

                    // 匹配目录更改isIveDone的状态
                    const list = allAssignmentList.map(item => {
                      if (item.id === assignmentId) {
                        item.isIveDone = true;
                      }
                      return item;
                    });
                    const undonelist = list.filter(
                      item => item.isIveDone === false
                    );

                    const donelist = list.filter(
                      item => item.isIveDone === true
                    );

                    client.writeQuery({
                      query: GET_ANSWER,
                      data: {
                        answerContent: data.content,
                        allAssignmentList: list,
                        undoneAssignmentList: undonelist,
                        doneAssignmentList: donelist,
                        assignmentList: list,
                        avatar: data.author.avatar,
                        nickname: data.author.nickname,
                        postTime: data.insertedAt,
                        isIveDone: true,
                        answerId: data.id
                      }
                    });
                    getCommits({ id: data.id }).then(res => {
                      if (res.data.node.commits) {
                        client.writeData({
                          data: { homeworkCommits: res.data.node.commits }
                        });
                      } else {
                        client.writeData({ data: { homeworkCommits: [] } });
                      }
                    });
                  }
                });
              }
            }}
            onChange={content => {
              setValue(content);
            }}
            butTitle={'提交作业'}
            minHeight={'240px'}
            pb={48}
          />
        </>
      )}
    </div>
  );
};

export default withRouter(MyAnswer);
