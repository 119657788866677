import styled from 'styled-components';

// example of a simple helper that sets defaultProps and update the type
type WithDefaultProps<C, D> = C & { defaultProps: D };

function withDefaultProps<C, D>(component: C, defaultProps: D): WithDefaultProps<C, D> {
    (component as WithDefaultProps<C, D>).defaultProps = defaultProps;
    return component as WithDefaultProps<C, D>;
}

interface CustomButtonProps {
  width: string;
  height: string;
  border: string;
  color: string;
  backgroundColor: string;
  loading: boolean ;
}

const defaultProps: CustomButtonProps = {
    width: "",
    height: "",
    border: "",
    color: "",
    backgroundColor: "",
    loading: false,
}


const CustomButtonWithoutDefault = styled.button<CustomButtonProps>`
  width: ${(props: CustomButtonProps) => props.width};
  height: ${(props: CustomButtonProps) => props.height};
  color: ${(props: CustomButtonProps) => props.color};
  background-color: ${(props: CustomButtonProps) => props.backgroundColor};
  border: ${(props: CustomButtonProps) => props.border};
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  line-height: 20px;
`;

const WhiteButtonWithoutDefault = styled.button<CustomButtonProps>`
  width: ${(props: CustomButtonProps) => props.width};
  height: ${(props: CustomButtonProps) => props.height};
  font-size: 14px;
  color: #282a2d;
  background-color: #fff;
  border: 1px solid #93979f;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  line-height: 20px;
`;

const RedButtonWithoutDefault = styled.button<CustomButtonProps>`
  width: ${(props: CustomButtonProps) => props.width};
  height: ${(props: CustomButtonProps) => props.height};
  color: #fff;
  background-color: #d4323c;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  line-height: 20px;
`;

const GreenButtonWithoutDefault = styled.button<CustomButtonProps>`
  width: ${(props: CustomButtonProps) => props.width};
  height: ${(props: CustomButtonProps) => props.height};
  color: #fff;
  background-color: #177e89;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  line-height: 20px;
`;


const  WhiteButton = withDefaultProps(WhiteButtonWithoutDefault, defaultProps);
const  CustomButton = withDefaultProps(CustomButtonWithoutDefault, defaultProps);
const  RedButton = withDefaultProps(RedButtonWithoutDefault, defaultProps);
const  GreenButton = withDefaultProps(GreenButtonWithoutDefault, defaultProps);

export { CustomButton, RedButton, WhiteButton, GreenButton };
