import * as React from "react";
import "./styles.css";

//引入的库组件
import { Modal, Form, Button, Input, message } from "antd";
import { useApolloClient } from "react-apollo";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import timeLocalStorage from "../../utils/TimeLocalStorage";
import { ColProps } from "antd/lib/col";

//graphql查询
import { useGetAuthCodeMutation, useRegisterUserMutation } from "../../generated/graphql";

//引入的其他页面组件
import { CountDownText } from '../JSComponents/countDown';

//引入的资源图片
import phone from "../../assets/icons/phone.png";
import pw from "../../assets/icons/password_grey.png";
import user from '../../assets/icons/user.png';

const GET_INFOS_FOR_USER = gql`
  {
    userInfo @client {
      head_img
    }
    isShowLoginModal @client
    isShowRegisterModal @client
    buttonLoading @client
    showButton @client
  }
`;

const contentLayout: React.CSSProperties = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column"
};

const Register = (props: any) => {
  const client = useApolloClient();
  const { data } = useQuery(GET_INFOS_FOR_USER);
  const { isShowLoginModal, isShowRegisterModal, buttonLoading, showButton } = data;

  const { getFieldDecorator, validateFieldsAndScroll } = props.form;

  const [getAuthCodeMutation] = useGetAuthCodeMutation();
  const [registerUser] = useRegisterUserMutation();

  const onCancel = () => {
    client.writeData({
      data: { showButton: true, isShowRegisterModal: false }
    });
    props.form.resetFields();
  };

  const register = () => {
    const { form } = props;
    form.validateFieldsAndScroll((errors: any, values: any) => {
      if (errors) {
        message.error("信息填写有误!");
      }else {
        let avatars = [
          "https://static.openmindclub.com/image/default-avatar/avatar3.png",
          "https://static.openmindclub.com/image/default-avatar/avatar2.png",
          "https://static.openmindclub.com/image/default-avatar/avatar1.png"
        ];
        let input: any = {
          authCode: values.auth_code,
          avatar: avatars[Math.floor(Math.random() * 3)],
          nickname: values.username,
          password: values.password,
          phone: values.phone,
        }

        registerUser({variables: {input: input}}).then((res: any) => {
          if(res.data.registerUser.errors) {
            let messageStr = "已被注册";
            if(res.data.registerUser.errors[0].key === "phone") {
              messageStr = "该手机号" + messageStr + ",请重新登录";
            }else if(res.data.registerUser.errors[0].key === "nickname") {
              messageStr = "该昵称" + messageStr + ",请重新登录";
            }
            message.error(messageStr);
          }else {
            message.success("注册成功，请登录!");
            form.resetFields();
            client.writeData({
              data: { showButton: true, isShowRegisterModal: false }
            });
          }
        }).catch((res:any)=> {
          message.error("注册失败!");
        });
      }
    });
    // const { dispatch, form } = this.props;
    // form.validateFieldsAndScroll((errors, values) => {
    //   if (errors) {
    //     false && console.log('tag');
    //   } else {
    //     dispatch({ type: 'login/register',
    //       payload: values,
    //       callback: () => {
    //         form.resetFields();
    //       } });
    //   }
    // });
  };

  return (
    <Modal
      keyboard={false}
      maskClosable={false}
      title="账号注册"
      visible={isShowRegisterModal}
      // onOk={register}
      onCancel={onCancel}
      width={380}
      footer={null}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <div style={contentLayout}>
        <Form className="login-register-register-2108">
          <Form.Item hasFeedback wrapperCol={{ fontSize: 18 } as ColProps}>
            {getFieldDecorator("phone", {
              rules: [
                { required: true, message: "请输入手机号" },
                {
                  pattern: "^[1][3,4,5,7,8,9,6][0-9]{9}$",
                  message: "手机号码格式错误"
                }
              ]
            })(
              <Input
                size={"large"}
                prefix={<img src={phone} />}
                className="login-register-register-2534"
                placeholder="手机号"
              />
            )}
          </Form.Item>
          <div className="login-register-register-2718">
            <Form.Item hasFeedback>
              {getFieldDecorator("auth_code", {
                rules: [{ required: true, message: "验证码为空" }]
              })(
                <Input
                  className="login-register-register-2957"
                  maxLength={6}
                  placeholder="验证码"
                />
              )}
            </Form.Item>
            <Form.Item>
              {showButton ? (
                <Button
                  className="login-register-register-3230"
                  onClick={() => {
                    validateFieldsAndScroll(["phone"], (errors: any, values: { phone: any; }) => {
                      if (errors) {
                        false && console.log("tag");
                      } else {
                        getAuthCodeMutation({variables: {input: {phone: values.phone}}}).then( res => {
                          message.success("验证码已发送");
                          client.writeData({
                            data: { showButton: false }
                          });
                        } ).catch( res => {
                          message.error("请求发送失败");
                        } );
                      }
                    });
                  }}
                >
                  获取验证码
                </Button>
              ) : (
                <CountDownText
                  className="login-register-register-3785"
                  countType={"seconds"}
                  auto
                  afterEnd={() => {
                    client.writeData({
                      data: { showButton: true }
                    });
                  }}
                  timeLeft={60}
                  step={-1}
                  startText={"获取验证码"}
                  endText={"获取验证码"}
                  intervalText={(sec: any) => `${sec}秒重新获取`}
                />
              )}
            </Form.Item>
          </div>
          <Form.Item hasFeedback className="login-register-register-4566">
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "请输入6-16位密码" },
                { min: 6, message: "密码小于6位" }
              ]
            })(
              <Input
                size={"large"}
                className="login-register-register-4846"
                maxLength={16}
                type="password"
                placeholder="密码"
                prefix={<img src={pw} />}
              />
            )}
          </Form.Item>
          <Form.Item hasFeedback className="login-register-register-5184">
            {getFieldDecorator("username", {
              rules: [{ required: true, message: "请输入昵称" }]
            })(
              <Input
                size={"large"}
                className="login-register-register-5382"
                maxLength={16}
                placeholder="您的昵称"
                prefix={<img src={user} />}
              />
            )}
          </Form.Item>
        </Form>
        <Button
          className="login-register-register-5659"
          onClick={register}
          type="danger"
          loading={buttonLoading}
        >
          注册
        </Button>
      </div>
    </Modal>
  );
};

const RegisterWrapper = Form.create()(Register);

export default RegisterWrapper;
