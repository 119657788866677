const manager = {
  managerItem: 'course',
  modal1Loading: false,
  modal2Loading: false,
  modal3Loading: false,

  modal1: { show: false, title: '', content: '', __typename: 'Modal1' },
  modal2: { show: false, title: '', content: '', __typename: 'Modal2' },
  modal3: { show: false, title: '', content: '', __typename: 'Modal3' },

  createdCoursesAfter: null,
  createdCoursesBefore: null,
  createdCoursesFirst: 200,
  createdCoursesLast: 200,
  createdCoursesPageSize: 20,
  createdCoursesSearchContent: '',

  isEditCourse: false,
  editCourseId: '',

  qiniuToken: '',

  screenful: false,
  hanStatus: { handing: false, tip: '', __typename: 'HanStatus' },
  dragMode: false,
  sort: false,
  pMode: true,

  packageManagerSelectedPackage: '',
  isCheckPackage: false,
  selectedFileId: '',
  selectedFilePackageId: '',
  errorCards: [],
  selectCards: [],
  viewMode: false,
  checkAll: { cancel: false, all: false, on: false, __typename: 'CheckAll' },
  showFiles: [],
  searchFiles: [],
  isShowCard: false,
  expandedKeys: [],
  searchMode: false,
  treeData: [],
  cooperationTreeData: [],
  cardPaging: { page: 0, count: 0, __typename: 'CardPaging' },

  isShowCreateModal: false,
  isCreateFolder: true,

  isEditCard: false,
  card_list: [],
  role: "",

  rightClick: true,

  handlerFileKeys: [],
  isCopy: false,
  isShowCopyModal: false,
  selectCard: "",

  renameName: "",
  isShowRenameModal: false,

  copyUrlModal: false, 
  copyUrl: "",

  showPreviewModal: false,

  packageTitle: "",
  diffChangesStr: "",
  isMerging: false,
  conflictData: [],
  isShowRejectModal: false,
  isMasterMerging: false,
  mergeInfo: {currentPackageId: "", targetPackageId: "", msgId: "", diffChangesStr: "", status: "", head_img: "", user: "", description: "", content: "", __typename: "MergeInfo"}
};

export default manager;
