import React from 'react';
import { Input } from 'antd';
import { FormConfig } from 'components/Form';
import { validatePassword } from 'utils/regexp';

const password: FormConfig[] = [
  {
    label: '原密码',
    value: 'oldPassword',
    rules: [{ required: true, message: '请输入原密码' }],
    component: props => <Input type='password' placeholder='请输入原密码' />
  },
  {
    label: '新密码',
    value: 'newPassword',
    rules: [
      {
        required: true,
        message: '请输入6-15位新密码',
        validator: (rule, value, callback) => {
          if (!validatePassword(value)) {
            callback(rule.message);
          } else {
            callback();
          }
        }
      }
    ],
    component: props => (
      <Input type='password' placeholder='请输入6-15位新密码' />
    )
  },
  {
    label: '确认密码',
    value: 'againNewPassword',
    rules: [
      {
        required: true,
        message: '请再次输入新密码'
      }
    ],
    component: props => <Input type='password' placeholder='请再次输入新密码' />
  }
];

export default password;
