import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Spin, Breadcrumb, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { FormComponentProps } from 'antd/es/form';
import { withRouter } from 'react-router';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import ItemRender from '../CommonComponents/ItemRender';
import DiffView from '../CommonComponents/DiffView';
import { RedButton } from '../CommonComponents/CustomButton';
import Modal2CallbackFunc from '../CommonComponents/Modals/Modal2CallbackFunc';

//graphql查询
import { useSubmitPullRequestMutation } from '../../generated/graphql';

//引入的资源图片
import back from '../../assets/icons/back.png';
import root from '../../assets/icons/file_medium_root.png';

interface Props extends FormComponentProps {
  props: any;
}

const GET_DIFF_CHANGES_INFO = gql`
  {
    diffChangesStr @client
    isMerging @client
    packageTitle @client
    selectedFileId @client
  }
`;

const routes = [
  {
    path: '//edu',
    breadcrumbName: '全部文件'
  },
  {
    path: '',
    breadcrumbName: '我协作的'
  },
  {
    path: '',
    breadcrumbName: '提交变更申请'
  }
];

const EduManagerPackageSubMergePage = (props: any) => {
  const client = useApolloClient();
  const { data: diffChangesInfo } = useQuery(GET_DIFF_CHANGES_INFO);
  const { diffChangesStr, isMerging, packageTitle, selectedFileId } = diffChangesInfo;
  const { form } = props;

  const [submitPullRequestMutation] = useSubmitPullRequestMutation();
  return (
    <Spin spinning={isMerging} size={'large'}>
      <div className='routes-study-SubMerge-1015'>
        <div className='routes-study-SubMerge-1583'>
          <div className='routes-study-SubMerge-1606'>
            <div className='routes-study-SubMerge-1623'>
              <div
                onClick={() => {
                  props.history.push('/edu');
                }}
                className='routes-study-SubMerge-1802'
              >
                <img src={back} />
                <span className='routes-study-SubMerge-1869'>返回</span>
              </div>
              <div className='routes-study-SubMerge-1928' />
              <Breadcrumb
                separator={'›'}
                itemRender={ItemRender}
                routes={routes}
                className='routes-study-SubMerge-2088'
              />
            </div>

            {diffChangesStr === '' || packageTitle === '' ? null : (
              <>
                <div className='routes-study-SubMerge-2150'>
                  <img src={root} />
                  <div className='routes-study-SubMerge-2216'>
                    {packageTitle}
                  </div>
                </div>

                <Form className='routes-study-SubMerge-2334'>
                  <Form.Item hasFeedback>
                    {form.getFieldDecorator('message', {
                      rules: [{ required: true, message: '请输入变更说明' }]
                    })(
                      <TextArea
                        placeholder={'请输入变更说明，不超过200字'}
                        className='routes-study-SubMerge-2556'
                        maxLength={200}
                      />
                    )}
                  </Form.Item>
                </Form>
              </>
            )}
          </div>

          {diffChangesStr === '' || packageTitle === '' ? null : (
            <>
              <div className='routes-study-SubMerge-4582'>
                <DiffView
                  currentCommit={{
                    diff: diffChangesStr.replace(/medium\n/g, '')
                  }}
                  lastCommit={null}
                  showAll={false}
                  showType={'split'}
                />
              </div>

              <div className='routes-study-SubMerge-4133'>
                <RedButton
                  width={'100px'}
                  height={'40px'}
                  onClick={() => {
                    form.validateFieldsAndScroll(
                      { first: true, force: true },
                      (errors: any, values: any) => {
                        if (errors) {
                          return;
                        }

                        Modal2CallbackFunc.callback = () => {
                          let input = {
                            currentPackageId: Base64.decode(selectedFileId).split('Package:')[1],
                            why: values.message
                          };
                          submitPullRequestMutation({variables: {input: input}}).then((res: any) => {
                            if(res.data.submitPr.errors) {
                              if(res.data.submitPr.errors[0] && res.data.submitPr.errors[0].message.indexOf('submit pr again')) {
                                message.error("上次提交申请还未处理，无法再次提交!");
                              }else {
                                message.error("提交变更失败!");
                              }
                              
                            }else {
                              message.success("提交变更成功!");
                              props.history.push('/edu');
                            }

                            client.writeData({
                              data: {
                                modal2Loading: false,
                                modal2: {
                                  show: false,
                                  title: '',
                                  content: '',
                                  __typename: 'Modal2'
                                }
                              }
                            });
                          }).catch((error: any) => {
                            client.writeData({
                              data: {
                                modal2Loading: false,
                                modal2: {
                                  show: false,
                                  title: '',
                                  content: '',
                                  __typename: 'Modal2'
                                }
                              }
                            });
                            message.error("提交变更失败!");
                          });
                        };

                        client.writeData({
                          data: {
                            modal2Loading: false,
                            modal2: {
                              show: true,
                              title: '确定要提交变更吗？',
                              content: '提交变更后，卡包拥有者将收到消息。',
                              __typename: 'Modal2'
                            }
                          }
                        });

                        // dispatch({
                        //   type: 'handler/handModal2',
                        //   modal2: {
                        //     show: true,
                        //     title: '确定要提交变更吗？',
                        //     content: '提交变更后，卡包拥有者将收到消息。',
                        //     event: () => {
                        //       dispatch({
                        //         type: 'course/submitMerge',
                        //         payload: {
                        //           message: values.message,
                        //           key: selectedFileId
                        //         }
                        //       });
                        //     }
                        //   }
                        // });
                      }
                    );
                  }}
                >
                  提交变更
                </RedButton>
              </div>
            </>
          )}
        </div>
      </div>
    </Spin>
  );
};

const EduManagerPackageSubMergePageWrapper = Form.create<Props>()(
  withRouter(EduManagerPackageSubMergePage)
);

export default EduManagerPackageSubMergePageWrapper;
