import React, { Component } from 'react';
import Button from 'antd/es/button/button';
import ReactMde from './react-mde/lib/js';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import 'react-mde/lib/styles/css/react-mde-editor.css';
import 'react-mde/lib/styles/css/react-mde.css';
import styled from 'styled-components';

import { Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';

const CustomReactMde = styled(ReactMde)`
  .public-DraftEditor-content {
    min-height: ${props => props.minHeight}!important;
    outline: #177e89 !important;
    margin: 0;
  }

  div.notranslate.public-DraftEditor-content {
    padding: 15px !important;
  }

  span {
    line-height: 20px;
  }

  div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    margin: 0;
  }

  div.DraftEditor-editorContainer {
    line-height: 20px;
  }

  li > button {
    height: 40px !important;
  }

  .react-mde {
    border-radius: 0px !important;
  }
`;

// export interface MDEditor_v2Props {
//   extraButton?: React.ReactNode;
// }

class MDEditor_v3 extends Component {
  constructor(props) {
    super(props);
    const value = props.value || {};
    this.state = {
      content: value.content || { content: { markdown: '' } }
    };
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
      simpleLineBreaks: true,
      onBlur: true
    });
  }

  componentWillReceiveProps(nextProps) {
    // Should be a controlled component.

    if ('value' in nextProps) {
      const value = nextProps.value;
      this.setState(value);
    }
  }

  triggerChange = changedValue => {
    // console.log(changedValue);
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(Object.assign({}, this.state, changedValue));
    }
  };

  handleContentChange = content => {
    if (!('value' in this.props)) {
      this.setState({ content });
    }
    this.triggerChange({ content });
  };

  render() {
    const {
      width,
      butTitle,
      onSubmit,
      isBottom,
      height,
      marLeft,
      pb,
      minHeight
    } = this.props;
    const { content, bodyWidth } = this.state;

    return (
      <div
        className='components-MDEditor'
        style={{
          height,
          width: width - 3,
          position: 'relative',
          borderTop: '0',
          marginLeft: marLeft,
          paddingBottom: pb
        }}
      >
        <CustomReactMde
          minHeight={minHeight}
          generateMarkdownPreview={markdown =>
            Promise.resolve(this.converter.makeHtml(markdown))
          }
          editorState={content}
          onChange={mdeState => {
            this.handleContentChange(mdeState);
          }}
          layout={'tabbed'}
        />

        {isBottom || this.props.extraButton ? (
          <div className='new-routes-study-courseHomework-components-MDEditor-2603'>
            {this.props.extraButton && (
              <div className='new-routes-study-courseHomework-components-MDEditor-2904'>
                <this.props.extraButton />
              </div>
            )}
            {isBottom && (
              <Button
                onClick={onSubmit}
                className='new-routes-study-courseHomework-components-MDEditor-2938'
              >
                {butTitle}
              </Button>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

class MDEditor_v3_Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: {
        content: {
          markdown: "",
          html: "",
        }
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <Form.Item>
          {getFieldDecorator('content2', {
            initialValue: this.props.contentValue
          })(<MDEditor_v3 {...this.props} />)}
        </Form.Item>
      </Form>
    );
  }
}

const MDEditor_v3Wrapper = Form.create()(MDEditor_v3_Container);

export default MDEditor_v3Wrapper;
