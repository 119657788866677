import { IProps } from 'components/Card';

export interface Courses {
  count: number;
  collegeTypes: CollegesCoursesTypes[];
  courseTypes: CollegesCoursesTypes[];
  filterCourseTypes: CollegesCoursesTypes[];
  collegeName: string;
  courseName: string;
  page: number;
  list: Course[];
  hasNextPage: boolean;
  __typename: 'courses';
}

const courses: Courses = {
  count: 0,
  collegeTypes: [],
  courseTypes: [],
  filterCourseTypes: [],
  collegeName: '全部',
  courseName: '全部',
  page: 1,
  list: [],
  hasNextPage: false,
  __typename: 'courses'
};

export default courses;

// 课程学院 | 课程类型
export interface CollegesCoursesTypes {
  name: string;
  id: string;
}

// 课程列表
export interface Course extends IProps {
  shortUrl: string;
}
