import * as React from 'react';
import './styles.css';

//引入的库组件
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

//引入的其他页面组件
import TextCardEditPage from './TextCardEditPage';


//graphql查询
import { useGetCardInfoQuery } from '../../generated/graphql';

//引入的资源图片


const GET_MODIFY_COURSE_INFO = gql`
  {
    card_list @client
  }
`;


const ModifyTextCardContainer = (props: any) => {
    const { data: modifyCourseInfo } = useQuery(GET_MODIFY_COURSE_INFO);

    const { data: cardInfoData, loading: cardInfoLoading, error: cardInfoError, refetch: func } = useGetCardInfoQuery({fetchPolicy: "network-only", variables: {id: modifyCourseInfo.card_list[0]}});

    if(!cardInfoData || cardInfoLoading || cardInfoError) {
        return <div />;
    }

    return  <TextCardEditPage props={props} cardInfo={cardInfoData} />;
}

export default withRouter(ModifyTextCardContainer);