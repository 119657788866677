export interface My {
  user: any;
  basicProps: any;
  detailedProps: any;
  avatar: string;
  __typename: 'my';
}

const my: My = {
  user: null,
  basicProps: null,
  detailedProps: null,
  avatar: '',
  __typename: 'my'
};

export default my;
