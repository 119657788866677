import * as React from 'react';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import gql from 'graphql-tag';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import IndexPage from './IndexPage';
import HomePage from '../HomePage';
import EduManagerPage from '../EduManagerPage';
import CreateCourse from '../EduManagerCreateCoursePage';
import CreateTextCard from '../EduManagerCreateTextCard';
import CreateVideoCard from '../EduManagerCreateVideoCard';
import CreateExamCard from '../EduManagerCreateExamCard';
import CreateAudioCard from '../EduManagerCreateAudioCard';
import ModifyMultiCards from '../EduManagerModifyMultiCars';
import SubMerge from '../EduManagerPackagePage/EduManagerPackageSubMergePage';
import MergeFromMaster from '../EduManagerPackagePage/EduManagerPackageMergeFromMaster';
import MergeToMaster from '../EduManagerPackagePage/EduManagerPackageMergeToMaster';
import ResolveConflict from '../EduManagerPackagePage/EduManagerPackageResolveConflictPage';
import DiffChange from '../EduManagerPackagePage/EduManagerDiffPage';

import Courses from '../Courses';
import CourseDetail from '../CourseDetail';
import CollegeDetail from '../CollegeDetail';
import LearningCenter from '../LearningCenter';
import My from '../My';
import Msg from '../Msg';
import Stu from '../Stu';
import Error from '../404';

// 学习中心
import CourseContent from './../LearningCenter/CourseContent';
import CourseHomework from './../LearningCenter/CourseHomework';
import StudentAnswers from './../LearningCenter/CourseHomework/StudentAnswers';
import CollectionDetailMain from './../LearningCenter/CourseCollection/CollectionMain.js';

export const history = createBrowserHistory();

const GET_LOGIN_STATUS = gql`
  {
    isLoggedIn @client
  }
`;
const IndexPageContainer = (props: any) => {
  const { data } = useQuery(GET_LOGIN_STATUS);
  // 跳转路由后返回顶部
  history.listen(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  });

  return (
    <Router history={history}>
      <IndexPage history={history}>
        <Switch>
          <Route exact path='/edu' component={EduManagerPage} />
          <Route exact path='/edu/course' component={CreateCourse} />
          <Route exact path='/edu/create/textcard' component={CreateTextCard} />
          <Route
            exact
            path='/edu/create/videocard'
            component={CreateVideoCard}
          />
          <Route exact path='/edu/create/examcard' component={CreateExamCard} />
          <Route exact path='/edu/diff/submerge' component={SubMerge} />
          <Route exact path='/edu/diff/mergefrommaster' component={MergeFromMaster} />
          <Route exact path='/edu/diff/mergetomaster' component={MergeToMaster} />
          <Route exact path='/edu/diff/resolveconflict' component={ResolveConflict} />
          <Route exact path='/edu/diff/check' component={DiffChange} />
          <Route
            exact
            path='/edu/create/audiocard'
            component={CreateAudioCard}
          />
          <Route
            exact
            path='/edu/create/multiEditCard'
            component={ModifyMultiCards}
          />
          <Route exact path='/mkt' component={HomePage} />
          <Route exact path='/mkt/courses' component={Courses} />
          <Route exact path='/mkt/course/:short_url' component={CourseDetail} />
          <Route exact path='/college/:type' component={CollegeDetail} />
          <Route
            exact
            path='/stu/:code/collection/cardDetail'
            component={CollectionDetailMain}
          />
          <Route
            exact
            path='/stu/:code/homework/:hk/:ak'
            component={StudentAnswers}
          />
          <Route path='/stu/:code' component={LearningCenter} />

          <Route exact path='/my' component={My} />
          <Route exact path='/msg' component={Msg} />
          <Route exact path='/stu' component={Stu} />
          <Route exact path='/' render={() => { return <Redirect to='/mkt' />}} />
          <Route component={Error} />
        </Switch>
      </IndexPage>
    </Router>
  );
};

export default IndexPageContainer;
