export interface Stu {
  sayHi: string;
  finished: any;
  learning: any;
  __typename: 'stu';
}

const now = new Date().getHours();
let sayHi = '';
if (now < 9) {
  sayHi = '早上好';
} else if (now < 12) {
  sayHi = '上午好';
} else if (now < 14) {
  sayHi = '中午好';
} else if (now < 17) {
  sayHi = '下午好';
} else if (now < 22) {
  sayHi = '晚上好';
} else {
  sayHi = '夜里好';
}

const stu: Stu = {
  sayHi,
  finished: [],
  learning: [],
  __typename: 'stu'
};

export default stu;
