import gql from 'graphql-tag';

export const GET_USER_INFO = gql`
  query getUserInfo {
    me {
      avatar
      id
      nickname
      phone
      role
      userProfile {
        address
        birth
        company
        email
        fieldOfStudy
        gender
        headline
        hobbies
        id
        industry
        info
        name
        omcRegion
        school
        state
        wechatNickname
        wechatUsername
        works
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors {
        message
        key
        code
      }
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      errors {
        message
        key
        code
      }
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      errors {
        message
        key
        code
      }
    }
  }
`;

export const CREATE_USER_PROFILE = gql`
  mutation createUserProfile($input: CreateUserProfileInput!) {
    createUserProfile(input: $input) {
      errors {
        message
        key
        code
      }
    }
  }
`;
