import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Spin, Breadcrumb, message, Layout, Avatar, Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { FormComponentProps } from 'antd/es/form';
import { withRouter } from 'react-router';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import ItemRender from '../CommonComponents/ItemRender';
import DiffView from '../CommonComponents/DiffView';
import { RedButton, WhiteButton } from '../CommonComponents/CustomButton';
import Modal2CallbackFunc from '../CommonComponents/Modals/Modal2CallbackFunc';
import RejectModalCallback from '../RejectModal/RejectModalCallbackFunc';

//graphql查询
import { useRejectPrMutation, useMergeMutation } from '../../generated/graphql';

//引入的资源图片
import back from '../../assets/icons/back.png';
import root from '../../assets/icons/file_medium_root.png';

interface Props extends FormComponentProps {
  props: any;
}

const routes = [
  {
    path: '/',
    breadcrumbName: '首页'
  },
  {
    path: '//msg',
    breadcrumbName: '消息'
  },
  {
    path: '',
    breadcrumbName: '变更申请'
  }
];

const GET_DIFF_CHANGES_INFO = gql`
  {
    mergeInfo @client(always: true) {
      currentPackageId
      targetPackageId
      head_img
      user
      status
      content
      description
      diffChangesStr
      msgId
    }
  }
`;

const EduManagerPackageMergeToMasterPage = (props: any) => {
  const client = useApolloClient();
  const { data: diffChangesInfo } = useQuery(GET_DIFF_CHANGES_INFO);
  const { mergeInfo } = diffChangesInfo;
  const {
    currentPackageId,
    targetPackageId,
    head_img,
    user,
    content,
    description,
    status,
    diffChangesStr,
    msgId
  } = mergeInfo;
  const { form } = props;

  const [rejectPrMutation] = useRejectPrMutation();
  const [mergeMutation] = useMergeMutation();

  const [loading, setLoading] = React.useState(false);

  return (
    <Spin size='large' tip='正在提交申请...' spinning={loading}>
      <Layout className='routes-study-Merge-1175'>
        <div className='routes-study-Merge-1240'>
          <div className='routes-study-Merge-1373'>
            <div
              onClick={() => {
                props.history.goBack();
              }}
              className='routes-study-Merge-1559'
            >
              <img src={back} />
              <span className='routes-study-Merge-1630'>返回</span>
            </div>
            <div className='routes-study-Merge-1703' />
            <Breadcrumb
              separator={'›'}
              itemRender={ItemRender}
              routes={routes}
              className='routes-study-Merge-1859'
            />
          </div>

          <div className='routes-study-Merge-1913'>
            <Avatar size={'large'} src={head_img} />
            <div className='routes-study-Merge-2114'>
              <span className='routes-study-Merge-2213'>{user}</span>
              <span className='routes-study-Merge-2296'>
                共有{diffChangesStr.split('--git').length - 1}个文件变更
              </span>
              <span className='routes-study-Merge-2421'>{content}</span>
            </div>
            {status === 'submitted' ? (
              <WhiteButton
                onClick={() => {
                  RejectModalCallback.callback = (msg: string) => {
                    let input: any = {
                      prMessageId: Base64.decode(msgId).split('Message:')[1],
                      why: msg
                    };
                    rejectPrMutation({ variables: { input: input } }).then(
                      (res: any) => {
                        setLoading(false);
                        if (res.data.rejectPr.errors) {
                          message.error('拒绝失败!');
                        } else {
                          message.success('已拒绝所有变更!');
                          props.history.push('/msg');
                        }
                      }
                    );
                  };
                  setLoading(true);
                  client.writeData({ data: { isShowRejectModal: true } });
                }}
                width={'120px'}
                height={'40px'}
                color={'#282A2D'}
                backgroundColor={'#fff'}
                className='routes-study-Merge-2773'
              >
                拒绝所有变更
              </WhiteButton>
            ) : null}
            {status === 'submitted' ? (
              <RedButton
                onClick={() => {
                  Modal2CallbackFunc.callback = () => {
                    let input = {
                      currentPackageId: currentPackageId,
                      targetPackageId: targetPackageId
                    };
                    mergeMutation({
                        variables: { input: input }
                      })
                        .then((res: any) => {
                          if (res.data.merge.errors) {
                            message.error('合并失败!');
                          } else {
                            let merger = res.data.merge.merger;
                            if(merger.conflicts && merger.conflicts.length > 0) {
                              message.error('合并发生冲突!');
                              client.writeData({data: {conflictData: merger.conflicts, isMasterMerging: true}});
                              props.history.push('/edu/diff/resolveconflict');
                            }else {
                              message.success('合并成功!');
                              props.history.push('/edu');
                            }
                          }

                          client.writeData({
                            data: {
                              modal2Loading: false,
                              modal2: {
                                show: false,
                                title: '',
                                content: '',
                                __typename: 'Modal2'
                              }
                            }
                          });
                        })
                        .catch((error: any) => {
                          client.writeData({
                            data: {
                              modal2Loading: false,
                              modal2: {
                                show: false,
                                title: '',
                                content: '',
                                __typename: 'Modal2'
                              }
                            }
                          });
                          message.error('合并失败!');
                        });
                  };

                  client.writeData({
                    data: {
                      modal2Loading: false,
                      modal2: {
                        show: true,
                        title: '确定要合并所有变更吗？',
                        content: '合并所有变更后，内容将无法恢复',
                        __typename: 'Modal2'
                      }
                    }
                  });
                }}
                width={'120px'}
                color={'#282A2D'}
                height={'40px'}
                className='routes-study-Merge-3535'
              >
                合并所有变更
              </RedButton>
            ) : null}
            {status !== 'submitted' ? (
              <Button disabled className='routes-study-Merge-3752'>
                {status === 'rejected' ? '申请已拒绝' : '文件已合并'}
              </Button>
            ) : null}
          </div>

          <div className='routes-study-Merge-5733'>
            <DiffView
              currentCommit={{
                diff: diffChangesStr
              }}
              lastCommit={null}
              showAll={false}
              showType={'split'}
            />
          </div>
        </div>
      </Layout>
    </Spin>
  );
};

const EduManagerPackageMergeToMasterPageWrapper = Form.create<Props>()(
  withRouter(EduManagerPackageMergeToMasterPage)
);

export default EduManagerPackageMergeToMasterPageWrapper;
