import * as React from 'react';
import './styles.css';

//引入的库组件
import { useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { Modal, Spin, Button, Input, message } from 'antd';

//引入的其他页面组件
import { Footer } from '../Footer';
import Modal2CallbackFunc from './Modal2CallbackFunc';

//graphql查询

//引入的资源图片
import warning from '../../../assets/icons/warning.png';

const GET_MODAL_INFO = gql`
  {
    modal2Loading @client
    modal2 @client {
      title
      show
      content
    }
  }
`;

const Modal2 = (props: any) => {
  const client = useApolloClient();
  const { data } = useQuery(GET_MODAL_INFO);
  const { modal2, modal2Loading } = data;

  const onCancel = () => {
    Modal2CallbackFunc.callback = null;
    client.writeData({
      data: {
        modal2: { show: false, title: '', content: '', __typename: 'Modal2' }
      }
    });
  };

  return (
    <Modal
      onCancel={onCancel}
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        height: 185,
      }}
      width={380}
      keyboard={false}
      maskClosable={false}
      closable={false}
      title={'操作提示'}
      visible={modal2.show}
      footer={null}
    >
      <Spin spinning={modal2Loading}>
        <div className='components-modal2-905'>
          <div className='components-modal2-989'>
            <div>
              <img data-rjs='2' src={warning} />
            </div>
            <div className='components-modal2-1160'>
              {modal2.title}
            </div>
          </div>
          <div className='components-modal2-1309'>
            {modal2.content}
          </div>
          <Footer className='components-modal2-1424'>
            <Footer.Cancel onClick={onCancel}>取消</Footer.Cancel>
            <Footer.OK onClick={Modal2CallbackFunc.callback}>确认</Footer.OK>
          </Footer>
        </div>
      </Spin>
    </Modal>
  );
};

export default Modal2;
