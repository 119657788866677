import gql from 'graphql-tag';

export const GET_USER_MESSAGE = gql`
  query getUserMessage($filter: MessageFilter, $first: Int) {
    me {
      messages(filter: $filter, first: $first) {
        edges {
          node {
            id
            info
            content
            state
            description
            poster {
              avatar
              nickname
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

export const UPDATE_MESSAGE = gql`
  mutation updateMessage($input: UpdateMessageInput) {
    updateMessage(input: $input) {
      errors {
        message
        key
        code
      }
    }
  }
`;

export const GET_PARENT_ID = gql`
  query getParentId($id: ID!) {
    node(id: $id) {
      ... on Assignment {
        pid
      }
    }
  }
`;

export const DIFF_CHANGES_IN_MESSAGE = gql`
  query DiffChangesInMessage($currentPackageId: ID!, $targetPackageId: ID, $diffType: DiffType!) {
    diff(currentPackageId: $currentPackageId, targetPackageId: $targetPackageId, diffType: $diffType) {
      changes
    }
  }
`;