/**
 * @description 单个学员故事组件
 * @author ali
 */

import React from 'react';

function MemberStory(props: any) {
  const { card } = props;
  return (
    <div className='coach-item-container' style={props.moving}>
      <div
        style={{
          width: '66px',
          height: '66px',
          borderRadius: '66px',
          background: `url(${card.avator})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          marginTop: '10px'
        }}
      />

      <h4 style={{ marginTop: '22px' }}>{card.userName}</h4>
      {card.workName.map((val: any) => (
        <div key={val}>{val}</div>
      ))}

      <p
        style={{
          // height: "160px",
          marginTop: '22px',
          paddingLeft: '18px',
          paddingRight: '18px',
          marginBottom: '0px',
          overflow: 'hidden',
          textAlign: 'left',
          fontWeight: 500,
          lineHeight: '28px'
        }}
      >
        {card.description}
      </p>
    </div>
  );
}

export default MemberStory;
