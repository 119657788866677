import * as React from 'react';
import './styles.css';
import styled from 'styled-components';
import { Carousel } from 'antd';
import { HomePageBannerQuery } from '../../generated/graphql';
import gaEvents from '../../utils/gaEvents.js';

import left from '../../assets/college/last.svg';
import right from '../../assets/college/next.svg';

interface Props {
  props: any;
  data: HomePageBannerQuery;
}

interface BannerViewProps {
  background: string;
}

const BannerView = styled.a`
  background: ${(props: BannerViewProps) => props.background};
  height: 360px;
  cursor: pointer;
  display: block;
`;

const HomePageBanner: React.FC<Props> = ({ data, props }) => {
  const [curBanner, setCurBanner] = React.useState(0);
  const [banner, setBanner] = React.useState(false);
  const [bannerRef, setBannerRef] = React.useState();

  let banners = !!data.banners ? data.banners : [];
  let currentBanner = banners[curBanner];
  let link = !!currentBanner ? currentBanner.linkUrl : '';
  return (
    <div>
      <Carousel
        className="home-page-height"
        ref={ref => setBannerRef(ref)}
        dots={false}
        autoplay
        afterChange={current => {
          setCurBanner(current);
        }}
      >
        {banners.map((item, index) => {
          return (
            <BannerView
              key={index.toString()}
              href={(item ? item.linkUrl : '')!}
              data-rjs='2'
              background={`url(${
                item ? item.imageUrl : ''
              }) no-repeat center top`}
              className='home-page-banner-2134'
              onMouseLeave={() => {
                setBanner(false);
              }}
              onMouseEnter={() => {
                setBanner(true);
              }}
            />
          );
        })}
      </Carousel>
      <a
        className='home-page-banner-5799'
        onClick={e => {
          if ((e.target as Element).matches('a')) {
            if (link === '') {
              return;
            }
            window.open(link!);
            gaEvents.gaEventsUpload({
              eventName:
                "点击-主页-Banner:" + link
            });
          }
        }}
        onMouseLeave={() => {
          setBanner(false);
        }}
        onMouseEnter={() => {
          setBanner(true);
        }}
        style={{
          cursor:
            banners.length === 0
              ? 'default'
              : link === ''
              ? 'default'
              : 'pointer',
          position: 'absolute',
          left: '50%',
          top: '10px',
          width: '1000px',
          transform: 'translate(-50%,0)'
        }}
      >
        <div
          className="home-page-banner-8485"
        >
          <div
            style={{
              pointerEvents: 'auto'
            }}
            onClick={() => {
              bannerRef.prev();
            }}
          >
            {banner && banners.length > 1 ? (
              <img data-rjs='2' className='home-page-banner-7086' src={left} />
            ) : null}
          </div>
          <div
            style={{
              pointerEvents: 'auto'
            }}
            onClick={() => {
              bannerRef.next();
            }}
          >
            {' '}
            {banner && banners.length > 1 ? (
              <img className='home-page-banner-7279' src={right} data-rjs='2' />
            ) : null}
          </div>
        </div>
      </a>
    </div>
  );
};

export default HomePageBanner;
