import React, { FC } from 'react';
import './style.less';

import gaEvents from '../../utils/gaEvents.js';

export interface IProps {
  cover: string;
  title: string;
  subscribedCount?: number | string;
  onClick?: () => void;
}

/**
 * Card - 卡片
 *
 * @param { string } cover - 卡片图片路径
 *
 * @param { string } title - 卡片底部描述
 *
 * @param { string | number } subscribedCount - 关注人数
 *
 * @param { HTMLDivElement } onClick - 点击处理函数
 *
 */
const Card: FC<IProps> = ({
  cover,
  title,
  subscribedCount,
  onClick,
  children
}) => (
  <div
    className='components-card'
    onClick={() => {
      gaEvents.gaEventsUpload({
        eventName: `点击-${title}-课程详细信息`
      });
      if(typeof onClick === "function") {
        onClick();
      }
    }}
  >
    <div className='components-card-img'>
      <img src={cover} alt={title} />
    </div>

    <div className='components-card-description'>
      <span className='components-card-description-title'>{title}</span>
      {children || (
        <span className='components-card-description-subscribedCount'>{/* `${subscribedCount}人关注` */}</span>
      )}
    </div>
  </div>
);

export default Card;
