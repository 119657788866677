import React from 'react';
import Concat from './Concat';
import QRCode from './QRCode';
import openmind from 'assets/footer/openMind.png';
import mail from 'assets/footer/mail.svg';
import github from 'assets/footer/github.svg';
import weibo from 'assets/footer/weibo.svg';
import './Footer.less';

const fontColor = { color: '#BFC4CE', fontSize: '18px !important' };
const links = [
  { icon: github, link: 'https://github.com/OpenMindClub/Share/wiki' },
  { icon: weibo, link: 'http://weibo.com/openmindclub' },
  { icon: mail, link: 'mailto:we@openmindclub.com' }
];
function Footer(props) {
  return (
    <div className='footer'>
      <div className='footer-container'>
        <div className='footer-content'>
          <div className='footer-contentOne'>
            <img
              src={openmind}
              style={{ width: '167px', height: '50px', marginBottom: '42px' }}
            />
            {/*<h2 className='footer-title'>未来创造者，从这里起步</h2>*/}
            {/*<p className='footer-description'>*/}
            {/*  开智学堂是一家新型职业教育机构，旗下拥有四大学院：认知学院、写作学院、效率学院、教练学院。在这里，你既可以通过信息分析、论证分析等课程升级底层认知；又可以通过创意写作、编程思维、教练黑客等课程提高核心技能，还能结识众多优秀小伙伴。欢迎来到开智学堂，升级认知、提升技能。*/}
            {/*</p>*/}
            {/*<div>*/}
            {/*  <ul className='footer-links'>*/}
            {/*    {links.map((item, index) => (*/}
            {/*      <li key={index.toString()}>*/}
            {/*        <a href={item.link}>*/}
            {/*          <img src={item.icon} />*/}
            {/*        </a>*/}
            {/*      </li>*/}
            {/*    ))}*/}
            {/*  </ul>*/}
            {/*</div>*/}
          </div>

          {/* <div className='list}>
          <Concat
            title={"课程"}
            list={[
              { name: "认知学院", cursor: true },
              { name: "写作学院", cursor: true },
              { name: "教练学院", cursor: true },
              { name: "效率学院", cursor: true }
            ]}
          />
        </div> */}

          {/*<div className='footer-list'>*/}
          {/*  <Concat*/}
          {/*    title={'关于'}*/}
          {/*    list={[*/}
          {/*      { name: '关于开智', cursor: false },*/}
          {/*      { name: '开智+', cursor: false },*/}
          {/*      { name: '开智大会', cursor: false }*/}
          {/*    ]}*/}
          {/*  />*/}
          {/*</div>*/}

          {/*<div>*/}
          {/*  <QRCode />*/}
          {/*</div>*/}
        </div>
        <div className='footer-copyright'>
          <p style={{ marginTop: '10px', ...fontColor, marginBottom: '0px' }}>
            © 开智学堂 2019. All Right Reserved. 备案号码
            <a href="https://beian.miit.gov.cn">京ICP备15045095号-4</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
