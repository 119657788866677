import React, { FC } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Card from 'components/Card';
import { CollegeCourse } from 'global_variable/home';
import gaEvents from '../../../utils/gaEvents.js';


const mappings: { [key: string]: string } = {
  效率学院: 'AI课堂',
  认知学院: '认知课堂',
  写作学院: '创作课堂',
  阅读学院: '阅读课堂',
  教练学院: '人生课堂'
}

const CollegeItem: FC<CollegeCourse & RouteComponentProps> = ({
  history,
  title,
  to,
  courses
}) => (
  <div className='college-item'>
    <div className='college-item-header'>
      <h1>{mappings[title] || title}</h1>
      <Link
        className='college-item-header-link'
        to={to}
        onClick={() => {
          gaEvents.gaEventsUpload({
            eventName: `点击-主页-${title}-更多`
          });
        }}
      >
        更多
      </Link>
    </div>
    <div className='college-item-footer'>
      {courses.map(item => (
        <Card
          key={item.title}
          cover={item.cover}
          title={item.title}
          subscribedCount={item.subscribedCount}
          onClick={() => history.push(`/mkt/course/${item.shortUrl}`)}
        ></Card>
      ))}
    </div>
  </div>
);

export default withRouter(CollegeItem);
