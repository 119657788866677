import gql from 'graphql-tag';

export const GET_COMMIT_DETAIL = gql`
  query GetCommitDetail($id: ID!) {
    node(id: $id) {
      ... on Commit {
        assignment {
          id
          pid
          location
        }
        content
        diff
        id
      }
    }
  }
`;

export const GET_COMMIT_DETAIL_CONTENT = gql`
  query GetCommitDetailContent($id: ID!) {
    node(id: $id) {
      ... on Commit {
        assignment {
          id
          pid
          location
        }
        content
        id
      }
    }
  }
`;

export const GET_COMMIT_DETAIL_DIFF = gql`
  query GetCommitDetailDiff($id: ID!) {
    node(id: $id) {
      ... on Commit {
        assignment {
          id
          pid
          location
        }
        diff
        id
      }
    }
  }
`;

// 获取作业列表
export const GET_ALL_ASSIGNMENTS = gql`
  query getAllAssignments($id: ID!) {
    node(id: $id) {
      ... on CoursePackage {
        assignments {
          name
          id
          isIveDone
          questions {
            name
            id
          }
        }
      }
    }
  }
`;

// 得到其他同学的答案
export const CHECKOTHERANSWER = gql`
  query checkSingleAnswer($id: ID!, $courseId: ID!) {
    node(id: $id) {
      ... on Assignment {
        content
        commentsCount
        insertedAt
        author {
          nickname
          avatar
        }
        id
        isCollected(courseId: $courseId)
        comments(first: 100) {
          edges {
            node {
              content
              id
              insertedAt
              poster {
                nickname
                avatar
                id
              }
            }
          }
        }
      }
    }
  }
`;

// 查看自己的答案
export const GET_MY_ANSWER_ID = gql`
  query getMyAnswerId($id: ID!) {
    node(id: $id) {
      ... on Assignment {
        answers(first: 1, filter: { isMine: true }) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

// 查看其他同学的答案
export const CHECK_OTHER_ANSWERS = gql`
  query checkOtherAnswers($id: ID!, $first: Int, $after: String, $last: Int, $before: String, $filter: AnswerFilter, $matching: String, $order: AssignmentOrder) {
    node(id: $id) {
      ... on Assignment {
        name
        answers(first: $first, after: $after, last: $last, before: $before, filter: $filter, matching: $matching, order: $order) {
          count
          edges {
            node {
              id
              author {
                nickname
                avatar
              }
              commentsCount
              insertedAt
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  }
`;

// 删除作业的评论
export const REMOVE_COMMENT = gql`
  mutation removeCommentFromAssignment(
    $input: RemoveCommentFromAssignmentInput!
  ) {
    removeCommentFromAssignment(input: $input) {
      assignment {
        comments(first: 100) {
          edges {
            node {
              poster {
                nickname
                avatar
                id
              }
              id
              content
            }
          }
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

// 获取课程的id
const GET_COURSE_ID = gql`
  query getCourseId($id: ID!) {
    node(id: $id) {
      ... on Course {
        id
        title
        coursesPackages {
          id
          packageTag
        }
      }
    }
  }
`;
