import { College } from 'global_variable/home';

interface CourseDetail {
  college: College | null;
  course: any;
  payVisible: boolean;
  popVisible: boolean;
  disabled: boolean;
  btnText: string;
  __typename: 'courseDetail';
}

const courseDetail: CourseDetail = {
  college: null,
  course: null,
  payVisible: false,
  popVisible: false,
  disabled: false,
  btnText: '',
  __typename: 'courseDetail'
};

export default courseDetail;
