import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /**
   * The `Json` scalar type represents arbitrary json string data, represented as UTF-8
   * character sequences. The Json type is most often used to represent a free-form
   * human-readable json string.
   **/
  Json: any;
  /** The name of file, can not contains '/' */
  FileName: any;
  /**
   * The `UUID4` scalar type represents UUID4 compliant string data, represented as UTF-8
   * character sequences. The UUID4 type is most often used to represent unique
   * human-readable ID strings.
   **/
  UUID4: any;
  /** Represents an uploaded file. */
  Upload: any;
};

export type AddCommentInput = {
  atUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  content: Scalars['String'];
};

export type AddCommentToAssignmentInput = {
  assignmentId: Scalars['ID'];
  comment: AddCommentInput;
  coursePackageId: Scalars['ID'];
};

export type AddCommentToDiscussionInput = {
  comment: AddCommentInput;
  coursePackageId: Scalars['ID'];
  discussionId: Scalars['ID'];
};

export type AddLabelsToCardInput = {
  id: Scalars['ID'];
  labelIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type AddScenesToLabelInput = {
  id?: Maybe<Scalars['ID']>;
  scenes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddTagToUsersInput = {
  phones: Array<Scalars['String']>;
  tag: Scalars['String'];
};

export type AddTagToUsersPayload = {
  __typename?: 'AddTagToUsersPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  unsuccessfulPhones?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AdminDiscussionFilter = {
  courseTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AnswerFilter = {
  authorName?: Maybe<Scalars['String']>;
  isMine?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Assignment = CardInfo &
  Node & {
    __typename?: 'Assignment';
    answers?: Maybe<AssignmentConnection>;
    author?: Maybe<User>;
    comments?: Maybe<CardCommentConnection>;
    commentsCount?: Maybe<Scalars['Int']>;
    commits?: Maybe<Array<Maybe<CommitInfo>>>;
    content?: Maybe<Scalars['String']>;
    contentModifiedAt?: Maybe<Scalars['Date']>;
    /** The ID of an object */
    id: Scalars['ID'];
    insertedAt?: Maybe<Scalars['Date']>;
    isCollected?: Maybe<Scalars['Boolean']>;
    isIveDone?: Maybe<Scalars['Boolean']>;
    location?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    package?: Maybe<Package>;
    pid?: Maybe<Scalars['ID']>;
    questions?: Maybe<Array<Maybe<Assignment>>>;
    status?: Maybe<Scalars['Boolean']>;
  };

export type AssignmentAnswersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AnswerFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  matching?: Maybe<Scalars['String']>;
  order?: Maybe<AssignmentOrder>;
};

export type AssignmentCommentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AssignmentIsCollectedArgs = {
  courseId: Scalars['ID'];
};

export type AssignmentConnection = {
  __typename?: 'AssignmentConnection';
  count?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<AssignmentEdge>>>;
  pageInfo: PageInfo;
};

export type AssignmentEdge = {
  __typename?: 'AssignmentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Assignment>;
};

export enum AssignmentOrder {
  AscCommentsCount = 'ASC_COMMENTS_COUNT',
  AscInsertedAt = 'ASC_INSERTED_AT',
  DescCommentsCount = 'DESC_COMMENTS_COUNT',
  DescInsertedAt = 'DESC_INSERTED_AT'
}

export type AssignmentPayload = {
  __typename?: 'AssignmentPayload';
  assignment?: Maybe<Assignment>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type AtUser = {
  __typename?: 'AtUser';
  id?: Maybe<Scalars['ID']>;
  nickname?: Maybe<Scalars['String']>;
};

export type AuthCodeErrors = {
  __typename?: 'AuthCodeErrors';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type AuthCodeResult = {
  __typename?: 'AuthCodeResult';
  phone?: Maybe<Scalars['String']>;
};

export type Banner = Node & {
  __typename?: 'Banner';
  /** The ID of an object */
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  insertedAt?: Maybe<Scalars['Date']>;
  linkUrl?: Maybe<Scalars['String']>;
};

export type BannerPayload = {
  __typename?: 'BannerPayload';
  banner?: Maybe<Banner>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type BulkCreatePurchasesInput = {
  phones: Array<Scalars['String']>;
  shortUrls: Array<Scalars['String']>;
};

export type BulkPurchasesPayload = {
  __typename?: 'BulkPurchasesPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  purchases?: Maybe<Array<Maybe<Purchase>>>;
  unsuccessfulPhones?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Card = CardInfo &
  Node & {
    __typename?: 'Card';
    ancestors?: Maybe<Array<Maybe<Card>>>;
    author?: Maybe<User>;
    children?: Maybe<Array<Maybe<Card>>>;
    collaborativeState?: Maybe<CardCollaborativeState>;
    content?: Maybe<Scalars['String']>;
    depth?: Maybe<Scalars['Int']>;
    fileType?: Maybe<FileType>;
    hasFolderChild?: Maybe<Scalars['Boolean']>;
    highlightingContent?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** The ID of an object */
    id: Scalars['ID'];
    insertedAt?: Maybe<Scalars['Date']>;
    isCollected?: Maybe<Scalars['Boolean']>;
    isLearned?: Maybe<Scalars['Boolean']>;
    learnedPeopleCount?: Maybe<Scalars['Int']>;
    location?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    package?: Maybe<Package>;
    parent?: Maybe<Card>;
    sourceContent?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['Boolean']>;
  };

export type CardAncestorsArgs = {
  isMaster: Scalars['Boolean'];
};

export type CardChildrenArgs = {
  coursePackageId?: Maybe<Scalars['ID']>;
  ignoreAns: Scalars['Boolean'];
  isMaster?: Scalars['Boolean'];
};

export type CardHasFolderChildArgs = {
  isMaster: Scalars['Boolean'];
};

export type CardIsCollectedArgs = {
  courseId: Scalars['ID'];
};

export type CardIsLearnedArgs = {
  coursePackageId: Scalars['ID'];
};

export type CardLearnedPeopleCountArgs = {
  coursePackageId: Scalars['ID'];
};

export type CardParentArgs = {
  isMaster?: Maybe<Scalars['Boolean']>;
};

export enum CardCollaborativeState {
  Created = 'CREATED',
  Merged = 'MERGED',
  Modified = 'MODIFIED'
}

export type CardComment = Node & {
  __typename?: 'CardComment';
  card?: Maybe<Card>;
  content?: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['Date']>;
  poster?: Maybe<User>;
  status?: Maybe<Scalars['Boolean']>;
};

export type CardCommentConnection = {
  __typename?: 'CardCommentConnection';
  count?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<CardCommentEdge>>>;
  pageInfo: PageInfo;
};

export type CardCommentEdge = {
  __typename?: 'CardCommentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CardComment>;
};

export type CardCommentPayload = {
  __typename?: 'CardCommentPayload';
  cardComment?: Maybe<CardComment>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type CardConnection = {
  __typename?: 'CardConnection';
  count?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<CardEdge>>>;
  pageInfo: PageInfo;
};

export type CardEdge = {
  __typename?: 'CardEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Card>;
};

export type CardExam = Node & {
  __typename?: 'CardExam';
  card?: Maybe<Card>;
  /** The ID of an object */
  id: Scalars['ID'];
  info?: Maybe<Scalars['Json']>;
  insertedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export type CardExamPayload = {
  __typename?: 'CardExamPayload';
  cardExam?: Maybe<CardExam>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type CardInfo = {
  author?: Maybe<User>;
  content?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  package?: Maybe<Package>;
  status?: Maybe<Scalars['Boolean']>;
};

export type CardLearningRecord = Node & {
  __typename?: 'CardLearningRecord';
  card?: Maybe<Card>;
  coursePackageId?: Maybe<Scalars['ID']>;
  /** The ID of an object */
  id: Scalars['ID'];
  lastViewedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  visitCounts?: Maybe<Scalars['Int']>;
  visitDuration?: Maybe<Scalars['Float']>;
};

export type CardPayload = {
  __typename?: 'CardPayload';
  card?: Maybe<Card>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type CardsPayload = {
  __typename?: 'CardsPayload';
  cards?: Maybe<Array<Maybe<Card>>>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export enum CardTag {
  Exam = 'EXAM',
  Txt = 'TXT',
  Video = 'VIDEO'
}

export type CardVideo = Node & {
  __typename?: 'CardVideo';
  defaultUrl?: Maybe<Scalars['String']>;
  encryptedUrl?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['Date']>;
  originalUrl?: Maybe<Scalars['String']>;
  platform?: Maybe<VideoPlatform>;
};

export type CardVideoConnection = {
  __typename?: 'CardVideoConnection';
  count?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<CardVideoEdge>>>;
  pageInfo: PageInfo;
};

export type CardVideoEdge = {
  __typename?: 'CardVideoEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CardVideo>;
};

export type CardVideoFilter = {
  name?: Maybe<Scalars['String']>;
};

export type CardVideoPayload = {
  __typename?: 'CardVideoPayload';
  cardVideo?: Maybe<CardVideo>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type CollectCardInput = {
  cardId: Scalars['ID'];
  courseId: Scalars['ID'];
};

export type Collection = CardInfo &
  Node & {
    __typename?: 'Collection';
    author?: Maybe<User>;
    comments?: Maybe<Array<Maybe<CardComment>>>;
    commentsCount?: Maybe<Scalars['Int']>;
    content?: Maybe<Scalars['String']>;
    highlightingContent?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** The ID of an object */
    id: Scalars['ID'];
    insertedAt?: Maybe<Scalars['Date']>;
    labels?: Maybe<Array<Maybe<Label>>>;
    location?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    package?: Maybe<Package>;
    parent?: Maybe<Assignment>;
    status?: Maybe<Scalars['Boolean']>;
  };

export type CollectionConnection = {
  __typename?: 'CollectionConnection';
  count?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<CollectionEdge>>>;
  pageInfo: PageInfo;
};

export type CollectionEdge = {
  __typename?: 'CollectionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Collection>;
};

export type CollectionFilter = {
  labels?: Maybe<Array<Scalars['String']>>;
  tag?: Maybe<CardTag>;
};

export enum CollectionOrder {
  AnswerDescCommentsAt = 'ANSWER__DESC_COMMENTS_AT',
  AnswerDescInsertedAt = 'ANSWER__DESC_INSERTED_AT',
  CollectionDescInsertedAt = 'COLLECTION__DESC_INSERTED_AT',
  ContentAscIndex = 'CONTENT__ASC_INDEX',
  ContentDescCollected = 'CONTENT__DESC_COLLECTED',
  DiscussionDescCommentsAt = 'DISCUSSION__DESC_COMMENTS_AT',
  DiscussionDescCommentsCount = 'DISCUSSION__DESC_COMMENTS_COUNT',
  DiscussionDescInsertedAt = 'DISCUSSION__DESC_INSERTED_AT'
}

export type CollectionPayload = {
  __typename?: 'CollectionPayload';
  collection?: Maybe<Collection>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type Commit = Node & {
  __typename?: 'Commit';
  assignment?: Maybe<Assignment>;
  content?: Maybe<Scalars['String']>;
  diff?: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
};

export type CommitInfo = {
  __typename?: 'CommitInfo';
  commitHash?: Maybe<Scalars['String']>;
  commitedAt?: Maybe<Scalars['String']>;
};

export type Conflict = {
  __typename?: 'Conflict';
  /** The conflict content. */
  content?: Maybe<Scalars['String']>;
  /** The conflicted card id of current package. */
  location?: Maybe<Scalars['String']>;
};

export type CopyCardsInput = {
  ids: Array<Scalars['ID']>;
  /** when you copy files to the root folder, you should set this field to null */
  newFolderId: Scalars['UUID4'];
  newPackageId: Scalars['ID'];
  oldFolderId?: Maybe<Scalars['ID']>;
  packageId: Scalars['ID'];
};

export type CopyCardsPayload = {
  __typename?: 'CopyCardsPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  /** The new folder */
  folder?: Maybe<Card>;
  package?: Maybe<Package>;
};

export type Course = Node & {
  __typename?: 'Course';
  category?: Maybe<CourseCategory>;
  chargingLink?: Maybe<Scalars['String']>;
  coursesPackages?: Maybe<Array<Maybe<CoursePackage>>>;
  cover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  instructors?: Maybe<Scalars['String']>;
  isEnd?: Maybe<Scalars['Boolean']>;
  isFee?: Maybe<Scalars['Boolean']>;
  isFinished?: Maybe<Scalars['Boolean']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  isSelling?: Maybe<Scalars['Boolean']>;
  isSubscribed?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  needUserProfile?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<User>;
  price?: Maybe<Scalars['Float']>;
  registrationBegin?: Maybe<Scalars['Date']>;
  registrationEnd?: Maybe<Scalars['Date']>;
  shortUrl?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  subscribedCount?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type CourseCoursesPackagesArgs = {
  packageTag?: Maybe<PackageTag>;
};

export type CourseCategory = Node & {
  __typename?: 'CourseCategory';
  /** The ID of an object */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<CourseCategory>;
};

export type CourseConnection = {
  __typename?: 'CourseConnection';
  count?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<CourseEdge>>>;
  pageInfo: PageInfo;
};

export type CourseEdge = {
  __typename?: 'CourseEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Course>;
};

export type CourseFilter = {
  collegiateCategory?: Maybe<Scalars['String']>;
  courseCategory?: Maybe<Scalars['String']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  isSelling?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type CoursePackage = Node & {
  __typename?: 'CoursePackage';
  assignments?: Maybe<Array<Maybe<Assignment>>>;
  contents?: Maybe<CardConnection>;
  course?: Maybe<Course>;
  discussions?: Maybe<DiscussionConnection>;
  /** Count the cards in this course package that filetype is FILE. */
  fileCardsCount?: Maybe<Scalars['Int']>;
  firstUnlearnedCard?: Maybe<Card>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['Date']>;
  /** Count the cards that user has learned. */
  learnedCount?: Maybe<Scalars['Int']>;
  nextCard?: Maybe<Card>;
  owner?: Maybe<User>;
  package?: Maybe<Package>;
  packageTag?: Maybe<PackageTag>;
  previousCard?: Maybe<Card>;
  signCard?: Maybe<Card>;
  status?: Maybe<Scalars['Boolean']>;
};

export type CoursePackageContentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CoursePackageDiscussionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter: DiscussionFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  matching?: Maybe<Scalars['String']>;
  order?: Maybe<DiscussionOrder>;
};

export type CoursePackageNextCardArgs = {
  currentCardId: Scalars['ID'];
};

export type CoursePackagePreviousCardArgs = {
  currentCardId: Scalars['ID'];
};

export type CoursePackageConnection = {
  __typename?: 'CoursePackageConnection';
  count?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<CoursePackageEdge>>>;
  pageInfo: PageInfo;
};

export type CoursePackageEdge = {
  __typename?: 'CoursePackageEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CoursePackage>;
};

export type CoursePackageFilter = {
  courseTitle?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
};

export type CoursePackagePayload = {
  __typename?: 'CoursePackagePayload';
  coursePackage?: Maybe<CoursePackage>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type CoursePayload = {
  __typename?: 'CoursePayload';
  course?: Maybe<Course>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type CreateAssignmentInput = {
  content: Scalars['String'];
  coursePackageId: Scalars['ID'];
  pid: Scalars['ID'];
};

export type CreateBannerInput = {
  imageUrl: Scalars['String'];
  linkUrl?: Maybe<Scalars['String']>;
};

export type CreateCardExamInput = {
  cardId: Scalars['ID'];
  info: Array<Maybe<ExamInfoInput>>;
};

export type CreateCardInput = {
  content: Scalars['String'];
  fileType: FileType;
  name: Scalars['FileName'];
  packageId: Scalars['ID'];
  /** when create card in the root folder, you should ignore this field. */
  pid?: Maybe<Scalars['ID']>;
};

export type CreateCardVideoInput = {
  fileId: Scalars['String'];
  platform?: Maybe<VideoPlatform>;
};

export type CreateCourseInput = {
  assignmentPackageId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['ID']>;
  chargingLink?: Maybe<Scalars['String']>;
  contentPackageId: Scalars['ID'];
  cover: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  instructors?: Maybe<Scalars['String']>;
  isEnd?: Maybe<Scalars['Boolean']>;
  isFee: Scalars['Boolean'];
  isOnline?: Maybe<Scalars['Boolean']>;
  isSelling: Scalars['Boolean'];
  labels: Array<Maybe<Scalars['String']>>;
  needUserProfile: Scalars['Boolean'];
  openDiscussion: Scalars['Boolean'];
  price?: Maybe<Scalars['Float']>;
  registrationBegin: Scalars['Date'];
  registrationEnd: Scalars['Date'];
  shortUrl: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateDiscussionInput = {
  content: Scalars['String'];
  coursePackageId: Scalars['ID'];
  labels?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['FileName'];
};

export type CreateLabelInput = {
  info?: Maybe<LabelInfoInput>;
  name: Scalars['String'];
  scenes: Array<Maybe<Scalars['String']>>;
};

/** Create a master package */
export type CreatePackageInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreatePurchaseInput = {
  courseId: Scalars['ID'];
  from: PurchaseFrom;
  price?: Maybe<Scalars['Float']>;
  userId: Scalars['ID'];
};

export type CreateUserInput = {
  avatar: Scalars['String'];
  nickname: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  role: UserRole;
};

export type CreateUserProfileInput = {
  address: Scalars['String'];
  birth: Scalars['Date'];
  company?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fieldOfStudy?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  headline?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  info?: Maybe<UserProfileInfoInput>;
  name: Scalars['String'];
  omcRegion: Scalars['String'];
  school?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  wechatNickname: Scalars['String'];
  wechatUsername: Scalars['String'];
  works?: Maybe<Array<Maybe<PersonalWorkInput>>>;
};

export type DeleteAssignmentInput = {
  id: Scalars['ID'];
};

export type DeleteBannerInput = {
  id: Scalars['ID'];
};

export type DeleteCardCommentInput = {
  id: Scalars['ID'];
};

export type DeleteCardFromAllBranchesInput = {
  id: Scalars['ID'];
};

export type DeleteCardInput = {
  id: Scalars['ID'];
};

export type DeleteCardPayload = {
  __typename?: 'DeleteCardPayload';
  card?: Maybe<Card>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type DeleteCardsInput = {
  ids: Array<Scalars['ID']>;
  packageId: Scalars['ID'];
};

export type DeleteCourseInput = {
  id: Scalars['ID'];
};

export type DeleteCoursePackageInput = {
  id: Scalars['ID'];
};

export type DeleteCoursePayload = {
  __typename?: 'DeleteCoursePayload';
  course?: Maybe<Course>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type DeleteDiscussionInput = {
  id: Scalars['ID'];
};

export type DeleteLabelInput = {
  id: Scalars['ID'];
};

/** TODO: Delete the master package and its branch packages.(collaborator can't delete the branch package by himself) */
export type DeletePackageInput = {
  id: Scalars['ID'];
};

export type DeletePackagePayload = {
  __typename?: 'DeletePackagePayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  package?: Maybe<Package>;
};

export type DeletePayload = {
  __typename?: 'DeletePayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type DeleteUserInput = {
  id: Scalars['ID'];
};

/** Filter by owner's phone */
export type Difference = {
  __typename?: 'Difference';
  changes?: Maybe<Scalars['String']>;
};

export enum DiffType {
  BranchFetchMaster = 'BRANCH_FETCH_MASTER',
  BranchSubmitPr = 'BRANCH_SUBMIT_PR',
  MasterMergeBranch = 'MASTER_MERGE_BRANCH'
}

export type Discussion = CardInfo &
  Node & {
    __typename?: 'Discussion';
    author?: Maybe<User>;
    comments?: Maybe<CardCommentConnection>;
    commentsCount?: Maybe<Scalars['Int']>;
    content?: Maybe<Scalars['String']>;
    highlightingContent?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** The ID of an object */
    id: Scalars['ID'];
    insertedAt?: Maybe<Scalars['Date']>;
    isCollected?: Maybe<Scalars['Boolean']>;
    labels?: Maybe<Array<Maybe<Label>>>;
    location?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    package?: Maybe<Package>;
    status?: Maybe<Scalars['Boolean']>;
  };

export type DiscussionCommentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DiscussionIsCollectedArgs = {
  courseId: Scalars['ID'];
};

export type DiscussionConnection = {
  __typename?: 'DiscussionConnection';
  count?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<DiscussionEdge>>>;
  pageInfo: PageInfo;
};

export type DiscussionEdge = {
  __typename?: 'DiscussionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Discussion>;
};

export type DiscussionFilter = {
  iParticipated?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['Boolean'];
};

export enum DiscussionOrder {
  DescCommentsAt = 'DESC_COMMENTS_AT',
  DescCommentsCount = 'DESC_COMMENTS_COUNT',
  DescInsertedAt = 'DESC_INSERTED_AT'
}

export type DiscussionPayload = {
  __typename?: 'DiscussionPayload';
  discussion?: Maybe<Discussion>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type ExamInfoInput = {
  answers: Array<Maybe<Scalars['String']>>;
  isCorrect: Scalars['Boolean'];
  question: Scalars['String'];
};

export type FailedFile = {
  __typename?: 'FailedFile';
  location?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum FileType {
  File = 'FILE',
  Folder = 'FOLDER'
}

export type ForkPackageToInput = {
  packageId: Scalars['ID'];
  toUserId: Scalars['ID'];
};

/** An error encountered trying to persist input */
export type InputError = {
  __typename?: 'InputError';
  code?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  message: Scalars['String'];
};

export type Label = Node & {
  __typename?: 'Label';
  /** The ID of an object */
  id: Scalars['ID'];
  info?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  scenes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LabelInfoInput = {
  color: Scalars['String'];
};

export type LabelPayload = {
  __typename?: 'LabelPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  label?: Maybe<Label>;
};

export type LearningCourseFilter = {
  isOnline?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  collegiateCategory?: Maybe<Scalars['String']>;
  courseCategory?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  registrationBegin?: Maybe<Scalars['Date']>;
  shortUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MergeInput = {
  currentPackageId: Scalars['ID'];
  /** If this field is null, then the merge type is default as branch_merge_master. */
  targetPackageId?: Maybe<Scalars['ID']>;
};

export type MergePayload = {
  __typename?: 'MergePayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  merger?: Maybe<Merger>;
};

export type Merger = {
  __typename?: 'Merger';
  conflicts?: Maybe<Array<Maybe<Conflict>>>;
  package?: Maybe<Package>;
};

export type Message = Node & {
  __typename?: 'Message';
  category?: Maybe<MessageCategory>;
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  info?: Maybe<Scalars['Json']>;
  insertedAt?: Maybe<Scalars['Date']>;
  poster?: Maybe<User>;
  state?: Maybe<MessageState>;
  toUser?: Maybe<User>;
};

export enum MessageCategory {
  Collaborative = 'COLLABORATIVE',
  Comment = 'COMMENT',
  Directional = 'DIRECTIONAL'
}

export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges?: Maybe<Array<Maybe<MessageEdge>>>;
  pageInfo: PageInfo;
  unreadCount?: Maybe<Scalars['Int']>;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Message>;
};

export type MessageFilter = {
  category?: Maybe<MessageCategory>;
  state?: Maybe<MessageState>;
};

export type MessagePayload = {
  __typename?: 'MessagePayload';
  errors?: Maybe<InputError>;
  message?: Maybe<Message>;
};

export enum MessageState {
  Created = 'CREATED',
  Viewed = 'VIEWED'
}

export type ModifiedConflict = {
  /** The modified content. */
  content: Scalars['String'];
  location: Scalars['String'];
};

export type ModifyCardsInput = {
  contents: Array<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  packageId: Scalars['ID'];
};

export type MoveCardsInput = {
  ids: Array<Scalars['ID']>;
  /** when you move files to the root folder, you should ignore this field */
  newFolderId: Scalars['UUID4'];
  newPackageId: Scalars['ID'];
  /** If you want to move the folder and file in the same time, should input this field */
  oldFolderId?: Maybe<Scalars['UUID4']>;
  packageId: Scalars['ID'];
};

export type MoveCardsPayload = {
  __typename?: 'MoveCardsPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  /** The new folder */
  folder?: Maybe<Card>;
  package?: Maybe<Package>;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
};

export type Notification = {
  __typename?: 'Notification';
  content?: Maybe<Scalars['String']>;
  insertedAt?: Maybe<Scalars['Date']>;
  unsuccessfulPhones?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OpenOrCloseDiscussionPackageInput = {
  courseId: Scalars['ID'];
  status?: Maybe<Scalars['Boolean']>;
};

export type OrderedCardsPayload = {
  __typename?: 'OrderedCardsPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type Package = Node & {
  __typename?: 'Package';
  branch?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hasFolderCard?: Maybe<Scalars['Boolean']>;
  /** The ID of an object */
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  rootCards?: Maybe<CardConnection>;
};

export type PackageRootCardsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PackageConnection = {
  __typename?: 'PackageConnection';
  edges?: Maybe<Array<Maybe<PackageEdge>>>;
  pageInfo: PageInfo;
};

export type PackageEdge = {
  __typename?: 'PackageEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Package>;
};

export type PackageFilter = {
  isMaster?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type PackagePayload = {
  __typename?: 'PackagePayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  package?: Maybe<Package>;
};

export enum PackageTag {
  Assignment = 'ASSIGNMENT',
  Content = 'CONTENT',
  Discussion = 'DISCUSSION'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PersonalWorkInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url: Scalars['String'];
};

export type Purchase = Node & {
  __typename?: 'Purchase';
  course?: Maybe<Course>;
  from?: Maybe<PurchaseFrom>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type PurchaseConnection = {
  __typename?: 'PurchaseConnection';
  count?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<PurchaseEdge>>>;
  pageInfo: PageInfo;
};

export type PurchaseEdge = {
  __typename?: 'PurchaseEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Purchase>;
};

export type PurchaseFilter = {
  courseTitle?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export enum PurchaseFrom {
  Admin = 'ADMIN',
  Web = 'WEB',
  Youzan = 'YOUZAN'
}

export type PurchasePayload = {
  __typename?: 'PurchasePayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  purchase?: Maybe<Purchase>;
};

export type RegisterUserInput = {
  authCode: Scalars['String'];
  avatar: Scalars['String'];
  nickname: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type RejectPrInput = {
  /** Why reject the pr. */
  prMessageId: Scalars['ID'];
  why: Scalars['String'];
};

export type RejectPrPayload = {
  __typename?: 'RejectPrPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  package?: Maybe<Package>;
};

export type RemoveCommentFromAssignmentInput = {
  assignmentId: Scalars['ID'];
  commentId: Scalars['ID'];
  coursePackageId: Scalars['ID'];
};

export type RemoveCommentFromDiscussionInput = {
  commentId: Scalars['ID'];
  coursePackageId: Scalars['ID'];
  discussionId: Scalars['ID'];
};

export type RemoveLabelsFromCardInput = {
  id: Scalars['ID'];
  labelIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type RemoveScenesFromLabelInput = {
  id?: Maybe<Scalars['ID']>;
  scenes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RenameCardInput = {
  id: Scalars['ID'];
  newName: Scalars['String'];
  packageId: Scalars['ID'];
};

export type ReplacePackageForCourseInput = {
  courseId: Scalars['ID'];
  packageId: Scalars['ID'];
  packageTag: RequiredPackageTag;
};

export enum RequiredPackageTag {
  Assignment = 'ASSIGNMENT',
  Content = 'CONTENT'
}

export type ResetPasswordInput = {
  authCode: Scalars['String'];
  newPassword: Scalars['String'];
  phone: Scalars['String'];
};

export type ResetUserPasswordInput = {
  newPassword: Scalars['String'];
  phone: Scalars['String'];
};

export type ResolveConflictsInput = {
  conflicts: Array<ModifiedConflict>;
  currentPackageId: Scalars['ID'];
  /** When current package's branch is not master, you should ignore this field. */
  targetPackageId?: Maybe<Scalars['ID']>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  deleteUser?: Maybe<UserPayload>;
  /** Update content card or assignment or assignment question */
  updateCardContent?: Maybe<CardPayload>;
  uncollectCard?: Maybe<CollectionPayload>;
  updateCardComment?: Maybe<CardCommentPayload>;
  createCardExam?: Maybe<CardExamPayload>;
  createAssignment?: Maybe<AssignmentPayload>;
  sendMessage?: Maybe<SendMessagePayload>;
  updateDiscussionStatus?: Maybe<DiscussionPayload>;
  sendNotification?: Maybe<SendNotificationPayload>;
  removeCommentFromAssignment?: Maybe<AssignmentPayload>;
  /** TODO: Delete the course package. */
  deleteCoursePackage?: Maybe<CoursePackagePayload>;
  /** TODO: modify the resolvers */
  createCourse?: Maybe<CoursePayload>;
  updateDiscussion?: Maybe<DiscussionPayload>;
  updateBanner?: Maybe<BannerPayload>;
  /** Delete a card from all branch */
  deleteCardFromAllBranches?: Maybe<DeleteCardPayload>;
  /** Creative reject the pr and send a message to the collaborator. */
  rejectPr?: Maybe<RejectPrPayload>;
  deleteAssignment?: Maybe<AssignmentPayload>;
  deleteCourse?: Maybe<DeleteCoursePayload>;
  deleteBanner?: Maybe<BannerPayload>;
  resetUserPassword?: Maybe<UserPayload>;
  /** Create content card or assignment or assignment question. */
  createCard?: Maybe<CardPayload>;
  /** Add labels to discussion card. */
  addLabelsToCard?: Maybe<CardPayload>;
  addCommentToDiscussion: DiscussionPayload;
  updateUserProfile?: Maybe<UserProfilePayload>;
  /** Sort card in folder. */
  sortFolder?: Maybe<OrderedCardsPayload>;
  /** Move cards to another place */
  moveCards?: Maybe<MoveCardsPayload>;
  resetPassword?: Maybe<UserPayload>;
  /** Copy cards to another place */
  copyCards?: Maybe<CopyCardsPayload>;
  sendAuthCode?: Maybe<SendAuthCodePayload>;
  /** Creative or Collaborator resolve the conflicts. */
  resolveConflicts?: Maybe<MergePayload>;
  updateAssignment?: Maybe<AssignmentPayload>;
  /** Replace the package for course. */
  replacePackageForCourse?: Maybe<CoursePackagePayload>;
  /** Rename a card */
  renameCard?: Maybe<CardPayload>;
  uploadZip?: Maybe<UploadZipPayload>;
  login?: Maybe<SessionPayload>;
  /** Collaborator submit a pr. */
  submitPr?: Maybe<SubmitPrPayload>;
  deleteLabel?: Maybe<LabelPayload>;
  deleteCardComment?: Maybe<CardComment>;
  deleteUserProfile?: Maybe<UserProfilePayload>;
  createUser?: Maybe<UserPayload>;
  sendEmail?: Maybe<SendEmailPayload>;
  refreshToken?: Maybe<SessionPayload>;
  addScenesToLabel?: Maybe<LabelPayload>;
  bulkCreatePurchases?: Maybe<BulkPurchasesPayload>;
  /** TODO: Update course package: add/update/remove sign, update status. */
  updateCoursePackage?: Maybe<CoursePackagePayload>;
  createPurchase?: Maybe<PurchasePayload>;
  registerUser?: Maybe<UserPayload>;
  modifyCards?: Maybe<CardsPayload>;
  /** Delete cards */
  deleteCards?: Maybe<DeletePayload>;
  /** Creative merge the pr from collaborator, or collaborator pull the master branch of the package. */
  merge?: Maybe<MergePayload>;
  createPackage?: Maybe<PackagePayload>;
  removeCommentFromDiscussion?: Maybe<DiscussionPayload>;
  updateUserPassword?: Maybe<UserPayload>;
  deletePackage?: Maybe<DeletePackagePayload>;
  collectCard?: Maybe<CollectionPayload>;
  updateLabel?: Maybe<LabelPayload>;
  createCardVideo?: Maybe<CardVideoPayload>;
  updatePurchase?: Maybe<PurchasePayload>;
  updateMessage?: Maybe<MessagePayload>;
  updatePackage?: Maybe<PackagePayload>;
  createUserProfile?: Maybe<UserProfilePayload>;
  /** Delete content card or assignment or assignment question */
  deleteCard?: Maybe<DeletePayload>;
  /** Open or close the discussion package. */
  openOrCloseDiscussionPackage?: Maybe<CoursePackagePayload>;
  /** Remove labels from discussion card. */
  removeLabelsFromCard?: Maybe<CardPayload>;
  /** Fork a package to another collaborator. */
  forkPackageTo?: Maybe<PackagePayload>;
  updateCourse?: Maybe<CoursePayload>;
  createBanner?: Maybe<BannerPayload>;
  addCommentToAssignment?: Maybe<AssignmentPayload>;
  deleteDiscussion?: Maybe<DiscussionPayload>;
  removeScenesFromLabel?: Maybe<LabelPayload>;
  createLabel?: Maybe<LabelPayload>;
  uploadCard?: Maybe<UploadCardPayload>;
  viewCard?: Maybe<ViewCardPayload>;
  updateUser?: Maybe<UserPayload>;
  createDiscussion?: Maybe<DiscussionPayload>;
  addTagToUsers?: Maybe<AddTagToUsersPayload>;
};

export type RootMutationTypeDeleteUserArgs = {
  input: DeleteUserInput;
};

export type RootMutationTypeUpdateCardContentArgs = {
  input: UpdateCardContentInput;
};

export type RootMutationTypeUncollectCardArgs = {
  input: UncollectCardInput;
};

export type RootMutationTypeUpdateCardCommentArgs = {
  input: UpdateCardCommentInput;
};

export type RootMutationTypeCreateCardExamArgs = {
  input: CreateCardExamInput;
};

export type RootMutationTypeCreateAssignmentArgs = {
  input: CreateAssignmentInput;
};

export type RootMutationTypeSendMessageArgs = {
  input: SendMessageInput;
};

export type RootMutationTypeUpdateDiscussionStatusArgs = {
  input: UpdateDiscussionStatusInput;
};

export type RootMutationTypeSendNotificationArgs = {
  input: SendNotificationInput;
};

export type RootMutationTypeRemoveCommentFromAssignmentArgs = {
  input: RemoveCommentFromAssignmentInput;
};

export type RootMutationTypeDeleteCoursePackageArgs = {
  input: DeleteCoursePackageInput;
};

export type RootMutationTypeCreateCourseArgs = {
  input: CreateCourseInput;
};

export type RootMutationTypeUpdateDiscussionArgs = {
  input: UpdateDiscussionInput;
};

export type RootMutationTypeUpdateBannerArgs = {
  input?: Maybe<UpdateBannerInput>;
};

export type RootMutationTypeDeleteCardFromAllBranchesArgs = {
  input: DeleteCardFromAllBranchesInput;
};

export type RootMutationTypeRejectPrArgs = {
  input: RejectPrInput;
};

export type RootMutationTypeDeleteAssignmentArgs = {
  input: DeleteAssignmentInput;
};

export type RootMutationTypeDeleteCourseArgs = {
  input: DeleteCourseInput;
};

export type RootMutationTypeDeleteBannerArgs = {
  input?: Maybe<DeleteBannerInput>;
};

export type RootMutationTypeResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type RootMutationTypeCreateCardArgs = {
  input: CreateCardInput;
};

export type RootMutationTypeAddLabelsToCardArgs = {
  input: AddLabelsToCardInput;
};

export type RootMutationTypeAddCommentToDiscussionArgs = {
  input: AddCommentToDiscussionInput;
};

export type RootMutationTypeUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

export type RootMutationTypeSortFolderArgs = {
  input: SortFolderInput;
};

export type RootMutationTypeMoveCardsArgs = {
  input: MoveCardsInput;
};

export type RootMutationTypeResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type RootMutationTypeCopyCardsArgs = {
  input: CopyCardsInput;
};

export type RootMutationTypeSendAuthCodeArgs = {
  input: SendAuthCodeInput;
};

export type RootMutationTypeResolveConflictsArgs = {
  input: ResolveConflictsInput;
};

export type RootMutationTypeUpdateAssignmentArgs = {
  input: UpdateAssignmentInput;
};

export type RootMutationTypeReplacePackageForCourseArgs = {
  input: ReplacePackageForCourseInput;
};

export type RootMutationTypeRenameCardArgs = {
  input: RenameCardInput;
};

export type RootMutationTypeUploadZipArgs = {
  input: UploadZipInput;
  metadata: Scalars['Upload'];
};

export type RootMutationTypeLoginArgs = {
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type RootMutationTypeSubmitPrArgs = {
  input: SubmitPrInput;
};

export type RootMutationTypeDeleteLabelArgs = {
  input: DeleteLabelInput;
};

export type RootMutationTypeDeleteCardCommentArgs = {
  input: DeleteCardCommentInput;
};

export type RootMutationTypeCreateUserArgs = {
  input: CreateUserInput;
};

export type RootMutationTypeSendEmailArgs = {
  input: SendEmailInput;
};

export type RootMutationTypeRefreshTokenArgs = {
  token: Scalars['String'];
};

export type RootMutationTypeAddScenesToLabelArgs = {
  input: AddScenesToLabelInput;
};

export type RootMutationTypeBulkCreatePurchasesArgs = {
  input: BulkCreatePurchasesInput;
};

export type RootMutationTypeUpdateCoursePackageArgs = {
  input: UpdateCoursePackageInput;
};

export type RootMutationTypeCreatePurchaseArgs = {
  input: CreatePurchaseInput;
};

export type RootMutationTypeRegisterUserArgs = {
  input: RegisterUserInput;
};

export type RootMutationTypeModifyCardsArgs = {
  input: ModifyCardsInput;
};

export type RootMutationTypeDeleteCardsArgs = {
  input: DeleteCardsInput;
};

export type RootMutationTypeMergeArgs = {
  input: MergeInput;
};

export type RootMutationTypeCreatePackageArgs = {
  input: CreatePackageInput;
};

export type RootMutationTypeRemoveCommentFromDiscussionArgs = {
  input: RemoveCommentFromDiscussionInput;
};

export type RootMutationTypeUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};

export type RootMutationTypeDeletePackageArgs = {
  input: DeletePackageInput;
};

export type RootMutationTypeCollectCardArgs = {
  input: CollectCardInput;
};

export type RootMutationTypeUpdateLabelArgs = {
  input: UpdateLabelInput;
};

export type RootMutationTypeCreateCardVideoArgs = {
  input: CreateCardVideoInput;
};

export type RootMutationTypeUpdatePurchaseArgs = {
  input: UpdatePurchaseInput;
};

export type RootMutationTypeUpdateMessageArgs = {
  input?: Maybe<UpdateMessageInput>;
};

export type RootMutationTypeUpdatePackageArgs = {
  input: UpdatePackageInput;
};

export type RootMutationTypeCreateUserProfileArgs = {
  input: CreateUserProfileInput;
};

export type RootMutationTypeDeleteCardArgs = {
  input: DeleteCardInput;
};

export type RootMutationTypeOpenOrCloseDiscussionPackageArgs = {
  input: OpenOrCloseDiscussionPackageInput;
};

export type RootMutationTypeRemoveLabelsFromCardArgs = {
  input: RemoveLabelsFromCardInput;
};

export type RootMutationTypeForkPackageToArgs = {
  input: ForkPackageToInput;
};

export type RootMutationTypeUpdateCourseArgs = {
  input: UpdateCourseInput;
};

export type RootMutationTypeCreateBannerArgs = {
  input?: Maybe<CreateBannerInput>;
};

export type RootMutationTypeAddCommentToAssignmentArgs = {
  input: AddCommentToAssignmentInput;
};

export type RootMutationTypeDeleteDiscussionArgs = {
  input: DeleteDiscussionInput;
};

export type RootMutationTypeRemoveScenesFromLabelArgs = {
  input: RemoveScenesFromLabelInput;
};

export type RootMutationTypeCreateLabelArgs = {
  input: CreateLabelInput;
};

export type RootMutationTypeUploadCardArgs = {
  input: UploadCardInput;
  metadata: Scalars['Upload'];
};

export type RootMutationTypeViewCardArgs = {
  input: ViewCardInput;
};

export type RootMutationTypeUpdateUserArgs = {
  input: UpdateUserInput;
};

export type RootMutationTypeCreateDiscussionArgs = {
  input: CreateDiscussionInput;
};

export type RootMutationTypeAddTagToUsersArgs = {
  input: AddTagToUsersInput;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  banners?: Maybe<Array<Maybe<Banner>>>;
  /** A list of card videos(for admin). */
  cardVideos?: Maybe<CardVideoConnection>;
  /** A list of cards that matched the search query. */
  cards?: Maybe<Array<Maybe<Card>>>;
  /** A list of cards that user collected of a course. */
  collections?: Maybe<CollectionConnection>;
  /** A list of course categories. */
  courseCategories?: Maybe<Array<Maybe<CourseCategory>>>;
  /** A list of course_packages(for admin). */
  coursePackages?: Maybe<CoursePackageConnection>;
  /** A list of courses. */
  courses?: Maybe<CourseConnection>;
  /** A list of courses that user created. */
  createdCourses?: Maybe<CourseConnection>;
  /** The changes between current package and target package */
  diff?: Maybe<Difference>;
  /** A list of discussions(for admin). */
  discussions?: Maybe<DiscussionConnection>;
  /** A list of @users. */
  fetchAtUsers?: Maybe<Array<Maybe<AtUser>>>;
  /** A list of labels */
  labels?: Maybe<Array<Maybe<Label>>>;
  /** Fetch the current user. */
  me?: Maybe<User>;
  /** A list of menu items. */
  menu?: Maybe<Array<Maybe<MenuItem>>>;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** A list packages that you created or forked. */
  packages?: Maybe<PackageConnection>;
  purchases?: Maybe<PurchaseConnection>;
  /** A list of cards that matched by name or filetype. */
  searchCards?: Maybe<Array<Maybe<Card>>>;
  /** Search card inside folder card. */
  searchCardsInside?: Maybe<Array<Maybe<Card>>>;
  /** Search the package by something. */
  searchPackage?: Maybe<Array<Maybe<Package>>>;
  /** A list of cards for admin to sign a course package. */
  signCards?: Maybe<CardConnection>;
  /** A list of courses that current user bought. */
  subscribedCourses?: Maybe<CourseConnection>;
  /** A list of users */
  users?: Maybe<UserConnection>;
};

export type RootQueryTypeBannersArgs = {
  order?: Maybe<SortOrder>;
};

export type RootQueryTypeCardVideosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CardVideoFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
};

export type RootQueryTypeCardsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  packageId: Scalars['ID'];
};

export type RootQueryTypeCollectionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  courseId: Scalars['ID'];
  filter?: Maybe<CollectionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  matching?: Maybe<Scalars['String']>;
  order: CollectionOrder;
};

export type RootQueryTypeCoursePackagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CoursePackageFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
};

export type RootQueryTypeCoursesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CourseFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
};

export type RootQueryTypeCreatedCoursesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<TeachingCourseFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
};

export type RootQueryTypeDiffArgs = {
  currentPackageId: Scalars['ID'];
  diffType: DiffType;
  targetPackageId?: Maybe<Scalars['ID']>;
};

export type RootQueryTypeDiscussionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AdminDiscussionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
};

export type RootQueryTypeFetchAtUsersArgs = {
  coursePackageId: Scalars['ID'];
  partialNickname: Scalars['String'];
};

export type RootQueryTypeMenuArgs = {
  isOnline?: Maybe<Scalars['Boolean']>;
  isSelling?: Maybe<Scalars['Boolean']>;
};

export type RootQueryTypeNodeArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypePackagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<PackageFilter>;
  first?: Maybe<Scalars['Int']>;
  ignoreDiscussion?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
};

export type RootQueryTypePurchasesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<PurchaseFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
};

export type RootQueryTypeSearchCardsArgs = {
  coursePackageId: Scalars['ID'];
  filter: SearchFilter;
  matching?: Maybe<Scalars['String']>;
};

export type RootQueryTypeSearchCardsInsideArgs = {
  folderCardId?: Maybe<Scalars['ID']>;
  matching: Scalars['String'];
  packageId: Scalars['ID'];
};

export type RootQueryTypeSearchPackageArgs = {
  filter?: Maybe<SearchPackageFilter>;
  ignoreDiscussion?: Maybe<Scalars['Boolean']>;
};

export type RootQueryTypeSignCardsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<SignCardFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  packageId: Scalars['ID'];
};

export type RootQueryTypeSubscribedCoursesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<LearningCourseFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
};

export type RootQueryTypeUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  newMessage?: Maybe<Message>;
};

export type SearchFilter = {
  cardTag?: Maybe<CardTag>;
  fileType?: Maybe<FileType>;
};

export type SearchPackageFilter = {
  isMaster?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type SendAuthCodeInput = {
  phone: Scalars['String'];
};

export type SendAuthCodePayload = {
  __typename?: 'SendAuthCodePayload';
  auth?: Maybe<AuthCodeResult>;
  errors?: Maybe<AuthCodeErrors>;
};

export type SendEmailInput = {
  emails: Array<Scalars['String']>;
  templateId: Scalars['String'];
};

export type SendEmailPayload = {
  __typename?: 'SendEmailPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type SendMessageInput = {
  phones: Array<Scalars['String']>;
  templateId: Scalars['Int'];
};

export type SendMessagePayload = {
  __typename?: 'SendMessagePayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type SendNotificationInput = {
  content: Scalars['String'];
  phones: Array<Scalars['String']>;
};

export type SendNotificationPayload = {
  __typename?: 'SendNotificationPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  notification?: Maybe<Notification>;
};

export type Session = {
  __typename?: 'Session';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type SessionPayload = {
  __typename?: 'SessionPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  session?: Maybe<Session>;
};

export type SignCardFilter = {
  name?: Maybe<Scalars['String']>;
};

export type SortFolderInput = {
  fileNames: Array<Scalars['String']>;
  folderId?: Maybe<Scalars['ID']>;
  /** The folder card id. */
  packageId: Scalars['ID'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SubmitPrInput = {
  currentPackageId: Scalars['ID'];
  /** The description that why you submit pr. */
  why: Scalars['String'];
};

export type SubmitPrPayload = {
  __typename?: 'SubmitPrPayload';
  /** If the last pr not merged or rejected, you can't submit again. */
  errors?: Maybe<Array<Maybe<InputError>>>;
  package?: Maybe<Package>;
};

export type TeachingCourseFilter = {
  title?: Maybe<Scalars['String']>;
};

export type UncollectCardInput = {
  cardId: Scalars['ID'];
  courseId: Scalars['ID'];
};

export type UpdateAssignmentInput = {
  content?: Maybe<Scalars['String']>;
  coursePackageId: Scalars['ID'];
  id: Scalars['ID'];
};

export type UpdateBannerInput = {
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
};

export type UpdateCardCommentInput = {
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateCardContentInput = {
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateCourseInput = {
  categoryId?: Maybe<Scalars['ID']>;
  chargingLink?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructors?: Maybe<Scalars['String']>;
  isEnd?: Maybe<Scalars['Boolean']>;
  isFee?: Maybe<Scalars['Boolean']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  isSelling?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  needUserProfile?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  registrationBegin?: Maybe<Scalars['Date']>;
  registrationEnd?: Maybe<Scalars['Date']>;
  shortUrl?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Update course package: add/update/remove sign, update status. */
export type UpdateCoursePackageInput = {
  id: Scalars['ID'];
  signCardId?: Maybe<Scalars['UUID4']>;
  status?: Maybe<Scalars['String']>;
};

export type UpdateDiscussionInput = {
  content?: Maybe<Scalars['String']>;
  coursePackageId: Scalars['ID'];
  id: Scalars['ID'];
  labels?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name?: Maybe<Scalars['FileName']>;
  /** open or close the discussion. */
  status?: Maybe<Scalars['Boolean']>;
};

export type UpdateDiscussionStatusInput = {
  id: Scalars['ID'];
  status: Scalars['Boolean'];
};

export type UpdateLabelInput = {
  info?: Maybe<LabelInfoInput>;
  name?: Maybe<Scalars['String']>;
  scenes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateMessageInput = {
  id: Scalars['ID'];
};

export type UpdatePackageInput = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type UpdatePurchaseInput = {
  id: Scalars['ID'];
  status?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  avatar?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
};

export type UpdateUserPasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  phone: Scalars['String'];
};

export type UpdateUserProfileInput = {
  address?: Maybe<Scalars['String']>;
  birth?: Maybe<Scalars['Date']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fieldOfStudy?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  info?: Maybe<UserProfileInfoInput>;
  name?: Maybe<Scalars['String']>;
  omcRegion?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  wechatNickname?: Maybe<Scalars['String']>;
  wechatUsername?: Maybe<Scalars['String']>;
  works?: Maybe<Array<Maybe<PersonalWorkInput>>>;
};

export type UploadCardInput = {
  /** if you want to upload a card into the root folder, you should ignore this field */
  folderId?: Maybe<Scalars['ID']>;
  packageId: Scalars['ID'];
};

export type UploadCardPayload = {
  __typename?: 'UploadCardPayload';
  card?: Maybe<Card>;
  errors?: Maybe<Array<Maybe<InputError>>>;
};

export type UploadZipInput = {
  folderId?: Maybe<Scalars['ID']>;
  packageId: Scalars['ID'];
};

export type UploadZipPayload = {
  __typename?: 'UploadZipPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  failedFiles?: Maybe<Array<Maybe<FailedFile>>>;
  folder?: Maybe<Card>;
  package?: Maybe<Package>;
};

export type User = Node & {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['Date']>;
  isDef?: Maybe<Scalars['Boolean']>;
  messages?: Maybe<MessageConnection>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  userProfile?: Maybe<UserProfile>;
};

export type UserMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<MessageFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  count?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<User>;
};

export type UserFilter = {
  /** partial nickname */
  nickname?: Maybe<Scalars['String']>;
  /** partial phone */
  phone?: Maybe<Scalars['String']>;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  user?: Maybe<User>;
};

export type UserProfile = Node & {
  __typename?: 'UserProfile';
  address?: Maybe<Scalars['String']>;
  birth?: Maybe<Scalars['Date']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fieldOfStudy?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  industry?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['Json']>;
  isProfileComplete?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  omcRegion?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  wechatNickname?: Maybe<Scalars['String']>;
  wechatUsername?: Maybe<Scalars['String']>;
  works?: Maybe<Scalars['Json']>;
};

export type UserProfileInfoInput = {
  blogUrl?: Maybe<Scalars['String']>;
  doubanUrl?: Maybe<Scalars['String']>;
  githubUrl?: Maybe<Scalars['String']>;
};

export type UserProfilePayload = {
  __typename?: 'UserProfilePayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  userProfile?: Maybe<UserProfile>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Collaborator = 'COLLABORATOR',
  Creative = 'CREATIVE',
  User = 'USER'
}

export enum VideoPlatform {
  Qcloud = 'QCLOUD'
}

export type ViewCardInput = {
  cardId: Scalars['ID'];
  coursePackageId: Scalars['ID'];
  visitDuration: Scalars['Float'];
};

export type ViewCardPayload = {
  __typename?: 'ViewCardPayload';
  errors?: Maybe<Array<Maybe<InputError>>>;
  learningRecord?: Maybe<CardLearningRecord>;
};
export type GetCourseDetailNQueryVariables = {
  id: Scalars['ID'];
};

export type GetCourseDetailNQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Course' } & Pick<
      Course,
      | 'chargingLink'
      | 'cover'
      | 'description'
      | 'id'
      | 'instructors'
      | 'isEnd'
      | 'isFee'
      | 'isOnline'
      | 'isSelling'
      | 'needUserProfile'
      | 'price'
      | 'registrationBegin'
      | 'registrationEnd'
      | 'shortUrl'
      | 'subTitle'
      | 'subscribedCount'
      | 'title'
    > & {
        category: Maybe<
          { __typename?: 'CourseCategory' } & {
            parent: Maybe<
              { __typename?: 'CourseCategory' } & Pick<CourseCategory, 'name'>
            >;
          }
        >;
      })
  >;
};

export type GetCourseDetailYQueryVariables = {
  id: Scalars['ID'];
};

export type GetCourseDetailYQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Course' } & Pick<
      Course,
      | 'chargingLink'
      | 'cover'
      | 'description'
      | 'id'
      | 'instructors'
      | 'isEnd'
      | 'isFee'
      | 'isOnline'
      | 'isSelling'
      | 'isSubscribed'
      | 'needUserProfile'
      | 'price'
      | 'registrationBegin'
      | 'registrationEnd'
      | 'shortUrl'
      | 'subTitle'
      | 'subscribedCount'
      | 'title'
    > & {
        category: Maybe<
          { __typename?: 'CourseCategory' } & {
            parent: Maybe<
              { __typename?: 'CourseCategory' } & Pick<CourseCategory, 'name'>
            >;
          }
        >;
      })
  >;
};

export type GetCourseSubscribedQueryVariables = {
  id: Scalars['ID'];
};

export type GetCourseSubscribedQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Course' } & Pick<Course, 'isSubscribed'>)
  >;
};

export type CreatePurchaseMutationVariables = {
  input: CreatePurchaseInput;
};

export type CreatePurchaseMutation = { __typename?: 'RootMutationType' } & {
  createPurchase: Maybe<
    { __typename?: 'PurchasePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type ListCollegesQueryVariables = {};

export type ListCollegesQuery = { __typename?: 'RootQueryType' } & {
  courseCategories: Maybe<
    Array<
      Maybe<
        { __typename?: 'CourseCategory' } & Pick<
          CourseCategory,
          'id' | 'name'
        > & {
            parent: Maybe<
              { __typename?: 'CourseCategory' } & Pick<CourseCategory, 'id'>
            >;
          }
      >
    >
  >;
};

export type ListCoursesQueryVariables = {
  filter?: Maybe<CourseFilter>;
  first?: Maybe<Scalars['Int']>;
};

export type ListCoursesQuery = { __typename?: 'RootQueryType' } & {
  courses: Maybe<
    { __typename?: 'CourseConnection' } & Pick<CourseConnection, 'count'> & {
        edges: Maybe<
          Array<
            Maybe<
              { __typename?: 'CourseEdge' } & Pick<CourseEdge, 'cursor'> & {
                  node: Maybe<
                    { __typename?: 'Course' } & Pick<
                      Course,
                      'title' | 'cover' | 'subscribedCount' | 'shortUrl'
                    >
                  >;
                }
            >
          >
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
      }
  >;
};

export type EduCreatePackageMutationVariables = {
  input: CreatePackageInput;
};

export type EduCreatePackageMutation = { __typename?: 'RootMutationType' } & {
  createPackage: Maybe<
    { __typename?: 'PackagePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
      package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id' | 'name'>>;
    }
  >;
};

export type EduCreateCardMutationVariables = {
  input: CreateCardInput;
};

export type EduCreateCardMutation = { __typename?: 'RootMutationType' } & {
  createCard: Maybe<
    { __typename?: 'CardPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
      card: Maybe<
        { __typename?: 'Card' } & Pick<Card, 'name' | 'id' | 'fileType'> & {
            parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
            package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id'>>;
          }
      >;
    }
  >;
};

export type GetEduManagerCoursesQueryVariables = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<TeachingCourseFilter>;
};

export type GetEduManagerCoursesQuery = { __typename?: 'RootQueryType' } & {
  createdCourses: Maybe<
    { __typename?: 'CourseConnection' } & Pick<CourseConnection, 'count'> & {
        edges: Maybe<
          Array<
            Maybe<
              { __typename?: 'CourseEdge' } & Pick<CourseEdge, 'cursor'> & {
                  node: Maybe<
                    { __typename?: 'Course' } & Pick<
                      Course,
                      | 'cover'
                      | 'title'
                      | 'subTitle'
                      | 'shortUrl'
                      | 'id'
                      | 'subscribedCount'
                    > & {
                        category: Maybe<
                          { __typename?: 'CourseCategory' } & Pick<
                            CourseCategory,
                            'name'
                          > & {
                              parent: Maybe<
                                { __typename?: 'CourseCategory' } & Pick<
                                  CourseCategory,
                                  'name'
                                >
                              >;
                            }
                        >;
                      }
                  >;
                }
            >
          >
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
        >;
      }
  >;
};

export type DeleteEduManagerCourseMutationVariables = {
  input: DeleteCourseInput;
};

export type DeleteEduManagerCourseMutation = {
  __typename?: 'RootMutationType';
} & {
  deleteCourse: Maybe<
    { __typename?: 'DeleteCoursePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type GetCourseInfoInEditQueryVariables = {
  id: Scalars['ID'];
};

export type GetCourseInfoInEditQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Course' } & Pick<
      Course,
      | 'chargingLink'
      | 'id'
      | 'cover'
      | 'description'
      | 'instructors'
      | 'isEnd'
      | 'isFee'
      | 'isOnline'
      | 'isSelling'
      | 'labels'
      | 'price'
      | 'registrationBegin'
      | 'registrationEnd'
      | 'shortUrl'
      | 'subTitle'
      | 'subscribedCount'
      | 'title'
      | 'needUserProfile'
    > & {
        category: Maybe<
          { __typename?: 'CourseCategory' } & Pick<
            CourseCategory,
            'id' | 'name'
          > & {
              parent: Maybe<
                { __typename?: 'CourseCategory' } & Pick<
                  CourseCategory,
                  'id' | 'name'
                >
              >;
            }
        >;
        coursesPackages: Maybe<
          Array<
            Maybe<
              { __typename?: 'CoursePackage' } & Pick<
                CoursePackage,
                'id' | 'packageTag' | 'status'
              > & {
                  package: Maybe<
                    { __typename?: 'Package' } & Pick<Package, 'name'>
                  >;
                }
            >
          >
        >;
      })
  >;
};

export type GetCategoryInfoInEditQueryVariables = {};

export type GetCategoryInfoInEditQuery = { __typename?: 'RootQueryType' } & {
  courseCategories: Maybe<
    Array<
      Maybe<
        { __typename?: 'CourseCategory' } & Pick<
          CourseCategory,
          'id' | 'name'
        > & {
            parent: Maybe<
              { __typename?: 'CourseCategory' } & Pick<
                CourseCategory,
                'id' | 'name'
              >
            >;
          }
      >
    >
  >;
};

export type CreateCourseMutationVariables = {
  input: CreateCourseInput;
};

export type CreateCourseMutation = { __typename?: 'RootMutationType' } & {
  createCourse: Maybe<
    { __typename?: 'CoursePayload' } & {
      course: Maybe<{ __typename?: 'Course' } & Pick<Course, 'title'>>;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type UpdateCourseMutationVariables = {
  input: UpdateCourseInput;
};

export type UpdateCourseMutation = { __typename?: 'RootMutationType' } & {
  updateCourse: Maybe<
    { __typename?: 'CoursePayload' } & {
      course: Maybe<{ __typename?: 'Course' } & Pick<Course, 'title'>>;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type OpenCloseDiscussionMutationVariables = {
  input: OpenOrCloseDiscussionPackageInput;
};

export type OpenCloseDiscussionMutation = {
  __typename?: 'RootMutationType';
} & {
  openOrCloseDiscussionPackage: Maybe<
    { __typename?: 'CoursePackagePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type ReplacePackageForCourseMutationVariables = {
  input: ReplacePackageForCourseInput;
};

export type ReplacePackageForCourseMutation = {
  __typename?: 'RootMutationType';
} & {
  replacePackageForCourse: Maybe<
    { __typename?: 'CoursePackagePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type DeleteCoursePackageMutationVariables = {
  input: DeleteCoursePackageInput;
};

export type DeleteCoursePackageMutation = {
  __typename?: 'RootMutationType';
} & {
  deleteCoursePackage: Maybe<
    { __typename?: 'CoursePackagePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type GetEduCreatedCoursePackagesQueryVariables = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<PackageFilter>;
};

export type GetEduCreatedCoursePackagesQuery = {
  __typename?: 'RootQueryType';
} & {
  packages: Maybe<
    { __typename?: 'PackageConnection' } & {
      edges: Maybe<
        Array<
          Maybe<
            { __typename?: 'PackageEdge' } & Pick<PackageEdge, 'cursor'> & {
                node: Maybe<
                  { __typename?: 'Package' } & Pick<Package, 'name' | 'id'>
                >;
              }
          >
        >
      >;
    }
  >;
};

export type CreateCardMutationVariables = {
  input: CreateCardInput;
};

export type CreateCardMutation = { __typename?: 'RootMutationType' } & {
  createCard: Maybe<
    { __typename?: 'CardPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
      card: Maybe<
        { __typename?: 'Card' } & Pick<Card, 'id' | 'name'> & {
            package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id'>>;
            parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
          }
      >;
    }
  >;
};

export type ModifyCardMutationVariables = {
  input: UpdateCardContentInput;
};

export type ModifyCardMutation = { __typename?: 'RootMutationType' } & {
  updateCardContent: Maybe<
    { __typename?: 'CardPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
      card: Maybe<
        { __typename?: 'Card' } & Pick<Card, 'id' | 'name' | 'content'>
      >;
    }
  >;
};

export type DeleteCardMutationVariables = {
  input: DeleteCardsInput;
};

export type DeleteCardMutation = { __typename?: 'RootMutationType' } & {
  deleteCards: Maybe<
    { __typename?: 'DeletePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type GetCardInfoQueryVariables = {
  id: Scalars['ID'];
};

export type GetCardInfoQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Card' } & Pick<Card, 'id' | 'name' | 'sourceContent'>)
  >;
};

export type GetCardInfoContentQueryVariables = {
  id: Scalars['ID'];
};

export type GetCardInfoContentQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Card' } & Pick<Card, 'id' | 'name' | 'content'>)
  >;
};

export type ModifyCardsMutationVariables = {
  input: ModifyCardsInput;
};

export type ModifyCardsMutation = { __typename?: 'RootMutationType' } & {
  modifyCards: Maybe<
    { __typename?: 'CardsPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type GetCardsForModifyQueryVariables = {
  ids?: Maybe<Array<Scalars['ID']>>;
  packageId: Scalars['ID'];
};

export type GetCardsForModifyQuery = { __typename?: 'RootQueryType' } & {
  cards: Maybe<
    Array<
      Maybe<
        { __typename?: 'Card' } & Pick<Card, 'id' | 'name' | 'sourceContent'>
      >
    >
  >;
};

export type GetEduCreatedPackagesQueryVariables = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<PackageFilter>;
};

export type GetEduCreatedPackagesQuery = { __typename?: 'RootQueryType' } & {
  packages: Maybe<
    { __typename?: 'PackageConnection' } & {
      edges: Maybe<
        Array<
          Maybe<
            { __typename?: 'PackageEdge' } & Pick<PackageEdge, 'cursor'> & {
                node: Maybe<
                  { __typename?: 'Package' } & Pick<
                    Package,
                    'name' | 'id' | 'hasFolderCard'
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type GetEduCreatedPackageInfoQueryVariables = {
  id: Scalars['ID'];
  isMaster: Scalars['Boolean'];
};

export type GetEduCreatedPackageInfoQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & (
      | ({ __typename?: 'Package' } & Pick<
          Package,
          'hasFolderCard' | 'name' | 'id'
        > & {
            rootCards: Maybe<
              { __typename?: 'CardConnection' } & {
                edges: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'CardEdge' } & {
                        node: Maybe<
                          { __typename?: 'Card' } & Pick<
                            Card,
                            | 'id'
                            | 'name'
                            | 'hasFolderChild'
                            | 'fileType'
                            | 'collaborativeState'
                          > & {
                              package: Maybe<
                                { __typename?: 'Package' } & Pick<Package, 'id'>
                              >;
                              parent: Maybe<
                                { __typename?: 'Card' } & Pick<Card, 'id'>
                              >;
                            }
                        >;
                      }
                    >
                  >
                >;
              }
            >;
          })
      | ({ __typename?: 'Card' } & Pick<
          Card,
          'name' | 'id' | 'fileType' | 'collaborativeState' | 'hasFolderChild'
        > & {
            package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id'>>;
            parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
            children: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Card' } & Pick<
                    Card,
                    | 'name'
                    | 'id'
                    | 'fileType'
                    | 'collaborativeState'
                    | 'hasFolderChild'
                  > & {
                      package: Maybe<
                        { __typename?: 'Package' } & Pick<Package, 'id'>
                      >;
                      parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
                    }
                >
              >
            >;
          }))
  >;
};

export type GetDataWithParentDataQueryVariables = {
  id: Scalars['ID'];
  isMaster: Scalars['Boolean'];
};

export type GetDataWithParentDataQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & (
      | ({ __typename?: 'Package' } & Pick<
          Package,
          'hasFolderCard' | 'name' | 'id'
        > & {
            rootCards: Maybe<
              { __typename?: 'CardConnection' } & {
                edges: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'CardEdge' } & {
                        node: Maybe<
                          { __typename?: 'Card' } & Pick<
                            Card,
                            | 'id'
                            | 'name'
                            | 'hasFolderChild'
                            | 'fileType'
                            | 'collaborativeState'
                          > & {
                              package: Maybe<
                                { __typename?: 'Package' } & Pick<Package, 'id'>
                              >;
                              parent: Maybe<
                                { __typename?: 'Card' } & Pick<Card, 'id'>
                              >;
                            }
                        >;
                      }
                    >
                  >
                >;
              }
            >;
          })
      | ({ __typename?: 'Card' } & Pick<
          Card,
          'name' | 'id' | 'fileType' | 'hasFolderChild' | 'collaborativeState'
        > & {
            package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id'>>;
            parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
            children: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Card' } & Pick<
                    Card,
                    | 'name'
                    | 'id'
                    | 'fileType'
                    | 'collaborativeState'
                    | 'hasFolderChild'
                  > & {
                      package: Maybe<
                        { __typename?: 'Package' } & Pick<Package, 'id'>
                      >;
                      parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
                    }
                >
              >
            >;
            ancestors: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Card' } & Pick<
                    Card,
                    | 'name'
                    | 'id'
                    | 'fileType'
                    | 'hasFolderChild'
                    | 'collaborativeState'
                    | 'depth'
                  > & {
                      children: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'Card' } & Pick<
                              Card,
                              'name' | 'id' | 'fileType' | 'hasFolderChild'
                            > & {
                                package: Maybe<
                                  { __typename?: 'Package' } & Pick<
                                    Package,
                                    'id'
                                  >
                                >;
                                parent: Maybe<
                                  { __typename?: 'Card' } & Pick<Card, 'id'>
                                >;
                              }
                          >
                        >
                      >;
                    }
                >
              >
            >;
          }))
  >;
};

export type GetSearchCardQueryVariables = {
  packageId: Scalars['ID'];
  matching: Scalars['String'];
  folderCardId?: Maybe<Scalars['ID']>;
};

export type GetSearchCardQuery = { __typename?: 'RootQueryType' } & {
  searchCardsInside: Maybe<
    Array<
      Maybe<{ __typename?: 'Card' } & Pick<Card, 'name' | 'id' | 'fileType'>>
    >
  >;
};

export type SortCardsMutationVariables = {
  input: SortFolderInput;
};

export type SortCardsMutation = { __typename?: 'RootMutationType' } & {
  sortFolder: Maybe<
    { __typename?: 'OrderedCardsPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type DeleteCardFromAllBranchesMutationVariables = {
  input: DeleteCardFromAllBranchesInput;
};

export type DeleteCardFromAllBranchesMutation = {
  __typename?: 'RootMutationType';
} & {
  deleteCardFromAllBranches: Maybe<
    { __typename?: 'DeleteCardPayload' } & {
      card: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type DeleteCardsMutationVariables = {
  input: DeleteCardsInput;
};

export type DeleteCardsMutation = { __typename?: 'RootMutationType' } & {
  deleteCards: Maybe<
    { __typename?: 'DeletePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type UploadCardMutationVariables = {
  input: UploadCardInput;
  metadata: Scalars['Upload'];
  isMaster: Scalars['Boolean'];
};

export type UploadCardMutation = { __typename?: 'RootMutationType' } & {
  uploadCard: Maybe<
    { __typename?: 'UploadCardPayload' } & {
      card: Maybe<
        { __typename?: 'Card' } & Pick<
          Card,
          'id' | 'name' | 'fileType' | 'collaborativeState'
        > & {
            package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id'>>;
            parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
          }
      >;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type UploadZipMutationVariables = {
  input: UploadZipInput;
  metadata: Scalars['Upload'];
  isMaster: Scalars['Boolean'];
};

export type UploadZipMutation = { __typename?: 'RootMutationType' } & {
  uploadZip: Maybe<
    { __typename?: 'UploadZipPayload' } & {
      failedFiles: Maybe<
        Array<
          Maybe<
            { __typename?: 'FailedFile' } & Pick<
              FailedFile,
              'location' | 'message'
            >
          >
        >
      >;
      folder: Maybe<
        { __typename?: 'Card' } & Pick<
          Card,
          'id' | 'fileType' | 'name' | 'hasFolderChild' | 'collaborativeState'
        > & {
            package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id'>>;
            parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
            children: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Card' } & Pick<
                    Card,
                    | 'name'
                    | 'id'
                    | 'fileType'
                    | 'hasFolderChild'
                    | 'collaborativeState'
                  > & {
                      package: Maybe<
                        { __typename?: 'Package' } & Pick<Package, 'id'>
                      >;
                      parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
                    }
                >
              >
            >;
          }
      >;
      package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id'>>;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type UploadZipToPackageRootMutationVariables = {
  input: UploadZipInput;
  metadata: Scalars['Upload'];
  isMaster: Scalars['Boolean'];
};

export type UploadZipToPackageRootMutation = {
  __typename?: 'RootMutationType';
} & {
  uploadZip: Maybe<
    { __typename?: 'UploadZipPayload' } & {
      failedFiles: Maybe<
        Array<
          Maybe<
            { __typename?: 'FailedFile' } & Pick<
              FailedFile,
              'location' | 'message'
            >
          >
        >
      >;
      package: Maybe<
        { __typename?: 'Package' } & Pick<
          Package,
          'id' | 'name' | 'hasFolderCard'
        > & {
            rootCards: Maybe<
              { __typename?: 'CardConnection' } & {
                edges: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'CardEdge' } & {
                        node: Maybe<
                          { __typename?: 'Card' } & Pick<
                            Card,
                            | 'id'
                            | 'name'
                            | 'hasFolderChild'
                            | 'collaborativeState'
                            | 'fileType'
                          > & {
                              package: Maybe<
                                { __typename?: 'Package' } & Pick<Package, 'id'>
                              >;
                              parent: Maybe<
                                { __typename?: 'Card' } & Pick<Card, 'id'>
                              >;
                            }
                        >;
                      }
                    >
                  >
                >;
              }
            >;
          }
      >;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type RenameCardMutationVariables = {
  input: RenameCardInput;
};

export type RenameCardMutation = { __typename?: 'RootMutationType' } & {
  renameCard: Maybe<
    { __typename?: 'CardPayload' } & {
      card: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id' | 'name'>>;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type RenamePackageMutationVariables = {
  input: UpdatePackageInput;
};

export type RenamePackageMutation = { __typename?: 'RootMutationType' } & {
  updatePackage: Maybe<
    { __typename?: 'PackagePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
      package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id' | 'name'>>;
    }
  >;
};

export type CopyCardsMutationVariables = {
  input: CopyCardsInput;
  isMaster: Scalars['Boolean'];
};

export type CopyCardsMutation = { __typename?: 'RootMutationType' } & {
  copyCards: Maybe<
    { __typename?: 'CopyCardsPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
      package: Maybe<
        { __typename?: 'Package' } & Pick<
          Package,
          'id' | 'name' | 'hasFolderCard'
        > & {
            rootCards: Maybe<
              { __typename?: 'CardConnection' } & {
                edges: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'CardEdge' } & {
                        node: Maybe<
                          { __typename?: 'Card' } & Pick<
                            Card,
                            | 'id'
                            | 'name'
                            | 'hasFolderChild'
                            | 'fileType'
                            | 'collaborativeState'
                          > & {
                              package: Maybe<
                                { __typename?: 'Package' } & Pick<Package, 'id'>
                              >;
                              parent: Maybe<
                                { __typename?: 'Card' } & Pick<Card, 'id'>
                              >;
                            }
                        >;
                      }
                    >
                  >
                >;
              }
            >;
          }
      >;
      folder: Maybe<
        { __typename?: 'Card' } & Pick<
          Card,
          'id' | 'fileType' | 'name' | 'hasFolderChild'
        > & {
            package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id'>>;
            parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
            children: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Card' } & Pick<
                    Card,
                    'name' | 'id' | 'fileType' | 'hasFolderChild'
                  > & {
                      package: Maybe<
                        { __typename?: 'Package' } & Pick<Package, 'id'>
                      >;
                      parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
                    }
                >
              >
            >;
          }
      >;
    }
  >;
};

export type MoveCardsMutationVariables = {
  input: MoveCardsInput;
  isMaster: Scalars['Boolean'];
};

export type MoveCardsMutation = { __typename?: 'RootMutationType' } & {
  moveCards: Maybe<
    { __typename?: 'MoveCardsPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
      package: Maybe<
        { __typename?: 'Package' } & Pick<
          Package,
          'id' | 'name' | 'hasFolderCard'
        > & {
            rootCards: Maybe<
              { __typename?: 'CardConnection' } & {
                edges: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'CardEdge' } & {
                        node: Maybe<
                          { __typename?: 'Card' } & Pick<
                            Card,
                            | 'id'
                            | 'name'
                            | 'collaborativeState'
                            | 'hasFolderChild'
                            | 'fileType'
                          > & {
                              package: Maybe<
                                { __typename?: 'Package' } & Pick<Package, 'id'>
                              >;
                              parent: Maybe<
                                { __typename?: 'Card' } & Pick<Card, 'id'>
                              >;
                            }
                        >;
                      }
                    >
                  >
                >;
              }
            >;
          }
      >;
      folder: Maybe<
        { __typename?: 'Card' } & Pick<
          Card,
          'id' | 'fileType' | 'name' | 'hasFolderChild' | 'collaborativeState'
        > & {
            package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id'>>;
            parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
            children: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Card' } & Pick<
                    Card,
                    | 'name'
                    | 'id'
                    | 'fileType'
                    | 'collaborativeState'
                    | 'hasFolderChild'
                  > & {
                      package: Maybe<
                        { __typename?: 'Package' } & Pick<Package, 'id'>
                      >;
                      parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
                    }
                >
              >
            >;
          }
      >;
    }
  >;
};

export type DeletePackageMutationVariables = {
  input: DeletePackageInput;
};

export type DeletePackageMutation = { __typename?: 'RootMutationType' } & {
  deletePackage: Maybe<
    { __typename?: 'DeletePackagePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type DiffChangesQueryVariables = {
  currentPackageId: Scalars['ID'];
  diffType: DiffType;
};

export type DiffChangesQuery = { __typename?: 'RootQueryType' } & {
  diff: Maybe<{ __typename?: 'Difference' } & Pick<Difference, 'changes'>>;
};

export type SubmitPullRequestMutationVariables = {
  input: SubmitPrInput;
};

export type SubmitPullRequestMutation = { __typename?: 'RootMutationType' } & {
  submitPr: Maybe<
    { __typename?: 'SubmitPrPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
      package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id' | 'name'>>;
    }
  >;
};

export type MergeMutationVariables = {
  input: MergeInput;
};

export type MergeMutation = { __typename?: 'RootMutationType' } & {
  merge: Maybe<
    { __typename?: 'MergePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
      merger: Maybe<
        { __typename?: 'Merger' } & {
          conflicts: Maybe<
            Array<
              Maybe<
                { __typename?: 'Conflict' } & Pick<
                  Conflict,
                  'content' | 'location'
                >
              >
            >
          >;
          package: Maybe<
            { __typename?: 'Package' } & Pick<Package, 'id' | 'name'>
          >;
        }
      >;
    }
  >;
};

export type ResolveConflictsMutationVariables = {
  input: ResolveConflictsInput;
};

export type ResolveConflictsMutation = { __typename?: 'RootMutationType' } & {
  resolveConflicts: Maybe<
    { __typename?: 'MergePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
      merger: Maybe<
        { __typename?: 'Merger' } & {
          conflicts: Maybe<
            Array<
              Maybe<
                { __typename?: 'Conflict' } & Pick<
                  Conflict,
                  'content' | 'location'
                >
              >
            >
          >;
          package: Maybe<
            { __typename?: 'Package' } & Pick<Package, 'id' | 'name'>
          >;
        }
      >;
    }
  >;
};

export type RejectPrMutationVariables = {
  input: RejectPrInput;
};

export type RejectPrMutation = { __typename?: 'RootMutationType' } & {
  rejectPr: Maybe<
    { __typename?: 'RejectPrPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
      package: Maybe<{ __typename?: 'Package' } & Pick<Package, 'id' | 'name'>>;
    }
  >;
};

export type ListCollegeCoursesQueryVariables = {
  filter?: Maybe<CourseFilter>;
  first?: Maybe<Scalars['Int']>;
};

export type ListCollegeCoursesQuery = { __typename?: 'RootQueryType' } & {
  courses: Maybe<
    { __typename?: 'CourseConnection' } & {
      edges: Maybe<
        Array<
          Maybe<
            { __typename?: 'CourseEdge' } & {
              node: Maybe<
                { __typename?: 'Course' } & Pick<
                  Course,
                  'title' | 'cover' | 'subscribedCount' | 'labels' | 'shortUrl'
                > & {
                    category: Maybe<
                      { __typename?: 'CourseCategory' } & Pick<
                        CourseCategory,
                        'name'
                      > & {
                          parent: Maybe<
                            { __typename?: 'CourseCategory' } & Pick<
                              CourseCategory,
                              'name'
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
          >
        >
      >;
    }
  >;
};

export type HomePageBannerQueryVariables = {};

export type HomePageBannerQuery = { __typename?: 'RootQueryType' } & {
  banners: Maybe<
    Array<
      Maybe<
        { __typename?: 'Banner' } & Pick<Banner, 'id' | 'imageUrl' | 'linkUrl'>
      >
    >
  >;
};

export type HomePageCoursesQueryVariables = {
  first: Scalars['Int'];
};

export type HomePageCoursesQuery = { __typename?: 'RootQueryType' } & {
  courses: Maybe<
    { __typename?: 'CourseConnection' } & {
      edges: Maybe<
        Array<
          Maybe<
            { __typename?: 'CourseEdge' } & {
              node: Maybe<
                { __typename?: 'Course' } & Pick<
                  Course,
                  | 'cover'
                  | 'description'
                  | 'id'
                  | 'instructors'
                  | 'isOnline'
                  | 'isSelling'
                  | 'subTitle'
                  | 'title'
                  | 'shortUrl'
                > & {
                    category: Maybe<
                      { __typename?: 'CourseCategory' } & Pick<
                        CourseCategory,
                        'id' | 'name'
                      > & {
                          parent: Maybe<
                            { __typename?: 'CourseCategory' } & Pick<
                              CourseCategory,
                              'id' | 'name'
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
          >
        >
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    }
  >;
};

export type HomePageMenuQueryVariables = {
  isOnline: Scalars['Boolean'];
};

export type HomePageMenuQuery = { __typename?: 'RootQueryType' } & {
  menu: Maybe<
    Array<
      Maybe<
        { __typename?: 'MenuItem' } & Pick<
          MenuItem,
          | 'collegiateCategory'
          | 'courseCategory'
          | 'isOnline'
          | 'registrationBegin'
          | 'title'
          | 'id'
          | 'shortUrl'
        >
      >
    >
  >;
};

export type RefreshTokenMutationVariables = {
  token: Scalars['String'];
};

export type RefreshTokenMutation = { __typename?: 'RootMutationType' } & {
  refreshToken: Maybe<
    { __typename?: 'SessionPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'message' | 'key'
            >
          >
        >
      >;
      session: Maybe<
        { __typename?: 'Session' } & Pick<Session, 'token'> & {
            user: Maybe<
              { __typename?: 'User' } & Pick<User, 'id' | 'nickname'>
            >;
          }
      >;
    }
  >;
};

export type GetCardProgressQueryVariables = {
  id: Scalars['ID'];
};

export type GetCardProgressQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'CoursePackage' } & Pick<
      CoursePackage,
      'fileCardsCount' | 'learnedCount'
    >)
  >;
};

export type UserLoginMutationVariables = {
  phone: Scalars['String'];
  password: Scalars['String'];
};

export type UserLoginMutation = { __typename?: 'RootMutationType' } & {
  login: Maybe<
    { __typename?: 'SessionPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
      session: Maybe<
        { __typename?: 'Session' } & Pick<Session, 'token'> & {
            user: Maybe<
              { __typename?: 'User' } & Pick<
                User,
                'isDef' | 'avatar' | 'id' | 'nickname' | 'phone' | 'role'
              > & {
                  userProfile: Maybe<
                    { __typename?: 'UserProfile' } & Pick<UserProfile, 'id'>
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type GetAuthCodeMutationVariables = {
  input: SendAuthCodeInput;
};

export type GetAuthCodeMutation = { __typename?: 'RootMutationType' } & {
  sendAuthCode: Maybe<
    { __typename?: 'SendAuthCodePayload' } & {
      auth: Maybe<
        { __typename?: 'AuthCodeResult' } & Pick<AuthCodeResult, 'phone'>
      >;
      errors: Maybe<
        { __typename?: 'AuthCodeErrors' } & Pick<
          AuthCodeErrors,
          'code' | 'message'
        >
      >;
    }
  >;
};

export type RegisterUserMutationVariables = {
  input: RegisterUserInput;
};

export type RegisterUserMutation = { __typename?: 'RootMutationType' } & {
  registerUser: Maybe<
    { __typename?: 'UserPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
      user: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          'avatar' | 'id' | 'nickname' | 'phone' | 'role'
        >
      >;
    }
  >;
};

export type ResetUserPasswordMutationVariables = {
  input: ResetPasswordInput;
};

export type ResetUserPasswordMutation = { __typename?: 'RootMutationType' } & {
  resetPassword: Maybe<
    { __typename?: 'UserPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'message' | 'key'
            >
          >
        >
      >;
    }
  >;
};

export type GetUserMessageQueryVariables = {
  filter?: Maybe<MessageFilter>;
  first?: Maybe<Scalars['Int']>;
};

export type GetUserMessageQuery = { __typename?: 'RootQueryType' } & {
  me: Maybe<
    { __typename?: 'User' } & {
      messages: Maybe<
        { __typename?: 'MessageConnection' } & {
          edges: Maybe<
            Array<
              Maybe<
                { __typename?: 'MessageEdge' } & {
                  node: Maybe<
                    { __typename?: 'Message' } & Pick<
                      Message,
                      'id' | 'info' | 'content' | 'state' | 'description'
                    > & {
                        poster: Maybe<
                          { __typename?: 'User' } & Pick<
                            User,
                            'avatar' | 'nickname'
                          >
                        >;
                      }
                  >;
                }
              >
            >
          >;
          pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
        }
      >;
    }
  >;
};

export type UpdateMessageMutationVariables = {
  input?: Maybe<UpdateMessageInput>;
};

export type UpdateMessageMutation = { __typename?: 'RootMutationType' } & {
  updateMessage: Maybe<
    { __typename?: 'MessagePayload' } & {
      errors: Maybe<
        { __typename?: 'InputError' } & Pick<
          InputError,
          'message' | 'key' | 'code'
        >
      >;
    }
  >;
};

export type GetParentIdQueryVariables = {
  id: Scalars['ID'];
};

export type GetParentIdQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Assignment' } & Pick<Assignment, 'pid'>)
  >;
};

export type DiffChangesInMessageQueryVariables = {
  currentPackageId: Scalars['ID'];
  targetPackageId?: Maybe<Scalars['ID']>;
  diffType: DiffType;
};

export type DiffChangesInMessageQuery = { __typename?: 'RootQueryType' } & {
  diff: Maybe<{ __typename?: 'Difference' } & Pick<Difference, 'changes'>>;
};

export type GetUserInfoQueryVariables = {};

export type GetUserInfoQuery = { __typename?: 'RootQueryType' } & {
  me: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      'avatar' | 'id' | 'nickname' | 'phone' | 'role'
    > & {
        userProfile: Maybe<
          { __typename?: 'UserProfile' } & Pick<
            UserProfile,
            | 'address'
            | 'birth'
            | 'company'
            | 'email'
            | 'fieldOfStudy'
            | 'gender'
            | 'headline'
            | 'hobbies'
            | 'id'
            | 'industry'
            | 'info'
            | 'name'
            | 'omcRegion'
            | 'school'
            | 'state'
            | 'wechatNickname'
            | 'wechatUsername'
            | 'works'
          >
        >;
      }
  >;
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput;
};

export type UpdateUserMutation = { __typename?: 'RootMutationType' } & {
  updateUser: Maybe<
    { __typename?: 'UserPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'message' | 'key' | 'code'
            >
          >
        >
      >;
    }
  >;
};

export type UpdateUserPasswordMutationVariables = {
  input: UpdateUserPasswordInput;
};

export type UpdateUserPasswordMutation = { __typename?: 'RootMutationType' } & {
  updateUserPassword: Maybe<
    { __typename?: 'UserPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'message' | 'key' | 'code'
            >
          >
        >
      >;
    }
  >;
};

export type UpdateUserProfileMutationVariables = {
  input: UpdateUserProfileInput;
};

export type UpdateUserProfileMutation = { __typename?: 'RootMutationType' } & {
  updateUserProfile: Maybe<
    { __typename?: 'UserProfilePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'message' | 'key' | 'code'
            >
          >
        >
      >;
    }
  >;
};

export type CreateUserProfileMutationVariables = {
  input: CreateUserProfileInput;
};

export type CreateUserProfileMutation = { __typename?: 'RootMutationType' } & {
  createUserProfile: Maybe<
    { __typename?: 'UserProfilePayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'message' | 'key' | 'code'
            >
          >
        >
      >;
    }
  >;
};

export type SubscribedCoursesQueryVariables = {
  first?: Maybe<Scalars['Int']>;
};

export type SubscribedCoursesQuery = { __typename?: 'RootQueryType' } & {
  subscribedCourses: Maybe<
    { __typename?: 'CourseConnection' } & Pick<CourseConnection, 'count'> & {
        edges: Maybe<
          Array<
            Maybe<
              { __typename?: 'CourseEdge' } & {
                node: Maybe<
                  { __typename?: 'Course' } & Pick<
                    Course,
                    | 'title'
                    | 'cover'
                    | 'shortUrl'
                    | 'isFinished'
                    | 'isOnline'
                    | 'isEnd'
                    | 'needUserProfile'
                  >
                >;
              }
            >
          >
        >;
      }
  >;
};

export type ContentCollectQueryVariables = {
  courseId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  order: CollectionOrder;
  filter?: Maybe<CollectionFilter>;
  matching?: Maybe<Scalars['String']>;
};

export type ContentCollectQuery = { __typename?: 'RootQueryType' } & {
  collections: Maybe<
    { __typename?: 'CollectionConnection' } & Pick<
      CollectionConnection,
      'count'
    > & {
        edges: Maybe<
          Array<
            Maybe<
              { __typename?: 'CollectionEdge' } & Pick<
                CollectionEdge,
                'cursor'
              > & {
                  node: Maybe<
                    { __typename?: 'Collection' } & Pick<
                      Collection,
                      | 'commentsCount'
                      | 'content'
                      | 'id'
                      | 'location'
                      | 'name'
                      | 'status'
                    > & {
                        author: Maybe<
                          { __typename?: 'User' } & Pick<
                            User,
                            'nickname' | 'avatar'
                          >
                        >;
                      }
                  >;
                }
            >
          >
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
        >;
      }
  >;
};

export type HomeworkCollectQueryVariables = {
  courseId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  order: CollectionOrder;
  filter?: Maybe<CollectionFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  matching?: Maybe<Scalars['String']>;
};

export type HomeworkCollectQuery = { __typename?: 'RootQueryType' } & {
  collections: Maybe<
    { __typename?: 'CollectionConnection' } & Pick<
      CollectionConnection,
      'count'
    > & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
        >;
        edges: Maybe<
          Array<
            Maybe<
              { __typename?: 'CollectionEdge' } & Pick<
                CollectionEdge,
                'cursor'
              > & {
                  node: Maybe<
                    { __typename?: 'Collection' } & Pick<
                      Collection,
                      'commentsCount' | 'id' | 'name'
                    > & {
                        author: Maybe<
                          { __typename?: 'User' } & Pick<
                            User,
                            'nickname' | 'avatar'
                          >
                        >;
                        parent: Maybe<
                          { __typename?: 'Assignment' } & Pick<
                            Assignment,
                            'name' | 'id' | 'insertedAt'
                          > & {
                              questions: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'Assignment' } & Pick<
                                      Assignment,
                                      'content'
                                    >
                                  >
                                >
                              >;
                            }
                        >;
                        comments: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'CardComment' } & Pick<
                                CardComment,
                                'insertedAt'
                              > & {
                                  card: Maybe<
                                    { __typename?: 'Card' } & Pick<Card, 'name'>
                                  >;
                                }
                            >
                          >
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetLabelsInDiscussionQueryVariables = {};

export type GetLabelsInDiscussionQuery = { __typename?: 'RootQueryType' } & {
  labels: Maybe<
    Array<
      Maybe<
        { __typename?: 'Label' } & Pick<
          Label,
          'id' | 'info' | 'name' | 'scenes'
        >
      >
    >
  >;
};

export type DiscussionCollectionQueryVariables = {
  courseId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<CollectionFilter>;
  order: CollectionOrder;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  matching?: Maybe<Scalars['String']>;
};

export type DiscussionCollectionQuery = { __typename?: 'RootQueryType' } & {
  collections: Maybe<
    { __typename?: 'CollectionConnection' } & Pick<
      CollectionConnection,
      'count'
    > & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
        >;
        edges: Maybe<
          Array<
            Maybe<
              { __typename?: 'CollectionEdge' } & Pick<
                CollectionEdge,
                'cursor'
              > & {
                  node: Maybe<
                    { __typename?: 'Collection' } & Pick<
                      Collection,
                      'insertedAt' | 'id' | 'name' | 'commentsCount'
                    > & {
                        author: Maybe<
                          { __typename?: 'User' } & Pick<
                            User,
                            'nickname' | 'avatar'
                          >
                        >;
                        labels: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'Label' } & Pick<
                                Label,
                                'info' | 'name'
                              >
                            >
                          >
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetSingleDiscussionDataQueryVariables = {
  id: Scalars['ID'];
  courseId: Scalars['ID'];
};

export type GetSingleDiscussionDataQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Discussion' } & Pick<
      Discussion,
      'commentsCount' | 'content' | 'name' | 'id' | 'insertedAt' | 'isCollected'
    > & {
        author: Maybe<
          { __typename?: 'User' } & Pick<User, 'avatar' | 'nickname'>
        >;
        comments: Maybe<
          { __typename?: 'CardCommentConnection' } & {
            edges: Maybe<
              Array<
                Maybe<
                  { __typename?: 'CardCommentEdge' } & {
                    node: Maybe<
                      { __typename?: 'CardComment' } & Pick<
                        CardComment,
                        'insertedAt' | 'id' | 'content'
                      > & {
                          poster: Maybe<
                            { __typename?: 'User' } & Pick<
                              User,
                              'nickname' | 'avatar' | 'id'
                            >
                          >;
                        }
                    >;
                  }
                >
              >
            >;
          }
        >;
        labels: Maybe<
          Array<
            Maybe<
              { __typename?: 'Label' } & Pick<
                Label,
                'id' | 'info' | 'name' | 'scenes'
              >
            >
          >
        >;
      })
  >;
};

export type PostCommentToDiscussMutationVariables = {
  input: AddCommentToDiscussionInput;
};

export type PostCommentToDiscussMutation = {
  __typename?: 'RootMutationType';
} & {
  addCommentToDiscussion: { __typename?: 'DiscussionPayload' } & {
    discussion: Maybe<
      { __typename?: 'Discussion' } & {
        comments: Maybe<
          { __typename?: 'CardCommentConnection' } & {
            edges: Maybe<
              Array<
                Maybe<
                  { __typename?: 'CardCommentEdge' } & {
                    node: Maybe<
                      { __typename?: 'CardComment' } & Pick<
                        CardComment,
                        'content' | 'id' | 'insertedAt'
                      > & {
                          poster: Maybe<
                            { __typename?: 'User' } & Pick<
                              User,
                              'nickname' | 'avatar' | 'id'
                            >
                          >;
                        }
                    >;
                  }
                >
              >
            >;
          }
        >;
      }
    >;
  };
};

export type GetWholeDirectoryQueryVariables = {
  id: Scalars['ID'];
  coursePackageId: Scalars['ID'];
};

export type GetWholeDirectoryQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'CoursePackage' } & {
      contents: Maybe<
        { __typename?: 'CardConnection' } & {
          edges: Maybe<
            Array<
              Maybe<
                { __typename?: 'CardEdge' } & {
                  node: Maybe<
                    { __typename?: 'Card' } & Pick<
                      Card,
                      'id' | 'name' | 'fileType' | 'isLearned'
                    > & {
                        parent: Maybe<
                          { __typename?: 'Card' } & Pick<Card, 'id'>
                        >;
                        children: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'Card' } & Pick<
                                Card,
                                'id' | 'name' | 'fileType' | 'isLearned'
                              > & {
                                  parent: Maybe<
                                    { __typename?: 'Card' } & Pick<Card, 'id'>
                                  >;
                                  children: Maybe<
                                    Array<
                                      Maybe<
                                        { __typename?: 'Card' } & Pick<
                                          Card,
                                          | 'id'
                                          | 'name'
                                          | 'fileType'
                                          | 'isLearned'
                                        > & {
                                            parent: Maybe<
                                              { __typename?: 'Card' } & Pick<
                                                Card,
                                                'id'
                                              >
                                            >;
                                          }
                                      >
                                    >
                                  >;
                                }
                            >
                          >
                        >;
                      }
                  >;
                }
              >
            >
          >;
        }
      >;
    })
  >;
};

export type GetCourseDirectoryQueryVariables = {
  id: Scalars['ID'];
};

export type GetCourseDirectoryQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'CoursePackage' } & Pick<
      CoursePackage,
      'id' | 'fileCardsCount' | 'learnedCount'
    > & {
        contents: Maybe<
          { __typename?: 'CardConnection' } & {
            edges: Maybe<
              Array<
                Maybe<
                  { __typename?: 'CardEdge' } & {
                    node: Maybe<
                      { __typename?: 'Card' } & Pick<
                        Card,
                        'id' | 'name' | 'fileType' | 'isLearned'
                      > & {
                          parent: Maybe<
                            { __typename?: 'Card' } & Pick<Card, 'id'>
                          >;
                        }
                    >;
                  }
                >
              >
            >;
          }
        >;
        firstUnlearnedCard: Maybe<
          { __typename?: 'Card' } & Pick<
            Card,
            'id' | 'name' | 'isLearned' | 'fileType'
          > & {
              ancestors: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Card' } & Pick<
                      Card,
                      'depth' | 'id' | 'name'
                    > & {
                        children: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'Card' } & Pick<
                                Card,
                                'id' | 'name' | 'isLearned' | 'fileType'
                              > & {
                                  parent: Maybe<
                                    { __typename?: 'Card' } & Pick<Card, 'id'>
                                  >;
                                }
                            >
                          >
                        >;
                      }
                  >
                >
              >;
              parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
            }
        >;
      })
  >;
};

export type GetSubDirectoryQueryVariables = {
  id: Scalars['ID'];
  coursePackageId: Scalars['ID'];
};

export type GetSubDirectoryQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Card' } & Pick<Card, 'id' | 'name' | 'fileType'> & {
        parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
        children: Maybe<
          Array<
            Maybe<
              { __typename?: 'Card' } & Pick<
                Card,
                'id' | 'name' | 'fileType' | 'isLearned'
              > & { parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>> }
            >
          >
        >;
      })
  >;
};

export type GetNextFolderDataQueryVariables = {
  id: Scalars['ID'];
  coursePackageId: Scalars['ID'];
};

export type GetNextFolderDataQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Card' } & Pick<Card, 'id' | 'name'> & {
        parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
        children: Maybe<
          Array<
            Maybe<
              { __typename?: 'Card' } & Pick<
                Card,
                'id' | 'name' | 'fileType' | 'isLearned'
              > & { parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>> }
            >
          >
        >;
      })
  >;
};

export type GetSingleCardContentQueryVariables = {
  id: Scalars['ID'];
  coursePackageId: Scalars['ID'];
  courseId: Scalars['ID'];
};

export type GetSingleCardContentQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Card' } & Pick<
      Card,
      | 'content'
      | 'name'
      | 'id'
      | 'isLearned'
      | 'isCollected'
      | 'learnedPeopleCount'
    > & { parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>> })
  >;
};

export type GetCardAncestorQueryVariables = {
  id: Scalars['ID'];
};

export type GetCardAncestorQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Card' } & Pick<Card, 'id'>)
  >;
};

export type GetSingleChapterAllDataQueryVariables = {
  id: Scalars['ID'];
};

export type GetSingleChapterAllDataQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Card' } & Pick<Card, 'id' | 'name'> & {
        children: Maybe<
          Array<
            Maybe<
              { __typename?: 'Card' } & Pick<Card, 'id' | 'name'> & {
                  children: Maybe<
                    Array<
                      Maybe<{ __typename?: 'Card' } & Pick<Card, 'id' | 'name'>>
                    >
                  >;
                }
            >
          >
        >;
      })
  >;
};

export type GetUnlockSingleCardMutationVariables = {
  input: ViewCardInput;
  coursePackageId: Scalars['ID'];
};

export type GetUnlockSingleCardMutation = {
  __typename?: 'RootMutationType';
} & {
  viewCard: Maybe<
    { __typename?: 'ViewCardPayload' } & {
      learningRecord: Maybe<
        { __typename?: 'CardLearningRecord' } & {
          card: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id' | 'isLearned'>>;
        }
      >;
    }
  >;
};

export type CreateCardExamMutationVariables = {
  input: CreateCardExamInput;
};

export type CreateCardExamMutation = { __typename?: 'RootMutationType' } & {
  createCardExam: Maybe<
    { __typename?: 'CardExamPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type GetDiscussLabelsQueryVariables = {};

export type GetDiscussLabelsQuery = { __typename?: 'RootQueryType' } & {
  labels: Maybe<
    Array<
      Maybe<
        { __typename?: 'Label' } & Pick<
          Label,
          'id' | 'info' | 'name' | 'scenes'
        >
      >
    >
  >;
};

export type GetDiscussListQueryVariables = {
  coursePackageId: Scalars['ID'];
  matching?: Maybe<Scalars['String']>;
  filter: DiscussionFilter;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<DiscussionOrder>;
};

export type GetDiscussListQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'CoursePackage' } & Pick<
      CoursePackage,
      'id' | 'packageTag'
    > & {
        discussions: Maybe<
          { __typename?: 'DiscussionConnection' } & Pick<
            DiscussionConnection,
            'count'
          > & {
              pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
              >;
              edges: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'DiscussionEdge' } & Pick<
                      DiscussionEdge,
                      'cursor'
                    > & {
                        node: Maybe<
                          { __typename?: 'Discussion' } & Pick<
                            Discussion,
                            'id' | 'name' | 'insertedAt' | 'commentsCount'
                          > & {
                              author: Maybe<
                                { __typename?: 'User' } & Pick<
                                  User,
                                  'id' | 'nickname' | 'avatar'
                                >
                              >;
                              labels: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'Label' } & Pick<
                                      Label,
                                      'id' | 'info' | 'name' | 'scenes'
                                    >
                                  >
                                >
                              >;
                            }
                        >;
                      }
                  >
                >
              >;
            }
        >;
      })
  >;
};

export type CreateDiscussMutationVariables = {
  input: CreateDiscussionInput;
};

export type CreateDiscussMutation = { __typename?: 'RootMutationType' } & {
  createDiscussion: Maybe<
    { __typename?: 'DiscussionPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'message' | 'code'
            >
          >
        >
      >;
      discussion: Maybe<{ __typename?: 'Discussion' } & Pick<Discussion, 'id'>>;
    }
  >;
};

export type GetDiscussDetailQueryVariables = {
  discussID: Scalars['ID'];
  courseId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};

export type GetDiscussDetailQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Discussion' } & Pick<
      Discussion,
      'id' | 'insertedAt' | 'isCollected' | 'content' | 'status' | 'name'
    > & {
        author: Maybe<
          { __typename?: 'User' } & Pick<User, 'id' | 'nickname' | 'avatar'>
        >;
        labels: Maybe<
          Array<
            Maybe<
              { __typename?: 'Label' } & Pick<
                Label,
                'id' | 'info' | 'name' | 'scenes'
              >
            >
          >
        >;
        comments: Maybe<
          { __typename?: 'CardCommentConnection' } & Pick<
            CardCommentConnection,
            'count'
          > & {
              pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
              >;
              edges: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'CardCommentEdge' } & Pick<
                      CardCommentEdge,
                      'cursor'
                    > & {
                        node: Maybe<
                          { __typename?: 'CardComment' } & Pick<
                            CardComment,
                            'content' | 'id' | 'insertedAt'
                          > & {
                              poster: Maybe<
                                { __typename?: 'User' } & Pick<
                                  User,
                                  'id' | 'nickname' | 'avatar'
                                >
                              >;
                            }
                        >;
                      }
                  >
                >
              >;
            }
        >;
      })
  >;
};

export type CloseDiscussMutationVariables = {
  input: UpdateDiscussionInput;
};

export type CloseDiscussMutation = { __typename?: 'RootMutationType' } & {
  updateDiscussion: Maybe<
    { __typename?: 'DiscussionPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
      discussion: Maybe<
        { __typename?: 'Discussion' } & Pick<Discussion, 'status'>
      >;
    }
  >;
};

export type UpdateDiscussMutationVariables = {
  input: UpdateDiscussionInput;
};

export type UpdateDiscussMutation = { __typename?: 'RootMutationType' } & {
  updateDiscussion: Maybe<
    { __typename?: 'DiscussionPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type DeleteDiscussMutationVariables = {
  input: DeleteDiscussionInput;
};

export type DeleteDiscussMutation = { __typename?: 'RootMutationType' } & {
  deleteDiscussion: Maybe<
    { __typename?: 'DiscussionPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type CreateCommentToDiscussionMutationVariables = {
  input: AddCommentToDiscussionInput;
};

export type CreateCommentToDiscussionMutation = {
  __typename?: 'RootMutationType';
} & {
  addCommentToDiscussion: { __typename?: 'DiscussionPayload' } & {
    errors: Maybe<
      Array<
        Maybe<
          { __typename?: 'InputError' } & Pick<
            InputError,
            'key' | 'code' | 'message'
          >
        >
      >
    >;
  };
};

export type RemoveCommentFromDiscussionMutationVariables = {
  input: RemoveCommentFromDiscussionInput;
};

export type RemoveCommentFromDiscussionMutation = {
  __typename?: 'RootMutationType';
} & {
  removeCommentFromDiscussion: Maybe<
    { __typename?: 'DiscussionPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'key' | 'code' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type GetDiscussCommentQueryVariables = {
  discussID: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};

export type GetDiscussCommentQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Discussion' } & {
      comments: Maybe<
        { __typename?: 'CardCommentConnection' } & Pick<
          CardCommentConnection,
          'count'
        > & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
              PageInfo,
              'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
            >;
            edges: Maybe<
              Array<
                Maybe<
                  { __typename?: 'CardCommentEdge' } & Pick<
                    CardCommentEdge,
                    'cursor'
                  > & {
                      node: Maybe<
                        { __typename?: 'CardComment' } & Pick<
                          CardComment,
                          'content' | 'id' | 'insertedAt'
                        > & {
                            poster: Maybe<
                              { __typename?: 'User' } & Pick<
                                User,
                                'id' | 'nickname' | 'avatar'
                              >
                            >;
                          }
                      >;
                    }
                >
              >
            >;
          }
      >;
    })
  >;
};

export type GetCommitDetailQueryVariables = {
  id: Scalars['ID'];
};

export type GetCommitDetailQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Commit' } & Pick<Commit, 'content' | 'diff' | 'id'> & {
        assignment: Maybe<
          { __typename?: 'Assignment' } & Pick<
            Assignment,
            'id' | 'pid' | 'location'
          >
        >;
      })
  >;
};

export type GetCommitDetailContentQueryVariables = {
  id: Scalars['ID'];
};

export type GetCommitDetailContentQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Commit' } & Pick<Commit, 'content' | 'id'> & {
        assignment: Maybe<
          { __typename?: 'Assignment' } & Pick<
            Assignment,
            'id' | 'pid' | 'location'
          >
        >;
      })
  >;
};

export type GetCommitDetailDiffQueryVariables = {
  id: Scalars['ID'];
};

export type GetCommitDetailDiffQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Commit' } & Pick<Commit, 'diff' | 'id'> & {
        assignment: Maybe<
          { __typename?: 'Assignment' } & Pick<
            Assignment,
            'id' | 'pid' | 'location'
          >
        >;
      })
  >;
};

export type GetAllAssignmentsQueryVariables = {
  id: Scalars['ID'];
};

export type GetAllAssignmentsQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'CoursePackage' } & {
      assignments: Maybe<
        Array<
          Maybe<
            { __typename?: 'Assignment' } & Pick<
              Assignment,
              'name' | 'id' | 'isIveDone'
            > & {
                questions: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'Assignment' } & Pick<
                        Assignment,
                        'name' | 'id'
                      >
                    >
                  >
                >;
              }
          >
        >
      >;
    })
  >;
};

export type CheckSingleAnswerQueryVariables = {
  id: Scalars['ID'];
  courseId: Scalars['ID'];
};

export type CheckSingleAnswerQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Assignment' } & Pick<
      Assignment,
      'content' | 'commentsCount' | 'insertedAt' | 'id' | 'isCollected'
    > & {
        author: Maybe<
          { __typename?: 'User' } & Pick<User, 'nickname' | 'avatar'>
        >;
        comments: Maybe<
          { __typename?: 'CardCommentConnection' } & {
            edges: Maybe<
              Array<
                Maybe<
                  { __typename?: 'CardCommentEdge' } & {
                    node: Maybe<
                      { __typename?: 'CardComment' } & Pick<
                        CardComment,
                        'content' | 'id' | 'insertedAt'
                      > & {
                          poster: Maybe<
                            { __typename?: 'User' } & Pick<
                              User,
                              'nickname' | 'avatar' | 'id'
                            >
                          >;
                        }
                    >;
                  }
                >
              >
            >;
          }
        >;
      })
  >;
};

export type GetMyAnswerIdQueryVariables = {
  id: Scalars['ID'];
};

export type GetMyAnswerIdQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Assignment' } & {
      answers: Maybe<
        { __typename?: 'AssignmentConnection' } & {
          edges: Maybe<
            Array<
              Maybe<
                { __typename?: 'AssignmentEdge' } & {
                  node: Maybe<
                    { __typename?: 'Assignment' } & Pick<Assignment, 'id'>
                  >;
                }
              >
            >
          >;
        }
      >;
    })
  >;
};

export type CheckOtherAnswersQueryVariables = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AnswerFilter>;
  matching?: Maybe<Scalars['String']>;
  order?: Maybe<AssignmentOrder>;
};

export type CheckOtherAnswersQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Assignment' } & Pick<Assignment, 'name'> & {
        answers: Maybe<
          { __typename?: 'AssignmentConnection' } & Pick<
            AssignmentConnection,
            'count'
          > & {
              edges: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'AssignmentEdge' } & {
                      node: Maybe<
                        { __typename?: 'Assignment' } & Pick<
                          Assignment,
                          'id' | 'commentsCount' | 'insertedAt'
                        > & {
                            author: Maybe<
                              { __typename?: 'User' } & Pick<
                                User,
                                'nickname' | 'avatar'
                              >
                            >;
                          }
                      >;
                    }
                  >
                >
              >;
              pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
              >;
            }
        >;
      })
  >;
};

export type RemoveCommentFromAssignmentMutationVariables = {
  input: RemoveCommentFromAssignmentInput;
};

export type RemoveCommentFromAssignmentMutation = {
  __typename?: 'RootMutationType';
} & {
  removeCommentFromAssignment: Maybe<
    { __typename?: 'AssignmentPayload' } & {
      assignment: Maybe<
        { __typename?: 'Assignment' } & {
          comments: Maybe<
            { __typename?: 'CardCommentConnection' } & {
              edges: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'CardCommentEdge' } & {
                      node: Maybe<
                        { __typename?: 'CardComment' } & Pick<
                          CardComment,
                          'id' | 'content'
                        > & {
                            poster: Maybe<
                              { __typename?: 'User' } & Pick<
                                User,
                                'nickname' | 'avatar' | 'id'
                              >
                            >;
                          }
                      >;
                    }
                  >
                >
              >;
            }
          >;
        }
      >;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<InputError, 'code' | 'message'>
          >
        >
      >;
    }
  >;
};

export type GetCourseIdQueryVariables = {
  id: Scalars['ID'];
};

export type GetCourseIdQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Course' } & Pick<Course, 'id' | 'title'> & {
        coursesPackages: Maybe<
          Array<
            Maybe<
              { __typename?: 'CoursePackage' } & Pick<
                CoursePackage,
                'id' | 'packageTag'
              >
            >
          >
        >;
      })
  >;
};

export type GetCourseInfoQueryVariables = {
  id: Scalars['ID'];
};

export type GetCourseInfoQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Course' } & Pick<
      Course,
      'id' | 'title' | 'isOnline'
    > & {
        coursesPackages: Maybe<
          Array<
            Maybe<
              { __typename?: 'CoursePackage' } & Pick<
                CoursePackage,
                'id' | 'packageTag' | 'status'
              >
            >
          >
        >;
      })
  >;
};

export type GetNextCardQueryVariables = {
  courseId: Scalars['ID'];
  coursePackgeId: Scalars['ID'];
  currentCardId: Scalars['ID'];
};

export type GetNextCardQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'CoursePackage' } & Pick<CoursePackage, 'id'> & {
        nextCard: Maybe<
          { __typename?: 'Card' } & Pick<
            Card,
            | 'id'
            | 'content'
            | 'isCollected'
            | 'name'
            | 'isLearned'
            | 'learnedPeopleCount'
          > & { parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>> }
        >;
      })
  >;
};

export type GetPrevCardQueryVariables = {
  courseId: Scalars['ID'];
  coursePackgeId: Scalars['ID'];
  currentCardId: Scalars['ID'];
};

export type GetPrevCardQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'CoursePackage' } & Pick<CoursePackage, 'id'> & {
        previousCard: Maybe<
          { __typename?: 'Card' } & Pick<
            Card,
            | 'id'
            | 'content'
            | 'isCollected'
            | 'name'
            | 'isLearned'
            | 'learnedPeopleCount'
          > & { parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>> }
        >;
      })
  >;
};

export type ShareCardLinkQueryVariables = {
  courseId: Scalars['ID'];
  coursePackgeId: Scalars['ID'];
  currentCardId: Scalars['ID'];
};

export type ShareCardLinkQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Card' } & Pick<
      Card,
      'id' | 'id' | 'content' | 'isCollected' | 'name' | 'learnedPeopleCount'
    > & {
        ancestors: Maybe<
          Array<
            Maybe<
              { __typename?: 'Card' } & Pick<Card, 'depth' | 'id' | 'name'> & {
                  children: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'Card' } & Pick<
                          Card,
                          'id' | 'name' | 'isLearned' | 'fileType'
                        > & {
                            parent: Maybe<
                              { __typename?: 'Card' } & Pick<Card, 'id'>
                            >;
                          }
                      >
                    >
                  >;
                }
            >
          >
        >;
        parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
      })
  >;
};

export type GetSingleAssignmentQueryVariables = {
  id: Scalars['ID'];
  courseId: Scalars['ID'];
};

export type GetSingleAssignmentQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Assignment' } & Pick<
      Assignment,
      'name' | 'isIveDone' | 'commentsCount' | 'insertedAt'
    > & {
        author: Maybe<
          { __typename?: 'User' } & Pick<User, 'avatar' | 'nickname'>
        >;
        answers: Maybe<
          { __typename?: 'AssignmentConnection' } & {
            edges: Maybe<
              Array<
                Maybe<
                  { __typename?: 'AssignmentEdge' } & {
                    node: Maybe<
                      { __typename?: 'Assignment' } & Pick<
                        Assignment,
                        | 'insertedAt'
                        | 'content'
                        | 'commentsCount'
                        | 'id'
                        | 'isCollected'
                      > & {
                          author: Maybe<
                            { __typename?: 'User' } & Pick<
                              User,
                              'avatar' | 'nickname'
                            >
                          >;
                          comments: Maybe<
                            { __typename?: 'CardCommentConnection' } & {
                              edges: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'CardCommentEdge' } & {
                                      node: Maybe<
                                        { __typename?: 'CardComment' } & Pick<
                                          CardComment,
                                          'content' | 'id' | 'insertedAt'
                                        > & {
                                            poster: Maybe<
                                              { __typename?: 'User' } & Pick<
                                                User,
                                                'avatar' | 'nickname' | 'id'
                                              >
                                            >;
                                          }
                                      >;
                                    }
                                  >
                                >
                              >;
                            }
                          >;
                        }
                    >;
                  }
                >
              >
            >;
          }
        >;
        questions: Maybe<
          Array<
            Maybe<
              { __typename?: 'Assignment' } & Pick<
                Assignment,
                'content' | 'pid' | 'name' | 'id' | 'insertedAt'
              > & {
                  author: Maybe<
                    { __typename?: 'User' } & Pick<User, 'avatar' | 'nickname'>
                  >;
                }
            >
          >
        >;
      })
  >;
};

export type GetAnswerCommitsQueryVariables = {
  id: Scalars['ID'];
};

export type GetAnswerCommitsQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Assignment' } & {
      commits: Maybe<
        Array<
          Maybe<
            { __typename?: 'CommitInfo' } & Pick<
              CommitInfo,
              'commitHash' | 'commitedAt'
            >
          >
        >
      >;
    })
  >;
};

export type GetSingleAnswerIdQueryVariables = {
  id: Scalars['ID'];
};

export type GetSingleAnswerIdQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'Assignment' } & {
      answers: Maybe<
        { __typename?: 'AssignmentConnection' } & {
          edges: Maybe<
            Array<
              Maybe<
                { __typename?: 'AssignmentEdge' } & {
                  node: Maybe<
                    { __typename?: 'Assignment' } & Pick<Assignment, 'id'>
                  >;
                }
              >
            >
          >;
        }
      >;
    })
  >;
};

export type UnCollectMutationVariables = {
  input: UncollectCardInput;
};

export type UnCollectMutation = { __typename?: 'RootMutationType' } & {
  uncollectCard: Maybe<
    { __typename?: 'CollectionPayload' } & {
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type CollectCardMutationVariables = {
  input: CollectCardInput;
};

export type CollectCardMutation = { __typename?: 'RootMutationType' } & {
  collectCard: Maybe<
    { __typename?: 'CollectionPayload' } & {
      errors: Maybe<
        Array<
          Maybe<{ __typename?: 'InputError' } & Pick<InputError, 'message'>>
        >
      >;
    }
  >;
};

export type AddCommentToSingleAssignmentMutationVariables = {
  input: AddCommentToAssignmentInput;
};

export type AddCommentToSingleAssignmentMutation = {
  __typename?: 'RootMutationType';
} & {
  addCommentToAssignment: Maybe<
    { __typename?: 'AssignmentPayload' } & {
      assignment: Maybe<
        { __typename?: 'Assignment' } & {
          comments: Maybe<
            { __typename?: 'CardCommentConnection' } & {
              edges: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'CardCommentEdge' } & {
                      node: Maybe<
                        { __typename?: 'CardComment' } & Pick<
                          CardComment,
                          'id' | 'content' | 'insertedAt'
                        > & {
                            poster: Maybe<
                              { __typename?: 'User' } & Pick<
                                User,
                                'nickname' | 'avatar' | 'id'
                              >
                            >;
                          }
                      >;
                    }
                  >
                >
              >;
            }
          >;
        }
      >;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type DeleteCommentMutationVariables = {
  input: DeleteCardCommentInput;
};

export type DeleteCommentMutation = { __typename?: 'RootMutationType' } & {
  deleteCardComment: Maybe<
    { __typename?: 'CardComment' } & Pick<
      CardComment,
      'content' | 'id' | 'insertedAt'
    >
  >;
};

export type PostAssignmentMutationVariables = {
  assignmentInput: CreateAssignmentInput;
};

export type PostAssignmentMutation = { __typename?: 'RootMutationType' } & {
  createAssignment: Maybe<
    { __typename?: 'AssignmentPayload' } & {
      assignment: Maybe<
        { __typename?: 'Assignment' } & Pick<
          Assignment,
          'id' | 'name' | 'content' | 'insertedAt' | 'isIveDone'
        > & {
            author: Maybe<
              { __typename?: 'User' } & Pick<User, 'nickname' | 'avatar'>
            >;
          }
      >;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type UpdateAssignmentMutationVariables = {
  assignmentInput: UpdateAssignmentInput;
};

export type UpdateAssignmentMutation = { __typename?: 'RootMutationType' } & {
  updateAssignment: Maybe<
    { __typename?: 'AssignmentPayload' } & {
      assignment: Maybe<
        { __typename?: 'Assignment' } & Pick<
          Assignment,
          'id' | 'name' | 'content' | 'insertedAt'
        >
      >;
      errors: Maybe<
        Array<
          Maybe<
            { __typename?: 'InputError' } & Pick<
              InputError,
              'code' | 'key' | 'message'
            >
          >
        >
      >;
    }
  >;
};

export type ContinueLearningQueryVariables = {
  id: Scalars['ID'];
};

export type ContinueLearningQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'CoursePackage' } & Pick<CoursePackage, 'id'> & {
        firstUnlearnedCard: Maybe<
          { __typename?: 'Card' } & Pick<
            Card,
            'id' | 'name' | 'isLearned' | 'fileType'
          > & {
              ancestors: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Card' } & Pick<
                      Card,
                      'depth' | 'id' | 'name'
                    > & {
                        children: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'Card' } & Pick<
                                Card,
                                'id' | 'name' | 'isLearned' | 'fileType'
                              > & {
                                  parent: Maybe<
                                    { __typename?: 'Card' } & Pick<Card, 'id'>
                                  >;
                                }
                            >
                          >
                        >;
                      }
                  >
                >
              >;
              parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
            }
        >;
      })
  >;
};

export type GoLearningNextCardQueryVariables = {
  courseId: Scalars['ID'];
  coursePackgeId: Scalars['ID'];
  currentCardId: Scalars['ID'];
};

export type GoLearningNextCardQuery = { __typename?: 'RootQueryType' } & {
  node: Maybe<
    {
      __typename?:
        | 'Banner'
        | 'CardVideo'
        | 'Card'
        | 'User'
        | 'Message'
        | 'UserProfile'
        | 'Package'
        | 'Collection'
        | 'CardComment'
        | 'Label'
        | 'Assignment'
        | 'CourseCategory'
        | 'CoursePackage'
        | 'Course'
        | 'Discussion'
        | 'Purchase'
        | 'CardExam'
        | 'CardLearningRecord'
        | 'Commit';
    } & ({ __typename?: 'CoursePackage' } & Pick<CoursePackage, 'id'> & {
        nextCard: Maybe<
          { __typename?: 'Card' } & Pick<
            Card,
            'id' | 'content' | 'isCollected' | 'name' | 'learnedPeopleCount'
          > & {
              ancestors: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Card' } & Pick<
                      Card,
                      'depth' | 'id' | 'name'
                    > & {
                        children: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'Card' } & Pick<
                                Card,
                                'id' | 'name' | 'isLearned' | 'fileType'
                              > & {
                                  parent: Maybe<
                                    { __typename?: 'Card' } & Pick<Card, 'id'>
                                  >;
                                }
                            >
                          >
                        >;
                      }
                  >
                >
              >;
              parent: Maybe<{ __typename?: 'Card' } & Pick<Card, 'id'>>;
            }
        >;
      })
  >;
};

export type SearchCourseContentCardQueryVariables = {
  coursePackageId: Scalars['ID'];
  matching?: Maybe<Scalars['String']>;
  filter: SearchFilter;
};

export type SearchCourseContentCardQuery = { __typename?: 'RootQueryType' } & {
  searchCards: Maybe<
    Array<
      Maybe<
        { __typename?: 'Card' } & Pick<
          Card,
          'id' | 'name' | 'fileType' | 'isLearned'
        >
      >
    >
  >;
};

export type GetTagCardQueryVariables = {
  coursePackageId: Scalars['ID'];
  filter: SearchFilter;
};

export type GetTagCardQuery = { __typename?: 'RootQueryType' } & {
  searchCards: Maybe<
    Array<
      Maybe<
        { __typename?: 'Card' } & Pick<
          Card,
          'id' | 'name' | 'fileType' | 'isLearned'
        >
      >
    >
  >;
};

export const GetCourseDetailNDocument = gql`
  query getCourseDetailN($id: ID!) {
    node(id: $id) {
      ... on Course {
        category {
          parent {
            name
          }
        }
        chargingLink
        cover
        description
        id
        instructors
        isEnd
        isFee
        isOnline
        isSelling
        needUserProfile
        price
        registrationBegin
        registrationEnd
        shortUrl
        subTitle
        subscribedCount
        title
      }
    }
  }
`;
export type GetCourseDetailNComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCourseDetailNQuery,
    GetCourseDetailNQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCourseDetailNQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCourseDetailNComponent = (
  props: GetCourseDetailNComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCourseDetailNQuery,
    GetCourseDetailNQueryVariables
  >
    query={GetCourseDetailNDocument}
    {...props}
  />
);

export type GetCourseDetailNProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetCourseDetailNQuery,
  GetCourseDetailNQueryVariables
> &
  TChildProps;
export function withGetCourseDetailN<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCourseDetailNQuery,
    GetCourseDetailNQueryVariables,
    GetCourseDetailNProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCourseDetailNQuery,
    GetCourseDetailNQueryVariables,
    GetCourseDetailNProps<TChildProps>
  >(GetCourseDetailNDocument, {
    alias: 'withGetCourseDetailN',
    ...operationOptions
  });
}

export function useGetCourseDetailNQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCourseDetailNQuery,
    GetCourseDetailNQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCourseDetailNQuery,
    GetCourseDetailNQueryVariables
  >(GetCourseDetailNDocument, baseOptions);
}
export type GetCourseDetailNQueryHookResult = ReturnType<
  typeof useGetCourseDetailNQuery
>;
export type GetCourseDetailNQueryResult = ApolloReactCommon.QueryResult<
  GetCourseDetailNQuery,
  GetCourseDetailNQueryVariables
>;
export const GetCourseDetailYDocument = gql`
  query getCourseDetailY($id: ID!) {
    node(id: $id) {
      ... on Course {
        category {
          parent {
            name
          }
        }
        chargingLink
        cover
        description
        id
        instructors
        isEnd
        isFee
        isOnline
        isSelling
        isSubscribed
        needUserProfile
        price
        registrationBegin
        registrationEnd
        shortUrl
        subTitle
        subscribedCount
        title
      }
    }
  }
`;
export type GetCourseDetailYComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCourseDetailYQuery,
    GetCourseDetailYQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCourseDetailYQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCourseDetailYComponent = (
  props: GetCourseDetailYComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCourseDetailYQuery,
    GetCourseDetailYQueryVariables
  >
    query={GetCourseDetailYDocument}
    {...props}
  />
);

export type GetCourseDetailYProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetCourseDetailYQuery,
  GetCourseDetailYQueryVariables
> &
  TChildProps;
export function withGetCourseDetailY<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCourseDetailYQuery,
    GetCourseDetailYQueryVariables,
    GetCourseDetailYProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCourseDetailYQuery,
    GetCourseDetailYQueryVariables,
    GetCourseDetailYProps<TChildProps>
  >(GetCourseDetailYDocument, {
    alias: 'withGetCourseDetailY',
    ...operationOptions
  });
}

export function useGetCourseDetailYQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCourseDetailYQuery,
    GetCourseDetailYQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCourseDetailYQuery,
    GetCourseDetailYQueryVariables
  >(GetCourseDetailYDocument, baseOptions);
}
export type GetCourseDetailYQueryHookResult = ReturnType<
  typeof useGetCourseDetailYQuery
>;
export type GetCourseDetailYQueryResult = ApolloReactCommon.QueryResult<
  GetCourseDetailYQuery,
  GetCourseDetailYQueryVariables
>;
export const GetCourseSubscribedDocument = gql`
  query getCourseSubscribed($id: ID!) {
    node(id: $id) {
      ... on Course {
        isSubscribed
      }
    }
  }
`;
export type GetCourseSubscribedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCourseSubscribedQuery,
    GetCourseSubscribedQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCourseSubscribedQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCourseSubscribedComponent = (
  props: GetCourseSubscribedComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCourseSubscribedQuery,
    GetCourseSubscribedQueryVariables
  >
    query={GetCourseSubscribedDocument}
    {...props}
  />
);

export type GetCourseSubscribedProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetCourseSubscribedQuery,
  GetCourseSubscribedQueryVariables
> &
  TChildProps;
export function withGetCourseSubscribed<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCourseSubscribedQuery,
    GetCourseSubscribedQueryVariables,
    GetCourseSubscribedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCourseSubscribedQuery,
    GetCourseSubscribedQueryVariables,
    GetCourseSubscribedProps<TChildProps>
  >(GetCourseSubscribedDocument, {
    alias: 'withGetCourseSubscribed',
    ...operationOptions
  });
}

export function useGetCourseSubscribedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCourseSubscribedQuery,
    GetCourseSubscribedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCourseSubscribedQuery,
    GetCourseSubscribedQueryVariables
  >(GetCourseSubscribedDocument, baseOptions);
}
export type GetCourseSubscribedQueryHookResult = ReturnType<
  typeof useGetCourseSubscribedQuery
>;
export type GetCourseSubscribedQueryResult = ApolloReactCommon.QueryResult<
  GetCourseSubscribedQuery,
  GetCourseSubscribedQueryVariables
>;
export const CreatePurchaseDocument = gql`
  mutation createPurchase($input: CreatePurchaseInput!) {
    createPurchase(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
export type CreatePurchaseMutationFn = ApolloReactCommon.MutationFunction<
  CreatePurchaseMutation,
  CreatePurchaseMutationVariables
>;
export type CreatePurchaseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreatePurchaseMutation,
    CreatePurchaseMutationVariables
  >,
  'mutation'
>;

export const CreatePurchaseComponent = (
  props: CreatePurchaseComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreatePurchaseMutation,
    CreatePurchaseMutationVariables
  >
    mutation={CreatePurchaseDocument}
    {...props}
  />
);

export type CreatePurchaseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreatePurchaseMutation,
  CreatePurchaseMutationVariables
> &
  TChildProps;
export function withCreatePurchase<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePurchaseMutation,
    CreatePurchaseMutationVariables,
    CreatePurchaseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePurchaseMutation,
    CreatePurchaseMutationVariables,
    CreatePurchaseProps<TChildProps>
  >(CreatePurchaseDocument, {
    alias: 'withCreatePurchase',
    ...operationOptions
  });
}

export function useCreatePurchaseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePurchaseMutation,
    CreatePurchaseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreatePurchaseMutation,
    CreatePurchaseMutationVariables
  >(CreatePurchaseDocument, baseOptions);
}
export type CreatePurchaseMutationHookResult = ReturnType<
  typeof useCreatePurchaseMutation
>;
export type CreatePurchaseMutationResult = ApolloReactCommon.MutationResult<
  CreatePurchaseMutation
>;
export type CreatePurchaseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePurchaseMutation,
  CreatePurchaseMutationVariables
>;
export const ListCollegesDocument = gql`
  query listColleges {
    courseCategories {
      id
      name
      parent {
        id
      }
    }
  }
`;
export type ListCollegesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCollegesQuery,
    ListCollegesQueryVariables
  >,
  'query'
>;

export const ListCollegesComponent = (props: ListCollegesComponentProps) => (
  <ApolloReactComponents.Query<ListCollegesQuery, ListCollegesQueryVariables>
    query={ListCollegesDocument}
    {...props}
  />
);

export type ListCollegesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ListCollegesQuery,
  ListCollegesQueryVariables
> &
  TChildProps;
export function withListColleges<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCollegesQuery,
    ListCollegesQueryVariables,
    ListCollegesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCollegesQuery,
    ListCollegesQueryVariables,
    ListCollegesProps<TChildProps>
  >(ListCollegesDocument, {
    alias: 'withListColleges',
    ...operationOptions
  });
}

export function useListCollegesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCollegesQuery,
    ListCollegesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCollegesQuery,
    ListCollegesQueryVariables
  >(ListCollegesDocument, baseOptions);
}
export type ListCollegesQueryHookResult = ReturnType<
  typeof useListCollegesQuery
>;
export type ListCollegesQueryResult = ApolloReactCommon.QueryResult<
  ListCollegesQuery,
  ListCollegesQueryVariables
>;
export const ListCoursesDocument = gql`
  query listCourses($filter: CourseFilter, $first: Int) {
    courses(filter: $filter, first: $first) {
      count
      edges {
        cursor
        node {
          title
          cover
          subscribedCount
          shortUrl
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
export type ListCoursesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCoursesQuery,
    ListCoursesQueryVariables
  >,
  'query'
>;

export const ListCoursesComponent = (props: ListCoursesComponentProps) => (
  <ApolloReactComponents.Query<ListCoursesQuery, ListCoursesQueryVariables>
    query={ListCoursesDocument}
    {...props}
  />
);

export type ListCoursesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ListCoursesQuery,
  ListCoursesQueryVariables
> &
  TChildProps;
export function withListCourses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCoursesQuery,
    ListCoursesQueryVariables,
    ListCoursesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCoursesQuery,
    ListCoursesQueryVariables,
    ListCoursesProps<TChildProps>
  >(ListCoursesDocument, {
    alias: 'withListCourses',
    ...operationOptions
  });
}

export function useListCoursesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCoursesQuery,
    ListCoursesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ListCoursesQuery, ListCoursesQueryVariables>(
    ListCoursesDocument,
    baseOptions
  );
}
export type ListCoursesQueryHookResult = ReturnType<typeof useListCoursesQuery>;
export type ListCoursesQueryResult = ApolloReactCommon.QueryResult<
  ListCoursesQuery,
  ListCoursesQueryVariables
>;
export const EduCreatePackageDocument = gql`
  mutation eduCreatePackage($input: CreatePackageInput!) {
    createPackage(input: $input) {
      errors {
        code
        key
        message
      }
      package {
        id
        name
      }
    }
  }
`;
export type EduCreatePackageMutationFn = ApolloReactCommon.MutationFunction<
  EduCreatePackageMutation,
  EduCreatePackageMutationVariables
>;
export type EduCreatePackageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    EduCreatePackageMutation,
    EduCreatePackageMutationVariables
  >,
  'mutation'
>;

export const EduCreatePackageComponent = (
  props: EduCreatePackageComponentProps
) => (
  <ApolloReactComponents.Mutation<
    EduCreatePackageMutation,
    EduCreatePackageMutationVariables
  >
    mutation={EduCreatePackageDocument}
    {...props}
  />
);

export type EduCreatePackageProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  EduCreatePackageMutation,
  EduCreatePackageMutationVariables
> &
  TChildProps;
export function withEduCreatePackage<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EduCreatePackageMutation,
    EduCreatePackageMutationVariables,
    EduCreatePackageProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    EduCreatePackageMutation,
    EduCreatePackageMutationVariables,
    EduCreatePackageProps<TChildProps>
  >(EduCreatePackageDocument, {
    alias: 'withEduCreatePackage',
    ...operationOptions
  });
}

export function useEduCreatePackageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EduCreatePackageMutation,
    EduCreatePackageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    EduCreatePackageMutation,
    EduCreatePackageMutationVariables
  >(EduCreatePackageDocument, baseOptions);
}
export type EduCreatePackageMutationHookResult = ReturnType<
  typeof useEduCreatePackageMutation
>;
export type EduCreatePackageMutationResult = ApolloReactCommon.MutationResult<
  EduCreatePackageMutation
>;
export type EduCreatePackageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EduCreatePackageMutation,
  EduCreatePackageMutationVariables
>;
export const EduCreateCardDocument = gql`
  mutation eduCreateCard($input: CreateCardInput!) {
    createCard(input: $input) {
      errors {
        code
        key
        message
      }
      card {
        name
        id
        fileType
        parent {
          id
        }
        package {
          id
        }
      }
    }
  }
`;
export type EduCreateCardMutationFn = ApolloReactCommon.MutationFunction<
  EduCreateCardMutation,
  EduCreateCardMutationVariables
>;
export type EduCreateCardComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    EduCreateCardMutation,
    EduCreateCardMutationVariables
  >,
  'mutation'
>;

export const EduCreateCardComponent = (props: EduCreateCardComponentProps) => (
  <ApolloReactComponents.Mutation<
    EduCreateCardMutation,
    EduCreateCardMutationVariables
  >
    mutation={EduCreateCardDocument}
    {...props}
  />
);

export type EduCreateCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  EduCreateCardMutation,
  EduCreateCardMutationVariables
> &
  TChildProps;
export function withEduCreateCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EduCreateCardMutation,
    EduCreateCardMutationVariables,
    EduCreateCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    EduCreateCardMutation,
    EduCreateCardMutationVariables,
    EduCreateCardProps<TChildProps>
  >(EduCreateCardDocument, {
    alias: 'withEduCreateCard',
    ...operationOptions
  });
}

export function useEduCreateCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EduCreateCardMutation,
    EduCreateCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    EduCreateCardMutation,
    EduCreateCardMutationVariables
  >(EduCreateCardDocument, baseOptions);
}
export type EduCreateCardMutationHookResult = ReturnType<
  typeof useEduCreateCardMutation
>;
export type EduCreateCardMutationResult = ApolloReactCommon.MutationResult<
  EduCreateCardMutation
>;
export type EduCreateCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EduCreateCardMutation,
  EduCreateCardMutationVariables
>;
export const GetEduManagerCoursesDocument = gql`
  query getEduManagerCourses(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $filter: TeachingCourseFilter
  ) {
    createdCourses(
      after: $after
      first: $first
      before: $before
      last: $last
      filter: $filter
    ) {
      edges {
        cursor
        node {
          cover
          title
          subTitle
          shortUrl
          id
          subscribedCount
          category {
            name
            parent {
              name
            }
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      count
    }
  }
`;
export type GetEduManagerCoursesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetEduManagerCoursesQuery,
    GetEduManagerCoursesQueryVariables
  >,
  'query'
>;

export const GetEduManagerCoursesComponent = (
  props: GetEduManagerCoursesComponentProps
) => (
  <ApolloReactComponents.Query<
    GetEduManagerCoursesQuery,
    GetEduManagerCoursesQueryVariables
  >
    query={GetEduManagerCoursesDocument}
    {...props}
  />
);

export type GetEduManagerCoursesProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetEduManagerCoursesQuery,
  GetEduManagerCoursesQueryVariables
> &
  TChildProps;
export function withGetEduManagerCourses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetEduManagerCoursesQuery,
    GetEduManagerCoursesQueryVariables,
    GetEduManagerCoursesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetEduManagerCoursesQuery,
    GetEduManagerCoursesQueryVariables,
    GetEduManagerCoursesProps<TChildProps>
  >(GetEduManagerCoursesDocument, {
    alias: 'withGetEduManagerCourses',
    ...operationOptions
  });
}

export function useGetEduManagerCoursesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetEduManagerCoursesQuery,
    GetEduManagerCoursesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetEduManagerCoursesQuery,
    GetEduManagerCoursesQueryVariables
  >(GetEduManagerCoursesDocument, baseOptions);
}
export type GetEduManagerCoursesQueryHookResult = ReturnType<
  typeof useGetEduManagerCoursesQuery
>;
export type GetEduManagerCoursesQueryResult = ApolloReactCommon.QueryResult<
  GetEduManagerCoursesQuery,
  GetEduManagerCoursesQueryVariables
>;
export const DeleteEduManagerCourseDocument = gql`
  mutation DeleteEduManagerCourse($input: DeleteCourseInput!) {
    deleteCourse(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
export type DeleteEduManagerCourseMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEduManagerCourseMutation,
  DeleteEduManagerCourseMutationVariables
>;
export type DeleteEduManagerCourseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteEduManagerCourseMutation,
    DeleteEduManagerCourseMutationVariables
  >,
  'mutation'
>;

export const DeleteEduManagerCourseComponent = (
  props: DeleteEduManagerCourseComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteEduManagerCourseMutation,
    DeleteEduManagerCourseMutationVariables
  >
    mutation={DeleteEduManagerCourseDocument}
    {...props}
  />
);

export type DeleteEduManagerCourseProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteEduManagerCourseMutation,
  DeleteEduManagerCourseMutationVariables
> &
  TChildProps;
export function withDeleteEduManagerCourse<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteEduManagerCourseMutation,
    DeleteEduManagerCourseMutationVariables,
    DeleteEduManagerCourseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteEduManagerCourseMutation,
    DeleteEduManagerCourseMutationVariables,
    DeleteEduManagerCourseProps<TChildProps>
  >(DeleteEduManagerCourseDocument, {
    alias: 'withDeleteEduManagerCourse',
    ...operationOptions
  });
}

export function useDeleteEduManagerCourseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEduManagerCourseMutation,
    DeleteEduManagerCourseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteEduManagerCourseMutation,
    DeleteEduManagerCourseMutationVariables
  >(DeleteEduManagerCourseDocument, baseOptions);
}
export type DeleteEduManagerCourseMutationHookResult = ReturnType<
  typeof useDeleteEduManagerCourseMutation
>;
export type DeleteEduManagerCourseMutationResult = ApolloReactCommon.MutationResult<
  DeleteEduManagerCourseMutation
>;
export type DeleteEduManagerCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEduManagerCourseMutation,
  DeleteEduManagerCourseMutationVariables
>;
export const GetCourseInfoInEditDocument = gql`
  query GetCourseInfoInEdit($id: ID!) {
    node(id: $id) {
      ... on Course {
        category {
          id
          name
          parent {
            id
            name
          }
        }
        chargingLink
        coursesPackages {
          id
          package {
            name
          }
          packageTag
          status
        }
        id
        cover
        description
        instructors
        isEnd
        isFee
        isOnline
        isSelling
        labels
        price
        registrationBegin
        registrationEnd
        shortUrl
        subTitle
        subscribedCount
        title
        needUserProfile
      }
    }
  }
`;
export type GetCourseInfoInEditComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCourseInfoInEditQuery,
    GetCourseInfoInEditQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCourseInfoInEditQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCourseInfoInEditComponent = (
  props: GetCourseInfoInEditComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCourseInfoInEditQuery,
    GetCourseInfoInEditQueryVariables
  >
    query={GetCourseInfoInEditDocument}
    {...props}
  />
);

export type GetCourseInfoInEditProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetCourseInfoInEditQuery,
  GetCourseInfoInEditQueryVariables
> &
  TChildProps;
export function withGetCourseInfoInEdit<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCourseInfoInEditQuery,
    GetCourseInfoInEditQueryVariables,
    GetCourseInfoInEditProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCourseInfoInEditQuery,
    GetCourseInfoInEditQueryVariables,
    GetCourseInfoInEditProps<TChildProps>
  >(GetCourseInfoInEditDocument, {
    alias: 'withGetCourseInfoInEdit',
    ...operationOptions
  });
}

export function useGetCourseInfoInEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCourseInfoInEditQuery,
    GetCourseInfoInEditQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCourseInfoInEditQuery,
    GetCourseInfoInEditQueryVariables
  >(GetCourseInfoInEditDocument, baseOptions);
}
export type GetCourseInfoInEditQueryHookResult = ReturnType<
  typeof useGetCourseInfoInEditQuery
>;
export type GetCourseInfoInEditQueryResult = ApolloReactCommon.QueryResult<
  GetCourseInfoInEditQuery,
  GetCourseInfoInEditQueryVariables
>;
export const GetCategoryInfoInEditDocument = gql`
  query GetCategoryInfoInEdit {
    courseCategories {
      id
      name
      parent {
        id
        name
      }
    }
  }
`;
export type GetCategoryInfoInEditComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCategoryInfoInEditQuery,
    GetCategoryInfoInEditQueryVariables
  >,
  'query'
>;

export const GetCategoryInfoInEditComponent = (
  props: GetCategoryInfoInEditComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCategoryInfoInEditQuery,
    GetCategoryInfoInEditQueryVariables
  >
    query={GetCategoryInfoInEditDocument}
    {...props}
  />
);

export type GetCategoryInfoInEditProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetCategoryInfoInEditQuery,
  GetCategoryInfoInEditQueryVariables
> &
  TChildProps;
export function withGetCategoryInfoInEdit<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCategoryInfoInEditQuery,
    GetCategoryInfoInEditQueryVariables,
    GetCategoryInfoInEditProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCategoryInfoInEditQuery,
    GetCategoryInfoInEditQueryVariables,
    GetCategoryInfoInEditProps<TChildProps>
  >(GetCategoryInfoInEditDocument, {
    alias: 'withGetCategoryInfoInEdit',
    ...operationOptions
  });
}

export function useGetCategoryInfoInEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCategoryInfoInEditQuery,
    GetCategoryInfoInEditQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCategoryInfoInEditQuery,
    GetCategoryInfoInEditQueryVariables
  >(GetCategoryInfoInEditDocument, baseOptions);
}
export type GetCategoryInfoInEditQueryHookResult = ReturnType<
  typeof useGetCategoryInfoInEditQuery
>;
export type GetCategoryInfoInEditQueryResult = ApolloReactCommon.QueryResult<
  GetCategoryInfoInEditQuery,
  GetCategoryInfoInEditQueryVariables
>;
export const CreateCourseDocument = gql`
  mutation createCourse($input: CreateCourseInput!) {
    createCourse(input: $input) {
      course {
        title
      }
      errors {
        code
        key
        message
      }
    }
  }
`;
export type CreateCourseMutationFn = ApolloReactCommon.MutationFunction<
  CreateCourseMutation,
  CreateCourseMutationVariables
>;
export type CreateCourseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCourseMutation,
    CreateCourseMutationVariables
  >,
  'mutation'
>;

export const CreateCourseComponent = (props: CreateCourseComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateCourseMutation,
    CreateCourseMutationVariables
  >
    mutation={CreateCourseDocument}
    {...props}
  />
);

export type CreateCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCourseMutation,
  CreateCourseMutationVariables
> &
  TChildProps;
export function withCreateCourse<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCourseMutation,
    CreateCourseMutationVariables,
    CreateCourseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCourseMutation,
    CreateCourseMutationVariables,
    CreateCourseProps<TChildProps>
  >(CreateCourseDocument, {
    alias: 'withCreateCourse',
    ...operationOptions
  });
}

export function useCreateCourseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCourseMutation,
    CreateCourseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCourseMutation,
    CreateCourseMutationVariables
  >(CreateCourseDocument, baseOptions);
}
export type CreateCourseMutationHookResult = ReturnType<
  typeof useCreateCourseMutation
>;
export type CreateCourseMutationResult = ApolloReactCommon.MutationResult<
  CreateCourseMutation
>;
export type CreateCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCourseMutation,
  CreateCourseMutationVariables
>;
export const UpdateCourseDocument = gql`
  mutation updateCourse($input: UpdateCourseInput!) {
    updateCourse(input: $input) {
      course {
        title
      }
      errors {
        code
        key
        message
      }
    }
  }
`;
export type UpdateCourseMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
>;
export type UpdateCourseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCourseMutation,
    UpdateCourseMutationVariables
  >,
  'mutation'
>;

export const UpdateCourseComponent = (props: UpdateCourseComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateCourseMutation,
    UpdateCourseMutationVariables
  >
    mutation={UpdateCourseDocument}
    {...props}
  />
);

export type UpdateCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
> &
  TChildProps;
export function withUpdateCourse<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCourseMutation,
    UpdateCourseMutationVariables,
    UpdateCourseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCourseMutation,
    UpdateCourseMutationVariables,
    UpdateCourseProps<TChildProps>
  >(UpdateCourseDocument, {
    alias: 'withUpdateCourse',
    ...operationOptions
  });
}

export function useUpdateCourseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCourseMutation,
    UpdateCourseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCourseMutation,
    UpdateCourseMutationVariables
  >(UpdateCourseDocument, baseOptions);
}
export type UpdateCourseMutationHookResult = ReturnType<
  typeof useUpdateCourseMutation
>;
export type UpdateCourseMutationResult = ApolloReactCommon.MutationResult<
  UpdateCourseMutation
>;
export type UpdateCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
>;
export const OpenCloseDiscussionDocument = gql`
  mutation openCloseDiscussion($input: OpenOrCloseDiscussionPackageInput!) {
    openOrCloseDiscussionPackage(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
export type OpenCloseDiscussionMutationFn = ApolloReactCommon.MutationFunction<
  OpenCloseDiscussionMutation,
  OpenCloseDiscussionMutationVariables
>;
export type OpenCloseDiscussionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OpenCloseDiscussionMutation,
    OpenCloseDiscussionMutationVariables
  >,
  'mutation'
>;

export const OpenCloseDiscussionComponent = (
  props: OpenCloseDiscussionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OpenCloseDiscussionMutation,
    OpenCloseDiscussionMutationVariables
  >
    mutation={OpenCloseDiscussionDocument}
    {...props}
  />
);

export type OpenCloseDiscussionProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  OpenCloseDiscussionMutation,
  OpenCloseDiscussionMutationVariables
> &
  TChildProps;
export function withOpenCloseDiscussion<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OpenCloseDiscussionMutation,
    OpenCloseDiscussionMutationVariables,
    OpenCloseDiscussionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    OpenCloseDiscussionMutation,
    OpenCloseDiscussionMutationVariables,
    OpenCloseDiscussionProps<TChildProps>
  >(OpenCloseDiscussionDocument, {
    alias: 'withOpenCloseDiscussion',
    ...operationOptions
  });
}

export function useOpenCloseDiscussionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OpenCloseDiscussionMutation,
    OpenCloseDiscussionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    OpenCloseDiscussionMutation,
    OpenCloseDiscussionMutationVariables
  >(OpenCloseDiscussionDocument, baseOptions);
}
export type OpenCloseDiscussionMutationHookResult = ReturnType<
  typeof useOpenCloseDiscussionMutation
>;
export type OpenCloseDiscussionMutationResult = ApolloReactCommon.MutationResult<
  OpenCloseDiscussionMutation
>;
export type OpenCloseDiscussionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OpenCloseDiscussionMutation,
  OpenCloseDiscussionMutationVariables
>;
export const ReplacePackageForCourseDocument = gql`
  mutation replacePackageForCourse($input: ReplacePackageForCourseInput!) {
    replacePackageForCourse(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
export type ReplacePackageForCourseMutationFn = ApolloReactCommon.MutationFunction<
  ReplacePackageForCourseMutation,
  ReplacePackageForCourseMutationVariables
>;
export type ReplacePackageForCourseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReplacePackageForCourseMutation,
    ReplacePackageForCourseMutationVariables
  >,
  'mutation'
>;

export const ReplacePackageForCourseComponent = (
  props: ReplacePackageForCourseComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ReplacePackageForCourseMutation,
    ReplacePackageForCourseMutationVariables
  >
    mutation={ReplacePackageForCourseDocument}
    {...props}
  />
);

export type ReplacePackageForCourseProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  ReplacePackageForCourseMutation,
  ReplacePackageForCourseMutationVariables
> &
  TChildProps;
export function withReplacePackageForCourse<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReplacePackageForCourseMutation,
    ReplacePackageForCourseMutationVariables,
    ReplacePackageForCourseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ReplacePackageForCourseMutation,
    ReplacePackageForCourseMutationVariables,
    ReplacePackageForCourseProps<TChildProps>
  >(ReplacePackageForCourseDocument, {
    alias: 'withReplacePackageForCourse',
    ...operationOptions
  });
}

export function useReplacePackageForCourseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReplacePackageForCourseMutation,
    ReplacePackageForCourseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReplacePackageForCourseMutation,
    ReplacePackageForCourseMutationVariables
  >(ReplacePackageForCourseDocument, baseOptions);
}
export type ReplacePackageForCourseMutationHookResult = ReturnType<
  typeof useReplacePackageForCourseMutation
>;
export type ReplacePackageForCourseMutationResult = ApolloReactCommon.MutationResult<
  ReplacePackageForCourseMutation
>;
export type ReplacePackageForCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReplacePackageForCourseMutation,
  ReplacePackageForCourseMutationVariables
>;
export const DeleteCoursePackageDocument = gql`
  mutation deleteCoursePackage($input: DeleteCoursePackageInput!) {
    deleteCoursePackage(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
export type DeleteCoursePackageMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCoursePackageMutation,
  DeleteCoursePackageMutationVariables
>;
export type DeleteCoursePackageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCoursePackageMutation,
    DeleteCoursePackageMutationVariables
  >,
  'mutation'
>;

export const DeleteCoursePackageComponent = (
  props: DeleteCoursePackageComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteCoursePackageMutation,
    DeleteCoursePackageMutationVariables
  >
    mutation={DeleteCoursePackageDocument}
    {...props}
  />
);

export type DeleteCoursePackageProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteCoursePackageMutation,
  DeleteCoursePackageMutationVariables
> &
  TChildProps;
export function withDeleteCoursePackage<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCoursePackageMutation,
    DeleteCoursePackageMutationVariables,
    DeleteCoursePackageProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCoursePackageMutation,
    DeleteCoursePackageMutationVariables,
    DeleteCoursePackageProps<TChildProps>
  >(DeleteCoursePackageDocument, {
    alias: 'withDeleteCoursePackage',
    ...operationOptions
  });
}

export function useDeleteCoursePackageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCoursePackageMutation,
    DeleteCoursePackageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCoursePackageMutation,
    DeleteCoursePackageMutationVariables
  >(DeleteCoursePackageDocument, baseOptions);
}
export type DeleteCoursePackageMutationHookResult = ReturnType<
  typeof useDeleteCoursePackageMutation
>;
export type DeleteCoursePackageMutationResult = ApolloReactCommon.MutationResult<
  DeleteCoursePackageMutation
>;
export type DeleteCoursePackageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCoursePackageMutation,
  DeleteCoursePackageMutationVariables
>;
export const GetEduCreatedCoursePackagesDocument = gql`
  query GetEduCreatedCoursePackages(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $filter: PackageFilter
  ) {
    packages(
      after: $after
      first: $first
      before: $before
      last: $last
      filter: $filter
    ) {
      edges {
        cursor
        node {
          name
          id
        }
      }
    }
  }
`;
export type GetEduCreatedCoursePackagesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetEduCreatedCoursePackagesQuery,
    GetEduCreatedCoursePackagesQueryVariables
  >,
  'query'
>;

export const GetEduCreatedCoursePackagesComponent = (
  props: GetEduCreatedCoursePackagesComponentProps
) => (
  <ApolloReactComponents.Query<
    GetEduCreatedCoursePackagesQuery,
    GetEduCreatedCoursePackagesQueryVariables
  >
    query={GetEduCreatedCoursePackagesDocument}
    {...props}
  />
);

export type GetEduCreatedCoursePackagesProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetEduCreatedCoursePackagesQuery,
  GetEduCreatedCoursePackagesQueryVariables
> &
  TChildProps;
export function withGetEduCreatedCoursePackages<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetEduCreatedCoursePackagesQuery,
    GetEduCreatedCoursePackagesQueryVariables,
    GetEduCreatedCoursePackagesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetEduCreatedCoursePackagesQuery,
    GetEduCreatedCoursePackagesQueryVariables,
    GetEduCreatedCoursePackagesProps<TChildProps>
  >(GetEduCreatedCoursePackagesDocument, {
    alias: 'withGetEduCreatedCoursePackages',
    ...operationOptions
  });
}

export function useGetEduCreatedCoursePackagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetEduCreatedCoursePackagesQuery,
    GetEduCreatedCoursePackagesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetEduCreatedCoursePackagesQuery,
    GetEduCreatedCoursePackagesQueryVariables
  >(GetEduCreatedCoursePackagesDocument, baseOptions);
}
export type GetEduCreatedCoursePackagesQueryHookResult = ReturnType<
  typeof useGetEduCreatedCoursePackagesQuery
>;
export type GetEduCreatedCoursePackagesQueryResult = ApolloReactCommon.QueryResult<
  GetEduCreatedCoursePackagesQuery,
  GetEduCreatedCoursePackagesQueryVariables
>;
export const CreateCardDocument = gql`
  mutation CreateCard($input: CreateCardInput!) {
    createCard(input: $input) {
      errors {
        code
        key
        message
      }
      card {
        id
        name
        package {
          id
        }
        parent {
          id
        }
      }
    }
  }
`;
export type CreateCardMutationFn = ApolloReactCommon.MutationFunction<
  CreateCardMutation,
  CreateCardMutationVariables
>;
export type CreateCardComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCardMutation,
    CreateCardMutationVariables
  >,
  'mutation'
>;

export const CreateCardComponent = (props: CreateCardComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateCardMutation,
    CreateCardMutationVariables
  >
    mutation={CreateCardDocument}
    {...props}
  />
);

export type CreateCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCardMutation,
  CreateCardMutationVariables
> &
  TChildProps;
export function withCreateCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCardMutation,
    CreateCardMutationVariables,
    CreateCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCardMutation,
    CreateCardMutationVariables,
    CreateCardProps<TChildProps>
  >(CreateCardDocument, {
    alias: 'withCreateCard',
    ...operationOptions
  });
}

export function useCreateCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCardMutation,
    CreateCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCardMutation,
    CreateCardMutationVariables
  >(CreateCardDocument, baseOptions);
}
export type CreateCardMutationHookResult = ReturnType<
  typeof useCreateCardMutation
>;
export type CreateCardMutationResult = ApolloReactCommon.MutationResult<
  CreateCardMutation
>;
export type CreateCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCardMutation,
  CreateCardMutationVariables
>;
export const ModifyCardDocument = gql`
  mutation ModifyCard($input: UpdateCardContentInput!) {
    updateCardContent(input: $input) {
      errors {
        code
        key
        message
      }
      card {
        id
        name
        content
      }
    }
  }
`;
export type ModifyCardMutationFn = ApolloReactCommon.MutationFunction<
  ModifyCardMutation,
  ModifyCardMutationVariables
>;
export type ModifyCardComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ModifyCardMutation,
    ModifyCardMutationVariables
  >,
  'mutation'
>;

export const ModifyCardComponent = (props: ModifyCardComponentProps) => (
  <ApolloReactComponents.Mutation<
    ModifyCardMutation,
    ModifyCardMutationVariables
  >
    mutation={ModifyCardDocument}
    {...props}
  />
);

export type ModifyCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ModifyCardMutation,
  ModifyCardMutationVariables
> &
  TChildProps;
export function withModifyCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ModifyCardMutation,
    ModifyCardMutationVariables,
    ModifyCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ModifyCardMutation,
    ModifyCardMutationVariables,
    ModifyCardProps<TChildProps>
  >(ModifyCardDocument, {
    alias: 'withModifyCard',
    ...operationOptions
  });
}

export function useModifyCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ModifyCardMutation,
    ModifyCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ModifyCardMutation,
    ModifyCardMutationVariables
  >(ModifyCardDocument, baseOptions);
}
export type ModifyCardMutationHookResult = ReturnType<
  typeof useModifyCardMutation
>;
export type ModifyCardMutationResult = ApolloReactCommon.MutationResult<
  ModifyCardMutation
>;
export type ModifyCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ModifyCardMutation,
  ModifyCardMutationVariables
>;
export const DeleteCardDocument = gql`
  mutation DeleteCard($input: DeleteCardsInput!) {
    deleteCards(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
export type DeleteCardMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCardMutation,
  DeleteCardMutationVariables
>;
export type DeleteCardComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCardMutation,
    DeleteCardMutationVariables
  >,
  'mutation'
>;

export const DeleteCardComponent = (props: DeleteCardComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteCardMutation,
    DeleteCardMutationVariables
  >
    mutation={DeleteCardDocument}
    {...props}
  />
);

export type DeleteCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCardMutation,
  DeleteCardMutationVariables
> &
  TChildProps;
export function withDeleteCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCardMutation,
    DeleteCardMutationVariables,
    DeleteCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCardMutation,
    DeleteCardMutationVariables,
    DeleteCardProps<TChildProps>
  >(DeleteCardDocument, {
    alias: 'withDeleteCard',
    ...operationOptions
  });
}

export function useDeleteCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCardMutation,
    DeleteCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCardMutation,
    DeleteCardMutationVariables
  >(DeleteCardDocument, baseOptions);
}
export type DeleteCardMutationHookResult = ReturnType<
  typeof useDeleteCardMutation
>;
export type DeleteCardMutationResult = ApolloReactCommon.MutationResult<
  DeleteCardMutation
>;
export type DeleteCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCardMutation,
  DeleteCardMutationVariables
>;
export const GetCardInfoDocument = gql`
  query GetCardInfo($id: ID!) {
    node(id: $id) {
      ... on Card {
        id
        name
        sourceContent
      }
    }
  }
`;
export type GetCardInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCardInfoQuery,
    GetCardInfoQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCardInfoQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCardInfoComponent = (props: GetCardInfoComponentProps) => (
  <ApolloReactComponents.Query<GetCardInfoQuery, GetCardInfoQueryVariables>
    query={GetCardInfoDocument}
    {...props}
  />
);

export type GetCardInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetCardInfoQuery,
  GetCardInfoQueryVariables
> &
  TChildProps;
export function withGetCardInfo<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCardInfoQuery,
    GetCardInfoQueryVariables,
    GetCardInfoProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCardInfoQuery,
    GetCardInfoQueryVariables,
    GetCardInfoProps<TChildProps>
  >(GetCardInfoDocument, {
    alias: 'withGetCardInfo',
    ...operationOptions
  });
}

export function useGetCardInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCardInfoQuery,
    GetCardInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCardInfoQuery, GetCardInfoQueryVariables>(
    GetCardInfoDocument,
    baseOptions
  );
}
export type GetCardInfoQueryHookResult = ReturnType<typeof useGetCardInfoQuery>;
export type GetCardInfoQueryResult = ApolloReactCommon.QueryResult<
  GetCardInfoQuery,
  GetCardInfoQueryVariables
>;
export const GetCardInfoContentDocument = gql`
  query GetCardInfoContent($id: ID!) {
    node(id: $id) {
      ... on Card {
        id
        name
        content
      }
    }
  }
`;
export type GetCardInfoContentComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCardInfoContentQuery,
    GetCardInfoContentQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCardInfoContentQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCardInfoContentComponent = (
  props: GetCardInfoContentComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCardInfoContentQuery,
    GetCardInfoContentQueryVariables
  >
    query={GetCardInfoContentDocument}
    {...props}
  />
);

export type GetCardInfoContentProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetCardInfoContentQuery,
  GetCardInfoContentQueryVariables
> &
  TChildProps;
export function withGetCardInfoContent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCardInfoContentQuery,
    GetCardInfoContentQueryVariables,
    GetCardInfoContentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCardInfoContentQuery,
    GetCardInfoContentQueryVariables,
    GetCardInfoContentProps<TChildProps>
  >(GetCardInfoContentDocument, {
    alias: 'withGetCardInfoContent',
    ...operationOptions
  });
}

export function useGetCardInfoContentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCardInfoContentQuery,
    GetCardInfoContentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCardInfoContentQuery,
    GetCardInfoContentQueryVariables
  >(GetCardInfoContentDocument, baseOptions);
}
export type GetCardInfoContentQueryHookResult = ReturnType<
  typeof useGetCardInfoContentQuery
>;
export type GetCardInfoContentQueryResult = ApolloReactCommon.QueryResult<
  GetCardInfoContentQuery,
  GetCardInfoContentQueryVariables
>;
export const ModifyCardsDocument = gql`
  mutation ModifyCards($input: ModifyCardsInput!) {
    modifyCards(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
export type ModifyCardsMutationFn = ApolloReactCommon.MutationFunction<
  ModifyCardsMutation,
  ModifyCardsMutationVariables
>;
export type ModifyCardsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ModifyCardsMutation,
    ModifyCardsMutationVariables
  >,
  'mutation'
>;

export const ModifyCardsComponent = (props: ModifyCardsComponentProps) => (
  <ApolloReactComponents.Mutation<
    ModifyCardsMutation,
    ModifyCardsMutationVariables
  >
    mutation={ModifyCardsDocument}
    {...props}
  />
);

export type ModifyCardsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ModifyCardsMutation,
  ModifyCardsMutationVariables
> &
  TChildProps;
export function withModifyCards<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ModifyCardsMutation,
    ModifyCardsMutationVariables,
    ModifyCardsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ModifyCardsMutation,
    ModifyCardsMutationVariables,
    ModifyCardsProps<TChildProps>
  >(ModifyCardsDocument, {
    alias: 'withModifyCards',
    ...operationOptions
  });
}

export function useModifyCardsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ModifyCardsMutation,
    ModifyCardsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ModifyCardsMutation,
    ModifyCardsMutationVariables
  >(ModifyCardsDocument, baseOptions);
}
export type ModifyCardsMutationHookResult = ReturnType<
  typeof useModifyCardsMutation
>;
export type ModifyCardsMutationResult = ApolloReactCommon.MutationResult<
  ModifyCardsMutation
>;
export type ModifyCardsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ModifyCardsMutation,
  ModifyCardsMutationVariables
>;
export const GetCardsForModifyDocument = gql`
  query GetCardsForModify($ids: [ID!], $packageId: ID!) {
    cards(ids: $ids, packageId: $packageId) {
      id
      name
      sourceContent
    }
  }
`;
export type GetCardsForModifyComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCardsForModifyQuery,
    GetCardsForModifyQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCardsForModifyQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCardsForModifyComponent = (
  props: GetCardsForModifyComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCardsForModifyQuery,
    GetCardsForModifyQueryVariables
  >
    query={GetCardsForModifyDocument}
    {...props}
  />
);

export type GetCardsForModifyProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetCardsForModifyQuery,
  GetCardsForModifyQueryVariables
> &
  TChildProps;
export function withGetCardsForModify<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCardsForModifyQuery,
    GetCardsForModifyQueryVariables,
    GetCardsForModifyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCardsForModifyQuery,
    GetCardsForModifyQueryVariables,
    GetCardsForModifyProps<TChildProps>
  >(GetCardsForModifyDocument, {
    alias: 'withGetCardsForModify',
    ...operationOptions
  });
}

export function useGetCardsForModifyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCardsForModifyQuery,
    GetCardsForModifyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCardsForModifyQuery,
    GetCardsForModifyQueryVariables
  >(GetCardsForModifyDocument, baseOptions);
}
export type GetCardsForModifyQueryHookResult = ReturnType<
  typeof useGetCardsForModifyQuery
>;
export type GetCardsForModifyQueryResult = ApolloReactCommon.QueryResult<
  GetCardsForModifyQuery,
  GetCardsForModifyQueryVariables
>;
export const GetEduCreatedPackagesDocument = gql`
  query GetEduCreatedPackages(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $filter: PackageFilter
  ) {
    packages(
      after: $after
      first: $first
      before: $before
      last: $last
      filter: $filter
    ) {
      edges {
        cursor
        node {
          name
          id
          hasFolderCard
        }
      }
    }
  }
`;
export type GetEduCreatedPackagesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetEduCreatedPackagesQuery,
    GetEduCreatedPackagesQueryVariables
  >,
  'query'
>;

export const GetEduCreatedPackagesComponent = (
  props: GetEduCreatedPackagesComponentProps
) => (
  <ApolloReactComponents.Query<
    GetEduCreatedPackagesQuery,
    GetEduCreatedPackagesQueryVariables
  >
    query={GetEduCreatedPackagesDocument}
    {...props}
  />
);

export type GetEduCreatedPackagesProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetEduCreatedPackagesQuery,
  GetEduCreatedPackagesQueryVariables
> &
  TChildProps;
export function withGetEduCreatedPackages<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetEduCreatedPackagesQuery,
    GetEduCreatedPackagesQueryVariables,
    GetEduCreatedPackagesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetEduCreatedPackagesQuery,
    GetEduCreatedPackagesQueryVariables,
    GetEduCreatedPackagesProps<TChildProps>
  >(GetEduCreatedPackagesDocument, {
    alias: 'withGetEduCreatedPackages',
    ...operationOptions
  });
}

export function useGetEduCreatedPackagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetEduCreatedPackagesQuery,
    GetEduCreatedPackagesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetEduCreatedPackagesQuery,
    GetEduCreatedPackagesQueryVariables
  >(GetEduCreatedPackagesDocument, baseOptions);
}
export type GetEduCreatedPackagesQueryHookResult = ReturnType<
  typeof useGetEduCreatedPackagesQuery
>;
export type GetEduCreatedPackagesQueryResult = ApolloReactCommon.QueryResult<
  GetEduCreatedPackagesQuery,
  GetEduCreatedPackagesQueryVariables
>;
export const GetEduCreatedPackageInfoDocument = gql`
  query GetEduCreatedPackageInfo($id: ID!, $isMaster: Boolean!) {
    node(id: $id) {
      ... on Package {
        hasFolderCard
        name
        id
        rootCards(first: 1000) {
          edges {
            node {
              id
              name
              hasFolderChild(isMaster: $isMaster)
              fileType
              collaborativeState
              package {
                id
              }
              parent(isMaster: $isMaster) {
                id
              }
            }
          }
        }
      }
      ... on Card {
        name
        id
        fileType
        collaborativeState
        package {
          id
        }
        parent(isMaster: $isMaster) {
          id
        }
        hasFolderChild(isMaster: $isMaster)
        children(ignoreAns: true, isMaster: $isMaster) {
          name
          id
          fileType
          collaborativeState
          hasFolderChild(isMaster: $isMaster)
          package {
            id
          }
          parent(isMaster: $isMaster) {
            id
          }
        }
      }
    }
  }
`;
export type GetEduCreatedPackageInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetEduCreatedPackageInfoQuery,
    GetEduCreatedPackageInfoQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetEduCreatedPackageInfoQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetEduCreatedPackageInfoComponent = (
  props: GetEduCreatedPackageInfoComponentProps
) => (
  <ApolloReactComponents.Query<
    GetEduCreatedPackageInfoQuery,
    GetEduCreatedPackageInfoQueryVariables
  >
    query={GetEduCreatedPackageInfoDocument}
    {...props}
  />
);

export type GetEduCreatedPackageInfoProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetEduCreatedPackageInfoQuery,
  GetEduCreatedPackageInfoQueryVariables
> &
  TChildProps;
export function withGetEduCreatedPackageInfo<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetEduCreatedPackageInfoQuery,
    GetEduCreatedPackageInfoQueryVariables,
    GetEduCreatedPackageInfoProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetEduCreatedPackageInfoQuery,
    GetEduCreatedPackageInfoQueryVariables,
    GetEduCreatedPackageInfoProps<TChildProps>
  >(GetEduCreatedPackageInfoDocument, {
    alias: 'withGetEduCreatedPackageInfo',
    ...operationOptions
  });
}

export function useGetEduCreatedPackageInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetEduCreatedPackageInfoQuery,
    GetEduCreatedPackageInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetEduCreatedPackageInfoQuery,
    GetEduCreatedPackageInfoQueryVariables
  >(GetEduCreatedPackageInfoDocument, baseOptions);
}
export type GetEduCreatedPackageInfoQueryHookResult = ReturnType<
  typeof useGetEduCreatedPackageInfoQuery
>;
export type GetEduCreatedPackageInfoQueryResult = ApolloReactCommon.QueryResult<
  GetEduCreatedPackageInfoQuery,
  GetEduCreatedPackageInfoQueryVariables
>;
export const GetDataWithParentDataDocument = gql`
  query GetDataWithParentData($id: ID!, $isMaster: Boolean!) {
    node(id: $id) {
      ... on Package {
        hasFolderCard
        name
        id
        rootCards(first: 1000) {
          edges {
            node {
              id
              name
              hasFolderChild(isMaster: $isMaster)
              fileType
              collaborativeState
              package {
                id
              }
              parent(isMaster: $isMaster) {
                id
              }
            }
          }
        }
      }
      ... on Card {
        name
        id
        fileType
        hasFolderChild(isMaster: $isMaster)
        collaborativeState
        package {
          id
        }
        parent(isMaster: $isMaster) {
          id
        }
        children(ignoreAns: true, isMaster: $isMaster) {
          name
          id
          fileType
          collaborativeState
          hasFolderChild(isMaster: $isMaster)
          package {
            id
          }
          parent(isMaster: $isMaster) {
            id
          }
        }
        ancestors(isMaster: $isMaster) {
          name
          id
          fileType
          hasFolderChild(isMaster: $isMaster)
          collaborativeState
          depth
          children(ignoreAns: true, isMaster: $isMaster) {
            name
            id
            fileType
            hasFolderChild(isMaster: $isMaster)
            package {
              id
            }
            parent(isMaster: $isMaster) {
              id
            }
          }
        }
      }
    }
  }
`;
export type GetDataWithParentDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetDataWithParentDataQuery,
    GetDataWithParentDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetDataWithParentDataQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetDataWithParentDataComponent = (
  props: GetDataWithParentDataComponentProps
) => (
  <ApolloReactComponents.Query<
    GetDataWithParentDataQuery,
    GetDataWithParentDataQueryVariables
  >
    query={GetDataWithParentDataDocument}
    {...props}
  />
);

export type GetDataWithParentDataProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetDataWithParentDataQuery,
  GetDataWithParentDataQueryVariables
> &
  TChildProps;
export function withGetDataWithParentData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetDataWithParentDataQuery,
    GetDataWithParentDataQueryVariables,
    GetDataWithParentDataProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetDataWithParentDataQuery,
    GetDataWithParentDataQueryVariables,
    GetDataWithParentDataProps<TChildProps>
  >(GetDataWithParentDataDocument, {
    alias: 'withGetDataWithParentData',
    ...operationOptions
  });
}

export function useGetDataWithParentDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetDataWithParentDataQuery,
    GetDataWithParentDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetDataWithParentDataQuery,
    GetDataWithParentDataQueryVariables
  >(GetDataWithParentDataDocument, baseOptions);
}
export type GetDataWithParentDataQueryHookResult = ReturnType<
  typeof useGetDataWithParentDataQuery
>;
export type GetDataWithParentDataQueryResult = ApolloReactCommon.QueryResult<
  GetDataWithParentDataQuery,
  GetDataWithParentDataQueryVariables
>;
export const GetSearchCardDocument = gql`
  query GetSearchCard($packageId: ID!, $matching: String!, $folderCardId: ID) {
    searchCardsInside(
      packageId: $packageId
      matching: $matching
      folderCardId: $folderCardId
    ) {
      name
      id
      fileType
    }
  }
`;
export type GetSearchCardComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSearchCardQuery,
    GetSearchCardQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetSearchCardQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetSearchCardComponent = (props: GetSearchCardComponentProps) => (
  <ApolloReactComponents.Query<GetSearchCardQuery, GetSearchCardQueryVariables>
    query={GetSearchCardDocument}
    {...props}
  />
);

export type GetSearchCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetSearchCardQuery,
  GetSearchCardQueryVariables
> &
  TChildProps;
export function withGetSearchCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSearchCardQuery,
    GetSearchCardQueryVariables,
    GetSearchCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSearchCardQuery,
    GetSearchCardQueryVariables,
    GetSearchCardProps<TChildProps>
  >(GetSearchCardDocument, {
    alias: 'withGetSearchCard',
    ...operationOptions
  });
}

export function useGetSearchCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSearchCardQuery,
    GetSearchCardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSearchCardQuery,
    GetSearchCardQueryVariables
  >(GetSearchCardDocument, baseOptions);
}
export type GetSearchCardQueryHookResult = ReturnType<
  typeof useGetSearchCardQuery
>;
export type GetSearchCardQueryResult = ApolloReactCommon.QueryResult<
  GetSearchCardQuery,
  GetSearchCardQueryVariables
>;
export const SortCardsDocument = gql`
  mutation SortCards($input: SortFolderInput!) {
    sortFolder(input: $input) {
      errors {
        key
        code
        message
      }
    }
  }
`;
export type SortCardsMutationFn = ApolloReactCommon.MutationFunction<
  SortCardsMutation,
  SortCardsMutationVariables
>;
export type SortCardsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SortCardsMutation,
    SortCardsMutationVariables
  >,
  'mutation'
>;

export const SortCardsComponent = (props: SortCardsComponentProps) => (
  <ApolloReactComponents.Mutation<SortCardsMutation, SortCardsMutationVariables>
    mutation={SortCardsDocument}
    {...props}
  />
);

export type SortCardsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  SortCardsMutation,
  SortCardsMutationVariables
> &
  TChildProps;
export function withSortCards<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SortCardsMutation,
    SortCardsMutationVariables,
    SortCardsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SortCardsMutation,
    SortCardsMutationVariables,
    SortCardsProps<TChildProps>
  >(SortCardsDocument, {
    alias: 'withSortCards',
    ...operationOptions
  });
}

export function useSortCardsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SortCardsMutation,
    SortCardsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SortCardsMutation,
    SortCardsMutationVariables
  >(SortCardsDocument, baseOptions);
}
export type SortCardsMutationHookResult = ReturnType<
  typeof useSortCardsMutation
>;
export type SortCardsMutationResult = ApolloReactCommon.MutationResult<
  SortCardsMutation
>;
export type SortCardsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SortCardsMutation,
  SortCardsMutationVariables
>;
export const DeleteCardFromAllBranchesDocument = gql`
  mutation DeleteCardFromAllBranches($input: DeleteCardFromAllBranchesInput!) {
    deleteCardFromAllBranches(input: $input) {
      card {
        id
      }
      errors {
        key
        code
        message
      }
    }
  }
`;
export type DeleteCardFromAllBranchesMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCardFromAllBranchesMutation,
  DeleteCardFromAllBranchesMutationVariables
>;
export type DeleteCardFromAllBranchesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCardFromAllBranchesMutation,
    DeleteCardFromAllBranchesMutationVariables
  >,
  'mutation'
>;

export const DeleteCardFromAllBranchesComponent = (
  props: DeleteCardFromAllBranchesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteCardFromAllBranchesMutation,
    DeleteCardFromAllBranchesMutationVariables
  >
    mutation={DeleteCardFromAllBranchesDocument}
    {...props}
  />
);

export type DeleteCardFromAllBranchesProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteCardFromAllBranchesMutation,
  DeleteCardFromAllBranchesMutationVariables
> &
  TChildProps;
export function withDeleteCardFromAllBranches<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCardFromAllBranchesMutation,
    DeleteCardFromAllBranchesMutationVariables,
    DeleteCardFromAllBranchesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCardFromAllBranchesMutation,
    DeleteCardFromAllBranchesMutationVariables,
    DeleteCardFromAllBranchesProps<TChildProps>
  >(DeleteCardFromAllBranchesDocument, {
    alias: 'withDeleteCardFromAllBranches',
    ...operationOptions
  });
}

export function useDeleteCardFromAllBranchesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCardFromAllBranchesMutation,
    DeleteCardFromAllBranchesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCardFromAllBranchesMutation,
    DeleteCardFromAllBranchesMutationVariables
  >(DeleteCardFromAllBranchesDocument, baseOptions);
}
export type DeleteCardFromAllBranchesMutationHookResult = ReturnType<
  typeof useDeleteCardFromAllBranchesMutation
>;
export type DeleteCardFromAllBranchesMutationResult = ApolloReactCommon.MutationResult<
  DeleteCardFromAllBranchesMutation
>;
export type DeleteCardFromAllBranchesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCardFromAllBranchesMutation,
  DeleteCardFromAllBranchesMutationVariables
>;
export const DeleteCardsDocument = gql`
  mutation DeleteCards($input: DeleteCardsInput!) {
    deleteCards(input: $input) {
      errors {
        key
        code
        message
      }
    }
  }
`;
export type DeleteCardsMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCardsMutation,
  DeleteCardsMutationVariables
>;
export type DeleteCardsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCardsMutation,
    DeleteCardsMutationVariables
  >,
  'mutation'
>;

export const DeleteCardsComponent = (props: DeleteCardsComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteCardsMutation,
    DeleteCardsMutationVariables
  >
    mutation={DeleteCardsDocument}
    {...props}
  />
);

export type DeleteCardsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCardsMutation,
  DeleteCardsMutationVariables
> &
  TChildProps;
export function withDeleteCards<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCardsMutation,
    DeleteCardsMutationVariables,
    DeleteCardsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCardsMutation,
    DeleteCardsMutationVariables,
    DeleteCardsProps<TChildProps>
  >(DeleteCardsDocument, {
    alias: 'withDeleteCards',
    ...operationOptions
  });
}

export function useDeleteCardsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCardsMutation,
    DeleteCardsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCardsMutation,
    DeleteCardsMutationVariables
  >(DeleteCardsDocument, baseOptions);
}
export type DeleteCardsMutationHookResult = ReturnType<
  typeof useDeleteCardsMutation
>;
export type DeleteCardsMutationResult = ApolloReactCommon.MutationResult<
  DeleteCardsMutation
>;
export type DeleteCardsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCardsMutation,
  DeleteCardsMutationVariables
>;
export const UploadCardDocument = gql`
  mutation UploadCard(
    $input: UploadCardInput!
    $metadata: Upload!
    $isMaster: Boolean!
  ) {
    uploadCard(input: $input, metadata: $metadata) {
      card {
        id
        name
        fileType
        collaborativeState
        package {
          id
        }
        parent(isMaster: $isMaster) {
          id
        }
      }
      errors {
        key
        code
        message
      }
    }
  }
`;
export type UploadCardMutationFn = ApolloReactCommon.MutationFunction<
  UploadCardMutation,
  UploadCardMutationVariables
>;
export type UploadCardComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UploadCardMutation,
    UploadCardMutationVariables
  >,
  'mutation'
>;

export const UploadCardComponent = (props: UploadCardComponentProps) => (
  <ApolloReactComponents.Mutation<
    UploadCardMutation,
    UploadCardMutationVariables
  >
    mutation={UploadCardDocument}
    {...props}
  />
);

export type UploadCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UploadCardMutation,
  UploadCardMutationVariables
> &
  TChildProps;
export function withUploadCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UploadCardMutation,
    UploadCardMutationVariables,
    UploadCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UploadCardMutation,
    UploadCardMutationVariables,
    UploadCardProps<TChildProps>
  >(UploadCardDocument, {
    alias: 'withUploadCard',
    ...operationOptions
  });
}

export function useUploadCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadCardMutation,
    UploadCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UploadCardMutation,
    UploadCardMutationVariables
  >(UploadCardDocument, baseOptions);
}
export type UploadCardMutationHookResult = ReturnType<
  typeof useUploadCardMutation
>;
export type UploadCardMutationResult = ApolloReactCommon.MutationResult<
  UploadCardMutation
>;
export type UploadCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadCardMutation,
  UploadCardMutationVariables
>;
export const UploadZipDocument = gql`
  mutation UploadZip(
    $input: UploadZipInput!
    $metadata: Upload!
    $isMaster: Boolean!
  ) {
    uploadZip(input: $input, metadata: $metadata) {
      failedFiles {
        location
        message
      }
      folder {
        id
        fileType
        name
        hasFolderChild(isMaster: $isMaster)
        collaborativeState
        package {
          id
        }
        parent(isMaster: $isMaster) {
          id
        }
        children(ignoreAns: true, isMaster: $isMaster) {
          name
          id
          fileType
          hasFolderChild(isMaster: $isMaster)
          collaborativeState
          package {
            id
          }
          parent(isMaster: $isMaster) {
            id
          }
        }
      }
      package {
        id
      }
      errors {
        key
        code
        message
      }
    }
  }
`;
export type UploadZipMutationFn = ApolloReactCommon.MutationFunction<
  UploadZipMutation,
  UploadZipMutationVariables
>;
export type UploadZipComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UploadZipMutation,
    UploadZipMutationVariables
  >,
  'mutation'
>;

export const UploadZipComponent = (props: UploadZipComponentProps) => (
  <ApolloReactComponents.Mutation<UploadZipMutation, UploadZipMutationVariables>
    mutation={UploadZipDocument}
    {...props}
  />
);

export type UploadZipProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UploadZipMutation,
  UploadZipMutationVariables
> &
  TChildProps;
export function withUploadZip<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UploadZipMutation,
    UploadZipMutationVariables,
    UploadZipProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UploadZipMutation,
    UploadZipMutationVariables,
    UploadZipProps<TChildProps>
  >(UploadZipDocument, {
    alias: 'withUploadZip',
    ...operationOptions
  });
}

export function useUploadZipMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadZipMutation,
    UploadZipMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UploadZipMutation,
    UploadZipMutationVariables
  >(UploadZipDocument, baseOptions);
}
export type UploadZipMutationHookResult = ReturnType<
  typeof useUploadZipMutation
>;
export type UploadZipMutationResult = ApolloReactCommon.MutationResult<
  UploadZipMutation
>;
export type UploadZipMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadZipMutation,
  UploadZipMutationVariables
>;
export const UploadZipToPackageRootDocument = gql`
  mutation UploadZipToPackageRoot(
    $input: UploadZipInput!
    $metadata: Upload!
    $isMaster: Boolean!
  ) {
    uploadZip(input: $input, metadata: $metadata) {
      failedFiles {
        location
        message
      }
      package {
        id
        name
        hasFolderCard
        rootCards(first: 1000) {
          edges {
            node {
              id
              name
              hasFolderChild(isMaster: $isMaster)
              collaborativeState
              fileType
              package {
                id
              }
              parent(isMaster: $isMaster) {
                id
              }
            }
          }
        }
      }
      errors {
        key
        code
        message
      }
    }
  }
`;
export type UploadZipToPackageRootMutationFn = ApolloReactCommon.MutationFunction<
  UploadZipToPackageRootMutation,
  UploadZipToPackageRootMutationVariables
>;
export type UploadZipToPackageRootComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UploadZipToPackageRootMutation,
    UploadZipToPackageRootMutationVariables
  >,
  'mutation'
>;

export const UploadZipToPackageRootComponent = (
  props: UploadZipToPackageRootComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UploadZipToPackageRootMutation,
    UploadZipToPackageRootMutationVariables
  >
    mutation={UploadZipToPackageRootDocument}
    {...props}
  />
);

export type UploadZipToPackageRootProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UploadZipToPackageRootMutation,
  UploadZipToPackageRootMutationVariables
> &
  TChildProps;
export function withUploadZipToPackageRoot<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UploadZipToPackageRootMutation,
    UploadZipToPackageRootMutationVariables,
    UploadZipToPackageRootProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UploadZipToPackageRootMutation,
    UploadZipToPackageRootMutationVariables,
    UploadZipToPackageRootProps<TChildProps>
  >(UploadZipToPackageRootDocument, {
    alias: 'withUploadZipToPackageRoot',
    ...operationOptions
  });
}

export function useUploadZipToPackageRootMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadZipToPackageRootMutation,
    UploadZipToPackageRootMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UploadZipToPackageRootMutation,
    UploadZipToPackageRootMutationVariables
  >(UploadZipToPackageRootDocument, baseOptions);
}
export type UploadZipToPackageRootMutationHookResult = ReturnType<
  typeof useUploadZipToPackageRootMutation
>;
export type UploadZipToPackageRootMutationResult = ApolloReactCommon.MutationResult<
  UploadZipToPackageRootMutation
>;
export type UploadZipToPackageRootMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadZipToPackageRootMutation,
  UploadZipToPackageRootMutationVariables
>;
export const RenameCardDocument = gql`
  mutation RenameCard($input: RenameCardInput!) {
    renameCard(input: $input) {
      card {
        id
        name
      }
      errors {
        key
        code
        message
      }
    }
  }
`;
export type RenameCardMutationFn = ApolloReactCommon.MutationFunction<
  RenameCardMutation,
  RenameCardMutationVariables
>;
export type RenameCardComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RenameCardMutation,
    RenameCardMutationVariables
  >,
  'mutation'
>;

export const RenameCardComponent = (props: RenameCardComponentProps) => (
  <ApolloReactComponents.Mutation<
    RenameCardMutation,
    RenameCardMutationVariables
  >
    mutation={RenameCardDocument}
    {...props}
  />
);

export type RenameCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RenameCardMutation,
  RenameCardMutationVariables
> &
  TChildProps;
export function withRenameCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RenameCardMutation,
    RenameCardMutationVariables,
    RenameCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RenameCardMutation,
    RenameCardMutationVariables,
    RenameCardProps<TChildProps>
  >(RenameCardDocument, {
    alias: 'withRenameCard',
    ...operationOptions
  });
}

export function useRenameCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RenameCardMutation,
    RenameCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RenameCardMutation,
    RenameCardMutationVariables
  >(RenameCardDocument, baseOptions);
}
export type RenameCardMutationHookResult = ReturnType<
  typeof useRenameCardMutation
>;
export type RenameCardMutationResult = ApolloReactCommon.MutationResult<
  RenameCardMutation
>;
export type RenameCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenameCardMutation,
  RenameCardMutationVariables
>;
export const RenamePackageDocument = gql`
  mutation RenamePackage($input: UpdatePackageInput!) {
    updatePackage(input: $input) {
      errors {
        key
        code
        message
      }
      package {
        id
        name
      }
    }
  }
`;
export type RenamePackageMutationFn = ApolloReactCommon.MutationFunction<
  RenamePackageMutation,
  RenamePackageMutationVariables
>;
export type RenamePackageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RenamePackageMutation,
    RenamePackageMutationVariables
  >,
  'mutation'
>;

export const RenamePackageComponent = (props: RenamePackageComponentProps) => (
  <ApolloReactComponents.Mutation<
    RenamePackageMutation,
    RenamePackageMutationVariables
  >
    mutation={RenamePackageDocument}
    {...props}
  />
);

export type RenamePackageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RenamePackageMutation,
  RenamePackageMutationVariables
> &
  TChildProps;
export function withRenamePackage<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RenamePackageMutation,
    RenamePackageMutationVariables,
    RenamePackageProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RenamePackageMutation,
    RenamePackageMutationVariables,
    RenamePackageProps<TChildProps>
  >(RenamePackageDocument, {
    alias: 'withRenamePackage',
    ...operationOptions
  });
}

export function useRenamePackageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RenamePackageMutation,
    RenamePackageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RenamePackageMutation,
    RenamePackageMutationVariables
  >(RenamePackageDocument, baseOptions);
}
export type RenamePackageMutationHookResult = ReturnType<
  typeof useRenamePackageMutation
>;
export type RenamePackageMutationResult = ApolloReactCommon.MutationResult<
  RenamePackageMutation
>;
export type RenamePackageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenamePackageMutation,
  RenamePackageMutationVariables
>;
export const CopyCardsDocument = gql`
  mutation CopyCards($input: CopyCardsInput!, $isMaster: Boolean!) {
    copyCards(input: $input) {
      errors {
        key
        code
        message
      }
      package {
        id
        name
        hasFolderCard
        rootCards(first: 1000) {
          edges {
            node {
              id
              name
              hasFolderChild(isMaster: $isMaster)
              fileType
              collaborativeState
              package {
                id
              }
              parent(isMaster: $isMaster) {
                id
              }
            }
          }
        }
      }
      folder {
        id
        fileType
        name
        hasFolderChild(isMaster: $isMaster)
        package {
          id
        }
        parent(isMaster: $isMaster) {
          id
        }
        children(ignoreAns: true, isMaster: $isMaster) {
          name
          id
          fileType
          hasFolderChild(isMaster: $isMaster)
          package {
            id
          }
          parent(isMaster: $isMaster) {
            id
          }
        }
      }
    }
  }
`;
export type CopyCardsMutationFn = ApolloReactCommon.MutationFunction<
  CopyCardsMutation,
  CopyCardsMutationVariables
>;
export type CopyCardsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CopyCardsMutation,
    CopyCardsMutationVariables
  >,
  'mutation'
>;

export const CopyCardsComponent = (props: CopyCardsComponentProps) => (
  <ApolloReactComponents.Mutation<CopyCardsMutation, CopyCardsMutationVariables>
    mutation={CopyCardsDocument}
    {...props}
  />
);

export type CopyCardsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CopyCardsMutation,
  CopyCardsMutationVariables
> &
  TChildProps;
export function withCopyCards<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CopyCardsMutation,
    CopyCardsMutationVariables,
    CopyCardsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CopyCardsMutation,
    CopyCardsMutationVariables,
    CopyCardsProps<TChildProps>
  >(CopyCardsDocument, {
    alias: 'withCopyCards',
    ...operationOptions
  });
}

export function useCopyCardsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CopyCardsMutation,
    CopyCardsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CopyCardsMutation,
    CopyCardsMutationVariables
  >(CopyCardsDocument, baseOptions);
}
export type CopyCardsMutationHookResult = ReturnType<
  typeof useCopyCardsMutation
>;
export type CopyCardsMutationResult = ApolloReactCommon.MutationResult<
  CopyCardsMutation
>;
export type CopyCardsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CopyCardsMutation,
  CopyCardsMutationVariables
>;
export const MoveCardsDocument = gql`
  mutation MoveCards($input: MoveCardsInput!, $isMaster: Boolean!) {
    moveCards(input: $input) {
      errors {
        key
        code
        message
      }
      package {
        id
        name
        hasFolderCard
        rootCards(first: 1000) {
          edges {
            node {
              id
              name
              collaborativeState
              hasFolderChild(isMaster: $isMaster)
              fileType
              package {
                id
              }
              parent(isMaster: $isMaster) {
                id
              }
            }
          }
        }
      }
      folder {
        id
        fileType
        name
        hasFolderChild(isMaster: $isMaster)
        collaborativeState
        package {
          id
        }
        parent(isMaster: $isMaster) {
          id
        }
        children(ignoreAns: true, isMaster: $isMaster) {
          name
          id
          fileType
          collaborativeState
          hasFolderChild(isMaster: $isMaster)
          package {
            id
          }
          parent(isMaster: $isMaster) {
            id
          }
        }
      }
    }
  }
`;
export type MoveCardsMutationFn = ApolloReactCommon.MutationFunction<
  MoveCardsMutation,
  MoveCardsMutationVariables
>;
export type MoveCardsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MoveCardsMutation,
    MoveCardsMutationVariables
  >,
  'mutation'
>;

export const MoveCardsComponent = (props: MoveCardsComponentProps) => (
  <ApolloReactComponents.Mutation<MoveCardsMutation, MoveCardsMutationVariables>
    mutation={MoveCardsDocument}
    {...props}
  />
);

export type MoveCardsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  MoveCardsMutation,
  MoveCardsMutationVariables
> &
  TChildProps;
export function withMoveCards<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MoveCardsMutation,
    MoveCardsMutationVariables,
    MoveCardsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    MoveCardsMutation,
    MoveCardsMutationVariables,
    MoveCardsProps<TChildProps>
  >(MoveCardsDocument, {
    alias: 'withMoveCards',
    ...operationOptions
  });
}

export function useMoveCardsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MoveCardsMutation,
    MoveCardsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    MoveCardsMutation,
    MoveCardsMutationVariables
  >(MoveCardsDocument, baseOptions);
}
export type MoveCardsMutationHookResult = ReturnType<
  typeof useMoveCardsMutation
>;
export type MoveCardsMutationResult = ApolloReactCommon.MutationResult<
  MoveCardsMutation
>;
export type MoveCardsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MoveCardsMutation,
  MoveCardsMutationVariables
>;
export const DeletePackageDocument = gql`
  mutation DeletePackage($input: DeletePackageInput!) {
    deletePackage(input: $input) {
      errors {
        key
        code
        message
      }
    }
  }
`;
export type DeletePackageMutationFn = ApolloReactCommon.MutationFunction<
  DeletePackageMutation,
  DeletePackageMutationVariables
>;
export type DeletePackageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeletePackageMutation,
    DeletePackageMutationVariables
  >,
  'mutation'
>;

export const DeletePackageComponent = (props: DeletePackageComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeletePackageMutation,
    DeletePackageMutationVariables
  >
    mutation={DeletePackageDocument}
    {...props}
  />
);

export type DeletePackageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeletePackageMutation,
  DeletePackageMutationVariables
> &
  TChildProps;
export function withDeletePackage<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeletePackageMutation,
    DeletePackageMutationVariables,
    DeletePackageProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeletePackageMutation,
    DeletePackageMutationVariables,
    DeletePackageProps<TChildProps>
  >(DeletePackageDocument, {
    alias: 'withDeletePackage',
    ...operationOptions
  });
}

export function useDeletePackageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePackageMutation,
    DeletePackageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeletePackageMutation,
    DeletePackageMutationVariables
  >(DeletePackageDocument, baseOptions);
}
export type DeletePackageMutationHookResult = ReturnType<
  typeof useDeletePackageMutation
>;
export type DeletePackageMutationResult = ApolloReactCommon.MutationResult<
  DeletePackageMutation
>;
export type DeletePackageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePackageMutation,
  DeletePackageMutationVariables
>;
export const DiffChangesDocument = gql`
  query DiffChanges($currentPackageId: ID!, $diffType: DiffType!) {
    diff(currentPackageId: $currentPackageId, diffType: $diffType) {
      changes
    }
  }
`;
export type DiffChangesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DiffChangesQuery,
    DiffChangesQueryVariables
  >,
  'query'
> &
  (
    | { variables: DiffChangesQueryVariables; skip?: boolean }
    | { skip: boolean });

export const DiffChangesComponent = (props: DiffChangesComponentProps) => (
  <ApolloReactComponents.Query<DiffChangesQuery, DiffChangesQueryVariables>
    query={DiffChangesDocument}
    {...props}
  />
);

export type DiffChangesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  DiffChangesQuery,
  DiffChangesQueryVariables
> &
  TChildProps;
export function withDiffChanges<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DiffChangesQuery,
    DiffChangesQueryVariables,
    DiffChangesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DiffChangesQuery,
    DiffChangesQueryVariables,
    DiffChangesProps<TChildProps>
  >(DiffChangesDocument, {
    alias: 'withDiffChanges',
    ...operationOptions
  });
}

export function useDiffChangesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DiffChangesQuery,
    DiffChangesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<DiffChangesQuery, DiffChangesQueryVariables>(
    DiffChangesDocument,
    baseOptions
  );
}
export type DiffChangesQueryHookResult = ReturnType<typeof useDiffChangesQuery>;
export type DiffChangesQueryResult = ApolloReactCommon.QueryResult<
  DiffChangesQuery,
  DiffChangesQueryVariables
>;
export const SubmitPullRequestDocument = gql`
  mutation SubmitPullRequest($input: SubmitPrInput!) {
    submitPr(input: $input) {
      errors {
        key
        code
        message
      }
      package {
        id
        name
      }
    }
  }
`;
export type SubmitPullRequestMutationFn = ApolloReactCommon.MutationFunction<
  SubmitPullRequestMutation,
  SubmitPullRequestMutationVariables
>;
export type SubmitPullRequestComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SubmitPullRequestMutation,
    SubmitPullRequestMutationVariables
  >,
  'mutation'
>;

export const SubmitPullRequestComponent = (
  props: SubmitPullRequestComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SubmitPullRequestMutation,
    SubmitPullRequestMutationVariables
  >
    mutation={SubmitPullRequestDocument}
    {...props}
  />
);

export type SubmitPullRequestProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  SubmitPullRequestMutation,
  SubmitPullRequestMutationVariables
> &
  TChildProps;
export function withSubmitPullRequest<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SubmitPullRequestMutation,
    SubmitPullRequestMutationVariables,
    SubmitPullRequestProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SubmitPullRequestMutation,
    SubmitPullRequestMutationVariables,
    SubmitPullRequestProps<TChildProps>
  >(SubmitPullRequestDocument, {
    alias: 'withSubmitPullRequest',
    ...operationOptions
  });
}

export function useSubmitPullRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitPullRequestMutation,
    SubmitPullRequestMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SubmitPullRequestMutation,
    SubmitPullRequestMutationVariables
  >(SubmitPullRequestDocument, baseOptions);
}
export type SubmitPullRequestMutationHookResult = ReturnType<
  typeof useSubmitPullRequestMutation
>;
export type SubmitPullRequestMutationResult = ApolloReactCommon.MutationResult<
  SubmitPullRequestMutation
>;
export type SubmitPullRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitPullRequestMutation,
  SubmitPullRequestMutationVariables
>;
export const MergeDocument = gql`
  mutation Merge($input: MergeInput!) {
    merge(input: $input) {
      errors {
        key
        code
        message
      }
      merger {
        conflicts {
          content
          location
        }
        package {
          id
          name
        }
      }
    }
  }
`;
export type MergeMutationFn = ApolloReactCommon.MutationFunction<
  MergeMutation,
  MergeMutationVariables
>;
export type MergeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MergeMutation,
    MergeMutationVariables
  >,
  'mutation'
>;

export const MergeComponent = (props: MergeComponentProps) => (
  <ApolloReactComponents.Mutation<MergeMutation, MergeMutationVariables>
    mutation={MergeDocument}
    {...props}
  />
);

export type MergeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  MergeMutation,
  MergeMutationVariables
> &
  TChildProps;
export function withMerge<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MergeMutation,
    MergeMutationVariables,
    MergeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    MergeMutation,
    MergeMutationVariables,
    MergeProps<TChildProps>
  >(MergeDocument, {
    alias: 'withMerge',
    ...operationOptions
  });
}

export function useMergeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MergeMutation,
    MergeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<MergeMutation, MergeMutationVariables>(
    MergeDocument,
    baseOptions
  );
}
export type MergeMutationHookResult = ReturnType<typeof useMergeMutation>;
export type MergeMutationResult = ApolloReactCommon.MutationResult<
  MergeMutation
>;
export type MergeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MergeMutation,
  MergeMutationVariables
>;
export const ResolveConflictsDocument = gql`
  mutation ResolveConflicts($input: ResolveConflictsInput!) {
    resolveConflicts(input: $input) {
      errors {
        key
        code
        message
      }
      merger {
        conflicts {
          content
          location
        }
        package {
          id
          name
        }
      }
    }
  }
`;
export type ResolveConflictsMutationFn = ApolloReactCommon.MutationFunction<
  ResolveConflictsMutation,
  ResolveConflictsMutationVariables
>;
export type ResolveConflictsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ResolveConflictsMutation,
    ResolveConflictsMutationVariables
  >,
  'mutation'
>;

export const ResolveConflictsComponent = (
  props: ResolveConflictsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ResolveConflictsMutation,
    ResolveConflictsMutationVariables
  >
    mutation={ResolveConflictsDocument}
    {...props}
  />
);

export type ResolveConflictsProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  ResolveConflictsMutation,
  ResolveConflictsMutationVariables
> &
  TChildProps;
export function withResolveConflicts<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ResolveConflictsMutation,
    ResolveConflictsMutationVariables,
    ResolveConflictsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ResolveConflictsMutation,
    ResolveConflictsMutationVariables,
    ResolveConflictsProps<TChildProps>
  >(ResolveConflictsDocument, {
    alias: 'withResolveConflicts',
    ...operationOptions
  });
}

export function useResolveConflictsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResolveConflictsMutation,
    ResolveConflictsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResolveConflictsMutation,
    ResolveConflictsMutationVariables
  >(ResolveConflictsDocument, baseOptions);
}
export type ResolveConflictsMutationHookResult = ReturnType<
  typeof useResolveConflictsMutation
>;
export type ResolveConflictsMutationResult = ApolloReactCommon.MutationResult<
  ResolveConflictsMutation
>;
export type ResolveConflictsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResolveConflictsMutation,
  ResolveConflictsMutationVariables
>;
export const RejectPrDocument = gql`
  mutation RejectPr($input: RejectPrInput!) {
    rejectPr(input: $input) {
      errors {
        key
        code
        message
      }
      package {
        id
        name
      }
    }
  }
`;
export type RejectPrMutationFn = ApolloReactCommon.MutationFunction<
  RejectPrMutation,
  RejectPrMutationVariables
>;
export type RejectPrComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RejectPrMutation,
    RejectPrMutationVariables
  >,
  'mutation'
>;

export const RejectPrComponent = (props: RejectPrComponentProps) => (
  <ApolloReactComponents.Mutation<RejectPrMutation, RejectPrMutationVariables>
    mutation={RejectPrDocument}
    {...props}
  />
);

export type RejectPrProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RejectPrMutation,
  RejectPrMutationVariables
> &
  TChildProps;
export function withRejectPr<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RejectPrMutation,
    RejectPrMutationVariables,
    RejectPrProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RejectPrMutation,
    RejectPrMutationVariables,
    RejectPrProps<TChildProps>
  >(RejectPrDocument, {
    alias: 'withRejectPr',
    ...operationOptions
  });
}

export function useRejectPrMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RejectPrMutation,
    RejectPrMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RejectPrMutation,
    RejectPrMutationVariables
  >(RejectPrDocument, baseOptions);
}
export type RejectPrMutationHookResult = ReturnType<typeof useRejectPrMutation>;
export type RejectPrMutationResult = ApolloReactCommon.MutationResult<
  RejectPrMutation
>;
export type RejectPrMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RejectPrMutation,
  RejectPrMutationVariables
>;
export const ListCollegeCoursesDocument = gql`
  query listCollegeCourses($filter: CourseFilter, $first: Int) {
    courses(filter: $filter, first: $first) {
      edges {
        node {
          title
          cover
          subscribedCount
          labels
          shortUrl
          category {
            name
            parent {
              name
            }
          }
        }
      }
    }
  }
`;
export type ListCollegeCoursesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCollegeCoursesQuery,
    ListCollegeCoursesQueryVariables
  >,
  'query'
>;

export const ListCollegeCoursesComponent = (
  props: ListCollegeCoursesComponentProps
) => (
  <ApolloReactComponents.Query<
    ListCollegeCoursesQuery,
    ListCollegeCoursesQueryVariables
  >
    query={ListCollegeCoursesDocument}
    {...props}
  />
);

export type ListCollegeCoursesProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  ListCollegeCoursesQuery,
  ListCollegeCoursesQueryVariables
> &
  TChildProps;
export function withListCollegeCourses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCollegeCoursesQuery,
    ListCollegeCoursesQueryVariables,
    ListCollegeCoursesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCollegeCoursesQuery,
    ListCollegeCoursesQueryVariables,
    ListCollegeCoursesProps<TChildProps>
  >(ListCollegeCoursesDocument, {
    alias: 'withListCollegeCourses',
    ...operationOptions
  });
}

export function useListCollegeCoursesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCollegeCoursesQuery,
    ListCollegeCoursesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCollegeCoursesQuery,
    ListCollegeCoursesQueryVariables
  >(ListCollegeCoursesDocument, baseOptions);
}
export type ListCollegeCoursesQueryHookResult = ReturnType<
  typeof useListCollegeCoursesQuery
>;
export type ListCollegeCoursesQueryResult = ApolloReactCommon.QueryResult<
  ListCollegeCoursesQuery,
  ListCollegeCoursesQueryVariables
>;
export const HomePageBannerDocument = gql`
  query HomePageBanner {
    banners {
      id
      imageUrl
      linkUrl
    }
  }
`;
export type HomePageBannerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    HomePageBannerQuery,
    HomePageBannerQueryVariables
  >,
  'query'
>;

export const HomePageBannerComponent = (
  props: HomePageBannerComponentProps
) => (
  <ApolloReactComponents.Query<
    HomePageBannerQuery,
    HomePageBannerQueryVariables
  >
    query={HomePageBannerDocument}
    {...props}
  />
);

export type HomePageBannerProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  HomePageBannerQuery,
  HomePageBannerQueryVariables
> &
  TChildProps;
export function withHomePageBanner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    HomePageBannerQuery,
    HomePageBannerQueryVariables,
    HomePageBannerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    HomePageBannerQuery,
    HomePageBannerQueryVariables,
    HomePageBannerProps<TChildProps>
  >(HomePageBannerDocument, {
    alias: 'withHomePageBanner',
    ...operationOptions
  });
}

export function useHomePageBannerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HomePageBannerQuery,
    HomePageBannerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    HomePageBannerQuery,
    HomePageBannerQueryVariables
  >(HomePageBannerDocument, baseOptions);
}
export type HomePageBannerQueryHookResult = ReturnType<
  typeof useHomePageBannerQuery
>;
export type HomePageBannerQueryResult = ApolloReactCommon.QueryResult<
  HomePageBannerQuery,
  HomePageBannerQueryVariables
>;
export const HomePageCoursesDocument = gql`
  query HomePageCourses($first: Int!) {
    courses(first: $first) {
      edges {
        node {
          category {
            id
            name
            parent {
              id
              name
            }
          }
          cover
          description
          id
          instructors
          isOnline
          isSelling
          subTitle
          title
          shortUrl
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
export type HomePageCoursesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    HomePageCoursesQuery,
    HomePageCoursesQueryVariables
  >,
  'query'
> &
  (
    | { variables: HomePageCoursesQueryVariables; skip?: boolean }
    | { skip: boolean });

export const HomePageCoursesComponent = (
  props: HomePageCoursesComponentProps
) => (
  <ApolloReactComponents.Query<
    HomePageCoursesQuery,
    HomePageCoursesQueryVariables
  >
    query={HomePageCoursesDocument}
    {...props}
  />
);

export type HomePageCoursesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  HomePageCoursesQuery,
  HomePageCoursesQueryVariables
> &
  TChildProps;
export function withHomePageCourses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    HomePageCoursesQuery,
    HomePageCoursesQueryVariables,
    HomePageCoursesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    HomePageCoursesQuery,
    HomePageCoursesQueryVariables,
    HomePageCoursesProps<TChildProps>
  >(HomePageCoursesDocument, {
    alias: 'withHomePageCourses',
    ...operationOptions
  });
}

export function useHomePageCoursesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HomePageCoursesQuery,
    HomePageCoursesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    HomePageCoursesQuery,
    HomePageCoursesQueryVariables
  >(HomePageCoursesDocument, baseOptions);
}
export type HomePageCoursesQueryHookResult = ReturnType<
  typeof useHomePageCoursesQuery
>;
export type HomePageCoursesQueryResult = ApolloReactCommon.QueryResult<
  HomePageCoursesQuery,
  HomePageCoursesQueryVariables
>;
export const HomePageMenuDocument = gql`
  query HomePageMenu($isOnline: Boolean!) {
    menu(isOnline: $isOnline) {
      collegiateCategory
      courseCategory
      isOnline
      registrationBegin
      title
      id
      shortUrl
    }
  }
`;
export type HomePageMenuComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    HomePageMenuQuery,
    HomePageMenuQueryVariables
  >,
  'query'
> &
  (
    | { variables: HomePageMenuQueryVariables; skip?: boolean }
    | { skip: boolean });

export const HomePageMenuComponent = (props: HomePageMenuComponentProps) => (
  <ApolloReactComponents.Query<HomePageMenuQuery, HomePageMenuQueryVariables>
    query={HomePageMenuDocument}
    {...props}
  />
);

export type HomePageMenuProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  HomePageMenuQuery,
  HomePageMenuQueryVariables
> &
  TChildProps;
export function withHomePageMenu<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    HomePageMenuQuery,
    HomePageMenuQueryVariables,
    HomePageMenuProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    HomePageMenuQuery,
    HomePageMenuQueryVariables,
    HomePageMenuProps<TChildProps>
  >(HomePageMenuDocument, {
    alias: 'withHomePageMenu',
    ...operationOptions
  });
}

export function useHomePageMenuQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HomePageMenuQuery,
    HomePageMenuQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    HomePageMenuQuery,
    HomePageMenuQueryVariables
  >(HomePageMenuDocument, baseOptions);
}
export type HomePageMenuQueryHookResult = ReturnType<
  typeof useHomePageMenuQuery
>;
export type HomePageMenuQueryResult = ApolloReactCommon.QueryResult<
  HomePageMenuQuery,
  HomePageMenuQueryVariables
>;
export const RefreshTokenDocument = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      errors {
        code
        message
        key
      }
      session {
        token
        user {
          id
          nickname
        }
      }
    }
  }
`;
export type RefreshTokenMutationFn = ApolloReactCommon.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export type RefreshTokenComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >,
  'mutation'
>;

export const RefreshTokenComponent = (props: RefreshTokenComponentProps) => (
  <ApolloReactComponents.Mutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >
    mutation={RefreshTokenDocument}
    {...props}
  />
);

export type RefreshTokenProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
> &
  TChildProps;
export function withRefreshToken<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RefreshTokenMutation,
    RefreshTokenMutationVariables,
    RefreshTokenProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RefreshTokenMutation,
    RefreshTokenMutationVariables,
    RefreshTokenProps<TChildProps>
  >(RefreshTokenDocument, {
    alias: 'withRefreshToken',
    ...operationOptions
  });
}

export function useRefreshTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, baseOptions);
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>;
export type RefreshTokenMutationResult = ApolloReactCommon.MutationResult<
  RefreshTokenMutation
>;
export type RefreshTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const GetCardProgressDocument = gql`
  query getCardProgress($id: ID!) {
    node(id: $id) {
      ... on CoursePackage {
        fileCardsCount
        learnedCount
      }
    }
  }
`;
export type GetCardProgressComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCardProgressQuery,
    GetCardProgressQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCardProgressQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCardProgressComponent = (
  props: GetCardProgressComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCardProgressQuery,
    GetCardProgressQueryVariables
  >
    query={GetCardProgressDocument}
    {...props}
  />
);

export type GetCardProgressProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetCardProgressQuery,
  GetCardProgressQueryVariables
> &
  TChildProps;
export function withGetCardProgress<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCardProgressQuery,
    GetCardProgressQueryVariables,
    GetCardProgressProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCardProgressQuery,
    GetCardProgressQueryVariables,
    GetCardProgressProps<TChildProps>
  >(GetCardProgressDocument, {
    alias: 'withGetCardProgress',
    ...operationOptions
  });
}

export function useGetCardProgressQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCardProgressQuery,
    GetCardProgressQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCardProgressQuery,
    GetCardProgressQueryVariables
  >(GetCardProgressDocument, baseOptions);
}
export type GetCardProgressQueryHookResult = ReturnType<
  typeof useGetCardProgressQuery
>;
export type GetCardProgressQueryResult = ApolloReactCommon.QueryResult<
  GetCardProgressQuery,
  GetCardProgressQueryVariables
>;
export const UserLoginDocument = gql`
  mutation UserLogin($phone: String!, $password: String!) {
    login(phone: $phone, password: $password) {
      errors {
        code
        key
        message
      }
      session {
        token
        user {
          isDef
          avatar
          id
          nickname
          phone
          role
          userProfile {
            id
          }
        }
      }
    }
  }
`;
export type UserLoginMutationFn = ApolloReactCommon.MutationFunction<
  UserLoginMutation,
  UserLoginMutationVariables
>;
export type UserLoginComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UserLoginMutation,
    UserLoginMutationVariables
  >,
  'mutation'
>;

export const UserLoginComponent = (props: UserLoginComponentProps) => (
  <ApolloReactComponents.Mutation<UserLoginMutation, UserLoginMutationVariables>
    mutation={UserLoginDocument}
    {...props}
  />
);

export type UserLoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UserLoginMutation,
  UserLoginMutationVariables
> &
  TChildProps;
export function withUserLogin<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserLoginMutation,
    UserLoginMutationVariables,
    UserLoginProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UserLoginMutation,
    UserLoginMutationVariables,
    UserLoginProps<TChildProps>
  >(UserLoginDocument, {
    alias: 'withUserLogin',
    ...operationOptions
  });
}

export function useUserLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserLoginMutation,
    UserLoginMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UserLoginMutation,
    UserLoginMutationVariables
  >(UserLoginDocument, baseOptions);
}
export type UserLoginMutationHookResult = ReturnType<
  typeof useUserLoginMutation
>;
export type UserLoginMutationResult = ApolloReactCommon.MutationResult<
  UserLoginMutation
>;
export type UserLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserLoginMutation,
  UserLoginMutationVariables
>;
export const GetAuthCodeDocument = gql`
  mutation GetAuthCode($input: SendAuthCodeInput!) {
    sendAuthCode(input: $input) {
      auth {
        phone
      }
      errors {
        code
        message
      }
    }
  }
`;
export type GetAuthCodeMutationFn = ApolloReactCommon.MutationFunction<
  GetAuthCodeMutation,
  GetAuthCodeMutationVariables
>;
export type GetAuthCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GetAuthCodeMutation,
    GetAuthCodeMutationVariables
  >,
  'mutation'
>;

export const GetAuthCodeComponent = (props: GetAuthCodeComponentProps) => (
  <ApolloReactComponents.Mutation<
    GetAuthCodeMutation,
    GetAuthCodeMutationVariables
  >
    mutation={GetAuthCodeDocument}
    {...props}
  />
);

export type GetAuthCodeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  GetAuthCodeMutation,
  GetAuthCodeMutationVariables
> &
  TChildProps;
export function withGetAuthCode<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAuthCodeMutation,
    GetAuthCodeMutationVariables,
    GetAuthCodeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GetAuthCodeMutation,
    GetAuthCodeMutationVariables,
    GetAuthCodeProps<TChildProps>
  >(GetAuthCodeDocument, {
    alias: 'withGetAuthCode',
    ...operationOptions
  });
}

export function useGetAuthCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GetAuthCodeMutation,
    GetAuthCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GetAuthCodeMutation,
    GetAuthCodeMutationVariables
  >(GetAuthCodeDocument, baseOptions);
}
export type GetAuthCodeMutationHookResult = ReturnType<
  typeof useGetAuthCodeMutation
>;
export type GetAuthCodeMutationResult = ApolloReactCommon.MutationResult<
  GetAuthCodeMutation
>;
export type GetAuthCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GetAuthCodeMutation,
  GetAuthCodeMutationVariables
>;
export const RegisterUserDocument = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      errors {
        code
        key
        message
      }
      user {
        avatar
        id
        nickname
        phone
        role
      }
    }
  }
`;
export type RegisterUserMutationFn = ApolloReactCommon.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export type RegisterUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >,
  'mutation'
>;

export const RegisterUserComponent = (props: RegisterUserComponentProps) => (
  <ApolloReactComponents.Mutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >
    mutation={RegisterUserDocument}
    {...props}
  />
);

export type RegisterUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RegisterUserMutation,
  RegisterUserMutationVariables
> &
  TChildProps;
export function withRegisterUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegisterUserMutation,
    RegisterUserMutationVariables,
    RegisterUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RegisterUserMutation,
    RegisterUserMutationVariables,
    RegisterUserProps<TChildProps>
  >(RegisterUserDocument, {
    alias: 'withRegisterUser',
    ...operationOptions
  });
}

export function useRegisterUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, baseOptions);
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>;
export type RegisterUserMutationResult = ApolloReactCommon.MutationResult<
  RegisterUserMutation
>;
export type RegisterUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const ResetUserPasswordDocument = gql`
  mutation ResetUserPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      errors {
        code
        message
        key
      }
    }
  }
`;
export type ResetUserPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;
export type ResetUserPasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >,
  'mutation'
>;

export const ResetUserPasswordComponent = (
  props: ResetUserPasswordComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >
    mutation={ResetUserPasswordDocument}
    {...props}
  />
);

export type ResetUserPasswordProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
> &
  TChildProps;
export function withResetUserPassword<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables,
    ResetUserPasswordProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables,
    ResetUserPasswordProps<TChildProps>
  >(ResetUserPasswordDocument, {
    alias: 'withResetUserPassword',
    ...operationOptions
  });
}

export function useResetUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >(ResetUserPasswordDocument, baseOptions);
}
export type ResetUserPasswordMutationHookResult = ReturnType<
  typeof useResetUserPasswordMutation
>;
export type ResetUserPasswordMutationResult = ApolloReactCommon.MutationResult<
  ResetUserPasswordMutation
>;
export type ResetUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;
export const GetUserMessageDocument = gql`
  query getUserMessage($filter: MessageFilter, $first: Int) {
    me {
      messages(filter: $filter, first: $first) {
        edges {
          node {
            id
            info
            content
            state
            description
            poster {
              avatar
              nickname
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;
export type GetUserMessageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetUserMessageQuery,
    GetUserMessageQueryVariables
  >,
  'query'
>;

export const GetUserMessageComponent = (
  props: GetUserMessageComponentProps
) => (
  <ApolloReactComponents.Query<
    GetUserMessageQuery,
    GetUserMessageQueryVariables
  >
    query={GetUserMessageDocument}
    {...props}
  />
);

export type GetUserMessageProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetUserMessageQuery,
  GetUserMessageQueryVariables
> &
  TChildProps;
export function withGetUserMessage<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetUserMessageQuery,
    GetUserMessageQueryVariables,
    GetUserMessageProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetUserMessageQuery,
    GetUserMessageQueryVariables,
    GetUserMessageProps<TChildProps>
  >(GetUserMessageDocument, {
    alias: 'withGetUserMessage',
    ...operationOptions
  });
}

export function useGetUserMessageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUserMessageQuery,
    GetUserMessageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetUserMessageQuery,
    GetUserMessageQueryVariables
  >(GetUserMessageDocument, baseOptions);
}
export type GetUserMessageQueryHookResult = ReturnType<
  typeof useGetUserMessageQuery
>;
export type GetUserMessageQueryResult = ApolloReactCommon.QueryResult<
  GetUserMessageQuery,
  GetUserMessageQueryVariables
>;
export const UpdateMessageDocument = gql`
  mutation updateMessage($input: UpdateMessageInput) {
    updateMessage(input: $input) {
      errors {
        message
        key
        code
      }
    }
  }
`;
export type UpdateMessageMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMessageMutation,
  UpdateMessageMutationVariables
>;
export type UpdateMessageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMessageMutation,
    UpdateMessageMutationVariables
  >,
  'mutation'
>;

export const UpdateMessageComponent = (props: UpdateMessageComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateMessageMutation,
    UpdateMessageMutationVariables
  >
    mutation={UpdateMessageDocument}
    {...props}
  />
);

export type UpdateMessageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateMessageMutation,
  UpdateMessageMutationVariables
> &
  TChildProps;
export function withUpdateMessage<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMessageMutation,
    UpdateMessageMutationVariables,
    UpdateMessageProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMessageMutation,
    UpdateMessageMutationVariables,
    UpdateMessageProps<TChildProps>
  >(UpdateMessageDocument, {
    alias: 'withUpdateMessage',
    ...operationOptions
  });
}

export function useUpdateMessageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessageMutation,
    UpdateMessageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateMessageMutation,
    UpdateMessageMutationVariables
  >(UpdateMessageDocument, baseOptions);
}
export type UpdateMessageMutationHookResult = ReturnType<
  typeof useUpdateMessageMutation
>;
export type UpdateMessageMutationResult = ApolloReactCommon.MutationResult<
  UpdateMessageMutation
>;
export type UpdateMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMessageMutation,
  UpdateMessageMutationVariables
>;
export const GetParentIdDocument = gql`
  query getParentId($id: ID!) {
    node(id: $id) {
      ... on Assignment {
        pid
      }
    }
  }
`;
export type GetParentIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetParentIdQuery,
    GetParentIdQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetParentIdQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetParentIdComponent = (props: GetParentIdComponentProps) => (
  <ApolloReactComponents.Query<GetParentIdQuery, GetParentIdQueryVariables>
    query={GetParentIdDocument}
    {...props}
  />
);

export type GetParentIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetParentIdQuery,
  GetParentIdQueryVariables
> &
  TChildProps;
export function withGetParentId<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetParentIdQuery,
    GetParentIdQueryVariables,
    GetParentIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetParentIdQuery,
    GetParentIdQueryVariables,
    GetParentIdProps<TChildProps>
  >(GetParentIdDocument, {
    alias: 'withGetParentId',
    ...operationOptions
  });
}

export function useGetParentIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetParentIdQuery,
    GetParentIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetParentIdQuery, GetParentIdQueryVariables>(
    GetParentIdDocument,
    baseOptions
  );
}
export type GetParentIdQueryHookResult = ReturnType<typeof useGetParentIdQuery>;
export type GetParentIdQueryResult = ApolloReactCommon.QueryResult<
  GetParentIdQuery,
  GetParentIdQueryVariables
>;
export const DiffChangesInMessageDocument = gql`
  query DiffChangesInMessage(
    $currentPackageId: ID!
    $targetPackageId: ID
    $diffType: DiffType!
  ) {
    diff(
      currentPackageId: $currentPackageId
      targetPackageId: $targetPackageId
      diffType: $diffType
    ) {
      changes
    }
  }
`;
export type DiffChangesInMessageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DiffChangesInMessageQuery,
    DiffChangesInMessageQueryVariables
  >,
  'query'
> &
  (
    | { variables: DiffChangesInMessageQueryVariables; skip?: boolean }
    | { skip: boolean });

export const DiffChangesInMessageComponent = (
  props: DiffChangesInMessageComponentProps
) => (
  <ApolloReactComponents.Query<
    DiffChangesInMessageQuery,
    DiffChangesInMessageQueryVariables
  >
    query={DiffChangesInMessageDocument}
    {...props}
  />
);

export type DiffChangesInMessageProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  DiffChangesInMessageQuery,
  DiffChangesInMessageQueryVariables
> &
  TChildProps;
export function withDiffChangesInMessage<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DiffChangesInMessageQuery,
    DiffChangesInMessageQueryVariables,
    DiffChangesInMessageProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DiffChangesInMessageQuery,
    DiffChangesInMessageQueryVariables,
    DiffChangesInMessageProps<TChildProps>
  >(DiffChangesInMessageDocument, {
    alias: 'withDiffChangesInMessage',
    ...operationOptions
  });
}

export function useDiffChangesInMessageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DiffChangesInMessageQuery,
    DiffChangesInMessageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    DiffChangesInMessageQuery,
    DiffChangesInMessageQueryVariables
  >(DiffChangesInMessageDocument, baseOptions);
}
export type DiffChangesInMessageQueryHookResult = ReturnType<
  typeof useDiffChangesInMessageQuery
>;
export type DiffChangesInMessageQueryResult = ApolloReactCommon.QueryResult<
  DiffChangesInMessageQuery,
  DiffChangesInMessageQueryVariables
>;
export const GetUserInfoDocument = gql`
  query getUserInfo {
    me {
      avatar
      id
      nickname
      phone
      role
      userProfile {
        address
        birth
        company
        email
        fieldOfStudy
        gender
        headline
        hobbies
        id
        industry
        info
        name
        omcRegion
        school
        state
        wechatNickname
        wechatUsername
        works
      }
    }
  }
`;
export type GetUserInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >,
  'query'
>;

export const GetUserInfoComponent = (props: GetUserInfoComponentProps) => (
  <ApolloReactComponents.Query<GetUserInfoQuery, GetUserInfoQueryVariables>
    query={GetUserInfoDocument}
    {...props}
  />
);

export type GetUserInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetUserInfoQuery,
  GetUserInfoQueryVariables
> &
  TChildProps;
export function withGetUserInfo<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetUserInfoQuery,
    GetUserInfoQueryVariables,
    GetUserInfoProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetUserInfoQuery,
    GetUserInfoQueryVariables,
    GetUserInfoProps<TChildProps>
  >(GetUserInfoDocument, {
    alias: 'withGetUserInfo',
    ...operationOptions
  });
}

export function useGetUserInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    baseOptions
  );
}
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoQueryResult = ApolloReactCommon.QueryResult<
  GetUserInfoQuery,
  GetUserInfoQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors {
        message
        key
        code
      }
    }
  }
`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export type UpdateUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
  'mutation'
>;

export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
    mutation={UpdateUserDocument}
    {...props}
  />
);

export type UpdateUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserMutation,
  UpdateUserMutationVariables
> &
  TChildProps;
export function withUpdateUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps>
  >(UpdateUserDocument, {
    alias: 'withUpdateUser',
    ...operationOptions
  });
}

export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserMutation
>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateUserPasswordDocument = gql`
  mutation updateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      errors {
        message
        key
        code
      }
    }
  }
`;
export type UpdateUserPasswordMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;
export type UpdateUserPasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >,
  'mutation'
>;

export const UpdateUserPasswordComponent = (
  props: UpdateUserPasswordComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >
    mutation={UpdateUserPasswordDocument}
    {...props}
  />
);

export type UpdateUserPasswordProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
> &
  TChildProps;
export function withUpdateUserPassword<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables,
    UpdateUserPasswordProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables,
    UpdateUserPasswordProps<TChildProps>
  >(UpdateUserPasswordDocument, {
    alias: 'withUpdateUserPassword',
    ...operationOptions
  });
}

export function useUpdateUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >(UpdateUserPasswordDocument, baseOptions);
}
export type UpdateUserPasswordMutationHookResult = ReturnType<
  typeof useUpdateUserPasswordMutation
>;
export type UpdateUserPasswordMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserPasswordMutation
>;
export type UpdateUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;
export const UpdateUserProfileDocument = gql`
  mutation updateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      errors {
        message
        key
        code
      }
    }
  }
`;
export type UpdateUserProfileMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export type UpdateUserProfileComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >,
  'mutation'
>;

export const UpdateUserProfileComponent = (
  props: UpdateUserProfileComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >
    mutation={UpdateUserProfileDocument}
    {...props}
  />
);

export type UpdateUserProfileProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
> &
  TChildProps;
export function withUpdateUserProfile<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables,
    UpdateUserProfileProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables,
    UpdateUserProfileProps<TChildProps>
  >(UpdateUserProfileDocument, {
    alias: 'withUpdateUserProfile',
    ...operationOptions
  });
}

export function useUpdateUserProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >(UpdateUserProfileDocument, baseOptions);
}
export type UpdateUserProfileMutationHookResult = ReturnType<
  typeof useUpdateUserProfileMutation
>;
export type UpdateUserProfileMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserProfileMutation
>;
export type UpdateUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const CreateUserProfileDocument = gql`
  mutation createUserProfile($input: CreateUserProfileInput!) {
    createUserProfile(input: $input) {
      errors {
        message
        key
        code
      }
    }
  }
`;
export type CreateUserProfileMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserProfileMutation,
  CreateUserProfileMutationVariables
>;
export type CreateUserProfileComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables
  >,
  'mutation'
>;

export const CreateUserProfileComponent = (
  props: CreateUserProfileComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables
  >
    mutation={CreateUserProfileDocument}
    {...props}
  />
);

export type CreateUserProfileProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateUserProfileMutation,
  CreateUserProfileMutationVariables
> &
  TChildProps;
export function withCreateUserProfile<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables,
    CreateUserProfileProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables,
    CreateUserProfileProps<TChildProps>
  >(CreateUserProfileDocument, {
    alias: 'withCreateUserProfile',
    ...operationOptions
  });
}

export function useCreateUserProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables
  >(CreateUserProfileDocument, baseOptions);
}
export type CreateUserProfileMutationHookResult = ReturnType<
  typeof useCreateUserProfileMutation
>;
export type CreateUserProfileMutationResult = ApolloReactCommon.MutationResult<
  CreateUserProfileMutation
>;
export type CreateUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserProfileMutation,
  CreateUserProfileMutationVariables
>;
export const SubscribedCoursesDocument = gql`
  query subscribedCourses($first: Int) {
    subscribedCourses(first: $first) {
      count
      edges {
        node {
          title
          cover
          shortUrl
          isFinished
          isOnline
          isEnd
          needUserProfile
        }
      }
    }
  }
`;
export type SubscribedCoursesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SubscribedCoursesQuery,
    SubscribedCoursesQueryVariables
  >,
  'query'
>;

export const SubscribedCoursesComponent = (
  props: SubscribedCoursesComponentProps
) => (
  <ApolloReactComponents.Query<
    SubscribedCoursesQuery,
    SubscribedCoursesQueryVariables
  >
    query={SubscribedCoursesDocument}
    {...props}
  />
);

export type SubscribedCoursesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SubscribedCoursesQuery,
  SubscribedCoursesQueryVariables
> &
  TChildProps;
export function withSubscribedCourses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SubscribedCoursesQuery,
    SubscribedCoursesQueryVariables,
    SubscribedCoursesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SubscribedCoursesQuery,
    SubscribedCoursesQueryVariables,
    SubscribedCoursesProps<TChildProps>
  >(SubscribedCoursesDocument, {
    alias: 'withSubscribedCourses',
    ...operationOptions
  });
}

export function useSubscribedCoursesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SubscribedCoursesQuery,
    SubscribedCoursesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SubscribedCoursesQuery,
    SubscribedCoursesQueryVariables
  >(SubscribedCoursesDocument, baseOptions);
}
export type SubscribedCoursesQueryHookResult = ReturnType<
  typeof useSubscribedCoursesQuery
>;
export type SubscribedCoursesQueryResult = ApolloReactCommon.QueryResult<
  SubscribedCoursesQuery,
  SubscribedCoursesQueryVariables
>;
export const ContentCollectDocument = gql`
  query contentCollect(
    $courseId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $order: CollectionOrder!
    $filter: CollectionFilter
    $matching: String
  ) {
    collections(
      courseId: $courseId
      first: $first
      last: $last
      before: $before
      after: $after
      order: $order
      filter: $filter
      matching: $matching
    ) {
      count
      edges {
        cursor
        node {
          author {
            nickname
            avatar
          }
          commentsCount
          content
          id
          location
          name
          status
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export type ContentCollectComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ContentCollectQuery,
    ContentCollectQueryVariables
  >,
  'query'
> &
  (
    | { variables: ContentCollectQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ContentCollectComponent = (
  props: ContentCollectComponentProps
) => (
  <ApolloReactComponents.Query<
    ContentCollectQuery,
    ContentCollectQueryVariables
  >
    query={ContentCollectDocument}
    {...props}
  />
);

export type ContentCollectProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ContentCollectQuery,
  ContentCollectQueryVariables
> &
  TChildProps;
export function withContentCollect<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ContentCollectQuery,
    ContentCollectQueryVariables,
    ContentCollectProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ContentCollectQuery,
    ContentCollectQueryVariables,
    ContentCollectProps<TChildProps>
  >(ContentCollectDocument, {
    alias: 'withContentCollect',
    ...operationOptions
  });
}

export function useContentCollectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContentCollectQuery,
    ContentCollectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ContentCollectQuery,
    ContentCollectQueryVariables
  >(ContentCollectDocument, baseOptions);
}
export type ContentCollectQueryHookResult = ReturnType<
  typeof useContentCollectQuery
>;
export type ContentCollectQueryResult = ApolloReactCommon.QueryResult<
  ContentCollectQuery,
  ContentCollectQueryVariables
>;
export const HomeworkCollectDocument = gql`
  query homeworkCollect(
    $courseId: ID!
    $first: Int
    $order: CollectionOrder!
    $filter: CollectionFilter
    $after: String
    $before: String
    $last: Int
    $matching: String
  ) {
    collections(
      courseId: $courseId
      first: $first
      order: $order
      filter: $filter
      after: $after
      before: $before
      last: $last
      matching: $matching
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      count
      edges {
        cursor
        node {
          author {
            nickname
            avatar
          }
          parent {
            name
            id
            insertedAt
            questions {
              content
            }
          }
          comments {
            insertedAt
            card {
              name
            }
          }
          commentsCount
          id
          name
        }
      }
    }
  }
`;
export type HomeworkCollectComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    HomeworkCollectQuery,
    HomeworkCollectQueryVariables
  >,
  'query'
> &
  (
    | { variables: HomeworkCollectQueryVariables; skip?: boolean }
    | { skip: boolean });

export const HomeworkCollectComponent = (
  props: HomeworkCollectComponentProps
) => (
  <ApolloReactComponents.Query<
    HomeworkCollectQuery,
    HomeworkCollectQueryVariables
  >
    query={HomeworkCollectDocument}
    {...props}
  />
);

export type HomeworkCollectProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  HomeworkCollectQuery,
  HomeworkCollectQueryVariables
> &
  TChildProps;
export function withHomeworkCollect<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    HomeworkCollectQuery,
    HomeworkCollectQueryVariables,
    HomeworkCollectProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    HomeworkCollectQuery,
    HomeworkCollectQueryVariables,
    HomeworkCollectProps<TChildProps>
  >(HomeworkCollectDocument, {
    alias: 'withHomeworkCollect',
    ...operationOptions
  });
}

export function useHomeworkCollectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HomeworkCollectQuery,
    HomeworkCollectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    HomeworkCollectQuery,
    HomeworkCollectQueryVariables
  >(HomeworkCollectDocument, baseOptions);
}
export type HomeworkCollectQueryHookResult = ReturnType<
  typeof useHomeworkCollectQuery
>;
export type HomeworkCollectQueryResult = ApolloReactCommon.QueryResult<
  HomeworkCollectQuery,
  HomeworkCollectQueryVariables
>;
export const GetLabelsInDiscussionDocument = gql`
  query getLabelsInDiscussion {
    labels {
      id
      info
      name
      scenes
    }
  }
`;
export type GetLabelsInDiscussionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetLabelsInDiscussionQuery,
    GetLabelsInDiscussionQueryVariables
  >,
  'query'
>;

export const GetLabelsInDiscussionComponent = (
  props: GetLabelsInDiscussionComponentProps
) => (
  <ApolloReactComponents.Query<
    GetLabelsInDiscussionQuery,
    GetLabelsInDiscussionQueryVariables
  >
    query={GetLabelsInDiscussionDocument}
    {...props}
  />
);

export type GetLabelsInDiscussionProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetLabelsInDiscussionQuery,
  GetLabelsInDiscussionQueryVariables
> &
  TChildProps;
export function withGetLabelsInDiscussion<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetLabelsInDiscussionQuery,
    GetLabelsInDiscussionQueryVariables,
    GetLabelsInDiscussionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetLabelsInDiscussionQuery,
    GetLabelsInDiscussionQueryVariables,
    GetLabelsInDiscussionProps<TChildProps>
  >(GetLabelsInDiscussionDocument, {
    alias: 'withGetLabelsInDiscussion',
    ...operationOptions
  });
}

export function useGetLabelsInDiscussionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLabelsInDiscussionQuery,
    GetLabelsInDiscussionQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetLabelsInDiscussionQuery,
    GetLabelsInDiscussionQueryVariables
  >(GetLabelsInDiscussionDocument, baseOptions);
}
export type GetLabelsInDiscussionQueryHookResult = ReturnType<
  typeof useGetLabelsInDiscussionQuery
>;
export type GetLabelsInDiscussionQueryResult = ApolloReactCommon.QueryResult<
  GetLabelsInDiscussionQuery,
  GetLabelsInDiscussionQueryVariables
>;
export const DiscussionCollectionDocument = gql`
  query discussionCollection(
    $courseId: ID!
    $first: Int
    $filter: CollectionFilter
    $order: CollectionOrder!
    $after: String
    $before: String
    $last: Int
    $matching: String
  ) {
    collections(
      courseId: $courseId
      first: $first
      filter: $filter
      order: $order
      after: $after
      before: $before
      last: $last
      matching: $matching
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      count
      edges {
        cursor
        node {
          author {
            nickname
            avatar
          }
          insertedAt
          id
          name
          labels {
            info
            name
          }
          commentsCount
        }
      }
    }
  }
`;
export type DiscussionCollectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DiscussionCollectionQuery,
    DiscussionCollectionQueryVariables
  >,
  'query'
> &
  (
    | { variables: DiscussionCollectionQueryVariables; skip?: boolean }
    | { skip: boolean });

export const DiscussionCollectionComponent = (
  props: DiscussionCollectionComponentProps
) => (
  <ApolloReactComponents.Query<
    DiscussionCollectionQuery,
    DiscussionCollectionQueryVariables
  >
    query={DiscussionCollectionDocument}
    {...props}
  />
);

export type DiscussionCollectionProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  DiscussionCollectionQuery,
  DiscussionCollectionQueryVariables
> &
  TChildProps;
export function withDiscussionCollection<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DiscussionCollectionQuery,
    DiscussionCollectionQueryVariables,
    DiscussionCollectionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DiscussionCollectionQuery,
    DiscussionCollectionQueryVariables,
    DiscussionCollectionProps<TChildProps>
  >(DiscussionCollectionDocument, {
    alias: 'withDiscussionCollection',
    ...operationOptions
  });
}

export function useDiscussionCollectionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DiscussionCollectionQuery,
    DiscussionCollectionQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    DiscussionCollectionQuery,
    DiscussionCollectionQueryVariables
  >(DiscussionCollectionDocument, baseOptions);
}
export type DiscussionCollectionQueryHookResult = ReturnType<
  typeof useDiscussionCollectionQuery
>;
export type DiscussionCollectionQueryResult = ApolloReactCommon.QueryResult<
  DiscussionCollectionQuery,
  DiscussionCollectionQueryVariables
>;
export const GetSingleDiscussionDataDocument = gql`
  query getSingleDiscussionData($id: ID!, $courseId: ID!) {
    node(id: $id) {
      ... on Discussion {
        author {
          avatar
          nickname
        }
        comments(first: 1000) {
          edges {
            node {
              insertedAt
              id
              poster {
                nickname
                avatar
                id
              }
              content
            }
          }
        }
        commentsCount
        content
        name
        id
        insertedAt
        isCollected(courseId: $courseId)
        labels {
          id
          info
          name
          scenes
        }
      }
    }
  }
`;
export type GetSingleDiscussionDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSingleDiscussionDataQuery,
    GetSingleDiscussionDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetSingleDiscussionDataQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetSingleDiscussionDataComponent = (
  props: GetSingleDiscussionDataComponentProps
) => (
  <ApolloReactComponents.Query<
    GetSingleDiscussionDataQuery,
    GetSingleDiscussionDataQueryVariables
  >
    query={GetSingleDiscussionDataDocument}
    {...props}
  />
);

export type GetSingleDiscussionDataProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetSingleDiscussionDataQuery,
  GetSingleDiscussionDataQueryVariables
> &
  TChildProps;
export function withGetSingleDiscussionData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSingleDiscussionDataQuery,
    GetSingleDiscussionDataQueryVariables,
    GetSingleDiscussionDataProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSingleDiscussionDataQuery,
    GetSingleDiscussionDataQueryVariables,
    GetSingleDiscussionDataProps<TChildProps>
  >(GetSingleDiscussionDataDocument, {
    alias: 'withGetSingleDiscussionData',
    ...operationOptions
  });
}

export function useGetSingleDiscussionDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSingleDiscussionDataQuery,
    GetSingleDiscussionDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSingleDiscussionDataQuery,
    GetSingleDiscussionDataQueryVariables
  >(GetSingleDiscussionDataDocument, baseOptions);
}
export type GetSingleDiscussionDataQueryHookResult = ReturnType<
  typeof useGetSingleDiscussionDataQuery
>;
export type GetSingleDiscussionDataQueryResult = ApolloReactCommon.QueryResult<
  GetSingleDiscussionDataQuery,
  GetSingleDiscussionDataQueryVariables
>;
export const PostCommentToDiscussDocument = gql`
  mutation postCommentToDiscuss($input: AddCommentToDiscussionInput!) {
    addCommentToDiscussion(input: $input) {
      discussion {
        comments(first: 100) {
          edges {
            node {
              content
              id
              insertedAt
              poster {
                nickname
                avatar
                id
              }
            }
          }
        }
      }
    }
  }
`;
export type PostCommentToDiscussMutationFn = ApolloReactCommon.MutationFunction<
  PostCommentToDiscussMutation,
  PostCommentToDiscussMutationVariables
>;
export type PostCommentToDiscussComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    PostCommentToDiscussMutation,
    PostCommentToDiscussMutationVariables
  >,
  'mutation'
>;

export const PostCommentToDiscussComponent = (
  props: PostCommentToDiscussComponentProps
) => (
  <ApolloReactComponents.Mutation<
    PostCommentToDiscussMutation,
    PostCommentToDiscussMutationVariables
  >
    mutation={PostCommentToDiscussDocument}
    {...props}
  />
);

export type PostCommentToDiscussProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  PostCommentToDiscussMutation,
  PostCommentToDiscussMutationVariables
> &
  TChildProps;
export function withPostCommentToDiscuss<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PostCommentToDiscussMutation,
    PostCommentToDiscussMutationVariables,
    PostCommentToDiscussProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PostCommentToDiscussMutation,
    PostCommentToDiscussMutationVariables,
    PostCommentToDiscussProps<TChildProps>
  >(PostCommentToDiscussDocument, {
    alias: 'withPostCommentToDiscuss',
    ...operationOptions
  });
}

export function usePostCommentToDiscussMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PostCommentToDiscussMutation,
    PostCommentToDiscussMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    PostCommentToDiscussMutation,
    PostCommentToDiscussMutationVariables
  >(PostCommentToDiscussDocument, baseOptions);
}
export type PostCommentToDiscussMutationHookResult = ReturnType<
  typeof usePostCommentToDiscussMutation
>;
export type PostCommentToDiscussMutationResult = ApolloReactCommon.MutationResult<
  PostCommentToDiscussMutation
>;
export type PostCommentToDiscussMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PostCommentToDiscussMutation,
  PostCommentToDiscussMutationVariables
>;
export const GetWholeDirectoryDocument = gql`
  query getWholeDirectory($id: ID!, $coursePackageId: ID!) {
    node(id: $id) {
      ... on CoursePackage {
        contents(first: 100) {
          edges {
            node {
              id
              name
              fileType
              parent {
                id
              }
              isLearned(coursePackageId: $coursePackageId)
              children(ignoreAns: true, coursePackageId: $coursePackageId) {
                id
                name
                fileType
                parent {
                  id
                }
                isLearned(coursePackageId: $coursePackageId)
                children(ignoreAns: true, coursePackageId: $coursePackageId) {
                  id
                  name
                  fileType
                  isLearned(coursePackageId: $coursePackageId)
                  parent {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type GetWholeDirectoryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetWholeDirectoryQuery,
    GetWholeDirectoryQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetWholeDirectoryQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetWholeDirectoryComponent = (
  props: GetWholeDirectoryComponentProps
) => (
  <ApolloReactComponents.Query<
    GetWholeDirectoryQuery,
    GetWholeDirectoryQueryVariables
  >
    query={GetWholeDirectoryDocument}
    {...props}
  />
);

export type GetWholeDirectoryProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetWholeDirectoryQuery,
  GetWholeDirectoryQueryVariables
> &
  TChildProps;
export function withGetWholeDirectory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetWholeDirectoryQuery,
    GetWholeDirectoryQueryVariables,
    GetWholeDirectoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetWholeDirectoryQuery,
    GetWholeDirectoryQueryVariables,
    GetWholeDirectoryProps<TChildProps>
  >(GetWholeDirectoryDocument, {
    alias: 'withGetWholeDirectory',
    ...operationOptions
  });
}

export function useGetWholeDirectoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetWholeDirectoryQuery,
    GetWholeDirectoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetWholeDirectoryQuery,
    GetWholeDirectoryQueryVariables
  >(GetWholeDirectoryDocument, baseOptions);
}
export type GetWholeDirectoryQueryHookResult = ReturnType<
  typeof useGetWholeDirectoryQuery
>;
export type GetWholeDirectoryQueryResult = ApolloReactCommon.QueryResult<
  GetWholeDirectoryQuery,
  GetWholeDirectoryQueryVariables
>;
export const GetCourseDirectoryDocument = gql`
  query getCourseDirectory($id: ID!) {
    node(id: $id) {
      ... on CoursePackage {
        id
        contents(first: 100) {
          edges {
            node {
              id
              name
              fileType
              isLearned(coursePackageId: $id)
              parent {
                id
              }
            }
          }
        }
        fileCardsCount
        learnedCount
        firstUnlearnedCard {
          ancestors(isMaster: true) {
            depth
            id
            name
            children(ignoreAns: true, coursePackageId: $id) {
              id
              name
              isLearned(coursePackageId: $id)
              fileType
              parent {
                id
              }
            }
          }
          id
          name
          isLearned(coursePackageId: $id)
          fileType
          parent {
            id
          }
        }
      }
    }
  }
`;
export type GetCourseDirectoryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCourseDirectoryQuery,
    GetCourseDirectoryQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCourseDirectoryQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCourseDirectoryComponent = (
  props: GetCourseDirectoryComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCourseDirectoryQuery,
    GetCourseDirectoryQueryVariables
  >
    query={GetCourseDirectoryDocument}
    {...props}
  />
);

export type GetCourseDirectoryProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetCourseDirectoryQuery,
  GetCourseDirectoryQueryVariables
> &
  TChildProps;
export function withGetCourseDirectory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCourseDirectoryQuery,
    GetCourseDirectoryQueryVariables,
    GetCourseDirectoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCourseDirectoryQuery,
    GetCourseDirectoryQueryVariables,
    GetCourseDirectoryProps<TChildProps>
  >(GetCourseDirectoryDocument, {
    alias: 'withGetCourseDirectory',
    ...operationOptions
  });
}

export function useGetCourseDirectoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCourseDirectoryQuery,
    GetCourseDirectoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCourseDirectoryQuery,
    GetCourseDirectoryQueryVariables
  >(GetCourseDirectoryDocument, baseOptions);
}
export type GetCourseDirectoryQueryHookResult = ReturnType<
  typeof useGetCourseDirectoryQuery
>;
export type GetCourseDirectoryQueryResult = ApolloReactCommon.QueryResult<
  GetCourseDirectoryQuery,
  GetCourseDirectoryQueryVariables
>;
export const GetSubDirectoryDocument = gql`
  query getSubDirectory($id: ID!, $coursePackageId: ID!) {
    node(id: $id) {
      ... on Card {
        id
        name
        parent {
          id
        }
        fileType
        children(ignoreAns: true, coursePackageId: $coursePackageId) {
          id
          name
          fileType
          parent {
            id
          }
          isLearned(coursePackageId: $coursePackageId)
        }
      }
    }
  }
`;
export type GetSubDirectoryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSubDirectoryQuery,
    GetSubDirectoryQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetSubDirectoryQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetSubDirectoryComponent = (
  props: GetSubDirectoryComponentProps
) => (
  <ApolloReactComponents.Query<
    GetSubDirectoryQuery,
    GetSubDirectoryQueryVariables
  >
    query={GetSubDirectoryDocument}
    {...props}
  />
);

export type GetSubDirectoryProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetSubDirectoryQuery,
  GetSubDirectoryQueryVariables
> &
  TChildProps;
export function withGetSubDirectory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSubDirectoryQuery,
    GetSubDirectoryQueryVariables,
    GetSubDirectoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSubDirectoryQuery,
    GetSubDirectoryQueryVariables,
    GetSubDirectoryProps<TChildProps>
  >(GetSubDirectoryDocument, {
    alias: 'withGetSubDirectory',
    ...operationOptions
  });
}

export function useGetSubDirectoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSubDirectoryQuery,
    GetSubDirectoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSubDirectoryQuery,
    GetSubDirectoryQueryVariables
  >(GetSubDirectoryDocument, baseOptions);
}
export type GetSubDirectoryQueryHookResult = ReturnType<
  typeof useGetSubDirectoryQuery
>;
export type GetSubDirectoryQueryResult = ApolloReactCommon.QueryResult<
  GetSubDirectoryQuery,
  GetSubDirectoryQueryVariables
>;
export const GetNextFolderDataDocument = gql`
  query getNextFolderData($id: ID!, $coursePackageId: ID!) {
    node(id: $id) {
      ... on Card {
        id
        name
        parent {
          id
        }
        children(ignoreAns: true, coursePackageId: $coursePackageId) {
          id
          name
          fileType
          parent {
            id
          }
          isLearned(coursePackageId: $coursePackageId)
        }
      }
    }
  }
`;
export type GetNextFolderDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetNextFolderDataQuery,
    GetNextFolderDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetNextFolderDataQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetNextFolderDataComponent = (
  props: GetNextFolderDataComponentProps
) => (
  <ApolloReactComponents.Query<
    GetNextFolderDataQuery,
    GetNextFolderDataQueryVariables
  >
    query={GetNextFolderDataDocument}
    {...props}
  />
);

export type GetNextFolderDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetNextFolderDataQuery,
  GetNextFolderDataQueryVariables
> &
  TChildProps;
export function withGetNextFolderData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetNextFolderDataQuery,
    GetNextFolderDataQueryVariables,
    GetNextFolderDataProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetNextFolderDataQuery,
    GetNextFolderDataQueryVariables,
    GetNextFolderDataProps<TChildProps>
  >(GetNextFolderDataDocument, {
    alias: 'withGetNextFolderData',
    ...operationOptions
  });
}

export function useGetNextFolderDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetNextFolderDataQuery,
    GetNextFolderDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetNextFolderDataQuery,
    GetNextFolderDataQueryVariables
  >(GetNextFolderDataDocument, baseOptions);
}
export type GetNextFolderDataQueryHookResult = ReturnType<
  typeof useGetNextFolderDataQuery
>;
export type GetNextFolderDataQueryResult = ApolloReactCommon.QueryResult<
  GetNextFolderDataQuery,
  GetNextFolderDataQueryVariables
>;
export const GetSingleCardContentDocument = gql`
  query getSingleCardContent($id: ID!, $coursePackageId: ID!, $courseId: ID!) {
    node(id: $id) {
      ... on Card {
        content
        name
        id
        isLearned(coursePackageId: $coursePackageId)
        isCollected(courseId: $courseId)
        learnedPeopleCount(coursePackageId: $coursePackageId)
        parent {
          id
        }
      }
    }
  }
`;
export type GetSingleCardContentComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSingleCardContentQuery,
    GetSingleCardContentQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetSingleCardContentQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetSingleCardContentComponent = (
  props: GetSingleCardContentComponentProps
) => (
  <ApolloReactComponents.Query<
    GetSingleCardContentQuery,
    GetSingleCardContentQueryVariables
  >
    query={GetSingleCardContentDocument}
    {...props}
  />
);

export type GetSingleCardContentProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetSingleCardContentQuery,
  GetSingleCardContentQueryVariables
> &
  TChildProps;
export function withGetSingleCardContent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSingleCardContentQuery,
    GetSingleCardContentQueryVariables,
    GetSingleCardContentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSingleCardContentQuery,
    GetSingleCardContentQueryVariables,
    GetSingleCardContentProps<TChildProps>
  >(GetSingleCardContentDocument, {
    alias: 'withGetSingleCardContent',
    ...operationOptions
  });
}

export function useGetSingleCardContentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSingleCardContentQuery,
    GetSingleCardContentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSingleCardContentQuery,
    GetSingleCardContentQueryVariables
  >(GetSingleCardContentDocument, baseOptions);
}
export type GetSingleCardContentQueryHookResult = ReturnType<
  typeof useGetSingleCardContentQuery
>;
export type GetSingleCardContentQueryResult = ApolloReactCommon.QueryResult<
  GetSingleCardContentQuery,
  GetSingleCardContentQueryVariables
>;
export const GetCardAncestorDocument = gql`
  query getCardAncestor($id: ID!) {
    node(id: $id) {
      ... on Card {
        id
      }
    }
  }
`;
export type GetCardAncestorComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCardAncestorQuery,
    GetCardAncestorQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCardAncestorQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCardAncestorComponent = (
  props: GetCardAncestorComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCardAncestorQuery,
    GetCardAncestorQueryVariables
  >
    query={GetCardAncestorDocument}
    {...props}
  />
);

export type GetCardAncestorProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetCardAncestorQuery,
  GetCardAncestorQueryVariables
> &
  TChildProps;
export function withGetCardAncestor<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCardAncestorQuery,
    GetCardAncestorQueryVariables,
    GetCardAncestorProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCardAncestorQuery,
    GetCardAncestorQueryVariables,
    GetCardAncestorProps<TChildProps>
  >(GetCardAncestorDocument, {
    alias: 'withGetCardAncestor',
    ...operationOptions
  });
}

export function useGetCardAncestorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCardAncestorQuery,
    GetCardAncestorQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCardAncestorQuery,
    GetCardAncestorQueryVariables
  >(GetCardAncestorDocument, baseOptions);
}
export type GetCardAncestorQueryHookResult = ReturnType<
  typeof useGetCardAncestorQuery
>;
export type GetCardAncestorQueryResult = ApolloReactCommon.QueryResult<
  GetCardAncestorQuery,
  GetCardAncestorQueryVariables
>;
export const GetSingleChapterAllDataDocument = gql`
  query getSingleChapterAllData($id: ID!) {
    node(id: $id) {
      ... on Card {
        id
        name
        children(ignoreAns: true) {
          id
          name
          children(ignoreAns: true) {
            id
            name
          }
        }
      }
    }
  }
`;
export type GetSingleChapterAllDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSingleChapterAllDataQuery,
    GetSingleChapterAllDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetSingleChapterAllDataQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetSingleChapterAllDataComponent = (
  props: GetSingleChapterAllDataComponentProps
) => (
  <ApolloReactComponents.Query<
    GetSingleChapterAllDataQuery,
    GetSingleChapterAllDataQueryVariables
  >
    query={GetSingleChapterAllDataDocument}
    {...props}
  />
);

export type GetSingleChapterAllDataProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetSingleChapterAllDataQuery,
  GetSingleChapterAllDataQueryVariables
> &
  TChildProps;
export function withGetSingleChapterAllData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSingleChapterAllDataQuery,
    GetSingleChapterAllDataQueryVariables,
    GetSingleChapterAllDataProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSingleChapterAllDataQuery,
    GetSingleChapterAllDataQueryVariables,
    GetSingleChapterAllDataProps<TChildProps>
  >(GetSingleChapterAllDataDocument, {
    alias: 'withGetSingleChapterAllData',
    ...operationOptions
  });
}

export function useGetSingleChapterAllDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSingleChapterAllDataQuery,
    GetSingleChapterAllDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSingleChapterAllDataQuery,
    GetSingleChapterAllDataQueryVariables
  >(GetSingleChapterAllDataDocument, baseOptions);
}
export type GetSingleChapterAllDataQueryHookResult = ReturnType<
  typeof useGetSingleChapterAllDataQuery
>;
export type GetSingleChapterAllDataQueryResult = ApolloReactCommon.QueryResult<
  GetSingleChapterAllDataQuery,
  GetSingleChapterAllDataQueryVariables
>;
export const GetUnlockSingleCardDocument = gql`
  mutation getUnlockSingleCard($input: ViewCardInput!, $coursePackageId: ID!) {
    viewCard(input: $input) {
      learningRecord {
        card {
          id
          isLearned(coursePackageId: $coursePackageId)
        }
      }
    }
  }
`;
export type GetUnlockSingleCardMutationFn = ApolloReactCommon.MutationFunction<
  GetUnlockSingleCardMutation,
  GetUnlockSingleCardMutationVariables
>;
export type GetUnlockSingleCardComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GetUnlockSingleCardMutation,
    GetUnlockSingleCardMutationVariables
  >,
  'mutation'
>;

export const GetUnlockSingleCardComponent = (
  props: GetUnlockSingleCardComponentProps
) => (
  <ApolloReactComponents.Mutation<
    GetUnlockSingleCardMutation,
    GetUnlockSingleCardMutationVariables
  >
    mutation={GetUnlockSingleCardDocument}
    {...props}
  />
);

export type GetUnlockSingleCardProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  GetUnlockSingleCardMutation,
  GetUnlockSingleCardMutationVariables
> &
  TChildProps;
export function withGetUnlockSingleCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetUnlockSingleCardMutation,
    GetUnlockSingleCardMutationVariables,
    GetUnlockSingleCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GetUnlockSingleCardMutation,
    GetUnlockSingleCardMutationVariables,
    GetUnlockSingleCardProps<TChildProps>
  >(GetUnlockSingleCardDocument, {
    alias: 'withGetUnlockSingleCard',
    ...operationOptions
  });
}

export function useGetUnlockSingleCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GetUnlockSingleCardMutation,
    GetUnlockSingleCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GetUnlockSingleCardMutation,
    GetUnlockSingleCardMutationVariables
  >(GetUnlockSingleCardDocument, baseOptions);
}
export type GetUnlockSingleCardMutationHookResult = ReturnType<
  typeof useGetUnlockSingleCardMutation
>;
export type GetUnlockSingleCardMutationResult = ApolloReactCommon.MutationResult<
  GetUnlockSingleCardMutation
>;
export type GetUnlockSingleCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GetUnlockSingleCardMutation,
  GetUnlockSingleCardMutationVariables
>;
export const CreateCardExamDocument = gql`
  mutation CreateCardExam($input: CreateCardExamInput!) {
    createCardExam(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
export type CreateCardExamMutationFn = ApolloReactCommon.MutationFunction<
  CreateCardExamMutation,
  CreateCardExamMutationVariables
>;
export type CreateCardExamComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCardExamMutation,
    CreateCardExamMutationVariables
  >,
  'mutation'
>;

export const CreateCardExamComponent = (
  props: CreateCardExamComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateCardExamMutation,
    CreateCardExamMutationVariables
  >
    mutation={CreateCardExamDocument}
    {...props}
  />
);

export type CreateCardExamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCardExamMutation,
  CreateCardExamMutationVariables
> &
  TChildProps;
export function withCreateCardExam<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCardExamMutation,
    CreateCardExamMutationVariables,
    CreateCardExamProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCardExamMutation,
    CreateCardExamMutationVariables,
    CreateCardExamProps<TChildProps>
  >(CreateCardExamDocument, {
    alias: 'withCreateCardExam',
    ...operationOptions
  });
}

export function useCreateCardExamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCardExamMutation,
    CreateCardExamMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCardExamMutation,
    CreateCardExamMutationVariables
  >(CreateCardExamDocument, baseOptions);
}
export type CreateCardExamMutationHookResult = ReturnType<
  typeof useCreateCardExamMutation
>;
export type CreateCardExamMutationResult = ApolloReactCommon.MutationResult<
  CreateCardExamMutation
>;
export type CreateCardExamMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCardExamMutation,
  CreateCardExamMutationVariables
>;
export const GetDiscussLabelsDocument = gql`
  query GetDiscussLabels {
    labels {
      id
      info
      name
      scenes
    }
  }
`;
export type GetDiscussLabelsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetDiscussLabelsQuery,
    GetDiscussLabelsQueryVariables
  >,
  'query'
>;

export const GetDiscussLabelsComponent = (
  props: GetDiscussLabelsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetDiscussLabelsQuery,
    GetDiscussLabelsQueryVariables
  >
    query={GetDiscussLabelsDocument}
    {...props}
  />
);

export type GetDiscussLabelsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetDiscussLabelsQuery,
  GetDiscussLabelsQueryVariables
> &
  TChildProps;
export function withGetDiscussLabels<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetDiscussLabelsQuery,
    GetDiscussLabelsQueryVariables,
    GetDiscussLabelsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetDiscussLabelsQuery,
    GetDiscussLabelsQueryVariables,
    GetDiscussLabelsProps<TChildProps>
  >(GetDiscussLabelsDocument, {
    alias: 'withGetDiscussLabels',
    ...operationOptions
  });
}

export function useGetDiscussLabelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetDiscussLabelsQuery,
    GetDiscussLabelsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetDiscussLabelsQuery,
    GetDiscussLabelsQueryVariables
  >(GetDiscussLabelsDocument, baseOptions);
}
export type GetDiscussLabelsQueryHookResult = ReturnType<
  typeof useGetDiscussLabelsQuery
>;
export type GetDiscussLabelsQueryResult = ApolloReactCommon.QueryResult<
  GetDiscussLabelsQuery,
  GetDiscussLabelsQueryVariables
>;
export const GetDiscussListDocument = gql`
  query GetDiscussList(
    $coursePackageId: ID!
    $matching: String
    $filter: DiscussionFilter!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: DiscussionOrder
  ) {
    node(id: $coursePackageId) {
      ... on CoursePackage {
        id
        packageTag
        discussions(
          filter: $filter
          first: $first
          after: $after
          before: $before
          last: $last
          order: $order
          matching: $matching
        ) {
          count
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              name
              insertedAt
              commentsCount
              author {
                id
                nickname
                avatar
              }
              labels {
                id
                info
                name
                scenes
              }
            }
          }
        }
      }
    }
  }
`;
export type GetDiscussListComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetDiscussListQuery,
    GetDiscussListQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetDiscussListQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetDiscussListComponent = (
  props: GetDiscussListComponentProps
) => (
  <ApolloReactComponents.Query<
    GetDiscussListQuery,
    GetDiscussListQueryVariables
  >
    query={GetDiscussListDocument}
    {...props}
  />
);

export type GetDiscussListProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetDiscussListQuery,
  GetDiscussListQueryVariables
> &
  TChildProps;
export function withGetDiscussList<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetDiscussListQuery,
    GetDiscussListQueryVariables,
    GetDiscussListProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetDiscussListQuery,
    GetDiscussListQueryVariables,
    GetDiscussListProps<TChildProps>
  >(GetDiscussListDocument, {
    alias: 'withGetDiscussList',
    ...operationOptions
  });
}

export function useGetDiscussListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetDiscussListQuery,
    GetDiscussListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetDiscussListQuery,
    GetDiscussListQueryVariables
  >(GetDiscussListDocument, baseOptions);
}
export type GetDiscussListQueryHookResult = ReturnType<
  typeof useGetDiscussListQuery
>;
export type GetDiscussListQueryResult = ApolloReactCommon.QueryResult<
  GetDiscussListQuery,
  GetDiscussListQueryVariables
>;
export const CreateDiscussDocument = gql`
  mutation CreateDiscuss($input: CreateDiscussionInput!) {
    createDiscussion(input: $input) {
      errors {
        key
        message
        code
      }
      discussion {
        id
      }
    }
  }
`;
export type CreateDiscussMutationFn = ApolloReactCommon.MutationFunction<
  CreateDiscussMutation,
  CreateDiscussMutationVariables
>;
export type CreateDiscussComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateDiscussMutation,
    CreateDiscussMutationVariables
  >,
  'mutation'
>;

export const CreateDiscussComponent = (props: CreateDiscussComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateDiscussMutation,
    CreateDiscussMutationVariables
  >
    mutation={CreateDiscussDocument}
    {...props}
  />
);

export type CreateDiscussProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateDiscussMutation,
  CreateDiscussMutationVariables
> &
  TChildProps;
export function withCreateDiscuss<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateDiscussMutation,
    CreateDiscussMutationVariables,
    CreateDiscussProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateDiscussMutation,
    CreateDiscussMutationVariables,
    CreateDiscussProps<TChildProps>
  >(CreateDiscussDocument, {
    alias: 'withCreateDiscuss',
    ...operationOptions
  });
}

export function useCreateDiscussMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDiscussMutation,
    CreateDiscussMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateDiscussMutation,
    CreateDiscussMutationVariables
  >(CreateDiscussDocument, baseOptions);
}
export type CreateDiscussMutationHookResult = ReturnType<
  typeof useCreateDiscussMutation
>;
export type CreateDiscussMutationResult = ApolloReactCommon.MutationResult<
  CreateDiscussMutation
>;
export type CreateDiscussMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDiscussMutation,
  CreateDiscussMutationVariables
>;
export const GetDiscussDetailDocument = gql`
  query GetDiscussDetail(
    $discussID: ID!
    $courseId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    node(id: $discussID) {
      ... on Discussion {
        author {
          id
          nickname
          avatar
        }
        id
        insertedAt
        isCollected(courseId: $courseId)
        labels {
          id
          info
          name
          scenes
        }
        content
        status
        name
        comments(first: $first, last: $last, before: $before, after: $after) {
          count
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            cursor
            node {
              content
              id
              insertedAt
              poster {
                id
                nickname
                avatar
              }
            }
          }
        }
      }
    }
  }
`;
export type GetDiscussDetailComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetDiscussDetailQuery,
    GetDiscussDetailQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetDiscussDetailQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetDiscussDetailComponent = (
  props: GetDiscussDetailComponentProps
) => (
  <ApolloReactComponents.Query<
    GetDiscussDetailQuery,
    GetDiscussDetailQueryVariables
  >
    query={GetDiscussDetailDocument}
    {...props}
  />
);

export type GetDiscussDetailProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetDiscussDetailQuery,
  GetDiscussDetailQueryVariables
> &
  TChildProps;
export function withGetDiscussDetail<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetDiscussDetailQuery,
    GetDiscussDetailQueryVariables,
    GetDiscussDetailProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetDiscussDetailQuery,
    GetDiscussDetailQueryVariables,
    GetDiscussDetailProps<TChildProps>
  >(GetDiscussDetailDocument, {
    alias: 'withGetDiscussDetail',
    ...operationOptions
  });
}

export function useGetDiscussDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetDiscussDetailQuery,
    GetDiscussDetailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetDiscussDetailQuery,
    GetDiscussDetailQueryVariables
  >(GetDiscussDetailDocument, baseOptions);
}
export type GetDiscussDetailQueryHookResult = ReturnType<
  typeof useGetDiscussDetailQuery
>;
export type GetDiscussDetailQueryResult = ApolloReactCommon.QueryResult<
  GetDiscussDetailQuery,
  GetDiscussDetailQueryVariables
>;
export const CloseDiscussDocument = gql`
  mutation CloseDiscuss($input: UpdateDiscussionInput!) {
    updateDiscussion(input: $input) {
      errors {
        key
        code
        message
      }
      discussion {
        status
      }
    }
  }
`;
export type CloseDiscussMutationFn = ApolloReactCommon.MutationFunction<
  CloseDiscussMutation,
  CloseDiscussMutationVariables
>;
export type CloseDiscussComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CloseDiscussMutation,
    CloseDiscussMutationVariables
  >,
  'mutation'
>;

export const CloseDiscussComponent = (props: CloseDiscussComponentProps) => (
  <ApolloReactComponents.Mutation<
    CloseDiscussMutation,
    CloseDiscussMutationVariables
  >
    mutation={CloseDiscussDocument}
    {...props}
  />
);

export type CloseDiscussProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CloseDiscussMutation,
  CloseDiscussMutationVariables
> &
  TChildProps;
export function withCloseDiscuss<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CloseDiscussMutation,
    CloseDiscussMutationVariables,
    CloseDiscussProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CloseDiscussMutation,
    CloseDiscussMutationVariables,
    CloseDiscussProps<TChildProps>
  >(CloseDiscussDocument, {
    alias: 'withCloseDiscuss',
    ...operationOptions
  });
}

export function useCloseDiscussMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CloseDiscussMutation,
    CloseDiscussMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CloseDiscussMutation,
    CloseDiscussMutationVariables
  >(CloseDiscussDocument, baseOptions);
}
export type CloseDiscussMutationHookResult = ReturnType<
  typeof useCloseDiscussMutation
>;
export type CloseDiscussMutationResult = ApolloReactCommon.MutationResult<
  CloseDiscussMutation
>;
export type CloseDiscussMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CloseDiscussMutation,
  CloseDiscussMutationVariables
>;
export const UpdateDiscussDocument = gql`
  mutation UpdateDiscuss($input: UpdateDiscussionInput!) {
    updateDiscussion(input: $input) {
      errors {
        key
        code
        message
      }
    }
  }
`;
export type UpdateDiscussMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDiscussMutation,
  UpdateDiscussMutationVariables
>;
export type UpdateDiscussComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateDiscussMutation,
    UpdateDiscussMutationVariables
  >,
  'mutation'
>;

export const UpdateDiscussComponent = (props: UpdateDiscussComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateDiscussMutation,
    UpdateDiscussMutationVariables
  >
    mutation={UpdateDiscussDocument}
    {...props}
  />
);

export type UpdateDiscussProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateDiscussMutation,
  UpdateDiscussMutationVariables
> &
  TChildProps;
export function withUpdateDiscuss<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateDiscussMutation,
    UpdateDiscussMutationVariables,
    UpdateDiscussProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateDiscussMutation,
    UpdateDiscussMutationVariables,
    UpdateDiscussProps<TChildProps>
  >(UpdateDiscussDocument, {
    alias: 'withUpdateDiscuss',
    ...operationOptions
  });
}

export function useUpdateDiscussMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDiscussMutation,
    UpdateDiscussMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateDiscussMutation,
    UpdateDiscussMutationVariables
  >(UpdateDiscussDocument, baseOptions);
}
export type UpdateDiscussMutationHookResult = ReturnType<
  typeof useUpdateDiscussMutation
>;
export type UpdateDiscussMutationResult = ApolloReactCommon.MutationResult<
  UpdateDiscussMutation
>;
export type UpdateDiscussMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDiscussMutation,
  UpdateDiscussMutationVariables
>;
export const DeleteDiscussDocument = gql`
  mutation DeleteDiscuss($input: DeleteDiscussionInput!) {
    deleteDiscussion(input: $input) {
      errors {
        key
        code
        message
      }
    }
  }
`;
export type DeleteDiscussMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDiscussMutation,
  DeleteDiscussMutationVariables
>;
export type DeleteDiscussComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteDiscussMutation,
    DeleteDiscussMutationVariables
  >,
  'mutation'
>;

export const DeleteDiscussComponent = (props: DeleteDiscussComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteDiscussMutation,
    DeleteDiscussMutationVariables
  >
    mutation={DeleteDiscussDocument}
    {...props}
  />
);

export type DeleteDiscussProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteDiscussMutation,
  DeleteDiscussMutationVariables
> &
  TChildProps;
export function withDeleteDiscuss<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteDiscussMutation,
    DeleteDiscussMutationVariables,
    DeleteDiscussProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteDiscussMutation,
    DeleteDiscussMutationVariables,
    DeleteDiscussProps<TChildProps>
  >(DeleteDiscussDocument, {
    alias: 'withDeleteDiscuss',
    ...operationOptions
  });
}

export function useDeleteDiscussMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDiscussMutation,
    DeleteDiscussMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteDiscussMutation,
    DeleteDiscussMutationVariables
  >(DeleteDiscussDocument, baseOptions);
}
export type DeleteDiscussMutationHookResult = ReturnType<
  typeof useDeleteDiscussMutation
>;
export type DeleteDiscussMutationResult = ApolloReactCommon.MutationResult<
  DeleteDiscussMutation
>;
export type DeleteDiscussMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDiscussMutation,
  DeleteDiscussMutationVariables
>;
export const CreateCommentToDiscussionDocument = gql`
  mutation createCommentToDiscussion($input: AddCommentToDiscussionInput!) {
    addCommentToDiscussion(input: $input) {
      errors {
        key
        code
        message
      }
    }
  }
`;
export type CreateCommentToDiscussionMutationFn = ApolloReactCommon.MutationFunction<
  CreateCommentToDiscussionMutation,
  CreateCommentToDiscussionMutationVariables
>;
export type CreateCommentToDiscussionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCommentToDiscussionMutation,
    CreateCommentToDiscussionMutationVariables
  >,
  'mutation'
>;

export const CreateCommentToDiscussionComponent = (
  props: CreateCommentToDiscussionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateCommentToDiscussionMutation,
    CreateCommentToDiscussionMutationVariables
  >
    mutation={CreateCommentToDiscussionDocument}
    {...props}
  />
);

export type CreateCommentToDiscussionProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateCommentToDiscussionMutation,
  CreateCommentToDiscussionMutationVariables
> &
  TChildProps;
export function withCreateCommentToDiscussion<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCommentToDiscussionMutation,
    CreateCommentToDiscussionMutationVariables,
    CreateCommentToDiscussionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCommentToDiscussionMutation,
    CreateCommentToDiscussionMutationVariables,
    CreateCommentToDiscussionProps<TChildProps>
  >(CreateCommentToDiscussionDocument, {
    alias: 'withCreateCommentToDiscussion',
    ...operationOptions
  });
}

export function useCreateCommentToDiscussionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCommentToDiscussionMutation,
    CreateCommentToDiscussionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCommentToDiscussionMutation,
    CreateCommentToDiscussionMutationVariables
  >(CreateCommentToDiscussionDocument, baseOptions);
}
export type CreateCommentToDiscussionMutationHookResult = ReturnType<
  typeof useCreateCommentToDiscussionMutation
>;
export type CreateCommentToDiscussionMutationResult = ApolloReactCommon.MutationResult<
  CreateCommentToDiscussionMutation
>;
export type CreateCommentToDiscussionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCommentToDiscussionMutation,
  CreateCommentToDiscussionMutationVariables
>;
export const RemoveCommentFromDiscussionDocument = gql`
  mutation removeCommentFromDiscussion(
    $input: RemoveCommentFromDiscussionInput!
  ) {
    removeCommentFromDiscussion(input: $input) {
      errors {
        key
        code
        message
      }
    }
  }
`;
export type RemoveCommentFromDiscussionMutationFn = ApolloReactCommon.MutationFunction<
  RemoveCommentFromDiscussionMutation,
  RemoveCommentFromDiscussionMutationVariables
>;
export type RemoveCommentFromDiscussionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveCommentFromDiscussionMutation,
    RemoveCommentFromDiscussionMutationVariables
  >,
  'mutation'
>;

export const RemoveCommentFromDiscussionComponent = (
  props: RemoveCommentFromDiscussionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RemoveCommentFromDiscussionMutation,
    RemoveCommentFromDiscussionMutationVariables
  >
    mutation={RemoveCommentFromDiscussionDocument}
    {...props}
  />
);

export type RemoveCommentFromDiscussionProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  RemoveCommentFromDiscussionMutation,
  RemoveCommentFromDiscussionMutationVariables
> &
  TChildProps;
export function withRemoveCommentFromDiscussion<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveCommentFromDiscussionMutation,
    RemoveCommentFromDiscussionMutationVariables,
    RemoveCommentFromDiscussionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveCommentFromDiscussionMutation,
    RemoveCommentFromDiscussionMutationVariables,
    RemoveCommentFromDiscussionProps<TChildProps>
  >(RemoveCommentFromDiscussionDocument, {
    alias: 'withRemoveCommentFromDiscussion',
    ...operationOptions
  });
}

export function useRemoveCommentFromDiscussionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveCommentFromDiscussionMutation,
    RemoveCommentFromDiscussionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveCommentFromDiscussionMutation,
    RemoveCommentFromDiscussionMutationVariables
  >(RemoveCommentFromDiscussionDocument, baseOptions);
}
export type RemoveCommentFromDiscussionMutationHookResult = ReturnType<
  typeof useRemoveCommentFromDiscussionMutation
>;
export type RemoveCommentFromDiscussionMutationResult = ApolloReactCommon.MutationResult<
  RemoveCommentFromDiscussionMutation
>;
export type RemoveCommentFromDiscussionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveCommentFromDiscussionMutation,
  RemoveCommentFromDiscussionMutationVariables
>;
export const GetDiscussCommentDocument = gql`
  query GetDiscussComment(
    $discussID: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    node(id: $discussID) {
      ... on Discussion {
        comments(first: $first, last: $last, before: $before, after: $after) {
          count
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            cursor
            node {
              content
              id
              insertedAt
              poster {
                id
                nickname
                avatar
              }
            }
          }
        }
      }
    }
  }
`;
export type GetDiscussCommentComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetDiscussCommentQuery,
    GetDiscussCommentQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetDiscussCommentQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetDiscussCommentComponent = (
  props: GetDiscussCommentComponentProps
) => (
  <ApolloReactComponents.Query<
    GetDiscussCommentQuery,
    GetDiscussCommentQueryVariables
  >
    query={GetDiscussCommentDocument}
    {...props}
  />
);

export type GetDiscussCommentProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetDiscussCommentQuery,
  GetDiscussCommentQueryVariables
> &
  TChildProps;
export function withGetDiscussComment<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetDiscussCommentQuery,
    GetDiscussCommentQueryVariables,
    GetDiscussCommentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetDiscussCommentQuery,
    GetDiscussCommentQueryVariables,
    GetDiscussCommentProps<TChildProps>
  >(GetDiscussCommentDocument, {
    alias: 'withGetDiscussComment',
    ...operationOptions
  });
}

export function useGetDiscussCommentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetDiscussCommentQuery,
    GetDiscussCommentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetDiscussCommentQuery,
    GetDiscussCommentQueryVariables
  >(GetDiscussCommentDocument, baseOptions);
}
export type GetDiscussCommentQueryHookResult = ReturnType<
  typeof useGetDiscussCommentQuery
>;
export type GetDiscussCommentQueryResult = ApolloReactCommon.QueryResult<
  GetDiscussCommentQuery,
  GetDiscussCommentQueryVariables
>;
export const GetCommitDetailDocument = gql`
  query GetCommitDetail($id: ID!) {
    node(id: $id) {
      ... on Commit {
        assignment {
          id
          pid
          location
        }
        content
        diff
        id
      }
    }
  }
`;
export type GetCommitDetailComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCommitDetailQuery,
    GetCommitDetailQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCommitDetailQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCommitDetailComponent = (
  props: GetCommitDetailComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCommitDetailQuery,
    GetCommitDetailQueryVariables
  >
    query={GetCommitDetailDocument}
    {...props}
  />
);

export type GetCommitDetailProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetCommitDetailQuery,
  GetCommitDetailQueryVariables
> &
  TChildProps;
export function withGetCommitDetail<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCommitDetailQuery,
    GetCommitDetailQueryVariables,
    GetCommitDetailProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCommitDetailQuery,
    GetCommitDetailQueryVariables,
    GetCommitDetailProps<TChildProps>
  >(GetCommitDetailDocument, {
    alias: 'withGetCommitDetail',
    ...operationOptions
  });
}

export function useGetCommitDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCommitDetailQuery,
    GetCommitDetailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCommitDetailQuery,
    GetCommitDetailQueryVariables
  >(GetCommitDetailDocument, baseOptions);
}
export type GetCommitDetailQueryHookResult = ReturnType<
  typeof useGetCommitDetailQuery
>;
export type GetCommitDetailQueryResult = ApolloReactCommon.QueryResult<
  GetCommitDetailQuery,
  GetCommitDetailQueryVariables
>;
export const GetCommitDetailContentDocument = gql`
  query GetCommitDetailContent($id: ID!) {
    node(id: $id) {
      ... on Commit {
        assignment {
          id
          pid
          location
        }
        content
        id
      }
    }
  }
`;
export type GetCommitDetailContentComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCommitDetailContentQuery,
    GetCommitDetailContentQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCommitDetailContentQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCommitDetailContentComponent = (
  props: GetCommitDetailContentComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCommitDetailContentQuery,
    GetCommitDetailContentQueryVariables
  >
    query={GetCommitDetailContentDocument}
    {...props}
  />
);

export type GetCommitDetailContentProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetCommitDetailContentQuery,
  GetCommitDetailContentQueryVariables
> &
  TChildProps;
export function withGetCommitDetailContent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCommitDetailContentQuery,
    GetCommitDetailContentQueryVariables,
    GetCommitDetailContentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCommitDetailContentQuery,
    GetCommitDetailContentQueryVariables,
    GetCommitDetailContentProps<TChildProps>
  >(GetCommitDetailContentDocument, {
    alias: 'withGetCommitDetailContent',
    ...operationOptions
  });
}

export function useGetCommitDetailContentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCommitDetailContentQuery,
    GetCommitDetailContentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCommitDetailContentQuery,
    GetCommitDetailContentQueryVariables
  >(GetCommitDetailContentDocument, baseOptions);
}
export type GetCommitDetailContentQueryHookResult = ReturnType<
  typeof useGetCommitDetailContentQuery
>;
export type GetCommitDetailContentQueryResult = ApolloReactCommon.QueryResult<
  GetCommitDetailContentQuery,
  GetCommitDetailContentQueryVariables
>;
export const GetCommitDetailDiffDocument = gql`
  query GetCommitDetailDiff($id: ID!) {
    node(id: $id) {
      ... on Commit {
        assignment {
          id
          pid
          location
        }
        diff
        id
      }
    }
  }
`;
export type GetCommitDetailDiffComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCommitDetailDiffQuery,
    GetCommitDetailDiffQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCommitDetailDiffQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCommitDetailDiffComponent = (
  props: GetCommitDetailDiffComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCommitDetailDiffQuery,
    GetCommitDetailDiffQueryVariables
  >
    query={GetCommitDetailDiffDocument}
    {...props}
  />
);

export type GetCommitDetailDiffProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetCommitDetailDiffQuery,
  GetCommitDetailDiffQueryVariables
> &
  TChildProps;
export function withGetCommitDetailDiff<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCommitDetailDiffQuery,
    GetCommitDetailDiffQueryVariables,
    GetCommitDetailDiffProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCommitDetailDiffQuery,
    GetCommitDetailDiffQueryVariables,
    GetCommitDetailDiffProps<TChildProps>
  >(GetCommitDetailDiffDocument, {
    alias: 'withGetCommitDetailDiff',
    ...operationOptions
  });
}

export function useGetCommitDetailDiffQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCommitDetailDiffQuery,
    GetCommitDetailDiffQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCommitDetailDiffQuery,
    GetCommitDetailDiffQueryVariables
  >(GetCommitDetailDiffDocument, baseOptions);
}
export type GetCommitDetailDiffQueryHookResult = ReturnType<
  typeof useGetCommitDetailDiffQuery
>;
export type GetCommitDetailDiffQueryResult = ApolloReactCommon.QueryResult<
  GetCommitDetailDiffQuery,
  GetCommitDetailDiffQueryVariables
>;
export const GetAllAssignmentsDocument = gql`
  query getAllAssignments($id: ID!) {
    node(id: $id) {
      ... on CoursePackage {
        assignments {
          name
          id
          isIveDone
          questions {
            name
            id
          }
        }
      }
    }
  }
`;
export type GetAllAssignmentsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetAllAssignmentsQuery,
    GetAllAssignmentsQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetAllAssignmentsQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetAllAssignmentsComponent = (
  props: GetAllAssignmentsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetAllAssignmentsQuery,
    GetAllAssignmentsQueryVariables
  >
    query={GetAllAssignmentsDocument}
    {...props}
  />
);

export type GetAllAssignmentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetAllAssignmentsQuery,
  GetAllAssignmentsQueryVariables
> &
  TChildProps;
export function withGetAllAssignments<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAllAssignmentsQuery,
    GetAllAssignmentsQueryVariables,
    GetAllAssignmentsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAllAssignmentsQuery,
    GetAllAssignmentsQueryVariables,
    GetAllAssignmentsProps<TChildProps>
  >(GetAllAssignmentsDocument, {
    alias: 'withGetAllAssignments',
    ...operationOptions
  });
}

export function useGetAllAssignmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllAssignmentsQuery,
    GetAllAssignmentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAllAssignmentsQuery,
    GetAllAssignmentsQueryVariables
  >(GetAllAssignmentsDocument, baseOptions);
}
export type GetAllAssignmentsQueryHookResult = ReturnType<
  typeof useGetAllAssignmentsQuery
>;
export type GetAllAssignmentsQueryResult = ApolloReactCommon.QueryResult<
  GetAllAssignmentsQuery,
  GetAllAssignmentsQueryVariables
>;
export const CheckSingleAnswerDocument = gql`
  query checkSingleAnswer($id: ID!, $courseId: ID!) {
    node(id: $id) {
      ... on Assignment {
        content
        commentsCount
        insertedAt
        author {
          nickname
          avatar
        }
        id
        isCollected(courseId: $courseId)
        comments(first: 100) {
          edges {
            node {
              content
              id
              insertedAt
              poster {
                nickname
                avatar
                id
              }
            }
          }
        }
      }
    }
  }
`;
export type CheckSingleAnswerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CheckSingleAnswerQuery,
    CheckSingleAnswerQueryVariables
  >,
  'query'
> &
  (
    | { variables: CheckSingleAnswerQueryVariables; skip?: boolean }
    | { skip: boolean });

export const CheckSingleAnswerComponent = (
  props: CheckSingleAnswerComponentProps
) => (
  <ApolloReactComponents.Query<
    CheckSingleAnswerQuery,
    CheckSingleAnswerQueryVariables
  >
    query={CheckSingleAnswerDocument}
    {...props}
  />
);

export type CheckSingleAnswerProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CheckSingleAnswerQuery,
  CheckSingleAnswerQueryVariables
> &
  TChildProps;
export function withCheckSingleAnswer<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CheckSingleAnswerQuery,
    CheckSingleAnswerQueryVariables,
    CheckSingleAnswerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CheckSingleAnswerQuery,
    CheckSingleAnswerQueryVariables,
    CheckSingleAnswerProps<TChildProps>
  >(CheckSingleAnswerDocument, {
    alias: 'withCheckSingleAnswer',
    ...operationOptions
  });
}

export function useCheckSingleAnswerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CheckSingleAnswerQuery,
    CheckSingleAnswerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CheckSingleAnswerQuery,
    CheckSingleAnswerQueryVariables
  >(CheckSingleAnswerDocument, baseOptions);
}
export type CheckSingleAnswerQueryHookResult = ReturnType<
  typeof useCheckSingleAnswerQuery
>;
export type CheckSingleAnswerQueryResult = ApolloReactCommon.QueryResult<
  CheckSingleAnswerQuery,
  CheckSingleAnswerQueryVariables
>;
export const GetMyAnswerIdDocument = gql`
  query getMyAnswerId($id: ID!) {
    node(id: $id) {
      ... on Assignment {
        answers(first: 1, filter: { isMine: true }) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
export type GetMyAnswerIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMyAnswerIdQuery,
    GetMyAnswerIdQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetMyAnswerIdQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetMyAnswerIdComponent = (props: GetMyAnswerIdComponentProps) => (
  <ApolloReactComponents.Query<GetMyAnswerIdQuery, GetMyAnswerIdQueryVariables>
    query={GetMyAnswerIdDocument}
    {...props}
  />
);

export type GetMyAnswerIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetMyAnswerIdQuery,
  GetMyAnswerIdQueryVariables
> &
  TChildProps;
export function withGetMyAnswerId<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMyAnswerIdQuery,
    GetMyAnswerIdQueryVariables,
    GetMyAnswerIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMyAnswerIdQuery,
    GetMyAnswerIdQueryVariables,
    GetMyAnswerIdProps<TChildProps>
  >(GetMyAnswerIdDocument, {
    alias: 'withGetMyAnswerId',
    ...operationOptions
  });
}

export function useGetMyAnswerIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMyAnswerIdQuery,
    GetMyAnswerIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMyAnswerIdQuery,
    GetMyAnswerIdQueryVariables
  >(GetMyAnswerIdDocument, baseOptions);
}
export type GetMyAnswerIdQueryHookResult = ReturnType<
  typeof useGetMyAnswerIdQuery
>;
export type GetMyAnswerIdQueryResult = ApolloReactCommon.QueryResult<
  GetMyAnswerIdQuery,
  GetMyAnswerIdQueryVariables
>;
export const CheckOtherAnswersDocument = gql`
  query checkOtherAnswers(
    $id: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: AnswerFilter
    $matching: String
    $order: AssignmentOrder
  ) {
    node(id: $id) {
      ... on Assignment {
        name
        answers(
          first: $first
          after: $after
          last: $last
          before: $before
          filter: $filter
          matching: $matching
          order: $order
        ) {
          count
          edges {
            node {
              id
              author {
                nickname
                avatar
              }
              commentsCount
              insertedAt
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  }
`;
export type CheckOtherAnswersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CheckOtherAnswersQuery,
    CheckOtherAnswersQueryVariables
  >,
  'query'
> &
  (
    | { variables: CheckOtherAnswersQueryVariables; skip?: boolean }
    | { skip: boolean });

export const CheckOtherAnswersComponent = (
  props: CheckOtherAnswersComponentProps
) => (
  <ApolloReactComponents.Query<
    CheckOtherAnswersQuery,
    CheckOtherAnswersQueryVariables
  >
    query={CheckOtherAnswersDocument}
    {...props}
  />
);

export type CheckOtherAnswersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CheckOtherAnswersQuery,
  CheckOtherAnswersQueryVariables
> &
  TChildProps;
export function withCheckOtherAnswers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CheckOtherAnswersQuery,
    CheckOtherAnswersQueryVariables,
    CheckOtherAnswersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CheckOtherAnswersQuery,
    CheckOtherAnswersQueryVariables,
    CheckOtherAnswersProps<TChildProps>
  >(CheckOtherAnswersDocument, {
    alias: 'withCheckOtherAnswers',
    ...operationOptions
  });
}

export function useCheckOtherAnswersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CheckOtherAnswersQuery,
    CheckOtherAnswersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CheckOtherAnswersQuery,
    CheckOtherAnswersQueryVariables
  >(CheckOtherAnswersDocument, baseOptions);
}
export type CheckOtherAnswersQueryHookResult = ReturnType<
  typeof useCheckOtherAnswersQuery
>;
export type CheckOtherAnswersQueryResult = ApolloReactCommon.QueryResult<
  CheckOtherAnswersQuery,
  CheckOtherAnswersQueryVariables
>;
export const RemoveCommentFromAssignmentDocument = gql`
  mutation removeCommentFromAssignment(
    $input: RemoveCommentFromAssignmentInput!
  ) {
    removeCommentFromAssignment(input: $input) {
      assignment {
        comments(first: 100) {
          edges {
            node {
              poster {
                nickname
                avatar
                id
              }
              id
              content
            }
          }
        }
      }
      errors {
        code
        message
      }
    }
  }
`;
export type RemoveCommentFromAssignmentMutationFn = ApolloReactCommon.MutationFunction<
  RemoveCommentFromAssignmentMutation,
  RemoveCommentFromAssignmentMutationVariables
>;
export type RemoveCommentFromAssignmentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveCommentFromAssignmentMutation,
    RemoveCommentFromAssignmentMutationVariables
  >,
  'mutation'
>;

export const RemoveCommentFromAssignmentComponent = (
  props: RemoveCommentFromAssignmentComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RemoveCommentFromAssignmentMutation,
    RemoveCommentFromAssignmentMutationVariables
  >
    mutation={RemoveCommentFromAssignmentDocument}
    {...props}
  />
);

export type RemoveCommentFromAssignmentProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  RemoveCommentFromAssignmentMutation,
  RemoveCommentFromAssignmentMutationVariables
> &
  TChildProps;
export function withRemoveCommentFromAssignment<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveCommentFromAssignmentMutation,
    RemoveCommentFromAssignmentMutationVariables,
    RemoveCommentFromAssignmentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveCommentFromAssignmentMutation,
    RemoveCommentFromAssignmentMutationVariables,
    RemoveCommentFromAssignmentProps<TChildProps>
  >(RemoveCommentFromAssignmentDocument, {
    alias: 'withRemoveCommentFromAssignment',
    ...operationOptions
  });
}

export function useRemoveCommentFromAssignmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveCommentFromAssignmentMutation,
    RemoveCommentFromAssignmentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveCommentFromAssignmentMutation,
    RemoveCommentFromAssignmentMutationVariables
  >(RemoveCommentFromAssignmentDocument, baseOptions);
}
export type RemoveCommentFromAssignmentMutationHookResult = ReturnType<
  typeof useRemoveCommentFromAssignmentMutation
>;
export type RemoveCommentFromAssignmentMutationResult = ApolloReactCommon.MutationResult<
  RemoveCommentFromAssignmentMutation
>;
export type RemoveCommentFromAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveCommentFromAssignmentMutation,
  RemoveCommentFromAssignmentMutationVariables
>;
export const GetCourseIdDocument = gql`
  query getCourseId($id: ID!) {
    node(id: $id) {
      ... on Course {
        id
        title
        coursesPackages {
          id
          packageTag
        }
      }
    }
  }
`;
export type GetCourseIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCourseIdQuery,
    GetCourseIdQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCourseIdQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCourseIdComponent = (props: GetCourseIdComponentProps) => (
  <ApolloReactComponents.Query<GetCourseIdQuery, GetCourseIdQueryVariables>
    query={GetCourseIdDocument}
    {...props}
  />
);

export type GetCourseIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetCourseIdQuery,
  GetCourseIdQueryVariables
> &
  TChildProps;
export function withGetCourseId<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCourseIdQuery,
    GetCourseIdQueryVariables,
    GetCourseIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCourseIdQuery,
    GetCourseIdQueryVariables,
    GetCourseIdProps<TChildProps>
  >(GetCourseIdDocument, {
    alias: 'withGetCourseId',
    ...operationOptions
  });
}

export function useGetCourseIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCourseIdQuery,
    GetCourseIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCourseIdQuery, GetCourseIdQueryVariables>(
    GetCourseIdDocument,
    baseOptions
  );
}
export type GetCourseIdQueryHookResult = ReturnType<typeof useGetCourseIdQuery>;
export type GetCourseIdQueryResult = ApolloReactCommon.QueryResult<
  GetCourseIdQuery,
  GetCourseIdQueryVariables
>;
export const GetCourseInfoDocument = gql`
  query getCourseInfo($id: ID!) {
    node(id: $id) {
      ... on Course {
        id
        title
        isOnline
        coursesPackages {
          id
          packageTag
          status
        }
      }
    }
  }
`;
export type GetCourseInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCourseInfoQuery,
    GetCourseInfoQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetCourseInfoQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetCourseInfoComponent = (props: GetCourseInfoComponentProps) => (
  <ApolloReactComponents.Query<GetCourseInfoQuery, GetCourseInfoQueryVariables>
    query={GetCourseInfoDocument}
    {...props}
  />
);

export type GetCourseInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetCourseInfoQuery,
  GetCourseInfoQueryVariables
> &
  TChildProps;
export function withGetCourseInfo<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCourseInfoQuery,
    GetCourseInfoQueryVariables,
    GetCourseInfoProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCourseInfoQuery,
    GetCourseInfoQueryVariables,
    GetCourseInfoProps<TChildProps>
  >(GetCourseInfoDocument, {
    alias: 'withGetCourseInfo',
    ...operationOptions
  });
}

export function useGetCourseInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCourseInfoQuery,
    GetCourseInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCourseInfoQuery,
    GetCourseInfoQueryVariables
  >(GetCourseInfoDocument, baseOptions);
}
export type GetCourseInfoQueryHookResult = ReturnType<
  typeof useGetCourseInfoQuery
>;
export type GetCourseInfoQueryResult = ApolloReactCommon.QueryResult<
  GetCourseInfoQuery,
  GetCourseInfoQueryVariables
>;
export const GetNextCardDocument = gql`
  query getNextCard($courseId: ID!, $coursePackgeId: ID!, $currentCardId: ID!) {
    node(id: $coursePackgeId) {
      ... on CoursePackage {
        id
        nextCard(currentCardId: $currentCardId) {
          id
          content
          isCollected(courseId: $courseId)
          name
          parent(isMaster: true) {
            id
          }
          isLearned(coursePackageId: $coursePackgeId)
          learnedPeopleCount(coursePackageId: $coursePackgeId)
        }
      }
    }
  }
`;
export type GetNextCardComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetNextCardQuery,
    GetNextCardQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetNextCardQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetNextCardComponent = (props: GetNextCardComponentProps) => (
  <ApolloReactComponents.Query<GetNextCardQuery, GetNextCardQueryVariables>
    query={GetNextCardDocument}
    {...props}
  />
);

export type GetNextCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetNextCardQuery,
  GetNextCardQueryVariables
> &
  TChildProps;
export function withGetNextCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetNextCardQuery,
    GetNextCardQueryVariables,
    GetNextCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetNextCardQuery,
    GetNextCardQueryVariables,
    GetNextCardProps<TChildProps>
  >(GetNextCardDocument, {
    alias: 'withGetNextCard',
    ...operationOptions
  });
}

export function useGetNextCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetNextCardQuery,
    GetNextCardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetNextCardQuery, GetNextCardQueryVariables>(
    GetNextCardDocument,
    baseOptions
  );
}
export type GetNextCardQueryHookResult = ReturnType<typeof useGetNextCardQuery>;
export type GetNextCardQueryResult = ApolloReactCommon.QueryResult<
  GetNextCardQuery,
  GetNextCardQueryVariables
>;
export const GetPrevCardDocument = gql`
  query getPrevCard($courseId: ID!, $coursePackgeId: ID!, $currentCardId: ID!) {
    node(id: $coursePackgeId) {
      ... on CoursePackage {
        id
        previousCard(currentCardId: $currentCardId) {
          id
          content
          isCollected(courseId: $courseId)
          name
          parent(isMaster: true) {
            id
          }
          isLearned(coursePackageId: $coursePackgeId)
          learnedPeopleCount(coursePackageId: $coursePackgeId)
        }
      }
    }
  }
`;
export type GetPrevCardComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetPrevCardQuery,
    GetPrevCardQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetPrevCardQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetPrevCardComponent = (props: GetPrevCardComponentProps) => (
  <ApolloReactComponents.Query<GetPrevCardQuery, GetPrevCardQueryVariables>
    query={GetPrevCardDocument}
    {...props}
  />
);

export type GetPrevCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetPrevCardQuery,
  GetPrevCardQueryVariables
> &
  TChildProps;
export function withGetPrevCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetPrevCardQuery,
    GetPrevCardQueryVariables,
    GetPrevCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetPrevCardQuery,
    GetPrevCardQueryVariables,
    GetPrevCardProps<TChildProps>
  >(GetPrevCardDocument, {
    alias: 'withGetPrevCard',
    ...operationOptions
  });
}

export function useGetPrevCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPrevCardQuery,
    GetPrevCardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetPrevCardQuery, GetPrevCardQueryVariables>(
    GetPrevCardDocument,
    baseOptions
  );
}
export type GetPrevCardQueryHookResult = ReturnType<typeof useGetPrevCardQuery>;
export type GetPrevCardQueryResult = ApolloReactCommon.QueryResult<
  GetPrevCardQuery,
  GetPrevCardQueryVariables
>;
export const ShareCardLinkDocument = gql`
  query shareCardLink(
    $courseId: ID!
    $coursePackgeId: ID!
    $currentCardId: ID!
  ) {
    node(id: $currentCardId) {
      ... on Card {
        id
        ancestors(isMaster: true) {
          depth
          id
          name
          children(ignoreAns: false) {
            id
            name
            isLearned(coursePackageId: $coursePackgeId)
            fileType
            parent {
              id
            }
          }
        }
        id
        content
        isCollected(courseId: $courseId)
        name
        parent(isMaster: true) {
          id
        }
        learnedPeopleCount(coursePackageId: $coursePackgeId)
      }
    }
  }
`;
export type ShareCardLinkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ShareCardLinkQuery,
    ShareCardLinkQueryVariables
  >,
  'query'
> &
  (
    | { variables: ShareCardLinkQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ShareCardLinkComponent = (props: ShareCardLinkComponentProps) => (
  <ApolloReactComponents.Query<ShareCardLinkQuery, ShareCardLinkQueryVariables>
    query={ShareCardLinkDocument}
    {...props}
  />
);

export type ShareCardLinkProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ShareCardLinkQuery,
  ShareCardLinkQueryVariables
> &
  TChildProps;
export function withShareCardLink<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ShareCardLinkQuery,
    ShareCardLinkQueryVariables,
    ShareCardLinkProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ShareCardLinkQuery,
    ShareCardLinkQueryVariables,
    ShareCardLinkProps<TChildProps>
  >(ShareCardLinkDocument, {
    alias: 'withShareCardLink',
    ...operationOptions
  });
}

export function useShareCardLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ShareCardLinkQuery,
    ShareCardLinkQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ShareCardLinkQuery,
    ShareCardLinkQueryVariables
  >(ShareCardLinkDocument, baseOptions);
}
export type ShareCardLinkQueryHookResult = ReturnType<
  typeof useShareCardLinkQuery
>;
export type ShareCardLinkQueryResult = ApolloReactCommon.QueryResult<
  ShareCardLinkQuery,
  ShareCardLinkQueryVariables
>;
export const GetSingleAssignmentDocument = gql`
  query getSingleAssignment($id: ID!, $courseId: ID!) {
    node(id: $id) {
      ... on Assignment {
        name
        author {
          avatar
          nickname
        }
        answers(filter: { isMine: true }, first: 1) {
          edges {
            node {
              author {
                avatar
                nickname
              }
              insertedAt
              content
              commentsCount
              id
              isCollected(courseId: $courseId)
              comments(first: 100) {
                edges {
                  node {
                    content
                    id
                    poster {
                      avatar
                      nickname
                      id
                    }
                    insertedAt
                  }
                }
              }
            }
          }
        }
        isIveDone
        commentsCount
        insertedAt
        questions {
          content
          pid
          name
          id
          insertedAt
          author {
            avatar
            nickname
          }
        }
      }
    }
  }
`;
export type GetSingleAssignmentComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSingleAssignmentQuery,
    GetSingleAssignmentQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetSingleAssignmentQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetSingleAssignmentComponent = (
  props: GetSingleAssignmentComponentProps
) => (
  <ApolloReactComponents.Query<
    GetSingleAssignmentQuery,
    GetSingleAssignmentQueryVariables
  >
    query={GetSingleAssignmentDocument}
    {...props}
  />
);

export type GetSingleAssignmentProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GetSingleAssignmentQuery,
  GetSingleAssignmentQueryVariables
> &
  TChildProps;
export function withGetSingleAssignment<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSingleAssignmentQuery,
    GetSingleAssignmentQueryVariables,
    GetSingleAssignmentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSingleAssignmentQuery,
    GetSingleAssignmentQueryVariables,
    GetSingleAssignmentProps<TChildProps>
  >(GetSingleAssignmentDocument, {
    alias: 'withGetSingleAssignment',
    ...operationOptions
  });
}

export function useGetSingleAssignmentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSingleAssignmentQuery,
    GetSingleAssignmentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSingleAssignmentQuery,
    GetSingleAssignmentQueryVariables
  >(GetSingleAssignmentDocument, baseOptions);
}
export type GetSingleAssignmentQueryHookResult = ReturnType<
  typeof useGetSingleAssignmentQuery
>;
export type GetSingleAssignmentQueryResult = ApolloReactCommon.QueryResult<
  GetSingleAssignmentQuery,
  GetSingleAssignmentQueryVariables
>;
export const GetAnswerCommitsDocument = gql`
  query GetAnswerCommits($id: ID!) {
    node(id: $id) {
      ... on Assignment {
        commits {
          commitHash
          commitedAt
        }
      }
    }
  }
`;
export type GetAnswerCommitsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetAnswerCommitsQuery,
    GetAnswerCommitsQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetAnswerCommitsQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetAnswerCommitsComponent = (
  props: GetAnswerCommitsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetAnswerCommitsQuery,
    GetAnswerCommitsQueryVariables
  >
    query={GetAnswerCommitsDocument}
    {...props}
  />
);

export type GetAnswerCommitsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetAnswerCommitsQuery,
  GetAnswerCommitsQueryVariables
> &
  TChildProps;
export function withGetAnswerCommits<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAnswerCommitsQuery,
    GetAnswerCommitsQueryVariables,
    GetAnswerCommitsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAnswerCommitsQuery,
    GetAnswerCommitsQueryVariables,
    GetAnswerCommitsProps<TChildProps>
  >(GetAnswerCommitsDocument, {
    alias: 'withGetAnswerCommits',
    ...operationOptions
  });
}

export function useGetAnswerCommitsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAnswerCommitsQuery,
    GetAnswerCommitsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAnswerCommitsQuery,
    GetAnswerCommitsQueryVariables
  >(GetAnswerCommitsDocument, baseOptions);
}
export type GetAnswerCommitsQueryHookResult = ReturnType<
  typeof useGetAnswerCommitsQuery
>;
export type GetAnswerCommitsQueryResult = ApolloReactCommon.QueryResult<
  GetAnswerCommitsQuery,
  GetAnswerCommitsQueryVariables
>;
export const GetSingleAnswerIdDocument = gql`
  query getSingleAnswerId($id: ID!) {
    node(id: $id) {
      ... on Assignment {
        answers(first: 1, filter: { isMine: false }, order: DESC_INSERTED_AT) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
export type GetSingleAnswerIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSingleAnswerIdQuery,
    GetSingleAnswerIdQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetSingleAnswerIdQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetSingleAnswerIdComponent = (
  props: GetSingleAnswerIdComponentProps
) => (
  <ApolloReactComponents.Query<
    GetSingleAnswerIdQuery,
    GetSingleAnswerIdQueryVariables
  >
    query={GetSingleAnswerIdDocument}
    {...props}
  />
);

export type GetSingleAnswerIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetSingleAnswerIdQuery,
  GetSingleAnswerIdQueryVariables
> &
  TChildProps;
export function withGetSingleAnswerId<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSingleAnswerIdQuery,
    GetSingleAnswerIdQueryVariables,
    GetSingleAnswerIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSingleAnswerIdQuery,
    GetSingleAnswerIdQueryVariables,
    GetSingleAnswerIdProps<TChildProps>
  >(GetSingleAnswerIdDocument, {
    alias: 'withGetSingleAnswerId',
    ...operationOptions
  });
}

export function useGetSingleAnswerIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSingleAnswerIdQuery,
    GetSingleAnswerIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSingleAnswerIdQuery,
    GetSingleAnswerIdQueryVariables
  >(GetSingleAnswerIdDocument, baseOptions);
}
export type GetSingleAnswerIdQueryHookResult = ReturnType<
  typeof useGetSingleAnswerIdQuery
>;
export type GetSingleAnswerIdQueryResult = ApolloReactCommon.QueryResult<
  GetSingleAnswerIdQuery,
  GetSingleAnswerIdQueryVariables
>;
export const UnCollectDocument = gql`
  mutation unCollect($input: UncollectCardInput!) {
    uncollectCard(input: $input) {
      errors {
        code
        key
        message
      }
    }
  }
`;
export type UnCollectMutationFn = ApolloReactCommon.MutationFunction<
  UnCollectMutation,
  UnCollectMutationVariables
>;
export type UnCollectComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnCollectMutation,
    UnCollectMutationVariables
  >,
  'mutation'
>;

export const UnCollectComponent = (props: UnCollectComponentProps) => (
  <ApolloReactComponents.Mutation<UnCollectMutation, UnCollectMutationVariables>
    mutation={UnCollectDocument}
    {...props}
  />
);

export type UnCollectProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UnCollectMutation,
  UnCollectMutationVariables
> &
  TChildProps;
export function withUnCollect<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UnCollectMutation,
    UnCollectMutationVariables,
    UnCollectProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UnCollectMutation,
    UnCollectMutationVariables,
    UnCollectProps<TChildProps>
  >(UnCollectDocument, {
    alias: 'withUnCollect',
    ...operationOptions
  });
}

export function useUnCollectMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnCollectMutation,
    UnCollectMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UnCollectMutation,
    UnCollectMutationVariables
  >(UnCollectDocument, baseOptions);
}
export type UnCollectMutationHookResult = ReturnType<
  typeof useUnCollectMutation
>;
export type UnCollectMutationResult = ApolloReactCommon.MutationResult<
  UnCollectMutation
>;
export type UnCollectMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnCollectMutation,
  UnCollectMutationVariables
>;
export const CollectCardDocument = gql`
  mutation CollectCard($input: CollectCardInput!) {
    collectCard(input: $input) {
      errors {
        message
      }
    }
  }
`;
export type CollectCardMutationFn = ApolloReactCommon.MutationFunction<
  CollectCardMutation,
  CollectCardMutationVariables
>;
export type CollectCardComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CollectCardMutation,
    CollectCardMutationVariables
  >,
  'mutation'
>;

export const CollectCardComponent = (props: CollectCardComponentProps) => (
  <ApolloReactComponents.Mutation<
    CollectCardMutation,
    CollectCardMutationVariables
  >
    mutation={CollectCardDocument}
    {...props}
  />
);

export type CollectCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CollectCardMutation,
  CollectCardMutationVariables
> &
  TChildProps;
export function withCollectCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CollectCardMutation,
    CollectCardMutationVariables,
    CollectCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CollectCardMutation,
    CollectCardMutationVariables,
    CollectCardProps<TChildProps>
  >(CollectCardDocument, {
    alias: 'withCollectCard',
    ...operationOptions
  });
}

export function useCollectCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CollectCardMutation,
    CollectCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CollectCardMutation,
    CollectCardMutationVariables
  >(CollectCardDocument, baseOptions);
}
export type CollectCardMutationHookResult = ReturnType<
  typeof useCollectCardMutation
>;
export type CollectCardMutationResult = ApolloReactCommon.MutationResult<
  CollectCardMutation
>;
export type CollectCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CollectCardMutation,
  CollectCardMutationVariables
>;
export const AddCommentToSingleAssignmentDocument = gql`
  mutation addCommentToSingleAssignment($input: AddCommentToAssignmentInput!) {
    addCommentToAssignment(input: $input) {
      assignment {
        comments(first: 100) {
          edges {
            node {
              poster {
                nickname
                avatar
                id
              }
              id
              content
              insertedAt
            }
          }
        }
      }
      errors {
        code
        key
        message
      }
    }
  }
`;
export type AddCommentToSingleAssignmentMutationFn = ApolloReactCommon.MutationFunction<
  AddCommentToSingleAssignmentMutation,
  AddCommentToSingleAssignmentMutationVariables
>;
export type AddCommentToSingleAssignmentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddCommentToSingleAssignmentMutation,
    AddCommentToSingleAssignmentMutationVariables
  >,
  'mutation'
>;

export const AddCommentToSingleAssignmentComponent = (
  props: AddCommentToSingleAssignmentComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddCommentToSingleAssignmentMutation,
    AddCommentToSingleAssignmentMutationVariables
  >
    mutation={AddCommentToSingleAssignmentDocument}
    {...props}
  />
);

export type AddCommentToSingleAssignmentProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  AddCommentToSingleAssignmentMutation,
  AddCommentToSingleAssignmentMutationVariables
> &
  TChildProps;
export function withAddCommentToSingleAssignment<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddCommentToSingleAssignmentMutation,
    AddCommentToSingleAssignmentMutationVariables,
    AddCommentToSingleAssignmentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddCommentToSingleAssignmentMutation,
    AddCommentToSingleAssignmentMutationVariables,
    AddCommentToSingleAssignmentProps<TChildProps>
  >(AddCommentToSingleAssignmentDocument, {
    alias: 'withAddCommentToSingleAssignment',
    ...operationOptions
  });
}

export function useAddCommentToSingleAssignmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddCommentToSingleAssignmentMutation,
    AddCommentToSingleAssignmentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddCommentToSingleAssignmentMutation,
    AddCommentToSingleAssignmentMutationVariables
  >(AddCommentToSingleAssignmentDocument, baseOptions);
}
export type AddCommentToSingleAssignmentMutationHookResult = ReturnType<
  typeof useAddCommentToSingleAssignmentMutation
>;
export type AddCommentToSingleAssignmentMutationResult = ApolloReactCommon.MutationResult<
  AddCommentToSingleAssignmentMutation
>;
export type AddCommentToSingleAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCommentToSingleAssignmentMutation,
  AddCommentToSingleAssignmentMutationVariables
>;
export const DeleteCommentDocument = gql`
  mutation deleteComment($input: DeleteCardCommentInput!) {
    deleteCardComment(input: $input) {
      content
      id
      insertedAt
    }
  }
`;
export type DeleteCommentMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export type DeleteCommentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >,
  'mutation'
>;

export const DeleteCommentComponent = (props: DeleteCommentComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >
    mutation={DeleteCommentDocument}
    {...props}
  />
);

export type DeleteCommentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
> &
  TChildProps;
export function withDeleteComment<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCommentMutation,
    DeleteCommentMutationVariables,
    DeleteCommentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCommentMutation,
    DeleteCommentMutationVariables,
    DeleteCommentProps<TChildProps>
  >(DeleteCommentDocument, {
    alias: 'withDeleteComment',
    ...operationOptions
  });
}

export function useDeleteCommentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >(DeleteCommentDocument, baseOptions);
}
export type DeleteCommentMutationHookResult = ReturnType<
  typeof useDeleteCommentMutation
>;
export type DeleteCommentMutationResult = ApolloReactCommon.MutationResult<
  DeleteCommentMutation
>;
export type DeleteCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const PostAssignmentDocument = gql`
  mutation postAssignment($assignmentInput: CreateAssignmentInput!) {
    createAssignment(input: $assignmentInput) {
      assignment {
        id
        name
        content
        insertedAt
        isIveDone
        author {
          nickname
          avatar
        }
      }
      errors {
        code
        key
        message
      }
    }
  }
`;
export type PostAssignmentMutationFn = ApolloReactCommon.MutationFunction<
  PostAssignmentMutation,
  PostAssignmentMutationVariables
>;
export type PostAssignmentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    PostAssignmentMutation,
    PostAssignmentMutationVariables
  >,
  'mutation'
>;

export const PostAssignmentComponent = (
  props: PostAssignmentComponentProps
) => (
  <ApolloReactComponents.Mutation<
    PostAssignmentMutation,
    PostAssignmentMutationVariables
  >
    mutation={PostAssignmentDocument}
    {...props}
  />
);

export type PostAssignmentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  PostAssignmentMutation,
  PostAssignmentMutationVariables
> &
  TChildProps;
export function withPostAssignment<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PostAssignmentMutation,
    PostAssignmentMutationVariables,
    PostAssignmentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PostAssignmentMutation,
    PostAssignmentMutationVariables,
    PostAssignmentProps<TChildProps>
  >(PostAssignmentDocument, {
    alias: 'withPostAssignment',
    ...operationOptions
  });
}

export function usePostAssignmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PostAssignmentMutation,
    PostAssignmentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    PostAssignmentMutation,
    PostAssignmentMutationVariables
  >(PostAssignmentDocument, baseOptions);
}
export type PostAssignmentMutationHookResult = ReturnType<
  typeof usePostAssignmentMutation
>;
export type PostAssignmentMutationResult = ApolloReactCommon.MutationResult<
  PostAssignmentMutation
>;
export type PostAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PostAssignmentMutation,
  PostAssignmentMutationVariables
>;
export const UpdateAssignmentDocument = gql`
  mutation updateAssignment($assignmentInput: UpdateAssignmentInput!) {
    updateAssignment(input: $assignmentInput) {
      assignment {
        id
        name
        content
        insertedAt
      }
      errors {
        code
        key
        message
      }
    }
  }
`;
export type UpdateAssignmentMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables
>;
export type UpdateAssignmentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateAssignmentMutation,
    UpdateAssignmentMutationVariables
  >,
  'mutation'
>;

export const UpdateAssignmentComponent = (
  props: UpdateAssignmentComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateAssignmentMutation,
    UpdateAssignmentMutationVariables
  >
    mutation={UpdateAssignmentDocument}
    {...props}
  />
);

export type UpdateAssignmentProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables
> &
  TChildProps;
export function withUpdateAssignment<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAssignmentMutation,
    UpdateAssignmentMutationVariables,
    UpdateAssignmentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAssignmentMutation,
    UpdateAssignmentMutationVariables,
    UpdateAssignmentProps<TChildProps>
  >(UpdateAssignmentDocument, {
    alias: 'withUpdateAssignment',
    ...operationOptions
  });
}

export function useUpdateAssignmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAssignmentMutation,
    UpdateAssignmentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateAssignmentMutation,
    UpdateAssignmentMutationVariables
  >(UpdateAssignmentDocument, baseOptions);
}
export type UpdateAssignmentMutationHookResult = ReturnType<
  typeof useUpdateAssignmentMutation
>;
export type UpdateAssignmentMutationResult = ApolloReactCommon.MutationResult<
  UpdateAssignmentMutation
>;
export type UpdateAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables
>;
export const ContinueLearningDocument = gql`
  query continueLearning($id: ID!) {
    node(id: $id) {
      ... on CoursePackage {
        id
        firstUnlearnedCard {
          ancestors(isMaster: true) {
            depth
            id
            name
            children(ignoreAns: false) {
              id
              name
              isLearned(coursePackageId: $id)
              fileType
              parent {
                id
              }
            }
          }
          id
          name
          isLearned(coursePackageId: $id)
          fileType
          parent {
            id
          }
        }
      }
    }
  }
`;
export type ContinueLearningComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ContinueLearningQuery,
    ContinueLearningQueryVariables
  >,
  'query'
> &
  (
    | { variables: ContinueLearningQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ContinueLearningComponent = (
  props: ContinueLearningComponentProps
) => (
  <ApolloReactComponents.Query<
    ContinueLearningQuery,
    ContinueLearningQueryVariables
  >
    query={ContinueLearningDocument}
    {...props}
  />
);

export type ContinueLearningProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ContinueLearningQuery,
  ContinueLearningQueryVariables
> &
  TChildProps;
export function withContinueLearning<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ContinueLearningQuery,
    ContinueLearningQueryVariables,
    ContinueLearningProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ContinueLearningQuery,
    ContinueLearningQueryVariables,
    ContinueLearningProps<TChildProps>
  >(ContinueLearningDocument, {
    alias: 'withContinueLearning',
    ...operationOptions
  });
}

export function useContinueLearningQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContinueLearningQuery,
    ContinueLearningQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ContinueLearningQuery,
    ContinueLearningQueryVariables
  >(ContinueLearningDocument, baseOptions);
}
export type ContinueLearningQueryHookResult = ReturnType<
  typeof useContinueLearningQuery
>;
export type ContinueLearningQueryResult = ApolloReactCommon.QueryResult<
  ContinueLearningQuery,
  ContinueLearningQueryVariables
>;
export const GoLearningNextCardDocument = gql`
  query goLearningNextCard(
    $courseId: ID!
    $coursePackgeId: ID!
    $currentCardId: ID!
  ) {
    node(id: $coursePackgeId) {
      ... on CoursePackage {
        id
        nextCard(currentCardId: $currentCardId) {
          ancestors(isMaster: true) {
            depth
            id
            name
            children(ignoreAns: false) {
              id
              name
              isLearned(coursePackageId: $coursePackgeId)
              fileType
              parent {
                id
              }
            }
          }
          id
          content
          isCollected(courseId: $courseId)
          name
          parent(isMaster: true) {
            id
          }
          learnedPeopleCount(coursePackageId: $coursePackgeId)
        }
      }
    }
  }
`;
export type GoLearningNextCardComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GoLearningNextCardQuery,
    GoLearningNextCardQueryVariables
  >,
  'query'
> &
  (
    | { variables: GoLearningNextCardQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GoLearningNextCardComponent = (
  props: GoLearningNextCardComponentProps
) => (
  <ApolloReactComponents.Query<
    GoLearningNextCardQuery,
    GoLearningNextCardQueryVariables
  >
    query={GoLearningNextCardDocument}
    {...props}
  />
);

export type GoLearningNextCardProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  GoLearningNextCardQuery,
  GoLearningNextCardQueryVariables
> &
  TChildProps;
export function withGoLearningNextCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GoLearningNextCardQuery,
    GoLearningNextCardQueryVariables,
    GoLearningNextCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GoLearningNextCardQuery,
    GoLearningNextCardQueryVariables,
    GoLearningNextCardProps<TChildProps>
  >(GoLearningNextCardDocument, {
    alias: 'withGoLearningNextCard',
    ...operationOptions
  });
}

export function useGoLearningNextCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GoLearningNextCardQuery,
    GoLearningNextCardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GoLearningNextCardQuery,
    GoLearningNextCardQueryVariables
  >(GoLearningNextCardDocument, baseOptions);
}
export type GoLearningNextCardQueryHookResult = ReturnType<
  typeof useGoLearningNextCardQuery
>;
export type GoLearningNextCardQueryResult = ApolloReactCommon.QueryResult<
  GoLearningNextCardQuery,
  GoLearningNextCardQueryVariables
>;
export const SearchCourseContentCardDocument = gql`
  query searchCourseContentCard(
    $coursePackageId: ID!
    $matching: String
    $filter: SearchFilter!
  ) {
    searchCards(
      coursePackageId: $coursePackageId
      matching: $matching
      filter: $filter
    ) {
      id
      name
      fileType
      isLearned(coursePackageId: $coursePackageId)
    }
  }
`;
export type SearchCourseContentCardComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SearchCourseContentCardQuery,
    SearchCourseContentCardQueryVariables
  >,
  'query'
> &
  (
    | { variables: SearchCourseContentCardQueryVariables; skip?: boolean }
    | { skip: boolean });

export const SearchCourseContentCardComponent = (
  props: SearchCourseContentCardComponentProps
) => (
  <ApolloReactComponents.Query<
    SearchCourseContentCardQuery,
    SearchCourseContentCardQueryVariables
  >
    query={SearchCourseContentCardDocument}
    {...props}
  />
);

export type SearchCourseContentCardProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  SearchCourseContentCardQuery,
  SearchCourseContentCardQueryVariables
> &
  TChildProps;
export function withSearchCourseContentCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchCourseContentCardQuery,
    SearchCourseContentCardQueryVariables,
    SearchCourseContentCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchCourseContentCardQuery,
    SearchCourseContentCardQueryVariables,
    SearchCourseContentCardProps<TChildProps>
  >(SearchCourseContentCardDocument, {
    alias: 'withSearchCourseContentCard',
    ...operationOptions
  });
}

export function useSearchCourseContentCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SearchCourseContentCardQuery,
    SearchCourseContentCardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SearchCourseContentCardQuery,
    SearchCourseContentCardQueryVariables
  >(SearchCourseContentCardDocument, baseOptions);
}
export type SearchCourseContentCardQueryHookResult = ReturnType<
  typeof useSearchCourseContentCardQuery
>;
export type SearchCourseContentCardQueryResult = ApolloReactCommon.QueryResult<
  SearchCourseContentCardQuery,
  SearchCourseContentCardQueryVariables
>;
export const GetTagCardDocument = gql`
  query getTagCard($coursePackageId: ID!, $filter: SearchFilter!) {
    searchCards(coursePackageId: $coursePackageId, filter: $filter) {
      id
      name
      fileType
      isLearned(coursePackageId: $coursePackageId)
    }
  }
`;
export type GetTagCardComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetTagCardQuery,
    GetTagCardQueryVariables
  >,
  'query'
> &
  ({ variables: GetTagCardQueryVariables; skip?: boolean } | { skip: boolean });

export const GetTagCardComponent = (props: GetTagCardComponentProps) => (
  <ApolloReactComponents.Query<GetTagCardQuery, GetTagCardQueryVariables>
    query={GetTagCardDocument}
    {...props}
  />
);

export type GetTagCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetTagCardQuery,
  GetTagCardQueryVariables
> &
  TChildProps;
export function withGetTagCard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetTagCardQuery,
    GetTagCardQueryVariables,
    GetTagCardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetTagCardQuery,
    GetTagCardQueryVariables,
    GetTagCardProps<TChildProps>
  >(GetTagCardDocument, {
    alias: 'withGetTagCard',
    ...operationOptions
  });
}

export function useGetTagCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTagCardQuery,
    GetTagCardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetTagCardQuery, GetTagCardQueryVariables>(
    GetTagCardDocument,
    baseOptions
  );
}
export type GetTagCardQueryHookResult = ReturnType<typeof useGetTagCardQuery>;
export type GetTagCardQueryResult = ApolloReactCommon.QueryResult<
  GetTagCardQuery,
  GetTagCardQueryVariables
>;
