import * as React from 'react';
import './styles.css';

//引入的库组件
import { Form, Spin, Breadcrumb, message, Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useApolloClient } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { FormComponentProps } from 'antd/es/form';
import { withRouter } from 'react-router';
import { Base64 } from 'js-base64';

//引入的其他页面组件
import ItemRender from '../CommonComponents/ItemRender';
import DiffView from '../CommonComponents/DiffView';
import { RedButton } from '../CommonComponents/CustomButton';
import Modal2CallbackFunc from '../CommonComponents/Modals/Modal2CallbackFunc';
import { getSuffix } from '../../utils/index';
import { getDataFromTreeDataByFileTitle } from './utils';

//graphql查询
import { useResolveConflictsMutation } from '../../generated/graphql';

//引入的资源图片
import back from '../../assets/icons/back.png';
import root from '../../assets/icons/file_medium_root.png';
import file_status_warning from '../../assets/icons/file_status_warning.png';

interface Props extends FormComponentProps {
  props: any;
}

const GET_CONFLICT_INFO = gql`
  {
    conflictData @client(always: true) {
      content
      location
    }
    selectedFileId @client
    isMasterMerging @client(always: true)
    mergeInfo @client(always: true) {
      currentPackageId
      targetPackageId
    }
  }
`;

const EduManagerPackageResolveConflictPage = (props: any) => {
  const client = useApolloClient();
  const { data: conflictInfo } = useQuery(GET_CONFLICT_INFO);
  const { conflictData, selectedFileId, isMasterMerging, mergeInfo } = conflictInfo;
  const { form } = props;


  const [conflictStrings, setConflictStrings] = React.useState(conflictData);
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [resolveConflictsMutation] = useResolveConflictsMutation();

  const routes = [
    {
      path: isMasterMerging ? '//msg' : '//edu',
      breadcrumbName: '全部文件'
    },
    {
      path: '',
      breadcrumbName: isMasterMerging ? '我创建的' : '我协作的'
    },
    {
      path: '',
      breadcrumbName: '解决合并冲突'
    }
  ];

  React.useEffect(() => {
    if(conflictData && conflictData.length > 0) {
      setConflictStrings(conflictData);
    }
  }, [conflictData]);

  React.useEffect(() => {
    const newConflict = JSON.stringify(conflictStrings);
    if (
      newConflict.indexOf('<<<<<<<') === -1 &&
      newConflict.indexOf('=======') === -1 &&
      newConflict.indexOf('>>>>>>>') === -1
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [JSON.stringify(conflictStrings)]);

  const handlerMerge = () => {
    form.validateFieldsAndScroll(
      { first: true, force: true },
      (errors: any, values: any) => {
        if (errors) {
          return;
        }

        setLoading(true);
        const conflicts = conflictData.map((value: any, index: any) => {
          return {location: value.location, content: values[`content${index}`]};
        });

        let input : any = {  };

        if(isMasterMerging) {
          input= {
            conflicts: conflicts,
            currentPackageId: mergeInfo.currentPackageId,
            targetPackageId: mergeInfo.targetPackageId,
          };
        }else {
          input = {
            conflicts: conflicts,
            currentPackageId: Base64.decode(selectedFileId).split("Package:")[1],
          };
        }

        resolveConflictsMutation({variables: {input: input}}).then((res: any) => {
          setLoading(false);
          if (res.data.resolveConflicts.errors) {
            message.error(res.data.resolveConflicts.errors[0].message);
            return;
          } else {
            message.success('合并成功!');
            client.writeData({data: {conflictData: []}});
            if(isMasterMerging) {
              props.history.push('/msg');
            }else {
              props.history.push('/edu');
            }
            
          }
        });
      }
    );
  };

  return (
    <Spin spinning={loading} size={'large'}>
      <div className='routes-course-ResolveConflict-2015'>
        <div className='routes-course-ResolveConflict-2106'>
          <div className='routes-course-ResolveConflict-2144'>
            <div
              onClick={() => {
                props.history.push('/edu');
              }}
              className='routes-course-ResolveConflict-2293'
            >
              <img src={back} />
              <span className='routes-course-ResolveConflict-2400'>返回</span>
              <div className='routes-course-ResolveConflict-2471' />
              <Breadcrumb
                separator={'›'}
                itemRender={ItemRender}
                routes={routes}
                className='routes-course-ResolveConflict-2636'
              />
            </div>
          </div>

          <div className='routes-course-ResolveConflict-2711'>
            <img
              src={file_status_warning}
              className='routes-course-ResolveConflict-2818'
            />
            <div className='routes-course-ResolveConflict-2870'>
              合并出现{conflictData.length}个冲突，请先解决后再重新合并
            </div>
          </div>

          <Form>
            {conflictData.map((value: any, index: any) => {
              return (
                <div className='routes-course-ResolveConflict-3113'>
                  <div className='routes-course-ResolveConflict-3207'>
                    <img
                      className='routes-course-ResolveConflict-3344'
                      src={getSuffix(
                        value.location.substring(
                          value.location.lastIndexOf('.') + 1,
                          value.location.length
                        )
                      )}
                    />
                    <div className='routes-course-ResolveConflict-3490'>
                      {value.location}
                    </div>
                  </div>
                  <Form.Item hasFeedback>
                    {form.getFieldDecorator(`content${index}`, {
                      initialValue: value.content,
                      rules: [{}]
                    })(
                      <TextArea
                        className='routes-course-ResolveConflict-3809'
                        autosize
                        value={value.content}
                        onChange={e => {
                          const editedConflict = conflictStrings;
                          conflictStrings[index].content = e.target.value;
                          setConflictStrings(editedConflict);
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              );
            })}
          </Form>

          <Button
            disabled={disabled}
            className='routes-course-ResolveConflict-4771'
            onClick={() => {
              handlerMerge();
            }}
          >
            提交合并
          </Button>
        </div>
      </div>
    </Spin>
  );
};

const EduManagerPackageResolveConflictPageWrapper = Form.create<Props>()(
  withRouter(EduManagerPackageResolveConflictPage)
);

export default EduManagerPackageResolveConflictPageWrapper;
