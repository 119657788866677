import gql from 'graphql-tag';

export const MESSAGE_SUBSCRIPTION = gql`
  subscription newMessage {
    newMessage {
      category
      content
      id
      info
    }
  }
`;

export const GET_MESSAGE_COUNT = gql`
  query getMessageCount {
    me {
      messages(first: 10) {
        unreadCount
      }
    }
  }
`;
