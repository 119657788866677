import React, { Component } from 'react';
import Audio from 'react-audioplayer';

class AudioPlayer extends Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        if(this.audioComponent && this.audioComponent.audioElement) {
            let audio = this.audioComponent.audioElement;
            audio.src = '';
            audio.load();
        }
    }

    render() {
        const { width, height, autoPlay, playlist } = this.props;
        return (
            <div>
                <Audio
                  width={width}
                  height={height}
                  autoPlay={autoPlay}
                  playlist={playlist}
                  ref={(audioComponent) => {this.audioComponent = audioComponent}}
                />
            </div>
        );
    }
}

export default AudioPlayer; 
