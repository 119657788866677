import * as React from "react";

import "./styles.css";

import { useHomePageBannerQuery } from "../../generated/graphql";
import HomePageBanner from "./HomePageBanner";

const HomePageBannerContainer = (props: any) => {
  const { data, error, loading } = useHomePageBannerQuery();

  if (error || loading || !data) {
    return <div className="home-page-banner-2134" />;
  }

  return (
    <HomePageBanner data={data} props={props} />
  );
};

export default HomePageBannerContainer;
