/**
 * @description 过滤卡片和搜索内容卡片
 * @author ali
 * @since 2019
 */

import React, { FC, useState } from 'react';
import { Input, Icon, Dropdown, Menu } from 'antd';

import {
  useGetSearchCardQuery,
  // useGetTagCardQuery
  useSearchCourseContentCardQuery,
  useGetTagCardQuery
} from '../../../../../generated/graphql.tsx';
import filterIcon from '../../../../../assets/v2.1/filter.png';
import cross from '../../../../../assets/icons/cross.png';
import './style.less';

import { useQuery, useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_DATA = gql`
  {
    coursePackageId @client
    copyDirectoryList @client
  }
`;

const GET_DIR = gql`
  {
    directoryList @client
  }
`;

const FilterCard = () => {
  const client = useApolloClient();
  const [value, setValue] = useState('');
  const {
    data: { coursePackageId, copyDirectoryList }
  } = useQuery(GET_DATA);
  const { refetch: seachCard } = useSearchCourseContentCardQuery({
    skip: true
  });
  const { refetch: searchTagCard } = useGetTagCardQuery({ skip: true });
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          client.writeQuery({
            query: GET_DIR,
            data: { directoryList: copyDirectoryList }
          });
        }}
      >
        全部卡片
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          searchTagCard({
            coursePackageId,
            filter: { cardTag: 'TXT', fileType: 'FILE' }
          }).then(res => {
            const filterCards = res.data.searchCards;
            client.writeQuery({
              query: GET_DIR,
              data: { directoryList: filterCards }
            });
          });
        }}
      >
        文本卡片
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          searchTagCard({
            coursePackageId,
            filter: { cardTag: 'VIDEO', fileType: 'FILE' }
          }).then(res => {
            const filterCards = res.data.searchCards;
            client.writeQuery({
              query: GET_DIR,
              data: { directoryList: filterCards }
            });
          });
        }}
      >
        视频卡片
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          searchTagCard({
            coursePackageId,
            filter: { cardTag: 'EXAM', fileType: 'FILE' }
          }).then(res => {
            const filterCards = res.data.searchCards;
            client.writeQuery({
              query: GET_DIR,
              data: { directoryList: filterCards }
            });
          });
        }}
      >
        测试卡片
      </Menu.Item>
    </Menu>
  );
  // 键盘监听对象

  return (
    <div className='catalogue-filter-search'>
      <Dropdown overlay={menu}>
        <a className='ant-dropdown-link' href='javascript:void(0)'>
          <span className='filter'>
            <img src={filterIcon} alt='过滤标签' />
          </span>
        </a>
      </Dropdown>

      <Input
        className='search'
        placeholder={'搜索课程'}
        prefix={<Icon type='search' style={{ fontSize: '18px' }} />}
        suffix={
          value && (
            <img
              src={cross}
              onClick={() => {
                setValue('');
                client.writeQuery({
                  query: GET_DIR,
                  data: { directoryList: copyDirectoryList }
                });
              }}
            />
          )
        }
        style={{
          width: '249px',
          height: '50px'
        }}
        value={value}
        onChange={e => {
          setValue(e.target.value);
        }}
        onKeyDown={e => {
          // 检查是否为空
          // value 从头到尾匹配是否有空字符串
          // 从头到尾匹配是否有空字符串
          console.log(value.match(/\s/g) === null);
          if (e.keyCode === 13 && value.length === 0) {
            client.writeQuery({
              query: GET_DIR,
              data: { directoryList: copyDirectoryList }
            });
          } else if (e && e.keyCode == 13 && value.length !== 0) {
            seachCard({
              coursePackageId,
              matching: value,
              filter: { fileType: 'FILE' }
            }).then(res => {
              const filterCards = res.data.searchCards;
              client.writeQuery({
                query: GET_DIR,
                data: { directoryList: filterCards }
              });
            });
          }
        }}
      />
    </div>
  );
};

export default FilterCard;
